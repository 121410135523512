import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Sidebarmob from "../../sidebarmob";
import PostRequest from "../../../apiConnection/postRequest";
import React from 'react';
import SidebarProfile from '../../sidebarProfile';
import leftarrow from "../../../../assets/Image/leftarrow.svg";
import { getLocalStoarge, } from "../../../common/allfunction";

function TermsCondition() {
  const [isLoading, setIsLoading] = useState(false);
  const [userLang, setUserLang] = useState(getLocalStoarge("langauage"));
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const [termsAndcondition, setTermsAndCondition] = useState("");
  const [heading,setHeading] = useState("");
  const getConsentsDetails = async (e) => {
    setIsLoading(true);
    try {
      const payload = {
        consent_id: (userLang == "en") ? process.env.REACT_APP_CONSENT_ID_EN : process.env.REACT_APP_CONSENT_ID_IT,
      }
      const getConsents = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONSENT}`,
        payload
      );
      if (getConsents && getConsents.status === 200) {
        if (getConsents.data.code === 200) {
          setIsLoading(false);
          setTermsAndCondition(getConsents.data.data.terms_and_condition.full_text);
          setHeading(getConsents.data.data.terms_and_condition.title)
        } else {
          setIsLoading(false);
          setAlerts({
            alert: "",
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
        }

      } else {
        setIsLoading(false);
        setAlerts({
          alert: "Internal server error.",
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setAlerts({
        alert: "Internal server error.",
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
    }
  };
  useEffect(() => {
    getConsentsDetails();
  }, []);
  return (
   <>
   <section className="bg-privacy admin-termscnd dashboard-mobile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 topspace d-lfti none-viewport ipad-widhset">
            <SidebarProfile />
          </div>
          <div className="col-md-10  ipd-righ">
            <div className="top-priv-static">
              <div className="row">
                <div className="col-md-12">
                  <h2 className="otp-heading m-bspaci imposta referment-h">
                    <Link to="/profile-menu" className="d-flex align-items-center">
                      <img src={leftarrow} className="deskt-n" alt="icon" /></Link>
                         {heading}
                  </h2>
                </div>

                <div className="col-md-12">
                 <div className="static-left res-pon">
                 <p
                    dangerouslySetInnerHTML={{__html: termsAndcondition}}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    < div className="act-ve">
   <Sidebarmob/>
  </div>
</>
  );
}

export default TermsCondition;
