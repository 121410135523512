import { useState } from "react";
import userIcon from "../../../assets/Image/userIcon.svg";
import password from "../../../assets/Image/password.svg";
import logo from "../../../assets/Image/logo.svg";
import { Link, useHistory } from "react-router-dom";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import {setLocalStoarge, getLocalStoarge} from "../../common/allfunction";
import {useTranslation} from "react-i18next";
import hidePassword from "../../../assets/Image/hidePassword.svg";
import showPassword from "../../../assets/Image/showPassword.svg";

const superadminLogin = () => {
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [t, i18n] = useTranslation('common');
  const [validation, setValidation] = useState({
    email: "",
    password: "",
  });

  //handle submit updates
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    setAlerts("", "", "", false);
    setValidation({
      email: "",
      password: "",
    });
  };
    // password eye icon
    const [isRevealPwd, setIsRevealPwd] = useState(false);

  const handleTranslationLang = () =>{
    let lang = getLocalStoarge("langauage");
    if(lang){
      i18n.changeLanguage(lang)
    } 
  }
  const [companyId, setCompanyId] = useState()
  const checkValidation = async () => {
    let errors = validation;
    // email validation
    let Emailregex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const email = inputValues.email;
    if (!email) {
      errors.email = "";
    } else if (!email.match(Emailregex)) {
      errors.email = t('user.login.valid_email');
    } else {
      errors.email = "";
    }
    //password validation
    const Passregex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    const password = inputValues.password;
    if (!password) {
      errors.password =t('user.login.password_req');
    } else if (password.length < 8) {
      errors.password =t('admin.profile.valid_admin_password1');
    } else if (password.length > 32) {
      errors.password =t('admin.profile.valid_admin_password2');
    } else if (!password.match(Passregex)) {
      errors.password =t('user.login.pass_regex');
    } else {
      errors.password = "";
    }

    setValidation(errors);
    let return_val = true;
    await Object.entries(errors).forEach(([key, value]) => {
      if (value !== "") return_val = false;
    });
    return return_val;
  };
  const do_Login = async (e) => {
    e.preventDefault();
    setAlerts({
      alert: t('user.login.wait'),
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    const { email, password } = inputValues;
    if (
      email == "" ||
      password == "" ||
      email == undefined ||
      password == undefined
    ) {
      setAlerts({
        alert: t('user.login.valid_both'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      return false;
    }
    const company =companyId;
    setIsLoading(true);
    const validate = await checkValidation();    
    if (validate) {
      try {
        const payload = {
          username: email,
          password: password
          // role_id : "3"
        };
        const loginResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUPER_ADMIN_LOGIN}`,
          payload
          );
        if (loginResult && loginResult.status === 200) {
          if (loginResult.data.code === 200) {            
            setLocalStoarge('roleId',loginResult.data.data.profile.role_id)
            if(loginResult.data.data.login.two_fa==0){
              setIsLoading(false);
              const { access_token, expires_in, refresh_token,two_fa } = loginResult.data.data.login;
              const logged_user = {
                access_token,
                expires_in,
                refresh_token,
                two_fa,
              };              
              setLocalStoarge("R3", JSON.stringify(logged_user)) 
              const { profile_pic, user_name, language} = loginResult.data.data.profile;
              let admin_data = {
                profile_pic, 
                user_name
              };
              setLocalStoarge('admin_profile',JSON.stringify(admin_data))
              setLocalStoarge('langauage', (language==0)?"it":'en');
              setAlerts({
                alert : t('user.login.success'),
                alerttype :"Success",
                alertclass : "success",
                showalert : true
              });
              setTimeout(() =>{
                // history.push("/protocol");
                window.location.href = "protocol";
             }, 1000);
            }
            else {
              setIsLoading(false);
              // Add email for 2fa
              setLocalStoarge("admin_email", email);
              window.location.href = "otp-verification";
            }
          }
        }
         else if (loginResult && loginResult.status === 203) {
          setIsLoading(false);
          setAlerts({
            alert: loginResult.data.message,
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
        }
      } catch (error) {
        setIsLoading(false);
        setAlerts({
          alert: t('user.login.server'),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
      }
    } else {
      setIsLoading(false);
      setAlerts("", "", "", false);
    }
  }

  // useEffect(() => {
  //   async function fetchCompanyAPI() {
  //     const company = await getCompany();
  //     if (company!==false){
  //             setCompanyId(company.document_id);
  //     }  
  //   }
  //   fetchCompanyAPI()
  //   handleTranslationLang()
  // }, [])
  
  return (
    <>
     <div className="bgadminmob">
      <section className="bgloginsuper-admin bg_otp m-top25m commonipadbg loginipad-bg">
        <div className="container">
          <div className="logo-w">
            <Link to="/signin">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </div>
          <div className="row top63">
            <div className="forgot">
              <div className="icon-aut">
                <h2 className="otp-heading">Benvenutə!</h2>
                <p className="Riemposta">
                 Accedi al servizio di Evolvity usando <br/>le tue credenziali personali.
                </p>
                <form onSubmit={(e) => do_Login(e)} method="POST">
                  <div className="registrati-form riempostainput super-admin">
                    {showalert ? (
                      <div className={`alert alert-${alertclass}`}>{alert}</div>
                    ) : (
                      <></>
                    )}
                  <div className="form-group relative">
                    <img src={userIcon} alt="icon" className="icon_left" />
                    <input
                    type="email"
                    placeholder="Nome"
                    className="form-control"
                    onChange={handleChange}
                    name="email"
                    defaultValue={inputValues.email}
                  />
                  {validation.email && (
                    <p className="danger">{validation.email}</p>
                  )}
                  </div>
                    <div className="form-group relative">
                      <img src={password} alt="icon" className="icon_left" />
                      <input
                          type={isRevealPwd ? "text" : "password"}
                        placeholder="Password"
                        className="form-control"
                        autoComplete="off"
                        onChange={handleChange}
                        name="password"
                        defaultValue={inputValues.password}
                      />
                        <img src={isRevealPwd ? hidePassword : showPassword}
                       onClick={() => setIsRevealPwd(prevState => !prevState)} alt="icon"
                       className="eye_icon_right" />
                      {validation.password && (
                        <p className="danger">{validation.password}</p>
                      )}
                      </div>
                     
                       <Link to="forgot-password">
                        <div className="forgot-password-style">Password dimenticata?</div>
                       </Link>
                    <button type="submit" className="buttons m91">Accedi</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          {(isLoading) ? <LazyLoader /> : null}
          </div>
          <p className="Powered-by">© Powered By Neocogita</p>
         </section>
   
      </div>
    </>
  );
};
export default superadminLogin;
