import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { getLocalStoarge } from '../../../common/allfunction';
import ReactApexChart from 'react-apexcharts';
import LazyLoader from '../../../common/lazyLoader';
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from '../../../apiConnection/getRequest';
import { Link } from "react-router-dom";

const CognitiveGoNoGoBarChart = (props) => {
  const [t, i18n] = useTranslation("common");
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [theme, setTheme] = useState(getLocalStoarge("theme"));
  const option1 = {
    series: [
      {
        data: [],
      },
    ],

    options: {
      chart: {
        type: 'bar',
        height: 350,

      },
      colors: [function ({ value, seriesIndex, dataPointIndex, w }) {
        if (dataPointIndex <=5) {
          return "#54C1FB"
        } else if (dataPointIndex <= 11) {
          return "#F6BF42";
        }
        else if (dataPointIndex <= 14) {
          return "#6D71F9"
        }
      }],
      responsive: [{
        breakpoint: 767, // define breakpoint for mobile
        options: {
          chart: {
            width: '100%', // make the chart 100% width for mobile
          }
        }
      }],
  
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        lines: {
          show: true,
        },
        categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
      
        labels: {
          style: {
            colors: (theme && (theme?.replace(/"/g, ''))=="dark") ? '#fff' : '#000',
          },
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
        title: {
          text: ''
        },
        labels: {
          style: {
            colors: (theme && (theme?.replace(/"/g, ''))=="dark") ? '#fff' : '#000', 
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    }
  }


  const option2 = {
    series: [
      {
        data: [],
      },
    ],

    options: {
      chart: {
        type: 'bar',
        height: 350,

      },
      colors: [function ({ value, seriesIndex, dataPointIndex, w }) {
        if (dataPointIndex <= 5) {
          return "#54C1FB"
        } else if (dataPointIndex <= 11) {
          return "#F6BF42";
        }
        else if (dataPointIndex <= 14) {
          return "#6D71F9"
        }
      }],
      responsive: [{
        breakpoint: 767, // define breakpoint for mobile
        options: {
          chart: {
            width: '100%', // make the chart 100% width for mobile
          }
        }
      }],
  
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        lines: {
          show: true,
        },
        categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15'],
      
        labels: {
          style: {
            colors: (theme && (theme?.replace(/"/g, ''))=="dark") ? '#fff' : '#000',
          },
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
        title: {
          text: ''
        },
        labels: {
          style: {
            colors: (theme && (theme?.replace(/"/g, ''))=="dark") ? '#fff' : '#000', 
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    }
  }

  useEffect(() => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
    setData1(option1);
    setData2(option2);
    goNoGoGraphData();
  }, [props.repeatTime]);


  const goNoGoGraphData = async () => {
    setIsLoading(true)
    try {
      // const refreshToken = await PostRequest(
      //   `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      //   {},
      //   "",
      //   "refresh_token"
      // );
      // if (refreshToken) {
        const getGoNoGoGraphData = await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_GO_NO_GO_REPORT}?repeat_time=${props.repeatTime}`,
        );
        setIsLoading(false)
        if (getGoNoGoGraphData && getGoNoGoGraphData.status === 200) {
          let result = getGoNoGoGraphData?.data?.data?.user_go_no_go_score;
          let newResponseTime=result.activity_day_wise_response_time.map((value)=>{
            return value !== 0 ? Math.floor(value) : value
          });
          let newActivityScore=result?.activity_day_wise_score?.map(value => value.toFixed(1)*100)
          setData1(prevData => ({
            series: [
              {
                data: newResponseTime,
              },
            ],
            options: prevData.options,
          }));
          setData2((prevData)=>({
            series: [
              {
                data: newActivityScore,
              },
            ],
            options: prevData.options,
          }))
        }
      // } else {
      //   setIsLoading(false);
      // }
    } catch (error) {
      console.log("error", error)
      setIsLoading(false);
    }
  }

  return (
    <div className='container'>
      {isLoading ? <LazyLoader /> : null}
      <h1 className='go-no-go-heading'>GO-NO-GO</h1>
      <div className='row'>
        <div className='col-lg-6'><p className='go-no-description'>{t('user.activityReport.Go-No-Go-description1')}</p></div>
        <div className='col-lg-6'><p className='go-no-description'>{t('user.activityReport.Go-No-Go-description2')}</p></div>
      </div>

      <div className='row mt-5'>
        <div className="col-lg-5">
          <div className='go-no-go-average'>{t('user.activityReport.average_time')}</div>
          {(data1) ? <div id="chart" className="graphical-bar-chart">
            <ReactApexChart options={data1?.options} series={data1?.series} type="bar" height={350} />
          </div> : null}
        </div>
        <div className="col-lg-5">
          <div className='go-no-go-average'>{t('user.activityReport.average_accuracy')}</div>
          {(data2) ? <div id="chart" className="graphical-bar-chart">
            <ReactApexChart options={data2?.options} series={data2?.series} type="bar" height={350} />
          </div> : null}
        </div>
        <div className="col-lg-2">
          <div className='go-no-go-graph-color'>
            <div className='n-back-bar-graph'>
              <div className='go-no-go-visio-circle mr-3'></div>
              <div className='go-no-go-activity-name'>{t('user.activityReport.go-no-go1')}</div>
            </div>
            <div className='n-back-bar-graph'>
              <div className='go-no-go-visuoacustico-circle mr-3'></div>
              <div className='go-no-go-activity-name'>{t('user.activityReport.go-no-go2')}</div>
            </div>
            <div className='n-back-bar-graph'>
              <div className='go-no-go-visuoacustico2-circle mr-3'></div>
              <div className='go-no-go-activity-name'>{t('user.activityReport.go-no-go3')}</div>
            </div>
          </div>
        </div>

      </div>
      <div className='go-no-bar-graph-btn'>
        {/* <div></div>
        <Link to={`/congnitive-training/${props.repeatTime}`}>
        <button className='go-no-meditation-type-btn'>{t('user.activityReport.btn')}</button>
        </Link> */}
      </div>
    </div>
  )
}

export default CognitiveGoNoGoBarChart
