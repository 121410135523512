import { useEffect, useState, useRef, forwardRef } from "react";
import { isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import ReactPaginate from 'react-paginate';
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";
import Sidebar from "../../common/sidebar";
import Sidebarmob from "../../common/sidebarMobile";
import Modal from "../../common/modal";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { getLocalStoarge, getDynamicCompany } from "../../common/allfunction";
import VectorDown from "../../../assets/Image/VectorDown.svg";
import Group698 from "../../../assets/Image/Group698.svg";
import Group1857link from "../../../assets/Image/Group1857link.svg"
import Group769 from "../../../assets/Image/Group769.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import OPEN_ICON from '../../../assets/Image/OPEN2_ICONA.svg';
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg";
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg';
import VideoPlayButton from '../../../assets/Image/VideoPlayButton.svg'
import GetRequest from "../../apiConnection/getRequest";
import VIDEO_IMAGE from '../../../assets/Image/Rectangle319.svg';
import ShowVideo from "../../common/ShowVideo";
import { ViewDocument } from "../../common/ViewDocument";
import '../../superAdmin/super_admin_assets/scss/protocol.scss';
import '../../admin/admin_assets/scss/Adminstyle.scss';
import '../scss/userStyle.scss';

const UserTraining = (props) => {
    let urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const [contentId, setContentId] = useState(null);
    const [docPreview, setDocPreview] = useState(false);
    const { SearchBar } = Search;
    const [company_id, setCompany_id] = useState(props.match.params.company_id)
    const [programData, setProgramData] = useState([])
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [companyDetails, setCompanyDetails] = useState({})
    const [filterShow, setFilterShow] = useState(false);
    const [filterValue, setFilterValue] = useState([]);
    const [filterValueData, setfilterValueData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [programColumn, setProgramColumn] = useState([{ text: "name", dataField: "program_name", sort: true }])
    const [personalText, setPersonalText] = useState("program");
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [documentData, setDocumentData] = useState([]);
    const [activeDocument, setActiveDocument] = useState({});
    const [documentProgramsModal, setDocumentProgramsModal] = useState(false)
    const [documentPageCount, setDocumentPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [videoData, setVideoData] = useState([])
    const [videoPageCount, setVideoPageCount] = useState(0)
    const [videoCurrentPage, setVideoCurrentPage] = useState(0)
    const [videoItemPerPage, setVideoItemPerPage] = useState(10)
    const [videoSearchQuery, setVideoSearchQuery] = useState("")
    const [documentSearchQuery, setDocumentSearchQuery] = useState("")
    const [currentVideoDetails, setCurrentVideoDetails] = useState({ link: "", title: "", description: "" })
    const [showVideoContentModal, setShowVideoContentModal] = useState(false)
    const [activeContentType, setActiveContentType] = useState("document")
    const [currentDocumentProgram, setCurrentDocumentProgram] = useState([])
    const [documentLoadMoreIndex, setDocumentLoadMoreIndex] = useState('')
    const [userLang, setUserLang] = useState(getLocalStoarge("langauage"))
    const GotoCommonDashboard = ()=>{
        window.location.href="/app/common-dashboard"
       }
    const getCompanyAllProgram = async (company) => {
        const payload = {
            "company_id": company,
        };
        setIsLoading(true);
        try {
            const getAllDataResult = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_PROGRAM_DATA}?company_id=${company}`,
                payload,
                "",
                ""
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                setIsLoading(false);
                if (getAllDataResult.data?.data?.resArr.length > 0) {
                    let arr = [];
                    getAllDataResult.data.data?.resArr?.forEach((item) => {
                        if (item.status > 0) {

                            arr = [...arr, {
                                program_name: item.program_name || "",
                                duration: item.duration || "",
                                course: item.course || 0,
                                release_date: item.release_date ? `${moment(new Date(item.release_date * 1000)).format("DD.MM.YY").replace(/YYYY/g, "YY")}` : '',
                                // obligatory: item.obligatory[0].toUpperCase() + item.obligatory.slice(1).toLowerCase() || '',
                                obligatory: (userLang == 'it') ? (item.obligatory == "Yes" || item.obligatory == "yes") ? t("superadmin.program.Obligatory_yes") : t("superadmin.program.Obligatory_no") : item.obligatory[0].toUpperCase() + item.obligatory.slice(1).toLowerCase() || '',
                                program_id: item.program_id || '',
                                status: item.status || 0,
                                referent: item.referent || '',
                                description: item.description || ''
                            }]
                        }
                    })
                    setProgramData(arr)
                }
            } else {
                setIsLoading(false);
                setLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getCompanyDocument = async (page) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const documentResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_COMPANY_DOCUMENT}?companyId=${companyDetails.document_id}&skip=${(page - 1) * itemPerPage}&limit=${itemPerPage}&search=${documentSearchQuery}`
                );
                if (documentResult && documentResult.status === 200) {
                    setIsLoading(false);
                    let data2 = documentResult.data?.data;
                    if (data2?.data?.length > 0) {
                        let arr = []
                        data2?.data?.forEach((d1) => {
                            let pro = [];
                            // d1.associatePrograms?.forEach((d2) => {
                            //     pro = [...pro, { name: d2.name, description: d2.description }]
                            // })
                            arr = [...arr, {
                                name: d1.title,
                                description: d1.description,
                                programs: d1.associatePrograms,
                                contentId: d1.id
                            }]
                        })
                        setDocumentData(arr)
                        setDocumentPageCount(Math.ceil(data2?.allDocuments / itemPerPage))
                        setCurrentPage(page)
                    } else {
                        setDocumentData()
                    }
                } else {
                    setDocumentData()
                    setIsLoading(false);
                    setLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getCompanyVideo = async (page) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const videoResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_COMPANY_VIDEO}?companyId=${companyDetails.document_id}&skip=${(page - 1) * itemPerPage}&limit=${itemPerPage}&search=${videoSearchQuery}`
                );
                if (videoResult && videoResult.status === 200) {
                    setIsLoading(false);
                    let data1 = videoResult.data?.data;
                    if (data1?.data?.length > 0) {
                        let arr = []
                        data1.data.forEach((d1) => {
                            // let pro = [];
                            // d1.associatePrograms?.forEach((d2) => {
                            //     pro = [...pro, { name: d2.name, description: d2.description,id: }]
                            // })
                            arr = [...arr, {
                                contentId: d1.id,
                                name: d1.title,
                                description: d1.description,
                                link: d1.url,
                                programs: d1.associatePrograms,
                                thumbnail: d1.thumbnail
                            }]
                        })
                        setVideoData(arr)
                        setVideoPageCount(Math.ceil(data1?.allVideos / videoItemPerPage))
                        setVideoCurrentPage(page)
                    } else {
                        setLoading(false);
                        setVideoData([])
                    }
                } else {
                    setIsLoading(false);
                    setLoading(false);
                    setVideoData([])

                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    // const getContentDetails = async (id) => {
    //     setIsLoading(true);
    //     try {
    //         const refreshToken = await PostRequest(
    //             `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
    //             {},
    //             "",
    //             "refresh_token"
    //         );
    //         if (refreshToken) {
    //             const videoResult = await GetRequest(
    //                 `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONTENT_DETAILS}?contentId=${id}`
    //             );
    //             if (videoResult && videoResult.status === 200) {
    //                 setIsLoading(false);
    //                 let data1 = videoResult.data?.data;
    //                 let link = data1.url ? data1.url : data1.filePath?.includes("vimeo") ? data1.filePath : `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_CONTENT}${data1.filePath}`;
    //                 setCurrentVideoDetails({ title: data1.title, description: data1.description, link: link, error: false })
    //                 setShowVideoContentModal(true)
    //             } else {
    //                 if (videoResult?.status === 203) {
    //                     setCurrentVideoDetails({ ...currentVideoDetails, error: true })
    //                 }
    //                 setIsLoading(false);
    //             }
    //         } else {
    //             setIsLoading(false);
    //         }
    //     } catch (error) {
    //         setIsLoading(false);
    //     }
    // }
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        const actionButtonsProgram = (cell, row, rowIndex, formateExtraData) => {
            return (
                <div className="d-flex justify-content-center">
                    <button className="table-action" onClick={() => window.location.href = `program-details/${row.program_id}`}><img src={ExpandIcon} alt="icon" /></button>
                </div>
            )
        }
        const nameFormatter = (cell, row) => {
            let color = row.status == 1 ? 'green' : row.status == 2 ? 'red' : 'yellow';
            return (
                <div>
                    {/* <span className={`small-dot ${color} `}> </span> */}
                    {` ${row.program_name}`}</div>
            )
        }
        setProgramColumn([
            {
                text: t("superadmin.training.name"),
                dataField: "program_name",
                formatter: nameFormatter,
                sort: true,
            },
            {
                text: t("superadmin.program.referente"),
                dataField: "referent",
                sort: true,
            },
            {
                text: t("superadmin.training.duration"),
                dataField: "duration",
                sort: true,
            },
            {
                text: t("superadmin.training.npaths"),
                dataField: "course",
                sort: true,
            },
            {
                text: t("superadmin.training.mandatory"),
                dataField: "obligatory",
                sort: true,
            },
            {
                text: t("superadmin.training.release"),
                dataField: "release_date",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtonsProgram,
                sort: true,
                headerAlign: "center"
            },
        ])
    };

    const callApis = async () => {
        setIsLoading(true)
        const company = await getDynamicCompany(localStorage.getItem('dynamic_id'));
        if (company) setCompanyDetails(company)
        setIsLoading(false)
        if (personalText == 'program') {
            await getCompanyAllProgram(company.document_id)
        }
        if (!localStorage.getItem('UserPA')) {
            updateUserProgramStatus()
        }
        // const callApi2 = setTimeout(() => {
        //     getCompanyVideo(1)
        // }, 2000)
        // return () => clearTimeout(callApi2)
    }
    // const filterValues = () => {
    //     setfilterValueData([
    //         { id: 0, name: t("superadmin.training.active"), key: 'active', value: 'active' },
    //         { id: 1, name: t("superadmin.training.non_active"), key: 'inactive', value: 'inactive' },
    //         { id: 2, name: t("superadmin.training.scheduled"), key: 'scheduled', value: 'scheduled' },
    //         { id: 3, name: 'Obligatorio', key: 'obligatory', value: 'obligatory' },
    //         { id: 4, name: 'Non Obligatorio', key: 'non-obligatory', value: 'non-obligatory' },
    //         { id: 5, name: 'Vedi tutti', key: 'all', value: 'all' },
    //     ])
    // }
    useEffect(() => {
        handleTranslationLang();
        // filterValues()
    }, []);
    useEffect(() => {
        callApis()
    }, [])
    useEffect(() => {
        if (activeContentType == "document" && personalText == "material") {
            const callApi = setTimeout(() => {
                getCompanyDocument(1)
            }, 2000)
            return () => clearTimeout(callApi)
        }
    }, [documentSearchQuery])
    useEffect(() => {
        if (activeContentType == "video") {

            const callApi = setTimeout(() => {
                getCompanyVideo(1);
            }, 2000)
            return () => clearTimeout(callApi)
        }
    }, [videoSearchQuery])
    // useEffect(() => {
    //     const checkIfClickedOutside = (e) => {
    //         if (filterShow && ref.current && !ref.current.contains(e.target)) {
    //             setFilterShow(false);
    //             setFilteredData([])
    //             setFilterValue
    //                 ([])
    //         }
    //     };
    //     document.addEventListener("mousedown", checkIfClickedOutside);
    //     return () => {          
    //         document.removeEventListener("mousedown", checkIfClickedOutside);
    //     };
    // }, [filterShow]);

    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-between pagination-list">
                <div className="row w-100">
                    <div className="col-lg-3 col-md-3"></div>
                    <div className="col-lg-4 col-md-4 d-flex justify-content-center">
                        {pages.map((p, index) => {
                            if (p.page === "<") return <div key={index} className="page" onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                            if (p.page === ">") return <div key={index} className="page" onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                            if (p.active) return <div key={index} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                            return (
                                <div className="page" key={index} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                            )
                        })}
                    </div>

                    {/* {(isMobile) ? (
                        <div className="container">
                        <div className="row align-items-center buttons-superadmin">
                            <div className="col-6 col-sm-3 justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(1)}><span className="small-dot green mr-1"></span><span> {t("superadmin.training.active")}</span></div>
                            <div className="col-6 col-sm-3 justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(2)}><span className="small-dot red "></span><span> {t("superadmin.training.non_active")}</span></div><br></br>
                            <div className="col-6 col-sm-3 justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(3)}><span className="small-dot yellow"></span><span> {t("superadmin.training.scheduled")}</span></div>
                            <div className="col-6 col-sm-3 justify-content-center align-items-center logout-button" onClick={() => { setFilteredData([]); setFilterShow(false) }}><span className="small-dot blue mr-1"></span><span> {t("superadmin.training.all")}</span></div>
                        </div>
                      </div>
                    )
                    : 
                    <div className="col-lg-5 col-md-5 d-flex justify-content-end">
                    <div className="mr-2 d-flex align-items-center buttons-superadmin">
                        <div className="mr-3 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(1)}><span className="small-dot green mr-1"></span><span> {t("superadmin.training.active")}</span></div>
                        <div className="mr-3 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(2)}><span className="small-dot red mr-1"></span><span> {t("superadmin.training.non_active")}</span></div>
                        <div className="mr-3 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(3)}><span className="small-dot yellow mr-1"></span><span> {t("superadmin.training.scheduled")}</span></div>
                        <div className="d-flex justify-content-center align-items-center logout-button" onClick={() => { setFilteredData([]); setFilterShow(false) }}><span className="small-dot blue mr-1"></span><span> {t("superadmin.training.all")}</span></div>
                    </div>
                </div> 
                    } */}

                </div>
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }
    const closeModal = () => {
        setDocumentProgramsModal(false)
        setActiveDocument({})
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    }
    const documentProgramsModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <strong className="ml-sm-0 ml-md-0 ml-lg-3 ml-xl-3 ml-xxl-3 program-font">{t("superadmin.training.popup.associated_programs")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                (activeDocument.programs && activeDocument.programs?.length) ?
                                    <div className="">
                                        {activeDocument.programs.map((data, i) => {
                                            return (
                                                <a href={'/app/program-details/' + data.id}>
                                                    <div className="document-program-details" key={i}>
                                                        <div className="name">{data.name}</div>
                                                        <div className="description">{data.description}</div>
                                                    </div>
                                                </a>
                                            )
                                        })}
                                    </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // const changeFilter = () => {
    //     setFilterShow(!filterShow);
    //     setFilteredData(programData)
    // };
    const handleStatusFilter = (value) => {
        let arr = programData.filter((d) => d.status == value)
        setFilteredData(arr)
        setFilterShow(true)
    }
    // const onChangeFilter = (e) => {
    //     const value = e.target.value;
    //     const status = e.target.checked;
    //     let arr = []
    //     if (status) {
    //         let temp = filterValue;
    //         temp.push(value);
    //         arr = temp;
    //         setFilterValue(temp);
    //     } else {
    //         let temp1 = filterValue;
    //         //   temp1.pop(value);
    //         temp1 = temp1.filter(ele => ele !== value)
    //         arr = temp1;
    //         setFilterValue(temp1);
    //     }
    //     refreshTable(arr);
    // };

    // const refreshTable = (arr) => {

    //     let tempData = [];
    //     programData.forEach((element) => {
    //         if (arr.includes("active")) {
    //             if (element.status === 1 && !filteredData.includes(element.program_id)) {
    //                 tempData = [...tempData, element]
    //             }
    //         }
    //         if (arr.includes("inactive")) {
    //             if (element.status === 2 && !filteredData.includes(element.program_id)) {
    //                 tempData = [...tempData, element]
    //             }
    //         }
    //         if (arr.includes("scheduled")) {
    //             if (element.status === 3 && !filteredData.includes(element.program_id)) {
    //                 tempData = [...tempData, element]
    //             }
    //         }
    //         if (arr.includes("obligatory")) {
    //             if (element.obligatory === "Yes" && !filteredData.includes(element.program_id)) {
    //                 tempData = [...tempData, element]
    //             }
    //         }
    //         if (arr.includes("non-obligatory")) {
    //             if (element.obligatory === "No" && !filteredData.includes(element.program_id)) {
    //                 tempData = [...tempData, element]
    //             }
    //         }
    //     });
    //     setFilteredData(tempData);
    // };
    // const resetFilters = () => {      
    //     setFilterShow(!filterShow);
    //     setFilteredData(programData)
    // };
    const handlePageClick = (event) => {
        let page = event.selected + 1
        setCurrentPage(page)
        getCompanyDocument(page)
    };
    const handlePageClickVideo = (event) => {
        let page = event.selected + 1
        setCurrentPage(page)
        getCompanyVideo(page)
    }

    // useEffect(() => {
    //     fileArr()
    // }, [files]);

    // useEffect(() => {
    //     pdfFileArr()
    // }, [pdfFile]);

    // useEffect(() => {
    //     if (pdfUrlChange != null) {
    //         fetchTitleAndDescription(pdfUrlChange)
    //     }
    // }, [pdfUrlChange])

    const previewDoc = (e, contentId) => {
        setContentId(contentId)
        setDocPreview(true)

    }

    //get Video content details
    const getVideoContentDetails = async (id, programs) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const videoResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONTENT_DETAILS}?contentId=${id}`
                );
                if (videoResult && videoResult.status === 200) {
                    setIsLoading(false);
                    updateContentCount('video', programs)
                    let data1 = videoResult.data?.data;
                    let link = data1.url ? data1.url : data1.filePath?.includes("vimeo") ? data1.filePath : `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_CONTENT}${data1.filePath}`;
                    if (data1.url) {
                        window.open(data1.url, '_blank').focus();
                    } else {
                        setCurrentVideoDetails({ title: data1.title, description: data1.description, link: link, error: false })
                        setShowVideoContentModal(true)
                    }
                    updateUserCourseContentStatus(id, programs)
                } else {
                    if (videoResult?.status === 203) {
                        setCurrentVideoDetails({ ...currentVideoDetails, error: true })
                    }
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const updateContentCount = async (type, program) => {
        let todayTime = new Date();
        let utcDate = new Date(todayTime.getUTCFullYear(), todayTime.getUTCMonth(), todayTime.getUTCDate()).getTime() / 1000;
        let payload = {
            companyId: companyDetails.document_id,
            contentType: type,
            "date": utcDate,
            "programs": program
        }
        try {
            const getAllDataResult = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_CONTENT_COUNT}`,
                payload,
                "",
                ""
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                console.log("video passed ")
            } else {
                console.log("failed")
            }
        } catch (error) {
            console.log(error)

        }
    }
    const updateUserProgramStatus = async () => {
        setIsLoading(true);
        try {
            const getAllDataResult = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_USER_PROGRAM_STATUS}`,
                {},
                "",
                ""
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                setIsLoading(false);
                localStorage.setItem('UserPA', 1)
            } else {
                localStorage.setItem('UserPA', 1)
                setIsLoading(false);
                setLoading(false);
            }
        } catch (error) {
            console.log(error)
            localStorage.setItem('UserPA', 1)
            setIsLoading(false);
        }
    };
    const updateUserCourseContentStatus = async (contentId, programs) => {
        let arr = [];
        if (programs) arr = programs?.map((d1) => d1.id)
        else arr = currentDocumentProgram.map((d1) => d1.id)
        let payload = {
            programs: arr,
            contentId: contentId,
        }
        try {
            const updateContentStatus = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_USER_COURSE_CONTENT_STATUS}`,
                payload,
                "",
                ""
            );
            if (updateContentStatus && updateContentStatus.status === 200) {
                setIsLoading(false)
            } 
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }
    return (
        <>

            <Modal title="" size="sm" contentClasses="" onClose={() => setDocumentProgramsModal(false)} show={documentProgramsModal}>
                {documentProgramsModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setShowVideoContentModal(false)} show={showVideoContentModal}>
                <ShowVideo details={currentVideoDetails} close={() => setShowVideoContentModal(false)} />
            </Modal>
            <Modal title="" size="sm" contentClasses=" " onClose={() => setDocPreview(false)} show={docPreview}>
                <ViewDocument contentId={contentId} program={currentDocumentProgram} companyId={companyDetails.document_id} updateUserCourseContentStatus={updateUserCourseContentStatus} close={() => setDocPreview(false)} />
            </Modal>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar path="training" />
                        <div className=" col-md-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace leftr-dari">
                                <div className="right-header right-header-edit-training back-training">
                                    <h2 className="heading-dash">
                                        {t("admin.training.trainingContent")}
                                    </h2>
                                    <div className="align-j-new buttons-superadmin">
                                        {personalText == "program" ? <div></div>
                                            : personalText == "document" ? <div>
                                            </div>
                                                : <div>
                                                </div>
                                        }
                                            <div className="cmd-back-training cmd-back-right-text justify-content-between" id="step-8">
                                            <p className="pt-2 pr-5" dangerouslySetInnerHTML={{ __html: t("user.dashboard.back_to_cmd") }}></p>                                            
                                                <button className="btn-apr" onClick={GotoCommonDashboard}>{t("user.dashboard.go")}</button>                    
                                            </div>
                                    </div>
                                </div>
                                <div className="graph-tabs graph-tabs-new ">
                                    <div className="user-training-section">
                                        <div className="flex-navtabs d-flex justify-content-between">
                                            <ul
                                                className="nav nav-tabs nav-stacked"
                                                id="myTab"
                                                role="tablist"
                                            >
                                                <>
                                                    <li className="nav-item" onClick={() => setPersonalText("program")}>
                                                        <a
                                                            className={
                                                                "nav-link buttons-superadmin mt-0 " +
                                                                (personalText == "program"
                                                                    ? "active show"
                                                                    : "")
                                                            }
                                                            id="first-tab"
                                                            data-toggle="tab"
                                                            href="#first"
                                                            role="tab"
                                                            aria-controls="first"
                                                            aria-selected="true"
                                                        >

                                                            <button className="buttons mr-3">{t("superadmin.training.button.program")}</button>
                                                        </a>
                                                    </li>
                                                    <li className={(isMobileOnly) ? `nav-item ` : `nav-item`} onClick={() => { setPersonalText("material"); getCompanyDocument(1) }}>
                                                        <a
                                                            className={
                                                                "nav-link buttons-superadmin " +
                                                                (personalText == "material" ? "active show" : "")
                                                            }
                                                            id="second-tab"
                                                            data-toggle="tab"
                                                            href="#second"
                                                            role="tab"
                                                            aria-controls="second"
                                                            aria-selected="false"
                                                            onClick={() =>
                                                                window.dispatchEvent(new Event("resize"))
                                                            }
                                                        >
                                                            <button className="buttons mr-3">{t("admin.training.material")}</button>
                                                        </a>
                                                    </li>
                                                </>
                                            </ul>

                                            <div className="ricsb super-search-top">
                                                {personalText == 'program' ?
                                                    <div className="rieplogo rieplogo-new"></div> :
                                                    <div></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content">
                                            <>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "program"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="first"
                                                    role="tabpanel"
                                                    aria-labelledby="first-tab"
                                                >
                                                    <div className="">
                                                        <div className="row mt-5 mx-0">
                                                            {(programData.length > 0 && programData) ? <>
                                                                <ToolkitProvider
                                                                    keyField="program_id"
                                                                    key={'datatable'}
                                                                    data={filterShow ? filteredData : programData}
                                                                    columns={programColumn}
                                                                    search
                                                                >{(props) => (

                                                                    <div id="test" className="table  ricsb ricsb-new protocolli-data push-table">
                                                                        <div>
                                                                            <div>
                                                                                <div className="searchbar searchbar-sa d-flex justify-content-end m-2 searchmoved-top">
                                                                                    <div style={{ position: "relative" }} className="search-mobile-box">
                                                                                        <img
                                                                                            src={Group698}
                                                                                            className=""
                                                                                            alt="icon"
                                                                                            style={{ position: "absolute", top: "18px", left: "10px" }}
                                                                                        />
                                                                                        <SearchBar
                                                                                            placeholder={t("superadmin.protocol.search")}
                                                                                            {...props.searchProps}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <BootstrapTable
                                                                                    src={VectorDown} alt="icon"
                                                                                    data={filterShow ? filteredData : programData}
                                                                                    {...props.baseProps}
                                                                                    filter={filterFactory()}
                                                                                    pagination={paginationFactory(paginationOption)}
                                                                                    wrapperClasses={'table-responsive mt-4'}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                )}
                                                                </ToolkitProvider>
                                                            </> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "material" ? "active show" : "")
                                                    }
                                                    id="second"
                                                    role="tabpanel"
                                                    aria-labelledby="second-tab"
                                                >
                                                    <div className="">
                                                        <div className="row content-section mt-2 mx-0" >
                                                            <div className="content-section-button">
                                                                <button onClick={() => { setActiveContentType("document"); getCompanyDocument(1) }} className={activeContentType === "document" ? "active" : "cursor-pointer"}>{t("superadmin.training.documents")}</button>
                                                                <button onClick={() => { setActiveContentType("video"); getCompanyVideo(1) }} className={activeContentType === "video" ? "active videoActive show" : "cursor-pointer"}>{t("superadmin.training.video")}</button>
                                                            </div>
                                                            {
                                                                activeContentType == 'document' ?

                                                                    <div className="col-md-12 pr-0 p0 ipd-righ">

                                                                        <div className="rigt-dashboard topspace ">
                                                                            <div>
                                                                                <div className=" searchbar my-2">
                                                                                    <div className="position-relative">
                                                                                        <img src={Group698} className="search-icon-user-material" alt="icon" />
                                                                                        <label htmlFor="search-bar-0" className=" d-flex">
                                                                                            <span id="search-bar-0-label" className=""></span>
                                                                                            <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="form-control" placeholder={t("superadmin.protocol.search")} type="text" value={documentSearchQuery} onChange={(e) => setDocumentSearchQuery(e.target.value)} />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                {/* {documentData && documentData.length ? */}
                                                                                <div>
                                                                                    {documentData && documentData.length ?
                                                                                        documentData.map((data, i) => {
                                                                                            //   { console.log(data.programs)}
                                                                                            return (
                                                                                                <div key={i}>
                                                                                                    <div className="d-flex document-section justify-content-between">
                                                                                                        <div className="text-left description-document description-document-new">
                                                                                                            <div className="d-sm-flex d-xl-flex d-xl-flex d-xxl-flex">
                                                                                                                <div className="icon" ><img src={(urlPattern.test(data.name) || (data.name.split('.').pop() == "html") || (data.name.split('.').pop() == "htm")) ? Group1857link : OPEN_ICON} alt="icon" onClick={(e) => { previewDoc(e, data.contentId); setCurrentDocumentProgram(data.programs) }} className="preview" /></div>
                                                                                                                <div className="text">
                                                                                                                    <div className="title-word-wrap">{data.name}</div>
                                                                                                                    {(data.description?.length > 250 && documentLoadMoreIndex != data.contentId) ?
                                                                                                                        <div className="mb-3 desc-normal" >
                                                                                                                            <p className="pb-2">{data.description?.substr(0, 250)}...</p>
                                                                                                                            <button className="show-btn-space" onClick={() => setDocumentLoadMoreIndex(data.contentId)}><span className="text-space-pd">{t("superadmin.troubleshooting.showMore")}</span></button>
                                                                                                                        </div> :
                                                                                                                        documentLoadMoreIndex == data.contentId ? <div className="mb-3 desc-normal">
                                                                                                                            {data.description}
                                                                                                                            <button className="show-btn-space" onClick={() => setDocumentLoadMoreIndex('')}><span className="text-space-pd">{t("superadmin.troubleshooting.showLess")}</span></button>
                                                                                                                        </div> :
                                                                                                                            <div className="mb-3 desc-normal" >{data.description}</div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="program-document" onClick={() => { setActiveDocument(data); setDocumentProgramsModal(true) }}>
                                                                                                            <span >{t("superadmin.troubleshooting.programs")}</span>
                                                                                                            <div className="mt-3">{data.programs.length}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        <>
                                                                                            {!isLoading ? <div className="d-flex document-section justify-content-between">
                                                                                                <div className="text-left description-document">
                                                                                                    <div className="d-flex">
                                                                                                        {t("superadmin.troubleshooting.documentNotFound")}

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> : null}
                                                                                        </>
                                                                                    }
                                                                                    {documentData && documentData.length ?

                                                                                        <div className="d-flex sa-program-pagination">
                                                                                            <ReactPaginate
                                                                                                breakLabel="..."
                                                                                                nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                                                previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                                                onPageChange={handlePageClick}
                                                                                                pageRangeDisplayed={3}
                                                                                                pageCount={documentPageCount}
                                                                                                renderOnZeroPageCount={null}
                                                                                                containerClassName="selectuser-pagination"
                                                                                                pageClassName="page-class"
                                                                                                previousClassName={currentPage == 1 ? "d-none" : ""}
                                                                                                nextClassName={currentPage == documentPageCount ? "d-none" : ""}
                                                                                                forcePage={currentPage - 1}
                                                                                            />
                                                                                        </div>
                                                                                        : null}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="col-md-12 pr-0 p0 ipd-righ">
                                                                        <div className="rigt-dashboard topspace">

                                                                            <div>
                                                                                <div className=" searchbar my-2">
                                                                                    <div className="position-relative">
                                                                                        <img src={Group698} className="search-icon-user-material" alt="icon" />
                                                                                        <label htmlFor="search-bar-0" className=" d-flex">
                                                                                            <span id="search-bar-0-label" className=""></span>
                                                                                            <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="form-control" placeholder={t("superadmin.protocol.search")} type="text" value={videoSearchQuery} onChange={(e) => setVideoSearchQuery(e.target.value)} />
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    {
                                                                                        videoData && videoData.length ?

                                                                                            videoData.map((data, i) => {
                                                                                                return (

                                                                                                    <div key={i} className="d-flex document-section justify-content-between">
                                                                                                        <div className="text-left description-document " >
                                                                                                            <div className="d-sm-flex d-xl-flex d-xl-flex d-xxl-flex">
                                                                                                                <div className="icon-image" onClick={() => getVideoContentDetails(data.contentId, data.programs)}>
                                                                                                                    {data.link ?
                                                                                                                        <div className="play-button play-btn"><img className="link" src={Group1857link} alt="" /></div> :
                                                                                                                        <div className="play-button play-btn"><img src={VideoPlayButton} alt="" /></div>
                                                                                                                    }
                                                                                                                    <img src={data.thumbnail || VIDEO_IMAGE} alt="icon" className="vimeo-icon-mbl"/>
                                                                                                                </div>
                                                                                                                <div className="text">
                                                                                                                    <div className="p-1 title-word-wrap">{data.name}</div>
                                                                                                                    {(data.description?.length > 250 && documentLoadMoreIndex != data.contentId) ?
                                                                                                                        <div className="pl-1 desc-normal" >
                                                                                                                            {/* {data.description?.substr(0, 250)}... */}
                                                                                                                            <p className="pb-2">{data.description?.substr(0, 250)}...</p>
                                                                                                                            {/* <button onClick={() => setDocumentLoadMoreIndex(data.contentId)}>show more</button> */}
                                                                                                                            <button className="show-btn-space" onClick={() => setDocumentLoadMoreIndex(data.contentId)}><span className="text-space-pd">{t("superadmin.troubleshooting.showMore")}</span></button>

                                                                                                                        </div> :
                                                                                                                        documentLoadMoreIndex == data.contentId ? <div className="pl-1 desc-normal">
                                                                                                                            {data.description}
                                                                                                                            {/* <button onClick={() => setDocumentLoadMoreIndex('')}>show less</button> */}
                                                                                                                            <button className="show-btn-space" onClick={() => setDocumentLoadMoreIndex('')}><span className="text-space-pd">{t("superadmin.troubleshooting.showLess")}</span></button>

                                                                                                                        </div> :
                                                                                                                            <div className="pl-1 desc-normal" >{data.description}</div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="program-document mbl-txt-small" onClick={() => { setActiveDocument(data); setDocumentProgramsModal(true) }}>
                                                                                                            <span >{t("superadmin.troubleshooting.programs")}</span>
                                                                                                            <div className="mt-3">{data.programs.length}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <>
                                                                                                {!isLoading ? <div className="d-flex document-section justify-content-between">
                                                                                                    <div className="text-left description-document">
                                                                                                        <div className="d-flex">
                                                                                                            {t("superadmin.troubleshooting.videoNotFound")}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div> : null}
                                                                                            </>
                                                                                    }
                                                                                    <div>
                                                                                        {
                                                                                            videoData && videoData.length ?
                                                                                                <div className="d-flex sa-program-pagination">
                                                                                                    <ReactPaginate
                                                                                                        key="paginate2"
                                                                                                        breakLabel="..."
                                                                                                        nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                                                        previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                                                        onPageChange={handlePageClickVideo}
                                                                                                        pageRangeDisplayed={3}
                                                                                                        pageCount={videoPageCount}
                                                                                                        renderOnZeroPageCount={null}
                                                                                                        containerClassName="selectuser-pagination"
                                                                                                        pageClassName="page-class"
                                                                                                        previousClassName={videoCurrentPage == 1 ? "d-none" : ""}
                                                                                                        nextClassName={videoCurrentPage == videoPageCount ? "d-none" : ""}
                                                                                                        forcePage={videoCurrentPage - 1}
                                                                                                    />
                                                                                                </div>
                                                                                                : null}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}
                </div>
            </section>
            <Sidebarmob />
        </>
    );
};

export default UserTraining;
