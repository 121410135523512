import React, { useEffect } from "react";
import { useState } from "react";
import Group1080 from "../../assets/Image/Group1080.svg";
import Sidebarmob from "../common/sidebarMobile";
import NEXTICONA from "../../assets/Image/NEXTICONA.svg";
import { Link } from "react-router-dom";
import ICONA_LIGHT1 from "../../assets/Image/ICONA_LIGHT1.svg";
import DARK_ICONA2 from "../../assets/Image/DARK_ICONA2.svg";
import progetto from "../../assets/Image/progetto.svg";
import referimenti from "../../assets/Image/referimenti.svg";
import privacypolicy from "../../assets/Image/privacy-policy.svg";
import faqicon from "../../assets/Image/faq-icon.svg";
import Logoutbox from "../../assets/Image/logoutarrow.svg";
import {isMobile} from 'react-device-detect';
import terms from '../../assets/Image/Terms.svg';
import {getLocalStoarge, removeLocalStoarge,removeLocalStoarge1, Logout} from "../../components/common/allfunction"
import { useTranslation } from "react-i18next";
import { Walktour } from "walktour";

function profilemenu() {
  const [theme, setTheme] = useState("light");
  const [toggleStatus, setToggleStatus] = useState(true)
  const [userProfile, setuserProfile] = useState("")
  const [t, i18n] = useTranslation('common');
  const [showpic, setSHowPic] = useState('default.png');
  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
    setToggleStatus(theme === "light" ? true : false);
    localStorage.setItem(
      "theme",
      JSON.stringify(theme === "light" ? "dark" : "light")
    );
  };
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    console.log(lang);
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };
 
  // const getdata = ()=>{ };
  const removeToken = ()=>{
    const logout = Logout();
    if(logout){
     window.location.href = `/app/login/${localStorage.getItem('dynamic_id')}`;
      // removeLocalStoarge(['summaryPageGuide','pageGuide']);
      removeLocalStoarge1();

    }else{
      console.log('something was wrong')
    }
  }
  

  useEffect(() => {
  document.body.className = theme;
  handleTranslationLang();
  let profiledata = getLocalStoarge('profile');
  profiledata = JSON.parse(profiledata)
  setSHowPic(((profiledata)?.profile_pic)?  profiledata.profile_pic: 'default.png');
  setuserProfile(((profiledata)?.user_name) ?  profiledata.user_name: "NO NAME");
  // getdata();
  // console.log(isMobile);
  }, [theme]);
  useEffect(() => {
    if (enablePageGuide != 1) {
      console.log('tooltip container');
      setTimeout(() => {
        const tooltipContainer = document.getElementById("walktour-tooltip-container");
        if (tooltipContainer) {
          tooltipContainer.classList.add("top-arrow");
        }
      }, 1500)

    }
  }, []);
  useEffect(() => {
    const theme1 = JSON.parse(localStorage.getItem("theme"));
    setTheme(theme1);
    if (theme1) {
      setToggleStatus(theme1 === "light" ? false : true);
    } else {
      setTheme("light");
      setToggleStatus(false)
    }

  }, []);
  const enablePageGuide = localStorage.getItem('pageGuide');
  const mobPageGuide = [
    {
      selector: "#step-mob-7",
      title: t("user.sidebar.tourguide.title7"),
      description: t("user.sidebar.tourguide.description7")
    },
    {
      selector: "#step-mob-8",
      title: t("user.sidebar.tourguide.title7"),
      description: t("user.sidebar.tourguide.description7")
    }
  ];
  const  myCustomNext = (logic: WalktourLogic) => {
    if (logic.stepIndex==0) {
        window.location.href = 'dashboard';
        return false;
    }
    if (logic.stepIndex==2) {
      $(".nav-stacked").scrollLeft(innerHeight);
    }
    logic.next();
    console.log('logic ', logic.stepIndex);
  }
  const closeTour = (logic: WalktourLogic) => {
    localStorage.setItem('pageGuide', 1)
    logic.close();
    console.log('logic ', logic.stepIndex);
  }
  return (
   <>
    {(isMobile && enablePageGuide!=1) ?  <Walktour steps={mobPageGuide} nextLabel={t("user.sidebar.nextlabel")} closeLabel={t("user.sidebar.closelabel")} customCloseFunc={closeTour} customNextFunc={myCustomNext} disableClose={0} /> :null }
    <>    
    <section className="full_bg dashboard-mobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p0 heightfull">
              <div className="right-dashboard topspace profile-right">
                <h2 className="heading-dash">{t("user.profile_menu.heading")}</h2>
                <div className="contact-heading w-100 mt-4" id="step-6" style={{textAlign :"end"}}>
                  <a href="mailto:evolvity.support@neocogita.com?subject=EVOLVITY Service Ticket/ ticket assistenza EVOLVITY.">{t("user.sidebar.contactEmail")}</a>
                    </div>  
                <p className="visi-none">{t("user.profile_menu.heading_1")}</p>
                <div className="row">
                  <div className="profile_menu">
                    {toggleStatus == true && (
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="checkbox"
                        defaultChecked
                        onChange={toggleTheme}
                      />
                    )}
                    {toggleStatus == false && (
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="checkbox"
                        onChange={toggleTheme}
                      />
                    )}
                    <label htmlFor="checkbox" className="label" id={'step-mob-7'}>
                      <div className="icco">
                        <img
                          src={ICONA_LIGHT1}
                          className="img-fluid"
                          alt="logo"
                        />
                      </div>
                      <div className="moon">
                        <img
                          src={DARK_ICONA2}
                          className="img-fluid"
                          alt="logo"
                        />
                      </div>
                      <div className="anim-dari">
                        <div className="ball"></div>
                        <div className="light"></div>
                        <div className="dark"></div>
                      </div>
                    </label>
                    {/* <div className="contact-heading w-100" id="step-6">
                  <a href="mailto:evolvity.support@neocogita.com?subject=EVOLVITY Service Ticket/ ticket assistenza EVOLVITY.">{t("user.sidebar.contactEmail")}</a>

                    </div>   */}
                  </div>
                  <div className="col-md-6 fulxs">
                    <div className="leftprofiles">
                      <div className="l-rflex">
                        <div className="profil_img">
                          <span className="circl-profile m18">
                            <img
                              src={process.env.REACT_APP_BACKEND_BASE_URL + process.env.REACT_APP_STATIC_PATH + showpic}
                              className="img-fluid"
                              alt="img"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="list-ul">
                        <ul>
                        <li>
                            <Link to="/steps">
                              <div className="divpro-menu">
                                <span className="in-iconmobile l-Progetto"></span>
                              </div>
                              {t("user.sidebarProfile.steps")}
                              <img
                                src={NEXTICONA}
                                className="arow-bullet"
                                alt="icon"
                              />
                            </Link>
                          </li>
                          <li>
                            <Link to="/privacy">
                                  {/* <img src={privacypolicy} alt="icon" /> */}
                               <div className="divpro-menu">
                                <span className="in-iconmobile privacy-mob"></span>
                               </div>
                               {t("user.sidebarProfile.privacy")}
                              <img
                                src={NEXTICONA}
                                className="arow-bullet"
                                alt="icon"
                              />
                            </Link>
                          </li>
                          <li>
                            <Link to="/terms">
                              {/* <img src={terms} alt="icon" /> */}
                               <div className="divpro-menu">
                                <span className="in-iconmobile terms-m"></span>
                               </div>
                               {t("user.sidebarProfile.t&c")}
                              <img
                                src={NEXTICONA}
                                className="arow-bullet"
                                alt="icon"
                              />
                            </Link>
                          </li>
                
                          <li>
                            <Link to="/referement-science">
                                  {/* <img src={referimenti} alt="icon" /> */}
                                  <div className="divpro-menu">
                                <span className="in-iconmobile referimentis"></span>
                              </div>
                              {t("user.sidebarProfile.referments")}
                              <img
                                src={NEXTICONA}
                                className="arow-bullet"
                                alt="icon"
                              />
                            </Link>
                          </li>
                  
                          <li>
                            <Link to="/faq">
                                  {/* <img src={faqicon} alt="icon" /> */}
                                  <div className="divpro-menu">
                                <span className="in-iconmobile faqsmob"></span>
                               </div>
                               {t("user.sidebarProfile.FAQ")}
                              <img
                                src={NEXTICONA}
                                className="arow-bullet"
                                alt="icon"
                              />
                            </Link>
                          </li>
                          <li>
                            <Link to="/profile">
                              {/* <img src={Group1080} alt="icon" /> */}
                              <div className="divpro-menu">
                                <span className="in-iconmobile"></span>
                              </div>
                              {t("user.sidebarProfile.profile")}
                              <img
                                src={NEXTICONA}
                                className="arow-bullet"
                                alt="icon"
                              />
                            </Link>
                          </li>
                 
                          <li onClick={removeToken} >
                              {/* <Link to="/"> */}
                                <div className="divpro-menu">
                                 <span className="in-iconmobile logi-out"></span>
                                </div>
                                  {t('user.sidebarProfile.logout')}
                             <img
                                src={NEXTICONA}
                                className="arow-bullet"
                                alt="icon"
                              />
                              {/* </Link> */}
                              
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Sidebarmob /> 
      </>
      :
      <>
      <div>This page is intented to view only on mobile.</div>
      </>

      </>
  );
}
export default profilemenu;
