import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Image/logo.svg";
import DOCUMENTO_ICONA from "../../assets/Image/DOCUMENTO_ICONA.svg";
import SidebarProfile from "../common/sidebarProfile";
import Sidebarmob from "../common/sidebarMobile";
import leftarrow from "../../assets/Image/leftarrow.svg";
import Group1223 from "../../assets/Image/Group1223.svg";
import Group698 from "../../assets/Image/Group698.svg";
import { Multiselect } from "multiselect-react-dropdown";
// import Group759 from "../../assets/Image/Group759.svg";
import Group1236 from "../../assets/Image/Group1236.svg";

function formazione() {
  const data = [
    { Parametri: "Parametri1" },
    { Parametri: "Parametri2" },
    { Parametri: "Parametri3" },
    { Parametri: "Parametri4" },
    { Parametri: "Parametri5" },
    { Parametri: "Parametri6" },
  ];
  const [options] = useState(data);

  return (
     <>
      <section className="bg-privacy Contenuti-bg formazione dashboard-mobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 topspace d-lfti none-viewport ipad-widhset">
              <SidebarProfile />
            </div>
            <div className="col-md-10 ipd-righ">
              <div className="top-priv-static">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="otp-heading m-bspaci mb-5">
                      <Link to="/profilemenu">
                        <img src={leftarrow} className="deskt-n" alt="icon" />
                      </Link>
                      Contenuti Formativi{" "}
                    </h2>
                  </div>

                  <div className="Contenuti-tabs">
                    <ul
                      className="nav nav-tabs nav-stacked"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="first-tab"
                          data-toggle="tab"
                          href="#first"
                          role="tab"
                          aria-controls="first"
                          aria-selected="true"
                        >
                          Tutti
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="second-tab"
                          data-toggle="tab"
                          href="#second"
                          role="tab"
                          aria-controls="second"
                          aria-selected="false"
                        >
                          Percorsi
                        </a>
                      </li>
                    </ul>
                    <div className="card-body">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="first"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          <div className="Contenuti">
                            <div className="col-md-6">
                              <Multiselect
                                displayValue="Parametri"
                                options={options}
                                placeholder="Scegli un tag..."
                                showCheckbox
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="rel-sec">
                                <input
                                  className="form-control"
                                  id="filterbtn"
                                  placeholder="Cerca"
                                />
                                <img src={Group698} alt="icon" />
                              </div>
                            </div>

                            <div className="col-md-6 b-rside">
                              <h4 className="Documenti">
                                <img src={DOCUMENTO_ICONA} alt="icon" />
                                Documenti
                              </h4>
                              <div className="bg-white-static">
                                <div className="d-flex">
                                  <div className="icon_doc">
                                    <img src={Group1223} alt="icon" />
                                  </div>
                                  <div className="align">
                                    <h5>
                                      Transdermal Optical Imaging Reveal Basal
                                      Stress via Heart Rate Variability
                                      Analysis: A Novel Methodology Comparable
                                      to Electrocardiography
                                    </h5>
                                    <p>
                                      Jing Wei, Hong Luo, Si J. Wu, Paul P.
                                      Zheng, Genyue Fu and Kang Lee
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="bg-white-static">
                                <div className="d-flex">
                                  <div className="icon_doc">
                                    <img src={Group1223} alt="icon" />
                                  </div>
                                  <div className="align">
                                    <h5>
                                      Transdermal optical imaging revealed
                                      different spatiotemporal patterns of
                                      facial cardiovascular activities
                                    </h5>
                                    <p>
                                      Jiangang Liu, Hong Luo, Paul Pu Zheng, Si
                                      Jia Wu & Kang Lee
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="bg-white-static">
                                <div className="d-flex">
                                  <div className="icon_doc">
                                    <img src={Group1223} alt="icon" />
                                  </div>
                                  <div className="align">
                                    <h5>
                                      Measuring Blood Pressure: from Cuff to
                                      Smartphone
                                    </h5>
                                    <p>Andrew Barszczyk & Kang Lee</p>
                                  </div>
                                </div>
                              </div>
                              <div className="bg-white-static">
                                <div className="d-flex">
                                  <div className="icon_doc">
                                    <img src={Group1223} alt="icon" />
                                  </div>
                                  <div className="align">
                                    <h5>
                                      Smartphone-Based Blood Pressure
                                      Measurement Using Transdermal Optical
                                      Imaging Technology
                                    </h5>
                                    <p>
                                      Hong Luo, Deye Yang, Andrew Barszczyk,
                                      Naresh Vempala, Jing Wei, Si Jia Wu, Paul
                                      Pu Zheng, Genyue Fu, Kang Lee, Zhong-Ping
                                      Feng
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 bor-spacic">
                              <h4 className="Documenti">
                                <img src={DOCUMENTO_ICONA} alt="icon" /> Video
                              </h4>
                              <div className="bg-white-static">
                                <div className="d-flex">
                                  <div className="icon_doc">
                                    <iframe
                                      width="200"
                                      height="auto"
                                      src="https://www.youtube.com/embed/zSguDQRjZv0"
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                  </div>
                                  <div className="align">
                                    <h5>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit. Tellus volutpat
                                      ullamcorper pulvinar eget. Sapien.
                                    </h5>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="bg-white-static">
                                <div className="d-flex">
                                  <div className="icon_doc">
                                    <iframe
                                      width="200"
                                      height="auto"
                                      src="https://www.youtube.com/embed/zSguDQRjZv0"
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                  </div>
                                  <div className="align">
                                    <h5>
                                      Lorem ipsum dolor sit amet, consectetur
                                      adipiscing elit. Tellus volutpat
                                      ullamcorper pulvinar eget. Sapien.
                                    </h5>
                                    <p>
                                      Lorem ipsum dolor sit amet, consectetur.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="second"
                          role="tabpanel"
                          aria-labelledby="second-tab"
                        >
                          <div className="Contenuti">
                            <div className="col-md-6">
                              <Multiselect
                                displayValue="Parametri"
                                options={options}
                                placeholder="Scegli un tag..."
                                showCheckbox
                              />
                            </div>
                            <div className="col-md-6">
                              <div className="rel-sec">
                                <input
                                  className="form-control"
                                  id="filterbtn"
                                  placeholder="Cerca"
                                />
                                <img src={Group698} alt="icon" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 b-rside">
                             <div class="accordion" id="accordionExample">
                                <div className="row">
                                  <div className="card mb-0">
                                    <div className="bot-lini">
                                      <div
                                        className="card-header collapse active show"
                                        data-toggle="collapse"
                                        href="#collapseOne"
                                        aria-expanded="true"
                                      >
                                        <a className="card-title">
                                          <div className="secoheadfor">
                                            <h4>
                                              {" "}
                                              <img
                                                src={Group1236}
                                                alt="icon"
                                              />{" "}
                                              Percorso 1
                                            </h4>
                                            <p>
                                              Transdermal Optical Imaging Reveal
                                              Basal Stress via Heart Rate
                                              Variability Analysis: A Novel
                                              Methodology Comparable to
                                              Electrocardiography
                                            </p>
                                          </div>
                                        </a>
                                      </div>
                                      <div
                                        id="collapseOne"
                                        className="card-body collapse active show"
                                        data-parent="#accordion"
                                      >
                                        <div className="bg-white-static">
                                          <div className="d-flex">
                                            <div className="icon_doc">
                                              <img src={Group1223} alt="icon" />
                                            </div>
                                            <div className="align">
                                              <h5>
                                                Transdermal Optical Imaging
                                                Reveal Basal Stress via Heart
                                                Rate Variability Analysis: A
                                                Novel Methodology Comparable to
                                                Electrocardiography
                                              </h5>
                                              <p>
                                                Jing Wei, Hong Luo, Si J. Wu,
                                                Paul P. Zheng, Genyue Fu and
                                                Kang Lee
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bg-white-static">
                                          <div className="d-flex">
                                            <div className="icon_doc">
                                              <iframe
                                                width="200"
                                                height="auto"
                                                src="https://www.youtube.com/embed/zSguDQRjZv0"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen
                                              ></iframe>
                                            </div>
                                            <div className="align">
                                              <h5>
                                                Lorem ipsum dolor sit amet,
                                                consectetur adipiscing elit.
                                                Tellus volutpat ullamcorper
                                                pulvinar eget. Sapien.
                                              </h5>
                                              <p>
                                                Lorem ipsum dolor sit amet,
                                                consectetur.
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="bg-white-static">
                                          <div className="d-flex">
                                            <div className="icon_doc">
                                              <img src={Group1223} alt="icon" />
                                            </div>
                                            <div className="align">
                                              <h5>
                                                Transdermal optical imaging
                                                revealed different
                                                spatiotemporal patterns of
                                                facial cardiovascular activities
                                              </h5>
                                              <p>
                                                Jiangang Liu, Hong Luo, Paul Pu
                                                Zheng, Si Jia Wu & Kang Lee
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                     </div>
                                   </div>
                                 </div>
                                </div>
                              </div>
                            <div className="col-md-6 bor-spacic">
                               <div id="accordion" className="accordion">
                                 <div className="bot-lini">
                                   <div
                                    className="card-header collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseTwo"
                                    aria-expanded="true"
                                  >
                                    <a className="card-title">
                                      <div className="secoheadfor">
                                        <h4>
                                          {" "}
                                          <img
                                            src={Group1236}
                                            alt="icon"
                                          />{" "}
                                          Percorso 2
                                        </h4>
                                        <p>
                                          Transdermal Optical Imaging Reveal
                                          Basal Stress via Heart Rate
                                          Variability Analysis: A Novel
                                          Methodology Comparable to
                                          Electrocardiography
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                  <div
                                    id="collapseTwo"
                                    className="card-body collapse"
                                    data-parent="#accordion"
                                  >
                                      <p>Text</p>
                                      </div>
                                    </div>
                                                              
                                   <div className="bot-lini">
                                   <div
                                    className="card-header collapsed"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseThree"
                                    aria-expanded="true"
                                  >
                                    <a className="card-title">
                                      <div className="secoheadfor">
                                        <h4>
                                          <img
                                            src={Group1236}
                                            alt="icon"
                                          />{" "}
                                          Percorso 3
                                        </h4>
                                        <p>
                                          Transdermal Optical Imaging Reveal
                                          Basal Stress via Heart Rate
                                          Variability Analysis: A Novel
                                          Methodology Comparable to
                                          Electrocardiography
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                  <div
                                    id="collapseThree"
                                    className="card-body collapse"
                                    data-parent="#accordion"
                                  >
                                    <p>Text</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       </section>
       <div className="act-ve">
      <Sidebarmob />
      </div>
    </>
  );
}

export default formazione;
