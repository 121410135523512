import password from "../../../assets/Image/password.svg";
import hidePassword from "../../../assets/Image/hidePassword.svg";
import showPassword from "../../../assets/Image/showPassword.svg";
import logo from "../../../assets/Image/logo.svg";
import { Link } from "react-router-dom";
import Group1027 from "../../../assets/Image/Group1027.svg";
import { useState, useEffect } from "react";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { setLocalStoarge, getLocalStoarge,removeLocalStoarge1,Logout } from "../../common/allfunction";
import CHEVRON_ICONA_DOWN from '../../../assets/Image/CHEVRON_ICONA_DOWN.svg';
import CHEVRON_ICONA_UP from '../../../assets/Image/CHEVRON_ICONA_UP.svg';
import { useTranslation } from "react-i18next";
import PutRequest from "../../apiConnection/putRequest";

const Updatepassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValue] = useState({
    password: "",
    confirmPassword: "",
  });
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [validation, setValidation] = useState({
    password: "",
    confirmPassword: "",

  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("it");
  const [t, i18n] = useTranslation("common");
  const [company_name, setCompanyName] = useState('');

  useEffect(() => {
    handleTranslationLang();
    setAlerts("", "", "", false);
    setValidation({
      password: "",
      confirmPassword: ""
    });
  }, [selectedLanguage, company_name])
  const removeToken = () => {
    const logout = Logout();
    if (logout) {
      window.location.href = `/app/login/${localStorage.getItem('dynamic_id')}`;
      removeLocalStoarge1();
    } else {
      console.log("something was wrong");
    }
  };


  //handle submit updates
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    let Passregex = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    if((!event.target.value) || (!Passregex.test(event.target.value)) || (event.target.value.length < 8) || (event.target.value.length > 20)){
      setAlerts({
        alert: t('user.register.pwd-valid'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      return false;
    }
    else {
      setAlerts({
        alert: t('user.register.pwd-combination-correct'),
        alerttype: "Success",
        alertclass: "success",
        showalert: true,
      });
      return false;
    }
  
  };
  const handleChange1 = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
   let Passregex1 = new RegExp(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
    );
    if((!event.target.value) || (!Passregex1.test(event.target.value)) || (event.target.value.length < 8) || (event.target.value.length > 20)){
      setAlerts({
        alert: t('user.register.pwd-valid-confirm'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      return false;
    }
    else if(inputValues.password != event.target.value)
      {
        setAlerts({
        alert: t('admin.profile.confirm_password_error'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
        });
        return false;
      }
    else {
      setAlerts({
        alert: t('user.register.pwd-combination-correct'),
        alerttype: "Success",
        alertclass: "success",
        showalert: true,
      });
      return false;
    }
   
    // setValidation({
    //   password: "",
    //   confirmPassword: "",
    // });
  };

  // password eye icon
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealPwd1, setIsRevealPwd1] = useState(false);


  const do_updatePassword = async (e) => {
    e.preventDefault();
    const { password, confirmPassword} = inputValues;
    if (password == "" || confirmPassword == "") {
      setAlerts({
        alert: t('user.login.all_field'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      return false;
    }

    if(password!==confirmPassword) {
      setAlerts({
        alert: t('admin.profile.confirm_password_error'),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      return false;
    }
   
      let confirm_password=inputValues.confirmPassword;
      try {
        setIsLoading(true);
        const refreshToken = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
          {},
          "",
          "refresh_token"
      );
      if(refreshToken)
        {
          const payload = {
              password,
              confirm_password              
            };
            const responseResult = await PutRequest(
              `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_PASSWORD}`,
              payload
            );
            if (responseResult && responseResult.status === 200) {
              if (responseResult.data.code === 200) {
                setIsLoading(false);
                setAlerts({
                  alert: t('user.updatepassword.update'),
                  alerttype: "Success",
                  alertclass: "success",
                  showalert: true,
                });
                setTimeout(()=>{
                  removeToken();
                },500)
              }
            } 
            else {
              setIsLoading(false);
              setAlerts({
                alert: t('user.register.pwd-not-updated'),
                alerttype: "Error",
                alertclass: "danger",
                showalert: true,
              });
            }
        }
        else{
          setIsLoading(false);
          setAlerts({
            alert: t('user.register.pwd-not-updated'),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
        }
      } catch (error) {
        setIsLoading(false);
        setAlerts({
          alert: t('user.register.pwd-not-updated'),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
      }
   };

  const handleTranslationLang = () => {
    let lang = getLocalStoarge("language");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  const toggleButton = (e) => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleLanguageChange = (language) => {
    setLocalStoarge("language", language)
    setSelectedLanguage(language);
  };


  return (

    <div className="bg-imgmobile">
      <section className="m-top25m newccount commonipadbg">
        <div className="d-flex justify-content-end p-4 mr-3 align-item-center mt-0">
          <div className="dropdown">
            <button
              type="button"
              className="dropdown-toggle bg-none"
              onClick={(e) => toggleButton(e)}
            >
              {t("user.login.language")}
              {(!isDropdownOpen) ? <img src={CHEVRON_ICONA_DOWN} className="ml-2" alt="img" /> : <img src={CHEVRON_ICONA_UP} className="ml-2" alt="img" />}
            </button>
            <div className="drop-down-menu-items">
              <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                <p className="ml-2 p-0 mb-0 switch-para">
                  <input type="radio" name="language" className="cursor-pointer" id="italianRadio"
                    checked={selectedLanguage === 'it'}
                    onChange={() => handleLanguageChange('it')} />
                  <label className="ml-1 cursor-pointer" htmlFor="italianRadio">Italiano</label>
                </p>
                <p className="ml-2 p-0 mb-0 switch-para">
                  <input type="radio" name="language" className="cursor-pointer" id="englishRadio"
                    checked={selectedLanguage === 'en'}
                    onChange={() => handleLanguageChange('en')} />
                  <label className="ml-1 cursor-pointer" htmlFor="englishRadio">English</label>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="logo-w">
            {/* <Link to={`/login/${localStorage.getItem('dynamic_id')}`}> */}
              <img src={logo} className="logo" alt="logo" />
            {/* </Link> */}
          </div>
          <div className="row top_30">
            <div className="forgot">
              <div className="icon-aut">
                <img src={Group1027} className="key" alt="icon" />
                {/* <img src={Group1015} className="key" alt="icon"/> */}
                <h2 className="otp-heading">{t('user.updatepassword.update')}</h2>
                <p>{t('user.register.instructions11')}</p>
                <form
                  method="POST"
                  className="registrati-form"
                  onSubmit={(e) => do_updatePassword(e)}
                >
                  {showalert ? (
                    <div className={`alert alert-${alertclass}`}>{alert}</div>
                  ) : (
                    <></>
                  )}

                  <div className="form-group relative">
                    <img src={password} alt="icon" className="icon_left" />
                    <input
                      type={isRevealPwd ? "text" : "password"}
                      placeholder={t('user.register.pwd-enter')}
                      className="form-control"
                      onChange={handleChange}
                      name="password"
                      defaultValue={inputValues.password}
                    />
                    <img src={isRevealPwd ? hidePassword : showPassword}
                      onClick={() => setIsRevealPwd(prevState => !prevState)} alt="icon"
                      className="eye_icon_right" />
                    {validation.password && (
                      <p className="danger password-validation">{validation.password}</p>
                    )}
                  </div>

                  <div className="form-group relative">
                    <img src={password} alt="icon" className="icon_left" />
                    <input
                      // type="password"
                      type={isRevealPwd1 ? "text" : "password"}
                      placeholder={t('user.register.confirm-pwd-enter')}
                      className="form-control"
                      onChange={handleChange1}
                      name="confirmPassword"
                      defaultValue={inputValues.confirmPassword}
                    />
                    <img src={isRevealPwd1 ? hidePassword : showPassword}
                      onClick={() => setIsRevealPwd1(prevState1 => !prevState1)} alt="icon"
                      className="eye_icon_right" />
                    {validation.confirmPassword && (
                      <p className="danger password-validation">{validation.confirmPassword}</p>
                    )}
                  </div>

                  <small id="emailHelp" className="form-text11 mb-10">
                    {t('user.register.validation')}
                  </small>

                  <div className="align-checkboxs">
                  </div>
                  <br />
                  <button type="submit" className="buttons">
                    {t('user.register.proceed')}
                  </button>


                </form>
              </div>
            </div>
          </div>
          {(isLoading) ? <LazyLoader /> : null}
        </div>
      </section>
      <p className="Powered">{t('user.register.powered')}</p>
    </div>
  );
};

export default Updatepassword;
