import { useState, useEffect } from "react";

import logo from "../../../assets/Image/logo.svg";
import { Link } from "react-router-dom";
import { api_axios } from '../../apiConnection/axios';
import PostRequest from "../../apiConnection/postRequest";
const Confirm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alerts, setAlerts] = useState({
    alert: '',
    alerttype: '',
    alertclass: 'danger',
    showalert: false,
  });
  const {showalert, alert, alerttype, alertclass} = alerts;
  const [token, setToken] = useState(props.match.params.token);
  useEffect(()=>{
    do_Confirm();
  },[token])
  

  const do_Confirm = async () => {
    setAlerts({
      alert: 'Per favore aspetta per un po.',
      alerttype: 'Info',
      alertclass: 'info',
      showalert: true,
    })
    if (token == "" || token == undefined) {
      setAlerts({
        alert: 'Invalid or Expired link.',
        alerttype: 'Error',
        alertclass: 'danger',
        showalert: true,
      })
      return false;
    } else if ((!token) || (token.length!==36)) {
      setAlerts({
        alert: 'Invalid or Expired link.',
        alerttype: 'Error',
        alertclass: 'danger',
        showalert: true,
      })
      return false;
    } else {
      
            const payload = {
              token
            }
            try {
            const confirmUser = await PostRequest(
              `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_CONFIRM}`,
              payload
            );
            if (confirmUser && confirmUser.status === 200) {
              if (confirmUser.data.code === 200) {
                setIsLoading(false);
                    setAlerts({
                      alert: 'Il tuo account è stato attivato correttamente, attendi durante il reindirizzamento per accedere.',
                      alerttype: 'Success',
                      alertclass: 'success',
                      showalert: true,
                    })
                    setTimeout(() => { window.location.href = `/app/login/${props.match.params.company_id}` }, 7000);
                  } else {
                    setIsLoading(false);
                    setAlerts({
                      alert: confirmUser.data.message,
                      alerttype: 'Error',
                      alertclass: 'danger',
                      showalert: true,
                    })
                  }
            } else if (confirmUser && confirmUser.status === 203){
              setIsLoading(false);
              setAlerts({
                alert: confirmUser.data.message,
                alerttype: 'Error',
                alertclass: 'danger',
                showalert: true,
              })
            }
           } catch(error) {
      setAlerts({
        alert: 'Internal server error.',
        alerttype: 'Error',
        alertclass: 'danger',
        showalert: true,
      })
    }
  }
}
  return (
    <section className="mid-align bg-imgmobile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 bg-image relative">
            <div className="heading-login text-center mb-3 none-m">
              <div className="logo-w">
                <Link to='/login'>
                  <img src={logo} className="logo hide-only" alt="logo" />
                </Link>
              </div>
              <p className="mb80">
              {(showalert) ? (
                  <div className={`alert alert-${alertclass}`}>
                    {alert}
                  </div>
                ) : (<></>)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Confirm;
