import React from "react";
import { useState, useEffect, useRef } from "react";
import { Walktour } from "walktour";
import shape from "../../assets/Image/shape.svg";
import VectorDown from "../../assets/Image/VectorDown.svg";
import Group698 from "../../assets/Image/Group698.svg";
import Group733 from "../../assets/Image/Group733.svg";
import NEXTICONA from "../../assets/Image/NEXTICONA.svg";
import DOCUMENTO_ICONA_PDF from "../../assets/Image/DOCUMENTO_ICONA_PDF.svg";
import Group769 from "../../assets/Image/Group769.svg";
import PDF_DOWNLOAD from "../../assets/Image/pdf_download.svg";
import Sidebar from "../common/sidebar.jsx";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Sidebarmob from "../common/sidebarMobile";
import PostRequest from "../apiConnection/postRequest";
import GetRequest from "../apiConnection/getRequest";
import LazyLoader from "../common/lazyLoader";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from "../common/allfunction";
import { isDesktop, isMobile } from 'react-device-detect';
import Modal from "../common/modal";
import { Link } from "react-router-dom";

function Summary(props) {
  const ref = useRef(null);
  const [t, i18n] = useTranslation('common');
  const [text, setText] = useState(t("user.summary.btn_text"));
  const [isLoading, setIsLoading] = useState(false);
  const [selection, setSelection] = useState(0);
  const [vector, setVector] = useState({ VectorDown });
  const [reportData, setReportData] = useState([]);
  const [reportDatatutti, setReportDatatutti] = useState([]);
  const [reportDataColumn, setReportDataColumn] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [controlValue, setControlValue] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [shownumber, setSHowNumber] = useState([]);
  const [heartRate, setHeartRate] = useState(0);
  const [filterValueData, setfilterValueData] = useState([]);
  const [tutikeys, setTutikeys] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [summaryParam, setSummaryParam] = useState([]);
  const [pagenumber, setPagenumber] = useState(1);
  const [reachMaxNumber, setReachMaxNumber] = useState(true);
  const [reachMinNumber, setReachMinNumber] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [pdfPopUp, setPdfPopUp] = useState(false);
  const [pdfName, setPdfName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
  const [indexArr,setIndexArr]= useState([]);
  // const [buttonDisable, setButtonDisable] = useState(true);
  const [fineBtn, setFineBtn] = useState(false)
  const [user, setUser] = useState('')
  const userLanguage = getLocalStoarge("langauage");

  useEffect(() => {
    handleTranslationLang();
    getMaterialTag();
  }, []);

  useEffect(() => {
    getAllData(false, []);
  }, [pagenumber]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (filterShow && ref.current && !ref.current.contains(e.target)) {
        setFilterShow(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [filterShow]);
  const closeModal = () => {
    setPdfPopUp(false)
    setAlertMessage({ show: false, spinner: false, message: "", class: "" });
    setUserEmail("");
    setUserMessage("");
    removePdfReport();
    // setButtonDisable(true);
    setFineBtn(false)

  }

  const changeText = () => {
    setText(!text);
    if (controlValue) {
      setControlValue(false);
    } else {
      setControlValue(true);
    }
  };

  const updateFilterSlice = (selectedoption) => {
    setSelection(selectedoption);
  };


  const handleChange = (e) => {
    let Emailregex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    const email = e.target.value;
    if (!email.match(Emailregex) && email != '') {
      setAlertMessage({ show: true, spinner: false, message: `${t("user.login.email_not_valid")}`, class: "alert alert-danger" });
      setFineBtn(true);
    } else if (email == '' || !email) {
      setAlertMessage({ show: false, spinner: false, message: "", class: "" });
      setFineBtn(false)
      setUserEmail(e.target.value);
    }
    else {
      setAlertMessage({ show: false, spinner: false, message: "", class: "" });
      setFineBtn(false)
      setUserEmail(e.target.value);
    }
  }
  const handleChangeTextArea = (e) => {
    setUserMessage(e.target.value);

  }

  const pageup = () => {
    const page = pagenumber + 1;
    setPagenumber(page);
    // renderValues(completeData, page, 6);
  }
  const pagedown = () => {
    const page = ((pagenumber - 1) < 1) ? 1 : (pagenumber - 1);
    setPagenumber(page);
    // renderValues(completeData, page, 6);
  }
  const getMaterialTag = async () => {
    const getAllGroup = await GetRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GROUP_USER_REPORT}`)

    if (getAllGroup.status === 200) {
      // console.log('getAllDataResult ', getAllGroup.data.data);
      setSummaryParam([getAllGroup.data.data]);
    }

  }
  const renderValues = (data, page, offset, summaryOrder) => {
    setReachMaxNumber(false);
    setReachMinNumber(false);
    let pageno = (page) ? page : 1;
    let offsetcount = (offset) ? offset : 6;
    let maxValueonPage = (offsetcount * pageno) - 1;
    if (pageno === 1) {
      setReachMinNumber(true);
    }
    let temparray = [];
    let tempArrArrange = [];
    let redarray = [];
    let tempArrColumn = [
      {
        text: t('user.summary.Parmeter_col1'),
        dataField: "paramFullname",
        // sort: true,
      },
      {
        text: t('user.summary.color_col2'),
        dataField: "color_code",
        sort: false,
      },
    ];
    let j = 0;
    let filterValueDataTemp = [];
    let dangerParam = [];
    // console.log('summaryParam - ', summaryOrder);
   
    data.forEach((element) => {
      j++;

      let copyElement = [];
      if (element?.parameter != "time_stamp") {

        const filterObj = {
          id: j,
          name: element.paramFullname,
          key: element.parameter,
          value: element.parameter,
        };
        filterValueDataTemp.push(filterObj);
        const color_was = element.color_code;
        let s = '<span class="' + element.color_code + ' circle"></span>';
        element.color_code = Parser(s);
        let jsonObject = element;
        let keyCount = Object.keys(jsonObject).length;
        let maxValueall = keyCount - 3;
        var from = offsetcount * (pageno - 1);
        var to = (offsetcount * pageno);
        if ((maxValueonPage + 1) >= maxValueall) {
          to = maxValueall;
          setReachMaxNumber(true);
        }
        for (let i = to; i > from; i--) {
          const tempDate = "mis" + (parseInt(i));
          const tempDatePrev = "mis" + parseInt(i + 1);
          for (let key in element) {
            if (key === tempDate) {
              copyElement[key] = element[key];
              if (
                parseFloat(copyElement[tempDatePrev]) <
                parseFloat(element[key])
              ) {
                element[key] = Parser(
                  `<span class="arrow_up">` + element[key] + `</span>`
                );
              } else if (
                parseFloat(copyElement[tempDatePrev]) >
                parseFloat(element[key])
              ) {
                element[key] = Parser(
                  `<span class="arrow_down">` + element[key] + `</span>`
                );
              } else {
                element[key] = Parser(
                  `<span>` + element[key] + `</span>`
                );
              }
            }
          }
        }
        element.color = color_was;
        temparray.push(element);
        if (color_was === "red" || color_was === "yellow") {
          redarray.push(element);
        }
        
      }
      else {

        let jsonObject = element;
        let keyCount = Object.keys(jsonObject).length;
        let maxValueall = keyCount - 3;
        var from = offsetcount * (pageno - 1);
        var to = (offsetcount * pageno) - 1;
        if ((maxValueonPage + 1) >= maxValueall) {
          to = maxValueall;
        }
        for (let i = to; i >= from; i--) {
          const tempDate = "mis" + (parseInt(i) + 1);
          tempArrColumn.push({
            text: formatDate(element[tempDate]),
            dataField: "mis" + (parseInt(i) + 1),
            sort: false,
          });
        }
      }
      filterValueDataTemp.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      setfilterValueData(filterValueDataTemp);
      setTutikeys(dangerParam);
    });
    redarray.sort((a, b) =>
      a.color > b.color ? 1 : b.color > a.color ? -1 : 0
    );
    /**
     * Arrange Array according to the summary order 
     */
    let cssIndex = 0
    let cssIndexArr = [];
    for (let category in summaryOrder) {
      // console.log("Category:", category);
      let categoryData = summaryOrder[category];
     
      // Loop through the properties of each category
      for (let property in categoryData) {
        tempArrArrange.push(findParameter(temparray,property))
        cssIndex++;
       
      }
      
      cssIndexArr.push(cssIndex)
    }
    setIndexArr(cssIndexArr)
    // console.log('index css ',cssIndexArr)
    let tempRevArray = tempArrColumn.slice(0, 2);
    let tempRevArrayColumn = tempArrColumn.slice(Math.max(tempArrColumn.length - 6, 2))
    tempRevArray = tempRevArray.concat(tempRevArrayColumn.reverse())
    // console.log('temparray  -- 1 ', tempArrArrange);
    setReportDatatutti(redarray);
    setReportData(tempArrArrange);
    // setReportData(temparray);
    setReportDataColumn(tempRevArray);
  }
  const findParameter= (arrangedData, parameterName) => {
    return arrangedData.find(item => item.parameter === parameterName);
  }
  const getAllData = async (control_value, filter_val) => {
    const payload = {
      filter: filter_val,
      control_value: control_value,
    };

    setIsLoading(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        "",
        "refresh_token"
      );
      if (refreshToken) {
        const getAllDataResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DATE_WISE_USER_REPORT}`,
          payload,
          "",
          ""
        );

        if (getAllDataResult && getAllDataResult.status === 200) {
          const getAllGroup = await GetRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GROUP_USER_REPORT}`)

          if (getAllGroup.status === 200) {
            // console.log('getAllDataResult ', getAllGroup.data.data);
            const summaryOrder = getAllGroup.data.data;
            // console.log('getAllDataResult 1', summaryParam);
            setIsLoading(false);
            let data1 = getAllDataResult.data.data.report_data;
            let heartVal = getAllDataResult.data.data.health_score ? parseFloat(getAllDataResult.data.data.health_score).toFixed(0) : "0";
            setCompleteData(data1);
            let newData = data1[20];
            for (const k in newData) {
              if (newData[k] === 0) {
                newData[k] = (userLanguage == "it") ? "ND" : "NA";
              }
            }

            let newData1 = data1[21];
            for (const k in newData1) {
              if (newData1[k] === 0) {
                newData1[k] = (userLanguage == "it") ? "ND" : "NA";
              }
            }

            setHeartRate(heartVal);
            if (isMobile) {
              renderValues(data1, pagenumber, 2, summaryOrder);
            }
            else {
              renderValues(data1, pagenumber, 6, summaryOrder);
            }
          }

        } else if (getAllDataResult && getAllDataResult.status === 203) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };


  const generatePdfReport = async () => {
    let user_profile_name = JSON.parse(getLocalStoarge("profile"));
    setUser(user_profile_name.user_name);
    setIsLoading(true);
    try {
      // const refreshToken = await PostRequest(
      //   `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      //   {},
      //   "",
      //   "refresh_token"
      // );
      // if (refreshToken) {
      const getPdfReport = await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GENERATE_PDF_REPORT}`
      );

      if (getPdfReport && getPdfReport.status === 200) {
        setIsLoading(false);
        setPdfPopUp(true);
        let fineData = getPdfReport.data.data;
        setPdfName(fineData);
        // setButtonDisable(false);

      } else if (getPdfReport && getPdfReport.status === 203) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
      // } else {
      //   setIsLoading(false);
      // }
    } catch (error) {
      setIsLoading(false);
    }
  };


  const emailPdfReport = async () => {
    setIsLoading(true);
    let companyUniqueId = getLocalStoarge("dynamic_id");
    const emailPayload = {
      email: userEmail,
      text: userMessage,
      filename: pdfName,
      filePath: process.env.REACT_APP_BACKEND_BASE_URL + process.env.REACT_APP_DOWNLOAD_PATH + '/' + pdfName,
      company : companyUniqueId
    }
    setTimeout(() => {
      setPdfPopUp(false);
      setIsLoading(false);
      setAlertMessage({ show: false, spinner: false, message: "", class: "" });
    }, 2000)

    setAlertMessage({ show: true, spinner: true, message: `${t("user.login.pdf_email_sending")}`, class: "alert alert-info" });

    try {
      // const refreshToken = await PostRequest(
      //   `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      //   {},
      //   "",
      //   "refresh_token"
      // );
      // if (refreshToken) {
      const emailResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EMAIL_PDF_REPORT}`,
        emailPayload,
        "",
        ""
      );

      if (emailResult && emailResult.status === 200) {

        // setAlertMessage({ show: true, spinner: false, message: `${t("user.login.pdf_email_sending")}`, class: "alert alert-success" });    
        // setTimeout(()=>{
        //   setPdfPopUp(false);
        closeModal();
        // },2000)

      } else if (emailResult && emailResult.status === 203) {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setAlertMessage({ show: false, spinner: false, message: "", class: "" });
      }
      // } else {
      //   setIsLoading(false);
      // }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const removePdfReport = async () => {
    const removeFilePayload = {
      filename: pdfName
    }
    setIsLoading(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        "",
        "refresh_token"
      );
      if (refreshToken) {
        const removeFileResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REMOVE_PDF_REPORT}`,
          removeFilePayload,
          "",
          ""
        );

        if (removeFileResult && removeFileResult.status === 200) {
          setIsLoading(false);
          setPdfName("");

        } else if (removeFileResult && removeFileResult.status === 203) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const formatDate = (date) => {
    var d = new Date(date * 1000),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("-");
  };

  const { SearchBar } = Search;

  const changeFilter = () => {
    setFilterShow(!filterShow);
    setText(t("user.summary.btn_text"));
    setControlValue(false);
  };

  const onChangeFilter = (e) => {
    const value = e.target.value;
    const status = e.target.checked;
    if (status) {
      let temp = filterValue;
      temp.push(value);
      setFilterValue(temp);
    } else {
      let temp1 = filterValue;
      temp1.pop(value);
      setFilterValue(temp1);
    }
    refreshTable();
  };
  const numbtable = (e) => {
    let numbdata = [];
    // const value = e.target.value;
    reportData.forEach((i) => {
      for (let i = 0; i <= 10; i++) {
        numbdata.push(i);
      }
    });
    setSHowNumber(numbdata);
  };
  const refreshTable = () => {
    let tempData = [];
    reportData.forEach((element) => {
      if (filterValue.includes(element.parameter)) {
        tempData.push(element);
      }
    });
    setFilteredData(tempData);
  };
  const resetFilters = () => {
    setFilterValue([]);
    refreshTable();
    numbtable();
    setFilterShow(false);
  };
  const onSearch = () => {
    setControlValue(false);
    let tempData = [];
    reportData.forEach((element) => {
      if (filterValue.includes(element.parameter)) {
        tempData.push(element);
      }
    });
    setFilteredData(tempData);
    getAllData(false, filterValue);
  };


  // https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Pagination&selectedStory=Custom%20Page%20List&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
  const pageListRenderer = ({ pages, onPageChange }) => {
    const pageWithoutIndication = pages.filter(
      (p) => typeof p.page === "string"
    );
    return (
      <>
         
        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 inline-text relative">
          {pageWithoutIndication.map((p) =>
            p.page === ">" ? (
              <div className="left-side">
                <div
                  className="text-right Prossimo prom-non visi-none textcolor"
                  onClick={() => onPageChange(p.page)}
                >
                  {t('user.summary.next')} <img src={NEXTICONA} alt="icon" />
                </div>
              </div>
            ) : (
              <div className="right-side">
                <div
                  className="text-left Prossimo prom-non visi-none textcolor"
                  onClick={() => onPageChange(p.page)}
                >
                  <img className="arrowLeft" src={NEXTICONA} alt="icon" />{" "}
                  {t('user.summary.back')}
                </div>
              </div>
            )
          )}
        </div>
      </>
    );
  };


  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }
  const options = {
    pageListRenderer,
  };

  const pdfEmailPopUp = () => {
    return (
      <div className="model-pop-new " >
        <div className="model-pop ">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <strong className="">{t('user.summary.title')} {user}</strong>
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => closeModal()}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <p>{t('user.summary.email_msg')}</p>
          <div className="modal-body scroll-pop scroll-pop-new">
            <div className="" >
              <form className="registrati-form registrati-form-new nuovo-programma">
                <div className="form-group">
                  <div>
                    {/* <label className="form-label">{t('user.summary.send_report_to')}</label> */}
                    <span><strong>{t('user.summary.send_report_to')}</strong></span>
                    <input type="text" className="form-control place-opacity mt-2" name="title" placeholder={t('user.summary.email_example')} onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="mt-3">
                    {/* <label className="form-label">{t('user.summary.add_comment')}</label> */}
                    <span><strong>{t('user.summary.add_comment')}</strong></span>
                    <textarea type="text" name="description" className="form-control place-opacity mt-2" placeholder="Text" onChange={handleChangeTextArea}
                    />
                  </div>
                  <div className="mt-5">
                    <span className="form-control mt-2 warning-text"
                      dangerouslySetInnerHTML={{
                        __html: t("user.summary.warning"),
                      }}
                    ></span>
                  </div>
                </div>
              </form>
            </div>
            {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
              {alertMessage.spinner ? <div className="spinner-container-new">
                <div className="loading-spinner-new">
                </div>
              </div> : null}
              <div className="ml-1">{alertMessage.message}</div>
            </div> : null}
            <div className="d-flex  justify-content-between buttons-superadmin mt-5">
              <div>
                <a href={process.env.REACT_APP_BACKEND_BASE_URL + process.env.REACT_APP_DOWNLOAD_PATH + '/' + pdfName} className={`buttons modal-button mobile-btn-email enable-btn mr-2`} download="Report.pdf" target="_blank" rel="noreferrer"><img src={PDF_DOWNLOAD} alt="icon" />{t('user.summary.save')}</a>
                <a href={process.env.REACT_APP_BACKEND_BASE_URL + process.env.REACT_APP_DOWNLOAD_PATH_VIEW + '/' + pdfName} className={`buttons modal-button mobile-btn-email enable-btn`} target="_blank" ><img src={DOCUMENTO_ICONA_PDF} alt="icon" /> {t('user.summary.press')}</a>
              </div>
              <div>
                <button className={`buttons modal-button mobile-btn-email ${(fineBtn) ? 'disable-btn' : 'enable-btn'}`} disabled={fineBtn} onClick={() => { emailPdfReport() }}>{t('user.summary.send_email')}</button>
              </div>
            </div>


          </div>
        </div>
      </div>
    )
  }
  const mobilePageGuide = [
    { selector: "#step-summary-1", title: t("user.sidebar.summarytour.title1"), description: t("user.sidebar.summarytour.description1") },
    { selector: "#step-summary-2", title: t("user.sidebar.summarytour.title2"), description: t("user.sidebar.summarytour.description2") },
    { selector: "#step-summary-m3", title: t("user.sidebar.summarytour.title3"), description: t("user.sidebar.summarytour.description3") },
    { selector: "#step-summary-m4", title: t("user.sidebar.summarytour.title4"), description: t("user.sidebar.summarytour.description4") }
  ];
  const pageGuide = [
    { selector: "#step-summary-1", title: t("user.sidebar.summarytour.title1"), description: t("user.sidebar.summarytour.description1") },
    { selector: "#step-summary-2", title: t("user.sidebar.summarytour.title2"), description: t("user.sidebar.summarytour.description2") },
    { selector: "#step-summary-3", title: t("user.sidebar.summarytour.title3"), description: t("user.sidebar.summarytour.description3") },
    { selector: "#step-summary-4", title: t("user.sidebar.summarytour.title4"), description: t("user.sidebar.summarytour.description4") }
  ];
  const enablePageGuide = localStorage.getItem('summaryPageGuide');
  const noOfScan = localStorage.getItem('noOfScan');
  const myCustomNext = (logic: WalktourLogic) => {
    const tooltipContainer = document.getElementById("walktour-tooltip-container");
    if (tooltipContainer) {
      if (logic.stepIndex == 0) {
        tooltipContainer.removeAttribute("class");
        tooltipContainer.classList.add(isDesktop ? "top" :"mobile-top-center");
      }
    }

    // if (isDesktop) {
    if (logic.stepIndex == 1) {
        tooltipContainer.removeAttribute("class");
        tooltipContainer.classList.add( isDesktop ? "top-arrow" :"top");
      }
    if (logic.stepIndex == 2) {
      tooltipContainer.removeAttribute("class");
      tooltipContainer.classList.add(isDesktop ? "step8-arrow" : "top");
    }
    //}

    if (isMobile && logic.stepIndex == 2) {
      localStorage.setItem('summaryPageGuide', 1)
    }

    else if (!isMobile && logic.stepIndex == 2) {
      localStorage.setItem('summaryPageGuide', 1)
    }
    logic.next();
    // console.log('logic ', logic.stepIndex);
  }
  const closeTour = (logic: WalktourLogic) => {
    localStorage.setItem('summaryPageGuide', 1)
    logic.close();
    // console.log('logic ', logic.stepIndex);
  }
  const GotoCommonDashboard = () => {
    window.location.href = "/app/common-dashboard"
  }
  useEffect(() => {
    if (enablePageGuide != 1) {
      // console.log('tooltip container');
      setTimeout(() => {
        const tooltipContainer = document.getElementById("walktour-tooltip-container");
        if (tooltipContainer) {
          tooltipContainer.classList.add(isDesktop ? "top" : "top-arrow");
        }
      }, 1000)

    }
  }, [isLoading]);
  return (
    <>
      <style>
       {  
       
        indexArr.map((value)=>
          `
          .table tbody tr:nth-child(${value}) td {
            border-bottom: 3px solid #3272b2 !important;
          }
        `)}
       </style>
      <Modal title="" size="sm" contentClasses="" onClose={() => setPdfPopUp(false)} show={pdfPopUp}>
        {pdfEmailPopUp()}
      </Modal>
      {(isLoading == false && enablePageGuide != 1) ? <Walktour steps={isMobile ? mobilePageGuide : pageGuide} nextLabel={t("user.sidebar.nextlabel")} customCloseFunc={closeTour} customNextFunc={myCustomNext} closeLabel={t("user.sidebar.closelabel")} disableClose={0} allSteps={pageGuide} /> : null}
      <section className="summarymobile dashboard-mobile">
        <div className="container-fluid full_bg">
          <div className={`row  ${filterShow ? "custom-height" : ""}`}>
            <Sidebar />
            <div className="col-md-10 p0 pr-0 ipd-righ">

              <div className="right-dashboard topspace mob-height">
                <div className={`${filterShow ? "activeFilter" : ""}`}>
                  <h2 className="heading-dash">{t('user.summary.heading')}</h2>
                </div>
                <div className="row summary-complete-daily-task">
                  <div className="col-sm-3 col-md-3 col-lg-4 col-xl-4 pl-sm-0">
                    <div className="cmd-back-right col-12 h-100" >
                      <p className="cmd-back-right-text pr-2 col-9 back-btn-text-color" dangerouslySetInnerHTML={{ __html: t("user.dashboard.back_to_cmd") }}>
                      </p>
                      <button className="btn-apr col-3" onClick={GotoCommonDashboard}>{t("user.dashboard.go")}</button>
                    </div>
                  </div>
                  <div className=" col-sm-3 col-md-3 col-lg-3 col-xl-2 pl-sm-0">
                    <div className="righ-side-tab righ-side visi-none hide-replie">
                      <div className="logo-shape">
                        <img src={shape} alt="icon" />
                        <p>{t('user.summary.buono')}</p>
                      </div>
                      <div className="w80 font58">{heartRate}</div>
                    </div>
                  </div>
                </div>
                {reportDataColumn.length > 0 ? (
                  <div className={`chart-table ${filterShow ? "custom-height" : ""} `}>
                    <ToolkitProvider
                      keyField="parameter"
                      data={
                        !controlValue
                          ? filterValue.length === 0
                            ? selection === 0
                              ? reportData
                              : selection === 1
                                ? reportData.slice(0, 10)
                                : selection === 2
                                  ? reportData.slice(10, 20)
                                  : reportData
                            : filteredData
                          : reportDatatutti
                      }
                      columns={reportDataColumn}
                      // pagination={ paginationFactory(options1)}
                      search

                    >
                      {(props) => (

                        <div className="table ricsb">
                          <div className="rieplogo alignflexstart">
                            <div className="filter-box" >
                              <div
                                className={`filt-b ${filterShow ? "activeFilter" : ""
                                  }`}
                                ref={ref}
                              >
                                <button
                                  className="b-box"
                                  onClick={changeFilter}
                                  id="step-summary-1"
                                >
                                  {/* <img src={Group733} alt="icon" />{" "} */}
                                  <span className="fillter-icon"></span>
                                  <span className="visi-none">
                                    {t('user.summary.filtri')}
                                    {/* -{filterValue.length} */}
                                  </span>
                                </button>
                                {filterShow ? (

                                  <div className="list-checkboxes">
                                    <div className="visibleonlly-phone">{t('user.summary.filtri')}
                                      {filterValue.length === 0 ? (
                                        <button className="search-filter" onClick={resetFilters}>
                                          <span className="cross-reset">X</span></button>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <h4>{t('user.summary.parameter')}</h4>
                                    {filterValue.length === 0 ? (
                                      <></>
                                    ) : (
                                      // <button className="search-filter" onClick={onSearch}><img src={Group698} alt="icon"/>Search</button>
                                      <button
                                        className="search-filter"
                                        onClick={resetFilters}
                                      >
                                        Reset
                                      </button>
                                    )}
                                    {filterValueData.map((data, i) => {
                                      let filterStatus = false;
                                      return (
                                        <div
                                          className="custom-control custom-checkbox"
                                          key={"opt" + i}
                                        >
                                          <input
                                            type="checkbox"
                                            defaultChecked={filterValue.includes(
                                              data.key
                                            )}
                                            className="custom-control-input"
                                            id={"customCheck" + i}
                                            value={data.key}
                                            onChange={onChangeFilter}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={"customCheck" + i}
                                          >
                                            {data.name}
                                          </label>
                                        </div>
                                      );
                                    })}
                                    <h4>{t('user.summary.measurments')}</h4>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="radio"
                                        defaultChecked={selection === 1}
                                        className="custom-control-input"
                                        name="fav_language"
                                        onClick={() => updateFilterSlice(1)}
                                        id={"selectionfilter1"}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"selectionfilter1"}
                                        onClick={() => updateFilterSlice(1)}
                                      >
                                        1 - 10
                                      </label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="radio"
                                        defaultChecked={selection === 2}
                                        className="custom-control-input"
                                        name="fav_language"
                                        onClick={() => updateFilterSlice(2)}
                                        id={"selectionfilter2"}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"selectionfilter2"}
                                        onClick={() => updateFilterSlice(2)}
                                      >
                                        10 - 20
                                      </label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                      <input
                                        type="radio"
                                        defaultChecked={selection === 0}
                                        className="custom-control-input"
                                        name="fav_language"
                                        onClick={() => updateFilterSlice(0)}
                                        id={"selectionfilter0"}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor={"selectionfilter0"}
                                        onClick={() => updateFilterSlice(0)}
                                      >
                                        {t('user.summary.vedi_tutte')}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                              </div>

                              <div className="searchbar" id="step-summary-2">
                                {/* <img
                                  src={Group698}
                                  className="search_icon"
                                  alt="icon"
                                /> */}
                                <span className="summary-search-icon"></span>

                                <SearchBar
                                  placeholder={t('user.summary.cerca')}
                                  {...props.searchProps}
                                />
                              </div>

                              <button
                                className="pdf-print visi-none"
                                onClick={() => generatePdfReport()}
                                id="step-summary-3"

                              >
                                {t("user.summary.pdf_text")}
                              </button>
                            </div>
                            <button
                              className="ricontrolla visi-none nonbutton"
                              onClick={() => changeText()}
                              id="step-summary-4"
                            >
                              {text
                                ? t("user.summary.btn_text")
                                : t("user.summary.btn_text1")}
                            </button>
                          </div>
                          <div className="relative alig-l-rarrow justify-arrow-ri">
                            {(!reachMaxNumber) ? (<>
                              <img className="deskt-n lefti" id='arrowsummary-left' src={NEXTICONA} onClick={() => pageup()} alt="icon" />
                            </>) : (<></>)}
                            {(!reachMinNumber) ? (<>
                              <img className="deskt-n right" id='arrowsummary' src={NEXTICONA} onClick={() => pagedown()} alt="icon" />
                            </>) : (<></>)}
                          </div>
                          <BootstrapTable
                            data={
                              !controlValue
                                ? filterValue.length === 0
                                  ? selection === 0
                                    ? reportData
                                    : selection === 1
                                      ? reportData.slice(0, 10)
                                      : selection === 2
                                        ? reportData.slice(10, 20)
                                        : reportData
                                  : filteredData
                                : reportDatatutti
                            }
                            {...props.baseProps}
                            filter={filterFactory()}
                            // pagination={ paginationFactory(options)}
                            wrapperClasses={"table-responsive"}
                          />
                          <button
                            className="pdf-print visi-none- d-block d-sm-none"
                            onClick={() => generatePdfReport()}
                            id="step-summary-m3"

                          >
                            {t("user.summary.pdf_text")}
                          </button>

                          <button
                            className="ricontrolla ricontrolla-tab-n tuoi  deskt-n"
                            onClick={() => changeText()}
                            id="step-summary-m4"
                          >
                            {text
                              ? t("user.summary.btn_text")
                              : t("user.summary.btn_text1")}
                          </button>
                          {setIsSearch(props.searchProps.searchText !== "")}
                        </div>
                      )}
                    </ToolkitProvider>

                  </div>
                ) : null}

                <div className="summary-inline-arrow">
                  {(!reachMaxNumber) ? (<>
                    <div className="left-side">
                      <div
                        className="text-right Prossimo prom-non  textcolor visi-none"
                        onClick={() => pageup()}
                      >
                        {t("user.summary.next")} &nbsp;<img src={NEXTICONA} alt="icon" />
                      </div>
                    </div>
                  </>) : (<></>)}


                  {(!reachMinNumber) ? (<>
                    <div className="right-side">
                      <div
                        className="text-left Prossimo prom-non  textcolor visi-none"
                        onClick={() => pagedown()}
                      >
                        <img className="arrowLeft" src={NEXTICONA} alt="icon" />&nbsp;
                        {t("user.summary.back")}
                      </div>
                    </div>
                  </>) : (<></>)}
                </div>
              </div>
            </div>
          </div>
          {isLoading ? <LazyLoader /> : null}
        </div>
      </section>
      {/* {(!isSearch) ? <Sidebarmob /> : null } */}
      <Sidebarmob />
    </>
  );
}

export default Summary;
