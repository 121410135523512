import { useEffect , useState} from "react";

function questionsAD() {

    const [question , setQuestion ] = useState({
        "BS_DASS42_Q1": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time",
        "4": "Applied to me very much, or most of the time",
        "label": "I found myself getting upset by quite trivial things"
        }, "BS_DASS42_Q2": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I was aware of dryness of my mouth"
        }, "BS_DASS42_Q3": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I couldn't seem to experience any positive feeling at all"
        }, "BS_DASS42_Q4": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I experienced breathing difficulty (eg, excessively rapid breathing, breathlessness in the absence of physical exertion)"
        }, "BS_DASS42_Q5": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time",
        "4": "Applied to me very much, or most of the time",
        "label": "I just couldn't seem to get going"
        }, "BS_DASS42_Q6": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I tended to over-react to situations"
        }, "BS_DASS42_Q7": {
         
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I had a feeling of shakiness (eg, legs going to give way)"
        },
        "BS_DASS42_Q8": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I found it difficult to relax"
        }, "BS_DASS42_Q9": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I found myself in situations that made me so anxious I was most relieved when they ended"
        }, "BS_DASS42_Q10": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I felt that I had nothing to look forward to"
        }, "BS_DASS42_Q11": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I found myself getting upset rather easily"
        }, "BS_DASS42_Q12": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", 
        "4": "Applied to me very much, or most of the time",
        "label": "I felt that I was using a lot of nervous energy"
        }, "BS_DASS42_Q13": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
         
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt sad and depressed"
        },
        "BS_DASS42_Q14": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I found myself getting impatient when I was delayed in any way (eg, elevators, traffic lights, being kept waiting)"
        }, "BS_DASS42_Q15": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I had a feeling of faintness"
        }, "BS_DASS42_Q16": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt that I had lost interest in just about everything"
        }, "BS_DASS42_Q17": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt I wasn't worth much as a person"
        }, "BS_DASS42_Q18": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt that I was rather touchy"
        }, "BS_DASS42_Q19": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
         
        "label": "I perspired noticeably (eg, hands sweaty) in the absence of high temperatures or physical exertion"
        }, "BS_DASS42_Q20": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt scared without any good reason"
        }, "BS_DASS42_Q21": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt that life wasn't worthwhile"
        }, "BS_DASS42_Q22": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I found it hard to wind down"
        }, "BS_DASS42_Q23": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I had difficulty in swallowing"
        }, "BS_DASS42_Q24": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I couldn't seem to get any enjoyment out of the things I did"
        }, "BS_DASS42_Q25": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I was aware of the action of my heart in the absence of physical exertion (eg, sense of heart rate increase, heart missing a beat)"
         
        },
        "BS_DASS42_Q26": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt down-hearted and blue"
        }, "BS_DASS42_Q27": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I found that I was very irritable"
        }, "BS_DASS42_Q28": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt I was close to panic"
        }, "BS_DASS42_Q29": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I found it hard to calm down after something upset me"
        }, "BS_DASS42_Q30": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I feared that I would be \"thrown\" by some trivial but unfamiliar task"
        }, "BS_DASS42_Q31": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I was unable to become enthusiastic about anything"
        }, "BS_DASS42_Q32": {
            "1": "Did not apply to me at all",
            "2": "Applied to me to some degree, or some of the time",
            "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
            "label": "I found it difficult to tolerate interruptions to what I was doing"       
        },               
        "BS_DASS42_Q33": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I was in a state of nervous tension"
        }, "BS_DASS42_Q34": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt I was pretty worthless"
        }, "BS_DASS42_Q35": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I was intolerant of anything that kept me from getting on with what I was doing"
        }, "BS_DASS42_Q36": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt terrified"
        }, "BS_DASS42_Q37": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I could see nothing in the future to be hopeful about"
        }, "BS_DASS42_Q38": {
        "1": "Did not apply to me at all",      
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I felt that life was meaningless"
        },
        "BS_DASS42_Q39": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I found myself getting agitated"
        }, "BS_DASS42_Q40": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I was worried about situations in which I might panic and make a fool of myself"
        }, "BS_DASS42_Q41": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I experienced trembling (eg, in the hands)"
        }, "BS_DASS42_Q42": {
        "1": "Did not apply to me at all",
        "2": "Applied to me to some degree, or some of the time",
        "3": "Applied to me to a considerable degree, or a good part of time", "4": "Applied to me very much, or most of the time",
        "label": "I found it difficult to work up the initiative to do things"
        }
        })       
        return question;
}

function anxietySet(setNumber)
{
//   console.log("setNumber",setNumber)

    const [setZero, setSetZero] = useState(['BS_DASS42_Q2', 'BS_DASS42_Q11', 'BS_DASS42_Q17', 'BS_DASS42_Q30', 'BS_DASS42_Q40']);
    const [setOne, setSetOne] = useState(['BS_DASS42_Q6', 'BS_DASS42_Q9', 'BS_DASS42_Q11', 'BS_DASS42_Q17', 'BS_DASS42_Q20']);
    const [setTwo, setSetTwo] = useState(['BS_DASS42_Q2', 'BS_DASS42_Q8', 'BS_DASS42_Q11', 'BS_DASS42_Q12', 'BS_DASS42_Q17']);
    const [setThree, setSetThree] = useState(['BS_DASS42_Q11', 'BS_DASS42_Q15', 'BS_DASS42_Q20', 'BS_DASS42_Q30', 'BS_DASS42_Q33']);
    const [setFour, setSetFour] = useState(['BS_DASS42_Q8', 'BS_DASS42_Q12', 'BS_DASS42_Q20', 'BS_DASS42_Q30', 'BS_DASS42_Q33']);
    const [setFive, setSetFive] = useState(['BS_DASS42_Q2', 'BS_DASS42_Q8', 'BS_DASS42_Q17', 'BS_DASS42_Q20', 'BS_DASS42_Q33']);
    const [setSix, setSetSix] = useState(['BS_DASS42_Q12', 'BS_DASS42_Q15', 'BS_DASS42_Q20', 'BS_DASS42_Q29', 'BS_DASS42_Q40']);
    const [setSeven, setSetSeven] = useState(['BS_DASS42_Q7', 'BS_DASS42_Q9', 'BS_DASS42_Q15', 'BS_DASS42_Q30', 'BS_DASS42_Q33']);
    const [setEight, setSetEight] = useState(['BS_DASS42_Q6', 'BS_DASS42_Q9', 'BS_DASS42_Q20', 'BS_DASS42_Q36', 'BS_DASS42_Q40']);
    const [setNine, setSetNine] = useState(['BS_DASS42_Q6', 'BS_DASS42_Q11', 'BS_DASS42_Q15', 'BS_DASS42_Q33', 'BS_DASS42_Q36']);
    switch (setNumber) {
        case "set0": return setZero;        
        case "set1" : return setOne;
        case "set2" : return setTwo;
        case "set3" : return setThree;
        case "set4" : return setFour;
        case "set5" : return setFive;
        case "set6" : return setSix;
        case "set7" : return setSeven;
        case "set8" : return setEight;
        case "set9" : return setNine;     
        default: return 0;
     }  
}
function depressionSet(setNumber)
{
    const [setZero, setSetZero] = useState(['BS_DASS42_Q16', 'BS_DASS42_Q20', 'BS_DASS42_Q27', 'BS_DASS42_Q34', 'BS_DASS42_Q40']);
    const [setOne, setSetOne] = useState(['BS_DASS42_Q6', 'BS_DASS42_Q13', 'BS_DASS42_Q18', 'BS_DASS42_Q24', 'BS_DASS42_Q26']);
    const [setTwo, setSetTwo] = useState(['BS_DASS42_Q3', 'BS_DASS42_Q11', 'BS_DASS42_Q17', 'BS_DASS42_Q29', 'BS_DASS42_Q35']);
    const [setThree, setSetThree] = useState(['BS_DASS42_Q13', 'BS_DASS42_Q20', 'BS_DASS42_Q26', 'BS_DASS42_Q35', 'BS_DASS42_Q40']);
    const [setFour, setSetFour] = useState(['BS_DASS42_Q3', 'BS_DASS42_Q18', 'BS_DASS42_Q27', 'BS_DASS42_Q29', 'BS_DASS42_Q34']);
    const [setFive, setSetFive] = useState(['BS_DASS42_Q6', 'BS_DASS42_Q11', 'BS_DASS42_Q16', 'BS_DASS42_Q17', 'BS_DASS42_Q24']);
    const [setSix, setSetSix] = useState(['BS_DASS42_Q11', 'BS_DASS42_Q16', 'BS_DASS42_Q17', 'BS_DASS42_Q26', 'BS_DASS42_Q34']);
    const [setSeven, setSetSeven] = useState(['BS_DASS42_Q6', 'BS_DASS42_Q13', 'BS_DASS42_Q20', 'BS_DASS42_Q27', 'BS_DASS42_Q29']);
    const [setEight, setSetEight] = useState(['BS_DASS42_Q3', 'BS_DASS42_Q18', 'BS_DASS42_Q24', 'BS_DASS42_Q35', 'BS_DASS42_Q40']);
    const [setNine, setSetNine] = useState(['BS_DASS42_Q3', 'BS_DASS42_Q11', 'BS_DASS42_Q13', 'BS_DASS42_Q17', 'BS_DASS42_Q35']);
    switch (setNumber) {
        case "set0": return setZero;        
        case "set1" : return setOne;
        case "set2" : return setTwo;
        case "set3" : return setThree;
        case "set4" : return setFour;
        case "set5" : return setFive;
        case "set6" : return setSix;
        case "set7" : return setSeven;
        case "set8" : return setEight;
        case "set9" : return setNine;     
        default: return 0;
     }  

}


function questionsADforIT() {

    const [question , setQuestion ] = useState({
        "BS_DASS42_Q1": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono agitato per delle sciocchezze"
        }, "BS_DASS42_Q2": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono reso conto di avere la bocca asciutta"
        }, "BS_DASS42_Q3": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Non ho potuto sentire nessuna emozione positiva"
        }, "BS_DASS42_Q4": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito che respiravo con difficoltà (per es. respiro rapido, affanno in assenza di sforzo fisico)"
        }, "BS_DASS42_Q5": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Non ho trovato la forza di iniziare a fare qualcosa"
        }, "BS_DASS42_Q6": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho reagito in modo esagerato alle situazioni"
        }, "BS_DASS42_Q7": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito di non essere fisicamente stabile (per es. le gambe mi tremavano)"
        },
        "BS_DASS42_Q8": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho avuto difficoltà a rilassarmi"
        }, "BS_DASS42_Q9": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono trovato in situazioni in cui mi sono sentito così ansioso che sono stato molto sollevato quando finivano"
        }, "BS_DASS42_Q10": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho avuto l’impressione di non potermi aspettare niente di positivo"
        }, "BS_DASS42_Q11": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho trovato che mi agitavo facilmente"
        }, "BS_DASS42_Q12": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito che stavo usando molta energia nervosa"
        }, "BS_DASS42_Q13": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono sentito triste e depresso"
        },
        "BS_DASS42_Q14": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono trovato ad avere poca pazienza quando ero in ritardo per qualsiasi ragione (per es. ascensori, semafori, aspettando qualcuno in ritardo)"
        }, "BS_DASS42_Q15": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho avuto una sensazione di svenimento"
        }, "BS_DASS42_Q16": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito che avevo perso interesse in quasi tutto"
        }, "BS_DASS42_Q17": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito di non avere molto valore come persona"
        }, "BS_DASS42_Q18": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito che mi irritavo facilmente"
        }, "BS_DASS42_Q19": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",         
        "label": "Ho sudato in modo visibile, anche se non faceva caldo e non avevo fatto nessuno sforzo (per es. mani sudate)"
        }, "BS_DASS42_Q20": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono sentito impaurito senza motivo"
        }, "BS_DASS42_Q21": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho avuto l'impressione che la vita non valesse la pena"
        }, "BS_DASS42_Q22": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho trovato difficile rilassare i miei nervi"
        }, "BS_DASS42_Q23": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho avuto difficoltà ad inghiottire"
        }, "BS_DASS42_Q24": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Nulla di ciò che ho fatto mi ha dato soddisfazione"
        }, "BS_DASS42_Q25": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito il cuore che mi batteva, anche senza aver fatto alcuno sforzo (per es. sensazione di aumento del battito del cuore, impressione che il cuore si fermi)"
         },
        "BS_DASS42_Q26": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono sentito giù di morale e triste"
        }, "BS_DASS42_Q27": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono sentito veramente irritabile"
        }, "BS_DASS42_Q28": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito che stava per venirmi un attacco di panico"
        }, "BS_DASS42_Q29": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho avuto difficoltà a calmarmi dopo che qualcosa mi aveva disturbato"
        }, "BS_DASS42_Q30": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho avuto paura di un’attività nuova, anche se facile"
        }, "BS_DASS42_Q31": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Non ho sentito entusiasmo per nessuna cosa"
        }, "BS_DASS42_Q32": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho trovato difficile sopportare interruzioni mentre facevo qualcosa"       
        },               
        "BS_DASS42_Q33": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono sentito molto nervoso"
        }, "BS_DASS42_Q34": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono sentito di non valere niente"
        }, "BS_DASS42_Q35": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Non ho potuto sopportare qualsiasi cosa mi impedisse di fare quello che stavo facendo"
        }, "BS_DASS42_Q36": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Mi sono sentito molto impaurito"
        }, "BS_DASS42_Q37": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Non sono riuscito a vedere che il futuro avesse qualcosa da offrirmi"
        }, "BS_DASS42_Q38": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito che la vita fosse senza scopo"
        },
        "BS_DASS42_Q39": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho notato che mi stavo agitando"
        }, "BS_DASS42_Q40": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ero preoccupato di situazioni che avrebbero potuto causare panico o farmi sentire ridicolo"
        }, "BS_DASS42_Q41": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho sentito che tremavo (per es. le mani)"
        }, "BS_DASS42_Q42": {
        "1": "Non mi è mai accaduto",
        "2": "Mi è capitato qualche volta",
        "3": "Mi è capitato con una certa frequenza",
        "4": "Mi è capitato quasi sempre",
        "label": "Ho trovato difficile prendere l’iniziativa di fare qualcosa"
        }
        })       
        return question;
}
export default
{
    questionsAD,
    anxietySet,
    depressionSet,
    questionsADforIT
} 
