import { Route } from "react-router-dom";

const SA_ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated = JSON.parse( localStorage.getItem("R3"));
  const isAuthenticatedRoleId = JSON.parse( localStorage.getItem("roleId"));
  if((isAuthenticated && (isAuthenticatedRoleId == 3 || isAuthenticatedRoleId == 4 ))){
    // remove r2
    localStorage.removeItem("R1");
    localStorage.removeItem("R2");

  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated?.access_token && (isAuthenticatedRoleId == 3 || isAuthenticatedRoleId == 4 )? <Component {...props} /> : window.location.href='/sa/signin'
      }
    />
  );
}


export default SA_ProtectedRoute;