import { useEffect, useState } from 'react'
import { getLocalStoarge, getMindfulnessPrePost } from '../../../common/allfunction';
import { useTranslation } from 'react-i18next';
import LazyLoader from '../../../common/lazyLoader';

const MindfulnessActvityWisePrePost1 = (props) => {
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [mentalPresence, setMentalPresence] = useState([]);
    const [anxiety, setAnxiety] = useState([]);
    const [stress, setStress] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        getPrePostData();
    }, [props.repeatTime]);

    const getPrePostData = async () => {
        const data = await getMindfulnessPrePost(props.repeatTime);
        setMentalPresence(data?.MentalPresence);
        setAnxiety(data?.Anxiety);
        setStress(data?.Stress);
        setIsLoading(false);
    }


    return (
        <div>
            {isLoading ? <LazyLoader /> : null}
            <div className='second-top-heading mt-3'>
                <div className="col-12">
                    <h2 className='second-page-heading'>{t('user.activityReport.details_pre_post')}</h2>
                    <p dangerouslySetInnerHTML={{ __html: t('user.activityReport.details_description') }}></p>
                </div>
            </div>
            <div className='pre-post-activity-name-desc'>
                <div className="row m-0">
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.activity1')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.activity1_description')}</p>
                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>

                            <h6 className='text-left'>{`PRE / ${(mentalPresence?.length > 0 && Boolean((mentalPresence[0]))) ? (mentalPresence[0]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: mentalPresence ? `${(((100 * mentalPresence[0]) / 90) > 100) ? 100 : ((100 * mentalPresence[0]) / 90)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "34%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "18%" }}></div>
                                <div className="progress-bar color-light-yellow" style={{ width: "16%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "16%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "16%" }}></div>
                            </div>

                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name'  style={{ width: "34%" }}>{t('user.activityReport.progress_bar_step1')}</p>
                                <p className='progress-score-name'  style={{ width: "18%" }}>{t('user.activityReport.progress_bar_step2')}</p>
                                <p className='progress-score-name'  style={{ width: "16%" }}>{t('user.activityReport.progress_bar_step3')}</p>
                                <p className='progress-score-name'  style={{ width: "16%" }}>{t('user.activityReport.progress_bar_step4')}</p>
                                <p className='progress-score-name'  style={{ width: "16%" }}>{t('user.activityReport.progress_bar_step5')}</p>
                            </div>
                        </div>


                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(mentalPresence?.length > 0 && Boolean((mentalPresence[1]))) ? (mentalPresence[1]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: mentalPresence ? `${(((100 * mentalPresence[1]) / 90) > 100) ? 100 : ((100 * mentalPresence[1]) / 90)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "34%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "18%" }}></div>
                                <div className="progress-bar color-light-yellow" style={{ width: "16%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "16%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "16%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name'  style={{ width: "34%" }}>{t('user.activityReport.progress_bar_step1')}</p>
                                <p className='progress-score-name'  style={{ width: "18%" }}>{t('user.activityReport.progress_bar_step2')}</p>
                                <p className='progress-score-name'  style={{ width: "16%" }}>{t('user.activityReport.progress_bar_step3')}</p>
                                <p className='progress-score-name'  style={{ width: "16%" }}>{t('user.activityReport.progress_bar_step4')}</p>
                                <p className='progress-score-name'  style={{ width: "16%" }}>{t('user.activityReport.progress_bar_step5')}</p>
                            </div>
                        </div>

                        <p className='calculate-percent'>{`${(mentalPresence?.length > 0 && Boolean((mentalPresence[2]))) ? (mentalPresence[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>

                        <ul className='pre-post-list-info'>
                            <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.info-list-1') }}></li>

                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.activity2')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.activity2_description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(anxiety?.length > 0 && Boolean((anxiety[0]))) ? (anxiety[0]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: anxiety ? `${(((100 * anxiety[0]) / 80) > 100) ? 100 : ((100 * anxiety[0]) / 80)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-light-sky" style={{ width: "50%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "25%" }}></div>
                                <div className="progress-bar color-red" style={{ width: "25%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name'  style={{ width: "50%" }}>{t('user.activityReport.progress_bar_step6')}</p>
                                <p className='progress-score-name'  style={{ width: "25%" }}>{t('user.activityReport.progress_bar_step7')}</p>
                                <p className='progress-score-name'  style={{ width: "25%" }}>{t('user.activityReport.progress_bar_step8')}</p>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(anxiety?.length > 0 && Boolean((anxiety[1]))) ? (anxiety[1]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: anxiety ? `${(((100 * anxiety[1]) / 80) > 100) ? 100 : ((100 * anxiety[1]) / 80)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-light-sky" style={{ width: "50%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "25%" }}></div>
                                <div className="progress-bar color-red" style={{ width: "25%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name'  style={{ width: "50%" }}>{t('user.activityReport.progress_bar_step6')}</p>
                                <p className='progress-score-name'  style={{ width: "25%" }}>{t('user.activityReport.progress_bar_step7')}</p>
                                <p className='progress-score-name'  style={{ width: "25%" }}>{t('user.activityReport.progress_bar_step8')}</p>
                            </div>
                        </div>

                        <p className='calculate-percent'>{`${(anxiety?.length > 0 && Boolean((anxiety[2]))) ? (anxiety[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>

                        <ul className='pre-post-list-info'>
                            <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.info-list-2') }}></li>

                        </ul>

                    </div>
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.activity3')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.activity3_description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(stress?.length > 0 && Boolean((stress[0]))) ? (stress[0]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: stress ? `${(((100 * stress[0]) / 40) > 100) ? 100 : ((100 * stress[0]) / 40)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-light-sky" style={{ width: "15%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "15%" }}></div>
                                <div className="progress-bar color-light-yellow" style={{ width: "5%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "15%" }}></div>
                                <div className="progress-bar color-red" style={{ width: "50%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name'  style={{ width: "15%" }}>{t('user.activityReport.progress_bar_step9')}</p>
                                <p className='progress-score-name'  style={{ width: "15%" }}>{t('user.activityReport.progress_bar_step10')}</p>
                                <p className='progress-score-name'  style={{ width: "5%" }}>{t('user.activityReport.progress_bar_step11')}</p>
                                <p className='progress-score-name'  style={{ width: "15%" }}>{t('user.activityReport.progress_bar_step12')}</p>
                                <p className='progress-score-name'  style={{ width: "50%" }}>{t('user.activityReport.progress_bar_step13')}</p>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(stress?.length > 0 && Boolean((stress[1]))) ? (stress[1]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: stress ? `${(((100 * stress[1]) / 40) > 100) ? 100 : ((100 * stress[1]) / 40)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-light-sky" style={{ width: "15%" }}></div>
                                <div className="progress-bar color-light-green" style={{ width: "15%" }}></div>
                                <div className="progress-bar color-light-yellow" style={{ width: "5%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "15%" }}></div>
                                <div className="progress-bar color-red" style={{ width: "50%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name'  style={{ width: "15%" }}>{t('user.activityReport.progress_bar_step9')}</p>
                                <p className='progress-score-name'  style={{ width: "15%" }}>{t('user.activityReport.progress_bar_step10')}</p>
                                <p className='progress-score-name'  style={{ width: "5%" }}>{t('user.activityReport.progress_bar_step11')}</p>
                                <p className='progress-score-name'  style={{ width: "15%" }}>{t('user.activityReport.progress_bar_step12')}</p>
                                <p className='progress-score-name'  style={{ width: "50%" }}>{t('user.activityReport.progress_bar_step13')}</p>
                            </div>
                        </div>

                        <p className='calculate-percent'>{`${(stress?.length > 0 && Boolean((stress[2]))) ? (stress[2]).toString()?.replace('.', ',') : 0}`}<span>%</span></p>

                        <ul className='pre-post-list-info'>
                            <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.info-list-3') }}></li>
                        </ul>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default MindfulnessActvityWisePrePost1
