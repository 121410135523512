import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Image/logo.svg";
import DOCUMENTO_ICONA from "../../assets/Image/DOCUMENTO_ICONA.svg";
import SidebarProfile from "../common/sidebarProfile";
import Sidebarmob from "../common/sidebarMobile";
import leftarrow from "../../assets/Image/leftarrow.svg";
import BRAINWELLNESS_ICONA from "../../assets/Image/BRAINWELLNESS_ICONA.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from "../common/allfunction";
import Group2841 from '../../assets/Image/Group2841.svg'
import Group1874 from '../../assets/Image/Group 1874.svg'
import Group1873 from '../../assets/Image/Group 1873.svg'
import REAZIONE_ICONA from '../../assets/Image/REAZIONE_ICONA.svg'

function ReferementScience() {
    const [t, i18n] = useTranslation('common');
    const [scrollTop, setScrollTop] = useState(false);

    useEffect(() => {
        handleTranslationLang();
        scrollMoveTop();
    }, []);

    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang)
        }
    }
    const scrollMoveTop = () => {
        if (!scrollTop) {
            window.scrollTo(0, 0);
            setScrollTop(true);
        }
    }

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <>
            <section className="bg-privacy riferimenti dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 topspace d-lfti none-viewport ipad-widhset">
                            <SidebarProfile />
                        </div>
                        <div className="col-md-10 ipd-righ">
                            <div className="top-priv-static">
                                <div className="row ml-sm-0 ml-md-0 ml-lg-3 ml-xl-3 ml-xxl-3">
                                    <div className="col-md-12 mb-4">
                                        <h2 className="otp-heading referment-h">
                                            <Link to="/profilemenu">
                                                <img src={leftarrow} className="deskt-n" alt="icon" />
                                            </Link>
                                            {t('user.referments.heading')}
                                        </h2>
                                    </div>
                                    <div className="col-md-6 d-flex">
                                        <div className="brainwel-card-position">
                                            <div className="d-flex-brainwel">
                                                <div className="brainwel-double-image">
                                                    {/* <img src={Group1873} alt="Icon" />  */}
                                                </div>
                                                <div><p className="mb-0 modulo-text">{t('user.referement_science.modulo')}</p>
                                                    <p className="welbeing-text">{t('user.referement_science.first_card_name')}</p> </div>
                                            </div>
                                            <div className="static-btn">
                                                <Link to={`/referements`}>
                                                    <button type='button' className='static-inner-btn brainwel-bnt'>{t('user.referement_science.button_name')}</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex">
                                        <div className="brainwel-card-position">
                                            <div className="d-flex-brainwel">
                                                <div className="brain-wellness-bg">
                                                </div>
                                                <div><p className="mb-0 modulo-text">{t('user.referement_science.modulo')}</p>
                                                <p className="welbeing-text">{t('user.referement_science.second_card_name')}</p></div>
                                             </div>
                                            <div className="static-btn">
                                             <Link to={`/brainwellness-science`}>
                                                    <button type='button' className='static-inner-btn brainwel-bnt'>{t('user.referement_science.button_name')}</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="act-ve">
                <Sidebarmob />
            </div>
        </>
    );
}
export default ReferementScience;