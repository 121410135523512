import { useEffect, useState } from "react";
import Adminsidebar from "../admin/sidebar";
import Sidebarmob from "../admin/sidebarmob";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import PostRequest from "../apiConnection/postRequest";
import LazyLoader from "../common/lazyLoader";
import {
  useParams,
} from "react-router-dom";
import { getLocalStoarge } from "../common/allfunction";
import { useTranslation } from "react-i18next";


const Dashboard = (props) => {
  const [pageno, setPageno] = useState((props.match.params?.page_name && props.match.params?.page_name !== "") ?
    (props.match.params?.page_name == 'dashboard') ? 0 :
      (props.match.params?.page_name == 'report') ? 1 :
        (props.match.params?.page_name == 'summary') ? 2 : 0 : 0);
  const [isLoading, setIsLoading] = useState(false);
  const [report, setReport] = useState({});
  const [powerBiData, setPowerBiData] = useState({});
  const [endpoint, setEndpoint] = useState();
  const [loaddone, setLoaddone] = useState(false);
  const [heading, setHeading] = useState((pageno == 0) ? 'Dashboard' : (pageno == 1) ? 'Report BI' : (pageno == 2) ? 'Tabella Riepilogativa' : 'Dashboard');
  // const [pages, setPages] = useState();
  const [bipages, setbipages] = useState(["ReportSection", "ReportSectionc0d8f083a621613105b7", "ReportSection63589e60dae36ec6febd"])
  const { page_name } = useParams();
  const [t, i18n] = useTranslation("common");

  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  useEffect(() => {
    console.log(window.innerWidth);
    getPowerBiData();
    handleTranslationLang();

    if (loaddone) {
      changePage();
    }
  }, [pageno, page_name]);



  const changePage = async () => {
    let page_no = (props.match.params?.page_name && props.match.params?.page_name !== "") ?
      (props.match.params?.page_name == 'dashboard') ? 0 :
        (props.match.params?.page_name == 'report') ? 1 :
          (props.match.params?.page_name == 'summary') ? 2 : 0 : 0;
    setHeading((page_no == 0) ? 'Dashboard' : (page_no == 1) ? 'Report BI' : (page_no == 2) ? 'Tabella Riepilogativa' : 'Dashboard');
    if (report) {
      setHeading((page_no === 0) ? 'Dashboard' : (page_no === 1) ? 'Report BI' : 'Tabella Riepilogativa');
      report.setPage(bipages[page_no]);
    }
  }


  const getPowerBiData = async () => {

    const payload = {};
    setIsLoading(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        "",
        "refresh_token"
      );
      if (refreshToken) {
        const getDataResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN_POWERBI}`, payload,
          "", ""
        );
        if (getDataResult && getDataResult.status === 200) {
          setIsLoading(false);
          setPowerBiData(getDataResult.data.data);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="full_bg dashboard-mobile">
        <div className="container-fluid">
          <div className="row">
            <Adminsidebar />
            <div className="col-md-10 pr-0 p0 ipd-righ">
              <div className="">
                {(powerBiData) ?
                  <div>
                    <PowerBIEmbed
                      embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: powerBiData.id,
                        embedUrl: powerBiData.embedUrl,
                        accessToken: powerBiData.token,
                        tokenType: models.TokenType.Embed,
                        pageName: bipages[pageno],
                        settings: {
                          panes: {
                            filters: {
                              expanded: false,
                              visible: false
                            },
                            pageNavigation: {
                              visible: false
                            }
                          },
                          background: models.BackgroundType.Transparent,
                          zoomLevel: 0.85,
                          width : window.innerWidth > 1920 ? "120%" : "100%" 
                        }
                      }}

                      eventHandlers={
                        new Map([
                          ['loaded', () => {
                            setLoaddone(true);
                          }],
                          ['rendered', () => { }],
                          ['error', (event) => { console.log(event.detail); }]
                        ])
                      }
                      cssClassName={"report-style-class-dashboard"}
                      getEmbeddedComponent={(embedObject) => {
                        setReport(embedObject);
                      }}
                    />
                  </div> : null}
              </div>
            </div>
          </div>
          {(isLoading) ? <LazyLoader /> : null}
        </div>
      </section>
      <Sidebarmob />
    </>
  );
}

export default Dashboard;


