import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import { NBackAcousticResult } from "./NBackAcousticResult";
import PostRequest from '../../../apiConnection/postRequest';
import Group1432 from "../../../../assets/Image/Group_1432.svg";
import bw_group_1432 from "../../../../assets/Image/bw_group_1432.svg";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import LazyLoader from "../../../common/lazyLoader";


const NBackAcousticResume = ({ props }) => {

    const { round, totalTime, resetRound, togglePause, timeRemaining, avgAccuracy, accuracyCurrentRound, params, totalAvgResponseTime, n, startTimer, score, roundTime, maxItemKeepMind, activitiyMaxScore, free} = props;

    const [t, i18n] = useTranslation("common");
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [status, setStatus] = useState("in-complete")
    const [isLoading, setIsLoading] = useState(false);
    const [activityPlayTime, setActivityPlayTime] = useState((totalTime * 60) - timeRemaining)
    const component_id = parseInt(params.component_id);
    const repeat_time = parseInt(params.repeat_time);
    const day_sequence = parseInt(params.day_sequence);
    const activity_id = parseInt(params.activity_id);

    useEffect(() => {
        togglePause();
    }, [])

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setIsLoading(false)
    }, [])

    const payload = {
        company_id: companyId,
        component_id: component_id,
        day_sequence: day_sequence,
        repeat_time: repeat_time,
        activity_id: activity_id,
        status: "in-complete",
        activity_score: avgAccuracy,
        activity_play_time: ((totalTime * 60) - timeRemaining),
        activity_round_no: round + 1,
        activities_max_score: activitiyMaxScore,
        total_avg_response_time: 0
    }

    useEffect(() => {
        (timeRemaining > 0 && !free) ? submitUserActivityData(payload) : setIsLoading(false)
    }, [payload])


    const submitUserActivityData = async (payload_1) => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            if (refreshToken) {
                const result = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
                    payload_1,
                    "",
                    ""
                );
                if (result) {
                    if (result.status === 200) {
                        // Handle status 200
                        // console.log("api_submit_result_1", result);
                    } else if (result.status === 203) {
                        // Handle status 203
                    }
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }


    const reset = () => {
        resetRound(accuracyCurrentRound, n);
    }

    const startCountdown = () => {
        startTimer();
    }

    return (
        <>
            {
                (timeRemaining > 0) ?
                    <>
                        {(isLoading) ? <LazyLoader /> : null}
                        <section className="reaction-speed-section">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stepsin stpelog">
                                            <Link to="dashboard">
                                                <img src={logo} className="logo-steps" alt="logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="reaction-speed-result">
                                            <div className="col-md-6">
                                                <div className="reaction-speed-result-1">
                                                    <h2>{t("user.activity.reaction_speed.result_heading")}</h2>
                                                    <p>{t("user.activity.n_back_visivo.result_message_1")} {maxItemKeepMind} {t("user.activity.n_back_visivo.result_message_2")} {Math.ceil(roundTime / 60)} {t("user.activity.n_back_visivo.result_message_3")} </p>
                                                    <div className="row">
                                                        <div className="reaction-speed-result-1-1">
                                                            <div className="col-md-6">
                                                                <p className="reaction-speed-result-reponse-bar">
                                                                    <CircularProgressbar value={accuracyCurrentRound} maxValue={1} strokeWidth={12} styles={buildStyles({
                                                                        pathColor: parseInt(Math.round(parseFloat(accuracyCurrentRound) * 100)) >= 60 ? '#9ECB61' : '#B02418',
                                                                    })}
                                                                    /></p>
                                                            </div>
                                                            <div className="col-md-6 reaction-speed-result-accuracy">
                                                                <h6>{Math.round(parseFloat(accuracyCurrentRound) * 100)}%</h6>
                                                                <p>{t("user.activity.reaction_speed.result_accuracy")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={`reaction-speed-result-2 ${parseInt(Math.round(parseFloat(accuracyCurrentRound) * 100)) >= 60 ? 'tumbs-up' : 'tumbs-down'}`}>
                                                    {parseInt(Math.round(parseFloat(accuracyCurrentRound) * 100)) >= 60 ?
                                                        < img src={Group1432} alt="icon" />
                                                        :
                                                        <img src={bw_group_1432} alt="icon" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 bw-resume-buttons">
                                        <button className="btn btn-primary btnNext" onClick={event => { reset(); startCountdown() }}>{t("user.activity.executive_function.continue")}</button>
                                        <Link to={`/congnitive-training/${parseInt(params.repeat_time)}`}>
                                            <button className="btn btn-primary btnNext-act">
                                                {t("user.activity.reaction_speed.exit")}
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                    :
                    <NBackAcousticResult props={{ "params": params, "avgAccuracy": avgAccuracy, "totalTime": totalTime, "totalAvgResponseTime": totalAvgResponseTime, "score": score, "roundTime": roundTime, "round": round, "maxItemKeepMind": maxItemKeepMind, "activitiyMaxScore": activitiyMaxScore, "accuracyCurrentRound": accuracyCurrentRound ,"free":free }} />
            }
        </>
    )
}
export default NBackAcousticResume;