import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import Group1432 from "../../../../assets/Image/Group_1432.svg";
import { getLocalStoarge,setLocalStoarge } from '../../../common/allfunction';
import PostRequest from '../../../apiConnection/postRequest';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import bw_group_1432 from "../../../../assets/Image/bw_group_1432.svg";
import { submitUserActivityData } from '../common/utilty';
import LazyLoader from "../../../common/lazyLoader";

export const ExecutiveFunctionResult = ({ props }) => {
    const { params, avgAccuracy, totalTime, totalAvgResponseTime, totalAvgCongruentResponseTime, totalAvgIncongruentResponseTime, totalAvgNeutralResponseTime, free } = props;
    const [t, i18n] = useTranslation("common");
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [isLoading, setIsLoading] = useState(true);

    const [payload, setPayload] = useState({
        company_id: companyId,
        component_id: parseInt(params.component_id),
        day_sequence: parseInt(params.day_sequence),
        repeat_time: parseInt(params.repeat_time),
        activity_id: parseInt(params.activity_id),
        status: "complete",
        activity_score: avgAccuracy,
        activity_play_time: totalTime * 60,
        total_avg_response_time: Math.round(totalAvgResponseTime),
        total_avg_congruent_response_time: Math.round(totalAvgCongruentResponseTime),
        total_avg_incongruent_response_time: Math.round(totalAvgIncongruentResponseTime),
        total_avg_neutral_response_time: Math.round(totalAvgNeutralResponseTime)
    })

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    useEffect(() => {
        // console.log("payload", payload);
        if (free) {
            setLocalStoarge("avgAccuracy", avgAccuracy);
            setLocalStoarge("totalAvgResponseTime", totalAvgResponseTime);
            var resultObj = [];
            if(localStorage.getItem('cognativeResult_'+payload.repeat_time).length>0){
                resultObj = JSON.parse(localStorage.getItem('cognativeResult_'+payload.repeat_time));
            }
            resultObj[1] = {
                'activityName': 1,
                'avgAccuracy': avgAccuracy,
                'totalAvgResponseTime': totalAvgResponseTime
            };
            setLocalStoarge("cognativeResult_"+payload.repeat_time, JSON.stringify(resultObj));
            setIsLoading(false)
        } else {
            submitUserActivityData(payload,setIsLoading);
        }
    }, [payload])


    return (
        <>
            {(isLoading) ? <LazyLoader /> : null}
            <section className="reaction-speed-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="stepsin stpelog">
                                <Link to="dashboard">
                                    <img src={logo} className="logo-steps" alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="executive-function-result">
                                <div className="col-md-6">
                                    <div className="executive-function-result-1">
                                        <h2>
                                            {t("user.activity.executive_function.result_heading")}</h2>
                                        <p>
                                            {t("user.activity.executive_function.result_message")}
                                        </p>
                                        {/* </div> */}
                                        <div className="row">
                                            <div className="reaction-speed-result-1-1">
                                                <div className="col-md-6">
                                                    <p className="reaction-speed-result-reponse-bar">
                                                        <CircularProgressbar value={avgAccuracy} maxValue={1} strokeWidth={12} styles={buildStyles({
                                                            pathColor: parseInt(Math.round(avgAccuracy * 100)) > 60 ? '#9ECB61' : '#B02418',

                                                        })}
                                                        /></p>
                                                </div>
                                                <div className="col-md-6 reaction-speed-result-accuracy">
                                                    <div className="planning-result-accuracy">
                                                        <h6>{Math.round(parseInt(avgAccuracy * 100))}%</h6>
                                                        <p>{t("user.activity.executive_function.result_accuracy")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row res-time">

                                            <div className="col-6 sm-p-38">
                                                <p className="reaction-speed-result-response">{Math.round(totalAvgCongruentResponseTime)}<span className="executive-fun-ms">ms</span></p>
                                            </div>
                                            <div className="col-6 ">
                                                <p className="reaction-speed-result-res-text mobile-size-font-executive">{t("user.activity.executive_function.congruent")} ({t("user.activity.executive_function.ES")}.<span className="executive-fun-res-time-blu">{t("user.activity.executive_function.BLUE")}</span>) <span className="executive-fun-res-time-congurent">{Math.round(totalAvgNeutralResponseTime - totalAvgCongruentResponseTime)}ms</span></p>
                                            </div>
                                            <div className="col-6 sm-p-38">
                                                <p className="reaction-speed-result-response">{Math.round(totalAvgNeutralResponseTime)}<span className="executive-fun-ms">ms</span></p>
                                            </div>
                                            <div className="col-6">
                                                <p className="reaction-speed-result-res-text mobile-size-font-executive">{t("user.activity.executive_function.neutrali")} ({t("user.activity.executive_function.ES")}.<span className="executive-fun-res-time-asfalto">{t("user.activity.executive_function.ASFALTO")}</span>)</p>
                                            </div>
                                            <div className="col-6 sm-p-38">
                                                <p className="reaction-speed-result-response">{Math.round(totalAvgIncongruentResponseTime)}<span className="executive-fun-ms">ms</span></p>
                                            </div>
                                            <div className="col-6 sm-p-38">
                                                <p className="reaction-speed-result-res-text mobile-size-font-executive">{t("user.activity.executive_function.incongruenti")}({t("user.activity.executive_function.ES")}.<span className="executive-fun-res-time-red">{t("user.activity.executive_function.BLUE")}</span>)<span className="executive-fun-res-time-incongurent">{Math.round(totalAvgIncongruentResponseTime - totalAvgNeutralResponseTime)}ms</span></p>
                                            </div>
                                        </div>
                                        <hr className="total-res topmob-move" />
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="reaction-speed-result-response">{Math.round(parseInt(totalAvgResponseTime))}<span className="executive-fun-ms">ms</span></p>
                                            </div>
                                            <div className="col-6">
                                                <p className="reaction-speed-result-response-text mobile-size-font-executive">{t("user.activity.reaction_speed.result_response")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="reaction-speed-result-2">
                                        {parseInt(Math.round(parseFloat(avgAccuracy) * 100)) > 60 ?
                                            < img src={Group1432} alt="" />
                                            :
                                            <img src={bw_group_1432} alt="" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <Link to={`/congnitive-training/${parseInt(params.repeat_time)}`}>
                                <button className="btn btn-primary btnNext-act">
                                    {t("user.activity.reaction_speed.exit")}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )

}
