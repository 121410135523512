import React, { useEffect } from 'react'
import ReactPlayer from 'react-player';
import Group769 from '../../assets/Image/Group769.svg';
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from "../common/allfunction";
import '../superAdmin/super_admin_assets/scss/protocol.scss'
function ShowVideo(props) {
    const [t, i18n] = useTranslation("common");

    useEffect(()=>{
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    },[])
    return (
        <div className="model-pop-new " >
            <div className="model-pop ">
                <div className="modal-header">
                    <h5 className="modal-title pop_heading" id="exampleModalLabel">
                        {/* <strong>{t("superadmin.training.popup.add_program_heading")}</strong> */}
                    </h5>
                    <button
                        type="button"
                        className="close r0-side"
                        onClick={() => props.close()}
                    >
                        <img src={Group769} alt="icon" />
                    </button>
                </div>
                <div></div>
                <div className="modal-body scroll-pop scroll-pop-new">
                 {!props.details.error ?   <div className="" >
                        <div className='player-wrapper'>
                            <ReactPlayer
                                className='react-player'
                                url={props.details.link}
                                width='100%'
                                height='380px'
                                controls={true}                              
                                // config={{vimeo: {
                                //     playerOptions : {
                                //         controls: false
                                //     }
                                // }}}
                            />
                        </div>
                        <div className='show-video-title text-color-black'>{props.details.title}</div>
                        <div className="show-video-description text-color-black">{props.details.description}</div>
                    </div>: null}

                    {/* <div className="d-flex justify-content-end buttons-superadmin mt-5">
                        <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                        <button className="buttons modal-button" onClick={() => checkValNewProgramModal1()}>{t("superadmin.training.popup.end")}</button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ShowVideo;