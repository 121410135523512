export const publicKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu9xv04wV+e0/PhaaQdZP
U9OA6+GXiZTLCu3Ir8OBIEL9wtKnVlPaS6W0tk+GjtkK261N3HOR8GPJmt6e/eZR
hKmvPhJaNs7hEwHVu3iUiAH146Dadb629i40sVWMaIhWbcBx+TQDk7bZtpKnX7+d
dfZuR+7ASAVRBQ1ZgRfkgpb5KU7YOG4OVtxDSq2nKdlR2+RXu6Ebeq/07WDodsHE
HIsm2tiehxAbKs59TShdjWPxgmlvhUgYniOQpi6ZDwdyAi2P0zOkbj1ziIVppCn9
cNgJBWkSNJwkEu3C4m/Ix+5DyIaMAudw593Yx2gn5BU8qrbM4aVZoAZ+jc6lD1OV
NwIDAQAB
-----END PUBLIC KEY-----
`;