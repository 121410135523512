import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Image/logo.svg";
import Group759 from "../../assets/Image/Group759.svg";
import SidebarProfile from "../common/sidebarProfile";
import Sidebarmob from "../common/sidebarMobile";
import leftarrow from "../../assets/Image/leftarrow.svg";
import { useTranslation } from "react-i18next";
import {getLocalStoarge} from "../common/allfunction";

function faq() {
  const [t, i18n] = useTranslation('common');

  useEffect(() => {
    handleTranslationLang();
  }, []);


  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }

  return (
    <>
      <section className="bg-privacy faqs dashboard-mobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 topspace none-viewport ipad-widhset">
              <SidebarProfile />
            </div>
            <div className="col-md-10 ipd-righ">
              <div className="top-priv-static">
                <div id="accordion" className="accordion">
                  <h2 className="otp-heading imposta referment-h">
                    <Link to="/profilemenu">
                      <img src={leftarrow} className="deskt-n" alt="icon" />
                     </Link>
                      {t('user.faq.heading')}
                   </h2>
                  <div className="row">
                    <div className="col-md-8 iapdwful p-r15">
                      <div className="card mb-0">
                        <div className="bot-lini">
                          <div
                            className="card-header collapsed"
                            data-toggle="collapse"
                            href="#collapseOne"
                            data-target="#collapseOne"
                            aria-expanded="true"
                          >
                            <a className="card-title">
                              <div className="d-faqflex">
                                <img src={Group759} alt="icon" />{t("user.faq.fisrt")}
                              </div>
                            </a>
                          </div>
                          <div
                            id="collapseOne"
                            className="card-body collapse"
                            data-parent="#accordion"
                          >
                            <p dangerouslySetInnerHTML={{__html: t('user.faq.first_1')}}></p>
                          </div>
                        </div>

                        <div className="bot-lini">
                          <div
                            className="card-header collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseTwo"
                            data-target="#collapseTwo"
                            aria-expanded="true"
                          >
                            <a className="card-title">
                              <div className="d-faqflex">
                                <img src={Group759} alt="icon" /> {t("user.faq.second")}
                              </div>
                            </a>
                          </div>
                          <div
                            id="collapseTwo"
                            className="card-body collapse"
                            data-parent="#accordion"
                          >
                            <p>{t("user.faq.second_1")}</p>
                          </div>
                        </div>
                        <div className="bot-lini">
                          <div
                            className="card-header collapsed"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThree"
                            data-target="#collapseThree"
                            aria-expanded="true"
                          >
                            <a className="card-title">
                              <div className="d-faqflex">
                                <img src={Group759} alt="icon" /> {t("user.faq.third")}
                              </div>
                            </a>
                          </div>
                          <div
                            id="collapseThree"
                            className="collapse"
                            data-parent="#accordion"
                          >
                            <div className="card-body">
                            <p dangerouslySetInnerHTML={{__html: t("user.faq.third_1")}}></p>
                            <p dangerouslySetInnerHTML={{__html: t("user.faq.third_2")}}></p>
                              <p>{t("user.faq.third_3")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                
                      <div className="bot-lini">
                        <div
                          className="card-header collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseFour"
                          data-target="#collapseFour"
                          aria-expanded="true"
                        >
                          <a className="card-title">
                            <div className="d-faqflex">
                              <img src={Group759} alt="icon" /> 
                              {t("user.faq.fourth")}
                            </div>
                          </a>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>{t("user.faq.fourth_1")}</p>
                          </div>
                        </div>
                      </div>

                      <div className="bot-lini">
                        <div
                          className="card-header collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseFive"
                          data-target="#collapseFive"
                          aria-expanded="true"
                        >
                          <a className="card-title">
                            <div className="d-faqflex">
                              <img src={Group759} alt="icon" /> {t("user.faq.fifth")}
                            </div>
                          </a>
                        </div>
                        <div
                          id="collapseFive"
                          className="collapse"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                          <p dangerouslySetInnerHTML={{ __html: t('user.faq.fifth_1') }}>
                      </p>
                    <p dangerouslySetInnerHTML={{ __html: t('user.faq.fifth_2') }}>
                     </p>
                          </div>
                        </div>
                      </div>
                      </div>

                    {/* <div className="w-100 text-right rifaq">
                      <Link to="/profile">
                        <button className="btn btn-primary Ho visi-none">
                          Chiudi
                        </button>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="act-ve">
      <Sidebarmob />
      </div>
    </>
  );
}

export default faq;
