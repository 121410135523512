import { useEffect, useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { useTranslation } from 'react-i18next';
import { getLocalStoarge } from '../../../common/allfunction';
import LazyLoader from '../../../common/lazyLoader';

const MindfulnessOverAllMindfulness = (props) => {
    const [t, i18n] = useTranslation("common");
    const [userName, setUserName] = useState(JSON.parse(getLocalStoarge('profile')));
    const [activityName, setActivityName] = useState("protocollo di Mindfulness");
    const [isLoading, setIsLoading] = useState(false);
    const [trainingPercent, setTrainingPercent] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
            if (lang == "en") {
                setActivityName("Mindfulness protocol")
            }
        }
    }, [props.repeatTime]);


    useEffect(() => {
        setTrainingPercent(props?.data?.trainingData?.training_percentage);
    }, [props])

    useEffect(() => {
        if (Boolean(trainingPercent) || trainingPercent == 0) {
            setIsLoading(false);
        }
    }, [trainingPercent])

    return (
        <div>
            {isLoading ? <LazyLoader /> : null}
            <div className="report-training-cognitive">
                <div className="row m-0">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 report-training-text">
                        <h2>Report mindfulness</h2>
                        <p dangerouslySetInnerHTML={{ __html: t('user.activityReport.desciption').replace('user', userName.user_name).replace('training-name', activityName) }}></p>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 d-flex align-items-center">
                        <div className="reaction-speed-result-1">
                            <div className="row">
                                <div className="reaction-speed-result-1-1">
                                    <div className="col-md-6">
                                        <p className="reaction-speed-result-reponse-bar report-training-chart">
                                            <CircularProgressbar value={trainingPercent} maxValue={100} strokeWidth={12} styles={buildStyles({
                                                pathColor: '#6D71F9',
                                            })}
                                            /></p>
                                    </div>
                                    <div className="col-md-6 usertrainingleft-status">
                                        <h6>{trainingPercent}%</h6>
                                        <p>{t("user.activityReport.completed")}</p>
                                    </div>
                                </div>

                                <div className="col-md-12 reaction-speed-result-1-1">
                                    <p className="completed-circle"><span></span>{t("user.activityReport.completed")}</p>
                                    <p className="completed-circle not-completed-circle"><span></span>{t("user.activityReport.not_completed")}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MindfulnessOverAllMindfulness
