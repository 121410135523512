import React from "react";
import ELIMINA_ICONA from "../../../assets/Image/ELIMINA_ICONA.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import leftarrow from "../../../assets/Image/leftarrow.svg";
import { Link } from "react-router-dom";
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import { useState, useEffect, useRef } from "react";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import {
    getProfile,
    setLocalStoarge,
    getLocalStoarge,
} from "../../common/allfunction";
import { useTranslation } from "react-i18next";

const SA_Profile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [profile_pic, setProfile] = useState();
    const [showpic, setSHowPic] = useState("default.png");
    const [IsDisabled, setIsDisabled] = useState(true);
    const inputElement = useRef();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState(null);
    const [text, setText] = useState("Modifica");
    const [t, i18n] = useTranslation("common");
    const [langText, setLangText] = useState(0);
    const [scrollTop, setScrollTop] = useState(false);
    const pass='*******'

    const scrollMoveTop = () => {
        if (!scrollTop) {
            window.scrollTo(0, 0);
            setScrollTop(true);
        }
    }


    const [inputValuesnew, setInputValuesnew] = useState({
        vitali: 0,
        physiological: 0,
        mental: 0,
        physicists: 0,
        general_risks: 0,
        metabolic_risks: 0,
        measurement_of_psychological_values: 0,
        twofa: 0,
        password: pass
    });

    const [alerts, setAlerts] = useState({
        alert: "",
        alerttype: "",
        alertclass: "danger",
        showalert: false,
    });

    const { alert, alerttype, alertclass, showalert } = alerts;
    const [validation, setValidation] = useState({
        email: "",
        password: "",
        username: ""
    });


    const checkValidation = async () => {
        let errors = validation;
        // email validation
        let Emailregex = new RegExp(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        );
        const email1 = email;

        if (!email1) {
            errors.email = t("user.profile.valid_email_address");
            setAlerts({
                // alert: addProfileResult.data.message,
                alert: t("user.profile.valid_email_address"),
                alerttype: "Error",
                alertclass: "danger",
                showalert: true,
            });
            setTimeout(() => {
                setAlerts({
                    alert: "",
                    alerttype: "",
                    alertclass: "",
                    showalert: false,
                });
            },
                process.env.REACT_APP_ALERT_TIMEOUT * 1000);
        } else if (!email.match(Emailregex)) {
            errors.email = t("user.profile.valid_email_address");
            setAlerts({
                // alert: addProfileResult.data.message,
                alert: t("user.profile.valid_email_address"),
                alerttype: "Error",
                alertclass: "danger",
                showalert: true,
            });
            setTimeout(() => {
                setAlerts({
                    alert: "",
                    alerttype: "",
                    alertclass: "",
                    showalert: false,
                });
            },
                process.env.REACT_APP_ALERT_TIMEOUT * 1000);
        } else {
            errors.email = "";
        }
        let userExp = new RegExp(/^[a-zA-Z ]*$/);
        if ((username === undefined || username === null || username === '') ||
            (isNaN(username) !== true || parseInt(username.length) < 3 || parseInt(username.length) > 30) || !username.match(userExp)) {
            errors.username = t("user.profile.valid_user_name");
            setAlerts({
                alert: t("user.profile.valid_user_name"),
                alerttype: "Error",
                alertclass: "danger",
                showalert: true,
            });
            setTimeout(() => {
                setAlerts({
                    alert: "",
                    alerttype: "",
                    alertclass: "",
                    showalert: false,
                });
            },
                process.env.REACT_APP_ALERT_TIMEOUT * 1000);
        }
        else {
            errors.username = "";
        }

        //password validation
        const Passregex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        );
        const password1 = password;
        if (password1 || password1 === '') {
            if (password.length < 8) {
                errors.password = t("user.login.valid_password1");
                setAlerts({
                    alert: t("user.login.valid_password1"),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
                setTimeout(() => {
                    setAlerts({
                        alert: "",
                        alerttype: "",
                        alertclass: "",
                        showalert: false,
                    });
                },
                    process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            } else if (password.length > 32) {
                errors.password = t("user.login.valid_password2");
                setAlerts({
                    alert: t("user.login.valid_password2"),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
                setTimeout(() => {
                    setAlerts({
                        alert: "",
                        alerttype: "",
                        alertclass: "",
                        showalert: false,
                    });
                },
                    process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            } else if (!password.match(Passregex)) {
                errors.password = t("user.login.valid_password3");
                setAlerts({
                    alert: t("user.login.valid_password3"),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
                setTimeout(() => {
                    setAlerts({
                        alert: "",
                        alerttype: "",
                        alertclass: "",
                        showalert: false,
                    });
                },
                    process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            }
        }
        else {
            errors.password = "";
        }
        setValidation(errors);
        let return_val = true;
        await Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;
    };

    const {
        profile_pic1,
        vitali,
        physiological,
        mental,
        physicists,
        general_risks,
        metabolic_risks,
        measurement_of_psychological_values,
        twofa,
    } = inputValuesnew;

    const [token, setToken] = useState(() => {
        const saved = localStorage.getItem("R3");
        const initialValue = JSON.parse(saved);
        return initialValue.access_token || "";
    });

    const route_Questionnare = () => {
        window.location.href = "./questionnare";
    };

    const handleImageChange = async (e) => {
        e.preventDefault();
        let files = e.target.files[0];
        if (files) {
            let dataImage = new FormData();
            dataImage.append("image", e.target.files[0]);
            const checkImage = e.target.files[0];
            const fileExtension = checkImage.name.replace(/^.*\./, '');
            const fileSize = Math.round((checkImage.size / 1024));
            // to check image size and type
            if (fileExtension === 'png' || fileExtension === 'PNG' || fileExtension === 'jpg' || fileExtension === 'JPG' || fileExtension === 'JPEG' || fileExtension === 'jpeg') {
                if (fileSize <= 1040) {
                    setIsLoading(true);
                    try {
                        const refreshToken = await PostRequest(
                            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                            {},
                            token,
                            "refresh_token"
                        );
                        if (refreshToken) {
                            const addProfileResult = await PostRequest(
                                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SA_ADD_PROFILE_PICTURE}`,
                                dataImage,
                                token,
                                "ImageUpload"
                            );
                            if (addProfileResult && addProfileResult.status === 200) {
                                if (addProfileResult.data.code === 200) {
                                    setIsLoading(false);
                                    const Image_data = addProfileResult.data.data.filename;
                                    const profile = { ...JSON.parse(getLocalStoarge('admin_profile')), profile_pic: Image_data };
                                    // setLocalStoarge("admin_profile", JSON.stringify(profile));
                                    setSHowPic(addProfileResult.data.data.filename);
                                    setProfile(addProfileResult.data.data.filename);
                                } else {
                                    setIsLoading(false);
                                }
                            }
                        } else {
                            setIsLoading(false);
                        }
                    }
                    catch (error) {
                        setIsLoading(false);
                    }
                }
                else {
                    setAlerts({
                        alert: t("user.profile.valid_image_size"),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                    setTimeout(() => {
                        setAlerts({
                            alert: "",
                            alerttype: "",
                            alertclass: "",
                            showalert: false,
                        });
                    },
                        process.env.REACT_APP_ALERT_TIMEOUT * 1000);
                }
            }
            else {
                setAlerts({
                    alert: t("user.profile.valid_image_type"),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
                setTimeout(() => {
                    setAlerts({
                        alert: "",
                        alerttype: "",
                        alertclass: "",
                        showalert: false,
                    });
                },
                    process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            }
        }
    };

    const removeImage = async (e) => {
        e.preventDefault();
        const Image_data = "default.png";
        const profile = { profile_pic: Image_data };
        // setLocalStoarge("profile", JSON.stringify(profile));
        setSHowPic(Image_data);
        setProfile(Image_data);
    };

    const submitProfile = async (e) => {
        e.preventDefault();
        const validate = await checkValidation();
        if (!validate) {
        }
        else {
            const payload = {
                profile_pic,
                twofa,
                password: password,
                username: username,
                email: email,
                lang: parseInt(langText),
            };

            try {
                setIsLoading(true);
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    token,
                    "refresh_token"
                );
                if (refreshToken) {
                    const addProfileResult = await PostRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SA_ADD_PROFILE}`,
                        payload,
                        token,
                        ""
                    );
                    if (addProfileResult && addProfileResult.status === 200) {
                        if (addProfileResult.data.code === 200) {
                            const addedprofileresult = addProfileResult.data.data;
                            let profile_data = {
                                profile_pic: addedprofileresult.profile_pic,
                                user_name: addedprofileresult.user_name,
                            };
                            // setLocalStoarge("profile", JSON.stringify(profile_data));
                            setLocalStoarge("admin_profile", JSON.stringify(profile_data));
                            setLocalStoarge("langauage", parseInt(langText) == 0 ? "it" : "en");
                            setLangText(parseInt(langText));
                            // setIsLoading(false);
                            setIsDisabled(true);
                            handleTranslationLang();
                            setAlerts({
                                alert: t("user.profile.profilo_aggiornato"),
                                alerttype: "Success",
                                alertclass: "success",
                                showalert: true,
                            });
                            setTimeout(() => {
                                window.location.reload();
                                setAlerts({
                                    alert: "",
                                    alerttype: "",
                                    alertclass: "",
                                    showalert: false,
                                });
                            },
                             process.env.REACT_APP_ALERT_TIMEOUT * 1000);
                            // await get_Profile();
                        } else {
                            setIsLoading(false);
                            setAlerts({
                                alert: t("user.profile.profileNotUpdated"),
                                alerttype: "Error",
                                alertclass: "danger",
                                showalert: true,
                            });
                            setTimeout(() => {
                                setAlerts({
                                    alert: "",
                                    alerttype: "",
                                    alertclass: "",
                                    showalert: false,
                                });
                            },
                                process.env.REACT_APP_ALERT_TIMEOUT * 1000);
                        }
                    } else if (addProfileResult && addProfileResult.status === 203) {
                        setIsLoading(false);
                        setAlerts({
                            alert: t("user.profile.alreadyUseEmail"),
                            alerttype: "Error",
                            alertclass: "danger",
                            showalert: true,
                        });
                        setTimeout(() => {
                            setAlerts({
                                alert: "",
                                alerttype: "",
                                alertclass: "",
                                showalert: false,
                            });
                        },
                            process.env.REACT_APP_ALERT_TIMEOUT * 1000);
                    }
                } else {
                    setIsLoading(false);
                    setAlerts({
                        alert: t("user.profile.profileNotUpdated"),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                    setTimeout(() => {
                        setAlerts({
                            alert: "",
                            alerttype: "",
                            alertclass: "",
                            showalert: false,
                        });
                    },
                        process.env.REACT_APP_ALERT_TIMEOUT * 1000);
                }
            } catch (error) {
                setIsLoading(false);
                setAlerts({
                    alert: t("user.profile.profileNotUpdated"),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
                setTimeout(() => {
                    setAlerts({
                        alert: "",
                        alerttype: "",
                        alertclass: "",
                        showalert: false,
                    });
                },
                    process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            }
        }
    };

    const validatedata = async (data) => {
        const {
            profile_pic,
            vitali,
            physiological,
            mental,
            physicists,
            general_risks,
            metabolic_risks,
            measurement_of_psychological_values,
        } = data;
        let errors = validation;

        if (
            profile_pic === undefined ||
            profile_pic === null ||
            isNaN(profile_pic) === true ||
            parseInt(profile_pic) < 10
        ) {
            // errors.profilePic = t("user.login.valid_profilePic");/
            setAlerts({
                alert: t("user.profile.profilo_aggiornato"),
                alerttype: "Error",
                alertclass: "danger",
                showalert: true,
            });
            setTimeout(() => {
                setAlerts({
                    alert: "",
                    alerttype: "",
                    alertclass: "",
                    showalert: false,
                });
            },
                process.env.REACT_APP_ALERT_TIMEOUT * 1000);
        }

        if (
            vitali == undefined ||
            vitali === null
        ) {
            errors.vitali = t("user.login.valid_vitali");
        }

        if (
            physiological == undefined ||
            physiological === null ||
            (physiological !== "yes" && physiological !== "no")
        ) {
            errors.physiological = t("user.login.valid_physiological");
        }
        if (
            mental == undefined ||
            mental === null ||
            (mental !== "yes" && mental !== "no")
        ) {
            errors.mental = t("user.login.valid_mental");
        }
        if (
            physicists == undefined ||
            physicists === null ||
            (physicists !== "yes" && physicists !== "no")
        ) {
            errors.physicists = t("user.login.valid_physicists");
        }
        if (
            general_risks == undefined ||
            general_risks === null ||
            (general_risks !== "yes" && general_risks !== "no")
        ) {
            errors.general_risks = t("user.login.valid_general_risks");
        }
        if (
            metabolic_risks == undefined ||
            metabolic_risks === null ||
            (metabolic_risks !== "yes" && metabolic_risks !== "no")
        ) {
            errors.metabolic_risks = t("user.login.valid_metabolic_risks");
        }
        if (
            measurement_of_psychological_values == undefined ||
            measurement_of_psychological_values === null ||
            (measurement_of_psychological_values !== "yes" && measurement_of_psychological_values !== "no")
        ) {
            errors.measurement_of_psychological_values = t("user.login.valid_measurement_of_psychological_values");
        }
        // setValidation(errors);
        let return_val = true;
        await Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;

    };


    const get_Profile = async () => {
        if (token && token !== null) {
            const payload = {};
            try {
                setIsLoading(true);

                const profileData = await getProfile();
                if (profileData) {
                    setIsLoading(false);
                    const getProfile = profileData;
                    setLocalStoarge("langauage", getProfile.language == 0 ? "it" : "en");
                    setLangText(getProfile.language);
                    setIsDisabled(true);
                    setInputValuesnew({...getProfile,twofa: getProfile.two_fa});
                    setSHowPic(
                        getProfile.profile_pic !== null
                            ? getProfile.profile_pic
                            : "default.png"
                    );
                    setProfile(
                        getProfile.profile_pic !== null
                            ? getProfile.profile_pic
                            : "default.png"
                    );
                    setUsername(getProfile.user_name);
                    setEmail(getProfile.email);
                    // setPassword(getProfile.password);
                    handleTranslationLang();
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        }
    };

    const checkupdate = (e) => {
        const { name, checked, value } = e.target;
        let valueset = 0;
        if (checked === true) {
            valueset = 1;
        } else {
            valueset = 0;
        }
        if (name == "name") {
            setUsername(value);
        } else if (name == "email") {
            setEmail(value);
        } else if (name == "password") {
            setPassword(value);
        } else if (name == "language") {
            setLangText(value);
        } else {
            setInputValuesnew({ ...inputValuesnew, [name]: valueset });
        }
        setAlerts("", "", "", false);
    };

    const cancelEdit = () => {
        setIsDisabled(true);
        inputElement.current.reset();
        get_Profile();
    };

    useEffect(() => {
        get_Profile();
        handleTranslationLang();
        scrollMoveTop();
    }, []);

    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    };
    const [defaultImage, setDefaultImage] = useState(process.env.REACT_APP_BACKEND_BASE_URL +
        process.env.REACT_APP_STATIC_PATH + "/default.png")
    const handleImageError = (event) => {
        event.target.onerror = null; 
        event.target.src = defaultImage; // Replace with fallback image URL
      };

    return (
        <>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-2 p-0 darkimenu ipad-widhset">
                            <Super_Sidebar path="profile" />
                        </div>
                        <div className="col-md-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace profile-right">
                                <h2 className="heading-dash imposta">
                                    <Link to="/profilemenu">
                                        <img src={leftarrow} className="deskt-n" alt="icon" />
                                    </Link>
                                    {/* {t("user.profile.heading")}{" "} */}
                                    <span className="visi-none">{t("user.profile.profile")}</span>
                                </h2>
                                {showalert ? (
                                    <div className={`alert alert-${alertclass}`}>{alert}</div>
                                ) : (
                                    <></>
                                )}
                                <p className="visi-none">{t("user.profile.manage")}</p>
                                {/* <p className="visi-none">{t("user.profile.image")}</p> */}
                                <div className="row">
                                    <div className="col-md-6 fulxs max-w70">
                                        <div className="leftprofiles">
                                            <div className="l-rflex">
                                                <div className="profil_img">
                                                    <span className="circl-profile viewprofile">
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_BACKEND_BASE_URL +
                                                                process.env.REACT_APP_STATIC_PATH +
                                                                showpic
                                                            }
                                                            className="img-fluid"
                                                            alt="img"
                                                            onError={handleImageError}

                                                        />
                                                    </span>
                                                    <label htmlFor="file-upload" className="deskt-n">
                                                        <img
                                                            src={Group878}
                                                            className="profile-edit-icon deskt-n"
                                                            alt="icon"
                                                        />
                                                    </label>
                                                    <input
                                                        id="file-upload"
                                                        type="file"
                                                        className="deskt-n"
                                                        onChange={(e) => handleImageChange(e)}
                                                    />
                                                    <div className="ri-dete visi-none">
                                                        {/* {(!IsDisabled)?
                          <> */}
                                                        {/* <label
                              htmlFor="file-upload"
                              className="buttons height-bnt"
                            > */}
                                                        {/* {t("user.profile.modify")} */}
                                                        {/* </label> */}
                                                        <input
                                                            id="file-upload"
                                                            type="file"
                                                            onChange={(e) => handleImageChange(e)}
                                                        />
                                                    </div>

                                                    {!IsDisabled ? (
                                                        <>
                                                            <label htmlFor="file-upload" className="deskt-n">
                                                                <img
                                                                    src={Group878}
                                                                    className="profile-edit-icon deskt-n"
                                                                    alt="icon"
                                                                />
                                                            </label>
                                                            <input
                                                                id="file-upload"
                                                                type="file"
                                                                className="deskt-n"
                                                                onChange={(e) => handleImageChange(e)}
                                                            />
                                                        </>
                                                    ) : null}
                                                </div>
                                                <div className="ri-dete visi-none">
                                                    {/* {(!IsDisabled)?
                          <> */}
                                                    <label
                                                        htmlFor="file-upload"
                                                        className="buttons height-bnt max-paddings"
                                                    >
                                                        {t("user.profile.modify")}
                                                    </label>
                                                    <input
                                                        id="file-upload"
                                                        type="file"
                                                        onChange={(e) => handleImageChange(e)}
                                                    />
                                                    <button
                                                        className="delete_btn height-bnt"
                                                        onClick={(e) => removeImage(e)
                                                        }
                                                    >
                                                        <img src={ELIMINA_ICONA} alt="icon" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 fulxs max-w70"></div>
                                    <div className="col-md-6 max-w70">
                                        <form ref={inputElement}>
                                            <div className="form-group relative">
                                                <label>{t("user.profile.name")}</label>
                                                <img
                                                    src={Group878}
                                                    className="edit-icon visi-none"
                                                    alt="icon"
                                                // onClick={()=>setIsDisabled(false)
                                                // }
                                                />
                                                <input
                                                    type="text"
                                                    onChange={(e) => checkupdate(e)}
                                                    value={username}
                                                    name="name"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    aria-describedby="emailHelp"
                                                // placeholder=""
                                                // disabled={((IsDisabled)) ? true : true}
                                                />
                                                {/* {(validation.username && validation.username!="") ? (
                          <p className="danger">{validation.username}</p>
                        ):null} */}
                                            </div>
                                            <div className="form-group relative">
                                                <label>{t("user.profile.email")}</label>
                                                <img
                                                    src={Group878}
                                                    className="edit-icon visi-none"
                                                    alt="icon"
                                                // onClick={()=>setIsDisabled(false)}
                                                />
                                                <input
                                                    type="email"
                                                    onChange={(e) => checkupdate(e)}
                                                    value={email}
                                                    name="email"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    aria-describedby="emailHelp"
                                                // placeholder={email}
                                                />
                                                {/* {(validation.email && validation.email!="") ? (
                          <p className="danger">{validation.email}</p>
                        ) : null} */}
                                            </div>
                                            <div className="form-group relative">
                                                <label>{t("user.profile.Password")}</label>
                                                <img
                                                    src={Group878}
                                                    className="edit-icon visi-none"
                                                    alt="icon"
                                                    onClick={() => setIsDisabled(false)}
                                                />
                                                <input
                                                    type="password"
                                                    // placeholder="********"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    onChange={(e) => checkupdate(e)}
                                                    name="password"
                                                    value={inputValuesnew.password}
                                                />
                                                {/* {(validation.password && validation.password!="") ? (
                          <p className="danger">{validation.password}</p>
                        ):null} */}
                                            </div>
                                            <div className="visi-none">
                                              
                                                {/* <div className="informazioni">
                                                    <h4>{t("user.profile.personal_info")} </h4>
                                                    <p>{t("user.profile.data_changed")}</p>
                                                    <Link
                                                        to={`./questionnare`}
                                                        className="buttons modifica modifi-m"
                                                    >
                                                        {t("user.profile.modify")}
                                                    </Link>
                                                    <img
                                                        src={Group878}
                                                        className="deskt-n modifiiconi"
                                                        alt="icon"
                                                    />
                                                </div> */}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-6 max-w70">
                                        <form>
                                            <div className="checki-r">                                                                                                                      
                                                <div className="d-flexswitch">
                                                    <div className="vb">
                                                        <h4>{t("user.profile.2FA")}</h4>
                                                    </div>
                                                    <div className="nb">
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => checkupdate(e)}
                                                                key={Math.random()}
                                                                defaultChecked={
                                                                    inputValuesnew.twofa == 0 ? false : true
                                                                }
                                                                name="twofa"
                                                            />
                                                            <span className="slider"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="d-flexswitch mt-3">
                                                    <div className="vb">
                                                        <h4>{t("user.profile.language")}</h4>
                                                    </div>
                                                    <div className="arrow_down1">
                                                        <select
                                                            name="language"
                                                            className="border_n"
                                                            value={langText}
                                                            onChange={(e) => checkupdate(e)}
                                                        >
                                                            <option value="0">Italiano</option>
                                                            <option value="1">English</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* {(!IsDisabled)?
                    <> */}
                                    <button
                                        className="buttons Salva visi-none"
                                        onClick={(e) => submitProfile(e)}
                                    >
                                        {t("user.profile.Salva_modifiche")}
                                    </button>
                                    <button
                                        className="buttons Salva deskt-n"
                                        onClick={(e) => submitProfile(e)}
                                    >
                                        {t("user.profile.Salva_modifiche")}
                                    </button>
                                    {/* </>:null} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}
                </div>
            </section>
            <div className="act-ve">
                <Super_Sidebarmob />
            </div>
        </>
    );
};
export default SA_Profile;
