import ReactDOM from "react-dom";
import React, { useEffect, useState, useRef } from "react";
import { useHistory, Link } from 'react-router-dom';
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import Modal from "../../common/modal";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge, getCompanyDetails } from "../../common/allfunction";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg"
import DOCUMENT_ICON from "../../../assets/Image/DOCUMENT_ICON.svg";
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg'
import VectorDown from "../../../assets/Image/VectorDown.svg";
import filterFactory from "react-bootstrap-table2-filter";
import delete1 from '../../../assets/Image/delete.png';
import Group698 from "../../../assets/Image/Group698.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import Group_8417 from "../../../assets/Image/Group_8417.svg";
import Group769 from "../../../assets/Image/Group769.svg";
import Group1171 from "../../../assets/Image/Group1171.svg";
import CrossAdmin from "../../../assets/Image/cross-admin-icon.svg";
import DeleteBin from '../../../assets/Image/delete-bin.svg'
import Delete_Icon_popup from '../../../assets/Image/Delete_Icon_popup.svg'
import Popup_Cross from '../../../assets/Image/Popup_Cross.svg'
import Popup_right from '../../../assets/Image/Popup_right.svg'
import PREV_PAGE_ICON from '../../../assets/Image/PREVPAGE_ICON.svg';
import ELIMINA_ICONA from "../../../assets/Image/ELIMINA_ICONA.svg";
import USER_ICONA from '../../../assets/Image/USER_ICON23.svg';
import EDIT_PENCIL from '../../../assets/Image/EDIT_PENCIL.svg';
import Group1283 from '../../../assets/Image/Group1283.png';
import '../super_admin_assets/scss/protocol.scss';
import PutRequest from "../../apiConnection/putRequest";
import DatePicker from "react-datepicker";
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import "react-datepicker/dist/react-datepicker.css";
import { fileUploadApi } from "../../apiConnection/fileUpload";
import moment from "moment";
import { SketchPicker } from 'react-color';
import { async } from "q";
import { isMobile, isTablet } from "react-device-detect";
import ReactPaginate from 'react-paginate';
import Right_Ans from '../../../assets/Image/right_ans.svg';
import Wrong_Ans from '../../../assets/Image/wrong_ans.svg';
import { buildStyles } from "react-circular-progressbar";
const Edit_Protocol = (props) => {
    const [errorfetch, setErrorfetch] = useState(
        props.match.params?.errorfetch && props.match.params?.errorfetch !== ""
            ? props.match.params?.errorfetch
            : ""
    );
    let history = useHistory();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const inputRef = useRef(null);
    const { SearchBar } = Search;
    const [files, setFiles] = useState([]);
    const fileUpload = useRef(null);
    const [company_id, setCompany_id] = useState(props.match.params.company_id)
    const [companyUserData, setCompanyUserData] = useState([])
    const [companyAdminData, setCompanyAdminData] = useState([])
    const path = window.location.pathname;
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [detail, setDetail] = useState("");
    const [loading, setLoading] = useState(true);
    const [tableData, settableData] = useState("");
    const [disableUserModal, setDisableUserModal] = useState(false)
    const [enableUserModal, setEnableUserModal] = useState(false)
    const [disableAdminModal, setDisableAdminModal] = useState(false)
    const [enableAdminModal, setEnableAdminModal] = useState(false)
    const [changeUserStatusId, setChangeUserStatusId] = useState({})
    const [popupLoader1, setPopupLoader1] = useState(false)
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [popupLoader3, setPopupLoader3] = useState(false)
    const [companyDetails, setCompanyDetails] = useState({})
    const [confirmEnable, setConfirmEnable] = useState(false);
    const [popupUserId, setPopupUserId] = useState();
    const [isDisabled, setIsDisabled] = useState(false)
    const [disableInput, setDisableInput] = useState(props.match.params.id)
    const [adminEnableDisable, setAdminEnableDisable] = useState({})
    const [excelPopupPage, setExcelPopupPage] = useState(1);
    const [selectedUser, setSelectedUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [itemsPerPage, setItemPerPage] = useState(12)
    const [excelData, setExcelData] = useState([]);
    const [excelFilename, setExcelFilename] = useState('');
    const [disableButton, setDisableButton] = useState(false)

    const [personalText, setPersonalText] = useState();
    const [alerts, setAlerts] = useState({ alert: "", alerttype: "", alertclass: "danger", showalert: false, });
    const { alert, alerttype, alertclass, showalert } = alerts;
    const [profile_pic, setProfile] = useState();
    const [resetConfirmModal, setResetConfirmModal] = useState(false);
    const [changePassModal, setChangePassModal] = useState(false);
    const [showpic, setSHowPic] = useState("building.jpg");
    const [inputValue, setInputValue] = useState({
        name: "", serviceStartDate: "", weeksProtocol: 0, referent: "", toiMeasurement: 0,
        parameterMeasurements: 0, color1: "#FEE01A", color2: "#0057B7", address: '', user: 0,
        admin: 0, email: "", contractExpiry: "", reminderDay: "", measurementsReminder: 0, icon: "building.jpg", configId: "", url: "",consentIdEn:"",consentIdIt:""
    })
    const [defaultImage, setDefaultImage] = useState(process.env.REACT_APP_BACKEND_BASE_URL +
        process.env.REACT_APP_STATIC_PATH_COMPANY_ICON + "/building.jpg")
    const [inputDateValue, setInputDateValue] = useState({
        serviceStartDate: null, contractExpiry: null
    })
    const handleImageError = (event) => {
        event.target.onerror = null;
        event.target.src = defaultImage; // Replace with fallback image URL
    };
    const [weekOption, setWeekOption] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])
    const [showColorPicker1, setShowColorPicker1] = useState(false)
    const [showColorPicker2, setShowColorPicker2] = useState(false)
    const [allReminderDay, setAllReminderDay] = useState([t("superadmin.protocol.mon"), t("superadmin.protocol.tue"), t("superadmin.protocol.wed"), t("superadmin.protocol.thu"), t("superadmin.protocol.fri")])
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [textCopy, setTextCopy] = useState(false)

    const [addAdminModal, setAddAdminModal] = useState(false)
    const [importUser, setImportUserModal] = useState(false)
    const [adminInputValues, adminInputValue] = useState({
        fullName: "",
        designation: "",
        email: "",
        powerBI_ID_ENG: "",
        powerBI_ID_ITA: "",
        powerBI_role_ENG: "",
        powerBI_role_ITA: "",
        password: "",
        confirmPassword: "",
    });
    const [resetInputValues, setResetInputValue] = useState({
        password: "",
        confirmPassword: ""
    });
    const [validation, setValidation] = useState({
        fullName: "",
        designation: "",
        email: "",
        powerBI_ID_ENG: "",
        powerBI_ID_ITA: "",
        powerBI_role_ENG: "",
        powerBI_role_ITA: "",
        password: "",
        confirmPassword: "",
    });
    const [editAdminModal, setEditAdminModal] = useState(false)
    const [companyAdminDetails, setCompanyAdminDetails] = useState({
        fullName: "",
        designation: "",
        email: "",
        powerBI_ID_ENG: "",
        powerBI_ID_ITA: "",
        powerBI_role_ENG: "",
        powerBI_role_ITA: ""
    })
    const [adminId, setAdminId] = useState();
    const [userId, setUserId] = useState();
    const [editUserModal, setEditUserModal] = useState(false)
    const [companyUserDetails, setCompanyUserDetails] = useState({
        fullName: "",
        email: "",
        language: ""
    })
    const [fileStatus, setFileSatus] = useState({
        already_imported: 0,
        import_successful: 0,
        not_imported: 0
    });
    const [changeCompanyStatus, setChangeCompanyStatus] = useState({})
    const [enableCompanyModal, setEnableCompanyModal] = useState(false)
    const [disableCompanyModal, setDisableCompanyModal] = useState(false)
    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div className="d-flex">
                <button className="table-action" onClick={() => window.location.href = `/sa/userLog/${row.user_id}`} title="Click for view"><img src={ExpandIcon} alt="icon" /></button>
                <button className="table-action" onClick={() => { getUserDetails(row.user_id) }} title="Click for edit"><img src={Group878} alt="icon" /></button>
                {(row.active == false) ? <button className="table-action bg-green-delete" onClick={() => { setEnableCompanyModal(true); setChangeCompanyStatus({ user_id: row.user_id, active: row.active, name: row.name }) }} title=" Click for enable">
                    <img src={CrossAdmin} alt="icon" />
                </button> :
                    <button className="table-action bg-green-delete" onClick={() => { setDisableCompanyModal(true); setChangeCompanyStatus({ user_id: row.user_id, active: row.active, name: row.name }) }} title="Click for disable">
                        <img src={Group1171} alt="icon" />
                    </button>
                }
                <button className="table-action" onClick={() => { setDisableUserModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 0, name: row.name }) }} title="Click for delete"><img src={DeleteBin} alt="icon" /></button>

            </div>
        )
    }
    const actionButtonAdmin = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div style={{ width: '100px' }} >
                <button className="table-action" onClick={() => getAdminDetails(row.user_id)} title="Click for edit"><img src={Group878} alt="icon" /></button>
                {!row.status ? <button className="table-action bg-green-delete" onClick={() => { setEnableAdminModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 1, name: row.name }) }} title="Click for enable">
                    <img src={CrossAdmin} alt="icon" />
                </button> :
                    <button className="table-action bg-green-delete" onClick={() => { setDisableAdminModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 0, name: row.name }) }} title="Click for disable">
                        <img src={Group1171} alt="icon" />
                    </button>
                }
            </div>
        )
    }
    const [generalColumn, setGeneralColumn] = useState([{ text: "Nome e Cognome", dataField: "name", sort: true }]);
    const [adminColumn, setAdminColumn] = useState([{ text: "Nome e Cognome", dataField: "name", sort: true }]);
    const handleSelectUser = (id) => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        if (selectedUser.some(user => user.user_id === id)) {
            let arr = selectedUser;
            const user = arr.find(user => user.user_id === id);
            user.checked = user.checked === 1 ? 0 : 1;
            setSelectedUser(arr)
            setCurrentItems(arr?.slice(0, itemsPerPage))
        } else {
            setSelectedUser([...selectedUser, id])

        }
    }
    const selectAll = () => {
        const users = selectedUser;
        users.forEach(user => {
            user.checked = 1;
        });
        setSelectedUser(users)
        setCurrentItems(users?.slice(0, itemsPerPage))
    }
    const getCompanyAllUser = async () => {
        const payload = {
            "company": company_id,
            "role_id": "1"
        };
        try {
            setIsLoading(true);

            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_ALL_USER}`,
                    payload,
                    "",
                    ""
                );
                if (getAllDataResult && getAllDataResult.status === 200) {
                    setIsLoading(false);
                    if (getAllDataResult.data?.data?.length > 0) {

                        let arr = [];
                        getAllDataResult.data.data.forEach((item) => {
                            // if (item.status == 1) {
                            arr = [...arr, {
                                name: item.name || "",
                                role: (item.designation == "null") ? "" : item.designation,
                                email: item.email || '',
                                last_access: item.last_Access ? `${moment(new Date(item.last_Access * 1000)).format("YY.MM.DD").replace(/YYYY/g, "YY")}` : '-',
                                measurements: item.scan > -1 ? item.scan : '',
                                user_id: item.user_id || '',
                                role_id: item.role_id || '',
                                status: item.status || 0,
                                active: item.active || false
                            }]
                            // }

                        })
                        setCompanyUserData(arr)

                    }
                } else {
                    setIsLoading(false);
                    setLoading(false);
                }
                getCompanyAllAdmin()

            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };

    const handlePageClick = (event) => {
        let page = event.selected + 1;
        let start = event.selected * itemsPerPage;
        setCurrentPage(page)
        setCurrentItems(excelData.slice(start, start + itemsPerPage))
    };
    const getCompanyAllAdmin = async () => {
        setIsLoading(true);
        const payload = {
            "company": company_id,
            "role_id": "2"
        };
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDashboard_Data = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_USER_LIST}`,
                    payload,
                    "",
                    ""
                );
                if (getDashboard_Data && getDashboard_Data.status === 200) {
                    setIsLoading(false);
                    const alldata = getDashboard_Data.data.data;
                    let arr = [];
                    alldata.forEach((item) => {
                        arr = [...arr, {
                            name: item.name || "",
                            email: item.email || '',
                            last_access: item.last_Access ? `${moment(new Date(item.last_Access * 1000)).format("DD.MM.YY").replace(/YYYY/g, "YY")}` : '',
                            powerbi_role: item.power_bi_role.substring(0, 3).concat('...') || '',
                            powerbi_id: item.power_bi_id.substring(0, 20).concat('...') || '',
                            user_id: item.user_id || '',
                            role_id: item.role_id || '',
                            status: item.status || 0
                        }]
                    })
                    setCompanyAdminData(arr)
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    const getAdminDetails = async (adminId) => {
        setAdminId(adminId);
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {

                const getCompanyAdminData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VIEW_COMPANY_ADMIN}?user_id=${adminId}`,
                );

                if (getCompanyAdminData && getCompanyAdminData.status === 200) {
                    setIsLoading(false);
                    setCompanyAdminDetails(getCompanyAdminData.data.data);
                    setEditAdminModal(true);

                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getUserDetails = async (userId) => {
        setUserId(userId);
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getCompanyUserData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VIEW_COMPANY_USER}?user_id=${userId}`,
                );
                if (getCompanyUserData && getCompanyUserData.status === 200) {
                    setIsLoading(false);
                    setCompanyUserDetails(getCompanyUserData.data.data);
                    setEditUserModal(true);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, month, year].join(".");
    };
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                text: t("superadmin.protocol.name_and_surname"),
                dataField: "name",
                sort: true,
            },
            {
                text: t("superadmin.protocol.role"),
                dataField: "role",
                sort: true,
            },
            {
                text: "E-mail",
                dataField: "email",
                sort: true,
            },
            {
                text: t("superadmin.protocol.last_access"),
                dataField: "last_access",
                sort: true,
            },
            {
                text: t("superadmin.protocol.measurements"),
                dataField: "measurements",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtons,
                sort: true,
                headerAlign: "left"
            },
        ])
        setAdminColumn([
            {
                text: t("superadmin.protocol.name_and_surname"),
                dataField: "name",
                sort: true,
            },
            {
                text: "E-mail",
                dataField: "email",
                sort: true,
            },
            {
                text: "PowerBI Role",
                dataField: "powerbi_role",
                sort: true,
            },
            {
                text: "PowerBI ID",
                dataField: "powerbi_id",
                sort: true,
            },
            {
                text: t("superadmin.protocol.last_access"),
                dataField: "last_access",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtonAdmin,
                sort: true,
                headerAlign: "left"
            },
        ])
    };


    const callApis = async () => {
        let tabTest = getLocalStoarge("company_general_tab");
        (tabTest == '' || tabTest == null) ? setPersonalText("general") : setPersonalText(tabTest);
        setIsLoading(true)
        let company = await getCompanyDetails(company_id);
        if (company) {
            setCompanyDetails(company);
            setAdminEnableDisable({
                company_id: props.match.params.company_id,
                company_dynamic_id: company.company_registration_id,
                brainwellnessModule: company.brainwellnessModule,
                trainingModule: company.trainingModule
            });
        }
        else {
            setIsLoading(false)
        }
        await getCompanyAllDetails();
        await getCompanyAllUser();
    }

    useEffect(() => {
        callApis()
        handleTranslationLang();
    }, [])

    useEffect(() => {
        calculateWeek();
    }, [inputDateValue])

    const handleCopyClick = async () => {
        navigator.clipboard.writeText(inputRef.current.value);
        setTimeout(() => {
            setTextCopy(false)
        }, 2000)
    };
    const [changeName, setChangeName] = useState({});
    const [isChecked, setIsChecked] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleConfirm = async () => {
        setIsChecked(prevChecked => !prevChecked);
        setConfirmEnable(false);
        const payload = changeName;
        await adminEnableDisableModule(payload)

    };

    const handleCancel = () => {
        setConfirmEnable(false);
    };

    const enableDisable = async (e) => {
        const { name, checked } = e.target;
        const valueset = checked ? 1 : 0;
        let msg = (checked) ? 'enable' : 'disable';
        setConfirmEnable(true);
        setChangeName({ ...adminEnableDisable, [name]: valueset });
        if (name == 'brainwellnessModule') {
            setTextMessage(t("superadmin.sidebar." + msg + "_br"));
            setHeadingText(t("superadmin.sidebar.brain_wellness"));

        }
        else if (name == 'trainingModule') {
            setTextMessage(t("superadmin.sidebar." + msg + "_wl"));
            setHeadingText(t("superadmin.sidebar.farmazione"));

        }
    };

    const [textMessage, setTextMessage] = useState('');
    const [headingText, setHeadingText] = useState('');

    const adminEnableDisableModule = async (payload) => {
        setIsLoading(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const result = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ENABLE_DISABLE_MODULE}`,
                    payload,
                    "",
                    ""
                );
                if (result.data.code === 200) {
                    // console.log('payload ', payload.brainwellnessModule);                 
                    const rec = await getCompanyDetails(company_id)
                    setAdminEnableDisable({
                        company_id: props.match.params.company_id,
                        company_dynamic_id: rec.company_registration_id,
                        brainwellnessModule: rec.brainwellnessModule,
                        trainingModule: rec.trainingModule
                    });
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                }

            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (showColorPicker1 && ref1.current && !ref1.current.contains(e.target)) {
                setShowColorPicker1(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showColorPicker1]);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (showColorPicker2 && ref2.current && !ref2.current.contains(e.target)) {
                setShowColorPicker2(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [showColorPicker2]);
    const handleChange = (event) => {
        let { name, value } = event.target;
        adminInputValue({ ...adminInputValues, [name]: value });
        setAlerts("", "", "", false);
        setValidation({
            fullName: "",
            designation: "",
            email: "",
            powerBI_ID_ENG: "",
            powerBI_ID_ITA: "",
            powerBI_role_ENG: "",
            powerBI_role_ITA: "",
            password: "",
            confirmPassword: ""
        })
    }
    const handleChangePassword = (e1) => {
        let { name, value } = e1.target;
        // if(e1.target.name)
        // {
        //     e1.target.value= e1.target.value.replace(/^\s+/g, '');
        //     // e1.target.value= e1.target.value.trim();

        // }
        setResetInputValue({ ...resetInputValues, [name]: value });
    }

    const handleChangeEdit = (e) => {
        let { name, value } = e.target;
        if (e.target.name) {
            e.target.value = e.target.value.replace(/^\s+/g, '');
        }
        setCompanyAdminDetails({ ...companyAdminDetails, [name]: value });
    }

    const handleChangeUserEdit = (e) => {
        let { name, value } = e.target;
        setCompanyUserDetails({ ...companyUserDetails, [name]: value });
    }
    useEffect(() => {
        fileArr()
    }, [files]);
    const adminValidation = () => {
        let errors = validation;
        let userExp = new RegExp(/^[a-zA-Z ]*$/);
        let Emailregex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        const Passregex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        );
        const fullName = adminInputValues.fullName.replace(/^\s+/g, '');
        if (!fullName || fullName.length < 3 || fullName.length > 30) {
            errors.fullName = t('user.profile.valid_user_name');
        } else if (!fullName.match(userExp)) {
            errors.fullName = t('user.profile.valid_user_name');
        } else {
            errors.fullName = "";
        }

        const designation = adminInputValues.designation.replace(/^\s+/g, '');
        if (!designation || designation.length < 3 || designation.length > 30) {
            errors.designation = t('user.profile.valid_designation');
        } else if (!designation.match(userExp)) {
            errors.designation = t('user.profile.valid_designation');
        } else {
            errors.designation = "";
        }
        const email = adminInputValues.email.trim();
        if (!email) {
            errors.email = t('user.login.valid_email');
        } else if (!email.match(Emailregex)) {
            errors.email = t('user.login.valid_email');
        } else {
            errors.email = "";
        }
        const password = adminInputValues.password;
        if (!password) {
            errors.password = t('user.login.password_req');
        }
        else if (!password.match(Passregex)) {
            errors.password = t('admin.profile.valid_admin_password3')
        }
        else {
            errors.password = "";
        }
        const confirmPassword = adminInputValues.confirmPassword;
        if (!confirmPassword) {
            errors.confirmPassword = t('user.login.password_req');
        }
        else if (!confirmPassword.match(Passregex)) {
            errors.confirmPassword = t('admin.profile.valid_admin_password3')
        }
        else if (confirmPassword != password) {
            errors.confirmPassword = t('admin.profile.confirm_password_error')
        }
        else {
            errors.confirmPassword = "";
        }
        setValidation({ ...errors });
        let return_val = true;
        Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;
    }
    const resetPasswordValidation = () => {
        let errors = validation;

        const Passregex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        );
        let noLeadingSpaceRegex = /^(?!\s)/;
        const password = resetInputValues.password;
        if (!password) {
            errors.password = t('user.login.password_req');
        }
        else if (!noLeadingSpaceRegex.test(password)) {
            errors.password = t('admin.profile.password_error_2')
        }
        else if (!password.match(Passregex)) {
            errors.password = t('admin.profile.valid_admin_password3')
        }
        else {
            errors.password = "";
        }
        const confirmPassword = resetInputValues.confirmPassword;
        if (!confirmPassword) {
            errors.confirmPassword = t('user.login.password_req');
        }
        else if (!noLeadingSpaceRegex.test(confirmPassword)) {
            errors.confirmPassword = t('admin.profile.confirm_password_error_2')
        }
        else if (!confirmPassword.match(Passregex)) {
            errors.confirmPassword = t('admin.profile.valid_admin_password3')
        }
        else if (confirmPassword != password) {
            errors.confirmPassword = t('admin.profile.confirm_password_error')
        }
        else {
            errors.confirmPassword = "";
        }
        setValidation({ ...errors });
        let return_val = true;
        Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;
    }

    const adminEditValidation = () => {
        let errors = validation;
        let userExp = new RegExp(/^[a-zA-Z ]*$/);
        let Emailregex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        const fullName = companyAdminDetails.name.trim();
        if (!fullName || fullName.length < 3 || fullName.length > 30) {
            errors.name = t('user.profile.valid_user_name');
        } else if (!fullName.match(userExp)) {
            errors.name = t('user.profile.valid_user_name');
        } else {
            errors.name = "";
        }
        const designation = companyAdminDetails.designation.trim();
        if (!designation || designation.length < 3 || designation.length > 30) {
            errors.designation = t('user.profile.valid_designation');
        } else if (!designation.match(userExp)) {
            errors.designation = t('user.profile.valid_designation');
        } else {
            errors.designation = "";
        }
        const email = companyAdminDetails.email;
        if (!email) {
            errors.email = t('user.login.valid_email');
        } else if (!email.match(Emailregex)) {
            errors.email = t('user.login.valid_email');
        } else {
            errors.email = "";
        }

        setValidation({ ...errors });
        let return_val = true;
        Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;
    }

    const userEditValidation = () => {
        let errors = validation;
        let userExp = new RegExp(/^[a-zA-Z ]*$/);
        let Emailregex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        const fullName = companyUserDetails.name.trim();
        if (!fullName || fullName.length < 3 || fullName.length > 30) {
            errors.name = t('user.profile.valid_user_name');
        } else if (!fullName.match(userExp)) {
            errors.name = t('user.profile.valid_user_name');
        } else {
            errors.name = "";
        }

        const email = companyUserDetails.email;
        if (!email) {
            errors.email = t('user.login.valid_email');
        } else if (!email.match(Emailregex)) {
            errors.email = t('user.login.valid_email');
        } else {
            errors.email = "";
        }

        setValidation({ ...errors });
        let return_val = true;
        Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;
    }

    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-center align-items-center pagination-list">
                {pages.map((p, index) => {
                    if (p.page === "<") return <div key={index} className="page mx-0" onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                    if (p.page === ">") return <div key={index} className="page mx-0" onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                    if (p.active) return <div key={index} className="active mx-0" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    return (
                        <div className="page mx-0" key={index} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    )
                })}
                {/* <li className="">
                    <a href="#" onClick={handleClick} style={activeStyle}>{page}</a>
                </li> */}
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }
    const closeModal = () => {
        setChangeUserStatusId({})
        setDisableAdminModal(false)
        setEnableAdminModal(false)
        setDisableUserModal(false)
        setDisableCompanyModal(false)
        setEnableCompanyModal(false)
        setPopupLoader1(false)
        setPopupLoader2(false)
        setPopupLoader3(false)
        setAddAdminModal(false)
        setImportUserModal(false)
        setEditAdminModal(false)
        setCompanyAdminDetails({})
        setConfirmEnable(false);
        setFiles([]);
        setExcelPopupPage(1);
        setValidation({
            fullName: "",
            designation: "",
            email: "",
            powerBI_ID_ENG: "",
            powerBI_ID_ITA: "",
            powerBI_role_ENG: "",
            powerBI_role_ITA: "",
            password: "",
            confirmPassword: ""
        })
        setAdminId();
        setUserId();
        setEditUserModal(false)
        setCompanyUserDetails({})
        setResetInputValue({})
        setDisableButton(false)
        setIsDisabled(false)
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    }

    // const reloadPage = () => {
    //     window.location.reload();
    // }
    const changeCompanyUserStatus = async () => {
        const payload = changeUserStatusId
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDashboard_Data = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACTIVE_DEACTIVE_COMPANY_USER}`,
                    payload,
                    "",
                    ""
                );
                if (getDashboard_Data && getDashboard_Data.status === 200) {
                    setIsLoading(false);
                    setIsDisabled(false);
                    closeModal();
                    getCompanyAllUser();
                } else {
                    setIsLoading(false);
                    setDisableButton(false);
                }
            } else {
                setIsLoading(false);
                setDisableButton(false);

            }
        } catch (error) {
            setIsLoading(false);
            setDisableButton(false);

        }
    }
    const tryAgain = () => {
        setExcelPopupPage(1);
        getCompanyAllUser();
    }
    const disableUserModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Delete_Icon_popup} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.protocol.popup.delete_user_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={isDisabled}
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader1 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6 className="visi-project">{t("superadmin.protocol.popup.delete_user_content")} "{changeUserStatusId?.name}"?</h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button type="button" className="buttons modal-button mr-3" disabled={isDisabled} onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button type="button" className="buttons modal-button" disabled={isDisabled} onClick={() => { changeCompanyUserStatus(); setPopupLoader1(true); setIsDisabled(true) }}>{t("superadmin.protocol.popup.delete")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const confirmationBox = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{headingText} </strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={isDisabled}
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            <h6>{textMessage}</h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button type="button" className="buttons modal-button mr-3" disabled={isDisabled} onClick={() => handleConfirm()}>{t("superadmin.protocol.popup.confirm")}</button>
                            <button type="button" className="buttons modal-button" disabled={isDisabled} onClick={() => { handleCancel() }}>{t("superadmin.protocol.popup.cancel")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const disableAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.protocol.popup.disable_admin_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={isDisabled}
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">

                            {popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6 className="visi-project"><strong>{t("superadmin.protocol.popup.disable_admin_content")} "{changeUserStatusId?.name}"?</strong></h6>
                        </div>

                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button type="button" className="buttons modal-button mr-3" disabled={isDisabled} onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button type="button" className="buttons modal-button" disabled={isDisabled} onClick={() => { changeCompanyUserStatus(); setPopupLoader2(true); setIsDisabled(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const enableAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_right} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.protocol.popup.enable_admin_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={isDisabled}
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader3 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6 className="visi-project"><strong>{t("superadmin.protocol.popup.enable_admin_content")} "{changeUserStatusId?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button type="button" className="buttons modal-button mr-3" disabled={isDisabled} onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button type="button" className="buttons modal-button" disabled={isDisabled} onClick={() => { changeCompanyUserStatus(); setIsDisabled(true); setPopupLoader3(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    /** Reset Password Modal open */
    const resetPassword = () => {
        // closeModal();
        setResetConfirmModal(true);
        setEditAdminModal(false);
        setEditUserModal(false);
    }
    /** Reset password confirmation screen */
    const resetConfirmationScreen = () => {
        return (
            // <>
            <div className="model-pop">
                <div className="modal-header">
                    <h5 className="modal-title pop_heading" id="exampleModalLabel">
                        <strong>{t("admin.profile.reset_password")}</strong>
                    </h5>
                </div>
                <div className="modal-body scroll-pop scroll-pop-new">
                    <p>{t("admin.profile.reset_password_msg")}</p>
                    <div className="d-flex justify-content-end buttons-superadmin p-2">
                        <button className="buttons modal-button mr-3" onClick={() => setResetConfirmModal(false)}>{t("superadmin.protocol.popup.cancel")}</button>
                        <button type="button" className="buttons modal-button" onClick={() => ChangePasswordenable()}>{t("admin.mailValidation.confirm_btn")}</button>
                    </div>
                </div>
            </div>
            // </>
        );
    }
    const ChangePasswordenable = () => {
        setResetConfirmModal(false);
        setChangePassModal(true);

    }
    /** Change Password Screen after confirmation screen */
    const changePasswordScreen = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={USER_ICONA} alt="icon" />{" "}
                            </div>
                            <strong>{t("admin.profile.reset_password")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={disableButton}
                            onClick={() => setChangePassModal(false)}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <form className="registrati-form registrati-form-new nova-program" >
                            <div className="container">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.password")}</label>
                                            <input type="text" className="form-control" name="password" onChange={(e1) => handleChangePassword(e1)}
                                                value={resetInputValues.password}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.confirm_password")}</label>
                                            <input type="text" className="form-control" name="confirmPassword" onChange={(e1) => handleChangePassword(e1)}
                                                value={resetInputValues.confirmPassword}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            {validation.password && (
                                                <p className="danger">{validation.password}</p>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            {validation.confirmPassword && (
                                                <p className="danger">{validation.confirmPassword}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showalert ?
                                <div className={`alert alert-${alertclass}`}>{alert}</div>
                                :
                                (popupLoader1) ?
                                    popupLoader1 && <div className="d-flex alert alert-info">
                                        <div className="spinner-container-new">
                                            <div className="loading-spinner-new">
                                            </div>
                                        </div>
                                        <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                                    </div>
                                    : null

                            }

                            <div className="d-flex justify-content-end buttons-superadmin p-2">
                                <button type="button" className="buttons modal-button mr-3" disabled={disableButton} onClick={() => { setAdminId(); setChangePassModal(false); setPopupLoader1(false); }}>{t("superadmin.protocol.popup.cancel")}</button>
                                <button type="button" disabled={disableButton} className="buttons modal-button" onClick={() => { submitResetPasswordApi(); }}>{t("superadmin.protocol.popup.confirm")}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )
    }
    const submitResetPasswordApi = async () => {
        const validate = resetPasswordValidation();
        if (validate) {
            setPopupLoader1(true)
            setDisableButton(true);
            const payload =
            {
                user_id: (adminId == '' || adminId == undefined) ? userId : adminId,
                password: resetInputValues.password,
                confirm_password: resetInputValues.confirmPassword,

            }
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    "",
                    "refresh_token"
                );
                if (refreshToken) {
                    const getDashboard_Data = await PutRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RESET_COMPANY_ADMIN_PASSWORD}`,
                        payload,
                        "",
                        ""
                    );
                    if (getDashboard_Data && getDashboard_Data.data.code === 200) {
                        setAlerts({
                            alert: t('admin.profile.admin_reset_password'),
                            alerttype: "Success",
                            alertclass: "success",
                            showalert: true,
                        });
                        setTimeout(() => {
                            setChangePassModal(false);
                            setAlerts("", "", "", false);
                            closeModal();
                        }, 3000)

                    } else {
                        setAlerts({
                            alert: t('admin.profile.admin_reset_password_error'),
                            alerttype: "Error",
                            alertclass: "danger",
                            showalert: true,
                        });
                        setDisableButton(false);
                    }
                } else {
                    setDisableButton(false);
                    setAlerts({
                        alert: t('admin.profile.admin_reset_password_error'),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                }
            } catch (error) {
                setDisableButton(false);
                setAlerts({
                    alert: t('admin.profile.admin_reset_password_error'),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
            }
        }
        else {
            setDisableButton(false);
            setAlerts("", "", "", false);
        }
    }
    const addAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={USER_ICONA} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.new_admin")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <form className="registrati-form registrati-form-new nova-program" >
                            <div className="container">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label ">{t("superadmin.protocol.fullname")}</label>
                                            <input type="text" className="form-control" name="fullName" onChange={handleChange} value={adminInputValues?.fullName.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.fullname")}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.fullName && (
                                                <p className="danger">{validation.fullName}</p>
                                            )}
                                        </div>

                                        <div className="col-md-12">
                                            <label className="form-label mt-2">{t("superadmin.protocol.role")}</label>
                                            <input type="text" className="form-control" name="designation" onChange={handleChange}
                                                value={adminInputValues?.designation.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.role")}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.designation && (
                                                <p className="danger">{validation.designation}</p>
                                            )}
                                        </div>

                                        <div className="col-md-12 ">
                                            <label className="form-label mt-2">{t("superadmin.protocol.email")}</label>
                                            <input type="email" className="form-control" name="email" onChange={handleChange}
                                                value={adminInputValues?.email.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.email")}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.email && (
                                                <p className="danger">{validation.email}</p>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.powerBI_roleITA")}</label>
                                            <input type="text" className="form-control" name="powerBI_role_ITA" onChange={handleChange}
                                                value={adminInputValues?.powerBI_role_ITA.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.powerBI_roleITA")}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.powerBI_roleIDITA")}</label>
                                            <input type="text" className="form-control" name="powerBI_ID_ITA" onChange={handleChange}
                                                value={adminInputValues?.powerBI_ID_ITA.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.powerBI_roleIDITA")}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.powerBI_roleENG")}</label>
                                            <input type="text" className="form-control" name="powerBI_role_ENG" onChange={handleChange}
                                                value={adminInputValues?.powerBI_role_ENG.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.powerBI_roleENG")}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.powerBI_roleIDENG")}</label>
                                            <input type="text" className="form-control" name="powerBI_ID_ENG" onChange={handleChange}
                                                value={adminInputValues?.powerBI_ID_ENG.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.powerBI_roleIDENG")}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.password")}</label>
                                            <input type="text" className="form-control" name="password" onChange={handleChange}
                                                value={adminInputValues?.password.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.password")}

                                            ></input>
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.confirm_password")}</label>
                                            <input type="text" className="form-control" name="confirmPassword" onChange={handleChange}
                                                value={adminInputValues?.confirmPassword.replace(/^\s+/g, '')}
                                                placeholder={t("superadmin.protocol.confirm_password")}

                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            {validation.password && (
                                                <p className="danger">{validation.password}</p>
                                            )}
                                        </div>
                                        <div className="col-md-6">
                                            {validation.confirmPassword && (
                                                <p className="danger">{validation.confirmPassword}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showalert ?
                                <div className={`alert alert-${alertclass}`}>{alert}</div>
                                :
                                (popupLoader1) ?
                                    <div className="d-flex alert alert-info">
                                        <div className="spinner-container-new">
                                            <div className="loading-spinner-new">
                                            </div>
                                        </div>
                                        <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                                    </div>
                                    : null
                            }

                            <div className="d-flex justify-content-end buttons-superadmin p-2">
                                <button type="button" className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                                <button type="button" className="buttons modal-button" disabled={disableButton} onClick={() => submitAdminDetails()}>{t("superadmin.protocol.popup.confirm")}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )

    }

    const editAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" />{" "}
                            </div>
                            <strong>{t("admin.profile.modify_admin")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <form className="registrati-form registrati-form-new nova-program" >
                            <div className="container">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label ">{t("superadmin.protocol.fullname")}</label>
                                            <input type="text" className="form-control" name="name" defaultValue={companyAdminDetails.name} onChange={(e) => handleChangeEdit(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.name && (
                                                <p className="danger">{validation.name}</p>
                                            )}
                                        </div>

                                        <div className="col-md-12">
                                            <label className="form-label mt-2">{t("superadmin.protocol.role")}</label>
                                            <input type="text" className="form-control" defaultValue={companyAdminDetails.designation} name="designation" onChange={(e) => handleChangeEdit(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.designation && (
                                                <p className="danger">{validation.designation}</p>
                                            )}
                                        </div>

                                        <div className="col-md-12 ">
                                            <label className="form-label mt-2">{t("superadmin.protocol.email")}</label>
                                            <input type="email" className="form-control" name="email" defaultValue={companyAdminDetails.email} onChange={(e) => handleChangeEdit(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.email && (
                                                <p className="danger">{validation.email}</p>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.powerBI_roleITA")}</label>
                                            <input type="text" className="form-control" name="powerBI_role_ITA" defaultValue={companyAdminDetails.powerBI_role_ITA} onChange={(e) => handleChangeEdit(e)}

                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.powerBI_roleIDITA")}</label>
                                            <input type="text" className="form-control" name="powerBI_ID_ITA" onChange={(e) => handleChangeEdit(e)}
                                                defaultValue={companyAdminDetails.powerBI_ID_ITA}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.powerBI_roleENG")}</label>
                                            <input type="text" className="form-control" name="powerBI_role_ENG" onChange={(e) => handleChangeEdit(e)}
                                                defaultValue={companyAdminDetails.powerBI_role_ENG}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.powerBI_roleIDENG")}</label>
                                            <input type="text" className="form-control" name="powerBI_ID_ENG" onChange={(e) => handleChangeEdit(e)}
                                                defaultValue={companyAdminDetails.powerBI_ID_ENG}
                                            ></input>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {showalert ?
                                <div className={`alert alert-${alertclass}`}>{alert}</div>
                                :
                                (popupLoader1) ?
                                    popupLoader1 && <div className="d-flex alert alert-info">
                                        <div className="spinner-container-new">
                                            <div className="loading-spinner-new">
                                            </div>
                                        </div>
                                        <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                                    </div>
                                    : null
                            }

                            <div className="d-flex justify-content-end buttons-superadmin p-2">
                                <a className="buttons modal-button mr-2" onClick={() => resetPassword()}>{t("admin.profile.reset_password")}</a>
                                <button type="button" className="buttons modal-button mr-2" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                                <button type="button" className="buttons modal-button mr-2" onClick={() => updateAdminDetails()}>{t("admin.profile.save")}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )


    }
    const handlePdfDocSelect = (e) => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        const selectedFiles = Array.from(e.target.files);
        let totalSize = 0;
        setFiles(selectedFiles);
        fileUpload.current.value = "";
        fileUpload.current.type = "xlsx";


    };
    const handleUpload = () => {
        setIsLoading(true);
        setTimeout(()=>{
        fileUpload.current.click();
        setIsLoading(false);
        },3000)
    };
    const fileArr = () => {
        if (files?.length > 0) {
            let formData = new FormData();
            formData.append('filesType', 3);
            files?.forEach((singleFile, index) => {
                formData.append(`files`, singleFile);
            });
            // setUploadAttempts(uploadAttempts + 1);
            fileUploadApi(formData, (event) => {
                // setProgress(Math.round((100 * event.loaded) / event.total));
            }).then(async (response) => {
                setAlertMessage({ show: false, spinner: false, message: "", class: "" })
                setIsLoading(true);
                if (response?.status == 200) {
                    const fileRes = response?.data?.data;
                    setExcelFilename(fileRes[0].fileName);
                    let payload = { filename: fileRes[0].fileName };
                    const getAllDataResult = await PostRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_EXCEL_DATA}`,
                        { filename: fileRes[0].fileName },
                        "",
                        ""
                    );
                    if (getAllDataResult && getAllDataResult.status === 200) {
                        let arr = [];
                        let users = [];              
                        let emailsInObj1 = companyUserData.map(user => user.email);
                            getAllDataResult.data.data.forEach((data, i) => {
                            arr = [...arr, {
                                name: data?.Username || "",
                                user_id: i,
                                email: data?.Email || "",
                                checked: emailsInObj1.includes(data?.Email) ? false : true
                            }];
                            });
                        setTimeout(()=>{
                        setIsLoading(false);
                        setSelectedUser(arr);
                        setExcelData(arr);
                        setCurrentItems(arr?.slice(0, itemsPerPage))
                        setCurrentPage(1)
                        setPageCount(Math.ceil(arr?.length / itemsPerPage))
                        setExcelPopupPage(2)
                        resetFileSelector();
                        },9000)

                    }
                    else if (getAllDataResult && getAllDataResult.status === 203) {
                        setAlertMessage({ show: true, spinner: false, message: getAllDataResult.data.message, class: "alert alert-warning" })
                    }
                } else {

                    setAlertMessage({ show: true, spinner: false, message: response.data.message, class: "alert alert-danger" })
                    resetFileSelector();
                }

            })
        }
    }
    const submitData = async () => {
        setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" })
        let excelFinalData = [];
        // excelFinalData = excelData.filter(item => item.checked);
        excelFinalData = excelData;
        const getImportStatus = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_EXCEL_DATA}`,
            { filename: excelFilename, data: excelFinalData, company: company_id },
            "",
            ""
        );
        console.log("getImportStatus.status", getImportStatus);

        if (getImportStatus && getImportStatus.status === 200) {
            setAlertMessage({ show: false, spinner: false, message: '', class: "" })
            let arr = []
            let users = []
            const uniqueCountStatus = [];
            for (const element of getImportStatus.data.data) {
                if (uniqueCountStatus[element]) {
                    uniqueCountStatus[element] += 1;
                } else {
                    uniqueCountStatus[element] = 1;
                }
            }
            if (uniqueCountStatus['Import successful']) {
                setFileSatus({
                    import_successful: uniqueCountStatus['Import successful'] ? uniqueCountStatus['Import successful'] : 0,
                    already_imported: uniqueCountStatus['already imported'] ? uniqueCountStatus['already imported'] : 0,
                    not_imported: uniqueCountStatus['not imported'] ? uniqueCountStatus['not imported'] : 0
                });
                setExcelPopupPage(4)
            }
            else {
                setFileSatus({
                    import_successful: 0,
                    already_imported: uniqueCountStatus['already imported'] ? uniqueCountStatus['already imported'] : 0,
                    not_imported: uniqueCountStatus['not imported'] ? uniqueCountStatus['not imported'] : 0
                });
                setExcelPopupPage(3)
            }
            window.location.href = `${process.env.REACT_APP_BACKEND_BASE_URL}` + "/excel-download/" + excelFilename;  // Download file
        }
        else
        {
            setAlertMessage({ show: false, spinner: false, message: '', class: "" })
            setExcelPopupPage(3);
        }
    }
    const resetFileSelector = () => {
        setFiles([]);
    }
    const buttonStyle = { "padding": "10px", "line-height": 0, "height": "30px" };
    const addImportUserContent = () => {
        return (
            <div className="model-pop-new ">
                <div className="model-pop ">
                    <div className="modal-header">
                        <div className="video-heading-img text-center">
                            <p className="text-center">
                                <img src={USER_ICONA} alt="" className="p-1" />
                            </p>
                        </div>
                        <h5 className="modal-title pop_heading ml-2" id="exampleModalLabel">
                            <strong>
                                {t("superadmin.protocol.import_user")}
                            </strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => {closeModal(); getCompanyAllUser();}}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>

                    {alertMessage.show ? (
                        <div className={`d-flex mt-3 ${alertMessage.class}`}>
                            {alertMessage.spinner ? (
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new"></div>
                                </div>
                            ) : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div>
                    ) : null}

                    {isLoading ? <LazyLoader /> : null}
                    <div className="modal-body">
                        <div className="">
                            <form className="registrati-form registrati-form-new nuovo-programma">
                                <div className="form-group">
                                    <div>
                                        {excelPopupPage === 1 ? (
                                            <>
                                                <div className="drop-area" draggable='true' onDragOver={(e) => e.preventDefault()}
                                                //onDrop={handleFileDrop}
                                                >
                                                    <div draggable="true">
                                                        <div className="row">
                                                            <div className="col-sm-12 d-flex">
                                                                <div className="video-block">
                                                                    <div className="inner-video-block">
                                                                        <div className="video-img-block">
                                                                            <p>
                                                                                <img src={Group1283} alt="" />
                                                                            </p>
                                                                        </div>
                                                                        <div className="choose-file">
                                                                            <input type="file" multiple accept=".xlsx" hidden onChange={handlePdfDocSelect} ref={fileUpload} />
                                                                        </div>
                                                                        <div className="video-popup-body">
                                                                            <strong>{t("superadmin.protocol.file_upload_text")}</strong>
                                                                        </div>
                                                                        <div className="video-upload-btn">
                                                                            <button type="button" onClick={() => handleUpload()} className="buttons modal-button">
                                                                                {t("superadmin.training.popup.add_pdf_heading")}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : excelPopupPage == 2 ? (
                                            <>
                                                <div className="video-block">
                                                    <div className="inner-video-block">
                                                        <div className="row">
                                                            {currentItems.map((item, index) => {
                                                                return (
                                                                    <div className="col-sm-4 obbligatorieta-check">
                                                                        <div className="pt-1 pb-3 text-left">
                                                                            <label className={`mr-4 check-radiotype ${item.checked ? "checked-icon" : ""} `} >
                                                                                <input type="checkbox" defaultChecked={true} onChange={() => handleSelectUser(item.user_id)} />
                                                                            </label>
                                                                            <span>{item.name}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <div className="row mt-2 justify-content-end">
                                                            <div className="col-sm-4 pt-2">
                                                                <div className="d-flex sa-program-pagination">
                                                                    <ReactPaginate
                                                                        breakLabel="..."
                                                                        nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                        previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                        onPageChange={handlePageClick}
                                                                        pageRangeDisplayed={1}
                                                                        marginPagesDisplayed={1}
                                                                        pageCount={pageCount}
                                                                        renderOnZeroPageCount={null}
                                                                        containerClassName="selectuser-pagination"
                                                                        pageClassName="page-class"
                                                                        previousClassName={currentPage == 1 ? "d-none" : ""}
                                                                        nextClassName={currentPage == pageCount ? "d-none" : ""}
                                                                        forcePage={currentPage - 1}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-5 pt-2">
                                                                <button type="button" onClick={() => selectAll()} className="buttons modal-button" style={buttonStyle}>
                                                                    {t("superadmin.protocol.select_btn")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : excelPopupPage == 3 ? (
                                            <>
                                                <div className="video-block">
                                                    <div className="inner-video-block">
                                                        <div className="row justify-content-center">
                                                            <div>
                                                                <p>
                                                                    <img src={Wrong_Ans} alt="" />
                                                                </p>
                                                                <p><strong>{t("superadmin.protocol.import_fail_text")}</strong> <br />{fileStatus.not_imported} {t("superadmin.protocol.import_fail_text2")} / {fileStatus.already_imported} {t("superadmin.protocol.import_fail_text3")}</p>
                                                                <p>
                                                                    <button class="buttons modal-button" onClick={tryAgain} style={buttonStyle}>{t("superadmin.protocol.try_again_btn")}</button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : excelPopupPage == 4 ? (
                                            <>
                                                <div className="video-block">
                                                    <div className="inner-video-block">
                                                        <div className="row justify-content-center">
                                                            <div>
                                                                <p>
                                                                    <img src={Right_Ans} alt="" />
                                                                </p>
                                                                <p><strong>{t("superadmin.protocol.import_success_text")}</strong>
                                                                    <br />{fileStatus.import_successful} {t("superadmin.protocol.import_success_text2")} / {fileStatus.not_imported} {t("superadmin.protocol.import_fail_text2")} / {fileStatus.already_imported} {t("superadmin.protocol.import_fail_text3")}</p>
                                                                <p>
                                                                    <button class="buttons modal-button" style={buttonStyle}>{t("superadmin.protocol.upload_new_btn")}</button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : ''}
                                        <div className="d-flex justify-content-end buttons-superadmin mt-4">
                                            <button type="button" className="buttons modal-button mr-3" onClick={() => {closeModal();getCompanyAllUser();}}>{t("superadmin.protocol.popup.cancel")}</button>
                                            {excelPopupPage === 2 ? <button type="button" onClick={() => submitData()} className="buttons modal-button" style={buttonStyle}>{t("superadmin.protocol.import")}</button> : null}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );

    }
    const updateAdminDetails = async () => {
        const editValidate = adminEditValidation();
        const payload =
        {
            user_id: adminId,
            name: companyAdminDetails.name,
            email: companyAdminDetails.email,
            designation: companyAdminDetails.designation,
            company: companyAdminDetails.company,
            powerBI_ID_ENG: companyAdminDetails.powerBI_ID_ENG,
            powerBI_ID_ITA: companyAdminDetails.powerBI_ID_ITA,
            powerBI_role_ENG: companyAdminDetails.powerBI_role_ENG,
            powerBI_role_ITA: companyAdminDetails.powerBI_role_ITA
        }
        if (editValidate) {
            setIsLoading(true);
            setPopupLoader1(true);
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    "",
                    "refresh_token"
                );
                if (refreshToken) {
                    const getData = await PutRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_COMPANY_ADMIN}`,
                        payload,
                        "",
                        ""
                    );
                    if (getData && getData.status === 200) {
                        setIsLoading(false);
                        setAlerts({
                            alert: t('admin.profile.admin_updated'),
                            alerttype: "Success",
                            alertclass: "success",
                            showalert: true,
                        });
                        setTimeout(() => {
                            closeModal()
                            setLocalStoarge("company_general_tab", "admin");
                            window.location.href = `/sa/protocol/${company_id}/2`
                        }, 2000)

                    } else {
                        setIsLoading(false);
                        setPopupLoader1(false);
                        setAlerts({
                            alert: getData.data.message,
                            alerttype: "Error",
                            alertclass: "danger",
                            showalert: true,
                        });
                        setTimeout(() => {
                            setAlerts("", "", "", false);
                        }, 2000)
                    }
                } else {
                    setIsLoading(false);
                    setPopupLoader1(false);
                    setAlerts({
                        alert: t('admin.profile.admin_not_created'),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                    setTimeout(() => {
                        setAlerts("", "", "", false);
                    }, 2000)
                }
            } catch (error) {
                setIsLoading(false);
                setPopupLoader1(false);

                setAlerts({
                    alert: t('admin.profile.admin_not_created'),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
                setTimeout(() => {
                    setAlerts("", "", "", false);
                }, 2000)
            }
        }
        else {
            setIsLoading(false);
            setPopupLoader1(false);
            setAlerts("", "", "", false);
        }
    }

    const editUserModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" />{" "}
                            </div>
                            <strong>{t("admin.profile.modify_admin")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <form className="registrati-form registrati-form-new nova-program" >
                            <div className="container">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label ">{t("superadmin.protocol.fullname")}</label>
                                            <input type="text" className="form-control" name="name" defaultValue={companyUserDetails.name} onChange={(e) => handleChangeUserEdit(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.name && (
                                                <p className="danger">{validation.name}</p>
                                            )}
                                        </div>


                                        <div className="col-md-12 ">
                                            <label className="form-label mt-2">{t("superadmin.protocol.email")}</label>
                                            <input type="email" className="form-control" name="email" defaultValue={companyUserDetails.email} onChange={(e) => handleChangeUserEdit(e)}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.email && (
                                                <p className="danger">{validation.email}</p>
                                            )}
                                        </div>

                                        <div className="col-md-12">
                                            <label className="form-label mt-2">{t("user.profile.language")}</label>
                                            {/* <input type="text" className="form-control" defaultValue={companyUserDetails.language} name="language" onChange={(e) => handleChangeEdit(e)}
                                            ></input> */}
                                            <select className="form-control" name="language" onChange={(e) => handleChangeUserEdit(e)} >
                                                <option value='1' selected={(companyUserDetails.language == 1) ? "selected" : ""}>English</option>
                                                <option value='0' selected={(companyUserDetails.language == 0) ? "selected" : ""}>Italian</option>
                                            </select>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.designation && (
                                                <p className="danger">{validation.language}</p>
                                            )}
                                        </div>


                                    </div>
                                </div>
                            </div>
                            {showalert ?
                                <div className={`alert alert-${alertclass}`}>{alert}</div>
                                :
                                (popupLoader1) ?
                                    popupLoader1 && <div className="d-flex alert alert-info">
                                        <div className="spinner-container-new">
                                            <div className="loading-spinner-new">
                                            </div>
                                        </div>
                                        <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                                    </div>
                                    : null
                            }

                            <div className="d-flex justify-content-end buttons-superadmin p-2">
                                <a className="buttons modal-button mr-3" onClick={() => resetPassword()}>{t("admin.profile.reset_password")}</a>
                                <button type="button" className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                                <button type="button" className="buttons modal-button" onClick={() => updateUserDetails()}>{t("admin.profile.save")}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )


    }

    const submitAdminDetails = async () => {
        const validate = adminValidation();
        if (validate) {
            setIsLoading(true);
            setPopupLoader1(true);

            const payload =
            {
                name: adminInputValues.fullName,
                email: adminInputValues.email,
                password: adminInputValues.password,
                confirm_password: adminInputValues.confirmPassword,
                designation: adminInputValues.designation,
                company: company_id,
                powerBI_ID_ENG: adminInputValues.powerBI_ID_ENG,
                powerBI_ID_ITA: adminInputValues.powerBI_ID_ITA,
                powerBI_role_ENG: adminInputValues.powerBI_role_ENG,
                powerBI_role_ITA: adminInputValues.powerBI_role_ITA
            }
            setDisableButton(true);
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    "",
                    "refresh_token"
                );
                if (refreshToken) {
                    const getDashboard_Data = await PostRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_COMPANY_ADMIN}`,
                        payload,
                        "",
                        ""
                    );
                    if (getDashboard_Data && getDashboard_Data.status === 200) {
                        setIsLoading(false);
                        setDisableButton(false);
                        setAlerts({
                            alert: t('admin.profile.admin_created'),
                            alerttype: "Success",
                            alertclass: "success",
                            showalert: true,
                        });
                        setTimeout(() => {
                            closeModal()
                            setLocalStoarge("company_general_tab", "admin");
                            window.location.href = `/sa/protocol/${company_id}/2`
                        }, 2000)

                    } else {
                        setDisableButton(false);
                        setAlerts({
                            alert: getDashboard_Data.data.message,
                            alerttype: "Error",
                            alertclass: "danger",
                            showalert: true,
                        });
                        setTimeout(() => {
                            setAlerts("", "", "", false);
                            setPopupLoader1(false);
                            setIsLoading(false);

                        }, 2000)
                    }
                } else {
                    setDisableButton(false);
                    setIsLoading(false);
                    setPopupLoader1(false);
                    setAlerts({
                        alert: t('admin.profile.admin_not_created'),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                }
            } catch (error) {
                setDisableButton(false);
                setIsLoading(false);
                setPopupLoader1(false);
                setAlerts({
                    alert: t('admin.profile.admin_not_created'),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
            }
        }
        else {
            setIsLoading(false);
            setPopupLoader1(false);
            setAlerts("", "", "", false);
        }
    }

    const updateUserDetails = async () => {
        const editValidateUser = userEditValidation();
        const payload =
        {
            user_id: userId,
            name: companyUserDetails.name,
            email: companyUserDetails.email,
            lang: companyUserDetails.language,
        }
        if (editValidateUser) {
            setIsLoading(true);
            setPopupLoader1(true);
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    "",
                    "refresh_token"
                );
                if (refreshToken) {
                    const getData = await PutRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_COMPANY_USER}`,
                        payload,
                        "",
                        ""
                    );
                    if (getData && getData.status === 200) {
                        setIsLoading(false);
                        setAlerts({
                            alert: t('admin.profile.user_updated'),
                            alerttype: "Success",
                            alertclass: "success",
                            showalert: true,
                        });

                        setTimeout(() => {
                            closeModal()
                            setLocalStoarge("company_general_tab", "user");
                            window.location.href = `/sa/protocol/${company_id}/2`
                        }, 2000)

                    } else {
                        setIsLoading(false);
                        setAlerts({
                            alert: t('admin.profile.admin_not_update'),
                            alerttype: "Error",
                            alertclass: "danger",
                            showalert: true,
                        });
                    }
                } else {
                    setIsLoading(false);
                    setAlerts({
                        alert: t('admin.profile.admin_not_created'),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                }
            } catch (error) {
                setIsLoading(false);
                setAlerts({
                    alert: t('admin.profile.admin_not_created'),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
            }
        }
        else {
            setIsLoading(false);
            setAlerts("", "", "", false);
        }
    }

    const handleImageChange = async (e) => {
        e.preventDefault();
        let files = e.target.files[0];
        if (files) {
            let dataImage = new FormData();
            dataImage.append("image", e.target.files[0]);
            const checkImage = e.target.files[0];
            const fileExtension = checkImage?.name.replace(/^.*\./, '');
            const fileSize = Math.round((checkImage.size / 1024));
            // to check image size and type
            if (fileExtension === 'png' || fileExtension === 'PNG' || fileExtension === 'jpg' || fileExtension === 'JPG' || fileExtension === 'JPEG' || fileExtension === 'jpeg') {
                if (fileSize <= 1040) {
                    setIsLoading(true);
                    try {
                        const refreshToken = await PostRequest(
                            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                            {},
                            "",
                            "refresh_token"
                        );
                        if (refreshToken) {
                            const addProfileResult = await PostRequest(
                                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPLOAD_COMPANY_LOGO}`,
                                dataImage,
                                "",
                                "ImageUpload"
                            );
                            if (addProfileResult && addProfileResult.status === 200) {
                                if (addProfileResult.data.code === 200) {
                                    setIsLoading(false);
                                    setInputValue({ ...inputValue, icon: addProfileResult.data.data.filename })
                                    setSHowPic(addProfileResult.data.data.filename);
                                    setProfile(addProfileResult.data.data.filename);
                                } else {
                                    setIsLoading(false);
                                }
                            }
                        } else {
                            setIsLoading(false);
                        }
                    }
                    catch (error) {
                        console.log("catch", error)
                        setIsLoading(false);
                    }
                }
                else {
                    setAlerts({ alert: t("user.profile.valid_image_size"), alerttype: "Error", alertclass: "danger", showalert: true, });
                    setTimeout(() => {
                        setAlerts({ alert: "", alerttype: "", alertclass: "", showalert: false, });
                    }, process.env.REACT_APP_ALERT_TIMEOUT * 1000);
                }
            }
            else {
                setAlerts({ alert: t("user.profile.valid_image_type"), alerttype: "Error", alertclass: "danger", showalert: true, });
                setTimeout(() => {
                    setAlerts({ alert: "", alerttype: "", alertclass: "", showalert: false, });
                }, process.env.REACT_APP_ALERT_TIMEOUT * 1000);
            }
        }
    };
    const disableCompanyModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.disable_user")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader1 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1">{t("superadmin.training.popup.wait")}</div>
                            </div>}
                            <h6 className="visi-project"><strong>{t("superadmin.training.popup.disable_user_content_text")} "{changeCompanyStatus?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button disabled={disableButton} className="buttons modal-button" onClick={() => { changeCompanyStatusAPI('false'); setDisableButton(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const enableCompanyModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_right} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.enable_user")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1">{t("superadmin.training.popup.wait")}</div>
                            </div>}
                            <h6 className="visi-project"><strong>{t("superadmin.training.popup.enable_user_content_text")} "{changeCompanyStatus?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button disabled={disableButton} className="buttons modal-button" onClick={() => { changeCompanyStatusAPI('true'); setDisableButton(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const changeCompanyStatusAPI = async (status) => {
        setIsLoading(true);
        setPopupLoader1(true);
        try {

            let payload = {
                "user_id": changeCompanyStatus.user_id,
                "active": status
            }
            const getCompanyStatus = await PostRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ONBOARDING_USER}`,
                payload,
                "",
                ""
            );
            if (getCompanyStatus && getCompanyStatus.status === 200) {

                setEnableCompanyModal(false)
                setDisableCompanyModal(false)
                setIsLoading(false);
                setDisableButton(false)
                setPopupLoader1(false);
                getCompanyAllUser();

            } else {
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }
    const getCompanyAllDetails = async () => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getCompanyData = await GetRequest(`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_DETAILS}?companyId=${company_id}`,
                );
                if (getCompanyData && getCompanyData.status === 200) {
                    if (getCompanyData.data?.data) {
                        let resp = getCompanyData.data?.data;
                        let obj = {
                            icon: resp.icon || "building.jpg",
                            name: resp.company_name,
                            serviceStartDate: resp.start_date,
                            weeksProtocol: resp.weeks,
                            referent: resp.referent,
                            toiMeasurement: resp.maxScan,
                            parameterMeasurements: resp.measurementParameter,
                            configId: resp.configId,
                            color1: resp.primary_color,
                            color2: resp.secondary_color,
                            address: resp.address,
                            user: resp.users,
                            admin: resp.admin,
                            email: resp.referentEmail,
                            contractExpiry: resp.contract_expirations_date,
                            reminderDay: resp.reminderDay,
                            measurementsReminder: resp.measurementReminder,
                            url: resp.url,
                            consentIdEn: resp.consent_id_en,
                            consentIdIt: resp.consent_id_it
                        }
                        setInputValue(obj);
                        setInputDateValue({ serviceStartDate: obj.serviceStartDate, contractExpiry: obj.contractExpiry });
                    }
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const removeImage = async (e) => {
        e.preventDefault();
        const Image_data = "building.jpg";
        const profile = { profile_pic: Image_data };
        // setLocalStoarge("companyLogo", JSON.stringify(profile));
        setInputValue({ ...inputValue, icon: Image_data })
    };
    const checkupdate = (e) => {
        setAlertMessage({})
        setInputValue({ ...inputValue, [e.target.name]: e.target.value })
    }
    const updateCompanyDetailsAPI = async () => {
        setIsLoading(true);
        let payload = {
            companyId: company_id,
            icon: inputValue.icon,
            company_name: inputValue.name,
            start_date: inputValue.serviceStartDate,
            weeks: inputValue.weeksProtocol,
            referent: inputValue.referent,
            maxScan: inputValue.toiMeasurement,
            measurementParameter: inputValue.parameterMeasurements,
            configId: inputValue.configId,
            primary_color: inputValue.color1,
            secondary_color: inputValue.color2,
            address: inputValue.address,
            users: inputValue.user,
            admin: inputValue.admin,
            referentEmail: inputValue.email,
            contract_expirations_date: inputValue.contractExpiry,
            // reminderDay: inputValue.reminderDay,
            measurementReminder: inputValue.measurementsReminder,
            consentIdEn: inputValue.consentIdEn,
            consentIdIt: inputValue.consentIdIt
        }
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getCompanyData = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_COMPANY_DETAILS}`,
                    payload,
                    "",
                    ""
                );
                if (getCompanyData && getCompanyData.status === 200) {
                    setIsLoading(false);
                    setAlertMessage({ show: true, spinner: false, message: `${getCompanyData.data.message}`, class: "alert alert-success" })
                    setTimeout(() => {
                        setAlertMessage({})
                        window.location.href = "/sa/protocol";

                    }, 3000)

                } else {
                    setIsLoading(false);
                    setAlertMessage({ show: true, spinner: false, message: `${getCompanyData.data.message}`, class: "alert alert-danger" })
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const validateConsentId = (value) => {
        const pattern = /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;
        const isValidFormat = pattern.test(value);
        const isValidLength = value.length === 36; // 8 + 4 + 4 + 4 + 12 + 4 hyphens = 30 characters
        return isValidFormat && isValidLength;
    };
    const checkValidation = (e) => {
        e.preventDefault();
        let emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        let userExp = new RegExp(/^[a-zA-Z ]*$/);
        let colorExp = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
        let addressExp = new RegExp(/^[a-zA-Z0-9\s!@#$%^&*()_+-={};:'",.<>?/\\[\]]+$/);
        let configId_match = new RegExp(/^[a-zA-Z0-9]+$/);
        inputValue.name = inputValue.name.trim();
        inputValue.referent = inputValue.referent.trim();
        inputValue.address = inputValue.address.trim();
        inputValue.configId = inputValue.configId.trim();

        if (inputValue.color1 == "" || inputValue.color1 == null || !inputValue.color1 || (!inputValue.color1.match(colorExp))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.err_color1")}`, class: "alert alert-danger" })
        }
        else if (inputValue.color2 == "" || inputValue.color2 == null || !inputValue.color2 || (!inputValue.color2.match(colorExp))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.err_color2")}`, class: "alert alert-danger" })
        }
        else if (inputValue.name?.length < 3 || inputValue.name?.length > 50 || (!inputValue.name.match(userExp))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidCompanyName")}`, class: "alert alert-danger" })
        }
        else if (inputValue.serviceStartDate == "" || inputValue.contractExpiry == "" || inputValue.serviceStartDate == null || inputValue.contractExpiry == null) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidCEDate")}`, class: "alert alert-danger" })
        }
        else if (inputValue.weeksProtocol < 4 || inputValue.weeksProtocol > 52) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.weekError")}`, class: "alert alert-danger" })
        }
        else if (inputValue.referent?.length < 3 || inputValue.referent?.length > 50) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidReferent")}`, class: "alert alert-danger" })
        } else if (inputValue.toiMeasurement < 0 || inputValue.toiMeasurement > 1000 || inputValue.toiMeasurement == undefined) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidTOImeasurement")}`, class: "alert alert-danger" })
        } else if (inputValue.address?.length < 10 || inputValue.address?.length > 150 || (!inputValue.address.match(addressExp))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidAddress")}`, class: "alert alert-danger" })
        } else if (!inputValue.email.match(emailRegex)) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.invalidEmail")}`, class: "alert alert-danger" })
        }
        else if (!inputValue.configId || inputValue.configId == "" || inputValue.configId == null || (!inputValue.configId.match(configId_match)) || inputValue.configId?.length <= 10 || inputValue.configId?.length >= 12) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.configIdError")}`, class: "alert alert-danger" })
        }
        else if (!inputValue.consentIdEn || inputValue.consentIdEn == "" || inputValue.consentIdEn == null || (!validateConsentId(inputValue.consentIdEn))) {
              setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.consentIdEn")}`, class: "alert alert-danger" })
        }
        else if (!inputValue.consentIdIt || inputValue.consentIdIt == "" || inputValue.consentIdIt == null || (!validateConsentId(inputValue.consentIdIt))) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.protocol.consentIdIt")}`, class: "alert alert-danger" })
        }
        else {
            setAlertMessage({ show: true, spinner: true, message: `${t("superadmin.training.popup.wait")}`, class: "alert alert-info" })
            updateCompanyDetailsAPI()
        }
    }
    const calculateWeeksBetween = (date1, date2) => {
        // The number of milliseconds in one week
        var ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
        // Convert both dates to milliseconds
        var date1_ms = new Date(date1 * 1000).getTime();
        var date2_ms = new Date(date2 * 1000).getTime();
        // Calculate the difference in milliseconds
        var difference_ms = Math.abs(date2_ms - date1_ms);
        // Convert back to weeks and return hole weeks
        return Math.floor(difference_ms / ONE_WEEK);
    }


    const calculateWeek = () => {
        const currentDate1 = Math.floor(new Date().getTime() / 1000);

        inputDateValue.serviceStartDate = (inputDateValue.serviceStartDate <= currentDate1 + 31536000) ? inputDateValue.serviceStartDate : currentDate1;

        if (inputDateValue.serviceStartDate) {
            inputDateValue.contractExpiry = (inputDateValue.contractExpiry <= parseInt(inputDateValue.serviceStartDate) + parseInt(31622400)) ? inputDateValue.contractExpiry : parseInt(inputDateValue.serviceStartDate) + 31622400;
            setInputValue({ ...inputValue, serviceStartDate: inputDateValue.serviceStartDate, contractExpiry: inputDateValue.contractExpiry });
        } else {
            setInputValue({ ...inputValue, serviceStartDate: inputDateValue.serviceStartDate, contractExpiry: null });
        }

        if (inputDateValue.contractExpiry != null && inputDateValue.contractExpiry != 0 && inputDateValue.serviceStartDate != null && inputDateValue.serviceStartDate != 0) {
            let value = calculateWeeksBetween(inputDateValue.serviceStartDate, inputDateValue.contractExpiry);
            setInputValue({ ...inputValue, weeksProtocol: value, serviceStartDate: inputDateValue.serviceStartDate, contractExpiry: inputDateValue.contractExpiry });
        }
    }
    const samplefile = `${process.env.REACT_APP_BACKEND_BASE_URL}` + "/sample-download/users.xlsx";
    return (
        <>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableUserModal(false)} show={disableUserModal}>
                {disableUserModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableAdminModal(false)} show={disableAdminModal}>
                {disableAdminModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEnableAdminModal(false)} show={enableAdminModal}>
                {enableAdminModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setAddAdminModal(false)} show={addAdminModal}>
                {addAdminModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEditAdminModal(false)} show={editAdminModal}>
                {editAdminModalContent()}
            </Modal>
            {/* Reset Password Confirmation modal */}
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setResetConfirmModal(false)} show={resetConfirmModal}>
                {resetConfirmationScreen()}
            </Modal>
            {/* Change Password modal */}
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setChangePassModal(false)} show={changePassModal}>
                {changePasswordScreen()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEditUserModal(false)} show={editUserModal}>
                {editUserModalContent()}
            </Modal>
            <Modal title="" size="lg" contentClasses="elimina-popup" onClose={() => setImportUserModal(false)} show={importUser}>
                {addImportUserContent()}
            </Modal>
            <Modal title="" size="lg" contentClasses="elimina-popup" onClose={() => setConfirmEnable(false)} show={confirmEnable}>
                {confirmationBox()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableCompanyModal(false)} show={disableCompanyModal}>
                {disableCompanyModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEnableCompanyModal(false)} show={enableCompanyModal}>
                {enableCompanyModalContent()}
            </Modal>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="protocol" />
                        <div className="col-md-11 col-lg-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace leftr-dari">
                                <div className="d-flex justify-content-between right-header">
                                    <h2 className="heading-dash">
                                        <button className="back-button" onClick={history.goBack}><img src={PREV_PAGE_ICON} alt="icon" /></button>
                                        {companyDetails?.company_name}
                                    </h2>

                                    <div className="align-j-new buttons-superadmin">

                                        {personalText == "user" ? <>
                                            <a className="buttons" href={samplefile} >{t("superadmin.protocol.import_sample")}</a> <button className="buttons" onClick={() => setImportUserModal(true)} >{t("superadmin.protocol.import_user")} </button> </> : personalText == "admin" ? <button className="buttons" onClick={() => setAddAdminModal(true)}>{t("superadmin.protocol.new_admin")}</button> : null}
                                    </div>

                                </div>
                                <div className="graph-tabs ">
                                    <ul
                                        className="nav nav-tabs nav-stacked"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <>
                                            <li className="nav-item" onClick={() => setPersonalText("general")}>
                                                <a
                                                    className={
                                                        "nav-link " +
                                                        (personalText == "general"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="first-tab"
                                                    data-toggle="tab"
                                                    href="#first"
                                                    role="tab"
                                                    aria-controls="first"
                                                    aria-selected="true"
                                                >
                                                    <span className="das-icontabs">
                                                        <div className="deskt-n"></div>
                                                        <span className="">
                                                            {t("superadmin.protocol.general")}
                                                        </span>
                                                    </span>
                                                </a>
                                            </li>
                                            {disableInput != 1 ?
                                                <li className="nav-item" onClick={() => setPersonalText("admin")}>
                                                    <a
                                                        className={
                                                            "nav-link " +
                                                            (personalText == "admin" ? "active show" : "")
                                                        }
                                                        id="second-tab"
                                                        data-toggle="tab"
                                                        href="#second"
                                                        role="tab"
                                                        aria-controls="second"
                                                        aria-selected="false"
                                                        onClick={() =>
                                                            window.dispatchEvent(new Event("resize"))
                                                        }
                                                    >
                                                        <span className="das-icontabs">
                                                            <div className="deskt-n "></div>
                                                            <span className="">
                                                                {t("superadmin.protocol.admin")}
                                                            </span>
                                                        </span>
                                                    </a>
                                                </li>
                                                : null}
                                            {disableInput != 1 ?
                                                <li className="nav-item" onClick={() => setPersonalText("user")}>
                                                    <a
                                                        className={
                                                            "nav-link " +
                                                            (personalText == "user"
                                                                ? "active show"
                                                                : "")
                                                        }
                                                        id="third-tab"
                                                        data-toggle="tab"
                                                        href="#third"
                                                        role="tab"
                                                        aria-controls="third"
                                                        aria-selected="false"
                                                    >
                                                        <span className="das-icontabs">
                                                            <div className="deskt-n"></div>
                                                            <span className="">
                                                                {t("superadmin.protocol.user")}
                                                            </span>
                                                        </span>
                                                    </a>
                                                </li>
                                                : null}
                                            {disableInput != 1 ?
                                                <li className="nav-item" onClick={() => setPersonalText("form")}>
                                                    <a
                                                        className={
                                                            "nav-link " +
                                                            (personalText == "form"
                                                                ? "active show"
                                                                : "")
                                                        }
                                                        id="fourth-tab"
                                                        data-toggle="tab"
                                                        href="#fourth"
                                                        role="tab"
                                                        aria-controls="fourth"
                                                        aria-selected="false"
                                                    >
                                                        <span className="das-icontabs">
                                                            <div className="deskt-n"></div>
                                                            <span className="">
                                                                {t("user.dashboard.moduli")}
                                                            </span>
                                                        </span>

                                                    </a>
                                                </li>
                                                : null}
                                        </>
                                    </ul>

                                    <div className="card-body">
                                        <div className="tab-content">
                                            <>
                                                {!isMobile ? (
                                                    <div className="col-md-8 col-sm-6 col-lg-10">
                                                        {alertMessage.show ? (
                                                            <div className={`d-flex mt-3 ${alertMessage.class}`}>
                                                                {alertMessage.spinner ? (
                                                                    <div className="spinner-container-new">
                                                                        <div className="loading-spinner-new"></div>
                                                                    </div>
                                                                ) : null}
                                                                <div className="ml-1">{alertMessage.message}</div>
                                                            </div>
                                                        ) : null}
                                                    </div>)
                                                    : null
                                                }
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "general"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="first"
                                                    role="tabpanel"
                                                    aria-labelledby="first-tab"
                                                >
                                                    <div className="">
                                                        <div className="">
                                                            <div className="right-dashboard topspace profile-right pl-0">
                                                                {showalert ? <div className={`alert alert-${alertclass}`}>{alert}</div> : null}
                                                                <form className="company-details-form">
                                                                    <div className="row">
                                                                        <div className="col-md-6 max-w70">
                                                                            <div className="">
                                                                                <div className="leftprofiles">
                                                                                    <div className="l-rflex pr-0">
                                                                                        <div className="profil_img">
                                                                                            <span className="circl-profile circle-profile-new viewprofile">
                                                                                                <img
                                                                                                    src={
                                                                                                        process.env.REACT_APP_BACKEND_BASE_URL +
                                                                                                        process.env.REACT_APP_STATIC_PATH_COMPANY_ICON +
                                                                                                        `/${inputValue.icon}`
                                                                                                    }
                                                                                                    className="img-fluid"
                                                                                                    alt="img"
                                                                                                    onError={handleImageError}
                                                                                                />
                                                                                            </span>
                                                                                            <label htmlFor="file-upload" className="deskt-n">
                                                                                                <img
                                                                                                    src={Group878}
                                                                                                    className="profile-edit-icon deskt-n"
                                                                                                    alt="icon"
                                                                                                />
                                                                                            </label>
                                                                                            <input
                                                                                                id="file-upload"
                                                                                                type="file"
                                                                                                className="deskt-n"
                                                                                                onChange={(e) => handleImageChange(e)}
                                                                                                disabled={disableInput == 1 ? true : false}
                                                                                            />
                                                                                            <div className="ri-dete visi-none">
                                                                                                <input
                                                                                                    id="file-upload"
                                                                                                    type="file"
                                                                                                    onChange={(e) => handleImageChange(e)}
                                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                                />
                                                                                            </div>
                                                                                            <>
                                                                                                <label htmlFor="file-upload" className="deskt-n">
                                                                                                    <img
                                                                                                        src={Group878}
                                                                                                        className="profile-edit-icon deskt-n"
                                                                                                        alt="icon"
                                                                                                    />
                                                                                                </label>
                                                                                                <input
                                                                                                    id="file-upload"
                                                                                                    type="file"
                                                                                                    className="deskt-n"
                                                                                                    onChange={(e) => handleImageChange(e)}
                                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                                />
                                                                                            </>

                                                                                        </div>
                                                                                        {disableInput != 1 ?
                                                                                            <div className="ri-dete visi-none">
                                                                                                <label
                                                                                                    htmlFor="file-upload"
                                                                                                    className="buttons height-bnt max-paddings d-flex justify-content-center align-items-center"
                                                                                                >
                                                                                                    {t("user.profile.modify")}
                                                                                                </label>
                                                                                                <input
                                                                                                    id="file-upload"
                                                                                                    type="file"
                                                                                                    onChange={(e) => handleImageChange(e)}
                                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                                />
                                                                                                <button
                                                                                                    className="delete_btn height-bnt"
                                                                                                    onClick={(e) => removeImage(e)
                                                                                                    }
                                                                                                >
                                                                                                    <img src={ELIMINA_ICONA} alt="icon" />
                                                                                                </button>
                                                                                            </div>
                                                                                            : null}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="d-flex justify-content-between color-box-margin">
                                                                                <div className="mr-2 d-flex position-relative" ref={ref1}>
                                                                                    <div className="select-color" style={{ backgroundColor: inputValue.color1 }} onClick={() => setShowColorPicker1(true)}>
                                                                                        {showColorPicker1 ? <SketchPicker
                                                                                            color={inputValue.color1}
                                                                                            onChangeComplete={(color) => { setInputValue({ ...inputValue, color1: color.hex }); setAlertMessage({}) }}
                                                                                            disabled={disableInput == 1 ? true : false}

                                                                                        />
                                                                                            : null}
                                                                                    </div>
                                                                                    <div className="form-group relative">
                                                                                        {disableInput != 1 ?
                                                                                            <img
                                                                                                src={Group878}
                                                                                                className="edit-icon visi-none"
                                                                                                alt="icon"
                                                                                            />
                                                                                            : null}
                                                                                        <input
                                                                                            type="text"
                                                                                            onChange={(e) => checkupdate(e)}
                                                                                            value={inputValue.color1}
                                                                                            name="color1"
                                                                                            autoComplete="off"
                                                                                            className="form-control"
                                                                                            aria-describedby="emailHelp"
                                                                                            disabled={disableInput == 1 ? true : false}

                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ml-2 d-flex position-relative" ref={ref2} >
                                                                                    <div className="select-color" style={{ backgroundColor: inputValue.color2 }} onClick={() => setShowColorPicker2(true)}>
                                                                                        {showColorPicker2 ? <SketchPicker
                                                                                            color={inputValue.color2}
                                                                                            onChangeComplete={(color) => { setInputValue({ ...inputValue, color2: color.hex }); setAlertMessage({}) }}
                                                                                            disabled={disableInput == 1 ? true : false}

                                                                                        />
                                                                                            : null}
                                                                                    </div>
                                                                                    <div className="form-group relative ">
                                                                                        {disableInput != 1 ?
                                                                                            <img
                                                                                                src={Group878}
                                                                                                className="edit-icon visi-none"
                                                                                                alt="icon"
                                                                                            />
                                                                                            : null}
                                                                                        <input
                                                                                            type="text"
                                                                                            onChange={(e) => checkupdate(e)}
                                                                                            value={inputValue.color2}
                                                                                            name="color2"
                                                                                            autoComplete="off"
                                                                                            className="form-control"
                                                                                            aria-describedby="emailHelp"
                                                                                            disabled={disableInput == 1 ? true : false}

                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 max-w70">
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.protocol.name")}</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.name.replace(/^\s+/g, '')}
                                                                                    name="name"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-xl-6 form-group relative date-input-new">
                                                                                    <div className="vb">
                                                                                        <label>{t("superadmin.protocol.serviceStartDate")}</label>
                                                                                    </div>
                                                                                    <div className="relative">
                                                                                        <DatePicker dateFormat="dd.MM.yyyy"
                                                                                            selected={inputValue.serviceStartDate * 1000}
                                                                                            // selected={inputDateValue.serviceStartDate * 1000}
                                                                                            onChange={(date) => { setInputDateValue({ ...inputDateValue, serviceStartDate: date / 1000 }); setAlertMessage({}); }}
                                                                                            // onChange={(date) => {setInputDateValue({ ...inputDateValue, serviceStartDate: date / 1000 }); setAlertMessage({})}}
                                                                                            minDate={new Date().getTime()}
                                                                                            disabled={disableInput == 1 ? true : false}
                                                                                            showMonthDropdown
                                                                                            showYearDropdown

                                                                                        />
                                                                                        <span className="edit-date">
                                                                                            {disableInput != 1 ?
                                                                                                <img className="pr-2" src={Group878} alt="" /> : null}
                                                                                        </span>

                                                                                    </div>
                                                                                </div>
                                                                                {/* expiry date here.... */}
                                                                                <div className="col-xl-6 form-group relative week-input-new">
                                                                                    <div className="vb">
                                                                                        <label>{t("superadmin.protocol.contractExpiry")}</label>
                                                                                    </div>
                                                                                    <div className="w-100 relative">
                                                                                        <DatePicker dateFormat="dd.MM.yyyy"
                                                                                            selected={inputValue.contractExpiry * 1000}
                                                                                            onChange={(date) => { setInputDateValue({ ...inputDateValue, contractExpiry: date / 1000 }); setAlertMessage({}); }}
                                                                                            minDate={new Date().getTime()}
                                                                                            disabled={disableInput == 1 ? true : false}
                                                                                            showMonthDropdown
                                                                                            showYearDropdown

                                                                                        /> {disableInput != 1 ? <span className="edit-date">

                                                                                            <img className="pr-2" src={Group878} alt="" />
                                                                                        </span>
                                                                                            : null}

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.protocol.referent")}</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.referent.replace(/^\s+/g, '')}
                                                                                    name="referent"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.protocol.toiMeasurement")}</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="number"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue.toiMeasurement}
                                                                                    name="toiMeasurement"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.add_protocal.consentId")} (EN)</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.consentIdEn}
                                                                                    name="consentIdEn"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>
                                                                        <div className="form-group relative">
                                                                                <label>{t("superadmin.add_protocal.consentId")} (IT)</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.consentIdIt}
                                                                                    name="consentIdIt"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                          </div>                                                                         
                                                                        </div>
                                                                        <div className="col-md-6 max-w70">
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.protocol.address")}</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    /> : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.address}
                                                                                    name="address"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>
                                                                            <div className="d-flex justify-content-between user-admin-margin-box">
                                                                                <div className="form-group relative w-100">
                                                                                    <label>{t("superadmin.protocol.user")}</label>
                                                                                    <div className="user-count mt-1">{inputValue.user}</div>
                                                                                </div>
                                                                                <div className="form-group relative w-100">
                                                                                    <label>{t("superadmin.protocol.admin")}</label>
                                                                                    <div className="user-count mt-1">{inputValue.admin}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.protocol.email")}</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    /> : null}
                                                                                <input
                                                                                    type="email"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.email}
                                                                                    name="email"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>

                                                                            {/* week */}

                                                                            <div className="form-group relative date-input-new">
                                                                                <div className="vb">
                                                                                    <label>{t("superadmin.protocol.weeksProtocol")}</label>
                                                                                </div>
                                                                                <div className="arrow_down1 arrow_down1-new">
                                                                                    {disableInput != 1 ?
                                                                                        <label htmlFor="weekProtocol" className="edit-date">

                                                                                            <img className="" src={Group878} alt="" /> </label> : null}

                                                                                    <input
                                                                                        id="weekProtocol"
                                                                                        name="weeksProtocol"
                                                                                        className="border_n"
                                                                                        value={inputValue.weeksProtocol}
                                                                                        onChange={(e) => checkupdate(e)}
                                                                                        style={{ width: '100%' }}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            {disableInput == 1 ?
                                                                                <div className="form-group relative date-input-new">
                                                                                    <div className="vb">
                                                                                        <label>{t("superadmin.protocol.finalUrl")}</label>
                                                                                        <img src={DOCUMENT_ICON} alt="icon" style={{ width: "25px", marginLeft: "5px", cursor: "pointer" }} onClick={() => { handleCopyClick(); setTextCopy(true); }} />
                                                                                        {(textCopy) ? <span style={{ marginLeft: "2px", color: "green" }}>{t("superadmin.protocol.urlCopy")}</span> : null}
                                                                                    </div>
                                                                                    <div className="arrow_down1 arrow_down1-new">

                                                                                        <input
                                                                                            id="weekProtocol"
                                                                                            name="weeksProtocol"
                                                                                            className="border_n"
                                                                                            value={inputValue.url}
                                                                                            onChange={(e) => checkupdate(e)}
                                                                                            style={{ width: '100%', fontSize: "17px" }}
                                                                                            disabled
                                                                                            ref={inputRef}

                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                : null}  
                                                                                                                                            
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.add_protocal.configId")}</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.configId}
                                                                                    name="configId"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>
                                                                            <div className="d-flex justify-content-end mt-5">
                                                                                {disableInput == 1 ? null :
                                                                                    <button className="buttons" onClick={(e) => checkValidation(e)}>{t("superadmin.training.popup.salva")
                                                                                    }</button>
                                                                                }
                                                                             </div>

                                                                        </div>
                                                                        {/* {disableInput != 1 ?
                                                                        <div className="col-sm-6">
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.add_protocal.consentId")} (EN)</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.consentIdEn}
                                                                                    name="consentIdEn"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :null}
                                                                        {disableInput != 1 ?
                                                                        <div className="col-sm-6">
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.add_protocal.consentId")} (IT)</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.consentIdIt}
                                                                                    name="consentIdIt"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :null} */}

                                                                        {/* {disableInput != 1 ?
                                                                        <div className="col-sm-6">
                                                                            <div className="form-group relative">
                                                                                <label>{t("superadmin.add_protocal.configId")}</label>
                                                                                {disableInput != 1 ?
                                                                                    <img
                                                                                        src={Group878}
                                                                                        className="edit-icon visi-none"
                                                                                        alt="icon"
                                                                                    />
                                                                                    : null}
                                                                                <input
                                                                                    type="text"
                                                                                    onChange={(e) => checkupdate(e)}
                                                                                    value={inputValue?.configId}
                                                                                    name="configId"
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    aria-describedby="emailHelp"
                                                                                    disabled={disableInput == 1 ? true : false}

                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :null} */}

                                                                        {/* <div className="col-sm-6">
                                                                            <div className="d-flex justify-content-end mt-5">
                                                                                {disableInput == 1 ? null :
                                                                                    <button className="buttons" onClick={(e) => checkValidation(e)}>{t("superadmin.training.popup.salva")
                                                                                    }</button>
                                                                                }
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                </form>
                                                                {isMobile ?
                                                                    (alertMessage.show ? (
                                                                        <div className={`d-flex mt-3 ${alertMessage.class}`}>
                                                                            {alertMessage.spinner ? (
                                                                                <div className="spinner-container-new">
                                                                                    <div className="loading-spinner-new"></div>
                                                                                </div>
                                                                            ) : null}
                                                                            <div className="ml-1">{alertMessage.message}</div>
                                                                        </div>
                                                                    ) : null)
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Wellbeing enable & disable button  */}
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "form"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="fourth"
                                                    role="tabpanel"
                                                    aria-labelledby="first-tab"
                                                >

                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <h6 className="text-left brainwellness"><strong>{t("superadmin.sidebar.farmazione")}</strong></h6>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="nb">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={(e) => enableDisable(e)}
                                                                        key={Math.random()}
                                                                        defaultChecked={
                                                                            adminEnableDisable?.trainingModule == 0 ? false : true
                                                                        }
                                                                        name="trainingModule"
                                                                    />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4"></div>
                                                    </div>



                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <h6 className="text-left brainwellness"><strong>{t("superadmin.sidebar.brain_wellness")}</strong></h6>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="nb">
                                                                <label className="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={(e) => enableDisable(e)}
                                                                        key={Math.random()}
                                                                        defaultChecked={
                                                                            adminEnableDisable?.brainwellnessModule == 0 ? false : true
                                                                        }
                                                                        name="brainwellnessModule"
                                                                    />
                                                                    <span className="slider"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4"></div>
                                                    </div>


                                                </div>
                                                {/* Wellbeing enable & disable button setConfirmEnable(true); */}

                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "admin" ? "active show" : "")
                                                    }
                                                    id="second"
                                                    role="tabpanel"
                                                    aria-labelledby="second-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-md-12 pr-0 p0 ipd-righ-">
                                                            <div className="rigt-dashboard topspace profile-right mx-3 ">
                                                                {(companyAdminData.length > 0 && companyAdminData) ? <>
                                                                    <ToolkitProvider
                                                                        keyField="user_id"
                                                                        key={'datatable'}
                                                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : (selection === 3) ? redsource.slice(20, 30) : redsource) : filteredData) : reportDatatutti}
                                                                        data={companyAdminData}
                                                                        columns={adminColumn}
                                                                        search
                                                                    >{(props) => (
                                                                        <div id="test" className="table ricsb protocolli-data">
                                                                            <div>
                                                                                <div >
                                                                                    <div className="searchbar d-flex justify-content-start my-2">
                                                                                        <img
                                                                                            src={Group698}
                                                                                            className="search_icon"
                                                                                            alt="icon"
                                                                                            style={{ top: "25px" }}
                                                                                        />
                                                                                        <SearchBar
                                                                                            placeholder={t("superadmin.protocol.search")}
                                                                                            {...props.searchProps}
                                                                                        />
                                                                                    </div>
                                                                                    <BootstrapTable

                                                                                        src={VectorDown} alt="icon"
                                                                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : redsource) : filteredData) : reportDatatutti}
                                                                                        data={companyAdminData}
                                                                                        {...props.baseProps}
                                                                                        filter={filterFactory()}
                                                                                        pagination={paginationFactory(paginationOption)}
                                                                                        wrapperClasses={'table-responsive'}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    </ToolkitProvider>
                                                                </> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "user"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="third"
                                                    role="tabpanel"
                                                    aria-labelledby="third-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-md-12 pr-0 p0 ipd-righ">
                                                            <div className="rigt-dashboard topspace mx-3 ">
                                                                {(companyUserData.length > 0 && companyUserData) ? <>
                                                                    <ToolkitProvider
                                                                        keyField="user_id"
                                                                        key={'datatable'}
                                                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : (selection === 3) ? redsource.slice(20, 30) : redsource) : filteredData) : reportDatatutti}
                                                                        data={companyUserData}
                                                                        columns={generalColumn}
                                                                        search
                                                                    >{(props) => (
                                                                        <div id="test" className="table ricsb protocolli-data">
                                                                            <div>
                                                                                <div >
                                                                                    <div className="searchbar d-flex justify-content-start my-2">
                                                                                        <img
                                                                                            src={Group698}
                                                                                            className="search_icon"
                                                                                            alt="icon"
                                                                                            style={{ top: "25px" }}

                                                                                        />
                                                                                        <SearchBar
                                                                                            placeholder={t("superadmin.protocol.search")}
                                                                                            {...props.searchProps}
                                                                                        />
                                                                                    </div>
                                                                                    <BootstrapTable
                                                                                        src={VectorDown} alt="icon"
                                                                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : redsource) : filteredData) : reportDatatutti}
                                                                                        data={companyUserData}
                                                                                        {...props.baseProps}
                                                                                        filter={filterFactory()}
                                                                                        pagination={paginationFactory(paginationOption)}
                                                                                        wrapperClasses={'table-responsive'}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    </ToolkitProvider>
                                                                </> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    );
};

export default Edit_Protocol;
