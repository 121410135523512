import axios from "axios";

async function PutRequest(session_url, formData, token, type) {
    let role = parseInt(window.localStorage.getItem('roleId'));
    let saved = (role === 2) ? window.localStorage.getItem('R2') : (role === 3) ? window.localStorage.getItem('R3') : (role === 4) ? window.localStorage.getItem('R3') : window.localStorage.getItem('R1');
    let initialValue = JSON.parse(saved);
    let Token = (initialValue)?.access_token;

    let config = {
        headers: { Authorization: `Bearer ${Token}` }
    };
    let payload = formData;
    if (type === "ImageUpload") {
        config = {
            headers: {
                Authorization: `Bearer ${Token}`,
                Accept: `*/*`,
                'content-type': 'multipart/form-data'
            }
        };
    }

    try {
        const getResponse = await axios.put(session_url, payload, config);
        return getResponse;
    } catch (error) {
        console.log("error ==>", error);
        throw error; // Re-throw the error to maintain the original behavior
    }
}

export default PutRequest;
