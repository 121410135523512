import { useEffect, useState } from 'react'
import { getLocalStoarge } from '../../../common/allfunction';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from '../../../apiConnection/getRequest';
import LazyLoader from '../../../common/lazyLoader';
import { Link } from "react-router-dom";
const MindfulnessBarChart = (props) => {
  const [t, i18n] = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [themeColor, setThemeColor] = useState(getLocalStoarge("theme"))
  const graphColor = (JSON.parse(themeColor) == "dark") ? "#FFFFFF" : "#272848"
 
  const [data, setData] = useState(
    {

      series: [{
        data: []
      }],
      options: {
        chart: {
          type: 'bar',
          events: {
            click: function (chart, w, e) {
            }
          },
        },
        responsive: [{
          breakpoint: 767, // define breakpoint for mobile
          options: {
            chart: {
              width: '100%', // make the chart 100% width for mobile
            }
          }
        }],
        colors: ['#6D71F9', '#54C1FB', '#367B21', '#9ECB61', '#F6BF42', '#B02418'],
        plotOptions: {
          bar: {
            columnWidth: '80%',
            distributed: true,
            vertical: true,
            dataLabels: {
              position: 'bottom',
              maxItems: 100,
              hideOverflowingLabels: true,
              total: {
                enabled: false,
                formatter: undefined,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: graphColor,
                  fontSize: '20px',
                  fontFamily: 'Lexend',
                  fontWeight: 700
                }
              }
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: false,
        },
        xaxis: {
          lines: {
            show: true,
          },
          categories: [
            [t('user.activityReport.bar-graph-column-1')],
            [t('user.activityReport.bar-graph-column-2')],
            [t('user.activityReport.bar-graph-column-3')],
            [t('user.activityReport.bar-graph-column-4')],
            [t('user.activityReport.bar-graph-column-5')],
            [t('user.activityReport.bar-graph-column-6')],
          ],
          labels: {
            style: {
              colors: graphColor,
              fontSize: '16px',
              fontFamily: 'Lexend',
              fontWeight: 300,
            },
          }
        },
        yaxis: {
          lines: {
            show: true,
          },
          labels: {
            style: {
              colors: graphColor,
              fontSize: '16px',
              fontFamily: 'Lexend',
              fontWeight: 300,
            },
          }
        },

      }
    }

  );

  useEffect(() => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
    getMindfulnessMusicData();
  }, [props.repeatTime]);

  const patchBarChartVal=(result, sequence)=>{
    let data=[]
    for (const activityName of sequence) {
      data.push(result[activityName])
    } 

    setData(prevData => ({
      series: [
        {
          data: data,
        },
      ],
      options: prevData.options,
    }));
  }

  const getMindfulnessMusicData = async () => {
    setIsLoading(true)
    try {
      // const refreshToken = await PostRequest(
      //   `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      //   {},
      //   "",
      //   "refresh_token"
      // );
      // if (refreshToken) {
        const mindfulnessMusicData = await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_MINDFULNESS_MUSIC}?repeat_time=${props.repeatTime}`,
        );
        setIsLoading(false)
        if (mindfulnessMusicData && mindfulnessMusicData.status === 200) {
          let result = mindfulnessMusicData.data.data.user_all_listen_musicFile_minute;      

          let updatedResult = {};
          Object.entries(result).forEach(([key, value]) => {
            updatedResult[key] = Math.ceil(value);
          });

          let sequence = ['relax','body','abdomen','nostrils','creativity','lucidity'];
          patchBarChartVal(updatedResult, sequence);      
        }
      // } else {
      //   setIsLoading(false);
      // }
    } catch (error) {
      console.log("error", error)
      setIsLoading(false);
    }
  }

  useEffect(() => {
    console.log('data', data)
  }, [data])


  return (
    <div className='bar-graph-section'>
      {isLoading ? <LazyLoader /> : null}
      <div className='second-top-heading mt-3'>
        <div className="col-12">
          <h2 className='second-page-heading'>{t('user.activityReport.meditation-session-heading')}</h2>
          <p dangerouslySetInnerHTML={{ __html: t('user.activityReport.meditation-session-description') }}></p>
        </div>
      </div>
      <div className='container'>
        <div id='chart'>
          <ReactApexChart type="bar" options={data?.options} series={data?.series} height={450} />
        </div>
      </div>
      <div className='bar-graph-btn'>
        <p className='meditation-type'>{t('user.activityReport.type-of-meditation')}</p>
        {/* <Link to={`/mindfulness/${props.repeatTime}`}>
        <button className='meditation-type-btn'>{t('user.activityReport.btn')}</button>
        </Link> */}
      </div>
    </div>
  )
}

export default MindfulnessBarChart
