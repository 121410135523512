import React from 'react'
import Sidebar from '../common/sidebar'
import { getLocalStoarge } from '../common/allfunction';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import shape from "../../assets/Image/shape.svg";
import { FaEllipsisH } from 'react-icons/fa';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import MEDITAZIONE_ICONA from '../../assets/Image/MEDITAZIONE_ICONA.svg'
import CLOCK_ICONA from '../../assets/Image/CLOCK_ICONA.svg'
import GroupLock from '../../assets/Image/GroupLock.svg'
import GroupBrainLock from '../../assets/Image/GroupBrainLock.svg'
import PostRequest from '../apiConnection/postRequest';
import LazyLoader from '../common/lazyLoader';
import TRAINING from '../../assets/Image/TRAINING COGNITIVO_ICONA.svg'
import Group1028 from "../../assets/Image/Group1028.svg";
import Modal from "../common/modal";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Group769 from '../../assets/Image/Group769.svg'
import REAZIONE_ICONA from '../../assets/Image/REAZIONE_ICONA.svg'
import GetRequest from '../apiConnection/getRequest';
import moment from 'moment';
import { Link, Redirect, useParams } from "react-router-dom";
import Group2841 from '../../assets/Image/Group2841.svg'
import GroupBrain959 from '../../assets/Image/GroupBrain959.svg'
import Sidebarmob from '../common/sidebarMobile';
import { useHistory } from "react-router-dom";
import Group1804 from '../../assets/Image/Group1804.svg';
import InfoActivityModel from '../common/infoActivityModel';
import NBack from '../../assets/Image/BW-Group841.svg'
// import HeaderIcon from "../../../../assets/Image/tutorial/Group841.svg";

import goNoGO from "../../assets/Image/tutorial/Group841.svg";
import rightArrow from '../../assets/Image/rightArrow.svg'
import leftArrowIcon from '../../assets/Image/leftArrowIcon.svg';

import Frame1 from '../../assets/Image/Frame (1).svg';
import planning_mental from '../../assets/Image/PIANIFICAZIONE_MENTALE_ICONA1.svg';
import FUNZIONI_ESECUTIVE_ICONA from '../../assets/Image/FUNZIONI_ESECUTIVE_ICONA.svg';
import { MindFullNessNewTab } from './mindfullnessNewTab';
// free activity images
import FREE_GONOGO_ICONA from '../../assets/Image/FREE_GONOGO_ICONA.svg';
import FREE_NBACK_ICONA from '../../assets/Image/FREE_NBACK_ICONA.svg';
import FREE_NEXT_ICONA from '../../assets/Image/FREE_NEXT_ICONA.svg'

export const CommonBrainWellness = (props) => {
    const [t, i18n] = useTranslation("common");
    const [detail, setDetail] = useState(JSON.parse(getLocalStoarge("health_data")));
    const [isLoading, setIsLoading] = useState(true);
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [userActivityData, setUserActivityData] = useState([]);
    const [trainingPopup, setTrainingPopup] = useState(false);
    const [componentId, setComponentId] = useState(1);
    const [item, setItem] = useState(4);
    const [totalItem, setTotalItem] = useState();
    const [numbers, setNumbers] = useState([1, 2, 3, 4, 5,]);
    const [repeatation, setRepeatation] = useState(props.match.params.repeatTime);
    const [popup, setPopup] = useState(props.match.params.open);
    const [activitiesStatus, setActivitiesStatus] = useState();
    const [cognitiveDate, setCognitiveDate] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment().startOf('day').unix());
    const [daywiseData, setDaywiseData] = useState([])
    const [dateDiff, setDateDiff] = useState();
    const [complete, setComplete] = useState(0);
    const [incomplete, setIncomplete] = useState(true);
    const [activeCard, setActiveCard] = useState(null);
    const [showSiderBar, setShowSideBar] = useState(false);
    const { repeatTime } = useParams();
    const [token, setToken] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("R1");
        const initialValue = JSON.parse(saved);
        return initialValue.access_token || "";
    });
    // const [repeatTime, setRepeatime] = useState(props.match.params.repeatTime);
    const [daySequence, setDaySequence] = useState(props.match.params.daySequence);
    const history = useHistory();
    const [enableLimit, setEnableLimit] = useState(27);
    const mindfullnessStep = getLocalStoarge("mindfullnessStep");

    {/**InfoActivty modal const */ }
    const [popupData, setPopupData] = useState({});
    const [modalIns1, setModalIns1] = useState(false);
    const [activeTab, setActiveTab] = useState("cognitive");
    const [refreshActivity,setRefreshActivity] = useState(0);
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if(tab == 'mindfullness'){
           setRefreshActivity(1);
        }
        else{
            setRefreshActivity(0);
        }
      };
      const [freeActivity, setFreeActivity] = useState([])

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        console.log(lang);
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setFreeActivity(
            [
                {
                    activityLogo: REAZIONE_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName1'),
                    activityDescription: t('user.activity.freeActivity.activityDescription1'),
                    buttonLink: '/reaction-speed-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free', viewTutorialLink: ''
                }, // done
                {
                    activityLogo: FUNZIONI_ESECUTIVE_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName2'),
                    activityDescription: t('user.activity.freeActivity.activityDescription2'),
                    buttonLink: '/executive-function-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                }, // done
                {
                    activityLogo: planning_mental,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName3'),
                    activityDescription: t('user.activity.freeActivity.activityDescription3'),
                    buttonLink: '/planning-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                }, // done
                {
                    activityLogo: Frame1,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName4'),
                    activityDescription: t('user.activity.freeActivity.activityDescription4'),
                    buttonLink: '/working-memory-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                }, // done
                {
                    activityLogo: FREE_NBACK_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName5'),
                    activityDescription: t('user.activity.freeActivity.activityDescription5'),
                    buttonLink: '/n-back-visivo-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                }, // done
                {
                    activityLogo: FREE_NBACK_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName:  t('user.activity.freeActivity.activityName6'),
                    activityDescription: t('user.activity.freeActivity.activityDescription6'),
                    buttonLink: '/n-back-movement-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                },
                {
                    activityLogo: FREE_NBACK_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName7'),
                    activityDescription: t('user.activity.freeActivity.activityDescription7'),
                    buttonLink: '/n-back-acoustic-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                },
                {
                    activityLogo: FREE_NBACK_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName:  t('user.activity.freeActivity.activityName8'),
                    activityDescription: t('user.activity.freeActivity.activityDescription8'),
                    buttonLink: '/n-back-visual-acoustic-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                },
                {
                    activityLogo: FREE_GONOGO_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName9'),
                    activityDescription: t('user.activity.freeActivity.activityDescription9'),
                    buttonLink: '/go-no-go-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                },
                {
                    activityLogo: FREE_GONOGO_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName10'),
                    activityDescription: t('user.activity.freeActivity.activityDescription10'),
                    buttonLink: '/go-no-go-simple-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                },
                {
                    activityLogo: FREE_GONOGO_ICONA,
                    commonHeading: t('user.sidebar.cognitive_training'),
                    activityName: t('user.activity.freeActivity.activityName11'),
                    activityDescription: t('user.activity.freeActivity.activityDescription11'),
                    buttonLink: '/go-no-go-complex-intro/1/'+props?.match?.params?.repeatTime+'/1/1/free',
                    viewTutorialLink: ''
                },
            ]
        )
    }, [])


    const [freeActivityResult, setFreeActivityResult] = useState(localStorage.getItem('cognativeResult_'+props?.match?.params?.repeatTime) ? JSON.parse(localStorage.getItem('cognativeResult_'+props?.match?.params?.repeatTime)) : []);
    useEffect(() => {
        coginitiveTrainingData(repeatTime);
    }, [repeatTime])


    useEffect(() => {

    }, [userActivityData])


    const coginitiveTrainingData = async (repeatTime) => {
        setIsLoading(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllCognitiveData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ALL_DAYWISE_TRAINING}?repeat_time=${repeatTime}&component_id=${componentId}&company_id=${companyId}`,
                    refreshToken
                );
                if (getAllCognitiveData && getAllCognitiveData.status === 200) {
                    setIsLoading(false);
                    setShowSideBar(true);
                    setTotalItem(getAllCognitiveData.data.data.user_all_activity_data.length)
                    const dateDifference = moment(moment.unix(currentDate).format("YYYY-MM-DD")).diff(moment(moment.unix(getAllCognitiveData.data.data.user_all_activity_data[0].start_training_date).format("YYYY-MM-DD")), 'days')
                    setDateDiff(moment(moment.unix(currentDate).format("YYYY-MM-DD")).diff(moment(moment.unix(getAllCognitiveData.data.data.user_all_activity_data[0].start_training_date).format("YYYY-MM-DD")), 'days') + 1)

                    const originalDate = moment.unix(getAllCognitiveData.data.data.user_all_activity_data[0].start_training_date);
                    //postAssessment start date resultTimestamp
                    const newDate = originalDate.add(34, 'days'); // if you open post assessment on 35th days
                    const postAssissmentStartDate = newDate.unix();

                    //postAssessment end date resultTimestamp
                    const finalDate = newDate.add(15, 'days');
                    const postAssissmentEndDate = finalDate.unix();


                    if (getAllCognitiveData.data.data?.user_all_activity_data.length > 0) {
                        let arr = [];
                        let card = [];
                        let isPostAssessment = 0;
                        let finalDiff = [];
                        let postAssessmentButton = false
                        card[0] = 1;
                        finalDiff[0] = 2;
                        let activityLength = getAllCognitiveData.data.data.user_all_activity_data.length;
                        getAllCognitiveData.data.data.user_all_activity_data.forEach((data, i) => {
                            let completed = 0;
                            data.status.forEach((d1) => {
                                if (d1 === 'complete') {
                                    completed++
                                }
                            })
                            if (completed == data.status.length) {
                                setIncomplete(false)
                            }
                            let totalTime = 0;
                            data.activities_total_time.forEach((t1) => {
                                totalTime += t1
                            })
                            let particularActivityTime = 0;
                            data.activities_play_time.forEach((t2) => {
                                particularActivityTime += t2
                            })
                            card[i + 1] = data.allActivityComplete;
                            finalDiff[i + 1] = data.finalCompleteDate != '' ? moment(moment.unix(currentDate).format("YYYY-MM-DD")).diff(moment(moment.unix(data.finalCompleteDate).format("YYYY-MM-DD")), 'days') + 1 : 0;
                            if (getAllCognitiveData.data.data.user_all_activity_data[15].allActivityComplete === 0) {
                                if (getAllCognitiveData.data.data.user_all_activity_data[0].allActivityComplete === 1) {
                                    if (postAssissmentStartDate <= currentDate && postAssissmentEndDate >= currentDate) {
                                        isPostAssessment = 1;
                                        card[i] = 1;
                                        finalDiff[i] = 2;
                                        postAssessmentButton = true;
                                    } else if (getAllCognitiveData.data.data.user_all_activity_data[16].allActivityComplete === 1 || dateDiff > 50) {
                                        isPostAssessment = 1;
                                        card[i] = 1;
                                        finalDiff[i] = 2;
                                        postAssessmentButton = true;
                                    }
                                }
                            }else if (getAllCognitiveData.data.data.user_all_activity_data[15].allActivityComplete === 1){
                                postAssessmentButton = true;
                            }

                            if (dateDifference > 51) {
                                isPostAssessment = 1;
                                card[i] = 1;
                                finalDiff[i] = 2;
                                postAssessmentButton = true;
                            }


                            arr = [...arr, {
                                user_id: data.user_id,
                                activities_score: data.activities_score || "",
                                activity_ids: data.activity_ids,
                                day_sequence: data.day_sequence || "",
                                type: data.type,
                                repeat_time: data.repeat_time,
                                status: data.status,
                                company_id: data.company_id,
                                component_id: data.component_id,
                                activities_round_no: data.activities_round_no,
                                activities_play_time: data.activities_play_time,
                                activities_total_time: data.activities_total_time,
                                activity_names: data.activity_names,
                                start_training_date: data.start_training_date,
                                activityCompleted: completed,
                                activityTotalTime: totalTime,
                                particularActivityTime: particularActivityTime,
                                cardEnable: card[i],
                                finalDiff: finalDiff[i],
                                allActivityComplete: data.allActivityComplete,
                                isPostAssessment: isPostAssessment,
                                postAssissmentStartDate,
                                postAssissmentEndDate,
                                postAssessmentButton
                            }]
                        })
                        setUserActivityData([])
                        setUserActivityData(prevData => (arr));
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                    setShowSideBar(true);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false)
            }

        } catch (error) {
            setIsLoading(false);
        }
    }


    const handleTraining = (componentId, day_sequence, repeatTime) => {
        setIsLoading(true)
        setTrainingPopup(true);
        getDaywiseActivityData(componentId, day_sequence, repeatTime);
        // setRepeatime(repeatTime);
        setDaySequence(day_sequence);

    }

    const closeModal = () => {
        if (popup == "true") {
            //    Redirect code should be here
            history.push(`/congnitive-training/${repeatation}`);
        }
        setTrainingPopup(false);
        setDaywiseData([]);
        coginitiveTrainingData(repeatTime);
    }

    const getDaywiseActivityData = async (componentId, day_sequence, repeatTime) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDaywiseActivity = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PARTICULAR_DAYWISE_TRAINING}?component_id=${componentId}&day_sequence=${day_sequence}&repeat_time=${repeatTime}`,
                );
                setIsLoading(false)
                if (getDaywiseActivity && getDaywiseActivity.status === 200) {
                    setDaywiseData(getDaywiseActivity?.data?.data?.daywise_plan)
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }

    const progressVal = (activityStatus, allActivity) => {
        let result = 0
        for (let i = 0; i < activityStatus.length; i++) {
            if (activityStatus[i] === 'complete') {
                result++
            }

        }
        let finalScore = Math.floor(result * 100 / allActivity)
        return finalScore
    }

    function getProgressBarColorClass(score) {
        if (score === 0) {
            return 'progress-bar-color-0';
        } else if (score <= 25) {
            return 'progress-bar-color-25';
        } else if (score <= 50) {
            return 'progress-bar-color-50';
        } else if (score <= 75) {
            return 'progress-bar-color-75';
        } else {
            return 'progress-bar-color-100';
        }
    }


    const getActivityTime = (allActivityTime) => {
        let allTime = 0
        for (let i = 0; i < allActivityTime?.length; i++) {
            allTime += allActivityTime[i]
        }
        return allTime
    }



    const trainingModalContent = () => {
        return (
            <>
                <div className="model-pop-new" >
                    <div className="model-pop ">
                        <div className="modal-header">
                            <h5 className="modal-title pop_heading" id="exampleModalLabel">
                                <div className='d-flex'>
                                    <div className="mr-3">
                                        <img src={GroupBrain959} alt="icon" />
                                    </div>
                                    {(daywiseData.day_sequence == 1) ? <strong>{t("user.cognitive_training.cognitive-model-heading")}</strong> : (daywiseData.day_sequence > 1 || daywiseData.day_sequence < 17) ? <strong>{t("user.cognitive_training.heading_1")} {daywiseData.day_sequence}</strong> : (daywiseData.day_sequence == 17) ? <strong>{t("user.cognitive_training.post_Assessment")}</strong> : ''}
                                </div>
                            </h5>
                            <button
                                type="button"
                                className="close r0-side"
                                onClick={() => { closeModal() }}
                            >
                                <img src={Group769} alt="icon" />
                            </button>
                        </div>
                        <div></div>
                        <div className="modal-body scroll-pop scroll-pop-new">
                            <div className='row'>
                                {daywiseData && daywiseData.activity_ids?.length > 0 ? daywiseData?.activity_ids?.map((activity, index) => (
                                    <>
                                        <div className='col-sm-6 pt-2 pb-3 d-flex' key={index}>
                                            <div className='first-training'>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <div className='activity-card'>
                                                                <img src={(activity.activity_name == 'Reaction speed') ? REAZIONE_ICONA : (activity.activity_name == 'Executive functions') ? FUNZIONI_ESECUTIVE_ICONA : (activity.activity_name == 'Planning') ? planning_mental : (activity.activity_name == 'Working memory') ? Frame1 : ''} alt="" />
                                                                <span><strong>{activity.activity_name}</strong></span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className='activity-min'>{activity.activity_duration}MIN</p>
                                                        </div>

                                                    </div>
                                                    <p className='activity-discription' dangerouslySetInnerHTML={{__html:activity.activity_description}}></p></div>
                                                <div>
                                                    <p className='activity-model-btn'>
                                                        {(daywiseData.day_sequence === 1 && activity.status === 'in-complete' && (activity.activity_name === "Reaction speed" || activity.activity_name === "Velocità di reazione")) ?
                                                            <Link to={`/reaction-speed-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`}>
                                                                <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                            </Link>
                                                            : (daywiseData.day_sequence === 1 && activity.status === 'in-complete' && (activity.activity_name === "Executive functions" || activity.activity_name === "Funzioni esecutive")) ?
                                                                <Link to={`/executive-function-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`}>
                                                                    <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                                </Link>
                                                                : (daywiseData.day_sequence === 1 && activity.status === 'in-complete' && (activity.activity_name === "Working memory" || activity.activity_name === "Memoria di lavoro")) ? <>
                                                                    <Link to={`/working-memory-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`}>
                                                                        <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                                    </Link>
                                                                </> : (daywiseData.day_sequence === 1 && activity.status === 'in-complete' && (activity.activity_name === "Planning" || activity.activity_name === "Pianificazione")) ? <>
                                                                    <Link to={`/planning-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`}>
                                                                        <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                                    </Link>
                                                                </> : (daywiseData.day_sequence === 17 && activity.status === 'in-complete' && (activity.activity_name === "Reaction speed" || activity.activity_name === "Velocità di reazione")) ?
                                                                    <Link to={`/reaction-speed-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`}>
                                                                        <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                                    </Link>
                                                                    : (daywiseData.day_sequence === 17 && activity.status === 'in-complete' && (activity.activity_name === "Executive functions" || activity.activity_name === "Funzioni esecutive")) ?
                                                                        <Link to={`/executive-function-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`}>
                                                                            <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                                        </Link>
                                                                        : (daywiseData.day_sequence === 17 && activity.status === 'in-complete' && (activity.activity_name === "Working memory" || activity.activity_name === "Memoria di lavoro")) ? <>
                                                                            <Link to={`/working-memory-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`}>
                                                                                <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                                            </Link>
                                                                        </> : (daywiseData.day_sequence === 17 && activity.status === 'in-complete' && (activity.activity_name === "Planning" || activity.activity_name === "Pianificazione")) ? <>
                                                                            <Link to={`/planning-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`}>
                                                                                <button type='button' className='start-training'>{t('user.cognitive_training.start')}</button>
                                                                            </Link>
                                                                        </>
                                                                            : (daywiseData.day_sequence > 1 && activity.status === 'in-complete' && (activity.activity_name === "N-BACK/visual" || activity.activity_name === "N-BACK/visivo")) ?
                                                                                <>

                                                                                    <button type='button' className='start-training' onClick={(e) => handleInfoModel(e, `/n-back-visivo-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`, daywiseData.day_sequence, activity.activity_name, activity.model_title, activity.model_description, NBack)}>{t('user.cognitive_training.start')}</button>

                                                                                </>
                                                                                : (daywiseData.day_sequence > 1 && activity.status === 'in-complete' && (activity.activity_name === "GO-NO-GO/visualacoustic/simple" || activity.activity_name === "GO-NO-GO/visivoacustico/semplice")) ?
                                                                                    <>

                                                                                        <button type='button' className='start-training' onClick={(e) => handleInfoModel(e, `/go-no-go-simple-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`, daywiseData.day_sequence, activity.activity_name, activity.model_title, activity.model_description, goNoGO)}>{t('user.cognitive_training.start')}</button>

                                                                                    </>
                                                                                    : (daywiseData.day_sequence > 1 && activity.status === 'in-complete' && (activity.activity_name === "GO-NO-GO/visualacoustic/complex" || activity.activity_name === "GO-NO-GO/visivoacustico/complesso")) ?
                                                                                        <>

                                                                                            <button type='button' className='start-training' onClick={(e) => handleInfoModel(e, `/go-no-go-complex-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`, daywiseData.day_sequence, activity.activity_name, activity.model_title, activity.model_description, goNoGO)}>{t('user.cognitive_training.start')}</button>

                                                                                        </>
                                                                                        : (daywiseData.day_sequence > 1 && activity.status === 'in-complete' && (activity.activity_name === "N-BACK/movements" || activity.activity_name === "N-BACK/movimenti")) ?
                                                                                            <>

                                                                                                <button type='button' className='start-training' onClick={(e) => handleInfoModel(e, `/n-back-movement-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`, daywiseData.day_sequence, activity.activity_name, activity.model_title, activity.model_description, NBack)}>{t('user.cognitive_training.start')}</button>

                                                                                            </>
                                                                                            : (daywiseData.day_sequence > 1 && activity.status === 'in-complete' && (activity.activity_name === "N-BACK/acoustic" || activity.activity_name === "N-BACK/acustico")) ?
                                                                                                <>

                                                                                                    <button type='button' className='start-training' onClick={(e) => handleInfoModel(e, `/n-back-acoustic-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`, daywiseData.day_sequence, activity.activity_name, activity.model_title, activity.model_description, NBack)}>{t('user.cognitive_training.start')}</button>

                                                                                                </>
                                                                                                : (daywiseData.day_sequence > 1 && activity.status === 'in-complete' && (activity.activity_name === "N-BACK/visuoacoustic" || activity.activity_name === "N-BACK/visivoacustico")) ?
                                                                                                    <>
                                                                                                        <button type='button' className='start-training' onClick={(e) => handleInfoModel(e, `/n-back-visual-acoustic-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`, daywiseData.day_sequence, activity.activity_name, activity.model_title, activity.model_description, NBack)}>{t('user.cognitive_training.start')}</button>
                                                                                                    </>
                                                                                                    : (daywiseData.day_sequence > 1 && activity.status === 'in-complete' && (activity.activity_name === "GO-NO-GO/visual" || activity.activity_name === "GO-NO-GO/visivo")) ?
                                                                                                        <button type='button' className='start-training' onClick={(e) => handleInfoModel(e, `/go-no-go-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`, daywiseData.day_sequence, activity.activity_name, activity.model_title, activity.model_description, goNoGO)}>{t('user.cognitive_training.start')}</button>
                                                                                                        :
                                                                                                        (daywiseData.day_sequence > 1 && activity.status === 'in-complete' && (activity.activity_name === "N-BACK/visual" || activity.activity_name === "N-BACK/visivo")) ?
                                                                                                            <button type='button' className='start-training' onClick={(e) => handleInfoModel(e, `/n-back-visivo-intro/${activity.component_id}/${repeatTime}/${daySequence}/${activity.id}`, daywiseData.day_sequence, activity.activity_name, activity.model_title, activity.model_description, NBack)}>{t('user.cognitive_training.start')}</button>

                                                                                                            : (activity.status === 'complete') ? <p className='complete-right'>
                                                                                                                <button type='button' className='complete-btn' >{t('user.cognitive_training.complete')}</button></p> : ''
                                                        }
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )) : ''}

                            </div>

                        </div>
                    </div>
                </div>

                {isLoading ? <LazyLoader /> : ''}
            </>
        )
    }

    const option = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 4,
                nav: true,
                loop: false
            }
        }
    }

    const getActiveCard = (userActivityData) => {
        let active = 0;
        for (const item of userActivityData) {
            if (item.cardEnable === 1 && item.finalDiff > 1) {
                active++
            }
        }
        return active
    }


    const handleInfoModel = (e, link, day, activityName, title, description, img) => {
        let modalRecord = {
            modalHeading: activityName,
            icon: img,
            modelTitle: title,
            modelDescription: description,
            startLink: link,
            day: day
        };
        setPopupData({ ...modalRecord });
        setModalIns1(true)
    }

    const handleModal = () => {
        setModalIns1(false);
    }

    const activityPlayTime = (playTime) => {
        let result = 0;
        result = 100 * playTime / 300
        return result
    }
    const GotoCommonDashboard = ()=>{
        window.location.href="/app/common-dashboard"
       }


    return (
        <>
            <Modal title="" size="sm" contentClasses="training-model-popup" onClose={() => setTrainingPopup(false)} show={trainingPopup}>
                {trainingModalContent()}
            </Modal>

            {(modalIns1) ? <InfoActivityModel popUpEnable={modalIns1} handleModal={handleModal} header={popupData} /> : null}

            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar repeatTime={repeatation} type={'cognitive'} />
                        <div className=" col-md-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace leftr-dari">
                            <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-5 col-xl-6 pr-sm-0 pr-md-0">
                                <h2 className="heading-dash">
                                {t("user.dashboard.brain_wellness")}
                                </h2>
                                <div className="dashboard-tabs">
                                <ul className="nav nav-tabs" id="myTabs" role="tablist">
                                    <li className="nav-item"><a className={`nav-link ${activeTab === "cognitive" ? "active show" : ""}`} href="javascript:void(0)" onClick={() => handleTabClick("cognitive")}> {t("user.dashboard.training-cognitive")}</a></li>
                                    <li className="nav-item"><a className={`nav-link ${activeTab === "mindfullness" ? "active show" : ""}`} href="javascript:void(0)" onClick={() => handleTabClick("mindfullness")} >{t("user.dashboard.training-mindfullness")}</a> </li>
                                </ul>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-4 col-xl-4 pl-sm-0">
                                <div className="cmd-back-right col-12" >
                                <p className="cmd-back-right-text pr-2 col-9 back-btn-text-color" dangerouslySetInnerHTML={{ __html: t("user.dashboard.back_to_cmd") }}>
                                </p>
                                <button className="btn-apr col-3" onClick={GotoCommonDashboard}>{t("user.dashboard.go")}</button>
                              </div>
                            </div>
                                    
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2 pl-sm-0">
                            {/* <div className="align-j pt-2">                    
                            </div> */}
                            <div className="righ-side-tab top-right-score bunono-w50" id="step-8">
                                <div className="logo-shape">
                                <img src={shape} alt="icon" />
                                <span>
                                    {detail.HEALTH_SCORE <= 20 ? (
                                    <p className="">
                                        {t("user.dashboard.buono")}
                                    </p>
                                    ) : detail.HEALTH_SCORE >= 21 &&
                                    detail.HEALTH_SCORE <= 40 ? (
                                    <p className="">
                                        {t("user.dashboard.buono1")}
                                    </p>
                                    ) : detail.HEALTH_SCORE >= 41 &&
                                    detail.HEALTH_SCORE <= 60 ? (
                                    <p className="">
                                        {t("user.dashboard.buono2")}
                                    </p>
                                    ) : detail.HEALTH_SCORE >= 61 &&
                                    detail.HEALTH_SCORE <= 80 ? (
                                    <p className="">
                                        {t("user.dashboard.buono3")}
                                    </p>
                                    ) : detail.HEALTH_SCORE >= 81 &&
                                    detail.HEALTH_SCORE <= 100 ? (
                                    <p className="">
                                        {t("user.dashboard.buono4")}
                                    </p>
                                    ) : (
                                    <span className=""></span>
                                    )}
                                </span>
                                </div>
                                <div className="w80 d-text-custom">
                                {detail.HEALTH_SCORE
                                    ? parseFloat(detail.HEALTH_SCORE)
                                    : "0"}
                                </div>
                            
                            </div>
                            </div>
                            <div className="heading-select pl-2"><strong class="mr-3">{t("user.cognitive_training.daily_activity")}</strong></div>
                            </div>
                            <div className="tab-content">
                                <div className={`tab-pane ${activeTab === "cognitive" ? "active show" : ""}`} id="cognitive">
                                <>
                                    {userActivityData && userActivityData?.length > 0 ?
                                        <OwlCarousel {...option} navText={[`<img src=${leftArrowIcon} alt="" className='mr-1' />`, `<img src=${rightArrow} alt="" className='mr-1' />`]} startPosition={0} items={4} dots={false} slideBy={4} className='owl-theme' loop={false} margin={10} nav={true}>
                                            {
                                                userActivityData?.map((userActivity, i) => (

                                                    <>
                                                        {(dateDiff >= userActivity.day_sequence && userActivity.cardEnable == 1 && userActivity.finalDiff > 1) ? <>
                                                            <div className='item' key={i}>
                                                                <div className='d-flex card-top'>
                                                                    <div className='img-heading'>
                                                                        <div className="mr-3">
                                                                            {(getActiveCard(userActivityData) === userActivity.day_sequence && userActivity.allActivityComplete === 0) ? <img src={GroupBrain959} alt="icon" style={{ background: 'aliceblue', borderRadius: '5px' }} className="dark-today" /> : <img src={Group1804} alt="icon" className="new-sidebar-icon" />}
                                                                        </div>
                                                                        {(getActiveCard(userActivityData) === userActivity.day_sequence && userActivity.allActivityComplete === 0) ? <h6 className='activity-name-font mb-0'>{t("user.cognitive_training.today")}</h6> : <h6 className='activity-name-font mb-0'>{t("user.cognitive_training.heading_1")} {userActivity.day_sequence}</h6>}
                                                                    </div>
                                                                    <div>
                                                                        {/* <i className="colorNew visi-none">
                                                                        {" "}
                                                                        <FaEllipsisH
                                                                            className="pointer"
                                                                        />
                                                                    </i> */}
                                                                    </div>
                                                                </div>

                                                                <div className='d-flex justify-content-between align-items-center mt-2 pl-3 pr-3'>
                                                                    <div>
                                                                        <span className='activity-id-name'>{userActivity.activity_ids?.length} {t("user.cognitive_training.activity")}</span>
                                                                    </div>
                                                                    {(userActivity.day_sequence == 17 || userActivity.day_sequence == 1) ? <div className='clock'>
                                                                        <span className="clock-img"><img src={CLOCK_ICONA} alt="" className='mr-1' /></span>
                                                                        <span className='activity-id-name'>{getActivityTime(userActivity.activities_total_time)} {t("user.cognitive_training.time")}</span>
                                                                    </div> : null}
                                                                </div>

                                                                {(userActivity.day_sequence == 1) ? <><p className='assisment pl-3 pr-3 mt-3'>{t("user.cognitive_training.pre_Assessment")}</p>
                                                                    <div className='pl-3 pr-3'>
                                                                        <div className="congnative-progress progress">
                                                                            <div className={`progress-bar ${getProgressBarColorClass(progressVal(userActivity.status, userActivity.activity_ids.length))}`} role="progressbar" style={{
                                                                                width: `${progressVal(userActivity.status, userActivity.activity_ids.length)}%`,
                                                                            }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                    </div>
                                                                    <p className='progress-number'>{`${progressVal(userActivity.status, userActivity.activity_ids.length)}%`} {t("user.cognitive_training.progress")}</p></> : ''}

                                                                {(userActivity.day_sequence == 17) ? <><p className='assisment pl-3 pr-3'>{t("user.cognitive_training.post_Assessment")}</p>
                                                                    <div className='pl-3 pr-3'>
                                                                        <div className="congnative-progress progress">
                                                                            <div className={`progress-bar ${getProgressBarColorClass(progressVal(userActivity.status, userActivity.activity_ids.length))}`} role="progressbar" style={{
                                                                                width: `${progressVal(userActivity.status, userActivity.activity_ids.length)}%`,
                                                                            }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                                        </div>
                                                                    </div>
                                                                    <p className='progress-number'>{`${userActivity.activityCompleted}/${userActivity.activity_ids.length}`} {t("user.cognitive_training.answer")}</p></> : ''}
                                                                <div className='mt-training-cognitivo'>
                                                                    {userActivity.day_sequence >= 2 && userActivity.day_sequence <= 16 && userActivity.activity_names && userActivity.activity_names.map((activityName, index) => (
                                                                        <div key={index}>
                                                                            <p className='assisment pl-3 pr-3 m-0'>{activityName?.replaceAll('/', ' / ')}</p>
                                                                            {userActivity.activities_play_time && (
                                                                                <>
                                                                                    <div className='pl-3 pr-3 pt-1'>
                                                                                        <div className="congnative-progress progress">
                                                                                            <div className={`progress-bar ${getProgressBarColorClass(activityPlayTime(userActivity.activities_play_time[index]))}`} role="progressbar" style={{ width: `${activityPlayTime(userActivity.activities_play_time[index])}%` }} aria-valuenow={activityPlayTime(userActivity.activities_play_time[index])} aria-valuemin="0" aria-valuemax="100"></div>
                                                                                        </div>
                                                                                        {(userActivity.activities_play_time[index] > 0) ? <p className='progress-number-day'>{`${(activityPlayTime(userActivity.activities_play_time[index])) ? Math.floor(activityPlayTime(userActivity.activities_play_time[index])) : 0}%`} {t("user.cognitive_training.progress")}</p> : <p className='progress-number-day'>0% {t("user.cognitive_training.progress")}</p>}
                                                                                    </div></>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {/* {(userActivity.day_sequence === dateDiff) ? <div className='btn-start'><button type='button' className='start-btn' onClick={() => handleTraining(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}>{t("user.cognitive_training.start")}</button></div> : ''} */}
                                                                {(userActivity.allActivityComplete === 0) && (userActivity.day_sequence < 17) && (userActivity.postAssessmentButton === false) && (userActivity.postAssissmentEndDate >= currentDate) ? <div className='btn-start'><button type='button' className='start-btn' onClick={() => handleTraining(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}>{t("user.cognitive_training.start")}</button>
                                                                </div> : ((dateDiff < 51) && (userActivity.day_sequence === 17) && (userActivity.allActivityComplete === 0) && (userActivity.postAssessmentButton)) ? <div className='btn-start'><button type='button' className='start-btn' onClick={() => handleTraining(userActivity.component_id, userActivity.day_sequence, userActivity.repeat_time)}>{t("user.cognitive_training.start")}</button>
                                                                </div> : null}

                                                            </div>
                                                        </> :
                                                            <div className='item-lock' key={i}>
                                                                <p className='img-lock-card card-top'><span className='ml-1'><img src={Group1804} alt="" /></span><span>{t('user.cognitive_training.day')}</span> <span className='m-1'>{userActivity.day_sequence}</span></p>
                                                                <div className='lock-card p-3'>
                                                                    <div className='lock-image-section'>
                                                                        <img src={GroupLock} alt="" className='img-lock' />
                                                                    </div>

                                                                </div>
                                                            </div>}

                                                    </>
                                                ))}

                                            {(userActivityData[16]?.allActivityComplete === 1) ? <div className='item'>
                                                <div className='d-flex p-3'>
                                                    <div className='img-heading'>
                                                        <div className="ico-background">
                                                            <img src={GroupBrain959} alt="icon" className="ico-color" />

                                                        </div>
                                                        <h6>{t("user.cognitive_training.today")}</h6>

                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center mt-2 p-2'>
                                                    <div>

                                                    </div>
                                                    <div className='clock'>
                                                    </div>
                                                </div>

                                                <h4 className='report-heading p-2 mt-3 mb-0'>Report</h4>
                                                <p className='text-left p-2 pt-0 m-0'>{t("user.activityReport.description")}</p>

                                                <div className='report-btn'>
                                                    <Link to={`/report-cognitive/${componentId}/${repeatTime}`}>
                                                        <button type='button' className='start-btn'>{t("user.activityReport.view")}</button>
                                                    </Link>
                                                </div>

                                            </div> : null}

                                        </OwlCarousel> : ''}
                                </>
                               <p className='total-item'>{userActivityData && userActivityData?.length > 0 ? `${getActiveCard(userActivityData)}/${(userActivityData?.length)}` : null}</p>
                                <div className='mt-2'>
                                    <p className='text-left'><strong>{t("user.cognitive_training.free")}</strong></p>
                
                                    <OwlCarousel {...option} navText={[`<img src=${leftArrowIcon} alt="" className='mr-1' />`, `<img src=${rightArrow} alt="" className='mr-1' />`]} startPosition={0} items={4} dots={false} slideBy={4} className='owl-theme' loop={false} margin={10} nav={true}>
                                       
                                        {freeActivity && freeActivity.map((item, index) => (

                                            <div className='item' key={index}>
                                                <div className='d-flex card-top'>
                                                    <div className='img-heading'>
                                                        <div className="mr-2">
                                                            <img src={item?.activityLogo} alt="icon" className="new-sidebar-icon" />
                                                        </div>
                                                        <h6 className='activity-name-font mb-0'>{item?.commonHeading}</h6>
                                                    </div>
                                                    {/* <div>
                                                        <i className="colorNew visi-none">
                                                            {" "}
                                                            <FaEllipsisH
                                                                className="pointer"
                                                            />
                                                        </i>
                                                    </div> */}
                                                </div>

                                                {

                                                    (freeActivityResult[index] && freeActivityResult[index]?.activityName == item?.activityName) ?
                                                        <div className="mt-2">
                                                            <p className='assisment pl-3 pr-3 m-0'>{item?.activityName}</p>
                                                            <div className="d-flex mt-2">
                                                                <div className="col-5">
                                                                    <CircularProgressbar value={freeActivityResult[index]?.avgAccuracy} maxValue={1} strokeWidth={12} styles={buildStyles({
                                                                        pathColor: parseInt(Math.round(parseFloat(freeActivityResult[index]?.avgAccuracy) * 100)) >= 60 ? '#9ECB61' : '#B02418',
                                                                    })}
                                                                    /></div>
                                                                <div className="col-7">
                                                                    <h6><span className="free-activity-score">{Math.round(parseFloat(freeActivityResult[index]?.avgAccuracy) * 100)}</span>%</h6>
                                                                </div>
                                                            </div>


                                                            <div className='free-activity-btn justify-content-end'>
                                                                <Link to={`${item?.buttonLink}`}>
                                                                    <button type='button' className='free-btn-start'>{t("superadmin.protocol.try_again_btn")}</button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className='mt-5'>
                                                                <p className='assisment pl-3 pr-3 m-0'>{item?.activityName}</p>
                                                                <p className='text-left pl-3 pr-3'>{item?.activityDescription}</p>
                                                            </div>

                                                            <div className='free-activity-btn'>
                                                                <div className='d-flex'>
                                                                    {/* <a href="javascript:void(0)" className='anchor-view'>{t('user.activity.reaction_speed.see_tutorial')}</a><span><img src={FREE_NEXT_ICONA} alt="" className='free-next' /></span> */}
                                                                </div>

                                                                <Link to={`${item?.buttonLink}`}>
                                                                    <button type='button' className='free-btn-start'>{t("user.cognitive_training.start")}</button>
                                                                </Link>
                                                            </div>
                                                        </>


                                                }

                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                                </div>
                                <div className={`tab-pane row ${activeTab === "mindfullness" ? "active show" : ""}`} id="mindfullness">
                                    <MindFullNessNewTab repeatTime= {mindfullnessStep} refreshActivity = {refreshActivity}/>
                                 </div>                                               
                            </div>
                        </div>
                    </div>
                    </div>
                    {(isLoading) ? <LazyLoader /> : null}
                </div>
            </section>
            <div className="act-ve">
                <Sidebarmob />
            </div>


        </>
    )
}
