import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Image/logo.svg";
import DOCUMENTO_ICONA from "../../assets/Image/DOCUMENTO_ICONA.svg";
import SidebarProfile from "../common/sidebarProfile";
import Sidebarmob from "../common/sidebarMobile";
import BRAINWELLNESS_ICONA from "../../assets/Image/BRAINWELLNESS_ICONA.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from "../common/allfunction";
import Group1874 from '../../assets/Image/Group 1874.svg'
import leftarrow from "../../assets/Image/bwVector.svg";
import groupStaticRefe from "../../assets/Image/Group_static_refe.svg";
import brainwelnessIconPage from "../../assets/Image/brainwelness_icon_page.svg";

function riferimenti() {

  const [t, i18n] = useTranslation('common');
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    handleTranslationLang();
    scrollMoveTop();
  }, []);

  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }
  const scrollMoveTop = () => {
    if (!scrollTop) {
      window.scrollTo(0, 0);
      setScrollTop(true);
    }
  }

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
      <section className="bg-privacy brain-wellness-img dashboard-mobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 topspace d-lfti none-viewport ipad-widhset">
              <SidebarProfile />
            </div>
            <div className="col-md-10 ipd-righ">
              <div className="top-priv-static">
                <div className="row">

                  <div className="col-md-12">
                    <h2 className="d-flex otp-heading m-bspaci imposta referment-h">
                      <Link to="/referement-science">
                        <img src={leftarrow} className="mr-2" alt="icon" />
                        {t('user.referments.heading')}
                      </Link>
                    </h2>
                  </div>

                  <div className="static-left">
                    <div className="static-left-img-h">
                      <img src={brainwelnessIconPage} alt="icon" />
                      <h2>{t('user.referement_science.second_card_name')}</h2>
                    </div>
                  </div>
                  <div className="col-md-12 m-bspaci">
                    <h5 className="second-heading-brain" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_second') }}></h5>
                  </div>

                  <div className="col-md-12 m-bspaci">
                    <h5 className="sub-heading-brain" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_1') }}></h5>
                    <p className="brainwell_p" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_p_1') }}></p>
                  </div>
                  <div className="col-md-12 m-bspaci">
                    <h5 className="sub-heading-brain" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_2') }}></h5>
                    <p className="brainwell_p" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_p_2') }}></p>
                  </div>
                  <div className="col-md-12 m-bspaci">
                    <h5 className="sub-heading-brain" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_3') }}></h5>
                    <p className="brainwell_p" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_p_3') }}></p>
                  </div>
                  <div className="col-md-12 m-bspaci">
                    <h5 className="sub-heading-brain" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_4') }}></h5>
                    <p className="brainwell_p" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_p_4') }}></p>
                  </div>
                  <div className="col-md-12 m-bspaci">
                    <h5 className="sub-heading-brain" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_5') }}></h5>
                    <p className="brainwell_p" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_p_5') }}></p>
                  </div>
                  <div className="col-md-12 m-bspaci">
                    <h5 className="sub-heading-brain" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_6') }}></h5>
                    <p className="brainwell_p" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_p_6') }}></p>
                  </div>
                  <div className="col-md-12 m-bspaci">
                    <h5 className="sub-heading-brain" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_7') }}></h5>
                    <p className="brainwell_p" dangerouslySetInnerHTML={{ __html: t('user.brainwelness_science.heading_p_7') }}></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="act-ve">
        <Sidebarmob />
      </div>
    </>
  );
}

export default riferimenti;
