
import { useEffect, useState } from "react";
import { Walktour } from "walktour";
import shape from "../../assets/Image/shape.svg";
import heart from "../../assets/Image/heart.svg";
import iconLungs from "../../assets/Image/iconLungs.svg";
import mitter from "../../assets/Image/mitter.svg";
import outlineHeart from "../../assets/Image/outlineHeart.svg";
import wave from "../../assets/Image/wave.svg";
import carico from "../../assets/Image/carico.svg";
import capacita from "../../assets/Image/capacita.svg";
import stress from "../../assets/Image/stress.svg";
import Group911 from "../../assets/Image/Group911.svg";
import Group938 from "../../assets/Image/Group938.svg";
import massacorp from "../../assets/Image/massacorp.svg";
import Stressmentale from "../../assets/Image/STRESS_black.svg";
import cardiovascular from "../../assets/Image/cardiovascular.svg";
import Group846545011 from "../../assets/Image/Group846545011.svg";
import INFO_iconi from "../../assets/Image/INFO_iconi.svg";
import ATTACCOCARDIACOICONA from "../../assets/Image/ATTACCOCARDIACOICONA.svg";
import ICTUSICONA from "../../assets/Image/ICTUSICONA.svg";
import Group728 from "../../assets/Image/Group728.svg";
import GLICEMIAICONA from "../../assets/Image/GLICEMIAICONA.svg";
import Grouprishico from "../../assets/Image/Grouprishico.svg";
import PASSWORD_ICONA from '../../assets/Image/PASSWORD_ICONA.svg';
import Group_1175PP from '../../assets/Image/Group_1175PP.png';
import Group_1175UI from '../../assets/Image/Group_1175UI.png';
import Group_1175AND from '../../assets/Image/GroupUnlock101023.png';
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import Indicedimassacorporea1 from "../../assets/Image/Indice-di-massa-corporea1.svg";
import Group769 from "../../assets/Image/Group769.svg";
import FISIOLOGICIICONA from "../../assets/Image/FISIOLOGICIICONA.svg";
import Group784 from "../../assets/Image/Group784.svg";
import Group792 from "../../assets/Image/Group792.svg";
import Group785 from "../../assets/Image/Group785.svg";
import Rischiodiictus from "../../assets/Image/Rischio-di-ictus.svg";
import Group781 from "../../assets/Image/Group781.svg";
import DIABETE from "../../assets/Image/DIABETE_ICONA1.svg";
import Diabetebigpopup from "../../assets/Image/Diabetebigpopup.svg";
import Group849 from "../../assets/Image/Group849.svg";
import Variabilitadel from "../../assets/Image/Variabilitadel-icon.svg";
import FATICAMENTALE_ICONA from "../../assets/Image/FATICAMENTALE_ICONA.svg";
import DEPRESSIONE_ICONA from "../../assets/Image/DEPRESSIONE_ICONA .svg";
import ANSIA_ICONA from "../../assets/Image/ANSIA_ICONA .svg";
import Sidebar from "../common/sidebar";
import Sidebarmob from "../common/sidebarMobile";
import { FaEllipsisH } from "react-icons/fa";
import { HiOutlineInformationCircle } from "react-icons/hi";
import ReactApexChart from "react-apexcharts";
import Modal from "../common/modal";
import Group1028 from "../../assets/Image/Group1028.svg";
import Group847 from "../../assets/Image/Group847.svg";
import Group846 from "../../assets/Image/Group846.svg";
import { Link } from "react-router-dom";
import PostRequest from "../apiConnection/postRequest";
import LazyLoader from "../common/lazyLoader";
import Group1029 from "../../assets/Image/Group102912.svg";
import Group1145 from "../../assets/Image/cardiac.svg";
import Group1030 from "../../assets/Image/Group1030.svg";
import Group1146 from "../../assets/Image/Group1146.svg";
import Group1466101 from "../../assets/Image/Group1466101.svg";
import Group1465101 from "../../assets/Image/Group1465101.svg";
import Group1464101 from "../../assets/Image/Group1464101.svg";
import Group1463101 from "../../assets/Image/Group1463101.svg";
import Group1462101 from "../../assets/Image/Group1462101.svg";
import Group1179 from "../../assets/Image/Group117979.svg";
import Group9771 from "../../assets/Image/Group9771.svg";
import Group9412 from "../../assets/Image/Group9412.svg";
import Group911110 from "../../assets/Image/Group911110.svg";
import Group9416 from "../../assets/Image/Group9416.svg";
import Group9417 from "../../assets/Image/Group9417.svg";
import Group9414 from "../../assets/Image/Group9414.svg";
import Group9415 from "../../assets/Image/Group9415.svg";
import Group9418 from "../../assets/Image/Group9418.svg";
import Group_8417 from "../../assets/Image/Group_8417.svg";
import Group_8418 from "../../assets/Image/Group_8418.svg";
import Group_8412 from "../../assets/Image/Group_8412.svg";
import Group_8419 from "../../assets/Image/Group_8419.svg";
import Group_8420 from "../../assets/Image/Group_8420.svg";
import Group_8421 from "../../assets/Image/Group_8421.svg";
import Group_8414 from "../../assets/Image/Group_8414.svg";
import { setLocalStoarge, getLocalStoarge, getQuestionairs, removeLocalStoarge } from "../common/allfunction";
import { useTranslation } from "react-i18next";
import BrainWellness from "./brainWellNess";
import InstructionModal from "../common/instructionModal";
import { useSelector } from "react-redux";
import SummaryTab from "./summaryTab";


const UserDashboard = (props) => {
  let num = Math.floor(Math.random() * 10);


  const [seriesStraightDatax, setSeriesStraightDatax] = useState([]);
  const [seriesStraightDatay, setSeriesStraightDatay] = useState([]);
  const [seriesbardata, setseriesbardata] = useState([]);
  const [barChartDatay, setBarChartDatay] = useState([]);
  const [seriesAreadatay, setSeriesAreadatay] = useState([]);
  const [activebg, setActivebg] = useState("active");
  const [activeContentType, setActiveContentType] = useState("dashboard");
	const [scanLimit, setScanLimit] = useState(getLocalStoarge("scanLimitOver"));


  const [errorfetch, setErrorfetch] = useState(
    props.match.params?.errorfetch && props.match.params?.errorfetch !== ""
      ? props.match.params?.errorfetch
      : ""
  );
  const [themeColor, setThemeColor] = useState(getLocalStoarge("theme"))
  const [graphColor, setGraphColor] = useState(JSON.parse(getLocalStoarge("theme")) == "dark" ? "#fff" : "#272848");
  const path = window.location.pathname;
  const [t, i18n] = useTranslation("common");

  const seriesStraight = [
    {
      name: "Variabilità battito cardiaco",
      data: seriesStraightDatay,
    },
  ];

  const seriesbar = [
    {
      name: "",
      data: seriesbardata,
    },
  ],
    optionsbar = {
      chart: {
        type: "bar",
        height: 130,
        width: 400,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 1,
          horizontal: true,
          barHeight: "5%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        stepSize:1,
        categories: [
          t("user.dashboard.overloaded"),
          t("user.dashboard.warden"),
          t("user.dashboard.productive"),
          t("user.dashboard.relaxed"),
          t("user.dashboard.exremely_relaxed")
        ],
      },
      yaxis: {
        max: 5,
      },

      colors: ["#272848"],
      grid: {
        show: false,
        borderColor: "#90A4AE",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
    };

  const seriesLine = [
    {
      name: "Carico cardiaco",
      data: barChartDatay,
    },
  ];

  const seriesArea = [
    {
      name: "capacita vascolare",
      data: seriesAreadatay,
    },
  ];
  const [optionsArea, setOptionsArea] = useState({
    chart: {
      height: 130,
      width: 160,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
      background: "none",
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: seriesStraightDatax,
      show: false,
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    colors: [graphColor],
    grid: {
      show: false,
      borderColor: "#90A4AE",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  });
  const [optionsLine, setOptionsLine] = useState({
    chart: {
      height: 130,
      width: 160,
      type: "bar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },
    stroke: {
      width: "2",
    },
    xaxis: {
      categories: seriesStraightDatax,
      show: false,
      labels: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        columnWidth: "15%",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    colors: [graphColor],
    grid: {
      show: false,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  });
  const [optionsStraight, setOptionsStraight] = useState({
    chart: {
      height: 130,
      width: 160,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
      background: "none",
      opacity: 1,
    },
    stroke: {
      curve: "straight",
      width: "3",
    },
    xaxis: {
      categories: seriesStraightDatax,
      show: false,
      labels: {
        show: false,
      },
    },

    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    colors: [graphColor],
    grid: {
      show: false,
      borderColor: "#90A4AE",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  });
  const themeTrigger = () => {
    let colorSelect = JSON.parse(getLocalStoarge("theme")) == "dark" ? "#fff" : "#272848";
    setOptionsArea({ ...optionsArea, colors: [colorSelect] });
    setOptionsLine({ ...optionsLine, colors: [colorSelect] });
    setOptionsStraight({ ...optionsStraight, colors: [colorSelect] });

  }
  const [isLoading, setIsLoading] = useState(false);
  const [modal1, setModal1] = useState();
  const [modal2, setModal2] = useState();
  const [modal3, setModal3] = useState();
  const [modal4, setModal4] = useState();
  const [modal5, setModal5] = useState();
  const [modal6, setModal6] = useState();
  const [modal7, setModal7] = useState();
  const [modal8, setModal8] = useState();
  const [modal9, setModal9] = useState();
  const [modal10, setModal10] = useState();
  const [modal11, setModal11] = useState();
  const [modal12, setModal12] = useState();
  const [modal13, setModal13] = useState();
  const [modal14, setModal14] = useState();
  const [modal15, setModal15] = useState();
  const [modal16, setModal16] = useState();
  const [modal17, setModal17] = useState();
  const [modal18, setModal18] = useState();
  const [modal19, setModal19] = useState();
  const [modal20, setModal20] = useState();

  const [detail, setDetail] = useState("");
  const [loading, setLoading] = useState(true);
  const [tableData, settableData] = useState("");
  const [gender, setGender] = useState("");
  const [popupVisiable, setPopupVisiable] = useState(false);
  const [token, setToken] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("R1");
    const initialValue = JSON.parse(saved);
    return initialValue.access_token || "";
  });

  const [personalizeDetail, setPersonalizeDetails] = useState({});
  const [personalText, setPersonalText] = useState("");
  const { data, status, isLoadingCheck } = useSelector((state) => {
    return state.repetition
  })


  const [activeTab, setActiveTab] = useState("dashboard"); // Initial active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    // You can add additional logic here if needed
  };
  const [language, setLanguage] = useState();

  const [modal21, setModal21] = useState();
  const [modal22, setModal22] = useState();
  const [todayMeasurement, setTodayMeasurement] = useState();
  const pageLoad = () => {
    setIsLoading(true);
    getQuestionairs();
  }
  const GotoCommonDashboard = () => {
    window.location.href = "/app/common-dashboard"
  }

  const getAllData = async () => {
    const payload = {};
    setIsLoading(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        token,
        "refresh_token"
      );
      if (refreshToken) {
        const getAllDataResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_LAST_HEALTH_DATA}`,
          payload,
          token,
          ""
        );

        if (getAllDataResult && getAllDataResult.status === 200) {
          if (localStorage.getItem('showInsPopup') == 'true') {
            setPopupVisiable(true);
            localStorage.setItem('showInsPopup', false);
          }
          await getDashboardData();
          const alldata = getAllDataResult.data.data;
          const getGender = getAllDataResult.data.data.personalizeDetails.gender;
          setLocalStoarge("health_data", JSON.stringify(alldata.latestData))
          setLocalStoarge("measurment_data", alldata.todaysEntry);
          setTodayMeasurement(alldata.todaysEntry);
          setGender(getGender);
          const personalizeDetails = getAllDataResult.data.data.personalizeDetails;
          let personalizeArray = [
            "physiological",
            "mental",
            "physicists",
            "general_risks",
            "metabolic_risks",
            "measurement_of_psychological_values",
          ];
          let check = true;
          personalizeArray.forEach((element) => {
            if (check) {
              let pageName = path.substring(path.lastIndexOf("/") + 1);
              if ((pageName == "anxietyAndDepression")) {
                setPersonalText("measurement_of_psychological_values");
                check = false;

              }
              else if (personalizeDetails[element] == 1) {
                setPersonalText(element);
                check = false;
              }
            }
          });
          setPersonalizeDetails(personalizeDetails);
          setDetail((alldata.todaysEntry = "0" ? alldata.latestData : "-"));
          var seriesbar = [];
          var msi = parseFloat(alldata.latestData.MSI).toFixed(0);
          for (var j = 1; j <= 5; j++) {
            seriesbar.push(j == msi ? msi : 0);
          }
          setseriesbardata(seriesbar.reverse());
          setLoading(false);

        } else {
          setIsLoading(false);
          setLoading(false);

        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };



  const getDashboardData = async () => {
    if (token && token !== null) {
      const payload = {};
      try {
        const getDashboard_Data = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DASHBOARD_API}`,
          payload,
          token,
          ""
        );
        if (getDashboard_Data && getDashboard_Data.status === 200) {
          const alldata = getDashboard_Data.data.data;
          settableData(alldata);
          var chartarrx = [];
          var chartarry = [];
          var barchart = [];
          var cvdRiskchart = [];
          for (var i = 0; i < alldata.length; i++) {
            var x = formatDate(alldata[i].time_stamp * 1000);
            var y = alldata[i].HRV_SDNN;
            var cardiac = alldata[i].BP_RPP;
            var cvd = alldata[i].BP_TAU;
            if (y !== undefined) chartarry.push(y);
            if (x !== undefined) chartarrx.push(x);
            if (cardiac !== undefined) barchart.push(cardiac);
            if (cvd !== undefined) cvdRiskchart.push(cvd);
          }
          setSeriesStraightDatax(chartarrx.reverse());
          setSeriesStraightDatay(chartarry.reverse());
          setBarChartDatay(barchart.reverse());
          setSeriesAreadatay(cvdRiskchart.reverse());

          setIsLoading(false);
        } else {
          setIsLoading(false);

        }

      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join(".");
  };
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    setLanguage(lang);
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };
  useEffect(() => {
    handleTranslationLang();
    getAllData();
    if (errorfetch === "error") {
      setTimeout(() => {
        setErrorfetch("");
        window.location.href = "/app/dashboard";
        localStorage.setItem('showInsPopup', true);
      }, 10000);
    }

  }, []);



  useEffect(() => {
    if (activeContentType == "brainwellness") {
      const callApi = setTimeout(() => {

      }, 2000)
      return () => clearTimeout(callApi)
    }
  }, [])

  const modal1Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group_8417} alt="icon" />{" "}
            </div>
            <strong>{t("user.dashboard.heading_1")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal1(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_1_1"),
                }}
              ></p>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_1_2"),
                }}
              ></span>
            </div>
            <div className="col-md-5 gr-per">
              <img src={Group1028} className="w-100 mb-2" alt="icon" />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_1_3"),
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal2Content = () => {
    return (
      <div>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <div className="in-linw-w">
                <img src={Group_8414} alt="icon" />{" "}
              </div>
              <strong>{t("user.dashboard.heading_2")}</strong>
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal2(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body scroll-pop">
            <div className="row d-flex align-items-center">
              <div className="col-md-7 r-linerigti">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_2_1"),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_2_2"),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_2_3"),
                  }}
                ></p>
              </div>
              <div className="col-md-5 gr-per d-flex- align-items-center">
                <img src={Group847} className="w-100 mb-2" alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal3Content = () => {
    return (
      <div>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <div className="in-linw-w">
                <img src={Group_8418} className="img-fluid" alt="icon" />
              </div>
              {t("user.dashboard.heading_3")}
            </h5>

            <button
              type="button"
              className="close r0-side"
              onClick={() => {
                setModal3(false);
              }}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body scroll-pop">
            <div className="row mb-3 d-flex align-items-center">
              <div className="col-md-7 r-linerigti">
                <span className="fpnt16 color-pe">
                  <strong>{t("user.dashboard.text_3_1")}</strong>
                </span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_3_2"),
                  }}
                ></p>
                <span className="fpnt16 color-pe">
                  <strong>{t("user.dashboard.text_3_3")}</strong>
                </span>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_3_4"),
                  }}
                ></p>
              </div>
              <div className="col-md-5 gr-per">
                <img src={Group846} className="w-100 mb-2" alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal4Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group_8412} className="img-fluid" alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_4")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal4(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row">
            <div className="mar-b">
              <div className="col-md-7 r-linerigti">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_4_1"),
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_4_2"),
                  }}
                ></p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1029} className="w-100" alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal5Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group_8419} alt="icon" />{" "}
            </div>
            <strong>{t("user.dashboard.heading_5")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal5(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="mar-b">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_5_1"),
                }}
              ></p>
              <p>{t("user.dashboard.text_5_2")}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_5_3"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1145} className="w-100" alt="icon" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal6Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group_8420} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_6")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal6(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row">
            <div className="col-md-12">
              <p className="font16"></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_6_1"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_6_2"),
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal7Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group846545011} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_7")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal7(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row">
            <div className="mar-b">
              <div className="col-md-7 r-linerigti">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_7_1"),
                  }}
                ></p>
                <span className="color-pe">{t("user.dashboard.text_7_2")}</span>
                <br />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_7_3"),
                  }}
                ></p>
              </div>
              <div className="col-md-5 d-flex  align-items-center">
                <img src={Group1030} className="img-fluid" alt="icon" />
              </div>
            </div>
            <div className="col-md-12">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_7_4"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_7_5"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_7_6"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_7_7"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_7_8"),
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal8Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Variabilitadel} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_8")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal8(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_8_1"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1179} className="w-100" alt="icon" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal9Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group9771} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_9")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal9(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row mar-b">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_9_1"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1146} className="w-100" alt="icon" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal10Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Indicedimassacorporea1} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_10")} </strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal10(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_10_1"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1466101} className="w-100" alt="icon" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal11Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading " id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group9414} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_11")} </strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal11(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row mar-b">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_11_1"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_11_2"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1462101} className="w-100" alt="icon" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal12Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group9416} alt="icon" />
            </div>
            <strong> {t("user.dashboard.heading_12")} </strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal12(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row">
            <div className="mar-b">
              <div className="col-md-7 r-linerigti">
                <p
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_12_1"),
                  }}
                ></p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1463101} className="w-100" alt="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal13Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Rischiodiictus} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_13")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal13(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row mar-b">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_13_1"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1464101} className="w-100" alt="icon" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal14Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group9415} className="img-fluid" alt="icon" />
            </div>
            {t("user.dashboard.heading_14")}
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal14(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row mar-b">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_14_1"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_14_2"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1465101} className="w-100" alt="icon" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_14_3"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_14_4"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_14_5"),
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const modal15Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group9412} alt="icon" />
            </div>
            {t("user.dashboard.heading_15")}
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal15(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="row mar-b">
            <div className="mar-b">
              <div className="col-md-7 r-linerigti">
                <p
                  // className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: t("user.dashboard.text_15_1"),
                  }}
                ></p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1465101} className="w-100" alt="icon" />
              </div>
            </div>
            <div className="col-md-12">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_15_2"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_15_3"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_15_4"),
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const modal16Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group911110} className="img-fluid" alt="icon" />
            </div>
            {t("user.dashboard.heading_16")}
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal16(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="mar-b">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_16_1"),
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_16_2"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1465101} className="w-100" alt="icon" />
            </div>
          </div>
          <div className="col-md-12">
            <p
              dangerouslySetInnerHTML={{
                __html: t("user.dashboard.text_16_3"),
              }}
            ></p>
            <p>
              <ul
                className="norm-g"
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_16_4"),
                }}
              ></ul>
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("user.dashboard.text_16_5"),
              }}
            ></p>
          </div>
          <div className="col-md-12">
            <p
              dangerouslySetInnerHTML={{
                __html: t("user.dashboard.text_16_6"),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("user.dashboard.text_16_7"),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("user.dashboard.text_16_8"),
              }}
            ></p>
          </div>
        </div>
      </div>
    );
  };

  const modal17Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Diabetebigpopup} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_17")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal17(false)}
          >
            <div className="in-linw-w">
              <img src={Group769} alt="icon" />
            </div>
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <div className="mar-b">
            <div className="col-md-7 r-linerigti">
              <p
                dangerouslySetInnerHTML={{
                  __html: t("user.dashboard.text_17_1"),
                }}
              ></p>
            </div>
            <div className="col-md-5 d-flex align-items-center gr-per">
              <img src={Group1465101} className="w-100" alt="icon" />
            </div>
          </div>
          <div className="col-md-12">
            <p
              dangerouslySetInnerHTML={{
                __html: t("user.dashboard.text_17_2"),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("user.dashboard.text_17_3"),
              }}
            ></p>
            <p
              dangerouslySetInnerHTML={{
                __html: t("user.dashboard.text_17_4"),
              }}
            ></p>
          </div>
        </div>
      </div>
    );
  };
  const modal18Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading left25" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group9417} alt="icon" />
            </div>
            <strong>{t("user.dashboard.heading_18")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal18(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <p
          dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_18_1") }}
        ></p>
        <p
          dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_18_2") }}
        ></p>
        <p
          dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_18_3") }}
        ></p>
      </div>
    );
  };
  const modal19Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group9418} alt="icon" />
            </div>
            <strong> {t("user.dashboard.heading_19")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal19(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <p
            dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_19_1") }}
          ></p>
          <p
            dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_19_2") }}
          ></p>
          <p
            dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_19_3") }}
          ></p>
        </div>
      </div>
    );
  };
  const modal20Content = () => {
    return (
      <div className="model-pop">
        <div className="modal-header">
          <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
              <img src={Group9418} alt="icon" />
            </div>
            <strong> {t("user.dashboard.heading_20")}</strong>
          </h5>
          <button
            type="button"
            className="close r0-side"
            onClick={() => setModal20(false)}
          >
            <img src={Group769} alt="icon" />
          </button>
        </div>
        <div className="modal-body scroll-pop">
          <p
            dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_20_1") }}
          ></p>
          <p
            dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_20_2") }}
          ></p>
        </div>
      </div>
    );
  };

  const modal21Content = () => {
    return (
      (todayMeasurement)
        ?
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading left25" id="exampleModalLabel">
              <div className="in-linw-w">
                <img src={Group9417} alt="icon" />
              </div>
              <strong>{t("user.dashboard.heading_anxiety_assessment")}</strong>
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal21(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <p
            dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_anxiety_assessment") }}
          ></p>
          <p className="btn-anxiety-align m-auto">
            <Link to={`/anxiety-and-depression/anxiety/${num}`} className={`buttons register-btn btn-anxiety ${(isDesktop || isTablet) ? " align-btn" : ''}`}>
              {t("user.dashboard.continue")}
            </Link>
            <button className={`buttons register-btn btn-anxiety ${(isDesktop || isTablet) ? " align-btn mr-2" : ''}`} onClick={() => setModal21(false)}>{t("user.dashboard.cancel")}</button>

          </p>
        </div>
        :
        <div className="model-pop">
          {/* <div className="modal-header">
            <div className="in-linw-w">
              <img src={Group9417} alt="icon" />
            </div>
            <div className="row">
             <div className="col-md-6 col-sm-12">
             <span className="scan-text">
              {t("user.dashboard.scan_for_measurement") }
              </span>
             </div>             
              <div className="col-md-6 col-sm-12">
              <p className="btn-anxiety-align m-auto">
              <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => setModal21(false)}>{t("user.dashboard.cancel")}</button>     
              <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => {pageLoad(); setModal21(false)}}>{t("user.dashboard.continue")}</button>
              </p>
               </div>  
             </div>    
        </div> */}

          <div class="modal-header">
            <div class="in-linw-w d-none d-sm-block">
              <img src={Group9417} alt="icon" />
            </div>
            <div class="row justify-content-end">
              <div class="col-3 d-sm-none">
                <img src={Group9417} alt="icon" />
              </div>
              <div class="col-md-6 col-sm-9 col-9">
                <span class="scan-text">{t("user.dashboard.scan_for_measurement")}</span>
              </div>
              <div class="col-md-6 col-sm-9 col-offset-9 col-9"><p class="btn-anxiety-align m-auto">
                <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => setModal21(false)}>{t("user.dashboard.cancel")}</button>
                <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => { pageLoad(); setModal21(false) }}>{t("user.dashboard.continue")}</button>
              </p>
              </div>
            </div>
          </div>
        </div>
    );
  };

  const modal22Content = () => {
    return (
      (todayMeasurement)
        ?
        <>
          <div className="model-pop">
            <div className="modal-header">
              <h5 className="modal-title pop_heading left25" id="exampleModalLabel">
                <div className="in-linw-w">
                  <img src={Group9418} alt="icon" />
                </div>
                <strong>{t("user.dashboard.heading_depression_assessment")}</strong>
              </h5>
              <button
                type="button"
                className="close r0-side"
                onClick={() => setModal22(false)}
              >
                <img src={Group769} alt="icon" />
              </button>
            </div>
            <p
              dangerouslySetInnerHTML={{ __html: t("user.dashboard.text_depression_assessment") }}
            ></p>
            <p className="btn-anxiety-align m-auto">
              <Link to={`/anxiety-and-depression/depression/${num}`} className={`buttons register-btn btn-anxiety  ${(isDesktop || isTablet) ? "align-btn" : ''}`} >
                {t("user.dashboard.continue")}
              </Link>
              <button className={`buttons register-btn btn-anxiety ${(isDesktop || isTablet) ? " align-btn mr-2 " : ''}`} onClick={() => setModal22(false)}>{t("user.dashboard.cancel")}</button>

            </p>
          </div>
        </>
        :
        <div className="model-pop">
          {/* <div className="modal-header">
            <div className="in-linw-w">
              <img src={Group9418} alt="icon" />
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
              <span className="scan-text">
              {t("user.dashboard.scan_for_measurement") }
              </span>
              </div>
              <div className="col-md-6 col-sm-12">
              <p className="btn-anxiety-align m-auto">
              <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => setModal22(false)}>{t("user.dashboard.cancel")}</button>     
              <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => {pageLoad(); setModal22(false)}}>{t("user.dashboard.continue")}</button>
              </p>
              </div>
            </div>
          </div> */}

          <div class="modal-header">
            <div class="in-linw-w d-none d-sm-block">
              <img src={Group9418} alt="icon" />
            </div>
            <div class="row justify-content-end">
              <div class="col-3 d-sm-none">
                <img src={Group9418} alt="icon" />
              </div>
              <div class="col-md-6 col-sm-9 col-9">
                <span class="scan-text">{t("user.dashboard.scan_for_measurement")}</span>
              </div>
              <div class="col-md-6 col-sm-9 col-offset-9 col-9"><p class="btn-anxiety-align m-auto">
                <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => setModal22(false)}>{t("user.dashboard.cancel")}</button>
                <button className="buttons register-btn btn-anxiety btn-scan-size" onClick={() => { pageLoad(); setModal22(false) }}>{t("user.dashboard.continue")}</button>
              </p>
              </div>
            </div>
          </div>

        </div>
    );
  };
  useEffect(() => {
    if (enablePageGuide != 1) {
      // console.log('tooltip container');
      setTimeout(() => {
        const tooltipContainer = document.getElementById("walktour-tooltip-container");
        if (tooltipContainer) {
          tooltipContainer.classList.add(isDesktop? "top":"bottom-right");
        }
      }, 1000)

    }
  }, [loading]);
  const mobDashPageGuide = [
    {
      selector: "#step-mob-1",
      title: t("user.sidebar.tourguide.title1"),
      description: t("user.sidebar.tourguide.description1")
    },
    {
      selector: "#step-mob-2",
      title: t("user.sidebar.tourguide.title2"),
      description: t("user.sidebar.tourguide.description2")
    },
    {
      selector: "#step-mob-3",
      title: t("user.sidebar.tourguide.title3"),
      description: t("user.sidebar.tourguide.description3")
    },
    {
      selector: "#step-mob-4",
      title: t("user.sidebar.tourguide.title4"),
      description: t("user.sidebar.tourguide.description4")
    },
    {
      selector: "#step-mob-5",
      title: t("user.sidebar.tourguide.title5"),
      description: t("user.sidebar.tourguide.description5")

    },
    {
      selector: "#step-mob-6",
      title: t("user.sidebar.tourguide.title6"),
      description: t("user.sidebar.tourguide.description6")
    },
    {
      selector: "#step-mob-7",
      title: t("user.sidebar.tourguide.title7"),
      description: t("user.sidebar.tourguide.description7")
    },
    {
      selector: "#step-mob-8",
      title: t("user.sidebar.tourguide.title8"),
      description: t("user.sidebar.tourguide.description8")

    },
    {
      selector: "#step-9",
      title: t("user.sidebar.tourguide.title9"),
      description: t("user.sidebar.tourguide.description9")

    },
    {
      selector: "#step-10",
      title: t("user.sidebar.tourguide.title10"),
      description: t("user.sidebar.tourguide.description10")

    },
    {
      selector: "#step-11",
      title: t("user.sidebar.tourguide.title11"),
      description: t("user.sidebar.tourguide.description11")
    },
    {
      selector: "#step-12",
      title: t("user.sidebar.tourguide.title12"),
      description: t("user.sidebar.tourguide.description12")

    },
    {
      selector: "#step-13",
      title: t("user.sidebar.tourguide.title13"),
      description: t("user.sidebar.tourguide.description13")

    },
    {
      selector: "#step-14",
      title: t("user.sidebar.tourguide.title14"),
      description: t("user.sidebar.tourguide.description14")

    },
    {
      selector: "#step-15",
      title: t("user.sidebar.tourguide.title15"),
      description: t("user.sidebar.tourguide.description15")
    }
  ];
  const pageGuide = [
    {
      selector: "#step-1",
      title: t("user.sidebar.tourguide.title1"),
      description: t("user.sidebar.tourguide.description1")
    },
    {
      selector: "#step-2",
      title: t("user.sidebar.tourguide.title2"),
      description: t("user.sidebar.tourguide.description2")
    },
    {
      selector: "#step-3",
      title: t("user.sidebar.tourguide.title3"),
      description: t("user.sidebar.tourguide.description3")
    },
    {
      selector: "#step-4",
      title: t("user.sidebar.tourguide.title4"),
      description: t("user.sidebar.tourguide.description4")
    },
    {
      selector: "#step-5",
      title: t("user.sidebar.tourguide.title5"),
      description: t("user.sidebar.tourguide.description5")

    },
    {
      selector: "#step-6",
      title: t("user.sidebar.tourguide.title6"),
      description: t("user.sidebar.tourguide.description6")
    },
    {
      selector: "#step-7",
      title: t("user.sidebar.tourguide.title7"),
      description: t("user.sidebar.tourguide.description7")
    },
    {
      selector: "#step-8",
      title: t("user.sidebar.tourguide.title8"),
      description: t("user.sidebar.tourguide.description8")

    },
    {
      selector: "#step-9",
      title: t("user.sidebar.tourguide.title9"),
      description: t("user.sidebar.tourguide.description9")

    },
    {
      selector: "#step-10",
      title: t("user.sidebar.tourguide.title10"),
      description: t("user.sidebar.tourguide.description10")

    },
    {
      selector: "#step-11",
      title: t("user.sidebar.tourguide.title11"),
      description: t("user.sidebar.tourguide.description11")
    },
    {
      selector: "#step-12",
      title: t("user.sidebar.tourguide.title12"),
      description: t("user.sidebar.tourguide.description12")

    },
    {
      selector: "#step-13",
      title: t("user.sidebar.tourguide.title13"),
      description: t("user.sidebar.tourguide.description13")

    },
    {
      selector: "#step-14",
      title: t("user.sidebar.tourguide.title14"),
      description: t("user.sidebar.tourguide.description14")

    },
    {
      selector: "#step-15",
      title: t("user.sidebar.tourguide.title15"),
      description: t("user.sidebar.tourguide.description15")
    }
  ];
  const mobilePageGuide = [
    { selector: "#step-summary-1", title: t("user.sidebar.summarytour.title1"), description: t("user.sidebar.summarytour.description1") },
    { selector: "#step-summary-2", title: t("user.sidebar.summarytour.title2"), description: t("user.sidebar.summarytour.description2") },
    { selector: "#step-summary-m3", title: t("user.sidebar.summarytour.title3"), description: t("user.sidebar.summarytour.description3") },
    { selector: "#step-summary-m4", title: t("user.sidebar.summarytour.title4"), description: t("user.sidebar.summarytour.description4") }
  ];
  const pageGuideone = [
    { selector: "#step-summary-1", title: t("user.sidebar.summarytour.title1"), description: t("user.sidebar.summarytour.description1") },
    { selector: "#step-summary-2", title: t("user.sidebar.summarytour.title2"), description: t("user.sidebar.summarytour.description2") },
    { selector: "#step-summary-3", title: t("user.sidebar.summarytour.title3"), description: t("user.sidebar.summarytour.description3") },
    { selector: "#step-summary-4", title: t("user.sidebar.summarytour.title4"), description: t("user.sidebar.summarytour.description4") }
  ];

  const enablePageGuide = localStorage.getItem('pageGuide');
  const enablePageGuideone = localStorage.getItem('pageGuideone');
  const noOfScan = localStorage.getItem('noOfScan');
  const guideRecord = (isMobile && !isTablet) ? mobDashPageGuide : pageGuide;
  const [enableRef, setEnableRef] = useState(1);
  const myCustomNext = (logic: WalktourLogic) => {
    const tooltipContainer = document.getElementById("walktour-tooltip-container");
    if (tooltipContainer) {
      if (logic.stepIndex == 0) {
        tooltipContainer.removeAttribute("class");
        tooltipContainer.classList.add("top");
      }
    }

    if (logic.stepIndex == 3 && isMobile && !isTablet) {
      window.location.href = 'profilemenu';
      return false;
    }
    // if (isDesktop) {
  
    if (logic.stepIndex == 2) {
      tooltipContainer.classList.remove("top");
      tooltipContainer.classList.add("center-arrow");
    }
    if (logic.stepIndex == 3) {
      tooltipContainer.classList.remove("center-arrow");
      tooltipContainer.classList.add("bottom-arrow");
    }
    if (logic.stepIndex == 6) {
      tooltipContainer.classList.remove("bottom-arrow");
      tooltipContainer.classList.add("step8-arrow");
    }
    if (logic.stepIndex == 7) {
      tooltipContainer.classList.remove("step8-arrow");
      tooltipContainer.classList.add("top-arrow");
    }
    if (logic.stepIndex == 8) {
      tooltipContainer.removeAttribute("class");
      tooltipContainer.classList.add("down-arrow");
    }
    if (logic.stepIndex == 9) {
      tooltipContainer.removeAttribute("class");
      tooltipContainer.classList.add(isDesktop ? "top" : "top-arrow");
    }
    if (logic.stepIndex == 10) {
      tooltipContainer.removeAttribute("class");
      tooltipContainer.classList.add(isDesktop ? "top" : "bottom-right");
    }
    if (logic.stepIndex == 11) {
      tooltipContainer.removeAttribute("class");
      tooltipContainer.classList.add(isDesktop ? "top-arrow" :"bottom-right");
    }
    if (logic.stepIndex == 12) {
      tooltipContainer.removeAttribute("class");
      tooltipContainer.classList.add(isDesktop ? "step8-arrow" : "bottom-right");
    }
    // }
    if (logic.stepIndex == 8) {
      if(isMobile){
        setTimeout(()=>{
          $(document).scrollTop(300);
        },200)
      }
   
      
    }
    // alert(logic.stepIndex);
    if(logic.stepIndex == 9){
      if(isMobile){ 
        setTimeout(()=>{
          $(document).scrollTop(100);
        },200)
      }
    }
    if (logic.stepIndex == 10) {
      $(".nav-stacked").scrollLeft(100);
      
    }
    if (logic.stepIndex == 11) {
      $(".nav-stacked").scrollLeft(200);
    }
    if (logic.stepIndex == 12) {
      $(".nav-stacked").scrollLeft(300);
    }
    if (logic.stepIndex == 13) {
      $(".nav-stacked").scrollLeft(innerHeight);
    }
    if (document.referrer.split("/").pop() == 'profilemenu' && enableRef === 1) {
      logic.goToStep(8);
      setEnableRef(0);
    }
    else {
      logic.next();
    }

    if (isMobile && !isTablet && logic.stepIndex == 11) {
      localStorage.setItem('pageGuide', 1)

    }
    else if (!isMobile && logic.stepIndex == 13) {
      localStorage.setItem('pageGuide', 1)
    }
  }
  const closeTour = (logic: WalktourLogic) => {
    localStorage.setItem('pageGuide', 1)
    logic.close();
  }
  const footer = () => {
    <>
      <h3>Footer</h3>
    </>
  }
  return (
    <>

      {
        (loading == false && enablePageGuide != 1 && activeTab == "dashboard") ? <Walktour steps={guideRecord} nextLabel={t("user.sidebar.nextlabel")} closeLabel={t("user.sidebar.closelabel")} customNextFunc={myCustomNext} disableClose={0} /> :
          (loading == false && enablePageGuide != 1 && activeTab == "summary") ?
            <Walktour steps={isMobile ? mobilePageGuide : pageGuideone} nextLabel={t("user.sidebar.nextlabel")} customCloseFunc={closeTour} customNextFunc={myCustomNext} closeLabel={t("user.sidebar.closelabel")} disableClose={0} allSteps={pageGuide} /> : null
      }

      <Modal title="" size="lg" onClose={() => setModal1(false)} show={modal1}>
        {modal1Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal2(false)} show={modal2}>
        {modal2Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal3(false)} show={modal3}>
        {modal3Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal4(false)} show={modal4}>
        {modal4Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal5(false)} show={modal5}>
        {modal5Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal6(false)} show={modal6}>
        {modal6Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal7(false)} show={modal7}>
        {modal7Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal8(false)} show={modal8}>
        {modal8Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal9(false)} show={modal9}>
        {modal9Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal10(false)}
        show={modal10}
      >
        {modal10Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal11(false)}
        show={modal11}
      >
        {modal11Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal12(false)}
        show={modal12}
      >
        {modal12Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal13(false)}
        show={modal13}
      >
        {modal13Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal14(false)}
        show={modal14}
      >
        {modal14Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal15(false)}
        show={modal15}
      >
        {modal15Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal16(false)}
        show={modal16}
      >
        {modal16Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal17(false)}
        show={modal17}
      >
        {modal17Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal18(false)}
        show={modal18}
      >
        {modal18Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal19(false)}
        show={modal19}
      >
        {modal19Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal20(false)}
        show={modal20}
      >
        {modal20Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal21(false)}
        show={modal21}
      >
        {modal21Content()}
      </Modal>
      <Modal
        title=""
        size="lg"
        onClose={() => setModal22(false)}
        show={modal22}
      >
        {modal22Content()}
      </Modal>
      {(popupVisiable) ?
        <InstructionModal popUpEnable={`true`} />

        : null
      }
      <section className="full_bg dashboard-mobile dashboard-section">
        {isLoading ? <LazyLoader /> : null}
        <div className="container-fluid">
          <div className="row">
            <Sidebar themeCall={themeTrigger} />
            <div className=" col-md-10 p0 pr-0 ipd-righ">
              <div className="right-dashboard topspace leftr-dari three-dots">
                {/** Dashboard Tab & Header Start */}
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-5 col-xl-6 pr-sm-0 pr-md-0">
                    <h2 className="heading-dash">
                     {t("user.dashboard.tab_dashboard_first")}
                    </h2>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="align-j pt-2">
                        {personalizeDetail ? (
                          personalizeDetail?.vitali ?? 0 === 1 ? (
                            <>
                            </>
                          ) : (
                            <h4></h4>
                          )
                        ) : (
                          <h4></h4>
                        )}

                      </div>

                    </div>
                    {errorfetch === "error" ? (
                      <>
                        <div className={`alert alert-danger`}>
                          {t(`user.dashboard.camera_error`)}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <div className="dashboard-tabs">
                      <ul className="nav nav-tabs" id="myTabs" role="tablist">
                        <li className="nav-item"><a className={`nav-link ${activeTab === "dashboard" ? "active show" : ""}`} href="javascript:void(0)" onClick={() => handleTabClick("dashboard")}> {t("user.dashboard.tab_dashboard_first")}</a></li>
                        <li className="nav-item"><a className={`nav-link ${activeTab === "summary" ? "active show" : ""}`} href="javascript:void(0)" onClick={() => handleTabClick("summary")} >{t("user.dashboard.tab_dashboard_second")}</a> </li>
                      </ul> 
                    </div> */}
                  </div>

                  <div className="col-sm-3 col-md-3 col-lg-4 col-xl-4 pl-sm-0">
                    <div className="cmd-back-right col-12" >
                      <p className="cmd-back-right-text pr-2 col-9 back-btn-text-color" dangerouslySetInnerHTML={{ __html: t("user.dashboard.back_to_cmd") }}>
                      </p>
                      <button className="btn-apr col-3" onClick={GotoCommonDashboard}>{t("user.dashboard.go")}</button>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2 pl-sm-0">
                    <div className="righ-side-tab top-right-score bunono-w50" id="step-8">
                      <div className="logo-shape">
                        <img src={shape} alt="icon" />
                        <span>
                          {detail.HEALTH_SCORE <= 20 ? (
                            <p className="">
                              {t("user.dashboard.buono")}
                            </p>
                          ) : detail.HEALTH_SCORE >= 21 &&
                            detail.HEALTH_SCORE <= 40 ? (
                            <p className="">
                              {t("user.dashboard.buono1")}
                            </p>
                          ) : detail.HEALTH_SCORE >= 41 &&
                            detail.HEALTH_SCORE <= 60 ? (
                            <p className="">
                              {t("user.dashboard.buono2")}
                            </p>
                          ) : detail.HEALTH_SCORE >= 61 &&
                            detail.HEALTH_SCORE <= 80 ? (
                            <p className="">
                              {t("user.dashboard.buono3")}
                            </p>
                          ) : detail.HEALTH_SCORE >= 81 &&
                            detail.HEALTH_SCORE <= 100 ? (
                            <p className="">
                              {t("user.dashboard.buono4")}
                            </p>
                          ) : (
                            <span className=""></span>
                          )}
                        </span>
                      </div>
                      <div className="w80 d-text-custom" >
                        {detail.HEALTH_SCORE
                          ? parseFloat(detail.HEALTH_SCORE)
                          : "0"}
                      </div>

                    </div>
                  </div>
                </div>

                {/** Dashboard Tab & Header End */}

                <div className="tab-content">
                  <div className={`tab-pane ${activeTab === "dashboard" ? "active show" : ""}`} id="dashboard">

                    <div id="step-9">
                      <div className="align-j"><h4>{t("user.dashboard.heading_vitali")}</h4></div>

                      {personalizeDetail ? (
                        personalizeDetail?.vitali == 1 ? (
                          <>
                            <div className="row inline-scrols" >
                              <div className="col-md-4 d-flex dashboard-widh50">
                                <div className="in-f">
                                  <div className="in-alini">
                                    <div className="ico-bg">
                                      {/* <img src={heart} alt="icon" /> */}
                                      <div className="heart-bg"></div>
                                    </div>
                                    <div className="right-s">
                                      <h4>{t("user.dashboard.heading_1")}</h4>
                                      <p className="flex-mobile-view">
                                        {detail.HR_BPM
                                          ? parseFloat(detail.HR_BPM)
                                          : "-"}
                                        <sub> bpm</sub>
                                        <span>
                                          {detail.HR_BPM < 60 ? (
                                            <span className="light-yellow line"></span>
                                          ) : detail.HR_BPM >= 60 &&
                                            detail.HR_BPM <= 100 ? (
                                            <span className="green line"></span>
                                          ) : detail.HR_BPM >= 100 ? (
                                            <span className="light-yellow line"></span>
                                          ) : (
                                            <span className=""></span>
                                          )}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-part">
                                    <i className="colorNew visi-none">
                                      {" "}
                                      <FaEllipsisH
                                        className="pointer"
                                        onClick={() => setModal1(true)}
                                      />
                                    </i>
                                    <i className="colorNew hd-n">
                                      {" "}
                                      <HiOutlineInformationCircle
                                        className="pointer"
                                        onClick={() => setModal1(true)}
                                      />
                                    </i>
                                    <div className="bott-s battiti-text">
                                      {detail.IHB_COUNT
                                        ? parseFloat(detail.IHB_COUNT)
                                        : "0"}{" "}
                                      {t("user.dashboard.heading_irregular")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 d-flex dashboard-widh50">
                                <div className="in-f">
                                  <div className="in-alini">
                                    <div className="ico-bg">
                                      {/* <img src={iconLungs} alt="icon" /> */}
                                      <div className="heart-bg mitter"></div>
                                    </div>
                                    <div className="right-s">
                                      <h4>{t("user.dashboard.heading_2")}</h4>
                                      <p className="flex-mobile-view">
                                        {detail.BR_BPM
                                          ? parseFloat(detail.BR_BPM)
                                          : "-"}
                                        <sub> brpm</sub>
                                        <span>
                                          {detail.BR_BPM < 12 ? (
                                            <span className="light-yellow line"></span>
                                          ) : detail.BR_BPM >= 12 &&
                                            detail.BR_BPM <= 25 ? (
                                            <span className="green line"></span>
                                          ) : detail.BR_BPM > 25 ? (
                                            <span className="yellow line"></span>
                                          ) : (
                                            <span className="light-yellow line"></span>
                                          )}
                                        </span>
                                      </p>
                                      <span className="width_line"></span>
                                    </div>
                                  </div>
                                  <div className="right-part">
                                    <i className="colorNew visi-none">
                                      {" "}
                                      <FaEllipsisH
                                        className="pointer"
                                        onClick={() => setModal2(true)}
                                      />
                                    </i>
                                    <i className="colorNew hd-n">
                                      {" "}
                                      <HiOutlineInformationCircle
                                        className="pointer"
                                        onClick={() => setModal2(true)}
                                      />
                                    </i>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 d-flex dashboard-widh50">
                                <div className="in-f">
                                  <div className="in-alini">
                                    <div className="ico-bg">
                                      <div className="heart-bg iconLungs"></div>
                                      {/* <img src={mitter} alt="icon" /> */}
                                    </div>
                                    <div className="d-flex align-items-end row">
                                      <div className="right-s dark-g col-10">
                                        <h4>{t("user.dashboard.start_sistolica")}</h4>

                                      </div>
                                      <div className="right-s col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 padding-right-mobile-0">
                                        <p className="f_size mobile-font-value">
                                          <span className="n-mal">
                                            {t("user.dashboard.heading_4_1")}
                                          </span>
                                          {detail.BP_SYSTOLIC
                                            ? parseFloat(detail.BP_SYSTOLIC)
                                            : "-"}
                                          <sub> mmHg</sub>
                                          <span>
                                            {detail.BP_SYSTOLIC > 0 &&
                                              detail.BP_SYSTOLIC <= 90 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BP_SYSTOLIC > 90 &&
                                              detail.BP_SYSTOLIC <= 120 ? (
                                              <span className="green line"></span>
                                            ) : detail.BP_SYSTOLIC > 120 &&
                                              detail.BP_SYSTOLIC <= 130 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.BP_SYSTOLIC > 130 &&
                                              detail.BP_SYSTOLIC <= 140 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BP_SYSTOLIC > 140 &&
                                              detail.BP_SYSTOLIC < 2500 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="right-s col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6  padding-right-mobile-0">
                                        <p className="f_size  mobile-font-value">
                                          <span className="n-mal">
                                            {t("user.dashboard.heading_4_2")}
                                          </span>
                                          {detail.BP_DIASTOLIC
                                            ? parseFloat(detail.BP_DIASTOLIC)
                                            : "-"}
                                          <sub> mmHg</sub>
                                          <span>
                                            {detail.BP_DIASTOLIC > 0 &&
                                              detail.BP_DIASTOLIC <= 60 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BP_DIASTOLIC > 60 &&
                                              detail.BP_DIASTOLIC <= 70 ? (
                                              <span className="green line"></span>
                                            ) : detail.BP_DIASTOLIC > 70 &&
                                              detail.BP_DIASTOLIC <= 80 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.BP_DIASTOLIC > 80 &&
                                              detail.BP_DIASTOLIC <= 90 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BP_DIASTOLIC > 90 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="right-part">
                                    <i className="colorNew visi-none">
                                      {" "}
                                      <FaEllipsisH
                                        className="pointer"
                                        onClick={() => setModal3(true)}
                                      />
                                    </i>
                                    <i className="colorNew hd-n">
                                      {" "}
                                      <HiOutlineInformationCircle
                                        className="pointer"
                                        onClick={() => setModal3(true)}
                                      />
                                    </i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null
                      ) : null}
                    </div>
                    <div className="graph-tabs" >
                      <ul
                        className="nav nav-tabs nav-stacked"
                        id="myTab"
                        role="tablist"
                      >
                        {personalizeDetail ? (
                          <>
                            {personalizeDetail.physiological == 1 ? (
                              <li className="nav-item">
                                <a
                                  className={
                                    "nav-link " +
                                    (personalText == "physiological"
                                      ? "active show"
                                      : "")
                                  }
                                  id="first-tab"
                                  data-toggle="tab"
                                  href="#first"
                                  role="tab"
                                  aria-controls="first"
                                  aria-selected="true"
                                >
                                  <span className="das-icontabs">
                                    {/* <img src={FISIOLOGICIICONA} className="deskt-n" alt="icon" /> */}
                                    <div className="dashboard-tabs-icon deskt-n"></div>
                                    <span className="visi-none tab-text-small">
                                      {t("user.dashboard.heading_nav1")}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            ) : null}
                            {personalizeDetail.mental == 1 ? (
                              <li className="nav-item" id="step-11">
                                <a
                                  className={
                                    "nav-link " +
                                    (personalText == "mental" ? "active show" : "")
                                  }
                                  id="second-tab"
                                  data-toggle="tab"
                                  href="#second"
                                  role="tab"
                                  aria-controls="second"
                                  aria-selected="false"
                                  onClick={() =>
                                    window.dispatchEvent(new Event("resize"))
                                  }
                                >
                                  <span className="das-icontabs">
                                    {/* <img src={Group784} className="deskt-n" alt="icon" /> */}
                                    <div className="dashboard-tabs-icon deskt-n stress-icons"></div>
                                    <span className="visi-none tab-text-small">
                                      {t("user.dashboard.heading_nav2")}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            ) : null}
                            {personalizeDetail.physicists == 1 ? (
                              <li className="nav-item" id="step-12">
                                <a
                                  className={
                                    "nav-link " +
                                    (personalText == "physicists"
                                      ? "active show"
                                      : "")
                                  }
                                  id="third-tab"
                                  data-toggle="tab"
                                  href="#third"
                                  role="tab"
                                  aria-controls="third"
                                  aria-selected="false"
                                >
                                  <span className="das-icontabs">
                                    <div className="dashboard-tabs-icon deskt-n Fisici-icon"></div>
                                    <span className="visi-none tab-text-small">
                                      {t("user.dashboard.heading_nav3")}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            ) : null}
                            {personalizeDetail.general_risks == 1 ? (
                              <li className="nav-item" id="step-13">
                                <a
                                  className={
                                    "nav-link " +
                                    (personalText == "general_risks"
                                      ? "active show"
                                      : "")
                                  }
                                  id="fourth-tab"
                                  data-toggle="tab"
                                  href="#fourth"
                                  role="tab"
                                  aria-controls="fourth"
                                  aria-selected="false"
                                >
                                  <span className="das-icontabs">
                                    <div className="dashboard-tabs-icon deskt-n Rischi-Generali"></div>
                                    <span className="visi-none tab-text-small">
                                      {t("user.dashboard.heading_nav4")}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            ) : null}
                            {personalizeDetail.metabolic_risks == 1 ? (
                              <li className="nav-item" id="step-14">
                                <a
                                  className={
                                    "nav-link " +
                                    (personalText == "metabolic_risks"
                                      ? "active show"
                                      : "")
                                  }
                                  id="fifth-tab"
                                  data-toggle="tab"
                                  href="#fifth"
                                  role="tab"
                                  aria-controls="fifth"
                                  aria-selected="false"
                                >
                                  <span className="das-icontabs">
                                    <div className="dashboard-tabs-icon deskt-n  Rischi-Metabolici"></div>
                                    <span className="visi-none tab-text-small">
                                      {t("user.dashboard.heading_nav5")}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            ) : null}
                            {personalizeDetail.measurement_of_psychological_values ==
                              1 ? (
                              <li className="nav-item" id="step-15">
                                <a
                                  className={
                                    "nav-link " +
                                    (personalText == "measurement_of_psychological_values"
                                      ? "active show"
                                      : "")
                                  }
                                  id="sixth-tab"
                                  data-toggle="tab"
                                  href="#sixth"
                                  role="tab"
                                  aria-controls="sixth"
                                  aria-selected="false"
                                >
                                  <span className="das-icontabs">
                                    <div className="dashboard-tabs-icon deskt-n  Psicologici"></div>
                                    <span className="visi-none tab-text-small">
                                      {t("user.dashboard.heading_nav6")}
                                    </span>
                                  </span>
                                </a>
                              </li>
                            ) : null}
                          </>
                        ) : null}
                      </ul>

                      <div className="card-body">
                        <div className="tab-content">
                          {personalizeDetail ? (
                            <>
                              {personalizeDetail.physiological == 1 ? (
                                <div
                                  className={
                                    "tab-pane fade " +
                                    (personalText == "physiological"
                                      ? "active show"
                                      : "")
                                  }
                                  id="first"
                                  role="tabpanel"
                                  aria-labelledby="first-tab"
                                >
                                  <div className="row" id="step-10">
                                    <div className="col-md-4 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={outlineHeart} alt="icon" />
                                              <span>
                                                {" "}
                                                {t("user.dashboard.heading_4")}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal4(true)}
                                              />
                                            </i>
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal4(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable">
                                          <ReactApexChart
                                            options={optionsStraight}
                                            series={seriesStraight}
                                            type="line"
                                            height={130}
                                            width={160}
                                          />
                                          <p>
                                            {detail.HRV_SDNN
                                              ? parseFloat(detail.HRV_SDNN)
                                              : "-"}
                                            <sub>ms</sub>
                                            {detail.HRV_SDNN <= 0 &&
                                              detail.HRV_SDNN <= 10.8 ? (
                                              <span className="red line"></span>
                                            ) : detail.HRV_SDNN >= 10.8 &&
                                              detail.HRV_SDNN <= 16.4 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.HRV_SDNN >= 16.4 &&
                                              detail.HRV_SDNN <= 35.5 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.HRV_SDNN >= 35.5 &&
                                              detail.HRV_SDNN <= 51.5 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.HRV_SDNN >= 51.5 ? (
                                              <span className="green line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={carico} alt="icon" />
                                              <span>
                                                {t("user.dashboard.heading_5")}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal5(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal5(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable">
                                          <ReactApexChart
                                            options={optionsLine}
                                            series={seriesLine}
                                            type="bar"
                                            height={130}
                                            width={170}
                                          />
                                          <p>
                                            {detail.BP_RPP
                                              ? parseFloat(detail.BP_RPP)
                                              : "-"}
                                            <sub>dB</sub>
                                            {detail.BP_RPP >= 0 &&
                                              detail.BP_RPP < 3.8 ? (
                                              <span className="green line"></span>
                                            ) : detail.BP_RPP >= 3.8 &&
                                              detail.BP_RPP < 3.9 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.BP_RPP >= 3.9 &&
                                              detail.BP_RPP < 4.08 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BP_RPP >= 4.08 &&
                                              detail.BP_RPP < 4.18 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.BP_RPP >= 4.18 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-4 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={capacita} alt="icon" />
                                              <span>
                                                {t("user.dashboard.heading_6")}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal6(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal6(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable">
                                          <ReactApexChart
                                            options={optionsArea}
                                            series={seriesArea}
                                            type="area"
                                            height={130}
                                            width={170}
                                          />
                                          <p>
                                            {detail.BP_TAU
                                              ? parseFloat(detail.BP_TAU)
                                              : "-"}
                                            <sub>sec</sub>
                                            {detail.BP_TAU >= 0 &&
                                              detail.BP_TAU <= 0.79 ? (
                                              <span className="red line"></span>
                                            ) : detail.BP_TAU > 0.79 &&
                                              detail.BP_TAU <= 1.12 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.BP_TAU > 1.12 &&
                                              detail.BP_TAU <= 1.78 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BP_TAU > 1.78 &&
                                              detail.BP_TAU <= 2.11 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.BP_TAU > 2.11 ? (
                                              <span className="light-green line"></span>
                                            ) : (
                                              <span className="yellow line"></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-10 tabi">
                                      <div className="bg-tables">
                                        <h4 className="title measure">
                                          {t("user.dashboard.Measurements_history")}
                                        </h4>
                                        <table className="table table-tab">
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td className="font-s">
                                                <img
                                                  src={outlineHeart}
                                                  alt="icon"
                                                />
                                                <br />
                                                {t("user.dashboard.heading_4")}
                                              </td>
                                              <td className="font-s">
                                                <img src={carico} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_5")}
                                              </td>
                                              <td className="font-s">
                                                <img src={capacita} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_6")}
                                              </td>
                                            </tr>

                                            {tableData &&
                                              Object.keys(tableData).length > 0
                                              ? tableData.map((data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <th>
                                                      {formatDate(
                                                        data.time_stamp * 1000
                                                      )}
                                                    </th>
                                                    <td>
                                                      {data.HRV_SDNN >= 0 &&
                                                        data.HRV_SDNN < 10.8 ? (
                                                        <span className="circle red"></span>
                                                      ) : data.HRV_SDNN >= 10.8 &&
                                                        data.HRV_SDNN < 16.4 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.HRV_SDNN >= 16.4 &&
                                                        data.HRV_SDNN < 35.5 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.HRV_SDNN >= 35.5 &&
                                                        data.HRV_SDNN < 51.5 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.HRV_SDNN >=
                                                        51.5 ? (
                                                        <span className="circle green"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data.BP_RPP >= 0 &&
                                                        data.BP_RPP < 3.8 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.BP_RPP >= 3.8 &&
                                                        data.BP_RPP < 3.9 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.BP_RPP >= 3.9 &&
                                                        data.BP_RPP < 4.08 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.BP_RPP >= 4.08 &&
                                                        data.BP_RPP < 4.18 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.BP_RPP >= 4.18 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data.BP_TAU >= 0.0 &&
                                                        data.BP_TAU < 0.79 ? (
                                                        <span className="circle red"></span>
                                                      ) : data.BP_TAU >= 0.79 &&
                                                        data.BP_TAU < 1.12 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.BP_TAU >= 1.12 &&
                                                        data.BP_TAU < 1.78 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.BP_TAU >= 1.78 &&
                                                        data.BP_TAU < 2.11 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.BP_TAU >= 2.11 ? (
                                                        <span className="circle green"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                              : null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-end tabicol2">
                                      {/* <Link
                                          to="/summary"
                                          className="buttons h-auto"
                                        >
                                          {t("user.dashboard.see_all")}
                                        </Link> */}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {personalizeDetail.mental == 1 ? (
                                <div
                                  className={
                                    "tab-pane fade " +
                                    (personalText == "mental" ? "active show" : "")
                                  }
                                  id="second"
                                  role="tabpanel"
                                  aria-labelledby="second-tab"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={Stressmentale} alt="icon" />
                                              <span>
                                                {t("user.dashboard.heading_nav7")}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal7(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal7(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s yello-line variable dashboard-graph-area">
                                          {loading ? (
                                            <></>
                                          ) : (
                                            <>
                                              <ReactApexChart
                                                className="visi-none ipad-textn"
                                                options={optionsbar}
                                                series={seriesbar}
                                                type="bar"
                                                height={window.innerHeight / 4}
                                                width={window.innerWidth / 1.53}
                                              />
                                              <ReactApexChart
                                                className="deskt-n gr-left"
                                                options={optionsbar}
                                                series={seriesbar}
                                                type="bar"
                                                height={window.innerHeight / 4}
                                                width={window.innerWidth / 1.4}
                                              />

                                              <ReactApexChart
                                                className="deskt-n visi-none ipad-show"
                                                options={optionsbar}
                                                series={seriesbar}
                                                type="bar"
                                                height={window.innerHeight / 3}
                                                width={window.innerWidth / 1.3}
                                              />
                                            </>
                                          )}
                                          <p>
                                            {detail.MSI
                                              ? parseFloat(detail.MSI)
                                              : "-"}
                                            <sub></sub>
                                            {detail.MSI >= 1 && detail.MSI < 2 ? (
                                              <span className="green line"></span>
                                            ) : detail.MSI >= 2 &&
                                              detail.MSI < 3 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.MSI >= 3 &&
                                              detail.MSI < 4 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.MSI >= 4 &&
                                              detail.MSI <= 5 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.MSI > 5 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-5 tabi">
                                      <div className="bg-tables">
                                        <h4 className="title measure">
                                          {t("user.dashboard.Measurements_history")}
                                        </h4>
                                        <table className="table table-tab">
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td className="font-s">
                                                <img
                                                  src={Stressmentale}
                                                  alt="icon"
                                                />
                                                <br />
                                                {t("user.dashboard.heading_nav7")}
                                              </td>
                                            </tr>
                                            {tableData &&
                                              Object.keys(tableData).length > 0
                                              ? tableData.map((data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <th>
                                                      {formatDate(
                                                        data.time_stamp * 1000
                                                      )}
                                                    </th>
                                                    <td>
                                                      {data.MSI >= 0 &&
                                                        data.MSI < 2 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.MSI >= 2 &&
                                                        data.MSI < 3 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.MSI >= 3 &&
                                                        data.MSI < 4 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.MSI >= 4 &&
                                                        data.MSI <= 5 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.MSI > 5 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                              : null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-md-7 d-flex align-items-end tabicol2">
                                      {/* <Link
                                          to="/summary"
                                          className="buttons  h-auto"
                                        >
                                          {t("user.dashboard.see_all")}
                                        </Link> */}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {personalizeDetail.physicists == 1 ? (
                                <div
                                  className={
                                    "tab-pane fade " +
                                    (personalText == "physicists"
                                      ? "active show"
                                      : "")
                                  }
                                  id="third"
                                  role="tabpanel"
                                  aria-labelledby="third-tab"
                                >
                                  <div className="row">
                                    <div className="col-md-4 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={Group911} alt="icon" />
                                              <span>
                                                {" "}
                                                {t("user.dashboard.heading_8")}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal8(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal8(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.WAIST_TO_HEIGHT
                                              ? parseFloat(detail.WAIST_TO_HEIGHT)
                                              : "-"}
                                            <sub>%</sub>
                                            {detail.WAIST_TO_HEIGHT >= 0 &&
                                              detail.WAIST_TO_HEIGHT < 43 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.WAIST_TO_HEIGHT >= 43 &&
                                              detail.WAIST_TO_HEIGHT < 53 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.WAIST_TO_HEIGHT >= 53 &&
                                              detail.WAIST_TO_HEIGHT < 58 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.WAIST_TO_HEIGHT >= 58 &&
                                              detail.WAIST_TO_HEIGHT < 63 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.WAIST_TO_HEIGHT >= 63 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-4 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={Group938} alt="icon" />
                                              <span>
                                                {" "}
                                                {t("user.dashboard.heading_9")}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal9(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal9(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.ABSI
                                              ? parseFloat(detail.ABSI)
                                              : "-"}
                                            <sub></sub>
                                            {gender == 1 ? (
                                              detail.ABSI >= 0 &&
                                                detail.ABSI < 7.1 ? (
                                                <span className="green line"></span>
                                              ) : detail.ABSI >= 7.1 &&
                                                detail.ABSI < 7.6 ? (
                                                <span className="light-green line"></span>
                                              ) : detail.ABSI >= 7.6 &&
                                                detail.ABSI < 8.6 ? (
                                                <span className="light-yellow line"></span>
                                              ) : detail.ABSI >= 8.6 &&
                                                detail.ABSI < 9.1 ? (
                                                <span className="yellow line"></span>
                                              ) : detail.ABSI >= 9.1 ? (
                                                <span className="red line"></span>
                                              ) : (
                                                <span className=""></span>
                                              )
                                            ) : detail.ABSI >= 0 &&
                                              detail.ABSI < 6.8 ? (
                                              <span className="green line"></span>
                                            ) : detail.ABSI >= 6.8 &&
                                              detail.ABSI < 7.4 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.ABSI >= 7.4 &&
                                              detail.ABSI < 8.6 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.ABSI >= 8.6 &&
                                              detail.ABSI < 9.2 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.ABSI >= 9.2 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-4 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={massacorp} alt="icon" />
                                              <span>
                                                {" "}
                                                {t("user.dashboard.heading_10")}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal10(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal10(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.BMI_CALC >= 0
                                              ? parseFloat(detail.BMI_CALC)
                                              : "-"}
                                            <sub></sub>
                                            {detail.BMI_CALC > 0 &&
                                              detail.BMI_CALC <= 18.5 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BMI_CALC > 18.5 &&
                                              detail.BMI_CALC <= 25 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.BMI_CALC > 25 &&
                                              detail.BMI_CALC >= 30 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BMI_CALC > 30 &&
                                              detail.BMI_CALC >= 35 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.BMI_CALC > 35 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-10 tabi">
                                      <div className="bg-tables">
                                        <h4 className="title measure">
                                          {t("user.dashboard.Measurements_history")}
                                        </h4>
                                        <table className="table table-tab">
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td className="font-s">
                                                <img src={Group911} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_8")}
                                              </td>
                                              <td className="font-s">
                                                <img src={Group938} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_9")}
                                              </td>
                                              <td className="font-s">
                                                <img src={massacorp} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_10")}
                                              </td>
                                            </tr>

                                            {tableData &&
                                              Object.keys(tableData).length > 0
                                              ? tableData.map((data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <th>
                                                      {formatDate(
                                                        data.time_stamp * 1000
                                                      )}
                                                    </th>
                                                    <td>
                                                      {data.WAIST_TO_HEIGHT >=
                                                        0 &&
                                                        data.WAIST_TO_HEIGHT <
                                                        43 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.WAIST_TO_HEIGHT >=
                                                        43 &&
                                                        data.WAIST_TO_HEIGHT <
                                                        53 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.WAIST_TO_HEIGHT >=
                                                        53 &&
                                                        data.WAIST_TO_HEIGHT <
                                                        58 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.WAIST_TO_HEIGHT >=
                                                        58 &&
                                                        data.WAIST_TO_HEIGHT <
                                                        63 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.WAIST_TO_HEIGHT >=
                                                        63 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {/* {data.ABSI >= 0 &&
                                                          data.ABSI < 7.07 ? (
                                                            <span className="circle light-green"></span>
                                                          ) : data.ABSI >= 7.07 &&
                                                            data.ABSI < 7.95 ? (
                                                            <span className="circle light-yellow"></span>
                                                          ) : data.ABSI >= 7.95 &&
                                                            data.ABSI < 8.39 ? (
                                                            <span className="circle yellow"></span>
                                                          ) : data.ABSI >= 8.39 ? (
                                                            <span className="circle red"></span>
                                                          ) : (
                                                            <span className=""></span>
                                                          )} */}

                                                      {/* {detail.ABSI
                                                ? parseFloat(detail.ABSI)
                                                : "-"} */}
                                                      {/* <sub></sub> */}
                                                      {gender == 1 ? (
                                                        detail.ABSI >= 0 &&
                                                          detail.ABSI < 7.1 ? (
                                                          <span className="circle green"></span>
                                                        ) : detail.ABSI >= 7.1 &&
                                                          detail.ABSI < 7.6 ? (
                                                          <span className="circle light-green"></span>
                                                        ) : detail.ABSI >= 7.6 &&
                                                          detail.ABSI < 8.6 ? (
                                                          <span className="circle light-yellow"></span>
                                                        ) : detail.ABSI >= 8.6 &&
                                                          detail.ABSI < 9.1 ? (
                                                          <span className="circle yellow"></span>
                                                        ) : detail.ABSI >= 9.1 ? (
                                                          <span className="circle red"></span>
                                                        ) : (
                                                          <span className=""></span>
                                                        )
                                                      ) : detail.ABSI >= 0 &&
                                                        detail.ABSI < 6.8 ? (
                                                        <span className="circle green"></span>
                                                      ) : detail.ABSI >= 6.8 &&
                                                        detail.ABSI < 7.4 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : detail.ABSI >= 7.4 &&
                                                        detail.ABSI < 8.6 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : detail.ABSI >= 8.6 &&
                                                        detail.ABSI < 9.2 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : detail.ABSI >= 9.2 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data.BMI_CALC > 0 &&
                                                        data.BMI_CALC < 18.5 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.BMI_CALC >= 18.5 &&
                                                        data.BMI_CALC < 25 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.BMI_CALC >= 25 &&
                                                        data.BMI_CALC < 30 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.BMI_CALC >= 30 &&
                                                        data.BMI_CALC < 35 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.BMI_CALC >= 35 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                              : null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-end tabicol2">
                                      {/* <Link
                                          to="/summary"
                                          className="buttons  h-auto"
                                        >
                                          {t("user.dashboard.see_all")}
                                        </Link> */}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {personalizeDetail.general_risks == 1 ? (
                                <div
                                  className={
                                    "tab-pane fade " +
                                    (personalText == "general_risks"
                                      ? "active show"
                                      : "")
                                  }
                                  id="fourth"
                                  role="tabpanel"
                                  aria-labelledby="fourth-tab"
                                >
                                  <div className="row">
                                    <div className="col-md-3 column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img
                                                src={cardiovascular}
                                                alt="icon"
                                              />
                                              <span>
                                                {" "}
                                                {t(
                                                  "user.dashboard.heading_11"
                                                )}{" "}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal11(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal11(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.BP_CVD >= 0
                                              ? parseFloat(detail.BP_CVD)
                                              : "-"}
                                            <sub>%</sub>
                                            {detail.BP_CVD >= 0 &&
                                              detail.BP_CVD < 5 ? (
                                              <span className="green line"></span>
                                            ) : detail.BP_CVD >= 5 &&
                                              detail.BP_CVD < 7.25 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.BP_CVD >= 7.25 &&
                                              detail.BP_CVD < 10 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BP_CVD >= 10 &&
                                              detail.BP_CVD < 20 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.BP_CVD > 20 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-3 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img
                                                src={ATTACCOCARDIACOICONA}
                                                alt="icon"
                                              />
                                              <span>
                                                {t("user.dashboard.heading_12")}{" "}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal12(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal12(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.BP_HEART_ATTACK >= 0
                                              ? parseFloat(detail.BP_HEART_ATTACK)
                                              : "-"}
                                            <sub>%</sub>
                                            {detail.BP_HEART_ATTACK >= 0 &&
                                              detail.BP_HEART_ATTACK < 1.65 ? (
                                              <span className="green line"></span>
                                            ) : detail.BP_HEART_ATTACK >= 1.65 &&
                                              detail.BP_HEART_ATTACK < 2.39 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.BP_HEART_ATTACK >= 2.39 &&
                                              detail.BP_HEART_ATTACK < 3.3 ? (
                                              <span className="light-yellow line"></span>
                                            ) : detail.BP_HEART_ATTACK >= 3.3 &&
                                              detail.BP_HEART_ATTACK < 6.6 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.BP_HEART_ATTACK >= 6.6 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-3 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={ICTUSICONA} alt="icon" />
                                              <span>
                                                {" "}
                                                {t(
                                                  "user.dashboard.heading_13"
                                                )}{" "}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal13(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal13(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.BP_STROKE >= 0
                                              ? parseFloat(detail.BP_STROKE)
                                              : "-"}
                                            <sub>%</sub>
                                            {detail.BP_STROKE >= 0 &&
                                              detail.BP_STROKE < 3.3 ? (
                                              <span className="green line"></span>
                                            ) : detail.BP_STROKE >= 3.3 &&
                                              detail.BP_STROKE < 4.79 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.BP_STROKE >= 4.79 &&
                                              detail.BP_STROKE < 6.6 ? (
                                              <span className=" light-yellow line"></span>
                                            ) : detail.BP_STROKE >= 6.6 &&
                                              detail.BP_STROKE < 13.2 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.BP_STROKE >= 13.2 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-3 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={Group728} alt="icon" />
                                              <span>
                                                {t("user.dashboard.heading_14")}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal14(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal14(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.HPT_RISK_PROB >= 0
                                              ? parseFloat(detail.HPT_RISK_PROB)
                                              : "-"}
                                            <sub>%</sub>
                                            {detail.HPT_RISK_PROB >= 0 &&
                                              detail.HPT_RISK_PROB < 25 ? (
                                              <span className="green line"></span>
                                            ) : detail.HPT_RISK_PROB >= 25 &&
                                              detail.HPT_RISK_PROB < 45 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.HPT_RISK_PROB >= 45 &&
                                              detail.HPT_RISK_PROB < 55 ? (
                                              <span className=" light-yellow line"></span>
                                            ) : detail.HPT_RISK_PROB >= 55 &&
                                              detail.HPT_RISK_PROB < 77 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.HPT_RISK_PROB >= 77 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-10 tabi">
                                      <div className="bg-tables">
                                        <h4 className="title measure">
                                          {t("user.dashboard.Measurements_history")}
                                        </h4>
                                        <table className="table table-tab">
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td className="font-s">
                                                <img
                                                  src={cardiovascular}
                                                  alt="icon"
                                                />
                                                <br />
                                                {t("user.dashboard.heading_11")}
                                              </td>
                                              <td className="font-s">
                                                <img
                                                  src={ATTACCOCARDIACOICONA}
                                                  alt="icon"
                                                />
                                                <br />
                                                {t("user.dashboard.heading_12")}
                                              </td>
                                              <td className="font-s">
                                                <img src={ICTUSICONA} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_13")}
                                              </td>
                                              <td className="font-s">
                                                <img src={Group728} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_14")}
                                              </td>
                                            </tr>

                                            {tableData &&
                                              Object.keys(tableData).length > 0
                                              ? tableData.map((data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <th>
                                                      {formatDate(
                                                        data.time_stamp * 1000
                                                      )}
                                                    </th>
                                                    <td>
                                                      {data.BP_CVD >= 0 &&
                                                        data.BP_CVD < 5 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.BP_CVD >= 5 &&
                                                        data.BP_CVD < 7.25 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.BP_CVD >= 7.25 &&
                                                        data.BP_CVD < 10 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.BP_CVD >= 10 &&
                                                        data.BP_CVD < 20 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.BP_CVD > 20 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data.BP_HEART_ATTACK >=
                                                        0 &&
                                                        data.BP_HEART_ATTACK <
                                                        1.65 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.BP_HEART_ATTACK >=
                                                        1.65 &&
                                                        data.BP_HEART_ATTACK <
                                                        2.39 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.BP_HEART_ATTACK >=
                                                        2.39 &&
                                                        data.BP_HEART_ATTACK <
                                                        3.3 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.BP_HEART_ATTACK >=
                                                        3.3 &&
                                                        data.BP_HEART_ATTACK <=
                                                        6.6 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.BP_HEART_ATTACK >=
                                                        6.6 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data.BP_STROKE >= 0 &&
                                                        data.BP_STROKE < 3.3 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.BP_STROKE >= 3.3 &&
                                                        data.BP_STROKE < 4.79 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.BP_STROKE >=
                                                        4.79 &&
                                                        data.BP_STROKE < 6.6 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.BP_STROKE >= 6.6 &&
                                                        data.BP_STROKE < 13.2 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : detail.BP_STROKE >=
                                                        13.2 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data.HPT_RISK_PROB >= 0 &&
                                                        data.HPT_RISK_PROB < 25 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.HPT_RISK_PROB >=
                                                        25 &&
                                                        data.HPT_RISK_PROB <
                                                        45 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.HPT_RISK_PROB >=
                                                        45 &&
                                                        data.HPT_RISK_PROB <
                                                        55 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.HPT_RISK_PROB >=
                                                        55 &&
                                                        data.HPT_RISK_PROB <
                                                        77 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.HPT_RISK_PROB >=
                                                        877 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                              : null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-end tabicol2">
                                      {/* <Link
                                          to="/summary"
                                          className="buttons h-auto"
                                        >
                                          {t("user.dashboard.see_all")}
                                        </Link> */}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {personalizeDetail.metabolic_risks == 1 ? (
                                <div
                                  className={
                                    "tab-pane fade " +
                                    (personalText == "metabolic_risks"
                                      ? "active show"
                                      : "")
                                  }
                                  id="fifth"
                                  role="tabpanel"
                                  aria-labelledby="fifth-tab"
                                >
                                  <div className="row">
                                    <div className="col-md-4 column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={GLICEMIAICONA} alt="icon" />
                                              <span>
                                                {" "}
                                                {t(
                                                  "user.dashboard.heading_15"
                                                )}{" "}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal15(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal15(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.TG_RISK_PROB
                                              ? parseFloat(detail.TG_RISK_PROB)
                                              : "-"}
                                            <sub>%</sub>
                                            {detail.TG_RISK_PROB >= 0 &&
                                              detail.TG_RISK_PROB < 25 ? (
                                              <span className="green line"></span>
                                            ) : detail.TG_RISK_PROB >= 25 &&
                                              detail.TG_RISK_PROB < 45 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.TG_RISK_PROB >= 45 &&
                                              detail.TG_RISK_PROB < 55 ? (
                                              <span className=" light-yellow line"></span>
                                            ) : detail.TG_RISK_PROB >= 55 &&
                                              detail.TG_RISK_PROB < 77.5 ? (
                                              <span className=" yellow line"></span>
                                            ) : detail.TG_RISK_PROB >= 77.5 ? (
                                              <span className="light-red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-4 column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={Grouprishico} alt="icon" />
                                              <span>
                                                {" "}
                                                {t(
                                                  "user.dashboard.heading_16"
                                                )}{" "}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part ">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal16(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal16(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.HDLTC_RISK_PROB
                                              ? parseFloat(detail.HDLTC_RISK_PROB)
                                              : "-"}
                                            <sub>%</sub>
                                            {detail.HDLTC_RISK_PROB >= 0 &&
                                              detail.HDLTC_RISK_PROB < 25 ? (
                                              <span className="green line"></span>
                                            ) : detail.HDLTC_RISK_PROB >= 25 &&
                                              detail.HDLTC_RISK_PROB < 45 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.HDLTC_RISK_PROB >= 45 &&
                                              detail.HDLTC_RISK_PROB < 55 ? (
                                              <span className=" yellow line"></span>
                                            ) : detail.HDLTC_RISK_PROB >= 55 &&
                                              detail.HDLTC_RISK_PROB < 77.5 ? (
                                              <span className=" yellow line"></span>
                                            ) : detail.HDLTC_RISK_PROB >= 77.5 ? (
                                              <span className=" yellow line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-4 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={DIABETE} alt="icon" />
                                              <span>
                                                {t("user.dashboard.heading_17")}{" "}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part ">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal17(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal17(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p>
                                            {detail.DBT_RISK_PROB >= 0
                                              ? parseFloat(detail.DBT_RISK_PROB)
                                              : "-"}
                                            <sub>%</sub>
                                            {detail.DBT_RISK_PROB >= 0 &&
                                              detail.DBT_RISK_PROB < 25 ? (
                                              <span className="green line"></span>
                                            ) : detail.DBT_RISK_PROB >= 25 &&
                                              detail.DBT_RISK_PROB < 45 ? (
                                              <span className="light-green line"></span>
                                            ) : detail.DBT_RISK_PROB >= 45 &&
                                              detail.DBT_RISK_PROB < 55 ? (
                                              <span className=" light-yellow line"></span>
                                            ) : detail.DBT_RISK_PROB >= 55 &&
                                              detail.DBT_RISK_PROB < 77.5 ? (
                                              <span className="yellow line"></span>
                                            ) : detail.DBT_RISK_PROB >= 77.5 ? (
                                              <span className="red line"></span>
                                            ) : (
                                              <span className=""></span>
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-10 tabi">
                                      <div className="bg-tables">
                                        <h4 className="title measure">
                                          {t("user.dashboard.Measurements_history")}
                                        </h4>
                                        <table className="table table-tab">
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td className="font-s">
                                                <img
                                                  src={GLICEMIAICONA}
                                                  alt="icon"
                                                />
                                                <br />
                                                {t("user.dashboard.heading_15")}
                                              </td>
                                              <td className="font-s">
                                                <img
                                                  src={GLICEMIAICONA}
                                                  alt="icon"
                                                />
                                                <br />
                                                {t("user.dashboard.heading_16")}
                                              </td>
                                              <td className="font-s">
                                                <img src={DIABETE} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_17")}
                                              </td>
                                            </tr>

                                            {tableData &&
                                              Object.keys(tableData).length > 0
                                              ? tableData.map((data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <th>
                                                      {formatDate(
                                                        data.time_stamp * 1000
                                                      )}
                                                    </th>
                                                    <td>
                                                      {data.TG_RISK_PROB >= 0 &&
                                                        data.TG_RISK_PROB < 25 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.TG_RISK_PROB >=
                                                        25 &&
                                                        data.TG_RISK_PROB < 45 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.TG_RISK_PROB >=
                                                        45 &&
                                                        data.TG_RISK_PROB < 55 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.TG_RISK_PROB >=
                                                        55 &&
                                                        data.TG_RISK_PROB <
                                                        77.5 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.TG_RISK_PROB >=
                                                        77.5 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data.HDLTC_RISK_PROB >=
                                                        0 &&
                                                        data.HDLTC_RISK_PROB <
                                                        25 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.HDLTC_RISK_PROB >=
                                                        25 &&
                                                        data.HDLTC_RISK_PROB <
                                                        45 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.HDLTC_RISK_PROB >=
                                                        45 &&
                                                        data.HDLTC_RISK_PROB <
                                                        55 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.HDLTC_RISK_PROB >=
                                                        55 &&
                                                        data.HDLTC_RISK_PROB <
                                                        77.5 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : data.HDLTC_RISK_PROB >=
                                                        77.5 ? (
                                                        <span className="circle red"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                    <td>
                                                      {data.DBT_RISK_PROB >= 0 &&
                                                        data.DBT_RISK_PROB < 20 ? (
                                                        <span className="circle green"></span>
                                                      ) : data.DBT_RISK_PROB >=
                                                        20 &&
                                                        data.DBT_RISK_PROB <
                                                        40 ? (
                                                        <span className="circle light-green"></span>
                                                      ) : data.DBT_RISK_PROB >=
                                                        40 &&
                                                        data.DBT_RISK_PROB <
                                                        60 ? (
                                                        <span className="circle light-yellow"></span>
                                                      ) : data.DBT_RISK_PROB >=
                                                        60 ? (
                                                        <span className="circle yellow"></span>
                                                      ) : (
                                                        <span className=""></span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                              : null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-end tabicol2">
                                      {/* <Link
                                          to="/summary"
                                          className="buttons h-auto"
                                        >
                                          {t("user.dashboard.see_all")}
                                        </Link> */}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {personalizeDetail.measurement_of_psychological_values ==
                                1 ? (
                                <div
                                  className={
                                    "tab-pane fade " +
                                    (personalText ==
                                      "measurement_of_psychological_values"
                                      ? "active show"
                                      : "")
                                  }
                                  id="sixth"
                                  role="tabpanel"
                                  aria-labelledby="sixth-tab"
                                >
                                  <div className="row">
                                    <div className="col-md-6 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img src={ANSIA_ICONA} alt="icon" />
                                              <span>
                                                {" "}
                                                {t(
                                                  "user.dashboard.heading_18"
                                                )}{" "}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal18(true)}
                                              />
                                            </i>{" "}
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal18(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">

                                          <p className="m-auto">
                                            {(detail.anxietyProbability && todayMeasurement)
                                              ? parseFloat(detail.anxietyProbability).toFixed(1)
                                              : (scanLimit == 0) ?
                                              <button className="buttons register-btn" onClick={() => setModal21(true)}><img src={(language == "it") ? Group_1175PP : Group_1175AND} alt="icon" /></button>
                                              :  <button className="buttons register-btn" ><img src={(language == "it") ? Group_1175PP : Group_1175AND} alt="icon" /></button>

                                            }
                                            {(detail.anxietyProbability && todayMeasurement) ? <sub>%</sub> : ''}
                                            {
                                              (todayMeasurement && detail.anxietyProbability) ?
                                                detail.anxietyProbability >= 0 &&
                                                  detail.anxietyProbability < 20 ? (
                                                  <span className="green line"></span>
                                                ) : detail.anxietyProbability >= 20 &&
                                                  detail.anxietyProbability < 40 ? (
                                                  <span className="light-green line"></span>
                                                ) : detail.anxietyProbability >= 40 &&
                                                  detail.anxietyProbability < 60 ? (
                                                  <span className=" yellow line"></span>
                                                ) : detail.anxietyProbability >= 60 &&
                                                  detail.anxietyProbability < 80 ? (
                                                  <span className="light-red line"></span>
                                                ) : detail.anxietyProbability >= 80 ? (
                                                  <span className="red line"></span>
                                                ) : (
                                                  <span className=""></span>
                                                )
                                                : null
                                            }
                                          </p>
                                          {
                                            (todayMeasurement && detail.anxietyProbability) ?
                                              <span className="depression-text-style" dangerouslySetInnerHTML={{
                                                __html: t("user.dashboard.anxietyText"),
                                              }}>
                                              </span>
                                              :
                                              null
                                          }

                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-6 d-flex column-increase">
                                      <div className="bg-boxes">
                                        <div className="Fisiologici">
                                          <div className="right-s">
                                            <h4>
                                              <img
                                                src={DEPRESSIONE_ICONA}
                                                alt="icon"
                                              />
                                              <span>
                                                {" "}
                                                {t(
                                                  "user.dashboard.heading_19"
                                                )}{" "}
                                              </span>
                                            </h4>
                                          </div>
                                          <div className="right-part ">
                                            <i className="colorNew  visi-none">
                                              {" "}
                                              <FaEllipsisH
                                                className="pointer"
                                                onClick={() => setModal19(true)}
                                              />
                                            </i>
                                            <i className="colorNew hd-n">
                                              {" "}
                                              <HiOutlineInformationCircle
                                                className="pointer"
                                                onClick={() => setModal19(true)}
                                              />
                                            </i>
                                          </div>
                                        </div>
                                        <div className="right-s variable le-spac">
                                          <p className="m-auto">
                                            
                                            {
                                              (detail.depressionProbability && todayMeasurement)
                                                ? parseFloat(detail.depressionProbability).toFixed(1)
                                                : (scanLimit == 0) ?
                                                <button className="buttons register-btn" onClick={() => setModal22(true)}><img src={(language == "it") ? Group_1175PP : Group_1175AND} alt="icon" /></button>
                                                :  <button className="buttons register-btn" ><img src={(language == "it") ? Group_1175PP : Group_1175AND} alt="icon" /></button>

                                            }
                                            {(detail.depressionProbability && todayMeasurement) ? <sub>%</sub> : ''}

                                            {
                                              (todayMeasurement && detail.depressionProbability) ?
                                                detail.depressionProbability >= 0 &&
                                                  detail.depressionProbability < 20 ? (
                                                  <span className="green line"></span>
                                                ) : detail.depressionProbability >= 20 &&
                                                  detail.depressionProbability < 40 ? (
                                                  <span className="light-green line"></span>
                                                ) : detail.depressionProbability >= 40 &&
                                                  detail.depressionProbability < 60 ? (
                                                  <span className=" yellow line"></span>
                                                ) : detail.depressionProbability >= 60 &&
                                                  detail.depressionProbability < 80 ? (
                                                  <span className="light-red line"></span>
                                                ) : detail.depressionProbability >= 80 ? (
                                                  <span className="red line"></span>
                                                ) : (
                                                  <span className=""></span>
                                                )
                                                : null
                                            }
                                          </p>
                                          {
                                            (todayMeasurement && detail.depressionProbability) ?
                                              <span className="depression-text-style">{t(
                                                "user.dashboard.depressionText"
                                              )}
                                              </span>
                                              :
                                              null
                                          }

                                        </div>
                                      </div>
                                    </div>

                                    {/* <div className="col-md-4 column-increase">
                                  <div className="bg-boxes">
                                    <div className="Fisiologici">
                                      <div className="right-s">
                                        <h4>
                                          <img
                                            src={FATICAMENTALE_ICONA}
                                            alt="icon"
                                          />
                                          <span>
                                            {t("user.dashboard.heading_20")}
                                          </span>
                                        </h4>
                                      </div>
                                      <div className="right-part ">
                                        <i className="colorNew  visi-none">
                                          {" "}
                                          <FaEllipsisH
                                            className="pointer"
                                            onClick={() => setModal20(true)}
                                          />
                                        </i>
                                        <i className="colorNew hd-n">
                                          {" "}
                                          <HiOutlineInformationCircle
                                            className="pointer"
                                            onClick={() => setModal20(true)}
                                          />
                                        </i>
                                      </div>
                                    </div>
                                    <div className="right-s variable le-spac">
                                      <p>
                                      <button className="buttons register-btn"><img src={Group_1175UI} alt="icon" /></button>
                                        {detail.Fatica_mentale >= 0 &&
                                        detail.Fatica_mentale < 20 ? (
                                          <span className="dark_green line"></span>
                                        ) : detail.Fatica_mentale >= 20 &&
                                          detail.Fatica_mentale < 40 ? (
                                          <span className="green line"></span>
                                        ) : detail.Fatica_mentale >= 40 &&
                                          detail.Fatica_mentale < 60 ? (
                                          <span className=" yellow line"></span>
                                        ) : detail.Fatica_mentale >= 60 &&
                                          detail.Fatica_mentale < 80 ? (
                                          <span className="light-red line"></span>
                                        ) : detail.Fatica_mentale >= 80 ? (
                                          <span className="red line"></span>
                                        ) : (
                                          <span className=""></span>
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div> */}

                                    <div className="col-md-10 tabi">
                                      <div className="bg-tables">
                                        <h4 className="title measure">
                                          {t("user.dashboard.Measurements_history")}
                                        </h4>
                                        <table className="table table-tab">
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td className="font-s">
                                                <img src={ANSIA_ICONA} alt="icon" />
                                                <br />
                                                {t("user.dashboard.heading_18")}
                                              </td>
                                              <td className="font-s">
                                                <img
                                                  src={DEPRESSIONE_ICONA}
                                                  alt="icon"
                                                />
                                                <br />
                                                {t("user.dashboard.heading_19")}
                                              </td>
                                              {/* <td className="font-s">
                                            <img
                                              src={FATICAMENTALE_ICONA}
                                              alt="icon"
                                            />
                                            <br />
                                            {t("user.dashboard.heading_20")}
                                          </td> */}
                                            </tr>

                                            {tableData &&
                                              Object.keys(tableData).length > 0
                                              ? tableData.map((data, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <th>
                                                      {formatDate(
                                                        data.time_stamp * 1000
                                                      )}
                                                    </th>
                                                    <td>
                                                      {(Math.floor(data.anxietyProbability) > 0) && (Math.floor(data.anxietyProbability) <= 20) ? (<span className="circle green"></span>)
                                                        : (Math.floor(data.anxietyProbability) > 20) && (Math.floor(data.anxietyProbability) <= 40) ? (<span className="circle light-green"></span>)
                                                          : (Math.floor(data.anxietyProbability) > 40) && (Math.floor(data.anxietyProbability) <= 60) ? (<span className="circle light-yellow"></span>)
                                                            : (Math.floor(data.anxietyProbability) > 60) && (Math.floor(data.anxietyProbability) <= 80) ? (<span className="circle light-red"></span>)
                                                              : (Math.floor(data.anxietyProbability) > 80) && (Math.floor(data.anxietyProbability) <= 100) ? (<span className="circle red"></span>)
                                                                : (<span className="">-</span>)}
                                                    </td>
                                                    <td>
                                                      {(Math.floor(data.depressionProbability) > 0) && (Math.floor(data.depressionProbability) <= 20) ? (<span className="circle green"></span>)
                                                        : (Math.floor(data.depressionProbability) > 20) && (Math.floor(data.depressionProbability) <= 40) ? (<span className="circle light-green"></span>)
                                                          : (Math.floor(data.depressionProbability) > 40) && (Math.floor(data.depressionProbability) <= 60) ? (<span className="circle light-yellow"></span>)
                                                            : (Math.floor(data.depressionProbability) > 60) && (Math.floor(data.depressionProbability) <= 80) ? (<span className="circle light-red"></span>)
                                                              : (Math.floor(data.depressionProbability) > 80) && (Math.floor(data.depressionProbability) <= 100) ? (<span className="circle red"></span>)
                                                                : (<span className="">-</span>)}
                                                    </td>
                                                    {/* <td> */}
                                                    {/* - */}
                                                    {/* {(data.BP_CVD >= 0) && (data.BP_CVD <= 10) ? (<span className="red-circle"></span>)
                                            : (data.BP_CVD >= 10) && (data.BP_CVD <= 20) ? (<span className="yellow-circle"></span>)
                                              : (data.BP_CVD >= 20) && (data.BP_CVD <= 30) ? (<span className="orange-circle"></span>)
                                                : (data.BP_CVD >= 30) && (data.BP_CVD <= 40) ? (<span className="green-color"></span>)
                                                  : (<span className=""></span>)} */}
                                                    {/* </td> */}
                                                  </tr>
                                                );
                                              })
                                              : null}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-end tabicol2">
                                      {/* <Link
                                    to="/summary"
                                    className="buttons h-auto"
                                  >
                                    {t("user.dashboard.see_all")}
                                  </Link> */}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className={`tab-pane ${activeTab === "summary" ? "active show" : ""}`} id="summary">                  
                    {activeTab==="summary" ? 
                        <SummaryTab activeTab={activeTab} />
                    :null}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* {isLoading ? <LazyLoader /> : null} */}
        </div>

      </section>
      <Sidebarmob />
    </>
  );
};

export default UserDashboard;
