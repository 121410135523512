import { ProgressBar } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import Modal from "../../common/modal";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, getCompanyDetails } from "../../common/allfunction";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import ReactPaginate from 'react-paginate';
import paginationFactory from "react-bootstrap-table2-paginator";
import VectorDown from "../../../assets/Image/VectorDown.svg";
import filterFactory from "react-bootstrap-table2-filter";
import Group698 from "../../../assets/Image/Group698.svg";
import Group1857PDF from "../../../assets/Image/Group1857PDF.svg";
import VIDEO_ICONAvideo from "../../../assets/Image/VIDEO_ICONAvideo.svg"
import Group1857link from "../../../assets/Image/Group1857link.svg"
import Group878 from "../../../assets/Image/Group878.svg";
import Frame from '../../../assets/Image/Frame.svg';
import Group769 from "../../../assets/Image/Group769.svg";
import DeleteBin from '../../../assets/Image/delete-bin.svg'
import Delete_Icon_popup from '../../../assets/Image/Delete_Icon_popup.svg'
import Popup_Cross from '../../../assets/Image/Popup_Cross.svg'
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import EDIT_PENCIL from '../../../assets/Image/EDIT_PENCIL.svg';
import OPEN_ICON from '../../../assets/Image/OPEN2_ICONA.svg';
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg";
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg';
import PREV_PAGE_ICON from '../../../assets/Image/PREVPAGE_ICON.svg';
import '../super_admin_assets/scss/protocol.scss';
import moment from "moment";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import GetRequest from "../../apiConnection/getRequest";
import Group1333 from "../../../assets/Image/Group 1333.svg";
import Group1333PDF from "../../../assets/Image/Group1333P.svg";
import Vectordocument from "../../../assets/Image/Vectordocument.svg"
import UPLOAD_ICONA from "../../../assets/Image/UPLOAD_ICONA.svg";
import VIDEO_IMAGE from '../../../assets/Image/Rectangle319.svg';
import { fileUploadApi } from "../../apiConnection/fileUpload";
import DOCUMENT_ICON from '../../../assets/Image/DOCUMENT_ICON.svg'
import CONTENTDOC from '../../../assets/Image/CONTENTDOC.svg';
import VideoPlayButton from '../../../assets/Image/VideoPlayButton.svg'
import ShowVideo from "../../common/ShowVideo";
import { ViewDocument } from "../../common/ViewDocument";
import Select from "react-select";

const Edit_Training = (props) => {
    const [fileSize, setFileSize] = useState(1e+8)
    const ref = useRef(null);
    const fileUpload = useRef(null);
    let history = useHistory();
    const [files, setFiles] = useState([]);
    const [pdfFile, setPdfFile] = useState([]);
    const [videoPopupPage, setVideoPopupPage] = useState(1);
    const [addVideoModal, setAddVideoModal] = useState(false);
    const [addPdfModal, setAddPdfModal] = useState(false);
    const [pdfPopupPage, setPdfPopupPage] = useState(1);
    const [contentId, setContentId] = useState(null);
    const [docPreview, setDocPreview] = useState(false);
    const [materialPopupPage, setMaterialPopupPage] = useState(1);
    const [uploadAttempts, setUploadAttempts] = useState(0);
    let urlPattern = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const [textCount, setTextCount] = useState(20);
    const [favIcon, setFavIcon] = useState('')
    const [errorfetch, setErrorfetch] = useState(
        props.match.params?.errorfetch && props.match.params?.errorfetch !== ""
            ? props.match.params?.errorfetch
            : ""
    );
    const [urlRegex, setUrlRegex] = useState(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+~#?&/=]*)/);
    const [currentEditingUrl, setCurrentEditingUrl] = useState(null)
    const [currentEditing, setCurrentEditing] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [uploadUrl, setUploadUrl] = useState([])
    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)
    const { SearchBar } = Search;
    const [company_id, setCompany_id] = useState(props.match.params.company_id)
    const [programData, setProgramData] = useState([])
    const [isDisabled, setIsDisabled] = useState(true)
    const [urlLink, setUrlLink] = useState(null)
    const path = window.location.pathname;
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [popupLoader1, setPopupLoader1] = useState(false)
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [popupLoader3, setPopupLoader3] = useState(false)
    const [companyDetails, setCompanyDetails] = useState({})
    const [filterShow, setFilterShow] = useState(false);
    const [filterValue, setFilterValue] = useState([]);
    const [filterValueData, setfilterValueData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [programColumn, setProgramColumn] = useState([{ text: "name", dataField: "program_name", sort: true }])
    const [personalText, setPersonalText] = useState("program");
    const [addProgramModal, setAddProgramModal] = useState(false)
    const [addProgramModal2, setAddProgramModal2] = useState(false)
    const [updateProgramModal2, setUpdateProgramModal2] = useState(false)
    const [updateProgramData, setUpdateProgramData] = useState({ company_id: props.match.params.company_id, program_name: "", description: "", course: {}, duration: 1, release_date: "", show_release_date: "", obligatory: "", status: 0, referent: "" })
    const [deleteProgramModal, setDeleteProgramModal] = useState(false)
    const [currentProgram, setCurrentProgram] = useState({})
    const [newProgramData, setNewProgramData] = useState({
        company_id: props.match.params.company_id, program_name: "",
        programNameITA: "", description: "", descriptionITA: "",
        course: [], courseITA: [], duration: 1, release_date: (new Date() / 1000),
        show_release_date: moment().format('YYYY-MM-DD'), obligatory: "", status: 0, referent: "", languagetype: 'it'
    })
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [documentData, setDocumentData] = useState([]);
    const [activeDocument, setActiveDocument] = useState({})
    const [documentProgramsModal, setDocumentProgramsModal] = useState(false)
    const [documentPageCount, setDocumentPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [itemPerPage, setItemPerPage] = useState(10)
    const [refrentData, setRefrentData] = useState([])
    const [videoData, setVideoData] = useState([])
    const [videoPageCount, setVideoPageCount] = useState(0)
    const [videoCurrentPage, setVideoCurrentPage] = useState(0)
    const [videoItemPerPage, setVideoItemPerPage] = useState(10)
    const [addTextDocumentModal, setAddTextDocumentModal] = useState(false)
    const [addTextDocumentData, setAddTextDocumentData] = useState({ title: "", description: "", text: "", editorState: "", languageType: "it" })
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [videoSearchQuery, setVideoSearchQuery] = useState("")
    const [pdfUrlChange, setPdfUrlChange] = useState(null)
    const [documentSearchQuery, setDocumentSearchQuery] = useState("")
    const [disableAddNewProgramButton, setDisableAddNewProgramButton] = useState(false)
    const [disableUpdateProgramButton, setDisableUpdateProgramButton] = useState(false)
    const [disableDeleteProgramButton, setDisableDeleteProgramButton] = useState(false)
    const [disableAddTextDocumentButton, setDisableAddTextDocumentButton] = useState(false)
    const [addLearningModal, setLearningModal] = useState(false)
    const [learningData, setLearningData] = useState([])
    const [learningDataLenght, setLearningDataLenght] = useState(0)
    const [arrObj, setArrObj] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [selectIndex, setSelectIndex] = useState();
    const [selectedNewLearningData, setselectedNewLearningData] = useState({});
    const [selectfinal, setSelectFinal] = useState({});
    const [checkboxStates, setCheckboxStates] = useState({});
    const [deleteContentPopUp, setDeleteContentPopUp] = useState(false)
    const [deleteVideoPopUp, setDeleteVideoPopUp] = useState(false)
    const [documentLoadMoreIndex, setDocumentLoadMoreIndex] = useState('')
    const modalRef = useRef(null);

    const [durationCount, setDurationCount] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20])
    const [fileObject, setFileObject] = useState({
        files:
            [],
        urls:
            []
    });
    const [pdfFileObject, setPdfFileObject] = useState({
        files:
            [],
        urls:
            []
    });
    const [maxFile, setMaxFile] = useState(10)
    const [progress, setProgress] = useState()
    const [currentVideoDetails, setCurrentVideoDetails] = useState({ link: "", title: "", description: "" })
    const [showVideoContentModal, setShowVideoContentModal] = useState(false)
    const [allContentList, setAllContentList] = useState([])
    const [selectedContents, setSelectedContents] = useState();
    const [newProgramAllCourse, setNewProgramAllCourse] = useState([])
    const getCompanyAllProgram = async () => {
        const payload = {
            "company_id": company_id,
        };
        setIsLoading(true);
        try {
            const getAllDataResult = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_PROGRAM}`,
                payload,
                "",
                ""
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                setIsLoading(false);
                if (getAllDataResult.data?.data?.length > 0) {
                    let arr = [];
                    getAllDataResult.data.data.forEach((item) => {
                        if (item.status > 0) {
                            arr = [...arr, {
                                program_name: item.program_name || "",
                                duration: item.duration || "",
                                course: item.course || 0,
                                release_date: item.release_date ? `${moment(new Date(item.release_date * 1000)).format("DD.MM.YY").replace(/YYYY/g, "YY")}` : '',
                                obligatory: item.obligatory[0].toUpperCase() + item.obligatory.slice(1).toLowerCase() || '',
                                program_id: item.program_id || '',
                                status: item.status || 0,
                                referent: item.referent || '',
                                description: item.description || ''
                            }]
                        }

                    })
                    setProgramData(arr)

                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const getCompanyRefrent = async () => {
        setIsLoading(true);
        const refrentPayload = {
            "company": company_id,
            "role_id": 2
        };
        try {
            const getRefrentData = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_USER_LIST}`,
                refrentPayload,
                "",
                ""
            );
            if (getRefrentData && getRefrentData.status === 200) {
                setIsLoading(false);
                if (getRefrentData.data?.data?.length > 0) {
                    let arr = [];
                    getRefrentData.data.data.forEach((item) => {
                        if (item.status > 0) {
                            arr = [...arr, {
                                name: item.name || "",
                                user_id: item.user_id || "",
                            }]
                        }

                    })
                    setRefrentData(arr);

                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    const addNewProgramAPI = async () => {
        setDisableAddNewProgramButton(true);
        setIsLoading(true)
        const payload = {
            ...newProgramData,
            course: newProgramAllCourse
        };
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const addProgram = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_PROGRAM}`,
                    payload,
                    "",
                    ""
                );
                if (addProgram && addProgram.status === 200) {
                    closeModal()
                    setNewProgramAllCourse([])
                    setDisableAddNewProgramButton(false)
                    setAlertMessage({ show: false, spinner: false, message: "", class: "" })
                    setIsLoading(false);
                    getCompanyAllProgram()
                } else {
                    if (addProgram.status === 203) {
                        setAlertMessage({ show: true, spinner: false, message: addProgram.data?.message, class: "alert alert-danger" })
                    }
                    setDisableAddNewProgramButton(false)
                    setIsLoading(false);
                }
            } else {
                setDisableAddNewProgramButton(false)
                setIsLoading(false);
            }
        } catch (error) {
            setDisableAddNewProgramButton(false)
            setIsLoading(false);
        }
    };
    const updateProgramAPI = async () => {
        setIsLoading(true)
        setDisableUpdateProgramButton(true)
        const payload = updateProgramData;
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const updateProgram = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_PROGRAM}`,
                    payload,
                    "",
                    ""
                );
                if (updateProgram && updateProgram.status === 200) {
                    setIsLoading(false);
                    closeModal()
                    setDisableUpdateProgramButton(false)
                    getCompanyAllProgram()
                } else {
                    if (updateProgram.status === 203) {
                        setAlertMessage({ show: true, spinner: false, message: updateProgram.data?.message, class: "alert alert-danger" })
                    }
                    setIsLoading(false);
                    setDisableUpdateProgramButton(false)
                }
            } else {
                setDisableUpdateProgramButton(false)
                setIsLoading(false);
            }
        } catch (error) {
            setDisableUpdateProgramButton(false)
            setIsLoading(false);
        }
    }
    const getCompanyDocument = async (page) => {
        setIsLoading(true);

        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const documentResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_DOCUMENT}?companyId=${company_id}&skip=${(page - 1) * itemPerPage}&limit=${itemPerPage}&search=${documentSearchQuery}`
                );
                if (documentResult && documentResult.status === 200) {
                    let data2 = documentResult.data?.data;
                    if (data2?.content.length > 0) {
                        let arr = []
                        data2.content.forEach((d1) => {
                            let pro = [];
                            d1.associatePrograms?.forEach((d2) => {
                                pro = [...pro, { id: d2.id, name: d2.name, description: d2.description }]
                            })
                            arr = [...arr, {
                                name: d1.title,
                                description: d1.description,
                                programs: pro,
                                contentId: d1.contentId
                            }]
                        })
                        setDocumentData(arr)
                        setDocumentPageCount(Math.ceil(data2?.count / itemPerPage))
                        setCurrentPage(page)
                        setIsLoading(false);

                    } else {
                        setDocumentData([])
                        setIsLoading(false);

                    }
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    const getCompanyVideo = async (page) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const documentResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_VIDEO}?companyId=${company_id}&skip=${(page - 1) * videoItemPerPage}&limit=${videoItemPerPage}&search=${videoSearchQuery}`
                );
                if (documentResult && documentResult.status === 200) {
                    let data1 = documentResult.data?.data;
                    if (data1?.content?.length > 0) {
                        let arr = []
                        data1.content.forEach((d1) => {
                            let pro = [];
                            d1.associatePrograms?.forEach((d2) => {
                                pro = [...pro, { id: d2.id, name: d2.name, description: d2.description }]
                            })
                            arr = [...arr, {
                                contentId: d1.contentId,
                                name: d1.title,
                                link: d1.link,
                                thumbnail: d1.thumbnail,
                                description: d1.description,
                                programs: pro
                            }]
                        })
                        setVideoData(arr)
                        setVideoPageCount(Math.ceil(data1?.count / videoItemPerPage))
                        setVideoCurrentPage(page)
                        setIsLoading(false);
                    } else {
                        setVideoData([])
                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);

                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const getVideoContentDetails = async (id) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const videoResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONTENT_DETAILS}?contentId=${id}`
                );
                if (videoResult && videoResult.status === 200) {
                    setIsLoading(false);
                    let data1 = videoResult.data?.data;
                    let link = data1.url ? data1.url : data1.filePath?.includes("vimeo") ? data1.filePath : `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_CONTENT}${data1.filePath}`;
                    if (data1.url) {
                        window.open(data1.url, '_blank').focus();
                    } else {
                        setCurrentVideoDetails({ title: data1.title, description: data1.description, link: link, error: false })
                        setShowVideoContentModal(true)
                    }
                } else {
                    if (videoResult?.status === 203) {
                        setCurrentVideoDetails({ ...currentVideoDetails, error: true })
                    }
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const contentPopUp = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.popup.deleteContent")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader1 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6><strong></strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => { setPopupLoader1(true); deleteContentDetails(contentId, "document") }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const videoPopUp = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.popup.deleteContent")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader1 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6><strong></strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => { setPopupLoader1(true); deleteContentDetails(contentId, "video") }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const deleteContentDetails = async (id, type) => {
        setIsLoading(true);
        const payloadContent = {
            "content_id": contentId
        }

        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const contentResult = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELETE_CONTENT_DATA}`,
                    payloadContent,
                    "",
                    ""
                );
                if (contentResult && contentResult.status === 200) {
                    setIsLoading(false);
                    setContentId(null);

                    closeModal();
                    (type == "video") ? getCompanyVideo(videoCurrentPage) : getCompanyDocument(currentPage)

                } else {

                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        const actionButtonsProgram = (cell, row, rowIndex, formateExtraData) => {
            return (
                <div className="d-flex justify-content-center">
                    <button className="table-action" onClick={() => window.location.href = `${company_id}/${row.program_id}`}><img src={ExpandIcon} alt="icon" /></button>
                    <button className="table-action" onClick={() => { setUpdateProgramData({ ...row, show_release_date: moment(row.release_date, 'DD.MM.YY').format("YYYY-MM-DD"), release_date: `${row.release_date ? new Date(moment(row.release_date, 'DD.MM.YY').format("YYYY-MM-DD")).getTime() / 1000 : ""}` }); setUpdateProgramModal2(true) }}><img src={Group878} alt="icon" /></button>
                    <button className="table-action" onClick={() => { setDeleteProgramModal(true); setCurrentProgram({ program_id: row.program_id, program_name: row.program_name, status: 0 }) }}><img src={DeleteBin} alt="icon" /></button>
                </div>
            )
        }
        const nameFormatter = (cell, row) => {
            let color = row.status == 1 ? 'green' : row.status == 2 ? 'red' : 'yellow';
            return (
                <div><span className={`small-dot ${color} `}> </span> {` ${row.program_name}`}</div>
            )
        }
        setProgramColumn([
            {
                text: t("superadmin.training.name"),
                dataField: "program_name",
                formatter: nameFormatter,
                sort: true,
            },
            {
                text: t("superadmin.training.duration"),
                dataField: "duration",
                sort: true,
            },
            {
                text: t("superadmin.training.npaths"),
                dataField: "course",
                sort: true,
            },
            {
                text: t("superadmin.training.mandatory"),
                dataField: "obligatory",
                sort: true,
            },
            {
                text: t("superadmin.training.release"),
                dataField: "release_date",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtonsProgram,
                sort: true,
                headerAlign: "center"
            },
        ])
    };
    //Get Learning material
    const getLearningMaterial = async () => {
        setSelectedData({});
        setIsLoading(true);
        try {
            const getDataLearning = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_LEARNING_MATERIAL}`
            );
            if (getDataLearning) {
                setIsLoading(false);
                const tagLearningData = getDataLearning.data.data;
                let totalDataLength = 0;
                totalDataLength = Object.keys(tagLearningData).length;
                (totalDataLength > 0) ? setLearningData(tagLearningData) : setLearningData(false)
                setLearningDataLenght(totalDataLength);

            } else {
                console.error("Failed to fetch learning material data.");
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    };
    //Get Learning Material close
    const getAllContentAPI = async () => {
        setIsLoading(true);

        if (allContentList.length > 0) return;
        try {
            const getAllContent = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ALL_CONTENT}`
            );
            if (getAllContent && getAllContent.status === 200) {
                setIsLoading(false);
                let arr = [];
                if (getAllContent.data?.data?.allContent?.length > 0) {
                    getAllContent.data?.data?.allContent?.forEach((item) => {
                        arr = [...arr, {
                            originalValue: item.id,
                            value: item.title || "",
                            label: item.title || "",
                            type: item.contentType,
                            icon: item.contentType == "video" ? <img src={VIDEO_ICONAvideo} alt="i" /> : <img src={CONTENTDOC} alt="i" />
                        }]
                    })
                }
                setAllContentList(arr)
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const callApis = async () => {
        setIsLoading(true)
        let company = await getCompanyDetails(company_id)
        if (company) setCompanyDetails(company)
        setIsLoading(false)
        if (personalText == 'program') {
            await getCompanyAllProgram()
            await getCompanyRefrent()
            await getAllContentAPI()
            await getLearningMaterial();
        }
    }
    const filterValues = () => {
        setfilterValueData([
            { id: 0, name: t("superadmin.training.active"), key: 'active', value: 'active' },
            { id: 1, name: t("superadmin.training.non_active"), key: 'inactive', value: 'inactive' },
            { id: 2, name: t("superadmin.training.scheduled"), key: 'scheduled', value: 'scheduled' },
            { id: 3, name: 'Obligatorio', key: 'obligatory', value: 'obligatory' },
            { id: 4, name: 'Non Obligatorio', key: 'non-obligatory', value: 'non-obligatory' },
            { id: 5, name: 'Vedi tutti', key: 'all', value: 'all' },
        ])
    }
    useEffect(() => {
        handleTranslationLang();
        filterValues()
    }, []);
    useEffect(() => {
        callApis()
    }, [])
    useEffect(() => {
        if (personalText == "document") {
            const callApi = setTimeout(() => {
                getCompanyDocument(1)
            }, 2000)
            return () => clearTimeout(callApi)
        }
    }, [documentSearchQuery])
    useEffect(() => {
        if (personalText == "video") {
            const callApi = setTimeout(() => {
                getCompanyVideo(1)
            }, 2000)
            return () => clearTimeout(callApi)
        }
    }, [videoSearchQuery])
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (filterShow && ref.current && !ref.current.contains(e.target)) {
                setFilterShow(false);
                setFilteredData([])
                setFilterValue([])
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [filterShow]);

    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-between pagination-list">
                <div className="row w-100">
                    <div className="col-lg-3 col-md-3"></div>
                    <div className="col-lg-4 col-md-4 d-flex justify-content-center">
                        {pages.map((p, index) => {
                            if (p.page === "<") return <div key={index} className="page" onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                            if (p.page === ">") return <div key={index} className="page" onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                            if (p.active) return <div key={index} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                            return (
                                <div className="page" key={index} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                            )
                        })}
                    </div>
                    <div className="col-lg-5 col-md-5 d-flex justify-content-end">
                        <div className="mr-2 d-flex align-items-center buttons-superadmin">
                            <div className="mr-3 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(1)}><span className="small-dot green mr-1"></span><span> {t("superadmin.training.active")}</span></div>
                            <div className="mr-3 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(2)}><span className="small-dot red mr-1"></span><span> {t("superadmin.training.non_active")}</span></div>
                            <div className="mr-3 d-flex justify-content-center align-items-center logout-button" onClick={() => handleStatusFilter(3)}><span className="small-dot yellow mr-1"></span><span> {t("superadmin.training.scheduled")}</span></div>
                            <div className="d-flex justify-content-center align-items-center logout-button" onClick={() => setFilteredData([])}><span className="small-dot blue mr-1"></span><span> {t("superadmin.training.all")}</span></div>

                        </div>
                    </div>
                </div>

            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }
    const closeModal = () => {
        setPdfUrlChange(null)
        setUrlLink(null)
        setCurrentEditing(null)
        setCurrentEditingUrl(null)
        setVideoPopupPage(1);
        setFileObject({
            files:
                [],
            urls:
                []
        })
        setPdfFileObject({
            files:
                [],
            urls:
                []
        })
        setAddProgramModal(false)
        setAddProgramModal2(false)
        setUpdateProgramModal2(false)
        setDocumentProgramsModal(false)
        setDeleteProgramModal(false)
        setAddTextDocumentModal(false)
        setEditorState(EditorState.createEmpty())
        setAddTextDocumentData({ title: "", description: "", text: "", languageType: "it" })
        setActiveDocument({})
        setUpdateProgramData({})
        setCurrentProgram({})
        setNewProgramData({ company_id: props.match.params.company_id, program_name: "", description: "", course: {}, duration: 0, release_date: (new Date() / 1000), show_release_date: moment().format('YYYY-MM-DD'), obligatory: "", status: 0, referent: "" })
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        setAddPdfModal(false)
        setLearningModal(false)
        setAddVideoModal(false)
        setPdfPopupPage(1)
        setArrObj([])
        setContentId(null)
        setPopupLoader1(false)
        setDeleteContentPopUp(false)
        setDeleteVideoPopUp(false)

    }
    const saveLearningData = async () => {
        setLearningModal(false)
    }
    const handleLearningCategory = (index) => {
        setLearningModal(true)
        setSelectIndex(index);
    }

    const handleLearningProgram = (categoryKey, item, isChecked, materialbodyindex) => {
        setSelectedData(prevObjectHandle => {
            const updatedObjectHandle = { ...prevObjectHandle };
            if (!updatedObjectHandle[selectIndex]) {
                updatedObjectHandle[selectIndex] = {};
            }
            if (!updatedObjectHandle[selectIndex][categoryKey]) {
                updatedObjectHandle[selectIndex][categoryKey] = [];
            }
            const categoryArray = updatedObjectHandle[selectIndex][categoryKey];
            // Check if the item is already in the array
            const isItemInArray = categoryArray.includes(item);
            if (isChecked && !isItemInArray) {
                updatedObjectHandle[selectIndex][categoryKey].push(item);
            } else if (!isChecked && isItemInArray) {
                // Remove the item from the array
                updatedObjectHandle[selectIndex][categoryKey] = categoryArray.filter(
                    selectedItem => selectedItem !== item
                );
            }
            return updatedObjectHandle;
        });
        setCheckboxStates((prevState) => ({
            ...prevState,
            [`${categoryKey}_${item}_${materialbodyindex}`]: isChecked,
        }));
    };
    const deleteProgramAPI = async () => {
        setIsLoading(true)
        setDisableDeleteProgramButton(true)
        const payload = currentProgram
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const delete_Data = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACTIVE_DEACTIVE_PROGRAM}`,
                    payload,
                    "",
                    ""
                );
                if (delete_Data && delete_Data.status === 200) {
                    setIsLoading(false);
                    closeModal()
                    getCompanyAllProgram()
                    setDisableDeleteProgramButton(false)
                } else {
                    if (delete_Data.status === 203) {
                        setAlertMessage({ show: true, spinner: false, message: delete_Data.data?.message, class: "alert alert-danger" })
                    }
                    setIsLoading(false);
                    setDisableDeleteProgramButton(false)
                }
            } else {
                setIsLoading(false);
                setDisableDeleteProgramButton(false)
            }
        } catch (error) {
            setIsLoading(false);
            setDisableDeleteProgramButton(false)
        }
    }
    const checkValNewProgramModal1 = () => {
        if (newProgramData.program_name.length < 1 || newProgramData.program_name.length > 150) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_program_name")}`, class: "alert alert-danger" })
        } else if (newProgramData.programNameITA.length < 1 || newProgramData.programNameITA.length > 150) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_program_name")}`, class: "alert alert-danger" })
        }
        else if (newProgramData.description.length < 10 || newProgramData.description.length > 400) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_description")}`, class: "alert alert-danger" })
        } else if (newProgramData.descriptionITA.length < 10 || newProgramData.descriptionITA.length > 400) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_description")}`, class: "alert alert-danger" })
        } else {
            if (newProgramAllCourse?.length < 1) {
                setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.req_course")}`, class: "alert alert-danger" })
                return;
            }
            for (let index of newProgramAllCourse) {
                if (index.english.courseName.length < 3 || index.english.courseName.length > 50) {
                    setAlertMessage({ show: true, spinner: false, message: `Course name is required in english.(minimum 3 and maximum 50 character)`, class: "alert alert-danger" })
                    return;
                } else if (index.italian.courseName.length < 3 || index.italian.courseName.length > 50) {
                    setAlertMessage({ show: true, spinner: false, message: `Il nome del corso è richiesto in italiano.(minimo 3 e massimo 50 caratteri)`, class: "alert alert-danger" })
                    return;
                } else if (index.english.courseDescription.length < 10 || index.english.courseDescription.length > 400) {
                    setAlertMessage({ show: true, spinner: false, message: `Course description is required in english.(minimum 10 and maximum 400 character)`, class: "alert alert-danger" })
                    return;
                } else if (index.italian.courseDescription.length < 10 || index.italian.courseDescription.length > 400) {
                    setAlertMessage({ show: true, spinner: false, message: `La descrizione del corso è richiesta in italiano.(minimo 10 e massimo 400 caratteri)`, class: "alert alert-danger" })
                    return;
                } else if (index.english.content.length !== index.italian.content.length || index.english.content.length < 1) {
                    setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.unequallyContent")}`, class: "alert alert-danger" })
                    return;
                }
            }
            setAddProgramModal(false);
            setAddProgramModal2(true);
        }
    }
    const checkValNewProgramModal2 = () => {
        let status = [1, 2, 3]
        if (!status.includes(newProgramData.status)) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_state")}`, class: "alert alert-danger" })
        } else if (!newProgramData.referent) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_reference")}`, class: "alert alert-danger" })
        } else if (!newProgramData.obligatory) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_obligatory")}`, class: "alert alert-danger" })
        }
        else {
            setAlertMessage({ show: true, spinner: true, message: `${t("superadmin.training.popup.wait")}`, class: "alert alert-info" })
            addNewProgramAPI();
        }
    }
    const checkValUpdateProgramModal2 = () => {
        let status = [1, 2, 3]
        if (!status.includes(updateProgramData.status)) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_state")}`, class: "alert alert-danger" })
        } else if (!updateProgramData.referent) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_reference")}`, class: "alert alert-danger" })
        } else if (!updateProgramData.obligatory) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_obligatory")}`, class: "alert alert-danger" })
        }
        else {
            setAlertMessage({ show: true, spinner: true, message: `${t("superadmin.training.popup.wait")}`, class: "alert alert-info" })
            updateProgramAPI()
        }
    }
    const handleChange = (event) => {
        let { name, value } = event.target;
        if (name == 'status') {
            value = Number(value)
            if (value != 3) setNewProgramData({ ...newProgramData, [name]: value, release_date: (new Date() / 1000), show_release_date: moment().format('YYYY-MM-DD') });
            else setNewProgramData({ ...newProgramData, [name]: value })
        } else {

            setNewProgramData({ ...newProgramData, [name]: value });
        }
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    };
    const handleChangeUpdate = (event) => {
        let { name, value } = event.target;
        if (name == 'status') {
            value = Number(value)
            if (value == 3) setUpdateProgramData({ ...updateProgramData, [name]: value });
            else setUpdateProgramData({ ...updateProgramData, [name]: value, release_date: (new Date() / 1000), show_release_date: moment().format('YYYY-MM-DD') });
        } else {
            setUpdateProgramData({ ...updateProgramData, [name]: value });
        }
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    };

    const handleSelectContent = (item, i, lang) => {
        setAlertMessage({})
        let arr = [];
        item?.forEach((d1) => {
            let content = allContentList?.find((d2) => d2.originalValue == d1.originalValue)
            arr = [...arr, { value: d1.originalValue, icon: content.icon, label: d1.label?.length > 20 ? `${d1.label?.substr(0, 20)}...` : d1.label, type: content.type, title: content?.label, originalValue: d1.originalValue }]
        })
        let arr2 = newProgramAllCourse;
        arr2[i][lang].content = arr
        setNewProgramAllCourse(() => [...arr2])
    }
    const handleAddCourse = (e) => {
        e.preventDefault();
        setNewProgramAllCourse([...newProgramAllCourse,
        {
            english: { courseName: "", courseDescription: "", content: [] },
            italian: { courseName: "", courseDescription: "", content: [] }
        }
        ])
    }
    const handleChangeCourse = (e, i, lang) => {
        setAlertMessage({})
        let arr = newProgramAllCourse.slice(0);
        arr[i][lang][e.target.name] = e.target.value;
        setNewProgramAllCourse([...arr])
    }
    const removeCourse = (e, i) => {
        e.preventDefault()
        let arr = newProgramAllCourse.slice(0);
        let arr2 = []
        arr.forEach((item, j) => {
            if (j != i) arr2 = [...arr2, item]
        })
        setNewProgramAllCourse([...arr2])
    }
    const addProgramModalContent = () => {
        return (
            <div className="model-pop-new " >
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <strong>{t("superadmin.training.popup.add_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { setNewProgramAllCourse([]); closeModal() }}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="" >
                            <form className="registrati-form registrati-form-new nuovo-programma">
                                <div className="form-group">
                                    <div>
                                        <label className="form-label">{t("superadmin.training.popup.name_program")}</label>
                                        <table className="new-program-name">

                                            <tr>
                                                <td> <span className="mr-2">English</span></td>
                                                <td className="w-100"><input type="text" className="form-control placeholder-design" name="program_name" placeholder="Write title"
                                                    value={newProgramData.program_name} onChange={handleChange} />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><span className="mr-3">Italian</span></td>
                                                <td className="w-100"><input type="text" className="form-control placeholder-design" name="programNameITA" placeholder="Scrivi titolo"
                                                    value={newProgramData.programNameITA} onChange={handleChange} />
                                                </td>

                                            </tr>
                                        </table>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">{t("superadmin.training.popup.description")} ( max 400 {t("superadmin.training.popup.characters")})</label>
                                        <div>
                                            <span className="">English</span>
                                            <textarea type="text" name="description" className="form-control placeholder-design mt-2" placeholder="Write Description"
                                                value={newProgramData.description} onChange={handleChange} />
                                        </div>
                                        <div className="mt-3">
                                            <span className="">Italian</span>
                                            <textarea type="text" name="descriptionITA" className="form-control placeholder-design mt-2" placeholder="Scrivi descrizione"
                                                value={newProgramData.descriptionITA} onChange={handleChange} />
                                        </div>
                                    </div>
                                    {newProgramAllCourse?.map((d, i) => {
                                        return (
                                            <div key={i} className="mt-2">
                                                <div className="d-flex justify-content-between">
                                                    <label className="form-label">{t("superadmin.training.popup.path")} {i + 1}</label>
                                                    <button onClick={(e) => removeCourse(e, i)} className="cursor-pointer align-items-center bg-transparent"><img src={DeleteBin} alt="icon" /></button>
                                                </div>
                                                <div>
                                                    <span className="mr-2">English</span>
                                                    <input type="text" className="form-control placeholder-design my-2" name="courseName" placeholder="Write name"
                                                        value={newProgramAllCourse[i]["english"].courseName} onChange={(e) => handleChangeCourse(e, i, "english")} />
                                                    <textarea type="text" name="courseDescription" className="form-control placeholder-design" placeholder="Write Description"
                                                        value={newProgramAllCourse[i]["english"].courseDescription} onChange={(e) => handleChangeCourse(e, i, "english")} />
                                                    <div className="dropdown-container mt-2" id="content-select">
                                                        <Select
                                                            options={allContentList}
                                                            placeholder="Select contents"
                                                            components={{ DropdownIndicator: () => <div className="mr-2"><img src={VectorDown} alt="icon" /></div> }}
                                                            value={d.english.content}
                                                            onChange={(item) => {
                                                                handleSelectContent(item, i, "english")
                                                            }
                                                            }
                                                            isSearchable={true}
                                                            getOptionLabel={e => (
                                                                <div className="dropdown-block">
                                                                    <span className={`${e.type == 'video' ? 'video-margin' : 'document-margin'}`} >{e.icon}</span>
                                                                    <span className="dropdown-label">{e.label}</span>
                                                                </div>
                                                            )}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <span className="">Italian</span>
                                                    <input type="text" className="form-control placeholder-design my-2" name="courseName" placeholder="Scrivi nome"
                                                        value={newProgramAllCourse[i]["italian"].courseName} onChange={(e) => handleChangeCourse(e, i, "italian")} />
                                                    <textarea type="text" name="courseDescription" className="form-control placeholder-design" placeholder="Scrivi descrizione"
                                                        value={newProgramAllCourse[i]["italian"].courseDescription} onChange={(e) => handleChangeCourse(e, i, "italian")} />
                                                    <div className="dropdown-container mt-2" id="content-select">
                                                        <Select
                                                            options={allContentList}
                                                            placeholder="Seleziona contenuti"
                                                            components={{ DropdownIndicator: () => <div className="mr-2"><img src={VectorDown} alt="icon" /></div> }}
                                                            value={d.italian.content}
                                                            onChange={(item) => handleSelectContent(item, i, "italian")}
                                                            isSearchable={true}
                                                            getOptionLabel={e => (
                                                                <div className="dropdown-block">
                                                                    <span className={`${e.type == 'video' ? 'video-margin' : 'document-margin'}`} >{e.icon}</span>
                                                                    <span className="dropdown-label">{e.label}</span>
                                                                </div>
                                                            )}
                                                            isMulti
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                                <div className="buttons-superadmin">
                                    <button className="buttons w-100" onClick={(e) => handleAddCourse(e)}>{t("superadmin.training.popup.add")} {t("superadmin.training.popup.path")}</button>
                                </div>
                            </form>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-1">
                            <button className="buttons modal-button mr-3" onClick={() => { setNewProgramAllCourse([]); closeModal() }}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => checkValNewProgramModal1()}>{t("superadmin.training.popup.end")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const addProgramModal2Content = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <strong>{t("superadmin.training.popup.add_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            <form className="registrati-form registrati-form-new nova-program">
                                <div className="form-group">
                                    <div>
                                        <label className="form-label">{t("superadmin.training.popup.state")}</label>
                                        <div className="d-flex">
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${newProgramData.status == 1 ? "active-green" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={1}
                                                        name="status"
                                                        checked={newProgramData.status == 1}
                                                        onChange={handleChange}
                                                    />
                                                    <span>{t("superadmin.training.active")}</span>
                                                </label>
                                            </div>
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${newProgramData.status == 2 ? "active-red" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={2}
                                                        name="status"
                                                        checked={newProgramData.status == 2}
                                                        onChange={handleChange}
                                                    />
                                                    <span>{t("superadmin.training.non_active")}</span>
                                                </label>
                                            </div>
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${newProgramData.status == 3 ? "active-yellow" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={3}
                                                        name="status"
                                                        checked={newProgramData.status == 3}
                                                        onChange={handleChange}
                                                    />
                                                    <span>{t("superadmin.training.scheduled")}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">{t("superadmin.training.popup.referent")}</label>
                                        <div className="">
                                            <select
                                                type="text"
                                                name="referent"
                                                className="form-control select-border"
                                                onChange={(e) => { setNewProgramData({ ...newProgramData, referent: e.target.value }); setAlertMessage({}) }}
                                                value={newProgramData.referent}
                                            >
                                                <option value="">{t("superadmin.training.popup.select_reference")}</option>
                                                {refrentData.map((opts, i) => (
                                                    <option key={i} value={opts.name}>
                                                        {opts.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3">
                                        <div className="mr-3">
                                            <label className="form-label">{t("superadmin.training.duration")}</label>
                                            <select type="text" className="form-control select-border" value={newProgramData.duration} onChange={(e) => { setNewProgramData({ ...newProgramData, duration: Number(e.target.value) }); setAlertMessage({}) }}>

                                                {durationCount.map((data, index) => {
                                                    return <option key={index} value={data}>{data}</option>
                                                })}
                                            </select>


                                        </div>
                                        <div className={!(newProgramData.status == 3) ? "disable-program-release" : ""}>
                                            <label className="form-label">{t("superadmin.training.popup.scheduled_release")}</label>
                                            <input
                                                type="date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                name="release_date"
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                data-date-format="DD.MM.YYYY"
                                                aria-describedby="emailHelp"
                                                value={newProgramData.show_release_date}
                                                onChange={(e) => { setNewProgramData({ ...newProgramData, release_date: new Date(e.target.value).getTime() / 1000, show_release_date: e.target.value }); setAlertMessage({}) }}
                                                min={moment().format('YYYY-MM-DD')}
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-3 obbligatorieta-check">
                                        <label className="form-label">{t("superadmin.training.mandatory")}</label>
                                        <div className="d-flex">
                                            <label className={`mr-4 check-radiotype ${newProgramData.obligatory == "Yes" ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value="Yes"
                                                    name="obligatory"
                                                    checked={newProgramData.obligatory == "Yes"}
                                                    onChange={handleChange}
                                                />
                                                <span>{t("superadmin.training.yes")}</span>
                                            </label>
                                            <label className={`check-radiotype ${newProgramData.obligatory == "No" ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value="No"
                                                    name="obligatory"
                                                    checked={newProgramData.obligatory == "No"}
                                                    onChange={handleChange}
                                                />
                                                <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-5" >
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button mr-3" onClick={() => { setAddProgramModal2(false); setAddProgramModal(true); }}>{t("user.Questionnare.backwards")}</button>
                            <button className="buttons modal-button " disabled={disableAddNewProgramButton} onClick={() => checkValNewProgramModal2()}>{t("superadmin.training.popup.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const upadateProgramModal2Content = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.training.popup.edit_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">

                            {popupLoader2 ? <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div> : null}
                            <form className="registrati-form registrati-form-new nova-program">
                                <div className="form-group">
                                    <div>
                                        <label className="form-label">{t("superadmin.training.popup.state")}</label>
                                        <div className="d-flex">
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${updateProgramData.status == 1 ? "active-green" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={1}
                                                        name="status"
                                                        checked={updateProgramData.status == 1}
                                                        onChange={handleChangeUpdate}
                                                    />
                                                    <span>{t("superadmin.training.active")}</span>
                                                </label>
                                            </div>
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${updateProgramData.status == 2 ? "active-red" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={2}
                                                        name="status"
                                                        checked={updateProgramData.status == 2}
                                                        onChange={handleChangeUpdate}
                                                    />
                                                    <span>{t("superadmin.training.non_active")}</span>
                                                </label>
                                            </div>
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${updateProgramData.status == 3 ? "active-yellow" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={3}
                                                        name="status"
                                                        checked={updateProgramData.status == 3}
                                                        onChange={handleChangeUpdate}
                                                    />
                                                    <span>{t("superadmin.training.scheduled")}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">{t("superadmin.training.popup.referent")}</label>
                                        <div className="mt-3">
                                            <select
                                                type="text"
                                                name="referent"
                                                className="form-control select-border"
                                                onChange={(e) => { setUpdateProgramData({ ...updateProgramData, referent: e.target.value }); setAlertMessage({}) }}
                                                value={updateProgramData.referent}
                                            >
                                                <option value="">{t("superadmin.training.popup.select_reference")}</option>
                                                {refrentData.map((opts, i) => (
                                                    <option key={i} value={opts.name}>
                                                        {opts.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="d-flex mt-3">
                                        <div className="mr-3">
                                            <label className="form-label">{t("superadmin.training.duration")}</label>
                                            <select type="text" className="form-control select-border" value={updateProgramData.duration} onChange={(e) => { setUpdateProgramData({ ...updateProgramData, duration: e.target.value }); setAlertMessage({}) }}>
                                                {durationCount.map((data, index) => {
                                                    return <option key={index} value={data}>{data}</option>
                                                })}
                                            </select>

                                        </div>
                                        <div className={!(updateProgramData.status == 3) ? "disable-program-release" : ""}>
                                            <label className="form-label">{t("superadmin.training.popup.scheduled_release")}</label>
                                            <input
                                                type="date"
                                                onKeyDown={(e) => e.preventDefault()}
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                data-date-format="DD.MM.YYYY"
                                                aria-describedby="emailHelp"
                                                value={updateProgramData.show_release_date}
                                                onChange={(e) => { setUpdateProgramData({ ...updateProgramData, release_date: new Date(e.target.value).getTime() / 1000, show_release_date: e.target.value }); setAlertMessage({}) }}
                                                min={moment().format('YYYY-MM-DD')}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3 obbligatorieta-check">
                                        <label className="form-label">{t("superadmin.training.mandatory")}</label>
                                        <div className="d-flex">
                                            <label className={`mr-4 check-radiotype ${updateProgramData.obligatory == "Yes" ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value="Yes"
                                                    name="obligatory"
                                                    checked={updateProgramData.obligatory == "Yes"}
                                                    onChange={handleChangeUpdate}
                                                />
                                                <span>{t("superadmin.training.yes")}</span>
                                            </label>
                                            <label className={`check-radiotype ${updateProgramData.obligatory == "No" ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value="No"
                                                    name="obligatory"
                                                    checked={updateProgramData.obligatory == "No"}
                                                    onChange={handleChangeUpdate}
                                                />
                                                <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-3" >
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableUpdateProgramButton} onClick={() => checkValUpdateProgramModal2()}>{t("superadmin.training.popup.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const deleteProgramModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Delete_Icon_popup} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.delete_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {alertMessage.show ? <div className="d-flex alert alert-info">
                                {alertMessage.spinner ? <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                    : null}
                                <div className="ml-1">{t("superadmin.training.popup.wait")} </div>
                            </div> : null}
                            <h6 className="visi-project">{t("superadmin.training.popup.delete_program_content")} "{currentProgram?.program_name}"?</h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableDeleteProgramButton} onClick={() => { deleteProgramAPI(); setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" }) }}>{t("superadmin.protocol.popup.delete")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const documentProgramsModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <strong>{t("superadmin.training.popup.associated_programs")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                (activeDocument.programs && activeDocument.programs?.length) ?
                                    <div className="">
                                        {activeDocument.programs.map((data, i) => {
                                            return (
                                                <a href={'/sa/training/' + company_id + '/' + data.id}>
                                                    <div className="document-program-details" key={i}>
                                                        <div className="name">{data.name}</div>
                                                        <div className="description">{data.description}</div>
                                                    </div>
                                                </a>
                                            )
                                        })}
                                    </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const addTextDocumentAPI = async () => {
        setDisableAddTextDocumentButton(true)
        let payload = {
            ...addTextDocumentData,
            title: addTextDocumentData.title,
            contentType: "document",
        }

        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const createDocument = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CREATE_TEXT_DOCUMENT}`,
                    payload,
                    "",
                    ""
                );
                if (createDocument && createDocument.status === 200) {
                    setIsLoading(false);
                    setAlertMessage({ show: true, spinner: false, message: createDocument.data?.message, class: "alert alert-success" })
                    setDisableAddTextDocumentButton(false)
                    setTimeout(() => {
                        closeModal()
                        window.location.reload();
                    }, 2000)
                } else {
                    if (createDocument.status === 203) {
                        setAlertMessage({ show: true, spinner: false, message: createDocument.data?.message, class: "alert alert-danger" })
                    }
                    setIsLoading(false);
                    setDisableAddTextDocumentButton(false)
                }
            } else {
                setIsLoading(false);
                setDisableAddTextDocumentButton(false)
            }
        } catch (error) {
            setIsLoading(false);
            setDisableAddTextDocumentButton(false)
        }
    }
    const handleChangeAddTextDocument = (event) => {
        let { name, value } = event.target;
        if (name == 'status') value = Number(value)
        setAddTextDocumentData({ ...addTextDocumentData, [name]: value })
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    }
    const addTextDocumentValCheck = () => {
        if (addTextDocumentData.title.length < 10 || addTextDocumentData.title.length > 50) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_document_title")}`, class: "alert alert-danger" })
        } else if (addTextDocumentData.description.length < 10 || addTextDocumentData.description.length > 400) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_document_description")}`, class: "alert alert-danger" })
        }
        else {
            setAlertMessage({ show: true, spinner: true, message: `${t("superadmin.training.popup.wait")}`, class: "alert alert-info" })
            addTextDocumentAPI();
        }
    }



    //Learning material modal

    const editorStateFunc = (state) => {
        setEditorState(state)
        setAddTextDocumentData({ ...addTextDocumentData, text: draftToHtml(convertToRaw(state.getCurrentContent())) })
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    }
    const addTextDocumentModalContent = () => {
        return (
            <div className="model-pop-new " >
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <img src={DOCUMENT_ICON} alt="icon" />
                            <strong className="ml-3">{t("superadmin.training.popup.add_text_document_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="" >
                            <form className="registrati-form registrati-form-new nuovo-programma">

                                <div className="form-group">
                                    <div class="switch-field">
                                        <input type="radio" id="radio-one" onChange={handleChangeAddTextDocument} name="languageType" value="it" checked={addTextDocumentData.languageType == "it"} />
                                        <label for="radio-one">Italian</label>
                                        <input type="radio" id="radio-two" onChange={handleChangeAddTextDocument} name="languageType" value="en" checked={addTextDocumentData.languageType == "en"} />
                                        <label for="radio-two">English</label>
                                    </div>
                                    <div>
                                        <label className="form-label">{t("superadmin.training.popup.document_name")}</label>
                                        <input type="text" className="form-control place-opacity" name="title" placeholder={t("superadmin.training.popup.title_placeholder")}
                                            value={addTextDocumentData.title} onChange={handleChangeAddTextDocument} />
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">{t("superadmin.training.popup.description")} </label>
                                        <textarea type="text" name="description" className="form-control place-opacity" placeholder={t("superadmin.training.popup.description_placeholder")}
                                            value={addTextDocumentData.description} onChange={handleChangeAddTextDocument} />
                                    </div>
                                    <div className="mt-3" >
                                        <label className="form-label">{t("superadmin.training.popup.text")} </label>
                                        <Editor
                                            placeholder={t("superadmin.training.popup.text_placeholder")}
                                            name="text"
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            editorStyle={{ minHeight: "100px", opacity: "1" }}
                                            onEditorStateChange={editorStateFunc}
                                            toolbar={{
                                                options: ['blockType', 'link', 'colorPicker', 'inline', "image", 'textAlign', 'list', 'history'],
                                                blockType: {
                                                    inDropdown: true,
                                                    options: ['Normal', "H1", "H2", 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                    className: "blockType-class",
                                                    dropdownClassName: "blockType-dropdown-class"
                                                },
                                                inline: {
                                                    inDropdown: false,
                                                    options: ['bold', 'italic', 'underline', 'strikethrough']
                                                },
                                                list: {
                                                    inDropdown: false,
                                                    options: ['unordered', 'ordered']
                                                },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true }
                                            }}
                                        />
                                    </div>

                                </div>
                            </form>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div> : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-1">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableAddTextDocumentButton} onClick={() => addTextDocumentValCheck()}>{t("superadmin.training.popup.load")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const changeFilter = () => {
        setFilterShow(!filterShow);
        setFilteredData(programData)
    };
    const handleStatusFilter = (value) => {
        let arr = programData.filter((d) => d.status == value)
        if (arr.length > 0) setFilteredData(arr)
        else { setFilteredData([{ name: "" }]) }
    }
    const onChangeFilter = (e) => {
        const value = e.target.value;
        const status = e.target.checked;
        let arr = []
        if (status) {
            let temp = filterValue;
            temp.push(value);
            arr = temp;
            setFilterValue(temp);
        } else {
            let temp1 = filterValue;
            temp1 = temp1.filter(ele => ele !== value)
            arr = temp1;
            setFilterValue(temp1);
        }
        refreshTable(arr);
    };

    const refreshTable = (arr) => {

        let tempData = [];
        programData.forEach((element) => {
            if (arr.includes("active")) {
                if (element.status === 1 && !filteredData.includes(element.program_id)) {
                    tempData = [...tempData, element]
                }
            }
            if (arr.includes("inactive")) {
                if (element.status === 2 && !filteredData.includes(element.program_id)) {
                    tempData = [...tempData, element]
                }
            }
            if (arr.includes("scheduled")) {
                if (element.status === 3 && !filteredData.includes(element.program_id)) {
                    tempData = [...tempData, element]
                }
            }
            if (arr.includes("obligatory")) {
                if (element.status === 1 && element.obligatory === "Yes" && filteredData.includes(element.program_id)) {
                    tempData = [...tempData, element]
                }
            }
            if (arr.includes("non-obligatory")) {
                if (element.status === 1 && element.obligatory === "No" && filteredData.includes(element.program_id)) {
                    tempData = [...tempData, element]
                }
            }
        });
        setFilteredData(tempData);
    };
    const resetFilters = () => {
        setFilterShow(!filterShow);
        setFilteredData(programData)
    };
    const handlePageClick = (event) => {
        let page = event.selected + 1
        setCurrentPage(page)
        getCompanyDocument(page)
    };
    const handlePageClickVideo = (event) => {
        let page = event.selected + 1
        setCurrentPage(page)
        getCompanyVideo(page)
    }

    const handleFileSelect = (e) => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        const selectedFiles = Array.from(e.target.files);
        let totalSize = 0;
        if (selectedFiles.length < maxFile) {
            for (const allFiles of selectedFiles) {
                totalSize += allFiles.size;
            }
        }

        if (totalSize <= fileSize) {
            setFiles(selectedFiles);
        }
        else {
            setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.file_error_msg"), class: "alert alert-danger" })
            fileUpload.current.value = "";
            fileUpload.current.type = "text";
            fileUpload.current.type = "file";
        }

    };

    // Function to handle file drop
    const handleDrop = (e) => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        let totalSize = 0;
        if (droppedFiles.length < maxFile) {
            for (const dropFile of droppedFiles) {
                totalSize += dropFile.size;
            }
        }

        if (totalSize <= fileSize) {
            setFiles(droppedFiles);
        }
        else {
            setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.file_error_msg"), class: "alert alert-danger" })
        }

    };

    useEffect(() => {
        fileArr()
    }, [files]);


    const getExtension = (filename) => {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }

    const isVideo = (file) => {
        var ext = getExtension(file.name);
        let newData = fileObject.files;
        let allUrls = fileObject.urls;
        fileObject.languageType = newProgramData.languagetype;
        switch (ext.toLowerCase()) {
            case 'm4v':
            case 'avi':
            case 'mpg':
            case 'mp4':
            case 'webm':
                newData?.push({ title: file.name, description: "", uploadAttempts, size: file.size, fileType: "video", language: programData.languagetype })
                setFileObject({ languageType: fileObject.languageType, files: newData, urls: allUrls });

                return true;
        }
        return false;
    }

    const fileArr = () => {
        if (files?.length > 0) {
            for (const file of files) {
                isVideo(file)
            }
            let formData = new FormData();
            formData.append('filesType', 2);
            files?.forEach((singleFile, index) => {
                formData.append(`files`, singleFile);
            });
            setUploadAttempts(uploadAttempts + 1);
            fileUploadApi(formData, (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            }).then((response) => {
                setAlertMessage({ show: false, spinner: false, message: "", class: "" })
                if (response?.status == 200) {
                    const fileRes = response?.data?.data;
                    if (fileRes.length > 0) {
                        fileRes?.forEach((item, index) => {
                            fileObject?.files?.forEach((data, id) => {
                                if ((data.title == item.originalFilename) && (data.uploadAttempts == (uploadAttempts))) {
                                    data.filePath = item.fileName
                                }
                            })

                        })
                    }
                    setVideoPopupPage(3);
                    setIsDisabled(false)
                    resetFileSelector();
                } else {
                    resetFileSelector();
                    setAlertMessage({ show: true, spinner: false, message: response.data.message, class: "alert alert-danger" })
                }

            })
        }
    }


    const resetFileSelector = () => {
        setProgress('');
        setFiles([]);
        setPdfFile([]);
    }

    const handleDragStart = (event) => {
        console.log('event')
    }

    const handleUpload = () => {
        fileUpload.current.click();
    };

    function formatFileSize(bytes, decimalPoint) {
        if (bytes == 0) return '0 Bytes';
        let k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const handleUrlChange = async (e) => {
        if (e.target.value == null || e.target.value == '') {
            setIsDisabled(true)
        }
        else {
            let urlValue = (e.target.value)
            urlPatternValidation(urlValue)
        }

    }

    const urlPatternValidation = (URL) => {
        if (URL != '') {
            const regexData = urlRegex.test(URL);
            if (regexData) {
                setUrlLink(URL)
                setPdfUrlChange(URL)
                setIsDisabled(false)
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
            } else {
                setIsDisabled(true)
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.regexUrl"), class: "alert alert-danger" })
            }
        }

    };

    useEffect(() => {
        if (urlLink != null && urlLink != '') {
            fetchTitleAndDescription(urlLink)
        }
    }, [urlLink])

    const handleInsertLink = (e) => {
        setIsDisabled(true)
        setVideoPopupPage(2)
        setUrlLink(null)
    }

    const handleUploadUrl = () => {
        // for Video popup
        let newData = fileObject.files;
        let allUrls = fileObject.urls;
        allUrls?.push({ title: title ? title : urlLink, description: description ? description : '', url: urlLink, filesType: 'video', favicon: favIcon })
        setFileObject({ files: newData, urls: allUrls });
        setVideoPopupPage(3)
        setUrlLink(null)
        setPdfUrlChange(null)
    }

    const handleEditVideo = (event, fileId) => {
        setIsDisabled(false)
        setCurrentEditing(fileId);

        // for Video
        setVideoPopupPage(4)
        // fileObject.files[currentEditing]?.title;
        // fileObject.files[currentEditing]?.size;
        // fileObject.files[currentEditing]?.desciption;


    }

    const handleRemoveVideo = (e, id, type) => {
        const newPdfData = fileObject.files
        const newUrlData = fileObject.urls
        if (type == 'video') {
            let remainingData = newPdfData?.filter((item, index) => {
                return index != id
            })
            setFileObject({ files: remainingData, urls: newUrlData })
        }
        else if (type == 'url') {
            let remainingData = newUrlData?.filter((item, index) => {
                return index != id
            })
            setFileObject({ files: newPdfData, urls: remainingData })
        }
    }

    const handleEditUrl = (url, urlId) => {
        setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
        setCurrentEditingUrl(urlId)
        // for video
        setVideoPopupPage(4)
        // fileObject.urls[currentEditing]?.title;
        // fileObject.urls[currentEditing]?.desciption;

    }

    const handleEditChange = (e) => {
        setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
        let nameofText = e.target.name
        let valueofText = e.target.value
        let newData = fileObject.files;
        let allurls = fileObject.urls;

        if (nameofText === 'title') {
            if (valueofText == '') {
                setIsDisabled(true)
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.enter-title"), class: "alert alert-danger" })
                newData[currentEditing].title = valueofText;
            } else if (valueofText.length < 3) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.title_limit_message"), class: "alert alert-danger" })
                newData[currentEditing].title = valueofText;
                setIsDisabled(true)
            }
            else {
                setIsDisabled(false)
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
                newData[currentEditing].title = valueofText;
            }
        }
        if (nameofText === 'description') {
            if (valueofText.length > 1000) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.description_limit_message"), class: "alert alert-danger" })
                newData[currentEditing].description = valueofText;
                setIsDisabled(true)
            } else {
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
                newData[currentEditing].description = valueofText;
                setIsDisabled(false)
            }
        }
        // Descritpion Validation
        fileObject.files.forEach((data) => {
            if (data.description && data.description.length > 1000) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.description_limit_message"), class: "alert alert-danger" })
                setIsDisabled(true)
            }
        });
        setFileObject({ files: newData, urls: allurls });
    }

    const handleUrl = (e) => {
        let nameofText = e.target.name
        let valueofText = e.target.value
        let newData = fileObject.files;
        let allurls = fileObject.urls;
        if (nameofText === 'title') {
            if (valueofText == '') {
                setIsDisabled(true)
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.enter-title"), class: "alert alert-danger" })
                allurls[currentEditingUrl].title = valueofText;
            } else if (valueofText.length < 3) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.title_limit_message"), class: "alert alert-danger" })
                allurls[currentEditingUrl].title = valueofText;
                setIsDisabled(true)
            }
            else {
                setIsDisabled(false)
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
                allurls[currentEditingUrl].title = valueofText;
            }
        }
        if (nameofText === 'description') {
            if (valueofText.length > 1000) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.description_limit_message"), class: "alert alert-danger" })
                allurls[currentEditingUrl].description = valueofText;
                setIsDisabled(true)
            } else {
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
                allurls[currentEditingUrl].description = valueofText;
                setIsDisabled(false)
            }
        }
        // Descritpion Validation
        fileObject.urls.forEach((data) => {
            if (data.description && data.description.length > 1000) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.description_limit_message"), class: "alert alert-danger" })
                setIsDisabled(true)
            }
        });
        setFileObject({ files: newData, urls: allurls });

    }

    const handleEditConfirm = (e) => {
        setVideoPopupPage(3)
        setPdfPopupPage(3)
        setCurrentEditing(null)
        setCurrentEditingUrl(null)
    }




    const fetchTitleAndDescription = async (url) => {
        if (url != '') {
            try {
                const response = await fetch(url);
                const htmlContent = await response.text();


                const tempElement = document.createElement('div');
                tempElement.innerHTML = htmlContent;

                // for Favicon
                const faviconElement = tempElement.querySelector('link[rel="icon"]');
                const faviconURL = faviconElement ? faviconElement.getAttribute('href') : '';

                // for title
                const titleElement = tempElement.querySelector('title');
                const title = titleElement ? titleElement.innerText : '';
                setTitle(title)

                // for description
                const descriptionElement = tempElement.querySelector('meta[name="description"]');
                const description = descriptionElement ? descriptionElement.getAttribute('content') : '';
                setDescription(description)

                // for host-origin
                const parsedURL = new URL(url);
                const hostName = parsedURL.origin;
                setFavIcon(`${hostName}${faviconURL}`)

                return true
            } catch (error) {
                setTitle(url);
                setDescription('')
                return null
            }
        }

    }


    const handleFinalData = async () => {
        setIsLoading(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const uploadFinalVideoData = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CONTENT_UPLOAD}`,
                    fileObject,
                    "",
                    ""
                );
                if (uploadFinalVideoData.status == 200) {
                    setIsLoading(false)
                    setAlertMessage({ show: true, spinner: false, message: uploadFinalVideoData.data.message, class: "alert alert-danger" })
                    getCompanyVideo(1)
                    closeModal()
                } else {
                    setIsLoading(false)
                    setAlertMessage({ show: true, spinner: false, message: uploadFinalVideoData.data.message, class: "alert alert-danger" })
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    }


    const addVideoModalContent = () => {
        return (
            <div className="model-pop-new ">
                <div className="model-pop ">
                    <div className="modal-header">
                        <div className="video-heading-img text-center">
                            <p className="text-center ml-1">
                                <img src={UPLOAD_ICONA} alt="" className="p-1" />
                            </p>
                        </div>
                        <h5 className="modal-title pop_heading ml-2" id="exampleModalLabel">
                            <strong>
                                {t("superadmin.training.popup.add_video_heading")}
                            </strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    {alertMessage.show ? (
                        <div className={`d-flex mt-3 ${alertMessage.class}`}>
                            {alertMessage.spinner ? (
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new"></div>
                                </div>
                            ) : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div>
                    ) : null}
                    {isLoading ? <LazyLoader /> : null}
                    <div className="modal-body .upload-pop">
                        <div className="">
                            <form className="registrati-form registrati-form-new nuovo-programma">
                                <div className="form-group">
                                    <div>
                                        {videoPopupPage === 1 ? (
                                            <>

                                                <div class="switch-field">
                                                    <input type="radio" id="radio-one" onChange={handleChange} name="languagetype" value="it" checked={newProgramData.languagetype == 'it'} />
                                                    <label for="radio-one">Italian</label>
                                                    <input type="radio" id="radio-two" onChange={handleChange} name="languagetype" value="en" checked={newProgramData.languagetype == 'en'} />
                                                    <label for="radio-two">English</label>
                                                </div>
                                                <div className="progressBar">
                                                    {(showProgressBar == true) || progress && <ProgressBar now={progress} label={`${progress}%`} />}
                                                </div>
                                                <div
                                                    className="drop-area"
                                                    draggable='true'
                                                    onDragOver={(e) => e.preventDefault()}
                                                    onDrop={handleDrop}
                                                >
                                                    <div draggable="true" onDragStart={handleDragStart}>
                                                        <div className="row">
                                                            <div className="col-sm-6 d-flex">
                                                                {files && (
                                                                    <div className="video-block">
                                                                        <div className="inner-video-block">
                                                                            <div className="video-img-block">
                                                                                <p>
                                                                                    <img src={Group1333} alt="" />
                                                                                </p>
                                                                            </div>
                                                                            <div className="choose-file">
                                                                                <input
                                                                                    type="file"
                                                                                    multiple
                                                                                    accept="video/*"
                                                                                    hidden
                                                                                    onChange={handleFileSelect}
                                                                                    ref={fileUpload}
                                                                                />

                                                                            </div>
                                                                            <div className="video-popup-body">
                                                                                <strong>
                                                                                    {t(
                                                                                        "superadmin.training.popup.video_popup_body"
                                                                                    )}
                                                                                </strong>
                                                                            </div>
                                                                            <div className="video-upload-btn">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => handleUpload()}
                                                                                    className="buttons modal-button"
                                                                                >
                                                                                    {t(
                                                                                        "superadmin.training.button.upload_video"
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="col-sm-6 d-flex">
                                                                <div className="video-block">
                                                                    <div className="inner-video-block">
                                                                        <div className="url-width">
                                                                            <div className="video-img-block">
                                                                                <img src={Group1857PDF} alt="" />
                                                                            </div>
                                                                            <div className="video-popup-body">
                                                                                <strong>
                                                                                    {t(
                                                                                        "superadmin.training.popup.urlpopup-body"
                                                                                    )}
                                                                                </strong>
                                                                            </div>
                                                                            <div className="video-upload-btn">
                                                                                <button
                                                                                    type="button"
                                                                                    className="buttons modal-button"
                                                                                    onClick={(e) => handleInsertLink(e)}
                                                                                >
                                                                                    {t("superadmin.training.button.upload_url")}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : videoPopupPage == 2 ? (
                                            <>
                                                <div className="url-block-section">
                                                    <div className="url-block-content">
                                                        <p className="url-input">
                                                            <input
                                                                type="text"
                                                                name="url"
                                                                id=""
                                                                placeholder={t(
                                                                    "superadmin.training.popup.place_holder_url"
                                                                )}
                                                                onChange={handleUrlChange}
                                                            />
                                                        </p>
                                                        <p className="url-btn">
                                                            <button
                                                                disabled={isDisabled}
                                                                type="button"
                                                                className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                                                onClick={handleUploadUrl}
                                                            >
                                                                {t("superadmin.training.button.url_button")}
                                                            </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : videoPopupPage == 3 ? (
                                            <>
                                                <div className="url-block">
                                                    {fileObject?.files && fileObject?.files?.length > 0 && fileObject?.files?.map((file, index) => (
                                                        <>
                                                            <div key={index} className="cancel-btn-content">
                                                                <button
                                                                    onClick={(e) => { handleRemoveVideo(e, index, 'video') }}
                                                                    type="button"
                                                                    className="cancel-btn cursor-pointer"
                                                                >
                                                                    <img src={Group769} alt="icon" />
                                                                </button>
                                                                <div className="modify-video mt-3" key={index}>
                                                                    <div className="video-icon-file">
                                                                        <button
                                                                            type="button"
                                                                            className="video-icon"
                                                                        >
                                                                            <img src={VIDEO_ICONAvideo} alt="icon" />
                                                                        </button>

                                                                        <p>
                                                                            {file.title.slice(0, 30) + (file.title.length > 30 ? "..." : "")}
                                                                            {/* {file.title} */}
                                                                        </p>
                                                                    </div>
                                                                    <div className="edit-btn-section">
                                                                        <div className="video-btn-number">
                                                                            <div className="mb-number">{formatFileSize(file.size)}</div>
                                                                            <p className="edit-btn">
                                                                                <button
                                                                                    type="button"
                                                                                    className="buttons modal-button"
                                                                                    onClick={() => handleEditVideo(file, index)}
                                                                                >
                                                                                    {t(
                                                                                        "superadmin.training.button.modify_video"
                                                                                    )}
                                                                                </button>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                    }

                                                    {
                                                        fileObject?.urls && fileObject?.urls?.length > 0 && fileObject?.urls?.map((url, index) => (
                                                            <>
                                                                <div key={index} className="cancel-btn-content">
                                                                    <button
                                                                        onClick={(e) => { handleRemoveVideo(e, index, 'url') }}
                                                                        type="button"
                                                                        className="cancel-btn cursor-pointer"
                                                                    >
                                                                        <img src={Group769} alt="icon" />
                                                                    </button>
                                                                    <div className="modify-video mt-3" key={index}>
                                                                        <div className="video-icon-file">
                                                                            <button
                                                                                type="button"
                                                                                className="video-icon"
                                                                            >
                                                                                <img src={Group1857link} alt="icon" />
                                                                            </button>

                                                                            <p>
                                                                                {/* {url.title} */}
                                                                                {url.title.slice(0, 30) + (url.title.length > 30 ? "..." : "")}

                                                                            </p>
                                                                        </div>
                                                                        <div className="edit-btn-section">
                                                                            <div className="video-btn-number">
                                                                                <p className="edit-btn">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="buttons modal-button"
                                                                                        onClick={() => handleEditUrl(url, index)}
                                                                                    >
                                                                                        {t(
                                                                                            "superadmin.training.button.modify_video"
                                                                                        )}
                                                                                    </button>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }

                                                    <p className="add-new">
                                                        <button
                                                            type="button"
                                                            className="buttons modal-button"
                                                            onClick={(e) => handleSelectMorePdf(e)}
                                                        >
                                                            {t(
                                                                "superadmin.training.button.modify_video_btn"
                                                            )}
                                                        </button>
                                                    </p>
                                                </div>

                                            </>
                                        ) : ((videoPopupPage == 4) && (currentEditing != undefined || currentEditing != null)) ? (
                                            <>
                                                <div className="url-block">
                                                    <div className="row">
                                                        <div className="col-sm-9">
                                                            <div className="edit-video-title">
                                                                <input name="title" type="text" onChange={(e) => handleEditChange(e)} value={fileObject?.files[currentEditing]?.title ? fileObject?.files[currentEditing]?.title : fileObject?.files[currentEditing]?.url} placeholder={t(
                                                                    "superadmin.training.popup.place_holder_title"
                                                                )} />
                                                            </div>
                                                            <div className="edit-video-description">
                                                                <textarea name="description" value={fileObject?.files[currentEditing]?.description} id="" onChange={(e) => handleEditChange(e)} cols="30" rows="10" placeholder={t(
                                                                    "superadmin.training.popup.place_holder_description"
                                                                )}></textarea>
                                                            </div>
                                                        </div>

                                                        <div className="col-sm-3">
                                                            <div className="confirm-btn-size">
                                                                <div className="file-size">{formatFileSize(fileObject?.files[currentEditing]?.size)}</div>
                                                                <div className="file-title"><strong>{fileObject?.files[currentEditing]?.title}</strong></div>
                                                                <div className="confirm-btn">
                                                                    <button className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                                                        disabled={isDisabled} onClick={(e) => handleEditConfirm(e)} type="button">{t(
                                                                            "superadmin.training.button.modify_edit_button"
                                                                        )}</button>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>



                                            </>
                                        ) : ((videoPopupPage == 4) && (currentEditingUrl != undefined || currentEditingUrl != null)) ? (
                                            <>
                                                <div className="url-block">
                                                    <div className="row">

                                                        <div className="col-sm-10">
                                                            <div className="edit-video-title">
                                                                <input name="title" type="text" onChange={(e) => handleUrl(e)} value={fileObject?.urls[currentEditingUrl]?.title} placeholder={t(
                                                                    "superadmin.training.popup.place_holder_title"
                                                                )} />
                                                            </div>
                                                            <div className="edit-video-description">
                                                                <textarea name="description" value={fileObject?.urls[currentEditingUrl]?.description} id="" onChange={(e) => handleUrl(e)} cols="30" rows="10" placeholder={t(
                                                                    "superadmin.training.popup.place_holder_description"
                                                                )}></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <div className="confirm-btn-size">
                                                                <div className="confirm-btn">
                                                                    <div className="confirm-btn-url"></div>
                                                                    <button className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                                                        disabled={isDisabled} onClick={(e) => handleEditConfirm(e)} type="button">{t(
                                                                            "superadmin.training.button.modify_edit_button"
                                                                        )}</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>



                                            </>
                                        ) : (videoPopupPage === 5) ?
                                            <div className="url-block">
                                                {fileObject?.files && fileObject?.files?.length > 0 && fileObject?.files?.map((file, index) => (
                                                    <>
                                                        <div className="cancel-btn-content">

                                                            <div className="modify-video mt-3" key={index}>
                                                                <div className="video-icon-file">
                                                                    <button
                                                                        type="button"
                                                                        className="video-icon"
                                                                    >
                                                                        <img src={VIDEO_ICONAvideo} alt="icon" />
                                                                    </button>

                                                                    <p>
                                                                        {/* {file.title} */}
                                                                        {file.title.slice(0, 30) + (file.title.length > 30 ? "..." : "")}
                                                                    </p>
                                                                </div>
                                                                <div className="edit-btn-section">
                                                                    <div className="video-btn-number">
                                                                        <div className="plus-sign"><img src={Frame} alt="" /></div>
                                                                        <div className="mb-number">{formatFileSize(file.size)}</div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                                }

                                                {
                                                    fileObject?.urls && fileObject?.urls?.length > 0 && fileObject?.urls?.map((url, index) => (
                                                        <>
                                                            <div className="cancel-btn-content">

                                                                <div className="modify-video mt-3" key={index}>
                                                                    <div className="video-icon-file">
                                                                        <button
                                                                            type="button"
                                                                            className="video-icon"
                                                                        >
                                                                            <img src={Group1857link} alt="icon" />
                                                                        </button>

                                                                        <p>
                                                                            {/* {url.title} */}
                                                                            {url.title.slice(0, 30) + (url.title.length > 30 ? "..." : "")}
                                                                        </p>
                                                                    </div>
                                                                    <div className="edit-btn-section">
                                                                        <div className="video-btn-number">
                                                                            <div className="plus-sign"><img src={Frame} alt="" /></div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                }


                                            </div> : ''
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin mt-1">
                            {(videoPopupPage === 2) || (videoPopupPage === 3) || (videoPopupPage === 4) || (videoPopupPage === 5) ? <button
                                className="buttons modal-button mr-3"
                                onClick={(e) => (videoPopupPage === 5) ? setVideoPopupPage(3) : setVideoPopupPage(1)}
                            >
                                {t("superadmin.protocol.popup.backButton")}
                            </button> : (videoPopupPage === 1) ?
                                <button
                                    className="buttons modal-button mr-3"
                                    onClick={() => closeModal()}
                                >
                                    {t("superadmin.protocol.popup.cancel")}
                                </button> : ''}
                            {fileObject && (fileObject?.files?.length > 0) && (videoPopupPage == 5) || (fileObject?.urls?.length > 0) && (videoPopupPage == 5) ? <button
                                className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                disabled={isDisabled}
                                type="submit"
                                onClick={handleFinalData}
                            >
                                {t("superadmin.training.popup.load")}
                            </button> : (videoPopupPage === 3) || (videoPopupPage === 4) ?
                                <button
                                    className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                    disabled={isDisabled}
                                    type="submit"
                                    onClick={(e) => { setVideoPopupPage(5) }}

                                >
                                    {t("superadmin.training.popup.next")}
                                </button> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const handlePdfDocSelect = (e) => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        const selectedFiles = Array.from(e.target.files);
        let totalSize = 0;
        if (selectedFiles.length < maxFile) {
            for (const allFiles of selectedFiles) {
                totalSize += allFiles.size;
            }
        }

        if (totalSize <= fileSize) {
            setPdfFile(selectedFiles);
        }
        else {
            setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.file_error_msg"), class: "alert alert-danger" })
            fileUpload.current.value = "";
            fileUpload.current.type = "text";
            fileUpload.current.type = "file";
        }
    }


    const handleFileDrop = (e) => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        let totalSize = 0;
        if (droppedFiles.length < maxFile) {
            for (const dropFile of droppedFiles) {
                totalSize += dropFile.size;
            }
        }

        if (totalSize <= fileSize) {
            setPdfFile(droppedFiles);
        }
        else {
            setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.file_error_msg"), class: "alert alert-danger" })
        }

    };

    useEffect(() => {
        pdfFileArr()
    }, [pdfFile]);

    const isDocument = (file) => {
        var ext = getExtension(file.name);
        let pdfData = pdfFileObject.files;
        let pdfUrlUrls = pdfFileObject.urls;
        pdfFileObject.languageType = newProgramData.languagetype;
        switch (ext.toLowerCase()) {
            case 'doc':
            case 'pdf':
            case 'docx':
                pdfData?.push({ title: file.name, description: "", uploadAttempts, size: file.size, fileType: "document" })
                setPdfFileObject({ languageType: pdfFileObject.languageType, files: pdfData, urls: pdfUrlUrls });

                return true;
        }
        return false;
    }



    const pdfFileArr = () => {
        if (pdfFile?.length > 0) {
            for (const file of pdfFile) {
                isDocument(file)
            }

            let form = new FormData();
            form.append('filesType', 1);
            pdfFile?.forEach((singleFile, index) => {
                form.append(`files`, singleFile);
            });
            setUploadAttempts(uploadAttempts + 1);
            fileUploadApi(form, (event) => {
                setProgress(Math.round((100 * event.loaded) / event.total));
            }).then((response) => {
                setAlertMessage({ show: false, spinner: false, message: "", class: "" })
                if (response?.status == 200) {
                    const pdfFileRes = response?.data?.data;
                    if (pdfFileRes.length > 0) {
                        pdfFileRes?.forEach((item, index) => {
                            pdfFileObject?.files?.forEach((data, id) => {
                                if ((data.title == item.originalFilename) && (data.uploadAttempts == (uploadAttempts))) {
                                    data.filePath = item.fileName
                                }
                            })

                        })
                    }
                    setPdfPopupPage(3)
                    setIsDisabled(false)
                    resetFileSelector();
                } else {
                    resetFileSelector();
                    setAlertMessage({ show: true, spinner: false, message: response.data.message, class: "alert alert-danger" })
                }

            })
        }
    }

    const handleEditPdf = (e, pdfFileId) => {
        setCurrentEditing(pdfFileId)
        setPdfPopupPage(4)
        // pdfFileObject.files[currentEditing]?.title;
        // pdfFileObject.files[currentEditing]?.size;
        // pdfFileObject.files[currentEditing]?.desciption;
    }


    const handlePdfUploadUrl = (e) => {
        let pdfFileData = pdfFileObject.files;
        let pdfUrlData = pdfFileObject.urls;
        pdfUrlData?.push({ title: title ? title : pdfUrlChange, description: description ? description : '', url: pdfUrlChange, fileType: "document", favicon: favIcon })
        setPdfFileObject({ files: pdfFileData, urls: pdfUrlData, languageType: newProgramData.languagetype });
        setPdfPopupPage(3)
        setPdfUrlChange(null)
        setUrlLink(null)
    }

    const handlePdfUrlChange = (e) => {
        if (e.target.value == null || e.target.value == '') {
            setIsDisabled(true)
        } else {
            let pdfUrl = e.target.value
            urlPatternValidation(pdfUrl)
        }
    }

    useEffect(() => {
        if (pdfUrlChange != null) {
            fetchTitleAndDescription(pdfUrlChange)
        }
    }, [pdfUrlChange])

    const handleSelectMorePdf = (e) => {
        setTitle('')
        setFavIcon('')
        setDescription('')
        setPdfPopupPage(1)
        setVideoPopupPage(1)
        setPdfUrlChange(null)
        setUrlLink(null)
    }

    const handlePdfEditChange = (e) => {
        let nameofText = e.target.name
        let valueofText = e.target.value
        let newPdfData = pdfFileObject.files;
        let newUrlData = pdfFileObject.urls;

        if (nameofText === 'title') {
            if (valueofText == '') {
                setIsDisabled(true)
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.enter-title"), class: "alert alert-danger" })
                newPdfData[currentEditing].title = valueofText;
            } else if (valueofText.length < 3) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.title_limit_message"), class: "alert alert-danger" })
                newPdfData[currentEditing].title = valueofText;
                setIsDisabled(true)
            }
            else {
                setIsDisabled(false)
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
                newPdfData[currentEditing].title = valueofText;
            }
        }
        if (nameofText === 'description') {
            if (valueofText.length > 1000) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.description_limit_message"), class: "alert alert-danger" })
                newPdfData[currentEditing].description = valueofText;
            } else {
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
                newPdfData[currentEditing].description = valueofText;
                setIsDisabled(false)
            }
        }
        // Descritpion Validation
        pdfFileObject.files.forEach((data) => {
            if (data.description && data.description.length > 1000) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.description_limit_message"), class: "alert alert-danger" })
                setIsDisabled(true)
            }
        });
        setPdfFileObject({ files: newPdfData, urls: newUrlData, languageType: newProgramData.languagetype });
    }

    const handleEditPdfUrl = (url, urlId) => {
        setCurrentEditingUrl(urlId)
        setPdfPopupPage(4)
        // pdfFileObject.urls[currentEditingUrl]?.title;
        // pdfFileObject.urls[currentEditingUrl]?.desciption;

    }

    const handleUploadPdfUrl = (e) => {
        setPdfUrlChange(null)
        setPdfPopupPage(2)
        setIsDisabled(true)
    }


    const handlePdfUrl = (e) => {
        let nameofText = e.target.name
        let valueofText = e.target.value
        let pdfData = pdfFileObject.files;
        let pdfUrl = pdfFileObject.urls;

        if (nameofText === 'title') {
            if (valueofText == '') {
                setIsDisabled(true)
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.enter-title"), class: "alert alert-danger" })
                pdfUrl[currentEditingUrl].title = valueofText;
            } else if (valueofText.length < 3) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.title_limit_message"), class: "alert alert-danger" })
                pdfUrl[currentEditingUrl].title = valueofText;
                setIsDisabled(true)
            }
            else {
                setIsDisabled(false)
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
                pdfUrl[currentEditingUrl].title = valueofText;
            }
        }
        if (nameofText === 'description') {
            if (valueofText.length > 1000) {
                pdfUrl[currentEditingUrl].description = valueofText;
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.description_limit_message"), class: "alert alert-danger" })

            } else {
                setAlertMessage({ show: false, spinner: false, message: '', class: "alert alert-danger" })
                pdfUrl[currentEditingUrl].description = valueofText;
                setIsDisabled(false)
            }
        }
        // Descritpion Validation
        pdfFileObject.urls.forEach((data) => {
            if (data.description && data.description.length > 1000) {
                setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.popup.description_limit_message"), class: "alert alert-danger" })
                setIsDisabled(true)
            }
        });
        setPdfFileObject({ files: pdfData, urls: pdfUrl });

    }

    const handleRemovePdf = (e, id, type) => {
        const newPdfData = pdfFileObject.files
        const newUrlData = pdfFileObject.urls
        if (type == 'pdf') {
            let remainingData = newPdfData?.filter((item, index) => {
                return index != id
            })
            setPdfFileObject({ files: remainingData, urls: newUrlData })
        }
        else if (type == 'url') {
            let remainingData = newUrlData?.filter((item, index) => {
                return index != id
            })
            setPdfFileObject({ files: newPdfData, urls: remainingData })
        }
    }

    const handleFinalPdfData = async () => {
        setIsLoading(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const uploadFinalPdfData = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CONTENT_UPLOAD}`,
                    //  pdfFileObject,
                    selectedNewLearningData,
                    "",
                    ""
                );
                if (uploadFinalPdfData.status == 200) {
                    setIsLoading(false)
                    setAlertMessage({ show: true, spinner: false, message: uploadFinalPdfData.data.message, class: "alert alert-danger" })
                    getCompanyDocument(1)
                    closeModal()
                } else {
                    setIsLoading(false)
                    setAlertMessage({ show: true, spinner: false, message: uploadFinalPdfData.data.message, class: "alert alert-danger" })
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
        setCheckboxStates({});
        setSelectedData({});
        //for empty checkbox
    }

    const addPdfModalContent = () => {
        return (
            <div className="model-pop-new ">
                <div className="model-pop ">
                    <div className="modal-header">
                        <div className="video-heading-img text-center">
                            <p className="text-center ml-1">
                                <img src={UPLOAD_ICONA} alt="" className="p-1" />
                            </p>
                        </div>
                        <h5 className="modal-title pop_heading ml-2" id="exampleModalLabel">
                            <strong>
                                {t("superadmin.training.popup.add_video_heading")}
                            </strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>

                    {alertMessage.show ? (
                        <div className={`d-flex mt-3 ${alertMessage.class}`}>
                            {alertMessage.spinner ? (
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new"></div>
                                </div>
                            ) : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div>
                    ) : null}

                    {isLoading ? <LazyLoader /> : null}
                    <div className="modal-body">
                        <div className="">
                            <form className="registrati-form registrati-form-new nuovo-programma">
                                <div className="form-group">
                                    <div>

                                        {pdfPopupPage == 1 ? (
                                            <>

                                                <div class="switch-field">
                                                    <input type="radio" id="radio-one" onChange={handleChange} name="languagetype" value="it" checked={newProgramData.languagetype == 'it'} />
                                                    <label for="radio-one">Italian</label>
                                                    <input type="radio" id="radio-two" onChange={handleChange} name="languagetype" value="en" checked={newProgramData.languagetype == 'en'} />
                                                    <label for="radio-two">English</label>
                                                </div>

                                                <div className="progressBar">
                                                    {(showProgressBar == true) || progress && <ProgressBar now={progress} label={`${progress}%`} />}
                                                </div>
                                                <div
                                                    className="drop-area"
                                                    draggable='true'
                                                    onDragOver={(e) => e.preventDefault()}
                                                    onDrop={handleFileDrop}
                                                >
                                                    <div draggable="true" onDragStart={handleDragStart}>
                                                        <div className="row">
                                                            <div className="col-sm-6 d-flex">
                                                                {pdfFile && (
                                                                    <div className="video-block">
                                                                        <div className="inner-video-block">
                                                                            <div className="video-img-block">
                                                                                <p>
                                                                                    <img src={Group1333PDF} alt="" />
                                                                                </p>
                                                                            </div>
                                                                            <div className="choose-file">
                                                                                <input
                                                                                    type="file"
                                                                                    multiple
                                                                                    accept=".pdf,.doc,.docx"
                                                                                    hidden
                                                                                    onChange={handlePdfDocSelect}
                                                                                    ref={fileUpload}
                                                                                />

                                                                            </div>
                                                                            <div className="video-popup-body">
                                                                                <strong>
                                                                                    {t(
                                                                                        "superadmin.training.popup.pdf_file_limit"
                                                                                    )}
                                                                                </strong>
                                                                            </div>
                                                                            <div className="video-upload-btn">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => handleUpload()}
                                                                                    className="buttons modal-button"
                                                                                >
                                                                                    {t(
                                                                                        "superadmin.training.popup.add_pdf_heading"
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="col-sm-6 d-flex">
                                                                <div className="video-block">
                                                                    <div className="inner-video-block">
                                                                        <div className="url-width">
                                                                            <div className="video-img-block">
                                                                                <img src={Group1857PDF} alt="" />
                                                                            </div>
                                                                            <div className="video-popup-body">
                                                                                <strong>
                                                                                    {t(
                                                                                        "superadmin.training.popup.pdf_file_limit2"
                                                                                    )}
                                                                                </strong>
                                                                            </div>
                                                                            <div className="video-upload-btn">
                                                                                <button
                                                                                    type="button"
                                                                                    className="buttons modal-button"
                                                                                    onClick={(e) => handleUploadPdfUrl(e)}
                                                                                >
                                                                                    {t("superadmin.training.popup.insertLink")}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : pdfPopupPage == 2 ? (
                                            <>
                                                <div className="url-block-section">
                                                    <div className="url-block-content">
                                                        <p className="url-input">
                                                            <input
                                                                type="text"
                                                                name="url"
                                                                id=""
                                                                placeholder={t(
                                                                    "superadmin.training.popup.place_holder_url"
                                                                )}
                                                                onChange={(e) => { handlePdfUrlChange(e) }}
                                                            />
                                                        </p>
                                                        <p className="url-btn">
                                                            <button
                                                                type="button"
                                                                disabled={pdfUrlChange == null}
                                                                className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                                                onClick={(e) => handlePdfUploadUrl(e)}
                                                            >
                                                                {t("superadmin.training.button.url_button")}
                                                            </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            </>

                                        ) : pdfPopupPage == 3 ? (
                                            <>
                                                <div className="url-block">
                                                    {pdfFileObject?.files && pdfFileObject?.files?.length > 0 && pdfFileObject?.files?.map((file, index) => (
                                                        <>
                                                            <div key={index} className="cancel-btn-content">
                                                                <button
                                                                    type="button"
                                                                    className="cancel-btn cursor-pointer"
                                                                    onClick={(e) => handleRemovePdf(e, index, 'pdf')}
                                                                >
                                                                    <img src={Group769} alt="icon" />
                                                                </button>
                                                                <div className="modify-video mt-3">
                                                                    <div className="video-icon-file">
                                                                        <button
                                                                            type="button"
                                                                            className="video-icon"
                                                                        >
                                                                            <img src={Vectordocument} alt="icon" />
                                                                        </button>

                                                                        <p>
                                                                            {/* {file.title} */}
                                                                            {file.title.slice(0, 30) + (file.title.length > 30 ? "..." : "")}

                                                                        </p>
                                                                    </div>
                                                                    <div className="edit-btn-section">
                                                                        <div className="video-btn-number">
                                                                            <div className="mb-number">{formatFileSize(file.size)}</div>
                                                                            <p className="edit-btn">
                                                                                <button
                                                                                    type="button"
                                                                                    className="buttons modal-button"
                                                                                    onClick={() => handleEditPdf(file, index)}
                                                                                >
                                                                                    {t(
                                                                                        "superadmin.training.button.modify_video"
                                                                                    )}
                                                                                </button>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                    }
                                                    {
                                                        pdfFileObject?.urls && pdfFileObject?.urls?.length > 0 && pdfFileObject?.urls?.map((url, index) => (
                                                            <>
                                                                <div key={index} className="cancel-btn-content">
                                                                    <button
                                                                        type="button"
                                                                        className="cancel-btn cursor-pointer"
                                                                        onClick={(e) => { handleRemovePdf(e, index, 'url') }}
                                                                    >
                                                                        <img src={Group769} alt="icon" />
                                                                    </button>
                                                                    <div className="modify-video mt-3" key={index}>
                                                                        <div className="video-icon-file">
                                                                            <button
                                                                                type="button"
                                                                                className="video-icon"
                                                                            >
                                                                                <img src={Group1857link} alt="icon" />
                                                                            </button>

                                                                            <p>
                                                                                {/* {url.title} */}
                                                                                {url.title.slice(0, 30) + (url.title.length > 30 ? "..." : "")}
                                                                            </p>
                                                                        </div>
                                                                        <div className="edit-btn-section">
                                                                            <div className="video-btn-number">
                                                                                <p className="edit-btn">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="buttons modal-button"
                                                                                        onClick={() => handleEditPdfUrl(url, index)}
                                                                                    >
                                                                                        {t(
                                                                                            "superadmin.training.button.modify_video"
                                                                                        )}
                                                                                    </button>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))
                                                    }

                                                    <p className="add-new">
                                                        <button
                                                            type="button"
                                                            className="buttons modal-button"
                                                            onClick={() => handleSelectMorePdf()}
                                                        >
                                                            {t(
                                                                "superadmin.training.button.modify_video_btn"
                                                            )}
                                                        </button>
                                                    </p>
                                                </div>

                                            </>
                                        ) :
                                            ((pdfPopupPage == 4) && (currentEditing != undefined || currentEditing != null)) ? (
                                                <>
                                                    <div className="url-block">
                                                        <div className="row">
                                                            <div className="col-sm-9">
                                                                <div className="edit-video-title">
                                                                    <input name="title" type="text" onChange={(e) => handlePdfEditChange(e)} value={pdfFileObject?.files[currentEditing]?.title ? pdfFileObject?.files[currentEditing]?.title : pdfFileObject?.files[currentEditing]?.url} placeholder={t(
                                                                        "superadmin.training.popup.place_holder_title"
                                                                    )} />
                                                                </div>
                                                                <div className="edit-video-description">
                                                                    <textarea name="description" value={pdfFileObject?.files[currentEditing]?.description} id="" onChange={(e) => handlePdfEditChange(e)} cols="30" rows="10" placeholder={t(
                                                                        "superadmin.training.popup.doc_description"
                                                                    )}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className="confirm-btn-size">
                                                                    <div className="file-size">{formatFileSize(pdfFileObject?.files[currentEditing]?.size)}</div>
                                                                    <div className="file-title"><strong>{pdfFileObject?.files[currentEditing]?.title}</strong></div>
                                                                    <div className="confirm-btn">
                                                                        <button className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                                                            disabled={isDisabled} onClick={(e) => handleEditConfirm(e)} type="button">{t(
                                                                                "superadmin.training.button.modify_edit_button"
                                                                            )}</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            ) : ((pdfPopupPage == 4) && (currentEditingUrl != undefined || currentEditingUrl != null)) ? (
                                                <>
                                                    <div className="url-block">
                                                        <div className="row">
                                                            <div className="col-sm-10">
                                                                <div className="edit-video-title">
                                                                    <input name="title" type="text" onChange={(e) => handlePdfUrl(e)} value={pdfFileObject?.urls[currentEditingUrl]?.title} placeholder={t(
                                                                        "superadmin.training.popup.place_holder_title"
                                                                    )} />
                                                                </div>
                                                                <div className="edit-video-description">
                                                                    <textarea name="description" value={pdfFileObject?.urls[currentEditingUrl]?.description} id="" onChange={(e) => handlePdfUrl(e)} cols="30" rows="10" placeholder={t(
                                                                        "superadmin.training.popup.place_holder_author"
                                                                    )}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <div className="confirm-btn-size">
                                                                    <div className="confirm-btn">
                                                                        <div className="confirm-btn-url"></div>
                                                                        <button className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                                                            disabled={isDisabled} onClick={(e) => handleEditConfirm(e)} type="button">{t(
                                                                                "superadmin.training.button.modify_edit_button"
                                                                            )}</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                                : (pdfPopupPage === 5) ?
                                                    <>
                                                        <div className="url-block">
                                                            {pdfFileObject?.files && pdfFileObject?.files?.length > 0 && pdfFileObject?.files?.map((file, index) => (
                                                                <>
                                                                    <div className="cancel-btn-content">

                                                                        <div className="modify-video mt-3">
                                                                            <div className="video-icon-file">
                                                                                <button
                                                                                    type="button"
                                                                                    className="video-icon"
                                                                                >
                                                                                    <img src={Vectordocument} alt="icon" />
                                                                                </button>

                                                                                <p>
                                                                                    {/* {file.title} */}
                                                                                    {file.title.slice(0, 30) + (file.title.length > 30 ? "..." : "")}

                                                                                </p>
                                                                            </div>
                                                                            <div className="edit-btn-section">

                                                                                <div className="video-btn-number">
                                                                                    <div className="plus-sign cont"><img src={Frame} alt="" onClick={() => handleLearningCategory(index)} /></div>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ))
                                                            }

                                                            {
                                                                pdfFileObject?.urls && pdfFileObject?.urls?.length > 0 && pdfFileObject?.urls?.map((url, index) => (
                                                                    <>
                                                                        <div className="cancel-btn-content">


                                                                            <div className="modify-video mt-3" key={index}>
                                                                                <div className="video-icon-file">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="video-icon"
                                                                                    >
                                                                                        <img src={Group1857link} alt="icon" />
                                                                                    </button>

                                                                                    <p>
                                                                                        {/* {url.title} */}
                                                                                        {url.title.slice(0, 30) + (url.title.length > 30 ? "..." : "")}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="edit-btn-section">

                                                                                    <div className="video-btn-number">
                                                                                        <div className="plus-sign"><img src={Frame} alt="" /></div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                            }


                                                        </div>

                                                    </>

                                                    : ''}
                                    </div>
                                </div>
                            </form>
                        </div>


                        <div className="d-flex justify-content-end buttons-superadmin mt-1">
                            {(pdfPopupPage === 2) || (pdfPopupPage === 3) || (pdfPopupPage === 4) || (pdfPopupPage === 5) ? <button
                                className="buttons modal-button mr-3"
                                onClick={(e) => (pdfPopupPage === 5) ? setPdfPopupPage(3) : setPdfPopupPage(1)}
                            >
                                {t("superadmin.protocol.popup.backButton")}
                            </button> : (pdfPopupPage === 1) ?
                                <button
                                    className="buttons modal-button mr-3"
                                    onClick={() => closeModal()}
                                >
                                    {t("superadmin.protocol.popup.cancel")}
                                </button> : ''}
                            {pdfFileObject && (pdfFileObject?.files?.length > 0) && (pdfPopupPage == 5) || (pdfFileObject?.urls?.length > 0) && (pdfPopupPage == 5) ? <button
                                className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                disabled={isDisabled}
                                type="submit"
                                onClick={handleFinalPdfData}
                            >
                                {t("superadmin.training.popup.load")}
                            </button> : (pdfPopupPage === 3) || (pdfPopupPage === 4) ?
                                <button
                                    className={isDisabled ? 'disable-btn' : 'enable-btn'}
                                    disabled={isDisabled}
                                    type="submit"
                                    onClick={(e) => { setPdfPopupPage(5) }}

                                >
                                    {t("superadmin.training.popup.next")}
                                </button> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const previewDoc = (e, contentId) => {
        setContentId(contentId)
        setDocPreview(true)

    }
    const addLearningMaterialContent = () => {
        return (
            <>
                {
                    learningDataLenght > 0 ? <div className="model-pop-new">
                        <div className="modal-body scroll-pop scroll-pop-new" ref={modalRef}>
                            <form className="register-form register-form-new new-program">
                                <div className="row">
                                    {
                                        Object.entries(learningData).map(([category, categoryData]) => (
                                            <div className="col-md-6" key={category}>
                                                <div className="load-new-heading">
                                                    <h3>{category}</h3>
                                                    {Object.entries(categoryData).map(([attribute, value]) => (
                                                        <div className="mandatory-check question-right-check" key={attribute}>
                                                            <label className={`mr-0 pl-0 check-radiotype ${checkboxStates[`${category}_${attribute}_${selectIndex}`] ? "checked-icon" : ""} `}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="mr-2"
                                                                    name="correct2"
                                                                    onChange={(e) => handleLearningProgram(category, attribute, e.target.checked, selectIndex)}
                                                                />
                                                            </label>
                                                            <span className="pl20">{value}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </form>
                        </div>
                    </div> : ""}

            </>
        )
    }

    //post learnig data

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                handleModalClose();
            }
        };
        if (addLearningModal) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [addLearningModal]);

    useEffect(() => {
        setSelectFinal(selectedData);
        pdfFileObject.files.forEach((file, index) => {
            if (index == selectIndex) {
                file.learnData = selectfinal[index];
            }
            file.language = programData.languagetype;
        });
        // pdfFileObject.language = programData.languagetype;
        setselectedNewLearningData(pdfFileObject);
    }, [selectedData])

    const handleModalClose = () => {
        setLearningModal(false);
        // setSelectedData({});
    };

    const handleSearch = () => { };
    return (
        <>
            <Modal title="" size="sm" contentClasses="" onClose={() => setAddProgramModal(false)} show={addProgramModal}>
                {addProgramModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setAddProgramModal2(false)} show={addProgramModal2}>
                {addProgramModal2Content()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setUpdateProgramModal2(false)} show={updateProgramModal2}>
                {upadateProgramModal2Content()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDeleteProgramModal(false)} show={deleteProgramModal}>
                {deleteProgramModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDocumentProgramsModal(false)} show={documentProgramsModal}>
                {documentProgramsModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setAddTextDocumentModal(false)} show={addTextDocumentModal}>
                {addTextDocumentModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setAddVideoModal(false)} show={addVideoModal}>
                {addVideoModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setAddPdfModal(false)} show={addPdfModal}>
                {addPdfModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="learning-popup-size" onClose={() => setLearningModal(false)} show={addLearningModal}>
                {addLearningMaterialContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setShowVideoContentModal(false)} show={showVideoContentModal}>
                <ShowVideo details={currentVideoDetails} close={() => setShowVideoContentModal(false)} />
            </Modal>

            <Modal title="" size="sm" contentClasses="" onClose={() => setDocPreview(false)} show={docPreview}>
                <ViewDocument contentId={contentId} close={() => setDocPreview(false)} />
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDeleteContentPopUp(false)} show={deleteContentPopUp}>
                {contentPopUp()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDeleteVideoPopUp(false)} show={deleteVideoPopUp}>
                {videoPopUp()}
            </Modal>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="training" />
                        <div className=" col-md-10 p0 pr-0 ipd-righ">
                            <div className="right-dashboard topspace leftr-dari">
                                <div className="right-header right-header-edit-training">
                                    <h2 className="heading-dash">
                                        <button className="back-button" onClick={history.goBack}><img src={PREV_PAGE_ICON} alt="icon" /></button>
                                        {companyDetails?.company_name}
                                    </h2>

                                    <div className="align-j-new buttons-superadmin">
                                        {personalText == "program" ? <div>
                                            <button className="buttons mr-3" onClick={() => window.location.href = `/sa/troubleshooting/${props.match.params.company_id}`}>{t("superadmin.training.button.troubleshooting")}</button>
                                            <button className="buttons" onClick={() => { setAddProgramModal(true); }}>{t("superadmin.training.button.new_program")}</button>
                                        </div>
                                            : personalText == "document" ? <div>
                                                <button className="buttons mr-3" onClick={() => { setAddTextDocumentModal(true); setAddTextDocumentData({ languageType: 'it' }) }}>{t("superadmin.training.button.create_new")}</button>
                                                <button className="buttons" onClick={() => { setAddPdfModal(true); setNewProgramData({ languagetype: 'it' }) }}>{t("superadmin.training.button.upload_new")}</button>
                                            </div>
                                                : <div>
                                                    <button className="buttons" onClick={() => { setAddVideoModal(true); setNewProgramData({ languagetype: 'it' }) }}>{t("superadmin.training.button.upload_new")}</button>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="graph-tabs graph-tabs-new ">
                                    <div>
                                        <div className="flex-navtabs">
                                            <ul
                                                className="nav nav-tabs nav-stacked"
                                                id="myTab"
                                                role="tablist"
                                            >
                                                <>
                                                    <li className="nav-item" onClick={() => setPersonalText("program")}>

                                                        <a
                                                            className={
                                                                "nav-link buttons-superadmin mt-0 " +
                                                                (personalText == "program"
                                                                    ? "active show"
                                                                    : "")
                                                            }
                                                            id="first-tab"
                                                            data-toggle="tab"
                                                            href="#first"
                                                            role="tab"
                                                            aria-controls="first"
                                                            aria-selected="true"
                                                        >

                                                            <button className="buttons mr-3">{t("superadmin.training.button.program")}</button>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" onClick={() => { setPersonalText("document"); getCompanyDocument(1); }}>
                                                        <a
                                                            className={
                                                                "nav-link buttons-superadmin mt-0" +
                                                                (personalText == "document" ? "active show" : "")
                                                            }
                                                            id="second-tab"
                                                            data-toggle="tab"
                                                            href="#second"
                                                            role="tab"
                                                            aria-controls="second"
                                                            aria-selected="false"
                                                        // onClick={() =>
                                                        //     window.dispatchEvent(new Event("resize"))
                                                        // }
                                                        >
                                                            <button className="buttons mr-3">{t("superadmin.training.button.documents")}</button>
                                                        </a>
                                                    </li>


                                                    <li className="nav-item" onClick={() => { setPersonalText("video"); getCompanyVideo(1) }}>
                                                        <a
                                                            className={
                                                                "nav-link buttons-superadmin mt-0" +
                                                                (personalText == "video"
                                                                    ? "active show"
                                                                    : "")
                                                            }
                                                            id="third-tab"
                                                            data-toggle="tab"
                                                            href="#third"
                                                            role="tab"
                                                            aria-controls="third"
                                                            aria-selected="false"
                                                        >
                                                            <button className="buttons">{t("superadmin.training.button.video")}</button>
                                                        </a>
                                                    </li>
                                                </>
                                            </ul>
                                            <div className="ricsb super-search-top">
                                                {personalText == 'program' ? <div className="rieplogo rieplogo-new">
                                                    <div className="filter-box">
                                                        <div
                                                            className={`filt-b ${filterShow ? "activeFilter" : ""
                                                                }`}
                                                            ref={ref}
                                                        >
                                                            <button
                                                                className="b-box"
                                                                onClick={changeFilter}
                                                            >
                                                                <span className="fillter-icon"></span>
                                                                <span className="visi-none">
                                                                    {t('user.summary.filtri')}
                                                                </span>
                                                            </button>
                                                            {filterShow ? (

                                                                <div className="list-checkboxes">
                                                                    <div className="visibleonlly-phone">{t('user.summary.filtri')}
                                                                        {filterValue.length === 0 ? (
                                                                            <button className="search-filter" onClick={resetFilters}>
                                                                                <span className="cross-reset">X</span></button>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </div>
                                                                    <h4>{t('user.summary.parameter')}</h4>
                                                                    {filterValue.length === 0 ? (
                                                                        <></>
                                                                    ) : (
                                                                        <button
                                                                            className="search-filter"
                                                                            onClick={resetFilters}
                                                                        >
                                                                            Reset
                                                                        </button>
                                                                    )}
                                                                    {filterValueData.map((data, i) => {
                                                                        let filterStatus = false;
                                                                        return (
                                                                            <div
                                                                                className="custom-control custom-checkbox"
                                                                                key={"opt" + i}
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    defaultChecked={filterValue.includes(
                                                                                        data.key
                                                                                    )}
                                                                                    className="custom-control-input"
                                                                                    id={"customCheck" + i}
                                                                                    value={data.key}
                                                                                    onChange={onChangeFilter}
                                                                                />
                                                                                <label
                                                                                    className="custom-control-label"
                                                                                    htmlFor={"customCheck" + i}
                                                                                >
                                                                                    {data.name}
                                                                                </label>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className="searchbar opacity0">
                                                            <span style={{ width: "200px" }} className="summary-search-icon"></span>
                                                            <SearchBar
                                                                placeholder={t('user.summary.cerca')}
                                                                {...props.searchProps}
                                                                data={filterShow ? filteredData : programData}
                                                                columns={programColumn}
                                                                onSearch={handleSearch}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> :
                                                    (personalText == 'document') ? <div>
                                                        <div className=" searchbar searchbar-content d-flex justify-content-end">
                                                            <div className="position-relative">
                                                                <img src={Group698} className="search_icon-new" alt="icon" />
                                                                <label htmlFor="search-bar-0" className="search-label">
                                                                    <span id="search-bar-0-label" className="sr-only"></span>
                                                                    <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="search-bar-new form-control" placeholder={t("superadmin.protocol.search")} type="text" value={documentSearchQuery} onChange={(e) => setDocumentSearchQuery(e.target.value)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                        <div>
                                                            <div className=" searchbar searchbar-content d-flex justify-content-end">
                                                                <div className="position-relative">
                                                                    <img src={Group698} className="search_icon-new" alt="icon" />
                                                                    <label htmlFor="search-bar-0" className="search-label">
                                                                        <span id="search-bar-0-label" className="sr-only"></span>
                                                                        <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="search-bar-new form-control" placeholder={t("superadmin.protocol.search")} type="text" value={videoSearchQuery} onChange={(e) => setVideoSearchQuery(e.target.value)} />
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body mt-5">
                                        <div className="tab-content">
                                            <>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "program"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="first"
                                                    role="tabpanel"
                                                    aria-labelledby="first-tab"
                                                >
                                                    <div className="row mx-0">
                                                        {(programData.length > 0 && programData) ? <>
                                                            <ToolkitProvider
                                                                keyField="program_id"
                                                                key={'datatable'}
                                                                data={filteredData.length > 0 ? filteredData : programData}
                                                                columns={programColumn}
                                                                search
                                                            >{(props) => (

                                                                <div id="test" className="table  ricsb ricsb-new protocolli-data push-table">
                                                                    <div>
                                                                        <div>
                                                                            <div className="searchbar searchbar-sa d-flex justify-content-end m-2 searchmoved-top">
                                                                                <div style={{ position: "relative" }}>
                                                                                    <img
                                                                                        src={Group698}
                                                                                        className="     "
                                                                                        alt="icon"
                                                                                        style={{ position: "absolute", top: "18px", left: "10px" }}
                                                                                    />
                                                                                    <SearchBar
                                                                                        placeholder={t("superadmin.protocol.search")}
                                                                                        {...props.searchProps}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <BootstrapTable
                                                                                src={VectorDown} alt="icon"
                                                                                data={filteredData.length > 0 ? filteredData : programData}
                                                                                {...props.baseProps}
                                                                                filter={filterFactory()}
                                                                                pagination={paginationFactory(paginationOption)}
                                                                                wrapperClasses={'table-responsive mt-4'}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )}
                                                            </ToolkitProvider>
                                                        </> : null}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "document" ? "active show" : "")
                                                    }
                                                    id="second"
                                                    role="tabpanel"
                                                    aria-labelledby="second-tab"
                                                >
                                                    <div className="row">

                                                        <div className="col-md-12 pr-0 p0 ipd-righ">
                                                            <div className="rigt-dashboard topspace mx-3 ">
                                                                {(documentData && documentData?.length > 0) ?
                                                                    <div>
                                                                        {documentData.map((data, i) => {
                                                                            return (
                                                                                <div key={i}>
                                                                                    <div className="d-flex document-section justify-content-between">
                                                                                        <div className="text-left description-document description-document-new">
                                                                                            <div className="d-sm-flex d-xl-flex d-xl-flex d-xxl-flex">
                                                                                                <div className="icon"><img src={(urlPattern.test(data.name) || (data.name.split('.').pop() == "html") || (data.name.split('.').pop() == "htm")) ? Group1857link : OPEN_ICON} alt="icon" onClick={(e) => previewDoc(e, data.contentId)} className="preview" /></div>
                                                                                                  <div className="text">
                                                                                                    <div className="title-word-wrap">{data.name}</div>
                                                                                                    {(data.description?.length > 250 && documentLoadMoreIndex != data.contentId) ?
                                                                                                        <div className="mb-3 desc-normal pb-4" >
                                                                                                            {data.description?.substr(0, 250)}...
                                                                                                            <button onClick={() => setDocumentLoadMoreIndex(data.contentId)}><span>{t("superadmin.troubleshooting.showMore")}</span></button>
                                                                                                        </div> :
                                                                                                        documentLoadMoreIndex == data.contentId ? <div className="mb-3 desc-normal pb-4">
                                                                                                            {data.description}
                                                                                                            <button onClick={() => setDocumentLoadMoreIndex('')}><span>{t("superadmin.troubleshooting.showLess")}</span></button>
                                                                                                        </div> :
                                                                                                            <div className="mb-3 desc-normal" >{data.description}</div>
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="program-document" onClick={() => { setActiveDocument(data); setDocumentProgramsModal(true) }}>
                                                                                            <span >{t("superadmin.troubleshooting.programs")}</span>
                                                                                            <div className="mt-3 number-value">{data.programs.length}</div>
                                                                                            <button className="table-action" onClick={() => { setContentId(data.contentId); setDeleteContentPopUp(true) }}><img src={DeleteBin} alt="icon" /></button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        <div className="d-flex sa-program-pagination">
                                                                            <ReactPaginate
                                                                                breakLabel="..."
                                                                                nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                                previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                                onPageChange={handlePageClick}
                                                                                pageRangeDisplayed={3}
                                                                                pageCount={documentPageCount}
                                                                                renderOnZeroPageCount={null}
                                                                                containerClassName="selectuser-pagination"
                                                                                pageClassName="page-class"
                                                                                previousClassName={currentPage == 1 ? "d-none" : ""}
                                                                                nextClassName={currentPage == documentPageCount ? "d-none" : ""}
                                                                                forcePage={currentPage - 1}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {!isLoading ? <div >
                                                                            <div className="d-flex document-section justify-content-between">
                                                                                <div className="text-left description-document">
                                                                                    <div className="d-flex w-100 ">
                                                                                        {t("superadmin.troubleshooting.documentNotFound")}

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : null}
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        "tab-pane fade " +
                                                        (personalText == "video"
                                                            ? "active show"
                                                            : "")
                                                    }
                                                    id="third"
                                                    role="tabpanel"
                                                    aria-labelledby="third-tab"
                                                >
                                                    <div className="row">
                                                        <div className="col-md-12 pr-0 p0 ipd-righ">
                                                            <div className="rigt-dashboard topspace mx-3 ">
                                                                {videoData && videoData.length ?
                                                                    <div>
                                                                        {videoData.map((data, i) => {
                                                                            return (

                                                                                <div key={i} className="d-flex document-section justify-content-between">
                                                                                    <div className="text-left description-document" >
                                                                                        <div className="d-sm-flex d-xl-flex d-xl-flex d-xxl-flex">
                                                                                            <div className="icon-image" onClick={() => getVideoContentDetails(data.contentId)}>
                                                                                                {data.link ?
                                                                                                    <div className="play-button"><img className="link" src={Group1857link} alt="" /></div> :
                                                                                                    <div className="play-button"><img src={VideoPlayButton} alt="" /></div>
                                                                                                }
                                                                                                <img src={data.thumbnail || VIDEO_IMAGE} alt="icon" />
                                                                                            </div>
                                                                                            <div className="text">
                                                                                                <div className="p-1 title-word-wrap">{data.name}</div>
                                                                                                {(data.description?.length > 250 && documentLoadMoreIndex != data.contentId) ?
                                                                                                    <div className="pl-1 desc-normal" >
                                                                                                        {data.description?.substr(0, 250)}...
                                                                                                        <button onClick={() => setDocumentLoadMoreIndex(data.contentId)}><span>{t("superadmin.troubleshooting.showMore")}</span></button>
                                                                                                    </div> :
                                                                                                    documentLoadMoreIndex == data.contentId ? <div className="pl-1 desc-normal">
                                                                                                        {data.description}
                                                                                                        <button onClick={() => setDocumentLoadMoreIndex('')}><span>{t("superadmin.troubleshooting.showLess")}</span></button>
                                                                                                    </div> :
                                                                                                        <div className="pl-1 desc-normal" >{data.description}</div>
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="program-document" onClick={() => { setActiveDocument(data); setDocumentProgramsModal(true) }}>
                                                                                        <span >{t("superadmin.troubleshooting.programs")}</span>
                                                                                        <div className="mt-3 number-value">{data.programs.length}</div>
                                                                                        <button className="table-action" onClick={() => { setContentId(data.contentId); setDeleteVideoPopUp(true) }}><img src={DeleteBin} alt="icon" /></button>

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        <div>
                                                                            <div className="d-flex sa-program-pagination">
                                                                                <ReactPaginate
                                                                                    key="paginate2"
                                                                                    breakLabel="..."
                                                                                    nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                                    previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                                    onPageChange={handlePageClickVideo}
                                                                                    pageRangeDisplayed={3}
                                                                                    pageCount={videoPageCount}
                                                                                    renderOnZeroPageCount={null}
                                                                                    containerClassName="selectuser-pagination"
                                                                                    pageClassName="page-class"
                                                                                    previousClassName={videoCurrentPage == 1 ? "d-none" : ""}
                                                                                    nextClassName={videoCurrentPage == videoPageCount ? "d-none" : ""}
                                                                                    forcePage={videoCurrentPage - 1}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        {!isLoading ? <div >
                                                                            <div className="d-flex document-section justify-content-between">
                                                                                <div className="text-left description-document">
                                                                                    <div className="d-flex w-100 ">
                                                                                        {t("superadmin.troubleshooting.videoNotFound")}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> : null}
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    );
};

export default Edit_Training;
