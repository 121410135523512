import React, { useEffect, useState, useCallback } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link, useHistory } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import { GoNoGoComplexResume } from "./GoNoGoComplexResume";
import Circle from "../GoNoGo/Circle.svg";
import Hexagone from "../GoNoGo/hexagon.svg";
import Square from "../GoNoGo/Square.svg";
import Triangle from "../GoNoGo/Triangle.svg";
import Rectangle from "../GoNoGo/Rectangle.svg"
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from "../../../apiConnection/getRequest";
import GoNoGoComplex from "./GoNoGoStep.json";
import { useSpeechSynthesis } from "react-speech-kit";
import BWGroup1862 from "../../../../assets/Image/BWGroup1862.svg";
import LazyLoader from "../../../common/lazyLoader";



export const GoNoGoComplexIndex = (props) => {

  const { resetRound } = props.data;
  const [t, i18n] = useTranslation("common");
  const [totalTime, setTotalTime] = useState()
  const [timeRemaining, setTimeRemaining] = useState();
  const [isPaused, setIsPaused] = useState(false);
  const [round, setRound] = useState();
  const [accuracyLastRound, setAccuracyLastRound] = useState(0);
  const [avgAccuracy, setAvgAccuracy] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [activityId, setActivityId] = useState();
  const [activityPlayTime, setActivityPlayTime] = useState();
  const [activityScore, setActivityScore] = useState();
  const [activityStatus, setActivityStatus] = useState("");
  const [accuracyCurrentRound, setAccuracyCurrentRound] = useState(0);
  const [roundFinish, setRoundFinish] = useState(false);
  const [steps, setSteps] = useState(0);
  const [maxSteps, setMaxSteps] = useState(10);
  const [shape, setShape] = useState([]);
  const [shapeImg, setShapeImg] = useState("Circle")
  const [gameStatus, setGameStatus] = useState(true);
  const [localShape, setLocalShape] = useState("");
  const [imageUrls, setImageUrls] = useState(Circle);
  const [aboutToEnd, setAboutToEnd] = useState(false);
  const [startTime, setStartTime] = useState();
  const [cssWidth, setCssWidth] = useState(0);
  const [currentResponseTime, setCurrentResponseTime] = useState(0);
  const [responseTimes, setResponseTimes] = useState([]);
  const [averageResponseTime, setAverageResponseTime] = useState(0);
  const [totalAvgResponseTime, setTotalAvgResponseTime] = useState(0);
  const shapes = ["Circle", "Square", "Hexagone", "Triangle", "Rectangle"];
  const masterShape = props?.data?.masterShape;
  const component_id = props?.data?.params.component_id;
  const repeat_time = props?.data?.params.repeat_time;
  const day_sequence = props?.data?.params.day_sequence;
  const activity_id = props?.data?.params.activity_id;
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));
  const history = useHistory();
  const [cases, setCases] = useState(null);
  const [imageName, setImageName] = useState();
  const [type, setType] = useState();
  const [distraction, setDistraction] = useState();
  const { speak, voices } = useSpeechSynthesis();
  const [speaks, setSpeaks] = useState();
  const [score, setScore] = useState(0);
  const [click, setClick] = useState(false);
  const free = props?.data?.params.free;
  useEffect(() => {
    document.body.className = (theme) ? theme : 'light';
  }, [theme])

  useEffect(() => {
    if(!free){
      getActivityUserData();//call api
    }
    else{
      setIsLoading(false);
    }
    setMaxSteps(Math.floor(Math.random() * (32 - 25 + 1)) + 25);//set step
    setShape(masterShape);//set master shape

    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [])

  //set local shapes (dynamic shapes) when change steps
  useEffect(() => {
    setImageUrls(null);

    if (steps <= maxSteps) {
      setTimeout(() => {
        if (imageName === "Circle") {
          setImageUrls(Circle)
          setLocalShape("Circle")
        }
        if (imageName === "Hexagone") {
          setImageUrls(Hexagone)
          setLocalShape("Hexagone")
        }
        if (imageName === "Square") {
          setImageUrls(Square)
          setLocalShape("Square")
        }

        if (imageName === "Triangle") {
          setImageUrls(Triangle)
          setLocalShape("Triangle")
        }
        if (imageName === "Rectangle") {
          setImageUrls(Rectangle)
          setLocalShape("Rectangle")
        }
      }, 100);
    }
  }, [steps, imageName])

  const getActivityUserData = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        "",
        "refresh_token"
      );

      if (refreshToken) {
        const getUserActivityData = await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_ACTIVITY_STATUS}?repeat_time=${repeat_time}&component_id=${component_id}&day_sequence=${day_sequence}&activity_id=${activity_id}`,
          "",
          "",
          ""
        );

        if (getUserActivityData && getUserActivityData.status === 200) {
          setIsLoading(false);
          if (getUserActivityData?.data?.data?.activity_data) {
            let dataObj = getUserActivityData?.data?.data?.activity_data;
            setActivityId(dataObj.activity_id);
            setActivityPlayTime(dataObj.activity_play_time);
            setTotalTime(dataObj.activity_total_data[0].activity_duration);
            setTimeRemaining(((dataObj.activity_total_data[0].activity_duration) * 60) - (dataObj.activity_play_time));
            setRound(dataObj.activity_round_no);
            setAvgAccuracy(dataObj.activity_score);
            setActivityStatus(dataObj.status);
            setTotalAvgResponseTime(dataObj.total_avg_response_time);
          }
        } else if (getAllDataResult && getAllDataResult.status === 203) {
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error)
    }
  }

  //set start time when change steps
  useEffect(() => {
    if (steps <= maxSteps && !speaks) {
      setStartTime(Date.now());
    }

  }, [steps, speaks])


  const handleUserKeyPress = useCallback(event => {
    console.log("space bar clicked");
    const { key, keyCode } = event;
    if (keyCode === 32 && !speaks) {
      checkShape(localShape, startTime);
    }
  })

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };

  }, [handleUserKeyPress, speaks]);


  function checkShape(localShape, startTime) {
    if (steps <= maxSteps && startTime) {

      setClick(true);
      const endTime = Date.now();
      const responseTime = endTime - startTime;
      setCurrentResponseTime(responseTime);
      setResponseTimes([...responseTimes, responseTime]);

      if (localShape !== shape) {
        setScore(score + 1);
        setSteps(steps + 1);
        setClick(false);
      }

      if (localShape == shape) {
        setSteps(steps + 1);
      }
    }
  }

  useEffect(() => {
    if (steps && steps <= maxSteps) {
      let wid = (100 - (currentResponseTime / 20));
      if (wid <= 0) {
        setCssWidth(0);
      } else {
        setCssWidth(100 - (currentResponseTime / 20));
      }
    }
  }, [currentResponseTime])


  useEffect(() => {
    let timerId;
    if (!isPaused && timeRemaining > 0) {
      timerId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [timeRemaining, isPaused]);

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const startTimer = () => {
    setIsPaused(false);
  }


  const minutes = Math.floor(timeRemaining / 60);
  const seconds = timeRemaining % 60;


  //Avg Accuracy of current round
  useEffect(() => {
    setAccuracyCurrentRound(score / maxSteps);
  }, [score])


  useEffect(() => {
    if (steps > maxSteps) {
      setTimeout(() => {
        setAboutToEnd(true);
      }, 1000)
    }
  }, [accuracyCurrentRound, steps])

  useEffect(() => {
    if (steps > maxSteps && aboutToEnd == true) {
      setRoundFinish(true);
    }
  }, [avgAccuracy, aboutToEnd])

  useEffect(() => {
    if ((steps > 0) && steps <= maxSteps && responseTimes.length > 0) {
      setAverageResponseTime((
        responseTimes.reduce((acc, curr) => acc + curr, 0) / responseTimes.length
      ).toFixed(0));
    }
  }, [responseTimes])


  useEffect(() => {
    const round = Math.floor(Math.random() * 6);
    var x = `case-${round}`;
    setCases(GoNoGoComplex[x])
  }, [])


  useEffect(() => {
    {
      if (!isLoading && !isPaused) {
        if (steps > 1 && click == false) {
          if (localShape == masterShape) {
            setScore(score + 1);
          }
        }
        const interval = setInterval(() => {
          setSteps(steps + 1);
          setClick(false);
        }, 4000);
        return () => clearInterval(interval);
      }
    }
  }, [steps, isLoading, isPaused]);


  useEffect(() => {
    if (steps <= maxSteps && cases) {
      let x = `step-${steps}`;
      setImageName(cases[x].shape)
      setType(cases[x].type);
      setDistraction(cases[x].distraction)
    }
  }, [steps])



  useEffect(() => {
    if (type === "audio" && steps) {
      setSpeaks(true);
      let x = `step-${steps}`;
      let shp = cases[x].shape;
      let translatedShapeName = t(`user.activity.go_no_go_complex.${shp}`);
      const selectedVoice = voices.find(voice => voice.lang.includes('it-IT'));

      setTimeout(() => {
        speak({ text: translatedShapeName, voice: selectedVoice });
        setSpeaks(false);
      }, 1500)
    }
  }, [type])

  useEffect(() => {
    if ((type === "visual" && distraction !== null && distraction) && steps) {
      var x = `step-${steps}`;
      setSpeaks(true);
      setTimeout(() => {

        let shp = distraction;
        let translatedShapeName = t(`user.activity.go_no_go_complex.${shp}`);
        const selectedVoice = voices.find(voice => voice.lang.includes('it-IT'));

        speak({ text: translatedShapeName, voice: selectedVoice });
        setSpeaks(false);
      }, 1500)
    }
  }, [distraction])

  useEffect(() => {
    if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && !free) {
      history.push(`/go-no-go-complex-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`);
    }
    else  if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && free) {
      history.push(`/go-no-go-complex-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`);
    }
  }, [activityStatus])


  return (
    <>
      {
        !roundFinish ?
          <>
            {(isLoading) ? <LazyLoader /> : null}
            <section className="reaction-speed-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <div className="stepsin stpelog">
                      <Link to="dashboard">
                        <img src={logo} className="logo-steps" alt="logo" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="btnNext-top">
                      <Link to={`/go-no-go-complex-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                        <button className="btn btn-primary btnNext">
                          {t("user.activity.reaction_speed.back")}
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="reaction-speed-p">
                      <p className="reaction-speed-p-1">{t('user.activity.go_no_go_complex.intro_heading')}</p>
                      <p className="reaction-speed-p-2 go-no-go-msg" dangerouslySetInnerHTML={{ __html: t('user.activity.go_no_go_visivo_acustico_simple.index_page_message') }}></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="reaction-speed-step">
                      <p>{`${(steps <= maxSteps) ? `${steps} / ${maxSteps}` : ''}`}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    {/* <h3>Score {score}</h3> */}
                    <div className="">
                      {
                        <>
                          {
                            type === "visual"
                              ?
                              <div className="fit-game-height" onClick={!speaks ? (e) => checkShape(localShape, startTime) : null}>
                                {imageUrls ?
                                  <img src={imageUrls} style={{ cursor: 'pointer' }} alt="Example" width="200" height="200" />
                                  : null
                                }
                              </div>
                              : (type === "audio")
                                ?
                                <div className="fit-game-height" onClick={!speaks ? (e) => checkShape(localShape, startTime) : null}>
                                  <img src={BWGroup1862} style={{ cursor: 'pointer' }} />
                                </div>
                                : <div className="fit-game-height"></div>
                          }
                          {/* <div className="go-no-go-progress-main">
                            <div className="go-no-go-progress-bar progress"> */}
                          {/* 2000 100%
                                                            2000/100 1%
                                                            20 = 1%
                                                            ex: 1000/20==50% */}
                          {/* <div className="progress-bar" role="progressbar" style={{ width: `${cssWidth}%`, backgroundColor: cssWidth < 25 ? '#ff0000' : cssWidth > 25 && cssWidth <= 35 ? '##ffa700' : cssWidth > 35 && cssWidth <= 50 ? '#fff400' : cssWidth > 50 && cssWidth <= 85 ? '#a3ff00' : '#2cba00' }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div> */}
                          <div className="go-no-go-progress-main">
                            <div className="d-flex">
                              <div className="go-no-go-progress-bar progress">
                                {/* 2000 100%
                                                            2000/100 1%
                                                            20 = 1%
                                                            ex: 1000/20==50% */}
                                <div className="progress-bar" role="progressbar" style={{ width: `${cssWidth}%`, backgroundColor: cssWidth <= 20 ? '#B02418' : cssWidth > 20 && cssWidth <= 40 ? '#F6BF42' : cssWidth > 40 && cssWidth <= 60 ? '#F9EF96' : cssWidth > 60 && cssWidth <= 80 ? '#9ECB61' : cssWidth > 80 && cssWidth <= 100 ? '#367B21' : '#00000000' }} aria-valuenow={cssWidth} aria-valuemin="0" aria-valuemax="100">
                                </div>
                              </div>
                              <div className="percentage-value">{Math.floor(cssWidth)}%</div>
                            </div>
                            <div className="justify-manage">
                              <div>{t('user.activity.go_no_go.slow')}</div>
                              <div>{t('user.activity.go_no_go.fast')}</div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
          :
          <GoNoGoComplexResume props={{ "round": round, "totalTime": totalTime, "resetRound": resetRound, "togglePause": togglePause, "startTimer": startTimer, "timeRemaining": timeRemaining, "avgAccuracy": avgAccuracy, "params": props?.data?.params, "totalAvgResponseTime": totalAvgResponseTime, "accuracyCurrentRound": accuracyCurrentRound, "averageResponseTime": averageResponseTime , "free":free }} />
      }
    </>
  );
}

