import React, { useState, useEffect } from "react";
import logo from "../../assets/Image/logo.svg";
import Group848 from "../../assets/Image/Group848.svg";
import Group3161 from "../../assets/Image/Icon1112.svg";
import Group792 from "../../assets/Image/Group792.svg";
import Group785 from "../../assets/Image/Group785.svg";
import Group849 from "../../assets/Image/Group849.svg";
import Group781 from "../../assets/Image/Group781.svg";
import Group784 from "../../assets/Image/Group784.svg";
import FISIOLOGICIICONA from "../../assets/Image/FISIOLOGICIICONA.svg";
import Group786 from "../../assets/Image/Group786.svg";
import INFOIcon from "../../assets/Image/INFOIcon.svg";
import Group8411 from "../../assets/Image/Group8411.svg";
import UTENTEonboarding04 from "../../assets/Image/UTENTEonboarding04.png";
import freq from "../../assets/Image/FREQ.CARDIACA_ICONA.svg";
import Group769 from "../../assets/Image/Group769.svg";
import Group923 from "../../assets/Image/Group923.svg";
import Group8412 from "../../assets/Image/Group8412.svg";
import Group811 from "../../assets/Image/Group811.svg";
import shape from "../../assets/Image/shape.svg";
import Group1029 from "../../assets/Image/Group102912.svg";
import Group1028 from "../../assets/Image/Group1028.svg";
import Group1030 from "../../assets/Image/Group1030.svg";
import Group8414 from "../../assets/Image/Group8414.svg";
import ANSIA_ICONA from "../../assets/Image/ANSIA_ICONA.svg";
import Group84115 from "../../assets/Image/Group84115.svg";
import GLICEMIA_ICONA1 from "../../assets/Image/GLICEMIA_ICONA1.svg";
import Group1179 from "../../assets/Image/Group117979.svg";
import CARDIOVASCOLARE_ICONA11 from "../../assets/Image/CARDIOVASCOLARE_ICONA11.svg";
import ATTACCO_CARDIACO_ICONA12 from "../../assets/Image/ATTACCO_CARDIACO_ICONA12.svg";
import Group8417 from "../../assets/Image/Group8417.svg";
import Group8418 from "../../assets/Image/Group8418.svg";
import Group9111 from "../../assets/Image/Group9111.svg";
import FORMACORPOREA_ICONA from "../../assets/Image/FORMACORPOREA_ICONA.svg";
import Group84191 from "../../assets/Image/Group84191.svg";
import Group924 from "../../assets/Image/Group924.svg";
import Group1183 from "../../assets/Image/Group1183.svg";
import indice from "../../assets/Image/indice.svg";
import Group1031 from "../../assets/Image/Group1031.svg";
import INFO_iconi from "../../assets/Image/INFO_iconi.svg";
import PRESSIONESANGUIGNA_ICONA from "../../assets/Image/PRESSIONE SANGUIGNA_ICONA.svg";
import Group846 from "../../assets/Image/Group846.svg";
import Group1142 from "../../assets/Image/Group1142.svg";
import Group847 from "../../assets/Image/Group847.svg";
import Group926 from "../../assets/Image/Group926.svg";
import Group1146 from "../../assets/Image/Group1146.svg";
import Group1462101 from "../../assets/Image/Group1462101.svg";
import Group1463101 from "../../assets/Image/Group1463101.svg";
import Group1464101 from "../../assets/Image/Group1464101.svg";
import Group1465101 from "../../assets/Image/Group1465101.svg";
import Group1466101 from "../../assets/Image/Group1466101.svg";
import Group114511 from "../../assets/Image/Group114511.svg";
import rishicoico from "../../assets/Image/rishico-ico.svg";
import IPERTENSIONE_ICONA from "../../assets/Image/IPERTENSIONE_ICONA.svg";
import Grouprishico from "../../assets/Image/Grouprishico.svg";
import DEPRESSIONE_ICONA from "../../assets/Image/DEPRESSIONE_ICONA.svg";
import DIABETE_ICONA from "../../assets/Image/DIABETE_ICONA.svg";
import { Link } from "react-router-dom";
import Modal from "../common/modal";
import logostep4 from "../../assets/Image/logostep4.svg";
import { setLocalStoarge, getLocalStoarge } from "../common/allfunction";
import { useTranslation } from "react-i18next";
import LazyLoader from "../common/lazyLoader";
function Steps() {
  const [modal0, setModal0] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);
  const [modal7, setModal7] = useState(false);
  const [modal8, setModal8] = useState(false);
  const [modal9, setModal9] = useState(false)
  const [allmodal, setallmodal] = useState(false);
  const [currenttab, setCurrenttab] = useState(1);
  const [Scropi, setScropi] = useState("Scropi");
  const [t, i18n] = useTranslation('common');
  const [btnText, setBtnText] = useState(`${t('user.steps.next')}`);
  // scroll mobile
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState("light");
  const [themeReal, setRealTheme]  = useState(getLocalStoarge("theme"));

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50 
  const changeRealTheme = ()=>{
    document.body.className = themeReal;
  }
  useEffect(() => {
    document.body.className = theme;
    setTimeout(()=>{
      setIsLoading(false)
    },1500)
    handleTranslationLang();
  }, []);
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    console.log("theme", theme)
    if (lang) {
      i18n.changeLanguage(lang)
    }
    setBtnText((`${t('user.steps.next')}`))
  }
  const toggleScropi = () => {
    setScropi(Scropi === "buttons width-w" ? "Scropi" : "buttons width-w");
  };

  const bgimg = (e) => {
    document.body.className = `${e ? "Scropi" : ""}`;
  };

  const nextpage = () => {
    var next = currenttab <= 3 ? currenttab + 1 : currenttab;
    var newtext = currenttab < 3 ? `${t('user.steps.next')}` : (`${t('user.steps.start')}`);
    setBtnText(newtext);
    setCurrenttab(next);
    changeDot(parseInt(next));
  };
  const prevpage = () => {
    var prev = currenttab >=2 ? currenttab - 1 : currenttab;
    setCurrenttab(prev);
    changeDot(parseInt(prev));
  };
  const changeDot = (newval) => {
    var newtext = newval < 4 ? `${t('user.steps.next')}` : (`${t('user.steps.start')}`);
    setBtnText(newtext);
    setCurrenttab(newval);
  };
  const handleSubmitData = ()=>{
    const check_measurment_status = getLocalStoarge("questionnare");
    if (parseInt(check_measurment_status) === 1) {
      setTimeout(() => {
        window.location.href = `/app/profile`;
      }, 1000);
    } else {
      setTimeout(() => {
        window.location.href = `/app/questionnare`;
      }, 1000);
  };
};



const onTouchStart = (e) => {
  setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
  setTouchStart(e.targetTouches[0].clientX)
}

const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

const onTouchEnd = () => {
  if (!touchStart || !touchEnd) return
  const distance = touchStart - touchEnd
  const isLeftSwipe = distance > minSwipeDistance
  const isRightSwipe = distance < -minSwipeDistance
  if (isLeftSwipe) {
    nextpage();
  } else if(isRightSwipe) {
    prevpage();
  }
 }

  const allmodalcontent = () => {
    return (
      <div>
        <div className="model-pop ">
          {/* <div className="modal-header"> */}
          <h5 className="modal-title pop_heading " id="exampleModalLabel">
            <button
              type="button"
              className="close"
              onClick={() => setallmodal(false)}
            >
              <img src={Group769} alt="icon" className="positionx step3img" />
            </button>
          </h5>
          <div className="pad-in newspace">
            <div className="w-100">
              <div className="bg-circ"></div>
              <div
                className="r-indice addingclass"
                id="pop1"
                onClick={() => setModal1(true)}
              >
                <img src={Group3161} alt="icon" />
                <p>{t('user.steps.heading_nav01')}</p>
              </div>
            </div>
            <div className="w50 pr-1">
              <div className="bg-c-rig"></div>
              <div
                className="r-indice addingclass"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => setModal2(true)}
              >
                <img src={Group786} alt="icon" />
                <p>{t('user.steps.heading_nav0')}</p>
              </div>
            </div>
            <div className="w50 pl-1">
              <div
                className="r-indice addingclass"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => setModal3(true)}
              >
                <img src={FISIOLOGICIICONA} alt="icon" />
                <p>{t('user.steps.heading_nav1')}</p>
              </div>
            </div>
            <div className="w50 pr-1">
              <div
                className="r-indice addingclass"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => setModal4(true)}
              >
                <img src={Group784} alt="icon" />
                <p>{t('user.steps.heading_nav2')}</p>
              </div>
            </div>
            <div className="w50 pl-1">
              <div
                className="r-indice addingclass"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => setModal5(true)}
              >
                <img src={Group792} alt="icon" />
                <p>{t('user.steps.heading_nav3')}</p>
              </div>
            </div>
            <div className="w50 pr-1">
              <div
                className="r-indice addingclass"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => setModal6(true)}
              >
                <img src={Group785} alt="icon" />
                <p>{t('user.steps.heading_nav4')}</p>
              </div>
            </div>
            <div className="w50 pl-1">
              <div
                className="r-indice addingclass"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => setModal7(true)}
              >
                <img src={Group781} alt="icon" />
                <p>{t('user.steps.heading_nav5')}</p>
              </div>
              <div className="b-imbg"></div>
            </div>
            <div className="w-100">
              <div
                className="r-indice addingclass"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => setModal8(true)}
              >
                <img src={Group849} alt="icon" />
                <p>
                  {t('user.steps.heading_nav6')} <small>({t('user.steps.heading_nav7')})</small>
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      // </div>
    );
  };
  const modal0Content = () => {
    return (
      <>
        <div className="model-pop">
          <div className="modal-header">
            <h5
              className="modal-title pop_heading left44"
              id="exampleModalLabel"
              dangerouslySetInnerHTML={{ __html: t('user.steps.ml_0.heading') }}
            >
            </h5>
            <button
              type="button"
              className="close"
              onClick={() => {
                setModal0(false);
                bgimg(false);
              }}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body padd45">
            <div className="row">
              <div className="col-md-12">
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_0.text_1') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_0.text_2') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_0.text_3') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_0.text_4') }}>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const modal1Content = () => {
    return (
      <div>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <div className="in-linw-w">
                <img src={Group1183} className="img-fluid" alt="icon" />
                </div> 
              {t('user.steps.ml_1.heading')}
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal1(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body scroll-pop mod1">
            <div className="row reverse-d">
              <div className="col-md-7">
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_1.text_1') }}>
                </p>
                <ul dangerouslySetInnerHTML={{ __html: t('user.steps.ml_1.text_2') }}>
                </ul>
                <p>
                  {t('user.steps.ml_1.text_3')}
                </p>
              </div>
              <div className="col-md-1 b-rigti"></div>
              <div className="col-md-4">
                <div className="righ-side poprw">
                  <div className="logo-shape">
                    <img src={shape} alt="icon" />
                    <p className="b-uono"> {t('user.steps.ml_1.buono')}</p>
                  </div>
                  <div className="w80">80</div>
                </div>
                <p className="umanlytic">
                  {t('user.steps.ml_1.text_4')}
                </p>
              </div>
            </div>
            <button
              className="btn btn-primary Ho"
              onClick={() => setModal1(false)}
            >
              {t('user.steps.ml_1.btntxt')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const modal2Content = () => {
    return (
      <>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
            <div className="in-linw-w">
                <img src={Group8411} className="img-fluid" alt="icon" />
                </div>
              {t('user.steps.ml_2.heading')}
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal2(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body scroll-pop">
            <div className="row">
              <div className="col-md-7 right_lines">
                <p className="fpnt16">
                  <img src={freq} alt="icon" /> <strong>{t('user.steps.ml_2.heading_1_1')}</strong>                  
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_1_1') }}>
                </p>
                <span dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_1_2') }}>
                </span>
              </div>

              <div className="col-md-5 gr-per">
                <img src={Group1028} className="w-100 mb-2" alt="icon" />
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_1_3') }}>
                </p>
              </div>
            </div>

            <div className="row mb-3 d-flex align-items-center">
              <div className="col-md-7 right_lines">
                <p className="fpnt16">
                  <img src={Group1142} alt="icon" /> <strong>{t('user.steps.ml_2.heading_2')}</strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_2_1') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_2_2') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_2_3') }}>
                </p>
              </div>
              <div className="col-md-5 gr-per d-flex- align-items-center">
                <img src={Group847} className="w-100 mb-2" alt="icon" />
              </div>
            </div>

            <div className="row mb-3 d-flex align-items-center">
              <div className="col-md-7 right_lines">
                <p className="fpnt16">
                  <img src={PRESSIONESANGUIGNA_ICONA} alt="icon" /> <strong>{t('user.steps.ml_2.heading_3')}</strong>
                </p>
                <span className="fpnt16 color-pe">
                  <strong>{t('user.steps.ml_2.text_3_1')}</strong>
                </span>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_3_2') }}>

                </p>
                <span className='fpnt16 color-pe' dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_3_3') }}>
                </span>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_2.text_3_4') }}>
                </p>
              </div>
              <div className="col-md-5 gr-per">
                <img src={Group846} className="w-100 mb-2" alt="icon" />
              </div>
            </div>
            <button
              className="btn btn-primary Ho"
              onClick={() => setModal2(false)}
            >
              {t('user.steps.ml_2.btntxt')}
            </button>
          </div>
        </div>
      </>
    );
  };

  const modal3Content = () => {
    return (
      <>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <div className="in-linw-w">
                <img src={Group8412} className="img-fluid" alt="icon" />
                </div>
                {t('user.steps.ml_3.heading')}
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal3(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body scroll-pop">
            <div className="row">
              <div className="mar-b">
                <div className="col-md-7 r-linerigti">
                  <p className="fpnt16">
                    <img src={Group923} alt="icon" /> <strong>{t('user.steps.ml_3.heading_1')}</strong>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_3.text_1_1') }}>                   
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_3.text_1_2') }}>
                  </p>
                </div>
                <div className="col-md-5 d-flex align-items-center gr-per">
                  <img src={Group1029} className="w-100" alt="icon" />
                </div>
              </div>
              <div className="mar-b">
                <div className="col-md-7 r-linerigti">
                  <p className="fpnt16">
                    <img src={Group924} alt="icon" /> <strong>{t('user.steps.ml_3.heading_2')}</strong>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_3.text_2_1') }}>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_3.text_2_2') }}>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_3.text_2_3') }}>
                  </p>
                </div>
                <div className="col-md-5 d-flex align-items-center gr-per">
                  <img src={Group114511} className="w-100" alt="icon" />
                </div>
              </div>
              <div className="col-md-12 r-linerigti">
                <p className="fpnt16">
                  <img src={Group926} alt="icon" /> <strong>{t('user.steps.ml_3.heading_3')}</strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_3.text_3_1') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_3.text_3_2') }}>
                </p>
              </div>
            </div>
            <button
              className="btn btn-primary Ho"
              onClick={() => setModal3(false)}
            >
              {t('user.steps.ml_3.btntxt')}
            </button>
          </div>
        </div>
      </>
    );
  };

  const modal4Content = () => {
    return (
      <>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <div className="in-linw-w">
                <img src={Group84191} className="img-fluid" alt="icon" />
                </div>
               {t('user.steps.ml_4.heading_1')}
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal4(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body scroll-pop">
            <div className="row">
              <div className="mar-b">
                <div className="col-md-7 r-linerigti">
                  <p className="fpnt16">
                    <img src={indice} alt="icon" /> <strong> {t('user.steps.ml_4.text_1_1')}</strong>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_4.text_1_2') }}>
                  </p>
                  <span className="color-pe">{t('user.steps.ml_4.text_1_3')}</span>
                  <br />
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_4.text_1_4') }}>
                  </p>
                </div>
                <div className="col-md-5 d-flex  align-items-center">
                  <img src={Group1030} className="img-fluid" alt="icon" />
                </div>
              </div>
              <div className="col-md-12">
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_4.text_1_5') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_4.text_1_6') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_4.text_1_7') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_4.text_1_8') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_4.text_1_9') }}>
                </p>
              </div>
            </div>
            <button
              className="btn btn-primary Ho"
              onClick={() => setModal4(false)}
            >
              {t('user.steps.ml_4.btntxt')}
            </button>
          </div>
        </div>
      </>
    );
  };

  const modal5Content = () => {
    return (
      <>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <div className="in-linw-w"><img src={Group8418} className="img-fluid" alt="icon" />
                </div>
                {t('user.steps.ml_5.heading')}
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal5(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body scroll-pop">
            <div className="row mar-b">
           
              <div className="col-md-7 r-linerigti">
              <p className="fpnt16">
                  <img src={Group9111} alt="icon" /> <strong> {t('user.steps.ml_5.heading_1')} </strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_5.text_1_1') }}>
                </p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1179} className="w-100" alt="icon" />
              </div>
            </div>

            <div className="row mar-b">
             <div className="col-md-7 r-linerigti">
               <p className="fpnt16">
                  <img src={FORMACORPOREA_ICONA} alt="icon" /> <strong>{t('user.steps.ml_5.heading_2')}</strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_5.text_2_1') }}>
                </p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1146} className="w-100" alt="icon" />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p className="fpnt16">
                  <img src={FORMACORPOREA_ICONA} alt="icon" />
                  <strong> {t('user.steps.ml_5.heading_3')} </strong>
                </p>
              </div>
              <div className="col-md-7 r-linerigti">
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_5.text_3_1') }}>
                </p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1466101} className="w-100" alt="icon" />
              </div>
            </div>
            <button
              className="btn btn-primary Ho"
              onClick={() => setModal5(false)}
            >
              {t('user.steps.ml_5.btntxt')}
            </button>
          </div>
        </div>
      </>
    );
  };

  const modal6Content = () => {
    return (
      <>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <div className="in-linw-w">
                <img src={Group8417} className="img-fluid" alt="icon" />
               </div>
              {t('user.steps.ml_6.heading')}
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal6(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
           <div className="modal-body scroll-pop">
            <div className="row mar-b">
             <div className="col-md-7 r-linerigti">
               <p className="fpnt16">
                  <img src={CARDIOVASCOLARE_ICONA11} alt="icon" /> <strong>{t('user.steps.ml_6.heading_1')}</strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_6.text_1_1') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_6.text_1_2') }}>
                </p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1462101} className="w-100" alt="icon" />
              </div>
            </div>

            <div className="row mar-b">
              <div className="col-md-7 r-linerigti">
               <p className="fpnt16">
                  <img src={ATTACCO_CARDIACO_ICONA12} alt="icon" /> <strong>{t('user.steps.ml_6.heading_2')} </strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_6.text_2_1') }}>
                </p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1463101} className="w-100" alt="icon" />
              </div>
            </div>
            <div className="row mar-b">
            <div className="col-md-7 r-linerigti">
              <p className="fpnt16">
                  <img src={rishicoico} alt="icon" /> <strong> {t('user.steps.ml_6.heading_3')} </strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_6.text_3_1') }}>
                </p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1464101} className="w-100" alt="icon" />
              </div>
            </div>
            <div className="row mar-b">
              <div className="col-md-7 r-linerigti">
                <p className="fpnt16">
                  <img src={IPERTENSIONE_ICONA} alt="icon" /> <strong>  {t('user.steps.ml_6.heading_4')} </strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_6.text_4_1') }}>
                </p>
                <p>
                  {t('user.steps.ml_6.text_4_2')}
                </p>
              </div>
              <div className="col-md-5 d-flex align-items-center gr-per">
                <img src={Group1465101} className="w-100" alt="icon" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  {t('user.steps.ml_6.text_4_3')}
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_6.text_4_4') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_6.text_4_5') }}>
                </p>
              </div>
            </div>
            <button
              className="btn btn-primary Ho"
              onClick={() => setModal6(false)}
            >
              {t('user.steps.ml_5.btntxt')}
            </button>
          </div>
        </div>
      </>
    );
  };
  const modal7Content = () => {
    return (
      <>
        <div className="model-pop">
          <div className="modal-header">
            <h5 className="modal-title pop_heading" id="exampleModalLabel">
              <div className="in-linw-w">
                <img src={Group84115} className="img-fluid" alt="icon" />
                </div>
               {t('user.steps.ml_7.heading')}
            </h5>
            <button
              type="button"
              className="close r0-side"
              onClick={() => setModal7(false)}
            >
              <img src={Group769} alt="icon" />
            </button>
          </div>
          <div className="modal-body scroll-pop">
            <div className="row">
              <div className="mar-b">
                <div className="col-md-7 r-linerigti">
                 <p className="fpnt16">
                   <img src={GLICEMIA_ICONA1} alt="icon" />&nbsp; <strong>{t('user.steps.ml_7.heading_1')}
                   </strong>
                  </p>
                  <p className="mb-0" dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_1_1') }}>
                  </p>
                </div>
                <div className="col-md-5 d-flex align-items-center gr-per">
                  <img src={Group1465101} className="w-100" alt="icon" />
                </div>
              </div>
              <div className="col-md-12">
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_1_2') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_1_3') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_1_4') }}>
                </p>
              </div>
         
              <div className="mar-b">
                <div className="col-md-7 r-linerigti">
                <p className="fpnt16">
                  <img src={Grouprishico} alt="icon" /> <strong>{t('user.steps.ml_7.heading_2')}</strong>
                </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_2_1') }}>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_2_2') }}>
                  </p>
                </div>
                <div className="col-md-5 d-flex align-items-center gr-per">
                  <img src={Group1465101} className="w-100" alt="icon" />
                </div>
              </div>
              <div className="col-md-12">
                {t('user.steps.ml_7.text_2_3')}
                <p>
                  <ul className="norm-g">
                    <li dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_2_4') }}>
                    </li>
                  </ul>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_2_5') }}>
                </p>
              </div>
              <div className="col-md-12">
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_2_6') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_2_7') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_2_8') }}>
                </p>
              </div>
       
              <div className="mar-b">
                <div className="col-md-7 r-linerigti">
                <p className="fpnt16">
                  <img src={DIABETE_ICONA} alt="icon" /> <strong>{t('user.steps.ml_7.heading_3')}</strong>
                </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_3_1') }}>
                  </p>
                </div>
                <div className="col-md-5 d-flex align-items-center gr-per">
                  <img src={Group1465101} className="w-100" alt="icon" />
                </div>
              </div>
              <div className="col-md-12">
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_3_2') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_3_3') }}>
                </p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_7.text_3_4') }}>
                </p>
              </div>
            </div>
            <button
              className="btn btn-primary Ho"
              onClick={() => setModal7(false)}
            >
              {t('user.steps.ml_7.btntxt')}
            </button>
          </div>
        </div>
      </>
    );
  };
  const modal8Content = () => {
    return (
      <>
        <div className="model-pop">
          <div className="bg-blur">
            <div className="modal-header">
              <h5 className="modal-title pop_heading" id="exampleModalLabel">
               <div className="in-linw-w">
                  <img src={Group8414} className="img-fluid" alt="icon" />
                  </div>
                {t('user.steps.ml_8.heading')}
              </h5>
              <button
                type="button"
                className="close r0-side"
                onClick={() => setModal8(false)}
              >
                <img src={Group769} alt="icon" />
              </button>
            </div>
            <div className="modal-body scroll-pop">
              <div className="row">
                <div className="col-md-12">
                  <p className="fpnt16">
                    <img src={ANSIA_ICONA} alt="icon" /> <strong>{t('user.steps.ml_8.heading_1')}
                    </strong>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_8.text_1_1') }}>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_8.text_1_2') }}>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_8.text_1_3') }}>
                  </p>
                  <p className="fpnt16">
                    <img src={DEPRESSIONE_ICONA} alt="icon" /> <strong>{t('user.steps.ml_8.heading_2')}</strong>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_8.text_2_1') }}>

                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_8.text_2_2') }}>

                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_8.text_2_3') }}>
                  </p>
                  <p className="fpnt16">
                    <img src={DEPRESSIONE_ICONA} alt="icon" /> <strong>{t('user.steps.ml_8.heading_3')}</strong>
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_8.text_3_1') }}>

                  </p>
                  <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_8.text_3_2') }}>

                  </p>
                </div>
              </div>
              <button
                className="btn btn-primary Ho"
                onClick={() => setModal8(false)}
              >
                {t('user.steps.ml_8.btntxt')}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  const modal9Content = () => {
    return (
      <>
        <div className="container">
          <div className="row">
            <button
              type="button"
              className="close"
              onClick={() => setModal9(false)}
            >
              <img src={Group769} alt="icon" className="positionx" />
            </button>
            <div className="Prima">
              <h2 className="otp-heading mt-0 prima-ltext">{t('user.steps.ml_9.heading')}
              </h2>
              <div className="scroll-y mbn"><p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_9.text_1_1')}}></p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_9.text_1_2') }}></p>
                <p dangerouslySetInnerHTML={{ __html: t('user.steps.ml_9.text_1_3') }}></p>
                <div className="text-right mobilr-hh">
                  <a onClick={()=> handleSubmitData()} className="buttons register-btn">{t('user.steps.ml_9.confirmbtn')}
                  </a>
                </div>
              </div>
              <div className="text-right hodi-c">
                <a onClick={()=> handleSubmitData()} className="buttons">{t('user.steps.ml_9.confirmbtn')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal title="" size="lg" onClose={() => setallmodal(false)} show={allmodal}>
        {allmodalcontent()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal0(false)} show={modal0}>
        {modal0Content()}
      </Modal>
      <Modal title="" size="lg" onClose={() => setModal1(false)} show={modal1}>
        {modal1Content()}
      </Modal>
      <Modal
        title=""
        contentClasses="py-3"
        size="lg"
        onClose={() => setModal2(false)}
        show={modal2}
      >
        {modal2Content()}
      </Modal>
      <Modal
        title=""
        contentClasses="py-3"
        size="lg"
        onClose={() => setModal3(false)}
        show={modal3}
      >
        {modal3Content()}
      </Modal>
      <Modal
        title=""
        contentClasses="py-3"
        size="lg"
        onClose={() => setModal4(false)}
        show={modal4}
      >
        {modal4Content()}
      </Modal>
      <Modal
        title=""
        contentClasses="py-3"
        size="lg"
        onClose={() => setModal5(false)}
        show={modal5}
      >
        {modal5Content()}
      </Modal>
      <Modal
        title=""
        contentClasses="py-3"
        size="lg"
        onClose={() => setModal6(false)}
        show={modal6}
      >
        {modal6Content()}
      </Modal>
      <Modal
        title=""
        contentClasses="py-3"
        size="lg"
        onClose={() => setModal7(false)}
        show={modal7}
      >
        {modal7Content()}
      </Modal>
      <Modal
        title=""
        contentClasses="py-3"
        size="lg"
        onClose={() => setModal8(false)}
        show={modal8}
      >
        {modal8Content()}
      </Modal>
      <Modal
        title=""
        contentClasses="py-3 mx-w630"
        size="lg"
        onClose={() => setModal9(false)}
        show={modal9}
      >
        {modal9Content()}
      </Modal>
      

      {/* Steps--- Main-page */}
      <section className="steps-process steps-buttonchange" >
      {isLoading ? <LazyLoader /> : null}
        <div className="container-fluid">
          <div className="row">
            <div className="stepsin stpelog">
              <Link to="/dashboard">
                <img src={logo} className="logo-steps" alt="logo" />
              </Link>
            </div>
            <div className="col-md-12 p-l-0">
              <div className="tabControl" >
                <div className="tab-content" id="pills-tabContent" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                  <div className={`tab-pane fade bgsteps1 ${currenttab === 1 ? "show active" : ""
                    }`}
                    id="pills-1"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab">
                     <div className="fl-xw  set-middlepart">
                       <div className="row Topspace on-responsive reverse-d rew-full ipad-tmanage">
                      <div className="col-md-7 leftsteps">
                        <div className="pal-70">
                          <h2 className="steps-heading top-Align">
                            {t('user.steps.pg_1.text.heading')}
                          </h2>
                          <p className="deskt-n" dangerouslySetInnerHTML={{ __html: t('user.steps.pg_1.text.line1') }}></p>
                          <p className="visi-none visi-project" dangerouslySetInnerHTML={{ __html: t('user.steps.pg_1.text.visinone') }}></p>
                        </div>
                      </div>
                      <div className="col-md-5 text-center on-spacmob">
                        <img
                          src={Group848}
                          className="img-fluid mang-imgi"
                          alt="icon"
                        />
                      </div>
                      <div className="btnNext-top">
                        <button
                          className="btn btn-primary btnNext ipdnn visi-none"
                          onClick={() => changeDot(4)}>
                          {t('user.steps.pg_1.text.nxtbtn')}
                        </button>
                      </div>
                      </div>
                    </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist">
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                              aria-controls="first"
                              aria-selected="true"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab "
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                              aria-controls="second"
                              aria-selected="false"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                              aria-controls="third"
                              aria-selected="false"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contract-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                              aria-controls="fourth"
                              aria-selected="false"
                            ></a>
                          </li>
                        </ul>
                      </div>
                      <span className="mob-bott" onClick={() => changeDot(4)}>
                        {t('user.steps.pg_1.text.nxtbtn')}
                      </span>
                      <button
                        className="btn btn-primary btnNext"
                        onClick={nextpage}
                      >
                        {btnText}
                      </button>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade bgsteps2 ${currenttab === 2 ? "show active" : ""
                      }`}
                    id="pills-2"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                     <div className="fl-xw  set-middlepart">
                         <div className="row Topspace on-responsive reverse-d rew-full ipad-tmanage">
                      <div className="col-md-7 leftsteps">
                        <div className="pal-70">
                          <h2 className="steps-heading top-Align" dangerouslySetInnerHTML={{ __html: t('user.steps.pg_2.text.heading') }}></h2>
                          <p className="deskt-n">
                            <span dangerouslySetInnerHTML={{ __html: t('user.steps.pg_2.text.line1') }}></span>
                            <img
                              src={INFO_iconi}
                              onClick={(e) => {
                                setModal0(true);
                                bgimg(true);
                              }}
                              className="img-fluid i-l"
                              alt="icon"
                            />
                          </p>

                          <div className="visi-none" >
                            <p className="visi-project" dangerouslySetInnerHTML={{ __html: t('user.steps.pg_2.text.visinone') }}></p>
                            <a
                              className="buttons width-w h-auto"
                              id="pop0"
                              onClick={(e) => {
                                setModal0(true);
                                bgimg(true);
                              }}
                              onChange={toggleScropi}
                            >
                              <img src={INFOIcon} alt="icon" /> {t('user.steps.pg_2.text.infobtn')}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 on-spacmob text-center">
                        {/* <img src={Groupcircle} className="tranfer-origin" alt="icon" /> */}
                        <img
                          src={Group811}
                          className="img-fluid mang-imgi"
                          alt="icon"
                        />
                      </div>
                       </div>
                     </div>
                    <div className="btnNext-top">
                      <button
                        className="btn btn-primary btnNext ipdnn visi-none"
                        onClick={() => changeDot(4)}
                      >
                        {t('user.steps.pg_2.text.nxtbtn')}
                      </button>
                    </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist">
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                              aria-controls="first"
                              aria-selected="true"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab "
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                              aria-controls="second"
                              aria-selected="false"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                              aria-controls="third"
                              aria-selected="false"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contract-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                              aria-controls="fourth"
                              aria-selected="false"
                            ></a>
                          </li>
                        </ul>
                      </div>
                      <span className="mob-bott" onClick={() => changeDot(4)}>
                        {t('user.steps.pg_2.text.nxtbtn')}
                      </span>
                      <button
                        className="btn btn-primary btnNext"
                        onClick={nextpage}
                      >
                        {btnText}
                      </button>
                    </div>
                  </div>
                  <div
                    className={`tab-pane fade bgsteps3 ipasbg3 ${currenttab === 3 ? "show active" : ""
                      }`}
                    id="pills-3"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                  <div className="fl-xw set-middlepart">
                    <div className="row Topspace on-responsive reverse-d rew-full padmin ipad-tmanage">
                      <div className="col-md-6 leftsteps iptopvalue">
                        <div className="pal-70">
                          <h2 className="steps-heading top-Align">
                            {t('user.steps.pg_3.text.heading')}
                          </h2>
                          {/* <p> */}
                            <p className="dak-c visi-none visi-project" dangerouslySetInnerHTML={{ __html: t('user.steps.pg_3.text.line1') }}></p>
                            {/* <img
                              src={INFO_iconi}
                              onClick={(e) => {
                                setallmodal(true);
                                bgimg(true);
                              }}
                              className="img-fluid i-l deskt-n"
                              alt="icon"
                            /> */}
                          {/* </p> */}
                          <p className="dak-c visi-none visi-project" dangerouslySetInnerHTML={{ __html: t('user.steps.pg_3.text.visinone') }}></p>
                        </div>
                      </div>
                      <div className="col-md-6 on-spacmob text-center ipdtexrs leftsteps">
                        <div className="row">
                          <img
                            src={Group1031}
                            className="img-fluid mang-imgi deskt-n"
                            alt="icon"
                          />
                          <div className="pad-in visi-none">
                            <div className="col-md-8">
                              <div className="bg-circ"></div>
                              <div
                                className="r-indice visi-project"
                                id="pop1"
                                onClick={() => setModal1(true)}
                              >
                                <img src={Group3161} alt="icon" />
                                <p>{t('user.steps.ml_1.heading')}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="bg-c-rig"></div>
                              <div
                                className="r-indice visi-project"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setModal2(true)}
                              >
                                <img src={Group786} alt="icon" />
                                <p>{t('user.steps.heading_nav0')}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className="r-indice visi-project"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setModal3(true)}
                              >
                                <img src={FISIOLOGICIICONA} alt="icon" />
                                <p>{t('user.steps.heading_nav1')}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className="r-indice visi-project"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setModal4(true)}
                              >
                                <img src={Group784} alt="icon" />
                                <p>{t('user.steps.heading_nav2')}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className="r-indice visi-project"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setModal5(true)}
                              >
                                <img src={Group792} alt="icon" />
                                <p>{t('user.steps.heading_nav3')}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className="r-indice visi-project"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setModal6(true)}
                              >
                                <img src={Group785} alt="icon" />
                                <p>{t('user.steps.heading_nav4')}</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className="r-indice visi-project"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setModal7(true)}
                              >
                                <img src={Group781} alt="icon" />
                                <p>{t('user.steps.heading_nav5')}</p>
                              </div>
                              <div className="b-imbg"></div>
                            </div>
                            <div className="col-md-4">
                              <div
                                className="r-indice visi-project"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setModal8(true)}
                              >
                                <img src={Group849} alt="icon" />
                                <p>
                                  {t('user.steps.heading_nav6')} <small>({t('user.steps.heading_nav7')})</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                       </div>
                     </div>
                    <div className="btnNext-top">
                      <button
                        className="btn btn-primary btnNext ipdnn visi-none"
                        onClick={() => changeDot(4)}
                      >
                        {t('user.steps.btn_text')}
                      </button>
                     </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist">
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 1 ? "active" : ""
                                }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                              aria-controls="first"
                              aria-selected="true"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 2 ? "active" : ""
                                }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab "
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                              aria-controls="second"
                              aria-selected="false"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 3 ? "active" : ""
                                }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                              aria-controls="third"
                              aria-selected="false"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${currenttab === 4 ? "active" : ""
                                }`}
                              onClick={() => changeDot(4)}
                              id="pills-contract-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                              aria-controls="fourth"
                              aria-selected="false"
                            ></a>
                          </li>
                        </ul>
                      </div>
                      <span className="mob-bott" onClick={() => changeDot(4)}>
                        {t('user.steps.btn_text')}
                      </span>
                      <button
                        className="btn btn-primary btnNext"
                        onClick={nextpage}
                      >
                        {btnText}
                      </button>
                    </div>
                   </div>
                  <div
                    className={`tab-pane fade bgsteps4 ${currenttab === 4 ? "show active" : ""
                      }`}
                    id="pills-4"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                    style={{ backgroundImage: `url(${UTENTEonboarding04})` }}
                  >
                    <div className="fl-xw">
                    <div className="row Topspace rig70 right0 medium-fd on-responsive rew-full flex-d-c">
                      <div className="col-md-12">
                        <h2 className="steps-heading top-Align pal-70" dangerouslySetInnerHTML={{ __html: t('user.steps.pg_4.text.heading') }}>
                        </h2>
                      </div>
                      <div className="m-valign">
                        <div className="col-md-6 step4apd">
                          <div className="pal-70">
                            <div className="deskt-n">
                              <p dangerouslySetInnerHTML={{ __html: t('user.steps.pg_4.text.line1_1') }}>
                              </p>
                              <p dangerouslySetInnerHTML={{ __html: t('user.steps.pg_4.text.line1_2') }}>
                              </p>
                            </div>
                            <div className="visi-none visi-project">
                              <p dangerouslySetInnerHTML={{ __html: t('user.steps.pg_4.text.visinone_L1') }}>
                              </p>
                              <p dangerouslySetInnerHTML={{ __html: t('user.steps.pg_4.text.visinone_L2') }}>
                              </p>
                            </div>
                          </div>

                        </div>

                        <div className="col-md-6 step4apd">
                          {/* <div class="col-md-6"> */}
                          <div className="mi-r">
                            {/* <img
                              src={logostep4}
                              className="img-fluid sizeis"
                              alt="icon_logo"
                            /> */}
                            {/* <h2 className="steps-heading top-Align pal-80"><br/></h2> */}
                            <div className="visi-none visi-project">
                              <p dangerouslySetInnerHTML={{ __html: t('user.steps.pg_4.text.visinone_R1') }}>
                              </p>
                              <p dangerouslySetInnerHTML={{ __html: t('user.steps.pg_4.text.visinone_R2') }}>
                              </p>
                              {/* <img
                                src={logostep4}
                                className="img-fluid ipevolvity"
                                alt="icon_logo"
                              /> */}
                            </div>
                          </div>
                         </div>
                        </div>
                       </div>
                      </div>
                    <div id="btnNext">
                      <div className="fit-bdots">
                        <ul
                          className="nav nav-pills nav-fill"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${
                                currenttab === 1 ? "active" : ""
                              }`}
                              onClick={() => changeDot(1)}
                              id="pills-home-tab"
                              data-toggle="pill"
                              href="#pills-1"
                              role="tab"
                              aria-controls="first"
                              aria-selected="true"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${
                                currenttab === 2 ? "active" : ""
                              }`}
                              onClick={() => changeDot(2)}
                              id="pills-profile-tab "
                              data-toggle="pill"
                              href="#pills-2"
                              role="tab"
                              aria-controls="second"
                              aria-selected="false"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${
                                currenttab === 3 ? "active" : ""
                              }`}
                              onClick={() => changeDot(3)}
                              id="pills-contact-tab"
                              data-toggle="pill"
                              href="#pills-3"
                              role="tab"
                              aria-controls="third"
                              aria-selected="false"
                            ></a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link  ${
                                currenttab === 4 ? "active" : ""
                              }`}
                              onClick={() => changeDot(4)}
                              id="pills-contract-tab"
                              data-toggle="pill"
                              href="#pills-4"
                              role="tab"
                              aria-controls="fourth"
                              aria-selected="false"
                            ></a>
                          </li>
                        </ul>
                      </div>
                      {/* <span className="mob-bott" onClick={() => changeDot(4)}>
                        Salta
                      </span> */}
                      <button
                        className="btn btn-primary btnNext"
                        // onClick={nextpage}
                        // onClick={() => {
                        //   setModal0(false);
                        //   bgimg(false);}}
                        onClick={() => {setModal9(true); changeRealTheme()}}
                      >
    

                        {btnText}
                      </button>
                    </div>
                    </div>
                  </div>         
                </div>
              </div>
            </div>
          </div>
      </section>
    </>
  );
}

export default Steps;
