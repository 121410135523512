
import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "../common/modal";
import measure_group from '../../assets/Image/measure-group.svg'
import closeIcon from '../../assets/Image/CLOSE_ICONA.png'
import step1Ins1 from '../../assets/Image/instruction/Group_1917.png'
import step1Ins2 from '../../assets/Image/instruction/Group_1918.png'
import step1Ins3 from '../../assets/Image/instruction/step3correct.png'
import step21 from '../../assets/Image/instruction/step2-1.svg'
import step22 from '../../assets/Image/instruction/step2-2.png'
import eye from '../../assets/Image/instruction/eye.png'
import eyeCorrect from '../../assets/Image/instruction/eye-correct.png'
import angle from '../../assets/Image/instruction/angle.png'
import faceScan from '../../assets/Image/instruction/face-scan.png'
import face from '../../assets/Image/instruction/face.png'
import step1bulbIcon from '../../assets/Image/instruction/Group1834.png'
import Group_inst13822 from '../../assets/Image/instruction/Group_inst13822.svg'
import Ellipse145 from '../../assets/Image/instruction/Ellipse145.png'
import Ellipse146 from '../../assets/Image/instruction/Ellipse146.png'
import Ellipse147 from '../../assets/Image/instruction/Ellipse147.png'
import Ellipse148 from '../../assets/Image/instruction/Ellipse148.png'
import Ellipse149 from '../../assets/Image/instruction/Ellipse149.png'


import { useTranslation } from "react-i18next";
function instructionModal(props) {
    const [instruction, setInstruction] = useState(1);
    const [modalIns1, setModalIns1] = useState(props.popUpEnable);
    const [t, i18n] = useTranslation("common");
    const [popUp1, setPopup1] = useState(false);
    const [popUp2, setPopup2] = useState(false);
    const [popUp3, setPopup3] = useState(false);
    useEffect(() => {
    (modalIns1 == "true" || modalIns1 == 1) ?
        setPopup1(true)
        : (modalIns1 == 2) ? setPopup2(true) : setPopup3(true);
    }, [modalIns1]);
    const closeModal = () => {
        setPopup1(false);
        setPopup2(false);
        setPopup3(false);
    }
    const instPopUp1 = () => {
        return (
            <>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={measure_group} alt="icon" style={{ width: '50px' }} />
                            </div>
                            <strong> {t("user.dashboard.instruction")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { (props.handleModal) ? props.handleModal() : closeModal() }}
                        >
                            <img src={closeIcon} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body">

                        <div className={`row justify-content-center ${(instruction == 1) ? '' : 'd-none'}`}>
                            <div className="col-4">
                                <img src={step1Ins1} className="w-100" />
                                <img src={step1Ins2} className="pt-2 w-100" />
                            </div>
                            <div className="col-5 d-flex align-items-center">
                                <img src={step1Ins3} className="w-100" />
                            </div>
                            <div className="col-9 pt-3 d-flex">
                                <img src={step1bulbIcon} style={{ width: '50px', height: "50px" }} /><p className="pl-3">{t("user.dashboard.instruction_step1")}</p>
                            </div>
                        </div>
                        <div className={`row justify-content-center ${(instruction == 2) ? '' : 'd-none'}`}>
                            <div className="col-5">

                                <img src={step21} className="w-100 pt-5 pb-5" />

                            </div>
                            <div className="col-5">
                                <img src={step22} className="pt-5 pb-5 w-100 " />
                            </div>
                            <div className="col-10 pt-3 d-flex">
                                <img src={eye} style={{ width: '50px', height: "50px" }} /><p className="pl-3">{t("user.dashboard.instruction_step2")}</p>
                            </div>
                        </div>
                        <div className={`row justify-content-center ${(instruction == 3) ? '' : 'd-none'}`}>
                            <div className="col-7">

                                <img src={eyeCorrect} className="w-100" />

                            </div>
                            <div className="col-9 pt-3 d-flex">
                                <img src={angle} style={{ width: '50px', height: "50px" }} /><p className="pl-3">{t("user.dashboard.instruction_step3")}</p>
                            </div>
                        </div>
                        <div className={`row justify-content-center ${(instruction == 4) ? '' : 'd-none'}`}>
                            <div className="col-7">

                                <img src={faceScan} className="w-100" />

                            </div>
                            <div className="col-9 pt-3 d-flex">
                                <img src={face} style={{ width: '50px', height: "50px" }} />
                                <p className="pl-3"
                              dangerouslySetInnerHTML={{
                                __html: t("user.dashboard.instruction_step4"),
                              }}
                            ></p>
                            </div>
                        </div>
                    </div>
                    <div className={`modal-footer d-flex  ${(instruction == 1) ? null : 'justify-content-between'} `} style={{ 'border-top': 0 }}>
                        <div class="col-sm-6">
                            {(instruction == 1) ? null : <button class="ricontrolla visi-none" style={{float: "left"}} onClick={() => setInstruction(instruction - 1)}>{t("user.dashboard.instruction_step_preview")}</button>}
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            {(instruction == 4) ?  <button class="ricontrolla visi-none" onClick={() => { (props.handleModal) ? props.handleModal() : closeModal() }}>{t("user.sidebar.btntxt")}</button>
 : <button class="ricontrolla visi-none d-block" onClick={() => setInstruction(instruction + 1)}>{t("user.dashboard.instruction_step_next")}</button>}
                            
                        </div>
                    </div>
                </div></>
        );
    }
    const instPopUp2 = () => {
        return (
            <>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Group_inst13822} alt="icon" style={{ width: '50px' }} />
                            </div>
                            <strong> {t("user.sidebar.status")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { (props.handleModal) ? props.handleModal() : closeModal() }}

                        >
                            <img src={closeIcon} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body">

                    <div className={`row justify-content-center 'd-none'}`}>
                            <div className="col-2">
                                <img src={Ellipse145} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.very_poor")}</span>
                            </div>
                            <div className="col-2">
                                <img src={Ellipse146} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.poor")}</span>
                            </div>
                             <div className="col-2">
                                <img src={Ellipse147} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.sufficient")}</span>
                            </div>
                            <div className="col-2">
                                <img src={Ellipse148} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.good")}</span>
                            </div>
                            <div className="col-2">
                                <img src={Ellipse149} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.excellent")}</span>
                            </div>
                    </div>
                       
                    </div>
                    <div className={`modal-footer d-flex  ${(instruction == 1) ? null : 'justify-content-between'} `} style={{ 'border-top': 0 }}>
                      
                        <div class="col-sm-6 d-flex justify-content-end">
                             <button class="ricontrolla visi-none d-block" onClick={() => { (props.handleModal) ? props.handleModal() : closeModal() }}>{t("user.sidebar.btntxt")}</button>
                        </div>
                    </div>
                </div></>
        );
    }
    const instPopUp3 = () => {
        return (
            <>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Group_inst13822} alt="icon" style={{ width: '50px' }} />
                            </div>
                            <strong> {t("user.sidebar.status")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { (props.handleModal) ? props.handleModal() : closeModal() }}

                        >
                            <img src={closeIcon} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body">

                    <div className="ellipseces">
                            <div className="text-center">
                                <img src={Ellipse145} className="inst-style"/><br></br>
                                <span className="inst-style-text">{t("user.sidebar.very_poor")}</span>
                            </div>
                            <div className="text-center">
                                <img src={Ellipse146} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.poor")}</span>
                            </div>
                             <div className="text-center">
                                <img src={Ellipse147} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.sufficient")}</span>
                            </div>
                            <div className="text-center">
                                <img src={Ellipse148} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.good")}</span>
                            </div>
                            <div className="text-center">
                                <img src={Ellipse149} className="inst-style" /><br></br>
                                <span className="inst-style-text">{t("user.sidebar.excellent")}</span>
                            </div>
                    </div>
                       
                    </div>
                    <div className={`modal-footer d-flex p-0  ${(instruction == 1) ? null : 'justify-content-between'} `} style={{ 'border-top': 0 }}>
                      
                        <div class="col-sm-6 d-flex justify-content-end pr-0 mspace-20">
                             <button class="ho-capito ricontrolla visi-none d-block" onClick={() => { (props.handleModal) ? props.handleModal() : closeModal() }}>{t("user.sidebar.btntxt")}</button>
                        </div>
                    </div>
                </div></>
        );
    }

    return (
        <>
      
         <Modal title="" size="" onClose={() => setPopup1(false)} show={popUp1}>
            
           { instPopUp1()}
          
        </Modal>
        <Modal title="" size="" onClose={() => setPopup2(false)} show={popUp2}>
            { instPopUp2()}
         </Modal>
       
         <Modal title="" size="" onClose={() => setPopup3(false)} show={popUp3} >
            { instPopUp3()}
         </Modal>
      
        </>
    );
}
export default instructionModal;