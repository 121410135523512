import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import GoNoGoResultImg from "../../../../assets/Image/GoNoGoResultImg.svg";
import { getLocalStoarge ,setLocalStoarge } from '../../../common/allfunction';
import PostRequest from '../../../apiConnection/postRequest';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { submitUserActivityData } from '../common/utilty';
import LazyLoader from "../../../common/lazyLoader";

export const GoNoGoResult = ({ props }) => {
    const { round, params, avgAccuracy, totalTime, totalAvgResponseTime, accuracyCurrentRound, averageResponseTime,free } = props;

    const [t, i18n] = useTranslation("common");
    const companyId = getLocalStoarge('company_id');
    const [isLoading, setIsLoading] = useState(true);

    const avgScore = () => {
        return (((avgAccuracy * round) + (accuracyCurrentRound)) / (round + 1))
    }

    const avgResponseTime = () => {
        return (((totalAvgResponseTime * round) + (parseInt(averageResponseTime))) / parseInt(round + 1));
    }

    const [payload, setPayload] = useState({
        company_id: companyId,
        component_id: parseInt(params.component_id),
        day_sequence: parseInt(params.day_sequence),
        repeat_time: parseInt(params.repeat_time),
        activity_id: parseInt(params.activity_id),
        status: "complete",
        activity_score: avgScore(),
        activity_play_time: totalTime * 60,
        total_avg_response_time: avgResponseTime(),
        activity_round_no: round + 1
    })

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    useEffect(() => {
        if (free) {
            var resultObj = [];
            if (localStorage.getItem('cognativeResult_'+payload.repeat_time).length > 0) {
                resultObj = JSON.parse(localStorage.getItem('cognativeResult_'+payload.repeat_time));
            }
            resultObj[9] = {
                'activityName': 9,
                'avgAccuracy': accuracyCurrentRound ? accuracyCurrentRound : 0,
                'totalAvgResponseTime': averageResponseTime ? averageResponseTime : 0
            };
            setLocalStoarge("cognativeResult_"+payload.repeat_time, JSON.stringify(resultObj));
            setIsLoading(false)
        }
        else {
        submitUserActivityData(payload);
        }
    }, [payload])

    return (
        <>
            {(isLoading) ? <LazyLoader /> : null}
            <section className="reaction-speed-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="stepsin stpelog">
                                <Link to="dashboard">
                                    <img src={logo} className="logo-steps" alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="reaction-speed-result">
                                <div className="col-md-6">
                                    <div className="reaction-speed-result-1">
                                        <h2>{t("user.activity.go_no_go.result_heading")}</h2>
                                        <p>
                                            {t("user.activity.go_no_go.result_message").replace('percent', `${Math.round(parseInt((accuracyCurrentRound * 100)))}%`).replace('averageTime', `${Math.round(averageResponseTime)} ms`)}
                                        </p>
                                        <div className="row">
                                            <div className="reaction-speed-result-1-1">
                                                <div className="col-sm-10 col-md-10 col-lg-6 col-xl-6 col-xxl-6">
                                                    <p className="reaction-speed-result-reponse-bar">
                                                        <CircularProgressbar value={accuracyCurrentRound} maxValue={1} strokeWidth={12} styles={buildStyles({
                                                            pathColor: (Math.round(parseInt(accuracyCurrentRound * 100))) >= 60 ? '#9ECB61' : '#B02418',
                                                        })}
                                                        /></p>
                                                </div>
                                                <div className="col-md-6 reaction-speed-result-accuracy">
                                                    <h6>{Math.round(parseInt(accuracyCurrentRound * 100))}%</h6>
                                                    <p>{t("user.activity.go_no_go.result_accuracy")}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="reaction-speed-result-response">{parseInt(Math.round(averageResponseTime))}ms</p>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="reaction-speed-result-response-text">{t("user.activity.go_no_go.result_response")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="reaction-speed-result-2 r-statistiche">
                                        <img src={GoNoGoResultImg} alt="" />
                                    </div>
                                </div>
                            </div>
                            <Link to={`/congnitive-training/${parseInt(params.repeat_time)}`}>
                                <button className="btn btn-primary btnNext-act">
                                    {t("user.activity.reaction_speed.exit")}
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
