import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { getLocalStoarge, getMindfulnessPrePost } from '../../../common/allfunction';
import LazyLoader from '../../../common/lazyLoader';

const MindfulnessActivityWisePrePost2 = (props) => {
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [perspectiveTaking, setPerspectiveTaking] = useState([]);
    const [resilience, setResilience] = useState([]);
    const [empathy, setEmpathy] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        getPrePostData();
    }, [props.repeatTime]);

    const getPrePostData = async () => {
        const data = await getMindfulnessPrePost(props.repeatTime);
        setResilience(data?.Resilience);
        setEmpathy(data?.Empathy);
        setPerspectiveTaking(data?.PerspectiveTaking);
        setIsLoading(false);
    }

    return (
        <div>
            {isLoading ? <LazyLoader /> : null}
            <div className='second-top-heading mt-3'>
                <div className='col-12'>
                    <h2 className='second-page-heading'>{t('user.activityReport.details_pre_post')}</h2>
                    <p className='pre-post-heading-description' dangerouslySetInnerHTML={{ __html: t('user.activityReport.details_description') }}></p>
                </div>
            </div>
            <div className='pre-post-activity-name-desc'>
                <div className="row m-0">
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.activity4')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.activity4_description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(perspectiveTaking?.length > 0 && Boolean((perspectiveTaking[0]))) ? (perspectiveTaking[0]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: perspectiveTaking ? `${(((100 * perspectiveTaking[0]) / 28) > 100) ? 100 : ((100 * perspectiveTaking[0]) / 28)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "29%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "39%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "32%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step14')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step15')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step16')}</p>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(perspectiveTaking?.length > 0 && Boolean((perspectiveTaking[1]))) ? (perspectiveTaking[1]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: perspectiveTaking ? `${(((100 * perspectiveTaking[1]) / 28) > 100) ? 100 : ((100 * perspectiveTaking[1]) / 28)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "29%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "39%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "32%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step14')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step15')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step16')}</p>
                            </div>
                        </div>

                        <p className='calculate-percent'>{`${(perspectiveTaking?.length > 0 && Boolean((perspectiveTaking[2]))) ? (perspectiveTaking[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>

                        <ul className='pre-post-list-info'>
                            <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.info-list-4') }}></li>

                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.activity5')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.activity5_description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(resilience?.length > 0 && Boolean((resilience[0]))) ? (resilience[0]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: resilience ? `${(((100 * resilience[0]) / 10) > 100) ? 100 : ((100 * resilience[0]) / 10)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "40%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "30%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "40%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step17')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step18')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step19')}</p>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(resilience?.length > 0 && Boolean((resilience[1]))) ? (resilience[1]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: resilience ? `${(((100 * resilience[1]) / 10) > 100) ? 100 : ((100 * resilience[1]) / 10)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "40%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "30%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "40%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step17')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step18')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step19')}</p>
                            </div>
                        </div>

                        <p className='calculate-percent'>{`${(resilience?.length > 0 && Boolean((resilience[2]))) ? (resilience[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>

                        <ul className='pre-post-list-info'>
                            <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.info-list-6') }}></li>

                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.activity6')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.activity6_description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(empathy?.length > 0 && Boolean((empathy[0]))) ? (empathy[0]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: empathy ? `${(((100 * empathy[0]) / 28) > 100) ? 100 : ((100 * empathy[0]) / 28)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "29%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "39%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "32%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step20')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step21')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step22')}</p>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(empathy?.length > 0 && Boolean((empathy[1]))) ? (empathy[1]) : 0}`}</h6>
                            <div className="progress progress-relative" style={{ width: "100%" }}>
                                <span style={{ left: empathy ? `${(((100 * empathy[1]) / 28) > 100) ? 100 : ((100 * empathy[1]) / 28)}%` : null }} className='vertical-line'></span>
                                <div className="progress-bar color-red" style={{ width: "29%" }}></div>
                                <div className="progress-bar color-yellow" style={{ width: "39%" }}></div>
                                <div className="progress-bar color-light-sky" style={{ width: "32%" }}></div>
                            </div>
                            <div className='d-flex justify-content-around'>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step20')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step21')}</p>
                                <p className='progress-score-name' style={{ width: "33.33%" }}>{t('user.activityReport.progress_bar_step22')}</p>
                            </div>
                        </div>

                        <p className='calculate-percent'>{`${(empathy?.length > 0 && Boolean((empathy[2]))) ? (empathy[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>

                        
                        <ul className='pre-post-list-info'>
                            <li dangerouslySetInnerHTML={{ __html: t('user.activityReport.info-list-4') }}></li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MindfulnessActivityWisePrePost2
