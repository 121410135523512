import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PostRequest from "../../apiConnection/postRequest";
import PutRequest from "../../apiConnection/putRequest";
import LazyLoader from "../../common/lazyLoader";
import { useParams } from "react-router-dom";
import Modal from "../../common/modal";
import Group698 from "../../../assets/Image/Group698.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg';
import DeleteBin from '../../../assets/Image/delete-bin.svg';
import crosseditprofile from '../../../assets/Image/cross-editprofile.svg';
import VectorDown from "../../../assets/Image/VectorDown.svg";
import GetRequest from "../../apiConnection/getRequest";
import '../super_admin_assets/scss/protocol.scss';
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg";
import NEXT_ICONA_P from "../../../assets/Image/NEXT_ICONA_P.svg";
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg';
import Statusicona from '../../../assets/Image/status_icona.svg';
import CALENDARIO_ICONA from '../../../assets/Image/CALENDARIO_ICONA.svg';
import PASSWORD_ICONA from '../../../assets/Image/PASSWORD_ICONA.svg';
import USER_ICONA from '../../../assets/Image/USER_ICONA.svg';
import rilascio_icona from '../../../assets/Image/rilascio_icona.svg';
import DOCUMENTO_ICONA from '../../../assets/Image/DOCUMENTO_ICONA.svg';
import EDIT_PENCIL from '../../../assets/Image/EDIT_PENCIL.svg';
import Group84123 from '../../../assets/Image/Group84123.svg';
import Group769 from "../../../assets/Image/Group769.svg";
import PREV_PAGE_ICON from '../../../assets/Image/PREVPAGE_ICON.svg';
import leftarrow from "../../../assets/Image/leftarrow.svg";
import filterFactory from "react-bootstrap-table2-filter";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge, getCompanyDetails } from "../../common/allfunction";
import ProgramStatics from "./programStatics";
import moment from 'moment';
import Select from "react-select";
import CONTENTDOC from '../../../assets/Image/CONTENTDOC.svg';
import VIDEO_ICONAvideo from "../../../assets/Image/VIDEO_ICONAvideo.svg"
import Delete_Icon_popup from '../../../assets/Image/Delete_Icon_popup.svg'

const SA_Company_Programs = (props) => {
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation("common");
    const [companiesData, setCompaniesData] = useState([]);
    const [company_id, setCompanyId] = useState(props.match.params.company_id)
    const [program_id, setProgramId] = useState(props.match.params.program_id)
    const [companyDetails, setCompanyDetails] = useState({})
    const [programData, setProgramData] = useState({ programNameITA: '', descriptionITA: '' });
    const [courseList,setCourseList] = useState([]);
    const [courseData, setCourseData] = useState([]);
    const [testCourseId, setTestCourseId] = useState([]);
    const [courseContent, setCourseContent] = useState({})
    const [courseContentITA, setCourseContentITA] = useState({})
    const [userCount, setUserCount] = useState();
    const [personalText, setPersonalText] = useState("detail")
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [modifyUserModal, setModifyUserModal] = useState(false)
    const [testProgram, setTestProgram] = useState(false)
    const [testUpdateProgram, setTestUpdateProgram] = useState(false)
    const [testEdit, setTestEdit] = useState(false)
    const [testId, setTestId] = useState()
    const [modifyProgramOne, setModifyProgramOne] = useState(false)
    const [multipleChoice, setMultipleChoice] = useState(false)
    const [singleChoice, setSingleChoice] = useState(false)
    const [trueFalseChoice, setTrueFalseChoice] = useState(false)
    const [writtenChoice, setWrittenChoice] = useState(false)
    const [selectedUser, setSelectedUser] = useState([])
    const [allUser, setAllUser] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [itemsPerPage, setItemPerPage] = useState(12)
    const [programAssignee, setProgramAssignee] = useState([])
    const [addTestButton, setAddTestButton] = useState(false)
    const [val, setVal] = useState([]);
    const [correct, setCorrect] = useState(null);
    const [optionCheck, setCheckOption] = useState(1);
    const [val2, setVal2] = useState([]);
    const [correct2, setCorrect2] = useState([]);
    const [allQuestionsArray, setAllQuestionsArray] = useState([]);
    const [allQuestionsObject, setAllQuestionsObject] = useState([]);
    const [queType, setQueType] = useState('');
    const [val4, setVal4] = useState([]);
    const [addProgramModal2Content, setAddProgramModal2Content] = useState(false);
    const [correct3, setCorrect3] = useState();
    const [questions, setQuestions] = useState([]);
    const [testData, setTestData] = useState([]);
    const [finalTest, setFinalTest] = useState({});
    const [noOfQue, setNoOfQue] = useState();
    const [programTestSummaryData, setProgramTestSummaryData] = useState([])
    const [programUser, setProgramUser] = useState([])
    const [showProgramUser, setShowProgramUsers] = useState([])
    const [currentPageList, setCurrentPageList] = useState([])
    const [itemPerPageList, setItemPerPageList] = useState(21)
    const [pageCountList, setPageCountList] = useState(0)
    const [isDisable, setIsDisable] = useState(true)
    const [disableUpAssigneeButton, setDisableUpAssigneeButton] = useState(false)
    const [fineBtn, setFineBtn] = useState(true)
    const [backGround, setBackGround] = useState(null)
    const [weeks, setWeeks] = useState(null)
    const [releaseDate, setReleaseDate] = useState(null);
    const [questionArr, setQuestionArr] = useState([])
    const [questionItaArr, setQuestionItaArr] = useState([]);
    const [questionNumbers, setQuestionNumbers] = useState(0);
    const [allContentList, setAllContentList] = useState([])
    const [updateProgramAllCourse, setUpdateProgramAllCourse] = useState([])
    const [activeContent, setActiveContent] = useState('')
    const [alertMessage1, setAlertMessage1] = useState({ show: false, spinner: false, message: "", class: "" })
    const [disableUpdateProgramOneButton, setDisableUpdateProgramOneButton] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState('')
    const [courseName, setCourseName] = useState([]);
    const [courseDescription, setCourseDescription] = useState([])
    const [activeCourse, setActiveCourse] = useState(null)
    const [disableUserModal, setDisableUserModal] = useState(false)
    const [popupLoader1, setPopupLoader1] = useState(false)
    const [confirmRemove, setConfirmRemove] = useState(false)
    const [removeCourseIndex, setRemoveCourseIndex] = useState()



    const handleAddVal = (index) => {
        let arr = questionArr
        arr[index]['ans'] = [...arr[index]['ans'], '']
        setQuestionArr([...arr])
        const text1 = [...val, []]
        setVal(text1)
    }


    const handleTextArea = (onChangeValue, i, index) => {
        let arr = questionArr
        if (onChangeValue.target.value != '') {
            setIsDisable(false)
            setFineBtn(true)
            arr[index]['ans'][i] = onChangeValue.target.value
            setQuestionArr([...arr])

        }
        else if (onChangeValue.target.value === '') {
            arr[index]['ans'][i] = onChangeValue.target.value
            setQuestionArr([...arr])
            setIsDisable(true)
            setFineBtn(true)
        }

        // const inputData = [...val];
        // if (onChangeValue.target.value != '') {
        //     inputData[i] = onChangeValue.target.value;
        //     setVal(inputData);
        //     setIsDisable(false)
        //     setFineBtn(true)
        // }
        // else if (onChangeValue.target.value === '') {
        //     inputData[i] = onChangeValue.target.value;
        //     setVal(inputData);
        //     setIsDisable(true)
        //     setFineBtn(true)
        // }

    };

    const handleCheck = (e, i, index) => {
        let arr = questionArr;
        let arrIta = questionItaArr;
        arr[index].correct = i
        arrIta[index].correct = i

        setQuestionArr([...arr])
        setQuestionItaArr([...arrIta])
        setCorrect(i);

    };

    // const handleCheckSequence = () => {
    //     setCheckOption(optionCheck + 1)
    // }



    const handleAddVal2 = (index) => {
        let arr = questionArr
        arr[index]['ans'] = [...arr[index]['ans'], '']
        setQuestionArr([...arr])

        // const text2 = [...val2, []]
        // setVal2(text2)
    }

    const handleTextArea2 = (onChangeValue2, i, index) => {
        let arr = questionArr
        if (onChangeValue2.target.value != '') {
            arr[index]['ans'][i] = onChangeValue2.target.value
            setQuestionArr([...arr])
            setIsDisable(false)
            setFineBtn(true)
        }
        else if (onChangeValue2.target.value === '') {
            arr[index]['ans'][i] = onChangeValue2.target.value
            setQuestionArr([...arr])
            setIsDisable(true)
            setFineBtn(true)
        }


        // const inputData2 = [...val2]
        // if (onChangeValue2.target.value != '') {
        //     inputData2[i] = onChangeValue2.target.value;
        //     setVal2(inputData2)
        //     setIsDisable(false)
        //     setFineBtn(true)
        // }
        // else if (onChangeValue2.target.value === '') {
        //     inputData2[i] = onChangeValue2.target.value;
        //     setVal2(inputData2)
        //     setIsDisable(true)
        //     setFineBtn(true)
        // }

    }
    // Italian answer text field 
    const handleTextArea3 = (onChangeValue2, i, index) => {

        let arr = questionItaArr;

        if (onChangeValue2.target.value != '') {
            arr[index]['ans'][i] = onChangeValue2.target.value
            setQuestionItaArr([...arr])
            setIsDisable(false)
            setFineBtn(true)
        }
        else if (onChangeValue2.target.value === '') {
            arr[index]['ans'][i] = onChangeValue2.target.value
            setQuestionItaArr([...arr])
            setIsDisable(true)
            setFineBtn(true)
        }



    }
    // const handleAddCorrectVal2 = () => {
    //     const option2 = [...correct2, []]
    // setCorrect2(option2)
    // }



    const handleCheck2 = (e, i, index) => {
        let arr = questionArr
        let arrIta = questionItaArr
        if (arr[index].correct?.includes(i)) {
            let arr2 = [];
            let arrayIta = [];
            arr2 = arr[index].correct?.filter((item) => item != i)
            arrayIta = arrIta[index].correct?.filter((item) => item != i)
            arr[index].correct = arr2
            arrIta[index].correct = arrayIta

        }
        else {
            arr[index].correct = [...arr[index].correct, i]
            arrIta[index].correct = [...arrIta[index].correct, i]
        }
        setQuestionArr([...arr])
        setQuestionItaArr([...arrIta])
        // if (correct2.includes(i)) {
        //     let arr = [];
        //     arr = correct2.filter((item) => item != i)
        //     setCorrect2(arr)
        // }
        // else {
        //     setCorrect2([...correct2, i])
        // }

    }
    const handleChange1 = (q1, index) => {
        let arr = questionArr
        arr[index] = { ...arr[index], que: q1.target.value }
        setFineBtn(true)
        setQuestionArr([...arr])
        // allQuestionsObject.que = q1.target.value;
    }
    const handleChangeIta1 = (q1, index) => { // Italian question field array data
        let arr = questionItaArr
        arr[index] = { ...arr[index], que: q1.target.value }
        setFineBtn(true)
        setQuestionItaArr([...arr])
        // allQuestionsObject.que = q1.target.value;
    }
    const handleAnswer = (a, index) => {
        let arr = questionArr
        arr[index]['ans'] = a.target.value
        setQuestionArr([...arr])

        setFineBtn(true)
        // setVal4(a.target.value);
    }

    const handleCheck3 = (v, type, index) => {
        let arr = questionArr;
        let arrITA = questionItaArr;
        setIsDisable(false)

        if (type == 'true') {
            arr[index].correct = v
            arrITA[index].correct = v
            setQuestionArr([...arr])
            setQuestionItaArr([...arrITA])
            setBackGround(true)
            setFineBtn(true)
        }
        else if (type == 'false') {
            arr[index].correct = v
            arrITA[index].correct = v
            setQuestionArr([...arr])
            setQuestionItaArr([...arrITA])
            setBackGround(false)
            setFineBtn(true)
        }
    }
    const handleCheckIta3 = (v, type, index) => {
        let arr = questionItaArr
        setIsDisable(false)

        if (type == 'true') {
            arr[index].correct = v
            setQuestionItaArr([...arr])
            setBackGround(true)
            setFineBtn(true)
        }
        else if (type == 'false') {
            arr[index].correct = v
            setQuestionItaArr([...arr])
            setBackGround(false)
            setFineBtn(true)
        }
    }



    const checkValNewQuestionModal1 = () => {
        setIsDisable(false)
        let index = 0;
        questionArr.forEach((q, ans) => {
        
            if (questionItaArr[index].ans.length > questionArr[index].ans.length) {
                setFineBtn(true);
                setAlertMessage1({ show: true, spinner: false, message: t("superadmin.test.scqError"), class: "alert alert-danger" });
            } else {
                setFineBtn(false);
                setAlertMessage1({ show: false, spinner: false, message: "", class: "" });
            }
            if (q.type == "tfq") {

                if (q.que == undefined || q.que == '' || q.correct == undefined) {
                    setFineBtn(true);
                    setAlertMessage({ show: true, spinner: false, message: t("superadmin.test.tfqError"), class: "alert alert-danger" });
                }
                else {
                    setFineBtn(false)
                    setAlertMessage({ show: false, spinner: false, message: "", class: "" })
                }
            }
            if (q.type == "scq") {
                if (q.que == undefined || q.que == '' || q.ans == undefined || q.ans == '' || q.correct == undefined || q.correct == null) {
                    setFineBtn(true);
                    setAlertMessage1({ show: true, spinner: false, message: t("superadmin.test.scqError"), class: "alert alert-danger" })
                }
                else {
                    setFineBtn(false);
                    setAlertMessage1({ show: false, spinner: false, message: "", class: "" });
                }
            }
            if (q.type == "mcq") {

                if (q.que == undefined || q.que == '' || q.ans == undefined || q.correct == undefined || q.correct == null) {
                    setFineBtn(true);
                    setAlertMessage1({ show: true, spinner: false, message: t("superadmin.test.mcqError"), class: "alert alert-danger" });
                }
                else {
                    setFineBtn(false);
                    setAlertMessage1({ show: false, spinner: false, message: "", class: "" });
                }
            }        
         
            index = index + 1;
        })
        let indexita = 0;
        questionItaArr.forEach((q, ans) => { // Italian Language question array
            if (questionArr[indexita].ans.length > questionItaArr[indexita].ans.length) {
                setFineBtn(true);
                setAlertMessage1({ show: true, spinner: false, message: t("superadmin.test.scqError"), class: "alert alert-danger" });
            } else {
                setFineBtn(false);
                setAlertMessage1({ show: false, spinner: false, message: "", class: "" });
            }
            if (q.type == "tfq") {

                if (q.que == undefined || q.que == '' || q.correct == undefined) {
                    setFineBtn(true);
                    setAlertMessage1({ show: true, spinner: false, message: t("superadmin.test.tfqError"), class: "alert alert-danger" });
                }
                else {
                    setFineBtn(false)
                    setAlertMessage1({ show: false, spinner: false, message: "", class: "" })
                }
            }
            if (q.type == "scq") {
                if (q.que == undefined || q.que == '' || q.ans == undefined || q.ans == '' || q.correct == undefined || q.correct == null) {

                    setFineBtn(true);
                    setAlertMessage1({ show: true, spinner: false, message: t("superadmin.test.scqError"), class: "alert alert-danger" });
                }
                else {
                    setFineBtn(false);
                    setAlertMessage1({ show: false, spinner: false, message: "", class: "" });
                }

            }
            if (q.type == "mcq") {

                if (q.que == undefined || q.que == '' || q.ans == undefined || q.correct.length == 0) {
                    setFineBtn(true);
                    setAlertMessage1({ show: true, spinner: false, message: t("superadmin.test.mcqError"), class: "alert alert-danger" });
                }
                else {
                    setFineBtn(false);
                    setAlertMessage1({ show: false, spinner: false, message: "", class: "" });
                }

            }
        
            indexita = indexita + 1;
        })

        // let obj = {};
        // obj.type = queType;
        // obj.que = allQuestionsObject.que;
        // obj.ans = (queType == 'scq') ? val : (queType == 'mcq') ? val2 : (queType == 'written') ? val4 : '';
        // obj.correct = (queType == 'scq') ? correct : (queType == 'mcq') ? correct2 : (queType == 'tfq') ? correct3 : '';
        // if (queType != '') setAllQuestionsArray([...allQuestionsArray, obj]);
        // setQueType('')
    }

    const setAllQuestionsDetails = () => {
        setFinalTest({ ...finalTest, questions: questionArr, questionsITA: questionItaArr });
        setNoOfQue(questionArr?.length);
    }


    const { SearchBar } = Search;
    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div className="d-flex justify-content-center">
                <button className="table-action" onClick={() => window.location.href = `protocol/${row.company_id}`}><img src={ExpandIcon} alt="icon" /></button>
                <button className="table-action" onClick={() => { }}><img src={Group878} alt="icon" /></button>
                <button className="table-action" onClick={() => { }}><img src={DeleteBin} alt="icon" /></button>
            </div>
        )
    }
    // const addTextAnswer = ()=>{
    //     alert(textAreaAnswer+1)
    //     setTextAreaAnswer(textAreaAnswer+1)
    // }
    const [updateProgramData, setUpdateProgramData] = useState({ company_id: "", program_name: "", programNameITA: "", description: "", descriptionITA: "", course: [], courseITA: [], duration: 0, release_date: "", show_release_date: "", obligatory: "", status: 0, referent: "" })
    const [updateProgramModal2, setUpdateProgramModal2] = useState(false)
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [programDatamodel2, setProgramDataModel2] = useState({ status: 0 });
    const [refrentData, setRefrentData] = useState([])
    const [previousReferent, setPreviousReferent] = useState("");

    const [generalColumn, setGeneralColumn] = useState([{
        // text: t("superadmin.protocol.name"),
        // text: "Name",
        dataField: "column1",
        sort: true,
    }]);
    const [token, setToken] = useState(() => {
        const saved = localStorage.getItem("R3");
        const initialValue = JSON.parse(saved);
        return initialValue.access_token || "";
    });
    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, month, year].join(".");
    };

    const getCompaniesData = async (assignee) => {
        setIsLoading(true);
        try {
            const payload = {
                company: company_id,
                role_id: "1"
            }
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     token,
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getAllDataResult = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_GET_COMPANY_USER_LIST}`,
                payload,
                "",
                ""
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                setIsLoading(false);
                if (getAllDataResult.data.data?.length > 0) {
                    let arr = []
                    let users = []
                    await getAllDataResult.data.data?.forEach((data, i) => {
                        let obj = {
                            name: data?.name || "",
                            user_id: data?.user_id || "",
                        }
                        arr = [...arr, obj]
                        if (assignee.length > 0) {
                            if (assignee.includes(data.user_id))
                                users = [...users, obj]
                        }
                    })
                    setCurrentItems(arr?.slice(0, itemsPerPage))
                    setCurrentPage(1)
                    setPageCount(Math.ceil(arr?.length / itemsPerPage))
                    setCompaniesData(arr);
                    setAllUser(arr)
                    setProgramUser(users)
                    setShowProgramUsers(users.slice(0, itemPerPageList))
                    setPageCountList(Math.ceil(users?.length / itemPerPageList))
                    setCurrentPageList(1)
                }
                else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else if (getAllDataResult && getAllDataResult.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            setAllUser()            
            setIsLoading(false);
        }
    }
    const updateAssigneeAPI = async () => {
        setIsLoading(true);
        setDisableUpAssigneeButton(true)
        try {
            const payload = {
                company_id: company_id,
                program_id: program_id,
                assignee: selectedUser
            }
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MODIFY_ASSIGNEE}`,
                    payload,
                    "",
                    ""
                );
                if (getAllDataResult && getAllDataResult.status === 200) {
                    closeModal()
                    getProgramData()
                    setDisableUpAssigneeButton(false)
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setAlertMessage({ show: true, spinner: false, message: `${getAllDataResult.data.message}`, class: "alert alert-danger" })
                    setIsLoading(false);
                    setDisableUpAssigneeButton(false)
                } else {
                    setIsLoading(false);
                    setDisableUpAssigneeButton(false)
                }
            } else {
                setIsLoading(false);
                setDisableUpAssigneeButton(false)
            }
        } catch (error) {
            setIsLoading(false);
            setDisableUpAssigneeButton(false)
        }
    }

    const getProgramData = async () => {
        setIsLoading(true);
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     token,
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getProgramDetails = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_PROGRAM_DETAILS}?company_id=${company_id}&program_id=${program_id}`
            );

            if (getProgramDetails && getProgramDetails.status === 200) {
                setReleaseDate(getProgramDetails?.data?.data.release_date)
                setProgramData({ ...programData, ...getProgramDetails.data.data });
                setCourseList(getProgramDetails.data.data.courseName);
                if (localStorage.getItem('langauage') == 'it' && getProgramDetails.data.data.courseITA) {
                    setCourseName(getProgramDetails.data.data.courseNameITA)
                    setCourseDescription(getProgramDetails.data.data.courseDescriptionITA)
                    setCourseData(Object.keys(getProgramDetails.data.data.courseITA));
                    setCourseContent(getProgramDetails.data.data.courseITA)
                }
                else {
                    if (getProgramDetails.data.data.courseName) setCourseName(getProgramDetails.data.data.courseName)
                    if (getProgramDetails.data.data.courseDescription) setCourseDescription(getProgramDetails.data.data.courseDescription)
                    setCourseData(Object.keys(getProgramDetails.data.data.course));
                    setCourseContent(getProgramDetails.data.data.course)
                }
                setProgramAssignee(getProgramDetails.data.data?.assignee)
                if (getProgramDetails.data.data?.assignee?.length > 0) {
                    setProgramAssignee(getProgramDetails.data.data.assignee)
                }
                setWeeks(getProgramDetails.data.data?.duration)
                getCompaniesData(getProgramDetails.data.data?.assignee);
                // getAllContentAPI(getProgramDetails.data.data.course)
                getAllContentAPI();
            } else if (getProgramDetails && getProgramDetails.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            // console.log("error", error)
            setIsLoading(false);
        }
    }


    const updateProgramDetail = async (modal2) => {
        // setIsLoading(true);
        setDisableUpdateProgramOneButton(true)
        try {
            let payload = {}
            if (modal2 == "modal2") {
                payload = {
                    company_id: company_id,
                    program_id: programData.id,
                    program_name: programData.program_name,
                    referent: programDatamodel2.referent,
                    status: programDatamodel2.status,
                    duration: Number(programDatamodel2.duration),
                    obligatory: programDatamodel2.obligatory,
                    release_date: programDatamodel2.release_date
                }
            } else {
                payload = {
                    company_id: company_id,
                    program_id: updateProgramData.id,
                    program_name: updateProgramData.program_name,
                    programNameITA: updateProgramData.programNameITA,
                    description: updateProgramData.description,
                    descriptionITA: updateProgramData.descriptionITA,
                    course: updateProgramAllCourse,
                    referent: updateProgramData.referent,
                    status: updateProgramData.status,
                    duration: updateProgramData.duration,
                    obligatory: updateProgramData.obligatory,
                    release_date: updateProgramData.release_date,

                }
            }

            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            let payloadURL = (modal2 == "modal2") ? process.env.REACT_APP_UPDATE_PROGRAM : process.env.REACT_APP_UPDATE_PROGRAM_PARTIAL
            if (refreshToken) {
                const updateProgramResult = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${payloadURL}`,
                    payload,
                    "",
                    ""
                );
                if (updateProgramResult && updateProgramResult.status === 200) {
                    setDisableUpdateProgramOneButton(false)
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else if (updateProgramResult && updateProgramResult.status === 203) {
                    setDisableUpdateProgramOneButton(false)
                    setIsLoading(false);
                    setAlertMessage({ show: true, spinner: false, message: updateProgramResult.data?.message, class: "alert alert-danger" })
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            // console.log(error)
            setIsLoading(false);
        }
    }

    const columnFormatter1 = (cell, row) => {
        return <div>{row.column1?.name ? row.column1?.name : ""}</div>
    }
    const columnFormatter2 = (cell, row) => {
        return <div>{row.column2?.name ? row.column2?.name : ""}</div>
    }
    const columnFormatter3 = (cell, row) => {
        return <div>{row.column3?.name ? row.column3?.name : ""}</div>
    }

    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        setCurrentLanguage(lang)
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                // text: t("superadmin.protocol.name"),
                // text: "Name",
                dataField: "column1",
                sort: true,
                formatter: columnFormatter1,
                headerStyle: {
                    display: "none"
                }
            },
            {
                // text: t("superadmin.protocol.name"),
                // text: "Email",
                dataField: "column2",
                sort: true,
                formatter: columnFormatter2,
                headerStyle: {
                    display: "none"
                }
            },
            {
                // text: t("superadmin.protocol.name"),
                // text: "Email",
                dataField: "column3",
                sort: true,
                formatter: columnFormatter3,
                headerStyle: {
                    display: "none"
                }
            },

        ])
    };
    const callApi = async () => {
        setIsLoading(true)
        let company = await getCompanyDetails(company_id)
        await getCompanyRefrent()
        if (company) setCompanyDetails(company);
        // setWeeks(company.weeks)
        setIsLoading(false)
        await getProgramData()
        await getProgramSummery()

        handleTranslationLang()

    }
    useEffect(() => {
        callApi()
    }, []);
    const handleModifyUserModal = () => {
        if (programAssignee.length > 0) {
            setSelectedUser(programAssignee)
        }
        setModifyUserModal(true)
    }
    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#6d71f9';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'white';
        } else {
            // activeStyle.backgroundColor = 'gray';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = '';
            activeStyle.color = '#6d71f9';
        }
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-between pagination-list mt-3 mb-2">
                <div className="d-flex justify-content-center align-items-center">
                    {pages.map((p, index) => {
                        if (p.page === "<") return <div key={index} className={`page ${p.page === 1 ? "disable-btn" : "enable-btn"}`} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                        if (p.page === ">") return <div key={index} className="page" onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                        if (p.active) return <div key={index} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                        return (
                            <div className="page" key={index} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                        )
                    })}
                </div>
                <div >
                    <button className="ricontrolla" onClick={() => handleModifyUserModal()}>{t("superadmin.training.popup.modifyButton")}</button>
                </div>
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }

    const handleSingleChoice = (e, type) => {
        if (type === 'scq') {
            setSingleChoice(true);
            setAddTestButton(true);
            setQueType('scq')
            setQuestionArr([...questionArr, { type: 'scq', ans: [], correct: null }])
            setQuestionItaArr([...questionItaArr, { type: 'scq', ans: [], correct: null }])
        }
        else if (type === 'mcq') {
            setMultipleChoice(true);
            setAddTestButton(true);
            setQueType('mcq')
            setQuestionArr([...questionArr, { type: 'mcq', ans: [], correct: [] }])
            setQuestionItaArr([...questionItaArr, { type: 'mcq', ans: [], correct: [] }])
        }
        else if (type === 'written') {
            setWrittenChoice(true);
            setAddTestButton(true);
            setQueType('written')
            setQuestionArr([...questionArr, { type: 'written', ans: '', }])
            setQuestionItaArr([...questionItaArr, { type: 'written', ans: '', }])
        }
        else if (type === 'tfq') {
            setTrueFalseChoice(true);
            setAddTestButton(true);
            setQueType('tfq')
            setQuestionArr([...questionArr, { type: 'tfq', que: '', ans: '', correct: null }])
            setQuestionItaArr([...questionItaArr, { type: 'tfq', que: '', ans: '', correct: null }])
        }

    }

    const closeModal = () => {
        setFinalTest({})
        setNoOfQue(null)
        setAllQuestionsArray([])
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        setModifyUserModal(false)
        setSelectedUser(programAssignee)
        setCurrentItems(allUser?.slice(0, itemsPerPage))
        setCurrentPage(1)
        setCompaniesData(allUser)
        setTestProgram(false)
        setTestUpdateProgram(false)
        setModifyProgramOne(false)
        setUpdateProgramModal2(false)
        setAddTestButton(false)
        setMultipleChoice(false)
        setSingleChoice(false)
        setWrittenChoice(false)
        setAddProgramModal2Content(false)
        setNoOfQue()
        setQuestionArr([])
        setQuestionItaArr([])
        setQuestionNumbers(0)
        setAlertMessage1({ show: false, spinner: false, message: "", class: "" })
        // setDisableUserModal(false)
        setPopupLoader1(false)
    }
    const closeEditModal = () => {
        setFinalTest({})
        setNoOfQue(null)
        setAllQuestionsArray([])
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        setModifyUserModal(false)
        setSelectedUser(programAssignee)
        setCurrentItems(allUser?.slice(0, itemsPerPage))
        setCurrentPage(1)
        setCompaniesData(allUser)
        setTestUpdateProgram(false)
        setAddTestButton(false)
        setMultipleChoice(false)
        setSingleChoice(false)
        setWrittenChoice(false)
        setAddProgramModal2Content(false);
        setNoOfQue()
        setQuestionArr([])
        setQuestionItaArr([])
        setQuestionNumbers(0)
        setAlertMessage1({ show: false, spinner: false, message: "", class: "" })

    }
    const handleSelectUser = (id) => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        if (selectedUser.includes(id)) {
            let arr = selectedUser
            arr = arr.filter((item) => item !== id)
            setSelectedUser(arr)
        } else {
            setSelectedUser([...selectedUser, id])
        }
    }
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        let start = event.selected * itemsPerPage;
        setCurrentPage(page)
        setCurrentItems(companiesData.slice(start, start + itemsPerPage))
    };
    const handlePageClickList = (event) => {
        let page = event.selected + 1;
        let start = event.selected * itemPerPageList;
        setCurrentPageList(page)
        setShowProgramUsers(programUser.slice(start, start + itemPerPageList))
    };
    const handleSearch = (e) => {
        let value = e.target.value;
        let arr = allUser;
        if (value == "") {
            setCompaniesData(allUser)
            setCurrentItems(allUser.slice(0, itemsPerPage))
            setCurrentPage(1)
        } else {
            arr = arr.filter((item) => item.name?.toLowerCase().includes(value.toLowerCase()))
            setCompaniesData(arr)
            setCurrentItems(arr.slice(0, itemsPerPage))
            setCurrentPage(1)
        }
    }

    const handleChangeUpdate = (event) => {
        let { name, value } = event.target;
        setUpdateProgramData({ ...updateProgramData, [name]: value });
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    };
    const handleChangeUpdate2 = (event) => {
        let { name, value } = event.target;
        if (name == 'status') value = Number(value)
        setProgramDataModel2({ ...programDatamodel2, [name]: value });
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    };
    const checkValNewProgramModal1 = () => {
        if (updateProgramData.program_name?.length < 1 || updateProgramData.program_name?.length > 150) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_program_name")}`, class: "alert alert-danger" })
        } else if (updateProgramData.programNameITA?.length < 1 || updateProgramData.programNameITA?.length > 150) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_program_name")}`, class: "alert alert-danger" })
        } else if (updateProgramData.description?.length < 10 || updateProgramData.description?.length > 1000) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_description")}`, class: "alert alert-danger" })
        } else if (updateProgramData.descriptionITA?.length < 10 || updateProgramData.descriptionITA?.length > 400) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_description")}`, class: "alert alert-danger" })
        } else {
            for (let index of updateProgramAllCourse) {
                if (index.english.courseName?.length < 3 || index.english.courseName?.length > 50) {
                    setAlertMessage({ show: true, spinner: false, message: `Course name is required in english.(minimum 3 and maximum 50 character)`, class: "alert alert-danger" })
                    return;
                } else if (index.italian.courseName?.length < 3 || index.italian.courseName?.length > 50) {
                    setAlertMessage({ show: true, spinner: false, message: `Il nome del corso è richiesto in italiano.(minimo 3 e massimo 50 caratteri)`, class: "alert alert-danger" })
                    return;
                } else if (index.english.courseDescription?.length < 10 || index.english.courseDescription?.length > 400) {
                    setAlertMessage({ show: true, spinner: false, message: `Course description is required in english.(minimum 10 and maximum 400 character)`, class: "alert alert-danger" })
                    return;
                } else if (index.italian.courseDescription?.length < 10 || index.italian.courseDescription?.length > 400) {
                    setAlertMessage({ show: true, spinner: false, message: `La descrizione del corso è richiesta in italiano.(minimo 10 e massimo 400 caratteri)`, class: "alert alert-danger" })
                    return;
                } else if (index.english.content?.length !== index.italian.content?.length || index.english.content?.length < 1) {
                    setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.unequallyContent")}`, class: "alert alert-danger" })
                    return;
                }
            }
            // closeModal()
            setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" })
            updateProgramDetail()
        }
    }

    const checkValUpdateProgramModal2 = () => {
        let status = [1, 2, 3]
        if (!status.includes(programDatamodel2.status)) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_state")}`, class: "alert alert-danger" })
        } else if (!programDatamodel2.obligatory) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_obligatory")}`, class: "alert alert-danger" })
        }
        else {
            setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" })
            updateProgramDetail("modal2")
        }
    }

    const handleQuestions = (evn) => {
        let { name, value } = evn.target;
        setFinalTest({ ...finalTest, [name]: value });
    }
    /**Update test popup */
    const getTestUpdatePopup = async (data) => {
        try {
           setQuestionArr([...data.questions]);
           setQuestionItaArr([...data.questionsITA]);
           setFinalTest({'description':data.description,'descriptionITA':data.descriptionITA,'percentOfCorrectAns':data.percentOfCorrectAns});
           setTestCourseId(data.test_course_id)
           setTestEdit(true);
           setTestId(data.id);
           setTestUpdateProgram(true);
        } catch (error) {
            setIsLoading(false);
        }
    }
    const questionsSubmitFun = () => {
        // if (finalTest.questions.length < 4) {
        //     setAlertMessage1({ show: true, spinner: false, message: `${t("superadmin.training.test_program.noOfQueRequired")}`, class: "alert alert-danger" })
        // }
        if ((finalTest.description) && (finalTest.description.length > 400)) {
            setAlertMessage1({ show: true, spinner: false, message: `${t("superadmin.training.test_program.descErr")}`, class: "alert alert-danger" })
        }
        else if (isNaN(finalTest.percentOfCorrectAns) || (finalTest.percentOfCorrectAns > 90)) {
            setAlertMessage1({ show: true, spinner: false, message: t("superadmin.training.test_program.percentageErr"), class: "alert alert-danger" })
        }
        else {
            setAlertMessage1({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" })
            if(testEdit==true){
                setIsDisable(true);
                updateTestRecords()
            }
            else{
                setIsDisable(true);
                addTestRecords()
            }
            
        }
    }
    const modifyUserModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" onClick={() => setUpdateProgramData(updateProgramData)} />
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.modify_assignee")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            <div className=" searchbar d-flex justify-content-end">
                                <div className="position-relative">
                                    <img src={Group698} className="search_icon-new" alt="icon" />
                                    <label htmlFor="search-bar-0" className="search-label">
                                        <span id="search-bar-0-label" className="sr-only"></span>
                                        <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="search-bar-new form-control" placeholder={t("superadmin.protocol.search")} type="text" onChange={handleSearch} />
                                    </label>
                                </div>
                            </div>
                            <div className="user-list-box obbligatorieta-check-new">
                                {(currentItems.length > 0 && currentItems) ?
                                    currentItems.map((data, i) => {
                                        return (
                                            <div key={i} className="my-2">
                                                <label className={`mr-4 check-radiotype ${selectedUser.includes(data.user_id) ? "checked-icon" : ""} `}>
                                                    <input type="checkbox" onChange={() => handleSelectUser(data.user_id)} />
                                                </label>
                                                <span>{data.name}</span>
                                            </div>
                                        )
                                    })
                                    : null}
                            </div>
                        </div>
                        <div className="d-flex sa-program-pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<img src={NEXTICONA} alt="icon" />}
                                previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                renderOnZeroPageCount={null}
                                containerClassName="selectuser-pagination"
                                pageClassName="page-class"
                                previousClassName={currentPage == 1 ? "d-none" : ""}
                                nextClassName={currentPage == pageCount ? "d-none" : ""}
                                forcePage={currentPage - 1}
                            />
                        </div>
                        {alertMessage.show ? <div className="d-flex alert alert-info">
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{t("superadmin.training.popup.wait")} </div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-4">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableUpAssigneeButton} onClick={() => { updateAssigneeAPI(); setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" }) }}>{t("superadmin.training.popup.update")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const addProgramModal2ContentFun = () => {

        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <strong>{t("superadmin.training.test_program.title")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeEditModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="" >
                        <form className="registrati-form registrati-form-new nuovo-programma">
                            <div className="form-group">
                                <div>
                                    <label className="form-label">{t("superadmin.training.test_program.noOfQue")}</label>
                                    <div className="d-flex align-items-center">
                                        <input type="text" className="form-control no-of-que" name="noOfQuestions" value={noOfQue} readOnly />

                                    </div>
                                    <label className="form-label mt-4">{t("superadmin.training.test_program.testDescription1")}</label>
                                    <span className="ml-2">{t("superadmin.training.test_program.testDescription2")}</span>
                                    <div className="d-flex align-items-center test-desc">
                                        <div className="d-flex align-items-center w-100">
                                            <textarea rows={12} type="text" className="form-control-input2" name="description" value={finalTest.description} onChange={handleQuestions} placeholder="Description" />
                                            <textarea rows={12} type="text" className="form-control-input2" name="descriptionITA" value={finalTest.descriptionITA} onChange={handleQuestions} placeholder="Descrizione" />
                                        </div>
                                    </div>

                                    <label className="form-label mt-4">{t("superadmin.training.test_program.percentOfCorrectAns")}</label>
                                    <div className="d-flex align-items-center">
                                        <input type="number" className="form-control no-of-percentage" placeholder="00" name="percentOfCorrectAns" value={finalTest.percentOfCorrectAns && finalTest.percentOfCorrectAns !== null ? `${finalTest.percentOfCorrectAns}` : null} onChange={handleQuestions} /> <span className="percentage-sign">%</span>

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="modal-body scroll-pop scroll-pop-new">
                        {alertMessage1.show ? <div className={`d-flex mt-3 ${alertMessage1.class}`} >
                            {alertMessage1.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage1.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-5" >
                            <button className="buttons modal-button mr-3" onClick={() => { setAddProgramModal2Content(false); }}>{t("user.Questionnare.backwards")}</button>
                            <button className="buttons modal-button" disabled={isDisable} onClick={() => questionsSubmitFun()}>{t("superadmin.training.popup.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }




    const addTestProgramContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Group84123} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.training.test_program.title")}</strong>
                        </h5>

                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    {
                        //  (addTestButton == false) ?
                        //     <div className="card mt-1 ">
                        //         <button className="ricontrolla" onClick={() => setTestProgram(true)}>{t("superadmin.training.test_program.add_test")}</button>
                        //     </div>
                        //     : null
                    }
                    <div className="modal-body scroll-pop scroll-pop-new ">
                        {questionArr?.map((qData, index) => {
                            if (qData.type === 'scq') return singleChoiceQuestions(index)
                            if (qData.type === 'mcq') return multipleChoiceQuestions(index)
                            if (qData.type === 'tfq') return trueFalseChoiceQuestions(index)
                        })}
                        <div className="buttons-superadmin">
                            {(singleChoice === true) || (multipleChoice === true) || (writtenChoice === true) || (trueFalseChoice === true) ? <button className={`buttons w-100 ${isDisable ? 'disable-btn' : 'enable-btn'}`} disabled={isDisable} onClick={() => checkValNewQuestionModal1()}>{t("superadmin.training.test_program.sub_title")}</button> : ''}
                        </div>

                        <div className="hight-space">
                            <div className="mt-3 d-flex justify-content-between" >
                                <span className="test-choice ml-1" onClick={(e) => { handleSingleChoice(e, 'scq'); }}>{t("superadmin.training.test_program.choice1")}</span>
                                <span className="test-choice ml-1" onClick={(e) => { handleSingleChoice(e, 'mcq'); }}>{t("superadmin.training.test_program.choice2")}</span>
                                {/* <span className="test-choice ml-1" onClick={(e) => { handleSingleChoice(e, 'written'); setQuestionNumbers(questionNumbers+1) }}>{t("superadmin.training.test_program.choice3")}</span> */}
                                <span className="test-choice ml-1" onClick={(e) => { handleSingleChoice(e, 'tfq'); }}>{t("superadmin.training.test_program.choice4")}</span>
                            </div>
                        </div>
                        {/* {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null} */}

                        {alertMessage1.show ? <div className={`d-flex mt-3 ${alertMessage1.class}`} >
                            {alertMessage1.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage1.message}</div>
                        </div> : null}

                        <div className="d-flex justify-content-end buttons-superadmin mt-5">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className={`buttons modal-button ${(fineBtn) ? 'disable-btn' : 'enable-btn'}`} disabled={fineBtn} onClick={() => { setAllQuestionsDetails(); setAddProgramModal2Content(true); }}>{t("superadmin.training.test_program.fine")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const addTestUpdateProgramContent  = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Group84123} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.training.test_program.title")}</strong>
                        </h5>

                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeEditModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    {
                        //  (addTestButton == false) ?
                        //     <div className="card mt-1 ">
                        //         <button className="ricontrolla" onClick={() => setTestProgram(true)}>{t("superadmin.training.test_program.add_test")}</button>
                        //     </div>
                        //     : null
                    }
                   
                    <div className="modal-body scroll-pop scroll-pop-new ">
                        {questionArr?.map((qData, index) => {
                            if (qData.type === 'scq') return singleChoiceQuestions(index)
                            if (qData.type === 'mcq') return multipleChoiceQuestions(index)
                            if (qData.type === 'tfq') return trueFalseChoiceQuestions(index)
                       
                        })}
                        <div className="buttons-superadmin">
                            {(singleChoice === true) || (multipleChoice === true) || (writtenChoice === true) || (trueFalseChoice === true) || (testEdit==true) ? <button className={`buttons w-100 ${isDisable ? 'disable-btn' : 'enable-btn'}`} onClick={() => checkValNewQuestionModal1()}>{t("superadmin.training.test_program.sub_title")}</button> : ''}
                        </div>

                        <div className="hight-space">
                            <div className="mt-3 d-flex justify-content-between" >
                                <span className="test-choice ml-1" onClick={(e) => { handleSingleChoice(e, 'scq'); }}>{t("superadmin.training.test_program.choice1")}</span>
                                <span className="test-choice ml-1" onClick={(e) => { handleSingleChoice(e, 'mcq'); }}>{t("superadmin.training.test_program.choice2")}</span>
                                {/* <span className="test-choice ml-1" onClick={(e) => { handleSingleChoice(e, 'written'); setQuestionNumbers(questionNumbers+1) }}>{t("superadmin.training.test_program.choice3")}</span> */}
                                <span className="test-choice ml-1" onClick={(e) => { handleSingleChoice(e, 'tfq'); }}>{t("superadmin.training.test_program.choice4")}</span>
                            </div>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-5">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            {/* <button className="buttons modal-button mr-3" onClick={() =>setAllQuestionsDetails()}>test</button> */}

                            <button className={`buttons modal-button ${(fineBtn) ? 'disable-btn' : 'enable-btn'}`} disabled={fineBtn} onClick={() => { setAllQuestionsDetails(); setAddProgramModal2Content(true); }}>{t("superadmin.training.test_program.fine")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const handleSelectContent = (item, i, lang) => {

        setAlertMessage({})
        let arr = [];
        item?.forEach((d1) => {
            let content = allContentList?.find((d2) => d2.originalValue == d1.originalValue)
            arr = [...arr, { value: d1.originalValue, icon: content.icon, label: d1.label?.length > 20 ? `${d1.label?.substr(0, 20)}...` : d1.label, type: content.type, title: content?.label, originalValue: d1.originalValue }]
        })
        let arr2 = updateProgramAllCourse;
        arr2[i][lang].content = arr
        setUpdateProgramAllCourse(() => [...arr2])
        // console.log(item)
        // let arr = [];
        // item?.forEach((d1) => {
        //     let content = allContentList?.find((d2) => d2.originalValue == d1.originalValue)
        //     arr = [...arr, { value: d1.originalValue, icon: content.icon, label: d1.label?.length > 20 ? `${d1.label?.substr(0, 20)}...` : d1.label, type: content.type, title: content?.label, originalValue: d1.originalValue }]
        // })
        // let arr2 = updateProgramAllCourse;
        // arr2[i] = arr
        // setUpdateProgramAllCourse(() => [...arr2])
    }
    const handleAddCourse = (e) => {
        e.preventDefault();
        setUpdateProgramAllCourse([...updateProgramAllCourse,
        {
            english: { courseName: "", courseDescription: "", content: [] },
            italian: { courseName: "", courseDescription: "", content: [] }
        }
        ])
    }
    const getAllContentAPI = async () => {
        if (allContentList.length > 0) return;
        try {
            const getAllContent = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ALL_CONTENT}`
            );
            if (getAllContent && getAllContent.status === 200) {
                setIsLoading(false);
                let arr = [];
                if (getAllContent.data?.data?.allContent?.length > 0) {
                    getAllContent.data?.data?.allContent?.forEach((item) => {
                        arr = [...arr, {
                            originalValue: item.id,
                            value: item.title || "",
                            label: item.title || "",
                            type: item.contentType,
                            icon: item.contentType == "video" ? <img src={VIDEO_ICONAvideo} alt="i" /> : <img src={CONTENTDOC} alt="i" />
                        }]
                    })
                }
                setAllContentList(arr)
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const handleChangeCourse = (e, i, lang) => {
        setAlertMessage({})
        let arr = updateProgramAllCourse.slice(0);
        arr[i][lang][e.target.name] = e.target.value;
        setUpdateProgramAllCourse([...arr])
    }
    const removeCourse = (e) => {
        // e.preventDefault()
        // if(confirmRemove) 
        // console.log(removeCourseIndex)
        // {
        let arr = updateProgramAllCourse.slice(0);
        let arr2 = []
        arr.forEach((item, j) => {
            if (j != removeCourseIndex) arr2 = [...arr2, item]
        })
        setUpdateProgramAllCourse([...arr2])
        setModifyProgramOne(true); setDisableUserModal(false);
        // }
        
    }
    const modifyProgramContentOne = () => {
        return (
            <div className="model-pop-new " >
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" />
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.edit_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { closeModal(); setUpdateProgramAllCourse([]) }}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="" >
                            <form className="registrati-form registrati-form-new nuovo-programma">
                                <div className="form-group">

                                    <div>
                                        <label className="form-label">{t("superadmin.training.popup.name_program")}</label>
                                        <table className="new-program-name">
                                            <tr>
                                                <td> <span className="mr-2">English</span></td>
                                                <td className="w-100"><input type="text" className="form-control placeholder-design" name="program_name" placeholder="Write title"
                                                    value={updateProgramData.program_name} onChange={handleChangeUpdate} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><span className="mr-3">Italian</span></td>
                                                <td className="w-100"><input type="text" className="form-control placeholder-design" name="programNameITA" placeholder="Scrivi titolo"
                                                    value={updateProgramData.programNameITA} onChange={handleChangeUpdate} />
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">{t("superadmin.training.popup.description")} ( max 400 {t("superadmin.training.popup.characters")})</label>
                                        <div>
                                            <span className="">English</span>
                                            <textarea type="text" name="description" className="form-control placeholder-design mt-2" placeholder="Write Description"
                                                value={updateProgramData.description} onChange={handleChangeUpdate} />
                                        </div>
                                        <div className="mt-3">
                                            <span className="">Italian</span>
                                            <textarea type="text" name="descriptionITA" className="form-control placeholder-design mt-2" placeholder="Scrivi descrizione"
                                                value={updateProgramData.descriptionITA} onChange={handleChangeUpdate} />
                                        </div>
                                    </div>
                                    {updateProgramAllCourse?.map((d, i) => {
                                        const courseId = 'course' + i;
                                        return (
                                            <>
                                                <div key={i} className="mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <label className="form-label">{t("superadmin.training.popup.path")} {i + 1}</label>
                                                        {/* <button onClick={(e) => removeCourse(e, i)} className="cursor-pointer align-items-center bg-transparent"><img src={DeleteBin} alt="icon" /></button> */}
                                                        <button onClick={(e) => {e.preventDefault(); setModifyProgramOne(false); setRemoveCourseIndex(i); setDisableUserModal(true);}} className="cursor-pointer align-items-center bg-transparent"><img src={DeleteBin} alt="icon" /></button>

                                                    </div>
                                                    <div>
                                                        <span className="mr-2">English</span>
                                                        <input type="text" className="form-control placeholder-design my-2" name="courseName" placeholder="Write name"
                                                            value={updateProgramAllCourse[i]["english"].courseName} onChange={(e) => handleChangeCourse(e, i, "english")} />
                                                        <textarea type="text" name="courseDescription" className="form-control placeholder-design" placeholder="Write Description"
                                                            value={updateProgramAllCourse[i]["english"].courseDescription} onChange={(e) => handleChangeCourse(e, i, "english")} />
                                                        <div className="dropdown-container mt-2" id="content-select">
                                                            <Select
                                                                options={allContentList}
                                                                placeholder="Select contents"
                                                                components={{ DropdownIndicator: () => <div className="mr-2"><img src={VectorDown} alt="icon" /></div> }}
                                                                value={d.english.content}
                                                                onChange={(item) => {
                                                                    handleSelectContent(item, i, "english")
                                                                }
                                                                }
                                                                isSearchable={true}
                                                                getOptionLabel={e => (
                                                                    <div className="dropdown-block">
                                                                        <span className={`${e.type == 'video' ? 'video-margin' : 'document-margin'}`} >{e.icon}</span>
                                                                        <span className="dropdown-label">{e.label}</span>
                                                                    </div>
                                                                )}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <span className="">Italian</span>
                                                        <input type="text" className="form-control placeholder-design my-2" name="courseName" placeholder="Scrivi nome"
                                                            value={updateProgramAllCourse[i]["italian"].courseName} onChange={(e) => handleChangeCourse(e, i, "italian")} />
                                                        <textarea type="text" name="courseDescription" className="form-control placeholder-design" placeholder="Scrivi descrizione"
                                                            value={updateProgramAllCourse[i]["italian"].courseDescription} onChange={(e) => handleChangeCourse(e, i, "italian")} />
                                                        <div className="dropdown-container mt-2" id="content-select">
                                                            <Select
                                                                options={allContentList}
                                                                placeholder="Seleziona contenuti"
                                                                components={{ DropdownIndicator: () => <div className="mr-2"><img src={VectorDown} alt="icon" /></div> }}
                                                                value={d.italian.content}
                                                                onChange={(item) => handleSelectContent(item, i, "italian")}
                                                                isSearchable={true}
                                                                getOptionLabel={e => (
                                                                    <div className="dropdown-block">
                                                                        <span className={`${e.type == 'video' ? 'video-margin' : 'document-margin'}`} >{e.icon}</span>
                                                                        <span className="dropdown-label">{e.label}</span>
                                                                    </div>
                                                                )}
                                                                isMulti
                                                            />
                                                        </div>
                                                    </div>
                                               
                                                {/* { edit test section start} */}
                                                {
                                                    programTestSummaryData?.length > 0 && programTestSummaryData?.map((data, i) => {

                                                        if (data.test_course_id == courseId) {
                                                            return (
                                                                <div className="bot-lini mt-2" key={i}>
                                                                    <div className="d-flex justify-content-between">
                                                                        <label className="form-label">{courseName[i]}</label>
                                                                    </div>
                                                                    <div  class="row" style={ {background:' #f0f0fe'}}>
                                                                        <div class="col-sm-9">
                                                                            <div className="m-1">{data.description}</div>
                                                                            <p className="m-1"><strong>{`${data.noOfQuestions} ${t("superadmin.training.popup.request")} / ${data.percentOfCorrectAns}%${t("superadmin.training.popup.answer")}`}</strong></p>

                                                                        </div>
                                                                        <div className="col-sm-3 justify-content-end d-flex align-items-center position-relative">
                                                                            <a href="javascript:void(0)" className="ricontrolla pt-0 pb-0" onClick={() => { getTestUpdatePopup(data.allRecord) }}>
                                                                                {t("user.profile.modify")}
                                                                            </a>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            );
                                                        }
                                                    })
                                                }
                                                {/* { edit test section end} */}
                                                </div>

                                               
                                            </>
                                        )
                                    })}
                                </div >
                                <div className="buttons-superadmin">
                                    <button className="buttons w-100" onClick={(e) => handleAddCourse(e)}>{t("superadmin.training.popup.add")} {t("superadmin.training.popup.path")}</button>
                                </div>
                            </form>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div> : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-5">
                            <button className="buttons modal-button mr-3" onClick={() => { closeModal(); setUpdateProgramAllCourse([]) }}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableUpdateProgramOneButton} onClick={() => checkValNewProgramModal1()}>{t("superadmin.training.popup.update")}</button>
                        </div>
                    </div>
                </div >
            </div >
        )
    }

    const upadateProgramModal2Content = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.edit_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            <form className="registrati-form registrati-form-new nova-program">
                                <div className="form-group">
                                    <div>
                                        <label className="form-label">{t("superadmin.training.popup.state")}</label>
                                        <div className="d-flex">
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${programDatamodel2.status == 1 ? "active-green" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={1}
                                                        name="status"
                                                        checked={programDatamodel2.status == 1}
                                                        onChange={handleChangeUpdate2}
                                                    />
                                                    <span>{t("superadmin.training.active")}</span>
                                                </label>
                                            </div>
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${programDatamodel2.status == 2 ? "active-red" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={2}
                                                        name="status"
                                                        checked={programDatamodel2.status == 2}
                                                        onChange={handleChangeUpdate2}
                                                    />
                                                    <span>{t("superadmin.training.non_active")}</span>
                                                </label>
                                            </div>
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${programDatamodel2.status == 3 ? "active-yellow" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={3}
                                                        name="status"
                                                        checked={programDatamodel2.status == 3}
                                                        onChange={handleChangeUpdate2}
                                                    />
                                                    <span>{t("superadmin.training.scheduled")}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">{t("superadmin.training.popup.referent")}</label>
                                        {/* <input type="text" name="referent" className="form-control" value={programDatamodel2.referent} onChange={handleChangeUpdate2} /> */}
                                        <select
                                            type="text"
                                            name="referent"
                                            className="form-control"
                                            onChange={(e) => setProgramDataModel2({ ...programDatamodel2, referent: e.target.value })}
                                            value={programDatamodel2.referent}
                                        >
                                            {refrentData.map((opts, i) => (
                                                <option key={i} value={opts.name} >
                                                    {opts.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="d-flex mt-3">
                                        <div className="mr-3">
                                            <label className="form-label">{t("superadmin.training.duration")}</label>
                                            <select type="text" className="form-control" value={programDatamodel2.duration} onChange={(e) => { setProgramDataModel2({ ...programDatamodel2, duration: e.target.value }); setAlertMessage({}) }}>
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((data, index) => {
                                                    return <option key={index} value={data}>{data}</option>

                                                })}
                                            </select>

                                        </div>
                                        <div className={!(programDatamodel2.status == 3) ? "disable-program-release" : ""}>
                                            <label className="form-label">{t("superadmin.training.popup.scheduled_release")}</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                data-date-format="DD.MM.YYYY"
                                                aria-describedby="emailHelp"
                                                //   placeholder={todayDate}
                                                value={programDatamodel2.show_release_date}
                                                onChange={(e) => { setProgramDataModel2({ ...programDatamodel2, release_date: new Date(e.target.value).getTime() / 1000, show_release_date: e.target.value }); setAlertMessage({}) }}
                                                min={moment().format('YYYY-MM-DD')}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3 obbligatorieta-check">
                                        <label className="form-label">{t("superadmin.training.mandatory")}</label>
                                        <div className="d-flex">
                                            <label className={`mr-4 check-radiotype ${programDatamodel2.obligatory == "Yes" ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value="Yes"
                                                    name="obligatory"
                                                    checked={programDatamodel2.obligatory == "Yes"}
                                                    onChange={handleChangeUpdate2}
                                                />
                                                <span>{t("user.Questionnare.yes")}</span>
                                            </label>
                                            <label className={`check-radiotype ${programDatamodel2.obligatory == "No" ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value="No"
                                                    name="obligatory"
                                                    checked={programDatamodel2.obligatory == "No"}
                                                    onChange={handleChangeUpdate2}
                                                />
                                                <span>{t("user.Questionnare.no")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-3" >
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => checkValUpdateProgramModal2()}>{t("superadmin.training.popup.update")}</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getCompanyRefrent = async () => {
        setIsLoading(true);
        const refrentPayload = {
            "company": company_id,
            "role_id": 2
        };
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     token,
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getRefrentData = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_USER_LIST}`,
                refrentPayload,
                "",
                ""
            );
            if (getRefrentData && getRefrentData.status === 200) {
                setIsLoading(false);
                if (getRefrentData.data?.data?.length > 0) {
                    let arr = [];
                    getRefrentData.data.data.forEach((item) => {
                        if (item.status > 0) {
                            arr = [...arr, {
                                name: item.name || "",
                                user_id: item.user_id || "",
                            }]
                        }

                    })
                    setRefrentData(arr);
                }
            } else {
                setIsLoading(false);
                // setLoading(false);
            }
            // } 
            // else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const addTestRecords = async () => {
        setIsLoading(true);
        const payload = {
            ...finalTest, company_id: company_id, program_id: program_id, test_course_id: testCourseId, noOfQuestions: noOfQue
        };
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
                const getTestData = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_TEST_RECORD}`,
                    payload,
                    "",
                    ""
                );
                if (getTestData && getTestData.status === 200) {
                    if(getTestData.data.statuscode == 0 ) 
                    {
                    setAlertMessage1({ show: true, spinner: false, message: t("superadmin.training.test_program.test_exist"), class: "alert alert-danger" })
                    setTimeout (()=>{
                        setIsLoading(false);
                        closeModal();
                        getProgramSummery();
                        resetData();
                    },2000)
                    }
                    else{
                    setAlertMessage1({ show: true, spinner: true, message: t("superadmin.training.test_program.test_saved"), class: "alert alert-success" })
                    setTimeout (()=>{
                        setIsLoading(false);
                        closeModal();
                        getProgramSummery();
                        resetData();
                      },2000)

                    }
                    
                } else {
                    setIsLoading(true);
                    setAlertMessage({ show: true, spinner: false, message: getTestData.message, class: "alert alert-info" })
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            // console.log(error)
            setIsLoading(false);
        }
    };
    const updateTestRecords = async () => {
        setIsLoading(true);
        const payload = {
            ...finalTest, test_id:testId, company_id: company_id, program_id: program_id, test_course_id: testCourseId, noOfQuestions: noOfQue
        };
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
                const getTestData = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_EDIT_TEST_RECORD}`,
                    payload,
                    "",
                    ""
                );
                if (getTestData && getTestData.status === 200) {
                    setIsLoading(false);
                    closeEditModal();
                    getProgramSummery();
                    // resetData();
                } else {
                    setIsLoading(true);
                    setAlertMessage({ show: true, spinner: false, message: getTestData.message, class: "alert alert-info" })
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            // console.log(error)
            setIsLoading(false);
        }
    };

    const getProgramSummery = async () => {
        setIsLoading(true);
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     token,
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getProgramTestDetails = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_PROGRAM_TEST_SUMMARY}?companyId=${company_id}&programId=${program_id}`
            );

            if (getProgramTestDetails && getProgramTestDetails.status === 200) {

                if (getProgramTestDetails?.data?.data?.length > 0) {
                    setProgramTestSummaryData(getProgramTestDetails?.data?.data)
                }
                setIsLoading(false);
                resetData()
            } else if (getProgramTestDetails && getProgramTestDetails.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            // console.log("error", error)
            setIsLoading(false);
        }
    }

    const resetData = () => {
        setAllQuestionsArray([])
        setAllQuestionsObject([])
        setFinalTest({})
        setVal([])
    }

    const singleChoiceQuestions = (index) => {
        return (
            <>
                <div className="" >
                    <form className="registrati-form registrati-form-new nuovo-programma">
                        <div className="form-group">
                            <div>
                                <label className="form-label">{t("superadmin.training.test_program.question")} {index + 1}</label>
                                <div className="d-flex align-items-center domanda">
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" value={questionArr[index].que}  onChange={(e) => handleChange1(e, index)} placeholder={t("superadmin.training.test_program.questionPlaceholder")} />
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que2" value={questionItaArr[index].que}  onChange={(e) => handleChangeIta1(e, index)} placeholder={t("superadmin.training.test_program.questionItaPlaceholder")} />
                                </div>
                                {questionArr[index].ans?.map((data, i) => (
                                    <div className="d-flex align-items-center domanda" key={i}>
                                        <div className="d-flex align-items-center w-100">
                                            <span>{String.fromCharCode(65 + i)}</span>
                                            <textarea
                                                rows={2}
                                                type="text"
                                                className="form-control-input2"
                                                name="ans"
                                                value={data}
                                                placeholder={t("superadmin.training.test_program.answerPlaceholder")}
                                                onChange={e => handleTextArea(e, i, index)}
                                            />
                                            <textarea
                                                rows={2}
                                                type="text"
                                                className="form-control-input2"
                                                name="ans1"
                                                value={questionItaArr[index].ans[i]} 
                                                placeholder={t("superadmin.training.test_program.answerItaPlaceholder")}
                                                onChange={e => handleTextArea3(e, i, index)}
                                            />
                                        </div>
                                        <div className="d-flex obbligatorieta-check domanda-right-check">
                                            <label className={`mr-4 check-radiotype ${questionArr[index].correct === i ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    checked={questionArr[index].correct === i}
                                                    className="mr-2"
                                                    name={`correct${index}`}
                                                    value={i + 1}
                                                    onChange={e => handleCheck(e, i, index)}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <span className="text-color-save" onClick={() => handleAddVal(index)}>{t("superadmin.training.test_program.addAnswer")}</span>
                        </div>

                    </form>
                </div>

                {/* <div className="" >
                    <form className="registrati-form registrati-form-new nuovo-programma">
                        <div className="form-group">
                            <div>
                                <label className="form-label">{t("superadmin.training.test_program.question")}</label>
                                <div className="d-flex align-items-center domanda">
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" onChange={(e)=>handleChange1(e,index)} />
                                </div>
                                {val.map((data, i) => (
                                    <div className="d-flex align-items-center domanda" key={i}>
                                        <div className="d-flex align-items-center w-100">
                                            <span>{String.fromCharCode(65 + i)}</span>
                                            <textarea
                                                rows={2}
                                                type="text"
                                                className="form-control-input2"
                                                name="ans"
                                                value={val[i]}
                                                onChange={e => handleTextArea(e, i,index)}
                                            />
                                        </div>
                                        <div className="d-flex obbligatorieta-check domanda-right-check">
                                            <label className={`mr-4 check-radiotype ${correct === i ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    checked={correct === i}
                                                    className="mr-2"
                                                    name="correct"
                                                    value={i}
                                                    onChange={e => handleCheck(e, i)}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <span className="text-color-save" onClick={() => { handleAddVal(); handleCheckSequence(); }}>{t("superadmin.training.test_program.addAnswer")}</span>
                        </div>

                    </form>
                </div> */}
            </>
        )
    }

    const multipleChoiceQuestions = (index) => {
        return (
            <>
                <div className="" >
                    <form className="registrati-form registrati-form-new nuovo-programma">
                        <div className="form-group">
                            <div>
                                <label className="form-label">{t("superadmin.training.test_program.question")} {index + 1}</label>

                                <div className="d-flex align-items-center domanda">
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" value={questionArr[index].que} onChange={(e) => handleChange1(e, index)} placeholder={t("superadmin.training.test_program.questionPlaceholder")} />
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" value={questionItaArr[index].que} onChange={(e) => handleChangeIta1(e, index)} placeholder={t("superadmin.training.test_program.questionItaPlaceholder")} />
                                </div>
                                {
                                    questionArr[index].ans?.map((data, i) => (

                                        <div className="d-flex align-items-center domanda" key={i}>
                                            <div className="d-flex align-items-center w-100">
                                                <span>{(i == "0") ? String.fromCharCode(65) : String.fromCharCode(65 + i)}</span>
                                                <textarea rows={2} type="text" className="form-control-input2" name="ans" value={data}  onChange={e => handleTextArea2(e, i, index)} placeholder={t("superadmin.training.test_program.answerPlaceholder")} />
                                                <textarea rows={2} type="text" className="form-control-input2" name="ans1" value={questionItaArr[index].ans[i]}  onChange={e => handleTextArea3(e, i, index)} placeholder={t("superadmin.training.test_program.answerItaPlaceholder")} />
                                            </div>
                                            <div className="d-flex obbligatorieta-check domanda-right-check">
                                                <label className={`mr-4 check-radiotype ${(questionArr[index].correct.includes(i + 1)) ? "checked-icon" : ""} `}>
                                                    <input type="checkbox" className="mr-2" name="correct2" value={i + 1} onChange={e => handleCheck2(e, i + 1, index)} />
                                                </label>
                                            </div>
                                        </div>

                                    ))
                                }
                            </div>
                            <span className="text-color-save" onClick={() => handleAddVal2(index)}>{t("superadmin.training.test_program.addAnswer")}</span>
                        </div>

                    </form>
                </div>
            </>
        )
    }

    const trueFalseChoiceQuestions = (index) => {
        return (
            <>
                <div className="" >
                    <form className="registrati-form registrati-form-new nuovo-programma">
                        <div className="form-group">
                            <div>
                                <label className="form-label">{t("superadmin.training.test_program.question")} {index + 1}</label>
                                <input type="hidden" className="" name="type" value="tfq" />
                                <div className="d-flex align-items-center domanda">
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" value={questionArr[index].que}  onChange={(e) => handleChange1(e, index)} placeholder={t("superadmin.training.test_program.questionPlaceholder")} />
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" value={questionItaArr[index].que}  onChange={(e) => handleChangeIta1(e, index)} placeholder={t("superadmin.training.test_program.questionItaPlaceholder")} />
                                </div>

                                <div className="d-flex align-items-center domanda justify-content-between">
                                    <div className="d-flex obbligatorieta-check domanda-right-check mt-2 mb-4" >
                                        <span className={`test-choice ml-1 ${(questionArr[index]?.correct == 1) ? 'true-btn' : 'false-btn'}`} value="1" onClick={() => { handleCheck3(1, 'true', index) }}>{t("superadmin.training.test_program.enchoiceTrue")}</span>
                                        <span className={`test-choice ml-1 ${(questionArr[index]?.correct == 0) ? 'true-btn' : 'false-btn'}`} value="0" onClick={() => { handleCheck3(0, 'false', index) }}>{t("superadmin.training.test_program.enchoiceFalse")}</span>
                                    </div>
                                    {/* <div className="d-flex obbligatorieta-check domanda-right-check mt-2 mb-4" >
                                        <span className={`test-choice ml-1 ${(questionItaArr[index]?.correct == 1) ? 'true-btn' : 'false-btn'}`} value="1" onClick={() => { handleCheckIta3(1, 'true', index) }}>{t("superadmin.training.test_program.itachoiceTrue")}</span>
                                        <span className={`test-choice ml-1 ${(questionItaArr[index]?.correct == 0) ? 'true-btn' : 'false-btn'}`} value="0" onClick={() => { handleCheckIta3(0, 'false', index) }}>{t("superadmin.training.test_program.itachoiceFalse")}</span>
                                    </div> */}
                                </div>

                            </div>
                        </div>

                    </form>
                </div>
            </>
        )
    }

    // const writtenChoiceQuestions = (index) => {
    //     return (
    //         <>
    //             <div className="" >
    //                 <form className="registrati-form registrati-form-new nuovo-programma">
    //                     <div className="form-group">
    //                         <div>
    //                             <label className="form-label">{t("superadmin.training.test_program.question") + questionNumbers}</label>
    //                             <div className="d-flex align-items-center domanda">
    //                                 <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" onChange={(e) => handleChange1(e, index)}  placeholder="Question"/>
    //                             </div>
    //                             <div className="d-flex align-items-center domanda">
    //                                 <div className="d-flex align-items-center w-100">
    //                                     <textarea rows={10} type="text" className="form-control-input2" name="ans1" onChange={(e) => handleAnswer(e, index)} placeholder="Answer"/>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </form>
    //             </div>
    //         </>
    //     )
    // }
    const updateProgramModal1Config = () => {
        let allCourse = [];
        Object.values(programData.course).forEach((item, i) => {
            let obj = {
                english: { courseName: "", courseDescription: "", content: [] },
                italian: { courseName: "", courseDescription: "", content: [] }
            }
            item.forEach((d1) => {
                let content = allContentList?.find((d2) => d2.originalValue == d1.content_Id)
                if (content?.value) obj.english.content = [...obj.english.content, { value: content.originalValue, icon: content.icon, label: content.label?.length > 20 ? `${content.label?.substr(0, 20)}...` : content.label, type: content.type, title: content?.label, originalValue: content.originalValue }]
            })
            if (programData.courseITA) {
                Object.values(programData.courseITA)[i]?.forEach((d3) => {
                    let content = allContentList?.find((d4) => d4.originalValue == d3.content_Id)
                    if (content?.value) obj.italian.content = [...obj.italian.content, { value: content.originalValue, icon: content.icon, label: content.label?.length > 20 ? `${content.label?.substr(0, 20)}...` : content.label, type: content.type, title: content?.label, originalValue: content.originalValue }]
                })
            }
            if (programData.courseName?.length) obj.english.courseName = programData.courseName[i]
            if (programData.courseNameITA?.length) obj.italian.courseName = programData.courseNameITA[i]
            if (programData.courseDescription?.length) obj.english.courseDescription = programData.courseDescription[i]
            if (programData.courseDescriptionITA?.length) obj.italian.courseDescription = programData.courseDescriptionITA[i]
            allCourse = [...allCourse, obj]
        })
        setUpdateProgramAllCourse([...allCourse])
        setUpdateProgramData(programData)
        setModifyProgramOne(true);
    }
    const activeContentConfig = (id) => {
        if (activeContent == id) setActiveContent('')
        else setActiveContent(id)
    }
    const disableUserModalContent = () => {
     
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Delete_Icon_popup} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.popup.delete_course_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader1 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6>{t("superadmin.protocol.popup.delete_course_content")} </h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => {setModifyProgramOne(true); setDisableUserModal(false)} }>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button mr-3" onClick={() => removeCourse() } >{t("superadmin.protocol.popup.delete")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <Modal title="" size="sm" contentClasses="" onClose={() => setModifyUserModal(false)} show={modifyUserModal}>
                {modifyUserModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setTestProgram(false)} show={testProgram}>
                {addTestProgramContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setModifyProgramOne(false)} show={modifyProgramOne}>
                {modifyProgramContentOne()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setUpdateProgramModal2(false)} show={updateProgramModal2}>
                {upadateProgramModal2Content()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setAddProgramModal2Content(false)} show={addProgramModal2Content}>
                {addProgramModal2ContentFun()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setTestUpdateProgram(false)} show={testUpdateProgram}>
                {addTestUpdateProgramContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableUserModal(false)} show={disableUserModal}>
                {disableUserModalContent()}
            </Modal>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar />
                        <div className="col-md-10 pr-0 p0 ipd-righ">
                            <div className="right-dashboard topspace">
                                <div className="d-flex justify-content-between company-name-programs">
                                    <h2 className="heading-dash">
                                        <button className="back-button" onClick={history.goBack}><img src={PREV_PAGE_ICON} alt="icon" /></button>
                                        {companyDetails.company_name} - {t("superadmin.program.program_title")}</h2>

                                </div>
                                <div className="graph-tabs">
                                    <ul
                                        className="nav nav-tabs nav-stacked"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item" onClick={() => setPersonalText("detail")}>
                                            <a
                                                className={
                                                    "nav-link " +
                                                    (personalText == "detail"
                                                        ? "active show"
                                                        : "")
                                                }
                                                id="first-tab"
                                                data-toggle="tab"
                                                href="#first"
                                                role="tab"
                                                aria-controls="first"
                                                aria-selected="true"
                                            >
                                                <span className="das-icontabs">
                                                    <div className="dashboard-tabs-icon deskt-n"></div>
                                                    <span className="visi-none">
                                                        {t("superadmin.program.detail")}
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => setPersonalText("static")}>
                                            <a
                                                className={
                                                    "nav-link " +
                                                    (personalText == "static" ? "active show" : "")
                                                }
                                                id="second-tab"
                                                data-toggle="tab"
                                                href="#second"
                                                role="tab"
                                                aria-controls="second"
                                                aria-selected="false"
                                                onClick={() =>
                                                    window.dispatchEvent(new Event("resize"))
                                                }
                                            >
                                                <span className="das-icontabs" >
                                                    {/* <img src={Group784} className="deskt-n" alt="icon" /> */}
                                                    <div className="dashboard-tabs-icon deskt-n stress-icons"></div>
                                                    <span className="visi-none" >
                                                        {t("superadmin.program.static")}
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <>
                                            <div
                                                className={
                                                    "tab-pane fade " +
                                                    (personalText == "detail"
                                                        ? "active show"
                                                        : "")
                                                }
                                                id="first"
                                                role="tabpanel"
                                                aria-labelledby="first-tab"
                                            >
                                                <div className="row">
                                                    <div className="col-md-6 right_lines fade-lines pl-0 mb-5 ">
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <div className="text-left">
                                                                <h6 className="modal-title pop_heading " style={{ textAlign: "left" }}>
                                                                    <span className="program-title-heading">{(programData.programNameITA && currentLanguage == 'it') ? programData.programNameITA : programData.program_name}</span>
                                                                </h6>
                                                                <p className="margin-left20"  >{(programData.descriptionITA && currentLanguage == 'it') ? programData.descriptionITA : programData.description}</p>
                                                            </div>
                                                            {/* <img className="edit-margin" src={Group878} alt="icon" /> */}
                                                            <span className="button-cursor" onClick={() => { updateProgramModal1Config() }}><img className="edit-margin" src={Group878} alt="icon" /></span>

                                                        </div>
                                                        <div id="accordion" className="accordion Percorso-text">
                                                            <div className="iapdwful p-r15">
                                                                <div className="card mb-0">
                                                                    {courseData?.length > 0 && courseData?.map((data, i) => {
                                                                        const courseId = 'course' + i;
                                                                        const increment = i + 1;
                                                                        return (
                                                                            <div className="bot-lini" key={i}>
                                                                                {courseName.length > 0 ? <p className="course-name pt-2"> {courseName[i]}</p> :
                                                                                    <p className="course-name pt-2"> {t("superadmin.training.popup.path")} {i + 1}</p>
                                                                                }
                                                                                {courseDescription.length > 0 ? <div className={`${activeCourse == i ? "" : "text-ellipsis"} text-left cursor-pointer text-color-wh`} onClick={() => setActiveCourse(i)}>
                                                                                    {courseDescription[i]}
                                                                                </div> : null}
                                                                                {courseContent[data]?.length > 0 ? (
                                                                                    <div>
                                                                                        {courseContent[data].map((d, j) => {
                                                                                            const uniqueId = `collapse_${i}_${j}`;
                                                                                            return (
                                                                                                <React.Fragment key={j}>
                                                                                                    <div
                                                                                                        className="card-header percorso-header cursor-pointer percorso-header-new collapsed"
                                                                                                        data-toggle="collapse"
                                                                                                        data-target={`#${uniqueId}`}
                                                                                                        aria-expanded="true"
                                                                                                    >
                                                                                                        <a className="card-title">
                                                                                                            <div className={`percorso-heading ${d.content_Id == activeContent ? "" : "text-ellipsis"}`} onClick={() => activeContentConfig(d.content_Id)}>
                                                                                                                {d.content_Name}
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        id={uniqueId}
                                                                                                        className="card-body collapse collapse-dark"
                                                                                                        data-parent="#accordion"
                                                                                                    >
                                                                                                        <div className="d-flex align-items-start">
                                                                                                            <img className="mr-2 mt-2" src={(d.content_type == "document") ? DOCUMENTO_ICONA : VIDEO_ICONAvideo} alt="icon" />
                                                                                                            <p className="course-title">{(d.contentDescription) ? d.contentDescription : "No Description"}</p>

                                                                                                            {/* <p className="course-title">Transdermal Optical Imaging Reveal Basal Stress via Heart Rate Variability Analysis: A Novel Methodology Comparable to Electrocardiography</p> */}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </React.Fragment>
                                                                                            );

                                                                                        })}
                                                                                        {programTestSummaryData?.length > 0 && programTestSummaryData?.map((data, i) => {

                                                                                            if (data.test_course_id == courseId) {
                                                                                                return (
                                                                                                    <div className="bot-lini" key={i}>
                                                                                                        <p className="course-name pt-2"> {courseName[i]}</p>

                                                                                                        <div
                                                                                                            className="card-header percorso-header collapsed"
                                                                                                            data-toggle="collapse"
                                                                                                            data-target={`#${i}`}
                                                                                                            aria-expanded="true"
                                                                                                        >
                                                                                                            <a className="card-title">
                                                                                                                <div className="percorso-heading">
                                                                                                                    <div className="m-1">{data.description}</div>
                                                                                                                    <p className="m-1"><strong>{`${data.noOfQuestions} ${t("superadmin.training.popup.request")} / ${data.percentOfCorrectAns}%${t("superadmin.training.popup.answer")}`}</strong></p>
                                                                                                                </div>

                                                                                                            </a>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            id={i}
                                                                                                            className="card-body collapse collapse-dark"
                                                                                                            data-parent="#accordion"
                                                                                                        >
                                                                                                            <div className="d-flex align-items-start">
                                                                                                                {/* <img className="mr-2" src={DOCUMENTO_ICONA} alt="icon" /> */}
                                                                                                                <p className="course-title">{data.description}</p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                );
                                                                                            }
                                                                                        })}
                                                                                        <div className="card mt-2 mb-3" >
                                                                                            <button className="ricontrolla" onClick={() => { setTestProgram(true); setTestCourseId(courseId); }}>
                                                                                                {t("superadmin.training.test_program.add_test")}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        );
                                                                    })}





                                                                </div>

                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="d-flex">
                                                            <div className="Stato">
                                                                <h3><img src={Statusicona} alt="icon" /> {t("superadmin.program.status")}</h3>
                                                                <p>{
                                                                    (programData.status == 1) ? t("superadmin.program.active_status") : (programData.status == 2) ? t("superadmin.program.non_active_status") : t("superadmin.program.schedule_status")
                                                                }</p>
                                                            </div>
                                                            <div className="Stato">
                                                                <h3><img src={USER_ICONA} alt="icon" /> {t("superadmin.program.referente")}</h3>
                                                                <p>{programData.referent}

                                                                </p>
                                                            </div>
                                                            <div className="Stato">
                                                                <h3><img src={CALENDARIO_ICONA} alt="icon" /> {t("superadmin.program.duration")}</h3>
                                                                <p>{programData.duration + " " + t("superadmin.program.week")}


                                                                </p>
                                                            </div>
                                                            <div className="Stato border-r-o">
                                                                <h3><img src={PASSWORD_ICONA} alt="icon" /> {t("superadmin.program.mandatory")}</h3>
                                                                <p>{
                                                                    (programData.obligatory == "Yes") ? t("superadmin.program.Obligatory_yes") : t("superadmin.program.Obligatory_no")
                                                                }</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="Stato border-r-o">
                                                                <h3><img src={rilascio_icona} alt="icon" /> {t("superadmin.program.release")}</h3>
                                                                <p>
                                                                    {formatDate(
                                                                        programData.release_date * 1000
                                                                    )}
                                                                </p>

                                                            </div>
                                                            <button className="ricontrolla" onClick={() => { setProgramDataModel2({ status: programData.status, obligatory: programData.obligatory, duration: programData.duration, referent: programData.referent, show_release_date: moment(new Date(Number(programData.release_date) * 1000)).format("YYYY-MM-DD"), release_date: `${programData.release_date ? programData.release_date : ""}` }); setUpdateProgramModal2(true) }} >{t("superadmin.training.popup.modifyButton")} </button>
                                                        </div>

                                                        <div className="d-flex justify-content-between assegnatari">
                                                            <div>
                                                                <h3>{t("superadmin.program.assignees")}</h3>
                                                            </div>
                                                            <div>
                                                                <span>{programUser.length + " " + t("superadmin.program.persone")}</span>
                                                            </div>
                                                        </div>

                                                        {(showProgramUser.length > 0 && showProgramUser) ?
                                                            <div className="program-user-div">
                                                                <div className="program-user-list">
                                                                    {showProgramUser.map((d1, i1) => {
                                                                        return (
                                                                            <div key={i1} className="program-user">
                                                                                <div>{d1.name}</div>
                                                                                {(i1 + 1) % 3 == 1 && <hr className="hr-program-user" />}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                    <div className="d-flex sa-program-pagination">
                                                                        <ReactPaginate
                                                                            breakLabel="..."
                                                                            nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                            previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                            onPageChange={handlePageClickList}
                                                                            pageRangeDisplayed={1}
                                                                            marginPagesDisplayed={1}
                                                                            pageCount={pageCountList}
                                                                            renderOnZeroPageCount={null}
                                                                            containerClassName="selectuser-pagination"
                                                                            pageClassName="page-class"
                                                                            previousClassName={currentPageList == 1 ? "d-none" : ""}
                                                                            nextClassName={currentPageList == pageCountList ? "d-none" : ""}
                                                                            forcePage={currentPageList - 1}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <button className="ricontrolla" onClick={() => { handleModifyUserModal() }} >{t("superadmin.training.popup.modifyButton")} </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : <div>
                                                                <button className="ricontrolla" onClick={() => { handleModifyUserModal() }} >{t("superadmin.training.popup.modifyButton")} </button>
                                                            </div>}

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "tab-pane fade " +
                                                    (personalText == "static" ? "active show" : "")
                                                }
                                                id="second"
                                                role="tabpanel"
                                                aria-labelledby="second-tab"
                                            >
                                                <div className="row">
                                                    {
                                                    (Object.keys(refrentData).length != 0 && releaseDate)
                                                    ? 
                                                        <ProgramStatics company_id={company_id} program_id={program_id} weeks={weeks} releaseDate={releaseDate} courseList={courseList} />
                                                    : null
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    );
}

export default SA_Company_Programs;


