import PostRequest from '../../../apiConnection/postRequest';

// Function to calculate the sum of two numbers
export const add = (a, b) => {
    return a + b;
};

export const submitUserActivityData = async (payload, setIsLoading) => {

    try {
        const refreshToken = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            {},
            "",
            "refresh_token"
        );
        if (refreshToken) {
            const result = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
                payload,
                "",
                ""
            );
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    } catch (error) {
        setIsLoading(false);
        console.log("error", error)
    }
}
