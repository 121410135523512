import React from 'react'
import Group1018 from "../../../assets/Image/Group1018.svg";
import PREVIEWICONA from "../../../assets/Image/PREVIEWICONA.svg"
import Image2022 from "../../../assets/Image/Image2022.png"
import convalidabg from "../../../assets/Image/convalida-bg.png"
import { Link } from 'react-router-dom';
function validateMail() {
  return (
    <>
     <section className="bg_otp autenticazione otpipad d-flex align-items-center">
       <div className="container">
          <div className="row">
              <div className="auth"> 
                  <div className="icon-aut">
                       <img src={Group1018} className="key" alt="icon"/>
                         <h2 className="otp-heading">Convalida Mail</h2>
                           <p>Inserisci le 6 cifre del codice che hai  <br/> ricevuto via mail.</p>
                            <form action="/action_page.php" className='cdn-center'>
                             <div className="inautho form-group">
                              <input type="text" className="form-control" placeholder="" id="email"/> 
                              <input type="text" className="form-control" placeholder="" id="email"/>
                              <input type="text" className="form-control" placeholder="" id="email"/>
                              <input type="text" className="form-control" placeholder="" id="email"/>
                              <input type="text" className="form-control" placeholder="" id="email"/>
                              <input type="text" className="form-control" placeholder="" id="email" />
                              <small id="emailHelp" className="form-text2 mb-3">
                              OTP non ricevuto? Invia un altro OTP
                              </small>
                             </div>
                            <br/>
                           </form>
                         <Link to='/bi-steps'className="buttons">Conferma</Link>
                         <Link to='/newAccount'>
                            <div className="torna">
                            <img src={PREVIEWICONA} alt="icon"/> Torna all’accesso
                            </div>
                             </Link>
                      </div>
                  </div> 
              </div>
           </div>
    </section>

    </>
  )
}

export default validateMail


