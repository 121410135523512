import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { useParams } from "react-router-dom";
import Group698 from "../../../assets/Image/Group698.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import DeleteBin from '../../../assets/Image/delete-bin.svg'
import crosseditprofile from '../../../assets/Image/cross-editprofile.svg'
import VectorDown from "../../../assets/Image/VectorDown.svg";
import '../super_admin_assets/scss/protocol.scss';
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg"
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg'
import filterFactory from "react-bootstrap-table2-filter";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge } from "../../common/allfunction";
import moment from 'moment';


export const Brain_wellness = () => {
    const [t, i18n] = useTranslation("common");
    const [isLoading, setIsLoading] = useState(false);
    const [generalColumn, setGeneralColumn] = useState([]);
    const [companiesData, setCompaniesData] = useState([])
    const { SearchBar } = Search;

    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div className="d-flex justify-content-center">
                <button className="table-action" onClick={() => {}}><img src={ExpandIcon} alt="icon" /></button>
                <button className="table-action" onClick={() => window.location.href = `brain-wellness/${row.company_id}/${row.company_name}`}><img src={Group878} alt="icon" /></button>
                <button className="table-action" onClick={() => {}}><img src={DeleteBin} alt="icon" /></button>
            </div>
        )
    }

    const getCompaniesData = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_SA_GET_COMPANIES}`,
                    {},
                    "",
                    ""
                );
                if (getAllDataResult && getAllDataResult.status === 200) {
                    setIsLoading(false);
                    if (getAllDataResult.data.data?.length > 0 ) {
                        let arr = []
                        getAllDataResult.data.data.forEach((data) => {
                            if(data.status==1){
                                arr = [...arr, {
                                    company_name: data.company_name || "",
                                    referent: data.referent || "",
                                    // last_login: data.lastAccess ? moment.unix(data.lastAccess).format("DD.MM.YY") : "",
                                    last_login: (data.lastAccess=='null' || data.lastAccess==0) ? "" : moment.unix(data.lastAccess).format("DD.MM.YY"),
                                    contract: "",
                                    weeks: data.weeks > -1 ? data.weeks : "",
                                    company_id: data.company_id || ""
                                }]
                            }
                            setCompaniesData(arr)
                        })
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                text: t("superadmin.protocol.company_name"),
                dataField: "company_name",
                sort: true,

            },
            {
                text: t("superadmin.protocol.contact_name"),
                dataField: "referent",
                align: "left",
                sort: true,
                style: {
                    textAlign: "left"
                }
            },
            {
                text: t("superadmin.protocol.no-repetitions"),
                dataField: "last_login",
                sort: true
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtons,
                sort: true,
                headerAlign: "center"
            },
        ])
    };
    useEffect(() => {
        setIsLoading(true)
        getCompaniesData()
        handleTranslationLang()
    }, []);
    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#6d71f9';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'white';
        } else {
            // activeStyle.backgroundColor = 'gray';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = '';
            activeStyle.color = '#6d71f9';
        }
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-center pagination-list" >
                {pages.map((p,i) => {
                    if (p.page === "<") return <div key={i} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon"  style={{
                        marginTop: "20px", marginRight: "20px"
                    }}  /></div>
                    if (p.page === ">") return <div key={i} onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" style={{
                        marginTop: "20px", marginLeft: "20px"
                    }} /></div>
                    if (p.active) return <div key={i} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    return (
                        <div key={i} style={{ margin: "10px", cursor: "pointer" }} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    )
                })}
                {/* <li className="">
                    <a href="#" onClick={handleClick} style={activeStyle}>{page}</a>
                </li> */}
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }

    return (
        <>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="brain-wellness" />
                        <div className="col-md-10 pr-0 p0 ipd-righ">    
                            <div className="right-dashboard topspace">
                                <div className="d-flex justify-content-between protocollo-space">
                                    <h2 className="heading-dash">{t("superadmin.sidebar.brain_wellness")}</h2>
                                </div>
                                {(companiesData.length > 0 && companiesData) ? <>
                                    <ToolkitProvider
                                        keyField="company_id"
                                        key={'datatable'}
                                        data={companiesData}
                                        columns={generalColumn}
                                        search
                                    >{(props) => (
                                        <div id="test" className="table  ricsb ricsb-new protocolli-data">
                                            <div>
                                                <div>
                                                    <div className="searchbar d-flex justify-content-start m-2 position-relative">
                                                        <img
                                                            src={Group698}
                                                            className="search_icon"
                                                            alt="icon"
                                                        />
                                                        <SearchBar
                                                            placeholder={t("superadmin.protocol.search")}
                                                            {...props.searchProps}
                                                        />
                                                    </div>
                                                    <BootstrapTable
                                                        src={VectorDown} alt="icon"
                                                        data={companiesData}
                                                        {...props.baseProps}
                                                        filter={filterFactory()}
                                                        pagination={paginationFactory(paginationOption)}
                                                        wrapperClasses={'table-responsive'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </ToolkitProvider>
                                </> : null}
                            </div>
                        </div>
                    </div>
                    {(isLoading) ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    )
}
