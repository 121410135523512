import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from "react-i18next";
import Group1432 from "../../../../assets/Image/Group_1432.svg";
import { getLocalStoarge, setLocalStoarge } from '../../../common/allfunction';
import PostRequest from '../../../apiConnection/postRequest';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import bw_group_1432 from "../../../../assets/Image/bw_group_1432.svg";
export const NBackVisivoResult = ({ props }) => {
    const { params, avgAccuracy, totalTime, totalAvgResponseTime, score, roundTime, round, maxItemKeepMind, activitiyMaxScore, accuracyCurrentRound ,free } = props;

    const [t, i18n] = useTranslation("common");
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [isLoading, setIsLoading] = useState(true);

    const [payload, setPayload] = useState({
        company_id: companyId,
        component_id: parseInt(params.component_id),
        day_sequence: parseInt(params.day_sequence),
        repeat_time: parseInt(params.repeat_time),
        activity_id: parseInt(params.activity_id),
        status: "complete",
        activity_score: avgAccuracy,
        activity_play_time: totalTime * 60,
        total_avg_response_time: 0,
        activity_round_no: round + 1,
        activities_max_score: activitiyMaxScore
    })

    useEffect(() => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    }, [])

    useEffect(() => {
        if (free) {
            var resultObj = [];
            if (localStorage.getItem('cognativeResult_'+payload.repeat_time).length > 0) {
                resultObj = JSON.parse(localStorage.getItem('cognativeResult_'+payload.repeat_time));
            }
            resultObj[4] = {
                'activityName': 4,
                'avgAccuracy': avgAccuracy ? avgAccuracy : 0,
                'totalAvgResponseTime': totalAvgResponseTime ? totalAvgResponseTime : 0
            };
            setLocalStoarge("cognativeResult_"+payload.repeat_time, JSON.stringify(resultObj));
            setIsLoading(false)
        }
        else {
            submitUserActivityData(payload);
        }
    }, [payload])


    const submitUserActivityData = async (payload_1) => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const result = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
                    payload_1,
                    "",
                    ""
                );
                if (result) {
                    if (result.status === 200) {
                        // Handle status 200
                        // console.log("api_submit_result_1", result);
                    } else if (result.status === 203) {
                        // Handle status 203
                    }
                }
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }


    return (
        <section className="reaction-speed-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <div className="stepsin stpelog">
                            <Link to="dashboard">
                                <img src={logo} className="logo-steps" alt="logo" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="reaction-speed-result">
                            <div className="col-md-6">
                                <div className="reaction-speed-result-1">
                                    <h2>{t("user.activity.reaction_speed.result_heading")}</h2>
                                    <p>{t("user.activity.n_back_visivo.result_message_1")} {maxItemKeepMind} {t("user.activity.n_back_visivo.result_message_2")} {Math.ceil(roundTime / 60)} {t("user.activity.n_back_visivo.result_message_3")} </p>
                                    <div className="row">
                                        <div className="reaction-speed-result-1-1">
                                            <div className="Col-sm-10 col-md-10 col-lg-6 col-xl-6 col-xxl-6">
                                                <p className="reaction-speed-result-reponse-bar">
                                                    <CircularProgressbar value={accuracyCurrentRound} maxValue={1} strokeWidth={12} styles={buildStyles({
                                                        pathColor: parseInt(Math.round(parseFloat(accuracyCurrentRound) * 100)) >= 60 ? '#9ECB61' : '#B02418',

                                                    })}
                                                    /></p>
                                            </div>
                                            <div className="col-md-6 reaction-speed-result-accuracy">
                                                <h6>{parseInt(Math.round(parseFloat(accuracyCurrentRound) * 100))}%</h6>
                                                <p>{t("user.activity.reaction_speed.result_accuracy")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={`reaction-speed-result-2 ${parseInt(Math.round(parseFloat(accuracyCurrentRound) * 100)) >= 60 ? 'tumbs-up' : 'tumbs-down'}`}>
                                    {parseInt(Math.round(parseFloat(accuracyCurrentRound) * 100)) >= 60 ?
                                        < img src={Group1432} alt="icon" />
                                        :
                                        <img src={bw_group_1432} alt="icon" />
                                    }
                                </div>
                            </div>
                        </div>d
                        <Link to={`/congnitive-training/${parseInt(params.repeat_time)}`}>
                            <button className="btn btn-primary btnNext-act">
                                {t("user.activity.reaction_speed.exit")}
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}
