import axios from "axios";

async function GetRequest(session_url) {
    try {
        let role = parseInt(window.localStorage.getItem('roleId'));
        let saved = (role === 2) ? window.localStorage.getItem('R2') : (role === 3) ? window.localStorage.getItem('R3') : (role === 4) ? window.localStorage.getItem('R3') : window.localStorage.getItem('R1');
        let initialValue = JSON.parse(saved);
        let Token = (initialValue)?.access_token;
        let config = {
            headers: { Authorization: `Bearer ${Token}` }
        };

        const getResponse = await axios.get(session_url, config);
        return getResponse;
    } catch (error) {
        console.log("error ==>", error);
        throw error; // Re-throw the error to maintain the original behavior
    }
}

export default GetRequest;