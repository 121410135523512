import { Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated = JSON.parse( localStorage.getItem("R1"));
  const isAuthenticatedRoleId = JSON.parse( localStorage.getItem("roleId"));
  if((isAuthenticated && isAuthenticatedRoleId == 1)){
    // remove r1
    localStorage.removeItem("R2");
    localStorage.removeItem("R3")
  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated?.access_token && (isAuthenticatedRoleId == 1)? <Component {...props} /> : window.location.href='/'
      }
    />
  );
}


export default ProtectedRoute;