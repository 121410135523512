import React from "react";
import Group842 from "../../../assets/Image/Group842.svg";
import Group1165 from "../../../assets/Image/Group1165.svg";
import Riepilogo1 from "../../../assets/Image/Riepilogo1.svg";
import REPORT_ICONA from "../../../assets/Image/REPORT_ICONA.svg";
import PROTOCOL_ICON from '../../../assets/Image/PROTOCOLLI_ICONA.svg';
import FORMAZI from '../../../assets/Image/FORMAZIONE_ICONA.svg';
import riepilogo from "../../../assets/Image/riepilogo.svg";
import LOGOUT_ICON from '../../../assets/Image/LOGOUT_ICONA.svg'
import dashboardIcon from "../../../assets/Image/dashboardIcon.svg";
import userManualImg from '../../../assets/Image/user-manual-sidebar.svg';
import Istruzioni from '../../../assets/Image/INFO_icon.svg'

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  removeLocalStoarge1, 
  Logout,
} from "../../common/allfunction";

function Super_Sidebarmob() {
  const [activebg, setActivebg] = useState("active");
  const path = window.location.pathname;
  const pageName = path.substring(path.lastIndexOf("/") + 1);
  useEffect(() => {
    setActivebg(pageName);
  }, []);
  const removeToken = () => {
    const logout = Logout();
    if (logout) {
        removeLocalStoarge1();
        window.location.href = "/sa/signin";
    } else {
        console.log("something was wrong");
    }
};
  return (
    <section>
      <div className="mobl-menu">

        <div className={activebg == "protocol" ? "active" : ""}>
        <Link to="/protocol">
            <img src={PROTOCOL_ICON} alt="icon" />
        </Link>
        </div>
        <div className={activebg == "training" ? "active" : ""}>
        <Link to="/training">
          <img src={FORMAZI} alt="icon" />        
      </Link>
        </div>
        <div className={activebg == "white-label" ? "active" : "com-act"}>
          <Link to="/white-label">
            <img src={dashboardIcon} alt="icon" />
          </Link>
        </div>
        <div className={activebg == "profile" ? "active" : "com-act"}>
          <Link to="/profile">
            <img src={Group1165} alt="icon" />
          </Link>
        </div>
        <div className={activebg == "sadmin-manual" ? "active" : "com-act"}>
          <Link to="/sadmin-manual">
            <img src={Istruzioni} alt="icon" />
          </Link>
        </div>
         <div  onClick={removeToken}>
          <Link to="">
            <img src={LOGOUT_ICON} alt="icon" />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Super_Sidebarmob;
