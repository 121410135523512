import React, { useState, useEffect } from 'react'
import { getLocalStoarge } from '../../../common/allfunction';
import { useTranslation } from 'react-i18next';
import logo from '../../../../assets/Image/logo.svg'
import { Link, useHistory } from "react-router-dom";
import Group1778 from '../../../../assets/Image/Group1778.svg'
import NEXT_ICONA from '../../../../assets/Image/NEXTICONA.svg'
import { useSpeechSynthesis } from "react-speech-kit";
import { WorkingMemoryResume } from "./WorkingMemoryResume";
import PostRequest from '../../../apiConnection/postRequest';
import GetRequest from "../../../apiConnection/getRequest";
import LazyLoader from "../../../common/lazyLoader";



export const WorkingMemory = (props) => {
    const history = useHistory();
    const [t, i18n] = useTranslation("common");
    const [showNumber, setShowNumber] = useState([])
    const { speak, voices } = useSpeechSynthesis();
    const [answer, setAnswer] = useState();
    const [enterNumber, setEnterNumber] = useState()
    const component_id = props?.match?.params?.component_id;
    const repeat_time = props?.match?.params?.repeat_time;
    const day_sequence = props?.match?.params?.day_sequence;
    const activity_id = props?.match?.params?.activity_id;
    const [numbers, setNumbers] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 0,])
    const [generateNumber, setGenerateNumber] = useState(null)
    const [length, setLength] = useState(1);
    const [steps, setSteps] = useState(1);
    const [randomNum, setRandomNum] = useState([]);
    const [value, setValue] = useState("");
    const [score, setScore] = useState(0);
    const [activityStatus, setActivityStatus] = useState("");
    const [isPaused, setIsPaused] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState();
    const [round, setRound] = useState(0);
    const [activityPlayTime, setActivityPlayTime] = useState();
    const [totalTime, setTotalTime] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [avgAccuracy, setAvgAccuracy] = useState(0);
    const [accuracyCurrentRound, setAccuracyCurrentRound] = useState();
    const [wrongAns, setWrongAns] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [speaks, setSpeaks] = useState(false);
    const [translatedNumArr, setTranslatedNumArr] = useState([]);
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));
    const totalSteps = 20;
    const [free, setFree] = useState(props?.match?.params?.free);

    useEffect(() => {
        document.body.className = (theme) ? theme : 'light';
    }, [theme])

    const handleClickNum = (num) => {
        setShowNumber([...showNumber, num])
    }

    const handleRemoveNum = () => {
        const temp = showNumber.slice(0, -1);
        setShowNumber(temp)
    }

    useEffect(() => {
        const compareNumber = showNumber.join('')
        setEnterNumber(compareNumber)
    }, [showNumber])


    // useEffect(() => {
    //     if (!isPaused && !isLoading) {
    //         createRandomNum();
    //     }
    // }, [isPaused, isLoading])


    function getNumber(value) {
        if (steps != 0) {

            var reverseArr = [];
            reverseArr = value.reverse();


            if (JSON.stringify(reverseArr) === JSON.stringify(randomNum)) {
                console.log("Your ans is correct");
                setScore(length);
                setSteps(steps + 1);
            } else {
                // setAvgAccuracy((avgAccuracy + 1) / round);
                avgScore();
                setWrongAns(true);
                setTimeout(() => {
                    setWrongAns(false);
                    setRedirect(true);
                }, 500)

                console.log("Your ans is incorrect");
            }
        }

        setValue("");

        if (steps > totalSteps) {
            return setGameStop(false);
        }
    }


    useEffect(() => {
        setShowNumber([]);
        if ((!isPaused) && (steps <= totalSteps) && (!redirect) && (!isLoading)) {
            createRandomNum();
        }
    }, [steps, isPaused, isLoading])

    useEffect(() => {
        if (steps >= 0 && steps <= totalSteps) {
            setAccuracyCurrentRound(score);
        }
    }, [score])


    // useEffect(() => {
    //     console.log("accuracyCurrentRound", accuracyCurrentRound);
    //     avgScore();
    // }, [accuracyCurrentRound])

    const avgScore = () => {
        let accuracy;
        // console.log("accuracyCurrentRound", accuracyCurrentRound);
        accuracy = (((avgAccuracy * round) + accuracyCurrentRound) / (round + 1));
        console.log("accuracy", accuracy);

        setAvgAccuracy(accuracy)
    }

    // useEffect(() => {
    //     setAvgAccuracy((accuracyCurrentRound) / (round + 1));
    // }, [accuracyCurrentRound])


    function createRandomNum() {
        // console.log("createRandomNum function called");

        var numArr = [];

        let nextLength = length + 1;

        if (nextLength >= 9) {
            nextLength = 9
        }

        // if (steps <= 3) {
        //     nextLength = 2
        // }

        for (let digit = 0; digit < nextLength; digit++) {
            // console.log("digit", digit);
            const randomInteger = Math.floor(Math.random() * 10);
            numArr.push(randomInteger);
        }

        // console.log("numArr", numArr);setLength
        setRandomNum(numArr);
        // console.log("randomNum", randomNum)
        // console.log("numArr", numArr.join(' | '))
        // console.log("numArr", numArr.join(' | ').replaceAll(' | ', ' '))


        const translatedNumArr = numArr.map((digit) => t(`user.activity.working_memory.numbers.${digit}`));
        setTranslatedNumArr(translatedNumArr)

        // console.log("translatedNumArr", translatedNumArr);
        setLength(nextLength);
        return randomNum;
    }


    useEffect(() => {
        if (translatedNumArr) {
            const selectedVoice = voices.find(voice => voice.lang.includes('it-IT'));
            speak({ text: translatedNumArr.join(' | ').replaceAll(' | ', ' '), voice: selectedVoice });
        }
    }, [translatedNumArr])


    useEffect(() => {
        // console.log("getReactionSpeedUserData use effect called")
        if (!free) {
            getReactionSpeedUserData();
        }
        else {
            setIsLoading(false);
        }
    }, [])

    const getReactionSpeedUserData = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );

            // console.log("refresh_token", refreshToken);
            if (refreshToken) {
                const getUserActivityData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_ACTIVITY_STATUS}?repeat_time=${repeat_time}&component_id=${component_id}&day_sequence=${day_sequence}&activity_id=${activity_id}`,
                    "",
                    "",
                    ""
                );

                // console.log("getPreAssessmentData_3", getUserActivityData?.data?.data?.activity_data);
                if (getUserActivityData && getUserActivityData.status === 200) {
                    // console.log("getPreAssessmentData_4_1", getUserActivityData);
                    setIsLoading(false);
                    if (getUserActivityData?.data?.data?.activity_data) {
                        // console.log("get-api-data", getUserActivityData?.data?.data?.activity_data);
                        let dataObj = getUserActivityData?.data?.data?.activity_data;
                        setActivityPlayTime(dataObj.activity_play_time);
                        setTotalTime(dataObj.activity_total_data[0].activity_duration);
                        setTimeRemaining(((dataObj.activity_total_data[0].activity_duration) * 60) - (dataObj.activity_play_time));
                        setRound(dataObj.activity_round_no);
                        setAvgAccuracy(dataObj.activity_score);
                        setActivityStatus(dataObj.status);
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }

    useEffect(() => {
        let timerId;
        if (!isPaused && timeRemaining > 0) {
            timerId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timeRemaining, isPaused]);

    const togglePause = () => {
        // console.log("toggle paused called");
        setIsPaused(!isPaused);
    };

    const startTimer = () => {
        setIsPaused(false);
    }

    const resetRound = () => {
        console.log('Parent function  is called');
        setSteps(1);
        setLength(1);
        // setRound(round + 1);
        getReactionSpeedUserData();
        setRedirect(!redirect);
    }

    // console.log("timeRemaining", timeRemaining);

    // const minutes = Math.floor(timeRemaining / 60);
    // const seconds = timeRemaining % 60;

    useEffect(() => {
        if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && !free) {
            history.push(`/working-memory-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`);
        }
        else if (activityStatus === "complete" && component_id && repeat_time && day_sequence && activity_id && free) {
            history.push(`/working-memory-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`);
        }
    }, [activityStatus])

    return (
        <>
            {
                ((steps <= totalSteps) && (redirect == false)) ?
                    <>
                        {(isLoading) ? <LazyLoader /> : null}
                        < section className="reaction-speed-section" >
                            {/* {console.log("redirect", redirect)} */}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="stepsin stpelog">
                                            <Link to="dashboard">
                                                <img src={logo} className="logo-steps" alt="logo" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="btnNext-top">
                                            {free ?
                                                <Link to={`/working-memory-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}/${free}`}>
                                                    <button
                                                        className="btn btn-primary  btnNext"
                                                    >
                                                        {t("user.activity.reaction_speed.back")}
                                                    </button>
                                                </Link> :
                                                <Link to={`/working-memory-intro/${component_id}/${repeat_time}/${day_sequence}/${activity_id}`}>
                                                    <button
                                                        className="btn btn-primary  btnNext"
                                                    >
                                                        {t("user.activity.reaction_speed.back")}
                                                    </button>
                                                </Link>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="reaction-speed-p">
                                            <p className="reaction-speed-p-1">{t("user.activity.working_memory.working_memory_heading")}</p>
                                            <p className="reaction-speed-p-2" dangerouslySetInnerHTML={{ __html: t("user.activity.working_memory.working_memory_instruction") }}></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="reaction-speed-step">
                                            <p>{steps}/{totalSteps}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className='working-activity-main'>
                                            <div className='working-activity-inner' style={{ border: wrongAns ? '5px solid red' : null }}>
                                                <h1 className='activity-number'>{!wrongAns ? showNumber : null}</h1>
                                            </div>
                                            <p>{answer}</p>
                                        </div>
                                        <div className='activity-number-type'>
                                            <div className='activity-number-inner-block'>
                                                {numbers && numbers.map((num, index) => {
                                                    return (
                                                        <>
                                                            <button type='button' key={index} className='num-btn' onClick={() => handleClickNum(num)}><p>{num}</p></button>
                                                            {num === 5 && (
                                                                <button type='button' className='num-btn none-mobile-device' onClick={() => handleRemoveNum()}>{t(`user.activity.working_memory.DEL`)}</button>
                                                            )}
                                                        </>
                                                    )
                                                })}
                                                <button type='button' className='num-btn visible-on-mobile' onClick={() => handleRemoveNum()}>{t(`user.activity.working_memory.DEL`)}</button>
                                                <button type='button' className='num-btn' disabled={(showNumber.length) > 0 ? false : true} onClick={() => getNumber(showNumber)}>{t(`user.activity.working_memory.OK`)}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section >
                    </>
                    :
                    <WorkingMemoryResume props={{ "round": round, "totalTime": totalTime, "resetRound": resetRound, "togglePause": togglePause, "startTimer": startTimer, "timeRemaining": timeRemaining, "params": props.match.params, "avgAccuracy": avgAccuracy, "free": free }} />
            }
        </>
    )
}
