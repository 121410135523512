import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import NEXTICONA from "../../../../assets/Image/NEXTICONA.svg";
import PRIVIEWICONA from '../../../../assets/Image/PREVIEWICONA.svg';
import CognitiveRadarChart from './CognitiveRadarChart';
import CognitiveOverAllCognitive from './CognitiveOverAllCognitive';
import CognitiveActivityWisePrePost1 from './CognitiveActivityWisePrePost1';
import CognitiveActivityWisePrePost2 from './CognitiveActivityWisePrePost2';
import CognitiveNbackBarChart from './CognitiveNbackBarChart';
import CognitiveGoNoGoBarChart from './CognitiveGoNoGoBarChart';
import { getLocalStoarge, getUserScore } from '../../../common/allfunction';
import moment from 'moment';
import LazyLoader from '../../../common/lazyLoader';


const CognitiveReport = (props) => {
    const [t, i18n] = useTranslation("common");
    const [repeatTime, setRepeatTime] = useState(props.match.params.repeatTime);
    const [componentId, setComponentId] = useState(props.match.params.component_id);
    const [reportPageCount, setReportPageCount] = useState(6);
    const [currentPage, setCurrentPage] = useState(null);
    const [theme, setTheme] = useState(JSON.parse(localStorage.getItem("theme")));
    const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
    const [dynamicId, setDynamicId] = useState(getLocalStoarge('dynamic_id'));
    const [cognitiveRepeatation, setCognitiveRepeatation] = useState(1);
    const [cognitiveDate, setCognitiveDate] = useState([]);
    const [data, setData] = useState({});
    const [assessmentComplete, setAssessmentComplete] = useState();
    const [lastReaptTime, setLastReaptTime] = useState(1);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        getUserScoreData();
    }, [repeatTime]);

    const getUserScoreData = async () => {
        const data = await getUserScore(companyId, dynamicId, componentId, repeatTime);
        setData(data);
    }

    const handlePageClick = (event) => {
        let page = event.selected + 1
        setCurrentPage(page)
    };

    useEffect(() => {
        document.body.className = theme || 'light';
    }, [theme]);

    useEffect(() => {
        if (data) {
            setCognitiveRepeatation(data?.module_training_date?.repeat_allowed_cognitive);
            setCognitiveDate(data?.module_training_date?.repeat_start_dates_cognitive);
            setAssessmentComplete(data?.trainingData?.postAssessment_date);
            setCurrentPage(1);
            setIsLoading(false);
        }
    }, [data])


    const handleRepeat = (index) => {
        setLastReaptTime(repeatTime);
        setRepeatTime(index)
    }

    // useEffect(() => {
    //     if (!assessmentComplete) {
    //         setRepeatTime(lastReaptTime);
    //     }
    // }, [assessmentComplete])


    const renderComponentByPage = () => {
        switch (currentPage) {
            case 1:
                return <CognitiveOverAllCognitive data={data} />;
            case 2:
                return <CognitiveRadarChart repeatTime={repeatTime} />;
            case 3:
                return <CognitiveActivityWisePrePost1 repeatTime={repeatTime} />;
            case 4:
                return <CognitiveActivityWisePrePost2 repeatTime={repeatTime} />;
            case 5:
                return <CognitiveNbackBarChart repeatTime={repeatTime} />;
            case 6:
                return <CognitiveGoNoGoBarChart repeatTime={repeatTime} />;
            default:
                return null;
        }
    }

    return (
        <>
            {isLoading ? <LazyLoader /> : null}
            <div>
                <div className='row m-0 mb-3'>
                    <div className="col-md-3 col-lg-4 col-xl-4 col-xxl-4">
                        <Link to="/dashboard">
                            <img src={logo} className="logo-steps" alt="logo" />
                        </Link>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-4 col-xxl-4  mt-3">
                        <div className='text-center'>
                            <p className='report-prev'>{t('user.activityReport.view_previous')}</p>
                            <div className='d-flex repeat-time-show'>
                                {cognitiveDate && cognitiveDate.length > 0 && cognitiveDate?.map((date, index) => (
                                    <div key={index} className={`${(repeatTime == index + 1) ? `bw-active-active mr-5 cursor-pointer` : 'bw-inactive cursor-pointer mr-5'}`} onClick={() => handleRepeat(index + 1)}><span className='repeat-time-font'>Report {index + 1} </span><p>{moment(date * 1000).format("DD.MM.YYYY")}</p></div>
                                ))}

                            </div>
                            <hr className='report-hr m-0' />
                        </div>
                    </div>
                    <div className="col-md-3 col-lg-4 col-xl-4 col-xxl-4 text-right">
                        <Link to={`/congnitive-training/${repeatTime}`}>
                            <button
                                className="btn btn-primary report-btnNext px-0 py-2 mr-4 mt-4 "
                            >
                                {t("user.activity.reaction_speed.back")}
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='load-component'>
                    {data && renderComponentByPage()}
                </div>
            </div>

            {data ? <div className="d-flex sa-program-pagination mt-3 mb-5">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={<img src={NEXTICONA} alt="icon" />}
                    previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={reportPageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="selectuser-pagination"
                    pageClassName="page-class"
                    previousClassName={currentPage == 1 ? "d-none" : ""}
                    nextClassName={currentPage == reportPageCount ? "d-none" : ""}
                    forcePage={currentPage - 1}
                />
            </div> : null}
        </>


    )
}

export default CognitiveReport
