import axios from "axios";
import moment from "moment";

async function PostRequest(session_url, formData, token, type) {
  let role = parseInt(window.localStorage.getItem('roleId'));
  let saved = (role === 2) ? window.localStorage.getItem('R2') : (role === 3) ? window.localStorage.getItem('R3') : (role === 4) ? window.localStorage.getItem('R3') : window.localStorage.getItem('R1');
  let userTime = window.localStorage.getItem('usertime')
  let initialValue = JSON.parse(saved);
  let Token = (initialValue)?.access_token;
  let expiryTime = initialValue?.expires_in;
  const startTime = (moment().unix() * 1000);
  const duration = moment.duration(startTime - userTime);
  const minutes = Math.floor(duration.asMinutes()) % 60;

  let config = {
    headers: { Authorization: `Bearer ${Token}` }
  };

  let payload = formData;


  if (type == "refresh_token") {

    payload = {
      "refresh_token": initialValue.refresh_token
    }

    // if (minutes <= 1) {
    //   return Token;
    // }

  } else if (type == "ImageUpload") {
    config = {
      headers: {
        Authorization: `Bearer ${Token}`,
        Accept: `*/*`,
        'content-type': 'multipart/form-data'
      },

    }
  }

  try {
    if (type == "refresh_token") {
      localStorage.setItem("usertime", new Date().getTime());
    }
    return new Promise((resolve, reject) => {
      axios
        .post(session_url, payload, config)
        .then((getResponse) => {
          if (type == "refresh_token") {
            var validity = check_time();
            if (!validity) {
              if (role === 3 || role === 4) {
                window.location.href = "/sa/signin";
              }
              else if (role === 2) {
                window.location.href = `/admin/signin/${localStorage.getItem('dynamic_id')}`;
              } else {
                window.location.href = `/app/login/${localStorage.getItem('dynamic_id')}`;
              }
              window.localStorage.clear();
              resolve(false);
              return;
            }

            if (getResponse.status === 200 && getResponse.data.code === 200) {
              const { access_token, expires_in, refresh_token } = getResponse.data.data;
              const logged_user = {
                access_token,
                expires_in,
                refresh_token,
                //refereshed_at : set current time using moment
              };
              localStorage.setItem("usertime", new Date().getTime());
              if ((role === 3) || (role === 4)) {
                localStorage.setItem("R3", JSON.stringify(logged_user));
              }
              else if (role === 2) {
                localStorage.setItem("R2", JSON.stringify(logged_user));
              } else {
                localStorage.setItem("R1", JSON.stringify(logged_user));
              }
              resolve(access_token);
            } else {
              resolve(false);
              if ((role == 3) || (role == 4)) {
                window.location.href = "/sa/signin";
              }
              else if (role == 2) {
                window.location.href = `/admin/signin/${localStorage.getItem('dynamic_id')}`;
              } else {
                window.location.href = `/app/login/${localStorage.getItem('dynamic_id')}`;
              }
              localStorage.clear()
            }
          } else {
            resolve(getResponse);
          }
        })
        .catch((error) => {
          if (error.response) {
            reject(error.message)
          }
        });
    })
  } catch (error) {
    console.log("error ==>", error);

  }
}
const check_time = () => {
  var expired_time = parseInt(process.env.REACT_APP_CURENT_TIME)
  let time_date = window.localStorage.getItem("usertime") || new Date().getTime();
  const DT_1 = time_date;
  const DT_2 = new Date().getTime();
  var diff = DT_2 - DT_1;
  var minutes_diff = (diff / (1000 * 60)).toFixed(0);
  if (minutes_diff >= expired_time) {
    return false;
  } else {
    return true;
  }
};
export default PostRequest;
