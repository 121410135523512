import PostRequest from '../apiConnection/postRequest';
import CryptoJS from 'crypto-js';
import { api_axios, base_axios } from "../apiConnection/axios";
import { publicKey } from "../user/publikcKey.ts";
import crypto from "crypto";
import { v1 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import GetRequest from '../apiConnection/getRequest';
import { async } from 'q';

export const getProfile = async () => {
  const payload = {};
  try {
    // setIsLoading(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refresh_token"
    );
    if (refreshToken) {
      let role = parseInt(window.localStorage.getItem('roleId'));
      let endPoint = (role === 1) ? process.env.REACT_APP_GET_PROFILE : (role === 2) ? process.env.REACT_APP_ADMIN_GET_PROFILE : process.env.REACT_APP_SA_GET_PROFILE;
      const getProfileResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
        payload,
        "",
        ""
      );
      if (getProfileResult && getProfileResult.status === 200) {
        if (getProfileResult.data.code === 200) {
          const getProfile = getProfileResult.data.data;
          return getProfile;
        } else {
          // setIsLoading(false);
          return false;
        }
      } else if (getProfileResult && getProfileResult.status === 203) {
        // setIsLoading(false);
        return false;
      }
      else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    // setIsLoading(false);
    return false;
  }
};

export const getCurrentDateMeasurment = async (profile) => {
  const payload = {};
  try {
    const result = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CURRENT_DATE_MEASUREMENT}`,
      payload,
      "",
      ""
    );
    if (result && result.status === 200) {
      if (result.data.code === 200) {
        const result1 = result.data.data;
        return result1;
      } else {
        return false;
      }
    } else if (result && result.status === 203) {
      return false;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const Logout = async () => {
  const payload = {};
  try {
    const result = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGOUT}`,
      payload,
      "",
      ""
    );
    if (result && result.status === 200) {
      if (result.data.code === 200) {
        return true;
      } else {
        return false;
      }
    } else if (result && result.status === 203) {
      return false;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};


export const getCompany = async () => {
  const base_url = (process.env.REACT_APP_RUN_TIME === "local") ? (process.env.REACT_APP_FRONTEND_BASE_URL || "https://staging.humanlytics.com") : window.location.protocol + `//` + window.location.hostname;
  const payload = {
    url: base_url
  }
  const companyDetail = await PostRequest(
    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY}`,
    payload
  );
  if (companyDetail && companyDetail.status === 200) {
    const companyData = companyDetail.data.data;
    return (companyData)
  } else if (companyDetail && companyDetail.status === 203) {
    return false;
  }
}

export const getDynamicCompany = async (company_id) => {
  // const base_url = (process.env.REACT_APP_RUN_TIME === "local") ? (process.env.REACT_APP_FRONTEND_BASE_URL || "https://staging.humanlytics.com") : window.location.protocol + `//` + window.location.hostname;
  const payload = {
    company_dynamic_id: company_id
  }
  const companyDetail = await PostRequest(
    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_BY_DYNAMIC_ID}`,
    payload
  );
  if (companyDetail && companyDetail.status === 200) {
    const companyData = companyDetail.data.data;
    return (companyData)
  } else if (companyDetail && companyDetail.status === 203) {
    return false;
  }
}

export const getQuestionairs = async () => {
  const payload = {};
  try {
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      '',
      "refresh_token"
    );
    if (refreshToken) {
      const getQuestionnaireResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_QUESTIONNAIRE}`,
        payload,
        ''
      );

      if (getQuestionnaireResult && getQuestionnaireResult.status === 200) {
        if (getQuestionnaireResult.data.code === 200) {
          const questionairs = getQuestionnaireResult.data.data;
          const dateValue = new Date(questionairs.dob);
          let year = dateValue.getFullYear();
          const currentdateValue = new Date();
          let currentyear = currentdateValue.getFullYear();
          const ageValue = parseInt(currentyear - year);
          const jsondata = {
            age: ageValue,
            gender: questionairs.gender == 1 ? "male" : questionairs.gender == 2 ? "female" : "other",
            height: questionairs.height,
            weight: questionairs.weight,
            smoker: questionairs.smoker == 1 ? "yes" : "no",
            // hypertension: questionairs.hypertension == 1 ? "yes" : "no",
            medication: questionairs.bp_medicine == 1 ? "yes" : "no",
            diabetic: questionairs.diabetic == 0 ? "0" : questionairs.diabetic == 1 ? "type1" : "type2",
            userid: questionairs.user_id,
            work: questionairs.work,
            dob: questionairs.dob,
            placeOfWork: questionairs.placeOfWork,
            corporate_department: questionairs.corporate_department

          };
          const scanInfo = await getScanDetail();
          if (scanInfo) {
            handleSubmitData(jsondata);
          }
          else {

            window.location.href = "/app/dashboard";
          }
        } else {
          return false;
        }
      } else if (
        getQuestionnaireResult &&
        getQuestionnaireResult.status === 203
      ) {
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.log(error)
    return false;
  }

};

export const handleSubmitData = async (jsondata) => {

  const {
    age,
    diabetic,
    height,
    // hypertension,
    medication,
    gender,
    smoker,
    weight,
    work,
    userid,
    dob,
    placeOfWork,
    corporate_department
  } = jsondata;
  // // Get the token ID
  const { data: configResponseData } = await base_axios.get(
    `${process.env.REACT_APP_CONFIGID}`
  );

  // // Get a token from the back end
  const { data: tokenResponseData } = await base_axios.post(
    `${process.env.REACT_APP_TOKEN}`
  );
  const { Token, RefreshToken } = tokenResponseData;
  const dfxPayload = {
    dfxToken: tokenResponseData.data.Token
  }
  await PostRequest(
    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STORE_DFX_TOKEN_AND}`,
    dfxPayload,
    ''
  );

  const payload = {
    identifier: userid, // Unique identifier for the user, it will be used to tag the measurement
    age: parseInt(age),
    height: parseInt(height),
    weight: parseInt(weight),
    gender,
    smoking: smoker === "yes" ? 1 : 0,
    // hyper: hypertension === "yes" ? 1 : 0,
    bloodpressuremedication: medication === "yes" ? 1 : 0,
    diabetes: diabetic,

  };
  const buffer = Buffer.from(JSON.stringify(payload));
  const encryptedProfile = crypto.publicEncrypt(publicKey, buffer);
  const sessionId = await uuidv4(); // Unique identifier for the session, passed back as part of the result object to identify the measurement

  const content = {
    gender:
      gender == "male"
        ? 1
        : gender == "female"
          ? 2
          : gender == "other"
            ? 3
            : 0,
    dob: dob,
    height: parseInt(height),
    weight: parseInt(weight),
    smoker: smoker === "yes" ? 1 : 0,
    // hypertension: hypertension === "yes" ? 1 : 0,
    bp_medicine: medication === "yes" ? 1 : 0,
    diabetic: diabetic === "0" ? 0 : diabetic === "type1" ? 1 : 2,
    work: work,
    placeOfWork: placeOfWork,
    corporate_department: corporate_department
  };
  try {
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      '',
      "refresh_token"
    );
    if (refreshToken) {
      const updateResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_QUESTIONNAIRE}`,
        content,
        ''
      );
      if (updateResult && updateResult.status === 200) {
        if (updateResult.data.code === 200) {
          setTimeout(() => {
            window.location.href = `https://awe.na-east.nuralogix.ai/c/${configResponseData.data.config_id
              }/${encodeURIComponent(
                encryptedProfile.toString("base64")
              )}/${encodeURIComponent(
                tokenResponseData.data.Token
              )}/${encodeURIComponent(
                tokenResponseData.data.RefreshToken
              )}/${sessionId}`;
          }, 1000);
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const langTranslation = async (lang) => {
  const [t, i18n] = useTranslation('common');
  i18n.changeLanguage('en');
  return t;
}

// export const getLocalStoarge = (key_id) =>{
//   // const onlyNumArray = ['role_id'];
//   let values = "";
//   // if (onlyNumArray.includes(key_id)){
//   //   values = parseInt(window.localStorage.getItem(key_id));
//   // } else {
//   //   values = window.localStorage.getItem(key_id);
//   // }
//   window.localStorage.getItem(key_id);
//   values = (values)?values: false;
//   return values;
// }

// export const setLocalStoarge = (key_id, items) =>{
//   console.log(items);
//   // const onlyNumArray = ['role_id'];
//   // let itemEncrypted = "";
//   // if (onlyNumArray.includes(key_id)){
//   //   itemEncrypted = encryptFunction(items.toString());
//   // } else {
//   //   itemEncrypted = encryptFunction(items);
//   // }
//   // console.log(itemEncrypted);
//   // window.localStorage.setItem(key_id, itemEncrypted);
//   // const decryptNow = decryptFunction(itemEncrypted)
//   // window.localStorage.setItem(key_id,decryptNow);
//   window.localStorage.setItem(key_id,items);
//   // console.log(decryptNow)
//   return true;

// }

export const getLocalStoarge = (key_id) => {
  let values = window.localStorage.getItem(key_id);
  values = (values) ? values : false;
  return values;
}

export const setLocalStoarge = (key_id, items) => {
  window.localStorage.setItem(key_id, items);
  return true;
}

export const removeLocalStoarge = (exclude) => {  
  for (let i = 0; i < window.localStorage.length; i++) {
    let key = window.localStorage.key(i);

    if (exclude.indexOf(key) === -1) {
      window.localStorage.removeItem(key);
    }
  }
  return true;
}
export const removeLocalStoarge1 = () => {
  window.localStorage.clear();
  return true;
}
export const removeLocalStoargeKey = (key_id) => {
  window.localStorage.removeItem(key_id);
  return true;
}

export const encryptFunction = (data) => {
  const secret = process.env.REACT_APP_SECRET_KEY;
  const encryptedData = CryptoJS.AES.encrypt(data, secret).toString();
  return encryptedData;
}

export const decryptFunction = (data) => {
  const secret = process.env.REACT_APP_SECRET_KEY;
  const bytes = CryptoJS.AES.decrypt(data, secret);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

export const getCompanyDetails = async (id) => {
  const payload = {
    company_id: id
  };
  try {
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      "",
      "refresh_token"
    );
    if (refreshToken) {
      const result = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SA_GET_COMPANY_DETAILS}`,
        payload,
        '',
        ""
      );
      if (result && result.status === 200) {
        if (result.data.code === 200) {
          const result1 = result.data.data;
          return result1;
        } else {
          return false;
        }
      } else if (result && result.status === 203) {
        return false;
      } else {
        return false;
      }
    } else {
      return false
    }
  } catch (error) {
    return false;
  }
}

export const getScanDetail = async () => {
  let companyUniqueId = getLocalStoarge("dynamic_id");
  const getScanResult = await GetRequest(
    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_SCAN_LIMIT_CHECK}?company_dynamic_id=${companyUniqueId}`,

  );
  if (getScanResult.data.code === 200 && getScanResult.status === 200) {
    return true;
  } else if (getScanResult.data.code === 203 || getScanResult.status === 203) {
    return false;
  }
}


export const getUserScore = async (company_id, company_dynamic_id, componentId, repeatTime) => {
  try {
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      "",
      "refresh_token"
    );
    if (refreshToken) {
      const getUserScore = await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_BOTH_TRAINING_PERCENT}?component_id=${componentId}&repeat_time=${repeatTime}&company_id=${company_id}&company_dynamic_id=${company_dynamic_id}`,
      );

      if (getUserScore.data.code == 200) {
        const response = await getUserScore.data.data
        return response
      }
    }
    else {
      return false
    }

  } catch (error) {
    console.log(error)
    return false
  }
}

export const getCognitivePrePost = async (repeatTime) => {
  try {
    // const refreshToken = await PostRequest(
    //   `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
    //   {},
    //   "",
    //   "refresh_token"
    // );
    // if (refreshToken) {
    const getCognitivePrePostData = await GetRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_COGNITIVE_PRE_POST}?repeat_time=${repeatTime}`,
    );

    if (getCognitivePrePostData.data.code == 200) {
      const response = await getCognitivePrePostData?.data?.data?.ActivitiesData
      return response
    }
    // }
    // else {
    //   return false
    // }
  }
  catch (error) {
    console.log(error)
    return false
  }
}



export const getMindfulnessPrePost = async (repeatTime) => {
  try {
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      "",
      "refresh_token"
    );
    if (refreshToken) {
      const getMindfulnessPrePostData = await GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_MINDFULNESS_PRE_POST}?repeat_time=${repeatTime}`,
      );

      if (getMindfulnessPrePostData.data.code == 200) {
        const response = await getMindfulnessPrePostData?.data?.data?.ActivitiesData
        return response
      }
    }
    else {
      return false
    }
  } catch (error) {
    console.log(error)
    return false
  }
}



export const trainingData = async () => {
  try {
    const company_id = getLocalStoarge("company_id");
    const getAllCognitiveData = await GetRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ALL_DAYWISE_TRAINING}?repeat_time=2&component_id=1&company_id=${company_id}`,

    );
    if (getAllCognitiveData && getAllCognitiveData.status === 200) {

      let obj = {};
      let arr = [];
      let activityArr = [];
      obj['length'] = getAllCognitiveData.data.data.length;
      arr.push(getAllCognitiveData.data.data.user_all_activity_data);
      let count = 0;
      Object(getAllCognitiveData.data.data.user_all_activity_data).map((k, i) => {
        if (k.finalCompleteDate) {
          // count++ 
          console.log("yes")
        }

        else {
          // count;
          console.log("no")
          activityArr.push(k);
          return;
        }
      })
      return activityArr;
    } else if (getAllDataResult && getAllDataResult.status === 203) {
      return false
    } else {
      return false
    } 

  } catch (error) {
    return false
  }
}