import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import Modal from "../../common/modal";
import { useParams } from "react-router-dom";
import Group698 from "../../../assets/Image/Group698.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import DeleteBin from '../../../assets/Image/delete-bin.svg'
import crosseditprofile from '../../../assets/Image/cross-editprofile.svg'
import VectorDown from "../../../assets/Image/VectorDown.svg";
import '../super_admin_assets/scss/protocol.scss';
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg"
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg'
import USER_ICONA from '../../../assets/Image/USER_ICON23.svg';
import filterFactory from "react-bootstrap-table2-filter";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge } from "../../common/allfunction";
import moment from 'moment';
import Group769 from "../../../assets/Image/Group769.svg";
import Popup_Cross from '../../../assets/Image/Popup_Cross.svg'
import Popup_right from '../../../assets/Image/Popup_right.svg'
import Group1171 from "../../../assets/Image/Group1171.svg";
import PutRequest from "../../apiConnection/putRequest";
import CrossAdmin from "../../../assets/Image/cross-admin-icon.svg";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";

const Super_WhiteLabel = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation("common");
    const [companiesData, setCompaniesData] = useState([])
    const { SearchBar } = Search;
    const [changeCompanyStatus, setChangeCompanyStatus] = useState({})
    const [enableCompanyModal, setEnableCompanyModal] = useState(false)
    const [popupLoader1, setPopupLoader1] = useState(false)
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [popupLoader3, setPopupLoader3] = useState(false)
    const [userId, setUserId] = useState()
    const [disableCompanyModal, setDisableCompanyModal] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const [alerts, setAlerts] = useState({ alert: "", alerttype: "", alertclass: "danger", showalert: false, });
    const { alert, alerttype, alertclass, showalert } = alerts;
    const [disableAdminModal, setDisableAdminModal] = useState(false)
    const [enableAdminModal, setEnableAdminModal] = useState(false)
    const [changeUserStatusId, setChangeUserStatusId] = useState({})
    const [adminInputValues, adminInputValue] = useState({
        fullName: "",
        email: "",   
        password: "",
        confirmPassword: "",
    });
    const [validation, setValidation] = useState({
        fullName: "",
        email: "",  
        password: "",
        confirmPassword: "",
    });
    const [editWlaDetails, setWlaDetails] = useState({
        name: "",
        email: ""
    })
    const [editValidation, setEditValidation] = useState({
        fullName: "",
        email: ""
    });
    const [resetConfirmModal, setResetConfirmModal] = useState(false);
    const [changePassModal, setChangePassModal] = useState(false);
    const [resetInputValues, setResetInputValue] = useState({
        password: "",
        confirmPassword: ""
    });
    const adminValidation = () => {
        let errors = validation;
        let userExp = new RegExp(/^[a-zA-Z ]*$/);
        let Emailregex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/); 
        const Passregex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        );      
        const fullName = adminInputValues.fullName.trim();
        if (!fullName || fullName.length < 3 || fullName.length > 30) {
            errors.fullName = t('user.profile.valid_user_name');
        } else if (!fullName.match(userExp)) {
            errors.fullName = t('user.profile.valid_user_name');
        } else {
            errors.fullName = "";
        }
      
        const email = adminInputValues.email;
        if (!email) {
            errors.email = t('user.login.valid_email');
        } else if (!email.match(Emailregex)) {
            errors.email = t('user.login.valid_email');
        } else {
            errors.email = "";
        }

        const password = adminInputValues.password;
        if (!password) {
            errors.password = t('user.login.password_req');
        }
        else if (!password.match(Passregex)) {
            errors.password = t('admin.profile.valid_admin_password3')
        }
        else {
            errors.password = "";
        }
        const confirmPassword = adminInputValues.confirmPassword;
        if (!confirmPassword) {
            errors.confirmPassword = t('user.login.password_req');
        }
        else if (!confirmPassword.match(Passregex)) {
            errors.confirmPassword = t('admin.profile.valid_admin_password3')
        }
        else if (confirmPassword != password) {
            errors.confirmPassword = t('admin.profile.confirm_password_error')
        }
        else {
            errors.confirmPassword = "";
        }
              
        setValidation({ ...errors });
        let return_val = true;
        Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;
    }
    const handleChangePassword = (event) => {
        let { name, value } = event.target;
        setResetInputValue({ ...resetInputValues, [name]: value });
    }

    const resetPasswordValidation = () => {
        let errors = validation;

        const Passregex = new RegExp(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/
        );
        const password = resetInputValues.password;
        if (!password) {
            errors.password = t('user.login.password_req');
        }
        else if (!password.match(Passregex)) {
            errors.password = t('admin.profile.valid_admin_password3')
        }
        else {
            errors.password = "";
        }
        const confirmPassword = resetInputValues.confirmPassword;
        if (!confirmPassword) {
            errors.confirmPassword = t('user.login.password_req');
        }
        else if (!confirmPassword.match(Passregex)) {
            errors.confirmPassword = t('admin.profile.valid_admin_password3')
        }
        else if (confirmPassword != password) {
            errors.confirmPassword = t('admin.profile.confirm_password_error')
        }
        else {
            errors.confirmPassword = "";
        }
        setValidation({ ...errors });
        let return_val = true;
        Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;
    }
    const editWlaValidation = () => {
        let errors = editValidation;
        let userExp = new RegExp(/^[a-zA-Z ]*$/);
        let Emailregex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);    
        const fullName = editWlaDetails.name.trim();
        if (!fullName || fullName.length < 3 || fullName.length > 30) {
            errors.fullName = t('user.profile.valid_user_name');
        } else if (!fullName.match(userExp)) {
            errors.fullName = t('user.profile.valid_user_name');
        } else {
            errors.fullName = "";
        }
      
        const email = editWlaDetails.email;
        if (!email) {
            errors.email = t('user.login.valid_email');
        } else if (!email.match(Emailregex)) {
            errors.email = t('user.login.valid_email');
        } else {
            errors.email = "";
        }
             
        setEditValidation({ ...errors });
        let return_val = true;
        Object.entries(errors).forEach(([key, value]) => {
            if (value !== "") return_val = false;
        });
        return return_val;
    }

    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div className="d-flex justify-content-center">
                {/* <button className="table-action" onClick={() => window.location.href = `protocol/${row.company_id}`}><img src={ExpandIcon} alt="icon" /></button> */}
                <button className="table-action" onClick={() => {setUserId(row.user_id); getWlaDetails(row.user_id);}} title="Click for edit"><img src={Group878} alt="icon" /></button>
                {/* <button className="table-action" onClick={() => {}}><img src={DeleteBin} alt="icon" /></button> */}
                {/* <button className="table-action bg-green-delete" onClick={()=> {setDisableAdminModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 0, name: row.name})}}  title="Disable">
                        <img src={Group1171} alt="icon" /> */}
                {!row.status ? <button className="table-action bg-green-delete" onClick={() => { setEnableAdminModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 1, name: row.name }) }} title="Click for enable">
                <img src={CrossAdmin} alt="icon" />
                </button> :
                    <button className="table-action bg-green-delete" onClick={() => { setDisableAdminModal(true); setChangeUserStatusId({ user_id: row.user_id, status: 0, name: row.name }) }} title="click for disable">
                        <img src={Group1171} alt="icon" />
                </button>
                }

            {/* </button> */}
            </div>
        )
    }


    const getWlaDetails = async (userId) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getWlaData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VIEW_WLA_DETAILS}?userId=${userId}`,
                );
                if (getWlaData && getWlaData.status === 200) {
                    setIsLoading(false);
                    setWlaDetails(getWlaData.data.data);
                    setEditAdminModal(true);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const closeModal = () => {
        setChangeCompanyStatus({})
        setDisableCompanyModal(false)
        setAddAdminModal(false)
        setEditAdminModal(false)
        adminInputValue({
        fullName: "",
        email: "",   
        password: "",
        confirmPassword: "",
        })
        setValidation({
        fullName: "",
        email: "",   
        password: "",
        confirmPassword: "",
       })
       setDisableAdminModal(false)
       setEnableAdminModal(false)
       setPopupLoader2(false)
       setPopupLoader3(false)
       setChangeUserStatusId({})
       setUserId()
       setEditValidation({
        fullName: "",
        email: "",  
       })
       setWlaDetails({
        name: "",
        email: ""
       })
       setResetConfirmModal(false)
       setChangePassModal(false)
       setResetInputValue({})
       setDisableButton(false)
    }
    const [generalColumn, setGeneralColumn] = useState([]);
    const [addAdminModal, setAddAdminModal] = useState(false)
    const [editAdminModal, setEditAdminModal] = useState(false)

    const getCompaniesData = async () => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_VIEW_ALL_WLA_LIST}`,
                    {},
                    "",
                    ""
                );
                if (getAllDataResult && getAllDataResult.status === 200) {
                    setIsLoading(false);
                    if (getAllDataResult.data.data?.length > 0) {
                        let arr = []
                        getAllDataResult.data.data.forEach((data) => {
                            arr = [...arr, {
                                user_id: data.user_id,
                                name: data.name,
                                email: data.email,
                                scanCount: data.scanCount,
                                status: data.status

                            }]
                            setCompaniesData(arr)
                        })
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                text: t("superadmin.wla.name"),
                dataField: "name",
                sort: true,
            },
            {
                text: t("superadmin.wla.email"),
                dataField: "email",
                align: "left",
                sort: true,
                style: {
                    textAlign: "left"
                }
            },
            {
                text: t("superadmin.wla.scanCount"),
                dataField: "scanCount",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtons,
                sort: true,
                headerAlign: "center"
            },
        ])
    };
    useEffect(() => {
        setIsLoading(true)
        getCompaniesData()
        handleTranslationLang()
    }, []);

    const handleChange = (event) => {
        let { name, value } = event.target;
        adminInputValue({ ...adminInputValues, [name]: value });
        setAlerts("", "", "", false);
        setValidation({
            fullName: "",
            email: "",          
            password: "",
            confirmPassword: ""
        })
    }

    const handleChangeEdit = (event) => {
        let { name, value } = event.target;
        setWlaDetails({ ...editWlaDetails, [name]: value });
       
    }
    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#6d71f9';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'white';
        } else {
            // activeStyle.backgroundColor = 'gray';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = '';
            activeStyle.color = '#6d71f9';
        }
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-center pagination-list align-items-center" >
                {pages.map((p, i) => {
                    if (p.page === "<") return <div style={{margin:'15px', cursor:'pointer'}} key={i} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                    if (p.page === ">") return <div style={{margin:'15px', cursor:'pointer'}} key={i} onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                    if (p.active) return <div style={{margin:'15px', cursor:'pointer'}} key={i} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    return (
                        <div key={i} style={{margin:'15px', cursor:'pointer'}} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    )
                })}
            
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }
    const resetPassword = () => {
        setResetConfirmModal(true);
    }
    const submitAdminDetails = async () => {
        const validate = adminValidation();
        if (validate) {
            setPopupLoader2(true);
            setDisableButton(true);
            const payload =
            {
                name: adminInputValues.fullName,
                email: adminInputValues.email,
                password: adminInputValues.password,
                confirm_password: adminInputValues.confirmPassword            
            }
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    "",
                    "refresh_token"
                );
                if (refreshToken) {
                    const getDashboard_Data = await PostRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_WLA}`,
                        payload,
                        "",
                        ""
                    );
                    if (getDashboard_Data && getDashboard_Data.status === 200) {
                        setAlerts({
                            alert: t('admin.profile.wla_created'),
                            alerttype: "Success",
                            alertclass: "success",
                            showalert: true,
                        });
                        setTimeout(() => {
                            closeModal()
                            window.location.href = `/sa/white-label`
                        }, 2000)
                    } else {
                        // if(getDashboard_Data.data.code == 203)
                        // {
                            setTimeout(() => {
                                setAlerts("", "", "", false);
                                setDisableButton(false);  
                             }, 2000)
                        setAlerts({
                            alert: t('admin.profile.wla_exist'),
                            // alert: getDashboard_Data.data.data,
                            alerttype: "Error",
                            alertclass: "danger",
                            showalert: true,
                        });
                        setPopupLoader2(false)
                    //   }
                    }
                } else {
                    setAlerts({
                        alert: t('admin.profile.wla_not_created'),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                    setPopupLoader2(false)
                }
            } catch (error) {
                setAlerts({
                    alert: t('admin.profile.wla_not_created'),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
                setPopupLoader2(false)
            }
        }
        else {
            setAlerts("", "", "", false);
            setDisableButton(false);  
            setPopupLoader2(false)
        }
    }

    const editAdminDetails = async () => {
        const validate = editWlaValidation();
        if (validate) {
            setPopupLoader2(true);
            setDisableButton(true);
            const payload =
            {
                userId: userId,
                name: editWlaDetails.name,
                email: editWlaDetails.email          
            }
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    "",
                    "refresh_token"
                );
                if (refreshToken) {
                    const getDashboard_Data = await PutRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_WLA}`,
                        payload,
                        "",
                        ""
                    );
                    if (getDashboard_Data && getDashboard_Data.status === 200) {
                        setAlerts({
                            alert: t('admin.profile.admin_updated'),
                            alerttype: "Success",
                            alertclass: "success",
                            showalert: true,
                        });
                        setTimeout(() => {
                            closeModal()
                            window.location.reload();
                        }, 2000)

                    } else {
                        setTimeout(() => {
                            setAlerts("", "", "", false);
                            setDisableButton(false);  
                         }, 2000)
                    setAlerts({
                        alert: t('admin.profile.wla_exist'),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                    setPopupLoader2(false)
                      
                    }
                } else {
                    setAlerts({
                        alert: t('admin.profile.admin_not_update'),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                    setPopupLoader2(false)

                }
            } catch (error) {
                setAlerts({
                    alert: t('admin.profile.admin_not_update'),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
                setPopupLoader2(false)

            }
        }
        else {
            setAlerts("", "", "", false);
            setDisableButton(false);            
        }
    }


    const addAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={USER_ICONA} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.wla.new_wla")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={disableButton}
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <form className="registrati-form registrati-form-new nova-program" >
                            <div className="container">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label ">{t("superadmin.protocol.fullname")}</label>
                                            <input type="text" className="form-control" name="fullName" onChange={handleChange} defaultValue={adminInputValues.fullName}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.fullName && (
                                                <p className="danger">{validation.fullName}</p>
                                            )}
                                        </div>                        

                                        <div className="col-md-12 ">
                                            <label className="form-label mt-2">{t("superadmin.protocol.email")}</label>
                                            <input type="email" className="form-control" name="email" onChange={handleChange}
                                                defaultValue={adminInputValues.email}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {validation.email && (
                                                <p className="danger">{validation.email}</p>
                                            )}
                                        </div>                                         
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.password")}</label>
                                            <input type="text" className="form-control" name="password" onChange={handleChange}
                                                defaultValue={adminInputValues.password}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.confirm_password")}</label>
                                            <input type="text" className="form-control" name="confirmPassword" onChange={handleChange}
                                                defaultValue={adminInputValues.confirmPassword}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            {validation.password && (
                                                <p className="danger">{validation.password}</p>
                                            )}
                                        </div>
                                    
                                        <div className="col-md-6">
                                            {validation.confirmPassword && (
                                                <p className="danger">{validation.confirmPassword}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showalert ? 
                            <div className={`alert alert-${alertclass}`}>{alert}</div>
                            : 
                            (popupLoader2) ?
                              popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t('user.login.wait')} </div>
                            </div>
                            : null
                            }
                            <div className="d-flex justify-content-end buttons-superadmin p-2">
                                <button type="button" className="buttons modal-button mr-3" disabled={disableButton} onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                                <button type="button" disabled={disableButton} className="buttons modal-button" onClick={() => submitAdminDetails()}>{t("superadmin.protocol.popup.confirm")}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )

    }

    const submitResetPasswordApi = async () => {
        const validate = resetPasswordValidation();
        if (validate) {
            setIsLoading(true);
            setDisableButton(true);
            setPopupLoader2(true);

            const payload =
            {
                user_id: userId,
                password: resetInputValues.password,
                confirm_password: resetInputValues.confirmPassword,

            }
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    "",
                    "refresh_token"
                );
                if (refreshToken) {
                    const getDashboard_Data = await PutRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RESET_COMPANY_ADMIN_PASSWORD}`,
                        payload,
                        "",
                        ""
                    );
                    if (getDashboard_Data && getDashboard_Data.data.code === 200) {
                        setIsLoading(false);
                        setAlerts({
                            alert: t('admin.profile.admin_reset_password'),
                            alerttype: "Success",
                            alertclass: "success",
                            showalert: true,
                        });
                        setTimeout(() => {
                            setChangePassModal(false);
                            setAlerts("", "", "", false);
                            closeModal();
                        }, 3000)

                    } else {
                        setIsLoading(false);
                        setDisableButton(false)
                        setAlerts({
                            alert: t('admin.profile.admin_reset_password_error'),
                            alerttype: "Error",
                            alertclass: "danger",
                            showalert: true,
                        });
                    }
                } else {
                    setIsLoading(false);
                    setDisableButton(false)
                    setAlerts({
                        alert: t('admin.profile.admin_reset_password_error'),
                        alerttype: "Error",
                        alertclass: "danger",
                        showalert: true,
                    });
                }
            } catch (error) {
                setIsLoading(false);
                setDisableButton(false)
                setAlerts({
                    alert: t('admin.profile.admin_reset_password_error'),
                    alerttype: "Error",
                    alertclass: "danger",
                    showalert: true,
                });
            }
        }
        else {
            setIsLoading(false);
            setDisableButton(false)
            setAlerts("", "", "", false);
        }
    }

    const disableAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.popup.disable_admin_heading")}</strong>
                        </h5>
                    
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={disableButton}
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">

                            {popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1">{t('user.login.wait')}</div>
                            </div>}
                            <h6 className="visi-project"><strong className="strong-heading-disable">{t("superadmin.protocol.popup.disable_admin_content")} "{changeUserStatusId?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" disabled={disableButton} onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableButton} onClick={() => {changeCompanyUserStatus(); setPopupLoader2(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const enableAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_right} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.protocol.popup.enable_admin_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={disableButton}
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            {popupLoader3 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t('user.login.wait')} </div>
                            </div>}
                            <h6 className="visi-project"><strong>{t("superadmin.protocol.popup.enable_admin_content")} "{changeUserStatusId?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" disabled={disableButton} onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableButton} onClick={() => { changeCompanyUserStatus(); setPopupLoader3(true) }}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const changeCompanyUserStatus = async () => {
        const payload = changeUserStatusId
        setDisableButton(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDashboard_Data = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACTIVE_DEACTIVE_COMPANY_USER}`,
                    payload,
                    "",
                    ""
                );
                if (getDashboard_Data && getDashboard_Data.status === 200) {
                    setIsLoading(false);
                    closeModal()
                    getCompaniesData()
                } else {
                    setIsLoading(false);
                    setDisableButton(false);

                }
            } else {
                setIsLoading(false);
                setDisableButton(false);

            }
        } catch (error) {
            setIsLoading(false);
            setDisableButton(false);

        }
    }

    const editAdminModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={USER_ICONA} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.wla.edit_new")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <form className="registrati-form registrati-form-new nova-program" >
                            <div className="container">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="form-label ">{t("superadmin.protocol.fullname")}</label>
                                            <input type="text" className="form-control" name="name" onChange={handleChangeEdit} defaultValue={editWlaDetails.name}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {(editValidation.fullName) ? (
                                                <p className="danger">{editValidation.fullName}</p>
                                            )
                                        : null}
                                        </div>                        

                                        <div className="col-md-12 ">
                                            <label className="form-label mt-2">{t("superadmin.protocol.email")}</label>
                                            <input type="email" className="form-control" name="email" onChange={handleChangeEdit}
                                                defaultValue={editWlaDetails.email}
                                            ></input>
                                        </div>
                                        <div className="col-md-12 mt-1">
                                            {(editValidation.email) ? (
                                                <p className="danger">{editValidation.email}</p>
                                            ) : null}
                                        </div>                                         
                                        {/* <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.password")}</label>
                                            <input type="text" className="form-control" name="password" onChange={handleChange}
                                                defaultValue={adminInputValues.password}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.confirm_password")}</label>
                                            <input type="text" className="form-control" name="confirmPassword" onChange={handleChange}
                                                defaultValue={adminInputValues.confirmPassword}
                                            ></input>
                                        </div> */}
                                        {/* <div className="col-md-6">
                                            {validation.password && (
                                                <p className="danger">{validation.password}</p>
                                            )}
                                        </div>
                                    
                                        <div className="col-md-6">
                                            {validation.confirmPassword && (
                                                <p className="danger">{validation.confirmPassword}</p>
                                            )}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            {showalert ? 
                            <div className={`alert alert-${alertclass}`}>{alert}</div>
                            : 
                            (popupLoader2) ?
                              popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t('user.login.wait')} </div>
                            </div>
                            : null
                            }
                            <div className={`d-flex buttons-superadmin p-2 ${(isMobile) ? 'justify-content-center' : 'justify-content-end'}`}>
                                <button type="button" style={{ display : (isMobile) ? "none" : "block" }} disabled={disableButton} className="buttons modal-button mr-3 " onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                                <button type="button" className="buttons modal-button mr-3"  disabled={disableButton} onClick={() =>{setEditAdminModal(false); resetPassword()}}>{t("admin.profile.reset_password")}</button>
                                <button type="button" style={{ marginTop : (isMobileOnly && isMobile) ? "1px" : "0px" }} disabled={disableButton} className="buttons modal-button" onClick={() => editAdminDetails() }>{t("superadmin.protocol.popup.confirm")}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )

    }

    const resetConfirmationScreen = () => {
        return (
            <>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <strong>{t("admin.profile.reset_password")}</strong>
                        </h5>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <p>{t("admin.profile.reset_password_msg")}</p>
                        <div className="d-flex justify-content-end buttons-superadmin p-2">
                            <button className="buttons modal-button mr-3" onClick={() => setResetConfirmModal(false)}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button type="button" className="buttons modal-button" onClick={() => ChangePasswordenable()}>{t("admin.mailValidation.confirm_btn")}</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const ChangePasswordenable = () => {
        setResetConfirmModal(false);
        setChangePassModal(true);

    }
    const changePasswordScreen = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={USER_ICONA} alt="icon" />{" "}
                            </div>
                            <strong>{t("admin.profile.reset_password")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            disabled={disableButton}
                            onClick={() => setChangePassModal(false)}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <form className="registrati-form registrati-form-new nova-program" >
                            <div className="container">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.password")}</label>
                                            <input type="text" className="form-control" name="password" onChange={handleChangePassword}
                                                value={resetInputValues.password}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label mt-2">{t("superadmin.protocol.confirm_password")}</label>
                                            <input type="text" className="form-control" name="confirmPassword" onChange={handleChangePassword}
                                                defaultValue={resetInputValues.confirmPassword}
                                            ></input>
                                        </div>
                                        <div className="col-md-6">
                                            {validation.password && (
                                                <p className="danger">{validation.password}</p>
                                            )}
                                        </div>

                                        <div className="col-md-6">
                                            {validation.confirmPassword && (
                                                <p className="danger">{validation.confirmPassword}</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showalert ? 
                            <div className={`alert alert-${alertclass}`}>{alert}</div> 
                            : (popupLoader2) ?
                             popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t('user.login.wait')} </div>
                            </div>
                            :
                            null
    
                            }

                            <div className="d-flex justify-content-end buttons-superadmin p-2">
                                <button type="button" disabled={disableButton} className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                                <button type="button" disabled={disableButton} className="buttons modal-button" onClick={() => submitResetPasswordApi()}>{t("superadmin.protocol.popup.confirm")}</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )
    }

    return (

        <>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setAddAdminModal(false)} show={addAdminModal}>
                {addAdminModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableAdminModal(false)} show={disableAdminModal}>
                {disableAdminModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEnableAdminModal(false)} show={enableAdminModal}>
                {enableAdminModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEditAdminModal(false)} show={editAdminModal}>
                {editAdminModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setResetConfirmModal(false)} show={resetConfirmModal}>
                {resetConfirmationScreen()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setChangePassModal(false)} show={changePassModal}>
                {changePasswordScreen()}
            </Modal>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="white-label" />
                        <div className="col-md-10 pr-0 p0 ipd-righ">
                            <div className="right-dashboard topspace">
                                <div className="d-flex justify-content-between protocollo-space row">
                                    <div className="col-12 col-sm-8">
                                    <h2 className="heading-dash" >{t("superadmin.sidebar.white_label_admin")}</h2>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                    <button className="ricontrolla  nonbutton1" style={{float: (isMobile) ? "left" : "right"}} onClick={() => setAddAdminModal(true)}> {t("superadmin.wla.add_new")}</button>
                                    </div>
                                </div>
                                {(companiesData.length > 0 && companiesData) ? <>
                                    <ToolkitProvider
                                        keyField="user_id"
                                        key={'datatable'}
                                        data={companiesData}
                                        columns={generalColumn}
                                        search
                                    >{(props) => (

                                        <div id="test" className="table  ricsb ricsb-new protocolli-data">
                                            <div>
                                                <div>
                                                    <div className="searchbar d-flex justify-content-start m-2 position-relative">
                                                        <img
                                                            src={Group698}
                                                            className="search_icon"
                                                            alt="icon"
                                                        />
                                                        <SearchBar
                                                            placeholder={t("superadmin.protocol.search")}
                                                            {...props.searchProps}
                                                        />
                                                    </div>
                                                    <BootstrapTable
                                                        src={VectorDown} alt="icon"
                                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : redsource) : filteredData) : reportDatatutti}
                                                        data={companiesData}
                                                        {...props.baseProps}
                                                        filter={filterFactory()}
                                                        pagination={paginationFactory(paginationOption)}
                                                        wrapperClasses={'table-responsive'}

                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                    </ToolkitProvider>
                                </> : null}
                            </div>
                        </div>
                    </div>
                    {(isLoading) ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    );
}

export default Super_WhiteLabel;


