import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { useParams } from "react-router-dom";
import Group698 from "../../../assets/Image/Group698.svg";
import Group1432 from "../../../assets/Image/Group1432.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import DeleteBin from '../../../assets/Image/delete-bin.svg'
import crosseditprofile from '../../../assets/Image/cross-editprofile.svg'
import VectorDown from "../../../assets/Image/VectorDown.svg";
import '../super_admin_assets/scss/protocol.scss';
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg"
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg'
import filterFactory from "react-bootstrap-table2-filter";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge } from "../../common/allfunction";
import moment from 'moment';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Alert from 'react-bootstrap/Alert';
const programStatics = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation("common");
    const [company_id, setCompanyId] = useState(props.company_id)
    const [program_id, setProgramId] = useState(props.program_id)
    const [releaseDate, setReleaseDate] = useState(props.releaseDate)
    const [programData, setProgramData] = useState([])
    const [userData, setUserData] = useState([])
    const [getProgramStatus, setProgramStatus] = useState(false)
    const [programUserId, setProgramUserId] = useState(false)
    const { SearchBar } = Search;
    const value = 0.68;
    const [generalColumn, setGeneralColumn] = useState([{
        text: t("superadmin.protocol.company_name"),
        dataField: "userName",
        sort: true,
    },]);

    useEffect(() => {
        getStaticsDetails()
        handleTranslationLang()
    }, []);

    useEffect(() => {
       // getStatusProgram("87d258df-6883-4e2e-9110-f1eeb4add334","fd24689d-8320-44e2-94fd-a80565bc07e1","7a447385-1d29-4960-83e6-a13e2650c96c")
    }, [programUserId]);
    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#6d71f9';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'white';
        } else {
            // activeStyle.backgroundColor = 'gray';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = '';
            activeStyle.color = '#6d71f9';
        }
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-center pagination-list">
                {pages.map(p => {
                    if (p.page === "<") return <div key={p.page} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" className="RightNextICON" /></div>
                    if (p.page === ">") return <div key={p.page} onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" className="RightNextICON"  /></div>
                    if (p.active) return <div key={p.page} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    return (
                        <div key={p.page} style={{ margin: "10px", cursor: "pointer",marginTop: "10px",padding: "5px 15px" }} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    )
                })}
                {/* <li className="">
                    <a href="#" onClick={handleClick} style={activeStyle}>{page}</a>
                </li> */}
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }

    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                text: t("superadmin.training.name"),
                dataField: "userName",
                sort: true,
                
            },
            {
                text: t("superadmin.training.test_program.timeTaken"),
                dataField: "employedTime",
                sort: true
            },
            {
                text: t("superadmin.training.test_program.currentPath"),
                dataField: "currentPath",
                sort: true,
            },
            // {
            //     text: t("superadmin.training.test_program.test"),
            //     dataField: "test",
            //     sort: true,
            // },
            {
                text: t("superadmin.program.status"),
                dataField: "programStatus",
                sort: true,
                formatter: (cellContent, programStatus) => {
                    // if (programStatus.programStatus == 'initial') {
                    //     return (
                    //         <Alert variant="warning">{t("superadmin.program.static_status1")}</Alert>
                    //     );
                    // }
                    if (programStatus.programStatus == 'concluded' || programStatus.programStatus == 'conclude') {
                        return(
                            <Alert variant="success" className="text-alert-status">{t("superadmin.program.static_status2")}</Alert>
                        )
                    }
                    else{
                        return(
                            <Alert variant="warning" className="text-alert-status">{t("superadmin.program.static_status1")}</Alert>
                        )
                    }
                   
                    // return (
                    //     <Alert variant="danger">{t("superadmin.program.static_status3")}</Alert>
                    // );

                }
            },
        ])
    };

    const getStaticsDetails = async () => {
        setIsLoading(true)
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     "",
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getAllDataResult = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_PROGRAM_STATICS_DETAILS}?company=${company_id}&programId=${program_id}&releaseDate=${releaseDate}`
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                let arr = []
                setProgramData(getAllDataResult.data.data.ProgramData)                
                getAllDataResult.data?.data?.UserData.forEach((userDetail) => {
                    arr = [...arr, {
                        userName: userDetail.userName,
                        currentPath: userDetail.currentPath,
                        programStatus: userDetail.programStatus,
                        test: userDetail.test,
                        employedTime: userDetail.timeTakenInDay,
                        userId: userDetail?.id,

                    }]
                    console.log("details..", arr)
                    setUserData(arr);
                    setTimeout(()=>{
                        setIsLoading(false);
                    },2000)
                 })
            } else if (getAllDataResult && getAllDataResult.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        // } else {
        //     setIsLoading(false);
        // }

        } catch (error) {
            setIsLoading(false);
        }
    }


    const handleCourseChange = async (e) => {
        // setUserData([]);
        setIsLoading(true)
        const courseName = e.target.value
        if(courseName != ''){
            try {
                const refreshToken = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                    {},
                    "",
                    "refresh_token"
                );
                if (refreshToken) {
                    const courseData = await GetRequest(
                        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_PROGRAM_COURSE}?company=${company_id}&programId=${program_id}&courseName=${courseName}&releaseDate=${props.releaseDate}`
                    );
                    if (courseData && courseData.status === 200) {
                        let arr = [];
                        setProgramData(courseData?.data?.data.ProgramData);             
                        const filterCourseData = courseData?.data?.data.UserData;                        
                        filterCourseData.forEach((userDetail) => {
                            arr = [...arr, {
                                userName: userDetail.userName,
                                currentPath: userDetail.currentPath,
                                programStatus: userDetail.programStatus,
                                test: userDetail.test,
                                employedTime: userDetail.timeTakenInDay,
                                userId: userDetail?.id,
                                // courseDataInfo : userDetail.ProgramData
                            }]
                            setUserData(arr);
                            console.log("change details", arr);
                            setTimeout(()=>{
                                setIsLoading(false);
                            },2000)
                        })
                    }
                    else if(getAllDataResult && getAllDataResult.status === 203){
                        setIsLoading(false);
                    }
                }
                else{
                    setIsLoading(false);
                }
            } catch (error) {
                // console.log("error", error)
                setIsLoading(false);
            }
        }
        else{
            getStaticsDetails()
        }
        
    }

    // get status of program
    // const getStatusProgram = async (company_id,program_id,userId) => {
    //     setIsLoading(true)
    //     setProgramUserId(userId);
    //     try {
           
    //         const getProgramRespone = await GetRequest(
    //             `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_STATUS_PROGRAM}?companyId=${company_id}&programId=${program_id}&userId=${userId}`
    //         );
    //         if (getProgramRespone && getProgramRespone.status === 200) {
    //             setIsLoading(false);
    //             let arr = []
    //             if(getProgramRespone.data.data.length>0)
    //             {
    //                 let record = getProgramRespone.data.data.programResult;
    //                 console.log("getProgrrecord",record);
    //                  setProgramStatus(record);
    //                  console.log("getProgramRespone",record+"---"+userId);

    //             }

    
    //         } else if (getProgramRespone && getProgramRespone.status === 203) {
    //             setIsLoading(false);
    //         } else {
    //             setIsLoading(false);
    //         }

    //     } catch (error) {
    //         console.log("error", error)
    //         setIsLoading(false);
    //     }
    // }



    return (
        <>
            {(Object.keys(programData).length != 0) ? <>
                {console.log("programData", programData)}
                <div className="w-100">
                    <div className="d-flex mt-3">
                        <div className="arrow_down1 select-iniza mr-3 text-left">
                        <select name="course" className="border_n right-super-select-arrow totali" onChange={(e) => handleCourseChange(e)}>
                                <option value=''>{t('superadmin.training.test_program.all_courses')}</option>
                                {programData[0]?.allCourse?.map((item, index) => {
                                    return (
                                        <option key={index} value={item.courseName}>{item.courseLabel}</option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>
                </div>

                <div className="row mt-10 w-100">
                    {
                        programData.map((data, i) => {
                            return (
                                <div className="col-md-4" key={i}>
                                    <div className="durate-box">
                                        <h2>{t('superadmin.training.test_program.avgDuration')}</h2>
                                        <div className="durate-mediabox">
                                            <h2>{data.avgWeek}</h2>
                                            <p>{t('superadmin.protocol.weeks')}</p>
                                        </div>
                                    </div>
                                    <div className="durate-box">
                                        <h2>{t('superadmin.training.test_program.completion')}</h2>
                                        <div className="durate-mediabox">
                                            {/* <h2>{data.totalPerson}</h2> */}
                                            <p className="total-count-person"><strong>{data.totalPerson} {t('superadmin.training.popup.person')}</strong></p>
                                            <p className="centeralign-Progressbar" style={{ width: 191, height: 191, }}>
                                                
                                                <CircularProgressbar value={data.concludedProgram/data.totalPerson} maxValue={1} strokeWidth={12} text={`${((data.concludedProgram/data.totalPerson)*100).toFixed(0)}%`}
                                             
                                                /></p>

                                            <div className="program-status-radio">
                                                <label className="radio-white active-purpule-radio concluso-radio">
                                                    <input type="radio" className="mr-2" name="status" value="2" />
                                                    <span>{t('superadmin.training.test_program.done')}</span>
                                                </label>
                                            </div>

                                            <div className="program-status-radio">
                                                <label className="radio-white inactive-purpule-radio concluso-radio">
                                                    <input type="radio" className="mr-2" name="progress" value="3" />
                                                    <span>{t('superadmin.training.test_program.inProgress')}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="durate-box">
                                        <h2>{t('superadmin.training.test_program.correctAnswer')}</h2>
                                        <div className="durate-mediabox durate-paddleft-to-right d-flex align-items-center">
                                            <div className="superato-right-space">
                                                <h2>{data.passPercentage}%</h2>
                                                <p>{data.personPass} {t('superadmin.training.test_program.testPass')} / {data.personFail} {t('superadmin.training.test_program.testNoPass')}</p>
                                            </div>
                                            {/* <div>
                                                <button className="buttons modal-button height ripeti mr-0">
                                                    <img src={Group1432} alt="icon" /> <span className="total-superto">+30%</span>
                                                </button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="col-md-8">
                        <div className="durate-box">
                            <h2>{t('superadmin.program.persone')}</h2>
                            <div className="durate-mediabox durate-paddleft-to-right">
                                {(userData) ? <>
                                    <ToolkitProvider
                                        keyField="userId"
                                        data={userData}
                                        columns={generalColumn}
                                        style={{wordWrap :"break-word"}}
                                    // search
                                    >{(props) => (

                                        <div id="test" className="table  ricsb ricsb-new protocolli-data check1">
                                            <div>
                                                <div>
                                                    <BootstrapTable
                                                        src={VectorDown} alt="icon"
                                                        data={userData}
                                                        {...props.baseProps}
                                                        filter={filterFactory()}
                                                        pagination={paginationFactory(paginationOption)}
                                                        wrapperClasses={'table-responsive'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </ToolkitProvider>
                                </> : null}
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading ? <LazyLoader /> : null}
            </>
                :
                <div className="card p-3">
                    <div className="bg-light-purple card-header collapsed" data-toggle="collapse" aria-expanded="true"><a className="card-title">{t('superadmin.program.no_static_data')}</a></div>
                </div>
            }
        </>
    )
}

export default programStatics;


