import { Route } from "react-router-dom";

const AdminUnProtectedRoute = ({ component: Component, ...restOfProps }) => {
  const isAuthenticated = JSON.parse( localStorage.getItem("R2"));
  const isAuthenticatedRoleId = JSON.parse( localStorage.getItem("roleId"));

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        (isAuthenticated?.access_token && isAuthenticatedRoleId && isAuthenticatedRoleId == 2) ? window.location.href='/admin/pages/dashboard' : <Component {...props} /> 
      }
    />
  );
}

export default AdminUnProtectedRoute;