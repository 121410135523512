import React, { memo, useEffect } from "react";
import { useState } from "react";
import ONBOARDINGICONA from "../../assets/Image/ONBOARDINGICONA.svg";
import RIFERIMENTIICONA from "../../assets/Image/RIFERIMENTIICONA.svg";
import logo from "../../assets/Image/logo.svg";
import riepilogo from "../../assets/Image/riepilogo.svg";
import { Link } from "react-router-dom";

import Group756 from "../../assets/Image/Group756.svg";
import Group824 from "../../assets/Image/Group824.svg";
import Group758 from "../../assets/Image/Group758.svg";
import ICONA_LIGHT1 from "../../assets/Image/ICONA_LIGHT1.svg";
import DARK_ICONA2 from "../../assets/Image/DARK_ICONA2.svg";
import Group1399 from '../../assets/Image/dashboardIcon.svg'
import Group842 from "../../assets/Image/Group842.svg";
import logoipad from "../../assets/Image/logo-ipad.svg";
import logout from "../../assets/Image/logout.svg";
import terms from "../../assets/Image/T&C_ICONA.svg";

import formazione from '../../assets/Image/FORMAZIONE_ICONA.svg'

import TRAINING from '../../assets/Image/TRAINING COGNITIVO_ICONA.svg'
import MEDITAZIONE from '../../assets/Image/MEDITAZIONE_ICONA.svg'
import Istruzioni from '../../assets/Image/INFO_icon.svg'
import Ist_sub_1 from '../../assets/Image/FACE_ICON.svg'
import Ist_sub_3 from '../../assets/Image/Group-965.svg'
import userManualImg from '../../assets/Image/user-manual-sidebar.svg';
import { isMobile, isTablet } from "react-device-detect";
import InstructionModal from "./instructionModal";
import {
  setLocalStoarge,
  removeLocalStoarge,
  removeLocalStoarge1,
  getLocalStoarge,
  Logout,
} from "./allfunction";
import { useTranslation } from "react-i18next";
import PostRequest from "../apiConnection/postRequest";
import GetRequest from "../apiConnection/getRequest";
import moment from "moment";
import { fetchRepetition } from "../../Store/TrainingRepetition/RepeatSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import LazyLoader from "./lazyLoader";
import Riepilogo1 from '../../assets/Image/Riepilogo1.svg'
import REPORT_PARAMETRI_ICONA1 from '../../assets/Image/REPORT_PARAMETRI_ICONA1.svg'
function Sidebar(props) {
  const [theme, setTheme] = useState("light");
  const [activebg, setActivebg] = useState("active");
  const [toggleStatus, setToggleStatus] = useState(true);
  const [userProfile, setuserProfile] = useState("");
  const [showpic, setSHowPic] = useState("default.png");
  const [t, i18n] = useTranslation("common");
  const [modalIns1, setModalIns1] = useState(0);
  const [cognitiveRepeatation, setCognitiveRepeatation] = useState(1);
  const [cognitiveDate, setCognitiveDate] = useState([]);
  const [mindfulnessRepeatation, setMindfulnessRepeatation] = useState(1);
  const [mindfulnessDate, setMindFulnessDate] = useState([])
  const [currentDate, setCurrentDate] = useState(moment().unix());
  const [trainingData, setTrainigData] = useState([]);
  const [modalIns2, setModalIns2] = useState(false);
  const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
  const [dynamicId, setDynamicId] = useState(getLocalStoarge('dynamic_id'));
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { data, status, isLoadingCheck } = useSelector((state) => {
    return state.repetition
  })



  const [token, setToken] = useState(() => {
    const saved = localStorage.getItem("R1");
    const initialValue = JSON.parse(saved);
    return initialValue.access_token || "";
  });
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };
  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
    setToggleStatus(theme === "light" ? true : false);
    setLocalStoarge(
      "theme",
      JSON.stringify(theme === "light" ? "dark" : "light")
    );
    if(props.themeCall){
      props.themeCall();
    }
  };

  const handleModal = () => {
    setModalIns1(0);
  }
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);
  const removeToken = () => {
    const logout = Logout();
    if (logout) {
      window.location.href = `/app/login/${localStorage.getItem('dynamic_id')}`;
      // removeLocalStoarge(['summaryPageGuide','pageGuide']);
      removeLocalStoarge1();


    } else {
      console.log("something was wrong");
    }
  };

  useEffect(() => {
    let theme1 = getLocalStoarge("theme");
    theme1 = theme1 ? JSON.parse(theme1) : "";
    setTheme(theme1);
    if (theme1) {
      setToggleStatus(theme1 === "light" ? false : true);
    } else {
      setTheme("light");
      setToggleStatus(false);
    }
    let activePage = '';
    path.split('/').forEach((list) => {
      if (list == '' || pageName == list || list == 'app') return
      activePage = list;
    });
    setActivebg(activePage ? activePage : pageName);
    let profiledata = getLocalStoarge("profile");
    profiledata = JSON.parse(profiledata);
    setSHowPic(
      profiledata?.profile_pic ? profiledata.profile_pic : "default.png"
    );
    setuserProfile(profiledata?.user_name ? profiledata.user_name : "NO NAME");
  }, []);
  useEffect(() => {
    handleTranslationLang();
  }, []);

  const path = window.location.pathname;
  const pageName = path.substring(path.lastIndexOf("/") + 1);
  useEffect(() => {
    if (!data) {
      setTimeout(() => {
        dispatch(fetchRepetition(companyId, dynamicId));
      }, 2000);
    }
  }, [])

  useEffect(() => {
    if (data) {
      setTrainigData(data);
      setLocalStoarge('trainingModule',data.trainingModule);
      setLocalStoarge('brainwellnessModule',data.brainwellnessModule);
      setCognitiveRepeatation(data?.repeat_allowed_cognitive);
      setCognitiveDate(data?.repeat_start_dates_cognitive);
      setMindfulnessRepeatation(data?.repeat_allowed_mindfulness);
      setMindFulnessDate(data?.repeat_start_dates_mindfulness);
      setIsLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (props.type == 'cognitive') {
      setCognitiveRepeatation(props.repeatTime)
    }
    else if (props.type == 'mindfulness') {
      setMindfulnessRepeatation(props.repeatTime)
    }
  }, [cognitiveRepeatation, mindfulnessRepeatation]);

  const userGuide = () => {
    localStorage.setItem('summaryPageGuide', 0)
    localStorage.setItem('pageGuide', 0)
    window.location.href="./dashboard";
  }


  useEffect(() => {
    setIsLoading(isLoadingCheck)
  }, [isLoadingCheck])
  const [defaultImage, setDefaultImage] = useState(process.env.REACT_APP_BACKEND_BASE_URL +
    process.env.REACT_APP_STATIC_PATH + "default.png")
  const handleImageError = (event) => {
    event.target.onerror = null; 
    event.target.src = defaultImage; // Replace with fallback image URL
  };
  return (
    <>
      {(modalIns1) ?
        <InstructionModal popUpEnable={modalIns1} handleModal={handleModal} />
        : null
      }
      <div className="col-md-2 p-0 darkimenu none-viewport ipad-widhset">
        <div className="left-dashboard text">
          <div className="w-100">
            <Link to="/dashboard">
              {/* <img
              src={logo}
              className="img-fluid logo_common ipd-logo-n"
              alt="logo"
            />
            <img
              src={logoipad}
              className="img-fluid logo_common vison-tab"
              alt="logo"
            /> */}
              <div className="logo-sidebar ipd-logo-n"></div>
              <div className="logo-sidebar ipad-logo-show vison-tab"></div>
            </Link>
            <div className="profile profile-new">
              <div className="dropdown profile-drop" id="step-1">
                <button
                  className="dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="prof-pic text">
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_BASE_URL +
                        process.env.REACT_APP_STATIC_PATH +
                        showpic
                      }
                      className="img-fluid"
                      alt="img"
                      onError={handleImageError}

                    ></img>
                  </div>
                  <span className="name_left ">
                    <span className="ipad-textn">{userProfile} </span>
                  </span>
                </button>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <Link to="/steps" className="dropdown-item">
                    {" "}
                    <img src={ONBOARDINGICONA} alt="icon" />
                    {t("user.sidebar.steps")}
                  </Link>
                  <Link to="/privacy" className="dropdown-item">
                    {" "}
                    <img src={Group758} alt="icon" />
                    {t("user.sidebar.privacy")}
                  </Link>

                  {/* <Link to="/referements" className="dropdown-item"> */}
                  <Link to="/referement-science" className="dropdown-item">
                    <img src={RIFERIMENTIICONA} alt="icon" />
                    {t("user.sidebar.referments")}
                  </Link>

                  <Link to="/faq" className="dropdown-item">
                    {" "}
                    <img src={Group824} alt="icon" />
                    {t("user.sidebar.FAQ")}
                  </Link>
                  <Link to="/profile" className="dropdown-item">
                    <img src={Group756} alt="icon" />
                    {t("user.sidebar.profile")}
                  </Link>



                  {/* <Link to="/training" className="dropdown-item">
                    <img src={formazione} alt="icon" />
                    {t("user.sidebar.training")}
                  </Link> */}
                  <Link to="/terms" className="dropdown-item">
                    <img src={terms} alt="icon" />
                    {t("user.sidebar.t&c")}
                  </Link>
                  {/* <Link to="/brainwellness-science" className="dropdown-item">
                    <img src={Group756} alt="icon" />
                    {t("user.sidebar.brain_wellness_science")}
                  </Link> */}

                  <a className="dropdown-item" onClick={removeToken}>
                    <img src={logout} alt="icon" />
                    <span>{t("user.sidebar.logout")}</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="navigate-new navigate manageside-bar">
              {/* <ul> */}
              {/* <li className={((activebg == "dashboard") || (activebg == "/")) ? "active" : ""}> */}
              {/* <Link to="/dashboard"> */}
              {/* <img src={Group1399} alt="icon" /> */}
              {/* <div className="dash-icon"></div> */}
              {/* <span className={"ipad-textn p-lefts"}> */}
              {/* {t("user.sidebar.dashboard")} */}
              {/* </span> */}
              {/* </Link> */}
              {/* </li> */}
              {/* <li className={activebg == "summary" ? "active" : ""}> */}
              {/* <Link to="/summary"> */}
              {/* <img src={riepilogo} alt="icon" /> */}
              {/* <div className="dash-icon summery-icon"></div> */}
              {/* <span className="ipad-textn p-lefts"> */}
              {/* {t("user.sidebar.summary")} */}
              {/* </span> */}
              {/* </Link> */}
              {/* </li> */}
              {/* </ul> */}


              <ul>
                <div className="profile-line">
                  {(isMobile || isTablet) ? <span className="li-heading ml-0">{t("user.sidebar.dashboard")}</span> : null}
                  <li className={((activebg == "common-dashboard") || (activebg == "/")) ? "active" : ""} id="step-2">
                    <Link to="/common-dashboard">
                      <img src={Group1399} alt="icon" className="new-sidebar-icon" />
                      {/* <div className="dash-icon"></div> */}
                      <span className={"ipad-textn p-lefts li-span-new"}>
                        {t("user.sidebar.dashboard")}
                      </span>
                    </Link>
                  </li>
                </div>
                <div className="profile-line">
                  <span className="li-heading ml-0">{t("user.sidebar.wellbeing_report")}</span>
                  {/* <li className={((activebg == "parameter-reports") || (activebg == "/")) ? "active" : ""}>
                  <Link to="/parameter-reports">
                    <img src={REPORT} alt="icon" className="new-sidebar-icon" />
                    <span className={"ipad-textn p-lefts li-span-new"}>
                      {t("user.sidebar.parameter_reports")}
                    </span>
                  </Link>
                </li> */}
                    <li className={((activebg == "dashboard") || (activebg == "/dashboard")) ? "active" : ""} id="step-3">
                    <Link to="/dashboard">
                      <img src={REPORT_PARAMETRI_ICONA1} alt="icon" className="new-sidebar-icon" />
                      <span className="ipad-textn p-lefts li-span-new">
                      {t("user.dashboard.tab_dashboard_first")}
                      </span>
                    </Link>
                  </li>
                  <li className={((activebg == "summary") || (activebg == "/summary")) ? "active" : ""} id="step-3">
                    <Link to="/summary">
                      <img src={Riepilogo1} alt="icon" className="new-sidebar-icon" />
                      {/* <div className="dash-icon summery-icon"></div> */}
                      <span className="ipad-textn p-lefts li-span-new">
                        {t("user.sidebar.summary")}
                      </span>
                    </Link>


                  </li>
                  {/* {(isMobile) ? <span className="li-heading">{t("user.sidebar.dashboard")}</span> : null} */}
                  {/* <li className={((activebg == "dashboard") || (activebg == "/")) ? "active" : ""}> */}
                  {/* <Link to="/dashboard"> */}
                  {/* <img src={Group1399} alt="icon" className="new-sidebar-icon" /> */}
                  {/* <div className="dash-icon"></div> */}
                  {/* <span className={"ipad-textn p-lefts li-span-new"}> */}
                  {/* {t("user.sidebar.dashboard")} */}
                  {/* </span> */}
                  {/* </Link> */}
                  {/* </li> */}
                </div>

                {/* <div className="profile-line"> */}
                {/* <span className="li-heading">{t("user.sidebar.wellbeingReports")}</span> */}
                {/* <li className={activebg == "summary" ? "active" : ""}> */}
                {/* <Link to="/summary"> */}
                {/* <img src={riepilogo} alt="icon" className="new-sidebar-icon" /> */}
                {/* <div className="dash-icon summery-icon"></div> */}
                {/* </ul><span className="ipad-textn p-lefts li-span-new"> */}
                {/* {t("user.sidebar.summary")} */}
                {/* </span> */}
                {/* </Link> */}
                {/* </li> */}
                {/* </div> */}
                {trainingData && trainingData.trainingModule === 1 ?
                <div className="profile-line" >
                  <span className="li-heading ml-0">{t("user.sidebar.wellbeingLiteracy")}</span>
                  <li className={activebg == "training" ? "active" : ""} id="step-4">
                    <Link to="/training">
                      <img src={formazione} className="new-sidebar-icon" alt="icon" />
                      <span className="ipad-textn p-lefts li-span-new">
                        {t("user.sidebar.training")}
                      </span>
                    </Link>
                  </li>
                </div >
                : null
                }
                {trainingData && trainingData.brainwellnessModule === 1 ? <>

                  <div className={`profile-line`}>
                    <span className="li-heading mb-2">{t("user.sidebar.brain_wellness_heading")}</span>
                    <div className={((activebg == 'congnitive-training') || (activebg == 'congnitive-training/')) ? 'active dropdown profile-drop mb-2' : 'dropdown profile-drop mb-2'}>
                      <button
                        className="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="ml-2">
                          <img
                            src={TRAINING}
                            className="new-sidebar-icon"
                            alt="img"
                          ></img>
                        </div>
                        <span className="mr-2">
                          <span className="sidebar-brainwellness ipad-textn p-lefts li-span-new">{t('user.sidebar.cognitive_training')}</span>
                        </span>
                      </button>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {cognitiveDate && cognitiveDate.length > 0 && cognitiveDate.map((date, index) => (
                          <Link to={`/congnitive-training/${index + 1}`} className="dropdown-item" key={index}>
                            {t('user.sidebar.cognitive_training')}-{index + 1}
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className={((activebg == 'mindfulness') || (activebg == 'mindfulness/')) ? 'active dropdown profile-drop mb-2' : 'dropdown profile-drop mb-2'}>
                      <button
                        className="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="ml-2">
                          <img
                            src={MEDITAZIONE}
                            className="new-sidebar-icon"
                            alt="img"
                          ></img>
                        </div>
                        <span className="mr-3">
                          <span className="sidebar-brainwellness ipad-textn p-lefts li-span-new">{t('superadmin.sidebar.mindfulness')}</span>
                        </span>
                      </button>

                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {mindfulnessDate && mindfulnessDate.length > 0 && mindfulnessDate.map((date, index) => (
                          <Link to={`/mindfulness/${index + 1}`} className="dropdown-item" key={index}>
                            {t('superadmin.sidebar.mindfulness')}-{index + 1}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </> : ''
                } 

                {/* { <div>
              <span className="li-heading">BRAIN WELLNESS</span>
              <li className={activebg == "congnitive-training" ? "active" : ""}>
                <Link to="/cognitive-training">
                  <img src={TRAINING} alt="icon" className="new-sidebar-icon" />
                  <span className="ipad-textn p-lefts li-span-new">
                    {t("user.sidebar.cognitive_training")}
                  </span>
                </Link>
              </li>
              <li className={activebg == "mindfulness" ? "active" : ""}>
                <Link to="/mindfulness">
                  <img src={MEDITAZIONE} alt="icon" className="new-sidebar-icon" />
                  <span className="ipad-textn p-lefts li-span-new">
                    {t("user.sidebar.mindfulness")}
                  </span>
                </Link>
              </li>
              </div> } */}





                <div className="profile-line">
                  <div className="dropdown profile-drop profile-drop-bottom" id="step-5">
                    <li>
                      <button className="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ color: '#000' }}

                      >
                        <img src={Istruzioni} className="new-sidebar-icon" alt="icon" />
                        <span className="ipad-textn p-lefts li-span-new instruction-color">
                          {t("user.sidebar.instructions")}

                        </span>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <Link to="#" className="dropdown-item" onClick={() => setModalIns1(1)}>
                          {" "}
                          <img src={Ist_sub_1} alt="icon" />
                          {t("user.sidebar.measurement")}
                        </Link>
                        {/* <Link to="#" className="dropdown-item" >
                          {" "}
                          <img src={Ist_sub_2} alt="icon" />
                          {t("user.sidebar.platform-usage")}
                        </Link>                        */}
                        <Link to="#" className="dropdown-item" onClick={() => setModalIns1(3)}>
                          {" "}
                          <img src={Ist_sub_3} alt="icon" />
                          {t("user.sidebar.legend")}
                        </Link>
                        <Link to="#" className="dropdown-item" onClick={() => userGuide()}>
                          {" "}
                          <img src={Istruzioni} alt="icon" />
                          {t("user.sidebar.tour")}
                        </Link>
                        <Link to="/user-manual" className="dropdown-item">
                          {" "}
                          <img src={userManualImg} alt="icon" />
                          {t("user.sidebar.manual")}
                        </Link>
                      </div>
                    </li>
                  </div>

                </div >
                <div className="contact-heading" id="step-6">
                  <a href="mailto:evolvity.support@neocogita.com?subject=EVOLVITY Service Ticket/ ticket assistenza EVOLVITY.">{t("user.sidebar.contactEmail")}</a>
                </div>
              </ul >
            </div >
          </div >



          <div className="ali-bottom">
            <div>
              {/* <a className="buttons width-w width-w-new h-auto my-0 legenda-text">
                <img className="sub-align" src={INFOICON} alt="icon" style={{ color: "white" }} />
                <span> Legenda </span>
              </a> */}
              <div className="fitdark-text">
                {/* <a className="buttons width-w width-w-new h-auto my-0 legenda-text">
         <img className="sub-align" src={INFOICON} alt="icon" style={{color:"white"}}/> 
           <span> Legenda </span>
         </a> */}
                <div className="bot-set n-mobi">
                  {toggleStatus == true && (
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      checked
                      onChange={toggleTheme}
                    />
                  )}
                  {toggleStatus == false && (
                    <input
                      type="checkbox"
                      className="checkbox"
                      id="checkbox"
                      onChange={toggleTheme}
                    />
                  )}

                  <label htmlFor="checkbox" className="label" id="step-7">
                    <div className="icco">
                      <img src={ICONA_LIGHT1} className="img-fluid" alt="logo" />
                    </div>
                    <div className="moon">
                      <img src={DARK_ICONA2} className="img-fluid" alt="logo" />
                    </div>

                    <div className="anim-dari">
                      <div className="ball"></div>
                      <div className="light"></div>
                      <div className="dark"></div>
                    </div>
                  </label >
                </div >
                {/* <span>{t("user.sidebar.contactEmail")}</span> */}
              </div>

            </div >
          </div >
        </div >
      </div >
      {(isLoading) ? <LazyLoader /> : null}
    </>
  );
}
export default memo(Sidebar);
