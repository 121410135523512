import React, { useEffect, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { useHistory } from 'react-router-dom';
import Sidebar from "../../common/sidebar";
import Sidebarmob from "../../common/sidebarMobile";
import PostRequest from "../../apiConnection/postRequest";
import PutRequest from "../../apiConnection/putRequest";
import LazyLoader from "../../common/lazyLoader";
import '../../superAdmin/super_admin_assets/scss/protocol.scss';
import GetRequest from "../../apiConnection/getRequest";
import DOCUMENTO_ICONA from '../../../assets/Image/DOCUMENTO_ICONA.svg';
import PREV_PAGE_ICON from '../../../assets/Image/PREVPAGE_ICON.svg';
import RIGHT from '../../../assets/Image/Popup_right.svg';
import { useTranslation } from "react-i18next";
import { getLocalStoarge, getDynamicCompany } from "../../common/allfunction";
import Modal from "../../common/modal";
import Group769 from "../../../assets/Image/Group769.svg";
import '../scss/userStyle.scss';
import Right_Ans from '../../../assets/Image/right_ans.svg';
import Wrong_Ans from '../../../assets/Image/wrong_ans.svg';
import VIDEO_ICONAvideo from "../../../assets/Image/VIDEO_ICONAvideo.svg"
import rightIcon from "../../../assets/Image/CHECK_ICONA_24.svg"

import ShowVideo from "../../common/ShowVideo";
import { ViewDocument } from "../../common/ViewDocument";
import ReactPlayer from 'react-player';

const UserProgramDetails = (props) => {
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation("common");
    const [program_id, setProgramId] = useState(props.match.params.programId)
    const [companyDetails, setCompanyDetails] = useState({})
    const [programData, setProgramData] = useState({});
    const [courseData, setCourseData] = useState([]);
    const [courseContent, setCourseContent] = useState({})
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [testModal, setTestModal] = useState(false)
    const [currentTestQuestions, setCurrentTestQuestions] = useState([])
    const [resultModalPass, setResultModalPass] = useState(false, { percent: "" })
    const [resultModalFail, setResultModalFail] = useState(false)
    const [resultModalFailSecond, setResultModalFailSecond] = useState(false)
    const [resultTestData, setResultTestData] = useState({ score: "" })
    const [testSubmitedData, setTestSubmitedData] = useState({ submittedId: "" })
    const [activeContent, setActiveContent] = useState('')
    const [testPrevScore, setTestPrevScore] = useState(null)
    //sugested modal
    const [testSuggestModal, setTestSuggestModal] = useState(false)
    const [resultPassUser, setResultPassUser] = useState([])
    const [allTestIds, setAllTestIds] = useState([])
    const [filterIds, setFilterIds] = useState([])
    const [suggestAns, setSuggestAns] = useState([])
    const [suggestMCQAns, setSuggestMCQAns] = useState([])
    const [suggestSCQAns, setSuggestSCQAns] = useState([])
    const [suggestTFQAns, setSuggestTFQAns] = useState([])
    const [currentVideoDetails, setCurrentVideoDetails] = useState({ link: "", title: "", description: "" })
    const [showVideoContentModal, setShowVideoContentModal] = useState(false)
    const [showDocumentInSidebar, setShowDucumentInSidebar] = useState(false)
    const [testAttempt, setTestAttempt] = useState(0)
    const [currentDocumentProgram, setCurrentDocumentProgram] = useState([])
    const [selectedCourse , setSelectCourse] = useState('');
    const [testComplete,setTestComplete] = useState(false);
    const [courseComplete,setCourseComplete] = useState(false);

    const GotoCommonDashboard = ()=>{
        window.location.href="/app/common-dashboard"
       }

    const [attemptedAnswers, setAttemptedAnswers] = useState([[], null, null, null])
    const [programTestSummaryData, setProgramTestSummaryData] = useState([])
    const [token, setToken] = useState(() => {
        const saved = localStorage.getItem("R1");
        const initialValue = JSON.parse(saved);
        return initialValue.access_token || "";
    });
    const [programTest, setProgramTest] = useState([])
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [currentTestId, setCurrentTestId] = useState('')
    const [allPassTestId, setAllPassTestId] = useState([])
    const [courseDescription, setCourseDescription] = useState([])
    const [allTestResult, setAllTestResult] = useState([]);

    const [activeCourse, setActiveCourse] = useState(null)
    const handelePassModal = async () => {
        closeModal();
        let arr = [];
        programTest?.forEach((data, i) => {
            arr.push(data.testId);
        });
        setTimeout(()=>{
            callApiTestStatus(arr);
            setAllTestIds(arr);
        },2000)
    }
    const callApiTestStatus = async (testIds) => {
        const testIdQueryParams = testIds.join(',');
        try {
            setIsLoading(true);
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
            const getResultStatus = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_TEST_STATUS}?testId=${testIdQueryParams}`
            );
            if (getResultStatus && getResultStatus.status === 200) {
                // setTestComplete(true)
                let objResultPass = {};
                if (getResultStatus.data.data?.length) {
                    objResultPass = getResultStatus.data.data;
                    setResultPassUser(objResultPass);
                    let arr = [];
                    let obj = {}
                    // let arr3 = [];

                    objResultPass.forEach((item, i) => {
                        // obj['testId'] = item.testId;
                        // obj['result'] = item.result;
                        arr = [...arr, item.testId]
                        // arr3 = [...arr3, obj]

                    })
                    setAllPassTestId(arr);
                    setTestComplete(true);
                    setTimeout(()=>{
                       setIsLoading(false);
                    },2000)

                }
                else {
                    setIsLoading(false);
                }

            } else if (getResultStatus && getResultStatus.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        let arr = [];
        programTest?.forEach((data, i) => {
            arr.push(data.testId);
        });
        if (arr.length) callApiTestStatus(arr);
        setAllTestIds(arr);
    }, [programTest]);

    // useEffect(() => {
        //console.log("filterIds", filterIds);  
        // checkPassResult?getProgramData(companyId):"";     
    // }, [filterIds]);

    const [contentId, setContentId] = useState('')
    const getProgramData = async (companyId) => {
        // setIsLoading(true);
        try {
                const getProgramDetails = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_USER_PROGRAM_DETAILS}?companyId=${companyId}&programId=${program_id}`
                );
                if (getProgramDetails && getProgramDetails.status === 200) {
                    let obj = {};
                    if (getProgramDetails.data.data?.length) obj = getProgramDetails.data.data[0]
                    let testObj = {};
                    let testArr = [];
                    testObj['id']=obj.programID;
                    testObj['name']=obj.program_name;
                    testObj['description']=obj.description;
                    testObj['courseName']=Object.keys(obj.course);
                    testArr.push(testObj);
                    setCurrentDocumentProgram(testArr)
                    setProgramData(obj);
                    setCourseContent(obj?.course)
                    setCourseData(Object.keys(obj?.course));
                    if (obj.courseDescription) setCourseDescription(obj.courseDescription)
                    if (obj?.TestProgram?.length > 0) setProgramTest(obj?.TestProgram)
                    // setIsLoading(false);
                } else if (getProgramDetails && getProgramDetails.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
          
        } catch (error) {
            setIsLoading(false);
        }
    }
    const handleTranslationLang = () => {        
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    };
    const callApi = async () => {
        setIsLoading(true)
        const company = await getDynamicCompany(localStorage.getItem('dynamic_id'));
        if (company) setCompanyDetails(company)
        await getProgramData(company.document_id)
        await getProgramSummery()
        handleTranslationLang()
        // setTimeout(()=>{
        // setIsLoading(false)
        // },3000)
    }
    useEffect(() => {
        callApi()
    }, []);

    const closeModal = () => {
        setIsLoading(false);
        setAlertMessage({ show: false, spinner: false, message: "", class: "" });
        setTestModal(false);
        setCurrentTestId('');
        setResultModalPass(false, "");
        setResultModalFail(false);
        setResultModalFailSecond(false);
        setTestSuggestModal(false);
        setDisableSubmitButton(false);
        setTestAttempt(0);
    }

    const getProgramSummery = async () => {
        // setIsLoading(true);
        try {
             const getProgramTestDetails = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_PROGRAM_TEST_SUMMARY}?companyId=${company_id}&programId=${program_id}`
            );

            if (getProgramTestDetails && getProgramTestDetails.status === 200) {

                if (getProgramTestDetails?.data?.data?.length > 0) {
                    setProgramTestSummaryData(getProgramTestDetails?.data?.data)
                }
                // setIsLoading(false);
            } else if (getProgramTestDetails && getProgramTestDetails.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
         
        } catch (error) {
            setIsLoading(false);
        }
    }
    const handleMultiChoiceAnswer = (queNo, ansNo) => {
        let allAnswer = attemptedAnswers;
        let arr = []
        if (attemptedAnswers[queNo].includes(ansNo)) arr = attemptedAnswers[queNo]?.filter((item) => item != ansNo)
        else arr = [...attemptedAnswers[queNo], ansNo]
        allAnswer[queNo] = arr;
        setAttemptedAnswers([...allAnswer])
    }
    const handleSingleChoiceAnswer = (queNo, ansNo) => {
        setAttemptedAnswers([...attemptedAnswers.map((item, i) => i == queNo ? ansNo : item)])
    }
    const handleTrueFalseAnswer = (queNo, ans) => {
        let allAnswer = attemptedAnswers;
        allAnswer[queNo] = ans;
        setAttemptedAnswers([...allAnswer])
    }
    const handleWrittenAnswer = (queNo, value) => {
        let allAnswer = attemptedAnswers;
        allAnswer[queNo] = value;
        setAttemptedAnswers([...allAnswer])
    }
    function validateData(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i] === null) {
                return `${t("user.program_test.validation_error")}`;
            } else if (Array.isArray(data[i]) && data[i].length === 0) {
                return `${t("user.program_test.validation_error")}`;
            }
        }
        return null; // No validation errors found
    }
    const handleTestValidation = () => {
        const validationError = validateData(attemptedAnswers);
        if (validationError) {
            setAlertMessage({ show: true, spinner: false, message: validationError, class: "alert alert-danger" });
        } else {
            submitTest();
        }
    }
    const submitTest = async () => {
        setAlertMessage({ show: true, spinner: true, message: t("user.login.wait"), class: "alert alert-info" })
        setDisableSubmitButton(true);
        let payload = {
            companyId: companyDetails.document_id,
            programId: program_id,
            testId: currentTestId,
            submittedAnswer: attemptedAnswers
        }
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
                const submitTest = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_USER_TEST_SUBMIT}`,
                    payload,
                    "",
                    ""
                );
                if (submitTest && submitTest.status === 200) {
                    if (submitTest.data.statuscode == 1) {
                        setTestSubmitedData({ "submittedId": submitTest.data.data.submittedId });
                        setTestPrevScore(submitTest.data.data.prevResult);
                        // Call test scrore api
                        // console.log("check......", submitTest.data.data.submittedId, submitTest.data.data.prevResult)
                       let userTestResult = await getTestScore(submitTest.data.data.submittedId, submitTest.data.data.prevResult);
                       
                    }
                    setAlertMessage({ show: true, spinner: false, message: t("user.training.testSubmitSuccess"), class: "alert alert-success" });
                } else if (submitTest && submitTest.status === 203) {
                    // setDisableSubmitButton(false);
                    setAlertMessage({ show: true, spinner: false, message: submitTest.data.message, class: "alert alert-danger" })
                } else {
                    setIsLoading(false);

                }
            } else {
            setIsLoading(false);

            }
        } catch (error) {
            //console.log("error", error)
            setIsLoading(false);
        }

    }
    const testModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading pr-2" id="exampleModalLabel">
                            <img src={RIGHT} alt="icon" />
                            <strong className="ml-sm-0 ml-md-0 ml-lg-3 ml-xl-3 ml-xxl-3 program-font p-2">{t("user.training.testProgram")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { closeModal(); setCurrentTestQuestions([]) }}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                (currentTestQuestions && currentTestQuestions.length) ?
                                    <div className="test-questions">
                                        {currentTestQuestions.map((question, i) => {
                                            return (
                                                <div className="question" key={i}>
                                                    <div className="name">
                                                        <span>{t("user.training.question")} {i + 1}</span>
                                                        {question.type == 'mcq' ? t("user.program_test.multiple_ans") : question.type == 'scq' ? t("user.program_test.single_ans") : ''}
                                                    </div>
                                                    <div className="description my-2">{question.que}</div>
                                                    {
                                                        question.type == 'mcq' ?
                                                            <div>
                                                                {question.ans.map((answer, j) => {
                                                                    return (
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="mr-3 span">{String.fromCharCode(65 + j)}</div>
                                                                            <div className={`document-program-details document-program-details-new w-100 ${attemptedAnswers[i]?.includes(j + 1) ? 'answer-selected' : ""}`} onClick={() => handleMultiChoiceAnswer(i, j + 1)}>{answer}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div> :
                                                            question.type == 'scq' ?
                                                                <div>
                                                                    {question.ans.map((answer, k) => {
                                                                        return (
                                                                            <div className="d-flex align-items-center" key={k}>
                                                                                <div className="mr-3 span">{String.fromCharCode(65 + k)}</div>
                                                                                <div className={`document-program-details document-program-details-new w-100 ${attemptedAnswers[i] == k ? 'answer-selected' : ""}`} onClick={() => handleSingleChoiceAnswer(i, k)}>{answer}</div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div> :
                                                                question.type == 'tfq' ?
                                                                    <div className="true-false-button">
                                                                        <button className={`buttons mr-3 ${attemptedAnswers[i] == 1 ? 'selected' : ''}`} onClick={() => handleTrueFalseAnswer(i, 1)}>{t("superadmin.training.test_program.choiceTrue")}</button>
                                                                        <button className={`buttons ${attemptedAnswers[i] == 0 ? 'selected' : ''}`} onClick={() => handleTrueFalseAnswer(i, 0)}>{t("superadmin.training.test_program.choiceFalse")}</button>
                                                                    </div> :
                                                                    <div className="answer-textarea">
                                                                        <textarea className="" rows={3} placeholder="Scrivi la risposta" value={attemptedAnswers[i]} onChange={(e) => handleWrittenAnswer(i, e.target.value)}></textarea>
                                                                    </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                        {alertMessage.show ? <div className={`d-flex my-2 ${alertMessage.class}`} >
                                            {alertMessage.spinner ? <div className="spinner-container-new">
                                                <div className="loading-spinner-new">
                                                </div>
                                            </div> : null}
                                            <div className="ml-1">{alertMessage.message}</div>
                                        </div> : null}
                                        <div className="d-flex justify-content-end test-section">
                                            <button className="buttons" disabled={disableSubmitButton} onClick={() => handleTestValidation()}>{t("user.training.end")}</button>
                                        </div>
                                    </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const handleSugesstedScreen = () => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        setDisableSubmitButton(false);
        setResultModalFail(false)
        setIsLoading(false);
        setTestSuggestModal(false);
        setCurrentTestId(currentTestId);
        
        if (currentTestQuestions.length > 0) {
            let arr = [];
            currentTestQuestions.forEach((item, i) => {
                if (item.type == 'mcq') arr = [...arr, []]
                else arr = [...arr, null]
            })
            setAttemptedAnswers(arr)
            setCurrentTestQuestions(currentTestQuestions)
        }
        setTestModal(false);
        setTestModal(true);
        // setCurrentTestQuestions(currentTestQuestions);
    }

    const testConfiguration = (test) => {        
        if (test?.length > 0) {
            let arr = [];
            test.forEach((item, i) => {
                if (item.type == 'mcq') arr = [...arr, []]
                else arr = [...arr, null]
            })
            setAttemptedAnswers(arr)
            setCurrentTestQuestions(test)
        }
    }
    const findCorrectAns = (data) => {
        if (data.length > 0) {
            let mcqarr = [];
            let scqarr = [];
            let tfqarr = [];
            data.forEach((item, i) => {
                if (item.type == 'mcq') {
                    mcqarr[i] = item.correct;
                }
                if (item.type == 'scq') {
                    scqarr[i] = item.correct;
                }

                if (item.type == 'tfq') {
                    tfqarr[i] = item.correct;
                }
            })
            setSuggestMCQAns(mcqarr);
            setSuggestSCQAns(scqarr);
            setSuggestTFQAns(tfqarr);
        }
    }
  
    //suggested modal start
    const testSuggestedModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading pr-2" id="exampleModalLabel">
                            <img src={RIGHT} alt="icon" />
                            <strong className="ml-sm-0 ml-md-0 ml-lg-3 ml-xl-3 ml-xxl-3 program-font suggestedScreen">{t("user.training.testProgram")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { handelePassModal() }}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                (currentTestQuestions && currentTestQuestions.length) ?
                                    <div className="test-questions">
                                        {currentTestQuestions.map((question, i) => {
                                            return (
                                                <div className="question" key={i}>
                                                    <div className="name">
                                                        <span>{t("user.training.question")} {i + 1}</span> {question.type == 'mcq' ? t("user.program_test.multiple_ans") : question.type == 'scq' ? t("user.program_test.single_ans") : ''}
                                                    </div>
                                                    <div className="description my-2">{question.que}</div>
                                                    {
                                                        question.type == 'mcq' ?
                                                            <div>
                                                                {question.ans.map((answer, j) => {
                                                                    // console.log("mcq", suggestMCQAns[i]?.includes(j + 1))

                                                                    return (
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="mr-3 span">{String.fromCharCode(65 + j)}</div>
                                                                            <div className={`document-program-details-new w-100 ${suggestMCQAns[i]?.includes(j + 1) ? 'document-program-details suggestedAns' : 'document-program-details'}`}>{answer}</div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div> :
                                                            question.type == 'scq' ?
                                                                <div>
                                                                    {question.ans.map((answer, k) => {
                                                                        // console.log("scq", suggestSCQAns[i], " ", k)
                                                                        return (
                                                                            <div className="d-flex align-items-center" key={k}>
                                                                                <div className="mr-3 span">{String.fromCharCode(65 + k)}</div>
                                                                                <div className={`document-program-details-new w-100 ${suggestSCQAns[i] == k ? 'document-program-details suggestedAns' : 'document-program-details'}`}  >{answer}</div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div> :
                                                                question.type == 'tfq' ?
                                                                    <div className="true-false-button">
                                                                        <button className={`buttons mr-3 ${suggestTFQAns?.[i] == 1 ? 'selected' : ''}`}  >{t("user.program_test.choiceTrue")}</button>
                                                                        <button className={`buttons ${suggestTFQAns?.[i] == 0 ? 'selected' : ''}`} >{t("user.program_test.choiceFalse")}</button>
                                                                    </div> :
                                                                    <div className="answer-textarea">
                                                                        <textarea className="" rows={3} placeholder={t("user.program_test.trueFlaseAns")} value={attemptedAnswers[i]} onChange={(e) => handleWrittenAnswer(i, e.target.value)}></textarea>
                                                                    </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                        {alertMessage.show ? <div className={`d-flex my-2 `} >
                                            {alertMessage.spinner ? <div className="spinner-container-new">
                                                <div className="loading-spinner-new">
                                                </div>
                                            </div> : null}
                                        </div> : null}
                                        <div className="d-flex justify-content-end test-section">
                                            <button className="buttons" onClick={() => { handleSugesstedScreen() }}>{t("user.program_test.repeat_test")}
                                            </button>

                                        </div>
                                    </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    //suggested modal close

    const resultModalContentPass = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading pr-2" id="exampleModalLabel">
                            <img src={RIGHT} alt="icon" />
                            <strong className="ml-sm-0 ml-md-0 ml-lg-3 ml-xl-3 ml-xxl-3 program-font">{t("user.training.testProgram")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => handelePassModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                (currentTestQuestions && currentTestQuestions.length) ?
                                    <div className="test-questions">
                                        {
                                            <>
                                                <div className="outer-container">
                                                    <div className="inner-container">
                                                        <div className="text-center">
                                                            <img src={Right_Ans} alt="icon" />
                                                            <h3>{resultTestData.score}% {t("user.program_test.correct_ans")}</h3>
                                                            <p> {t("user.program_test.test_pass")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="d-flex justify-content-end">
                                            {/* <button className="buttons" onClick={() => closeModal()}>{t("user.program_test.fine")}</button> */}
                                            <button className="buttons" onClick={() => handelePassModal()}>{t("user.program_test.fine")}</button>
                                        </div>
                                    </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const resultModalContentFail = () => {
        return (
            <div className="model-pop-new fail-first-time">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading pr-2" id="exampleModalLabel">
                            <img src={RIGHT} alt="icon" />
                            <strong className="ml-sm-0 ml-md-0 ml-lg-3 ml-xl-3 ml-xxl-3 program-font">{t("user.training.testProgram")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => handelePassModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                (currentTestQuestions && currentTestQuestions.length) ?
                                    <div className="test-questions">
                                        {
                                            <>
                                                <div className="outer-container">
                                                    <div className="inner-container">
                                                        <div className="text-center">
                                                            <img src={Wrong_Ans} alt="icon" />
                                                            <h3 className="wrong-test-heading">{resultTestData.score}% {t("user.program_test.correct_ans")}</h3>
                                                            <div className="box-wraping wrong-test-para">
                                                                {t("user.program_test.fail_message")}
                                                                <p>{t("user.program_test.repeat_test")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="d-flex justify-content-center">
                                            <button className="buttons" onClick={() => handleSugesstedScreen()}>{t("user.program_test.repeat_test")}</button>
                                        </div>
                                    </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const resultModalContentFailSecond = () => {
           return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading pr-2" id="exampleModalLabel">
                            <img src={RIGHT} alt="icon" />
                            <strong className="ml-sm-0 ml-md-0 ml-lg-3 ml-xl-3 ml-xxl-3 program-font fail-second-time">{t("user.training.testProgram")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => { handelePassModal(); setAlertMessage({ spinner: false }); }}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            {
                                (currentTestQuestions && currentTestQuestions.length) ?
                                    <div className="test-questions">
                                        {
                                            <>
                                                <div className="outer-container">
                                                    <div className="inner-container">
                                                        <div className="text-center">
                                                            <img src={Wrong_Ans} alt="icon" />
                                                            <h3 className="wrong-test-heading">{resultTestData.score}% {t("user.program_test.correct_ans")}</h3>
                                                            <div className="box-wraping wrong-test-para">
                                                                {t("user.program_test.fail_message")} <p>{t("user.program_test.suggest_ans")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="d-flex justify-content-center">
                                            <button className="buttons " onClick={() => { setTestModal(false); setResultModalFailSecond(false); {(testAttempt>2) ? handelePassModal() : setTestSuggestModal(true)}; setCurrentTestId(currentTestId) }}>{(testAttempt>2) ? t("user.program_test.cant_repeat_test") : t("user.program_test.repeat_test")}</button>
                                        </div>
                                    </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getTestScore = async (submittedId, prevResult) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
                const getProgramDetails = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_GET_USER_TEST_SCORE}?testId=${submittedId}`
                );

                if (getProgramDetails && getProgramDetails.status === 200) {
                    const result = getProgramDetails.data.data;
                    if (result.length > 0) {
                        // console.log(result[0])
                        setTestModal(false);
                        setIsLoading(false);
                        const percent = result[0].passingPercentage;
                        const correctAnswer = result[0].correctAnswer;
                        const noOfQuestions = result[0].noOfQuestions;
                        setTestAttempt(result[0].attempt)
                        let score = 0;
                        if (noOfQuestions != 0) {
                            score = (correctAnswer / noOfQuestions) * 100;
                            score = Math.round(score);
                        } else {
                            score = score.round(result[0].score);
                        }
                        console.log("score > percent", score > percent)
                        if (score > percent) {
                            setResultTestData({ score });
                            setResultModalPass(true);
                        }
                        else {
                            getSuggestionAns(currentTestId);

                            if (prevResult == "fail") {
                                // setTimeout(()=>{
                                    setResultTestData({ score });
                                    setResultModalFailSecond(true);
                                // },3000)

                            } else {
                                setResultTestData({ score });
                                setResultModalFail(true);
                            }
                        }
                    }
                } else if (getProgramDetails && getProgramDetails.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            //console.log("error", error)
            setIsLoading(false);
        }
    }
    const getSuggestionAns = async (testId) => {
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
                const getSuggestAns = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_SUGGEST_ANS}?testId=${testId}`
                );
                setIsLoading(false);
                if (getSuggestAns && getSuggestAns.status === 200) {
                    findCorrectAns(getSuggestAns.data.data);
                    setSuggestAns(getSuggestAns.data.data);
                }
            }
        } catch (error) {
            //console.log("error", error)
            setIsLoading(false);
        }
    }
    const activeContentConfig = (id) => {
        setActiveContent(id)
        if (activeContent !== id) {
            setShowDucumentInSidebar(false)
            setShowVideoContentModal(false)
        }
    }

    const updateContentCount = async (program, course) => {
        let todayTime = new Date();
        let utcDate = new Date(todayTime.getUTCFullYear(), todayTime.getUTCMonth(), todayTime.getUTCDate()).getTime() / 1000;
        let payload = {
            companyId: companyDetails.document_id,
            contentType: 'video',
            "date": utcDate,
            "programs": program,
            "selectedCourse" : course
        }
        try {
            const getAllDataResult = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_CONTENT_COUNT}`,
                payload,
                "",
                ""
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                console.log("video passed ")
            } else {
                console.log("failed")
            }
        } catch (error) {
            console.log(error)

        }
    }


    const getVideoContentDetails = async (id,course) => {
        console.log('selectedCourse detail - ', course);
        setSelectCourse(course);
        window.scrollTo(0, 0);
        setIsLoading(true);
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const videoResult = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CONTENT_DETAILS}?contentId=${id}`
                );
                if (videoResult && videoResult.status === 200) {
                    setIsLoading(false);
                    let data1 = videoResult.data?.data;
                    updateContentCount(currentDocumentProgram, course)

                    let link = data1.url ? data1.url : data1.filePath?.includes("vimeo") ? data1.filePath : `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_CONTENT}${data1.filePath}`;
                    if (data1.url) {
                        window.open(data1.url, '_blank').focus();
                    } else {
                        setCurrentVideoDetails({ title: data1.title, description: data1.description, link: link, error: false })
                        setShowVideoContentModal(true)
                        setShowDucumentInSidebar(false)
                    }
                    updateUserCourseContentStatus(id)
                } else {
                    if (videoResult?.status === 203) {
                        setCurrentVideoDetails({ ...currentVideoDetails, error: true })
                    }
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const getTestDetailsAPI = async (testCourseId) => {
        try {
            setIsLoading(true)
            let testId = programTest?.find((d1 => d1.test_course_id == testCourseId))?.testId;
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     "",
            //     "refresh_token"
            // );
            // if (refreshToken) {
                const result = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_TEST_DETAILS}?testId=${testId}`
                );
                if (result && result.status === 200) {
                    setTestModal(true);
                    testConfiguration(result.data.data.questions);
                    setCurrentTestId(testId)
                    setIsLoading(false);
                } else {
                 setIsLoading(false);
                }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const previewDoc = (contentId, course) => {
        setSelectCourse(course);
        setContentId(contentId)
        setShowDucumentInSidebar(true)
        setShowVideoContentModal(false)
        window.scrollTo(0, 0);
    }
    const updateUserCourseContentStatus = async (contentId) => {
        setIsLoading(true)
        let payload = {
            programs: [program_id],
            contentId: contentId,
        }
        try {
            const updateContentStatus = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_USER_COURSE_CONTENT_STATUS}`,
                payload,
                "",
                ""
            );
            if (updateContentStatus && updateContentStatus.status === 200) {
                setIsLoading(false);
                // add tab highlighted code
            }
        } catch (error) {
            // console.log("error", error)
            setIsLoading(false);
        }
    }
    return (
        <>
            <Modal title="" size="sm" contentClasses="" onClose={() => { setTestModal(false); setCurrentTestQuestions([]) }} show={testModal}>
                {testModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setResultModalPass(false, "")} show={resultModalPass}>
                {resultModalContentPass()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setResultModalFail(false)} show={resultModalFail}>
                {resultModalContentFail()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setResultModalFailSecond(false)} show={resultModalFailSecond}>
                {resultModalContentFailSecond()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => { setTestSuggestModal(false); setCurrentTestQuestions([]) }} show={testSuggestModal}>
                {testSuggestedModalContent()}
            </Modal>

            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-md-10 pr-0 p0 ipd-righ">
                            <div className="right-dashboard topspace">
                                <div className="d-flex justify-content-between company-name-programs">
                                    <h2 className="heading-dash">
                                        <button className="back-button pl-0" onClick={history.goBack}><img src={PREV_PAGE_ICON} alt="icon" /></button>
                                        <span className="program-back-btn">{t("superadmin.program.program_title")}</span></h2>

                                        {(isMobileOnly) ? null :(
                                        <div className="cmd-back-training cmd-back-right-text" id="step-8">
                                            <p className="pt-2 pr-5" dangerouslySetInnerHTML={{ __html: t("user.dashboard.back_to_cmd") }}></p>                                            
                                                <button className="btn-apr" onClick={GotoCommonDashboard}>{t("user.dashboard.go")}</button>                    
                                            </div>)}
                                </div>

                                <div className="">
                                    <div className="">
                                        <>
                                            <div>
                                            {(isMobileOnly) ? (
                                                <div className="cmd-back-training cmd-back-right-text mb-3" id="step-8">
                                                <p className="p-2" dangerouslySetInnerHTML={{ __html: t("user.dashboard.back_to_cmd") }}></p>                                            
                                                    <button className="btn-apr" onClick={GotoCommonDashboard}>{t("user.dashboard.go")}</button>                    
                                                </div>
                                            )
                                            :  null}
                                                <div className="row">

                                                    <div className="col-md-5 right_lines fade-lines pl-sm-3 pl-md-3 pl-lg-3 pl-xl-0 pl-xxl-0 mb-5">
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <div className="text-left">
                                                                <h6 className="modal-title pop_heading " style={{ textAlign: "left" }}>
                                                                    <span className="program-title-heading">{programData.program_name}</span>
                                                                </h6>
                                                                <p className="margin-left20"  >{programData.description}</p>
                                                            </div>
                                                        </div>
                                                        {(isMobileOnly) ? (
                                                            <div className="display-visible-tab">                                                              
                                                                {showVideoContentModal ? <div className="" >
                                                                    <div className='player-wrapper'>
                                                                        <ReactPlayer
                                                                            className='react-player'
                                                                            url={currentVideoDetails.link}
                                                                            width='100%'
                                                                            height='380px'
                                                                            controls={true}
                                                                        />
                                                                    </div>
                                                                    <div className='show-video-title video-text' >{currentVideoDetails.title}</div>
                                                                    <div className="show-video-description m-0 video-text">{currentVideoDetails.description}</div>
                                                                </div> : null}
                                                                {/* {showDocumentInSidebar ? <ViewDocument contentId={contentId} userProgramDetails={true} updateUserCourseContentStatus={updateUserCourseContentStatus}  /> : null} */}
                                                            {showDocumentInSidebar ? <ViewDocument contentId={contentId} program={currentDocumentProgram} companyId={companyDetails.document_id} userProgramDetails={true} updateUserCourseContentStatus={updateUserCourseContentStatus} selectedCourse={selectedCourse}/> : null}

                                                            </div>
                                                        ) : null}
                                                        <div id="accordion" className="accordion Percorso-text">
                                                            <div className="iapdwful p-r15">
                                                                <div className="card mb-0">
                                                                    {courseData?.length > 0 && courseData?.map((data, i) => {
                                                                        let keyData  = data ; 
                                                                        return (
                                                                            <div className="bot-lini" key={i}>
                                                                                <p className="course-name pt-2">{(programData.courseName && programData.courseName?.length) ? programData.courseName[i] : data}</p>
                                                                                {courseDescription.length > 0 ? <div className={`${activeCourse == i ? "" : "text-ellipsis"} text-left cursor-pointer text-color-wh`} onClick={() => setActiveCourse(i)}>
                                                                                    {courseDescription[i]}
                                                                                </div> : null}
                                                                                {courseContent[data].length > 0 ? (
                                                                                    <div className="course-content-list">
                                                                                        {courseContent[data].map((d, j) => {
                                                                                            const uniqueId = `collapse_${i}_${j}`;
                                                                                            return (
                                                                                                <React.Fragment key={j}>
                                                                                                    <div
                                                                                                        className="card-header percorso-header cursor-pointer percorso-header-new collapsed"
                                                                                                        data-toggle="collapse"
                                                                                                        data-target={`#${uniqueId}`}
                                                                                                        aria-expanded="true"
                                                                                                        onClick={() => activeContentConfig(d.content_Id)}
                                                                                                    >
                                                                                                        {/* <a className="card-title"> */}
                                                                                                        <div className={`percorso-heading ${(d.content_Id == activeContent && activeContent !== '') ? "" : "black-course-text"}`} >
                                                                                                           {d.content_Name} <span>
                                                                                                           {d.contentStatus == "conclude" ?
                                                                                                           <img className="rightIcon-symbol" src={rightIcon} alt="icon" /> : null }
                                                                                                           </span>
                                                                                                        </div>
                                                                                                        {/* </a> */}

                                                                                                    </div>
                                                                                                    <div
                                                                                                        id={uniqueId}
                                                                                                        className="card-body collapse collapse-dark"
                                                                                                        data-parent="#accordion"
                                                                                                    >
                                                                                                        <div className="d-flex align-items-start cursor-pointer" onClick={() => d.content_type == 'video' ? getVideoContentDetails(d.content_Id,keyData) : previewDoc(d.content_Id,keyData)}>
                                                                                                            <img className="mr-2 mt-2" src={(d.content_type == "document") ? DOCUMENTO_ICONA : VIDEO_ICONAvideo} alt="icon" />
                                                                                                            <p className="course-title">{(d.contentDescription) ? d.contentDescription : "No Description"}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}
                                                                                     
                                                                                        {
                                                                                            (programTest[i]?.test_course_id && programTest[i]?.testId!= null) ?
                                                                                                <button disabled={allPassTestId.includes(programTest[i]?.testId)} className={`button ${(allPassTestId.includes(programTest[i]?.testId)) ? "enable-btn disable-btn2-new" : "enable-btn"}`} onClick={() => { getTestDetailsAPI(programTest[i]?.test_course_id) }}>
                                                                                                    {(allPassTestId.includes(programTest[i]?.testId) && testComplete == true) ? t("user.training.completed") : t("user.training.goToTheTest")}
                                                                                                </button> :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        );
                                                                    })}

                                                                    {programTestSummaryData?.length > 0 && programTestSummaryData?.map((data, i) => {
                                                                        return (
                                                                            <div className="bot-lini" key={i}>
                                                                                <p className="course-name pt-2">Test</p>

                                                                                <div
                                                                                    className="card-header percorso-header collapsed"
                                                                                    data-toggle="collapse"
                                                                                    data-target={`#${i}`}
                                                                                    aria-expanded="true"
                                                                                >
                                                                                    <a className="card-title">
                                                                                        <div className="percorso-heading">
                                                                                            <div className="m-1">{data.description}</div>
                                                                                            <p className="m-1"><strong>{`${data.noOfQuestions}${t("superadmin.training.popup.request")} / ${data.percentOfCorrectAns}%${t("superadmin.training.popup.answer")}`}</strong></p>
                                                                                        </div>

                                                                                    </a>
                                                                                </div>
                                                                                <div
                                                                                    id={i}
                                                                                    className="card-body collapse"
                                                                                    data-parent="#accordion"
                                                                                >
                                                                                    <div className="d-flex align-items-start">
                                                                                        <img className="mr-2" src={DOCUMENTO_ICONA} alt="icon" />
                                                                                        <p className="course-title">{data.description}</p>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                {/* <div className="card mt-5">
                                                                    {(programTest && programTest.length > 0) ?
                                                                        <div className="test-section">
                                                                            {programTest.map((item, i) => {
                                                                                return (
                                                                                    <div className="d-flex justify-content-between align items-center item all-test-button user-test-list">
                                                                                        <span>Test {i + 1}</span>
                                                                                        <button disabled={allPassTestId.includes(item.testId)} className={`button ${(allPassTestId.includes(item.testId)) ? "enable-btn disable-btn2-new" : "enable-btn"}`} onClick={() => { setTestModal(true); testConfiguration(item.questions); setCurrentTestId(item.testId) }}>
                                                                                            {(allPassTestId.includes(item.testId)) ? t("user.training.completed") : t("user.training.goToTheTest")}
                                                                                        </button>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div> : null}
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(!isMobileOnly) ? (
                                                        <div className="col-md-7 display-none-tab">
                                                            {showVideoContentModal ? <div className="" >
                                                                <div className='player-wrapper'>
                                                                    <ReactPlayer
                                                                        className='react-player'
                                                                        url={currentVideoDetails.link}
                                                                        width='100%'
                                                                        height='380px'
                                                                        controls={true}
                                                                    />
                                                                </div>
                                                                <div className='show-video-title video-text' >{currentVideoDetails.title}</div>
                                                                <div className="show-video-description m-0 video-text">{currentVideoDetails.description}</div>
                                                            </div> : null}
                                                            {showDocumentInSidebar ? <ViewDocument contentId={contentId} program={currentDocumentProgram} companyId={companyDetails.document_id} userProgramDetails={true} updateUserCourseContentStatus={updateUserCourseContentStatus} selectedCourse={selectedCourse}/> : null}
                                                        </div>
                                                    ) : null}

                                                </div>
                                            </div>

                                        </>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}

                </div>
            </section>
            <Sidebarmob />
        </>
    );
}

export default UserProgramDetails;


