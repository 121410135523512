import { createSlice } from '@reduxjs/toolkit';
import PostRequest from '../../components/apiConnection/postRequest';
import GetRequest from '../../components/apiConnection/getRequest';
import { setLocalStoarge } from '../../components/common/allfunction';

export const STATUSES = Object.freeze({
    SUCCESS: 'SUCCESS',
    ERROR: 'Error',
    LOADING: false
})

const repeatSlice = createSlice({
    name: 'repeat',
    initialState: {
        data: null,
        status: STATUSES.SUCCESS,
        isLoadingCheck:false
    },
    reducers: {
        setGetRepetitionData(state, actions) {
            state.data = {...actions.payload}
        },
        setGetRepetitionStatus(state, actions) {
            state.status = actions.payload
        },
        setLoading(state,actions){
            state.isLoadingCheck= actions.payload
        }
    }
})

// Action creators are generated for each case reducer function
export const { setGetRepetitionData, setGetRepetitionStatus, setLoading } = repeatSlice.actions

export default repeatSlice.reducer;


// middleware 

export function fetchRepetition(companyId,dynamicId) {
    return async function fetchRepeatThunk(dispatch) {
        dispatch(setLoading(true));
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     "",
            //     "refresh_token"
            // );
            // if (refreshToken) {
                const getTrainingData = await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSIGN_TRAINING_DATA}?company_id=${companyId}&company_dynamic_id=${dynamicId}`
                );
                
                if (getTrainingData && getTrainingData.status === 200) {
                    dispatch(setGetRepetitionData(getTrainingData.data.data.module_training_date));
                    setLocalStoarge("cognitiveStep",getTrainingData.data.data.module_training_date.repeat_start_dates_mindfulness.length)
                    setLocalStoarge("mindfullnessStep",getTrainingData.data.data.module_training_date.repeat_start_dates_cognitive.length)
                    dispatch(setGetRepetitionStatus(STATUSES.SUCCESS));
                    dispatch(setLoading(false));
                }
                else{
                    dispatch(setLoading(false));
                }
               
            // } 
            // else {
            //  return false
            //  }
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
            dispatch(setGetRepetitionStatus(STATUSES.error));
        }
    }
}


