import React, { useEffect, useState } from 'react'
import Group842 from "../../assets/Image/Group842.svg";
import Group1165 from "../../assets/Image/Group1165.svg";
import Riepilogo1 from "../../assets/Image/Riepilogo1.svg";
import bwBrainwellness from "../../assets/Image/bwBrainwellness.svg";
import { Link } from "react-router-dom";
import FORMAZIONE_ICONA from "../../assets/Image/FORMAZIONE_ICONA.svg";
import { getLocalStoarge } from './allfunction';
import GetRequest from '../apiConnection/getRequest';
import { useTranslation } from 'react-i18next';
import TRAINING from '../../assets/Image/TRAINING COGNITIVO_ICONA.svg';
import MEDITAZIONE from '../../assets/Image/MEDITAZIONE_ICONA.svg';
import { useSelector } from 'react-redux';
import userManualImg from '../../assets/Image/user-manual-sidebar.svg';
import Istruzioni from '../../assets/Image/INFO_icon.svg';
import Ist_sub_1 from '../../assets/Image/FACE_ICON.svg';
import Ist_sub_3 from '../../assets/Image/Group-965.svg';
import REPORT_PARAMETRI_ICONA1 from '../../assets/Image/REPORT_PARAMETRI_ICONA1.svg';

import InstructionModal from "./instructionModal";
function Sidebarmob() {

  const [activebg, setActivebg] = useState("active");
  const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
  const [dynamicId, setDynamicId] = useState(getLocalStoarge('dynamic_id'));
  const path = window.location.pathname;
  const pageName = path.substring(path.lastIndexOf('/') + 1);
  const [cognitiveRepeatation, setCognitiveRepeatation] = useState(1);
  const [mindfulnessRepeatation, setMindfulnessRepeatation] = useState(1);
  const [cognitiveDate, setCognitiveDate] = useState([]);
  const [mindfulnessDate, setMindFulnessDate] = useState([])
  const [trainingData, setTrainigData] = useState([]);
  const [t, i18n] = useTranslation("common");
  const [isShown, setIsShown] = useState(false);
  const [isShownM, setIsShownM] = useState(false);
  const [modalIns1, setModalIns1] = useState(0);
  const { data, status } = useSelector((state) => {
    return state.repetition
  })
  const handleModal = () => {
    setModalIns1(0);
  }

  useEffect(() => {
    setActivebg(pageName);
  }, [])

  const handleBrainwell = () => {
    setIsShown(!isShown);

  }
  const handleManual = () => {
    setIsShownM(!isShownM);
  }
  useEffect(() => {
    setTrainigData(data)
    setCognitiveRepeatation(data?.repeat_allowed_cognitive)
    setCognitiveDate(data?.repeat_start_dates_cognitive)
    setMindfulnessRepeatation(data?.repeat_allowed_mindfulness)
    setMindFulnessDate(data?.repeat_start_dates_mindfulness)
  }, [data, status])

  const userGuide = () => {
    localStorage.setItem('summaryPageGuide', 0)
    localStorage.setItem('pageGuide', 0)
    window.location.href="./dashboard";
  }

  return (
    <>
      {(modalIns1) ?
        <InstructionModal popUpEnable={modalIns1} handleModal={handleModal} />
        : null
      }
      <div className='mobl-menu'>
        <div className={activebg == "common-dashboard" ? 'active' : ''} id="step-mob-2">
          <Link to='/common-dashboard'><img src={Group842} alt="icon" /></Link>
        </div>
        <div className={activebg == "dashboard" ? 'active' : ''} id="step-mob-2">
          <Link to='/dashboard'><img src={REPORT_PARAMETRI_ICONA1} alt="icon" /></Link>
        </div>
        <div className={activebg == "summary" ? 'active' : ''} id="step-mob-3">
          <Link to='/summary' active-link="active"><img src={Riepilogo1} alt="icon" /></Link>
        </div>
        {(isShown) ? <div className='Trainingcognitivo-mobile'>
          <p className='border-bottom-line'>
            {cognitiveDate && cognitiveDate.length > 0 && cognitiveDate.map((date, index) => (
              <Link to={`/congnitive-training/${index + 1}`} className="dropdown-item" key={index}>
                <img src={TRAINING} alt="icon" /> {t('user.sidebar.cognitive_training')}-{index + 1}
              </Link>
            ))}
          </p>
          <p>
            {mindfulnessDate && mindfulnessDate.length > 0 && mindfulnessDate.map((date, index) => (
              <Link to={`/mindfulness/${index + 1}`} className="dropdown-item" key={index}>
                <img src={MEDITAZIONE} alt="icon" /> {t('superadmin.sidebar.mindfulness')}-{index + 1}
              </Link>
            ))}
          </p>
        </div> : null}
        {(isShownM) ? <div className="Trainingcognitivo-mobile">
          <Link to="#" className="dropdown-item" onClick={() => setModalIns1(1)}>
            {" "}
            <img src={Ist_sub_1} alt="icon" />
            {t("user.sidebar.measurement")}
          </Link>
          {/* <Link to="#" className="dropdown-item" >
                          {" "}
                          <img src={Ist_sub_2} alt="icon" />
                          {t("user.sidebar.platform-usage")}
                        </Link>                        */}
          <Link to="#" className="dropdown-item" onClick={() => setModalIns1(3)}>
            {" "}
            <img src={Ist_sub_3} alt="icon" />
            {t("user.sidebar.legend")}
          </Link>
          <Link to="#" className="dropdown-item" onClick={() => userGuide()}>
            {" "}
            <img src={Istruzioni} alt="icon" />
            {t("user.sidebar.tour")}
          </Link>
          <Link to="/user-manual" className="dropdown-item">
            {" "}
            <img src={userManualImg} alt="icon" />
            {t("user.sidebar.manual")}
          </Link>
        </div> : null}
        {/* <div className={activebg == "brainwellness" ? 'active' : ''}>
          <button
            className="dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{
              width: '70px',
              height: '53px',
              borderRadius: '9px',
              backgroundColor: '#f0f1fe'
            }}
          >
            <img src={bwBrainwellness} alt="icon" />
            <div style={{ width: '100%', position:'absolute' ,height:'500px'  }} >
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {cognitiveDate && cognitiveDate.length > 0 && cognitiveDate.map((date, index) => (
                  <Link to={`/congnitive-training/${index + 1}`} className="dropdown-item" key={index}>
                    {t('superadmin.sidebar.cognitive')}-{index + 1}
                  </Link>
                ))}
              </div>

              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {mindfulnessDate && mindfulnessDate.length > 0 && mindfulnessDate.map((date, index) => (
                  <Link to={`/mindfulness/${index + 1}`} className="dropdown-item" key={index}>
                    {t('superadmin.sidebar.mindfulness')}-{index + 1}
                  </Link>
                ))}
              </div>

            </div>
          </button>
        </div> */}
        {/* {trainingData.trainingModule ==1 ? */}
        {/* <> */}
        <div className={activebg == "training" ? 'active' : ''} id="step-mob-4">
          <Link to='/training'><img src={FORMAZIONE_ICONA} alt="icon" /></Link>
        </div>
        <div className={(activebg == 1 || activebg == 2 || activebg == 3 || activebg == 4) ? 'active' : ''}>
          <button style={{ background: 'none' }} onClick={() => handleBrainwell()}><img src={bwBrainwellness} alt="icon" /></button>
        </div>
        {/* </> */}
        {/* : null} */}




        <div className={activebg == "profilemenu" ? 'active' : ''} id="step-mob-1">
          <Link to='/profilemenu'><img src={Group1165} alt="icon" /></Link>
        </div>
        <div className={activebg == "user-manual" ? 'active' : ''}>
          <Link to='#' onClick={() => handleManual()} ><img src={Istruzioni} alt="icon" /></Link>
        </div>

        {/* <div className={activebg == "profilemenu" ? 'active' : 'com-act'}>
       <Link to='/profilemenu'><img src={Group1165} alt="icon" /></Link>
      </div>
      <div className={activebg == "profilemenu" ? 'active' : 'com-act'}>
       <Link to='/profilemenu'><img src={Group1165} alt="icon" /></Link>
      </div> */}
        {/* <div className={activebg == "privacy" ? 'active' : ''}>
       <Link to='/privacy'><img src={Group1165} alt="icon" /></Link>
      </div> */}

      </div >


    </>

  );
}

export default Sidebarmob