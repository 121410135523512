import { useState, useEffect } from 'react'
import Group1018 from "../../../assets/Image/Group1018.svg";
import PREVIEWICONA from "../../../assets/Image/PREVIEWICONA.svg"
import { Link } from 'react-router-dom';
import OTPInput from "otp-input-react";
import PostRequest from '../../apiConnection/postRequest';
import {getQuestionairs, setLocalStoarge, getLocalStoarge, removeLocalStoargeKey, removeLocalStoarge} from "../../common/allfunction";
import LazyLoader from "../../common/lazyLoader";
import { useTranslation } from "react-i18next";

const OTPlogin = (props) =>{
  const [OTP, setOTP] = useState((props.match.params?.otp && props.match.params?.otp!=="" ) ? props.match.params?.otp : "");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [t, i18n] = useTranslation('common');
  const [alerts, setAlerts] = useState({
    alert: "",
    alerttype: "",
    alertclass: "danger",
    showalert: false,
  });
  useEffect(() => {
    handleTranslationLang();
  }, []);
  const { alert, alerttype, alertclass, showalert } = alerts;
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }
  const showalertMessage= async()=>{
       setTimeout(() => {
      setAlerts({
        alert: "Otp Invia nuovamente con successo.",
        alerttype: "Info",
        alertclass: "info",
        showalert: false,
      })
    }, 3000);  
  }

  const [counter, setCounter] = useState(90);
  const authTwoFaVerify = async (e) => {
    e.preventDefault();
    setAlerts({
      alert: "Per favore attendere un attimo.",
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    setIsLoading(true);
    const text_otp = OTP
    try {
      const payload = {
        otp: text_otp,
        email: email
      };
      const OTPResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_2FA_OTP}`,
        payload
      );
      if (OTPResult && OTPResult.status === 200) {
        if (OTPResult.data.code === 200) {
          setIsLoading(false);
          const { access_token, expires_in, refresh_token } = OTPResult.data.data.login;
          const logged_user = {
            access_token,
            expires_in,
            refresh_token,
          };
          setLocalStoarge("R1", JSON.stringify(logged_user));
          removeLocalStoargeKey('user_email');
          const { profile_pic, user_name, language,user_id, scan} = OTPResult.data.data.profile;
          const { already_exist} = OTPResult.data.data.measurement;
          const {done} = OTPResult.data.data.questionnaire;
          const profie_user = {
            profile_pic,
            user_name,
          };
        
          setLocalStoarge("noOfScan", scan);
          setLocalStoarge("user_id", user_id);
          setLocalStoarge("profile", JSON.stringify(profie_user));
          setLocalStoarge("measurment_data", already_exist);
          setLocalStoarge("questionnare", done);
          setLocalStoarge("langauage", language == 0 ? "it" : "en");
          setAlerts({
            alert: t("user.login.success"),
            alerttype: "Success",
            alertclass: "success",
            showalert: true,
          });
          if(done == 1){
            if(already_exist == 1){
              setIsLoading(false);
              setTimeout(() => {
                window.location.href = "/app/dashboard";
              }, 500);
            }else{
             const scanData = getQuestionairs();
             if(!scanData){
              setIsLoading(false);
              removeLocalStoarge();
              setAlerts({
                alert: t("user.login.server"),
                alerttype: "Error",
                alertclass: "danger",
                showalert: true,
              });
             }
            }    
          }else{
            setIsLoading(false);
            setTimeout(() => {
              window.location.href = "/app/steps";
            }, 500);
          }
        }
      } else if (OTPResult && OTPResult.status === 203) {
        setIsLoading(false);
        setAlerts({
          alert: t("user.login.otp-not-send"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
      }
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
      setAlerts({
        alert: t("user.login.server"),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
    }
  };


  const resendOtp = async () => {
    setAlerts({
      alert: t("user.login.please_wait"),
      alerttype: "Info",
      alertclass: "info",
      showalert: true,
    });
    setIsLoading(true);
    try {
      const payload = {
        email: email,
        role_id:"1"
      };
      const OTPResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_AUTH_RESEND_OTP}`,
        payload
      );
      if (OTPResult && OTPResult.status === 200) {
        if (OTPResult.data.code === 200) {
            setIsLoading(false);
            setAlerts({
              alert: t("user.login.otp-send-success"),
              alerttype: "Success",
              alertclass: "success",
              showalert: true,
            }); 
            setCounter(90)
            showalertMessage();
          }else{
            setIsLoading(false);
            window.localStorage.clear();
            setAlerts({
              alert: t("user.login.otp-not-send"),
              alerttype: "Error",
              alertclass: "danger",
              showalert: true,
            });
            showalertMessage();
            return false;
          }
      } else {
        setIsLoading(false);
        setAlerts({
          alert: t("user.login.otp-not-send"),
          alerttype: "Error",
          alertclass: "danger",
          showalert: true,
        });
        showalertMessage();
      }
    } catch (error) {
      setIsLoading(false);
      setAlerts({
        alert:t("user.login.server"),
        alerttype: "Error",
        alertclass: "danger",
        showalert: true,
      });
      showalertMessage();
    }
  };
  useEffect(() => {
    const authTwoFa = getLocalStoarge("user_email");
    const urlemail = (props.match.params?.email && props.match.params?.email!=="" ) ? props.match.params?.email : "";
    if ((authTwoFa === null || (authTwoFa===undefined)) && ((urlemail === null) || (urlemail === ""))) {
      window.location.href = "/";
    } else {
      setEmail((urlemail!=="") ? urlemail : authTwoFa);
    }
  }, []);

   // Timer 
   useEffect(() => {
      const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
   }, [counter]);

   const resetTimer = () =>{
      if(counter==0){
        resendOtp();
      }
   }

  return (
    <>
      <section className="bg_otp autenticazione otpipad d-flex align-items-center">
        <div className="container">
          <div className="row mx-mobile">
          <div className="auth">
              <div className="icon-aut">
                <img src={Group1018} className="key" alt="icon" />
                <h2 className="otp-heading">{t('user.Otp.Authentication')}</h2>
                <p dangerouslySetInnerHTML={{ __html: t('user.Otp.enter_otp1')}} ></p>
                <form method="POST" action="/action_page.php" className='cdn-center'>
                  <div className="inautho form-group">
                    {showalert ? (
                      <div className={`alert alert-${alertclass}`}>{alert}</div>
                    ) : (
                      <></>
                    )}
                    <OTPInput
                      className="otp-box"
                      value={OTP}
                      onChange={setOTP}
                      autoFocus OTPLength={6}
                      otpType="number"
                      disabled={false} />

                    <small id="emailHelp" className={"form-text2 otpline mb-3" +(counter==0 ? " torna cursor-p" : " ")} onClick={(e) => resetTimer()}>
                    {t('user.Otp.resend')} {counter>0?<span>{t('user.Otp.validation')}{counter}</span>:null} 
                    </small>
                  </div>
                  <br />
                </form>
                <button type="button" onClick={(e) => authTwoFaVerify(e)} className="buttons">{t('user.Otp.confirm')}</button>
                <Link to='/login'>
                  <div className="torna">
                    <img src={PREVIEWICONA} alt="icon" /> {t('user.Otp.back')}
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {(isLoading) ? <LazyLoader /> : null}

        </div>
      </section>
    </>
  )
}

export default OTPlogin