import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import DashboardImg from "../../assets/Image/dashboard-img.png";
import Group1744 from "../../assets/Image/Group1744.svg";
import weblink from "../../assets/Image/weblink.svg";
import TRAINING_COGNITIVO_ICONA from "../../assets/Image/TRAINING_COGNITIVO_ICONA.svg";
import GONOGO_ICONA from "../../assets/Image/GONOGO_ICONA.svg";
import Group696 from "../../assets/Image/Group696.svg";
import Group1670 from "../../assets/Image/Group1670.svg";
import NBACK_ICONA from "../../assets/Image/NBACK_ICONA.svg";
import Group1723 from "../../assets/Image/Group1723.svg";
import Group1409 from "../../assets/Image/Group1409.svg";
import MEDITAZIONE_ICONA from "../../assets/Image/MEDITAZIONE_ICONA.svg";
import FORMAZIONE_ICONA from "../../assets/Image/FORMAZIONE_ICONA.svg";
import Group959 from "../../assets/Image/Group959.svg";
import Group961 from "../../assets/Image/Group961.svg";
import Ellipse162 from "../../assets/Image/Ellipse162.svg";
import Group1729 from "../../assets/Image/Group1729.svg";
import Group1802 from "../../assets/Image/Group1802.svg";
import Group1754 from "../../assets/Image/Group1754.svg";
import Group1749 from "../../assets/Image/Group1749.svg";
import Group1753 from "../../assets/Image/Group1753.svg";
import GroupBig1749 from "../../assets/Image/GroupBig1749.svg";
import NEXT_ICONA from "../../assets/Image/medal/NEXT_ICONA.png";
import BACK_ICONA from "../../assets/Image/medal/bacik-arrow.png";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from "@ramonak/react-progress-bar";
import Modal from "../common/modal";
import Group769 from "../../assets/Image/Group769.svg";
import mindfullness from "../../assets/Image/mindfullness.svg";
import cognitive from "../../assets/Image/TRAINING COGNITIVO_ICONA.svg";
import wlicon from "../../assets/Image/medal/literacy-icon.png";
import medals1 from "../../assets/Image/medals1.svg"
import medals2 from "../../assets/Image/medals2.svg"
import medals3 from "../../assets/Image/medals3.svg"
import buon4 from "../../assets/Image/buon4.svg"
import espetr8 from "../../assets/Image/espetr8.svg"
import Group1746 from "../../assets/Image/Group1746.svg"
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import processImg from "../../assets/Image/cm-right-check.svg";
import brainWellness from "../../assets/Image/brain-wellness-icon.png";
import journalingImg from "../../assets/Image/journaling-icon.svg";
import serieImg from "../../assets/Image/serie-lunga-icon.svg";
import paroleImg from "../../assets/Image/parole-icon.svg";
import { getLocalStoarge } from "../common/allfunction";
import Chart from 'chart.js/auto'; // Import Chart.js library
import 'chartjs-adapter-date-fns'; // Import chart.js date-fns adapter if you're using dates in your chart
import GraphRecord from "./graphRecord"
import medalsalute1 from "../../assets/Image/medal/medal-buona-salute-1.png";
import medalsalute3 from "../../assets/Image/medal/medal-buona-salute-3.png";
import medalsalute6 from "../../assets/Image/medal/medal-buona-salute-6.png";
import medalsalute12 from "../../assets/Image/medal/medal-buona-salute-12.png";
import medalottima1 from "../../assets/Image/medal/medal-ottima-salute-1.png";
import medalottima3 from "../../assets/Image/medal/medal-ottima-salute-3.png";
import medalottima6 from "../../assets/Image/medal/medal-ottima-salute-6.png";
import medalottima12 from "../../assets/Image/medal/medal-ottima-salute-12.png";
import medalm1 from "../../assets/Image/medal/medal-m-1.png";
import medalm2 from "../../assets/Image/medal/medal-m-2.png";
import medalm3 from "../../assets/Image/medal/medal-m-3.png";
import medalt1 from "../../assets/Image/medal/medal-train-1.png";
import medalt2 from "../../assets/Image/medal/medal-train-2.png";
import medalt3 from "../../assets/Image/medal/medal-train-3.png";
/** wellbeing medal icon  */
import medalwl1 from "../../assets/Image/medal/medal-wl-1.png";
import medalwl2 from "../../assets/Image/medal/medal-wl-2.png";
import medalwl3 from "../../assets/Image/medal/medal-wl-3.png";
import medalwl11 from "../../assets/Image/medal/medalwl11.png";
import medalwl21 from "../../assets/Image/medal/medalwl21.png";
import medalwl12 from "../../assets/Image/medal/medalwl12.png";
import medalwl22 from "../../assets/Image/medal/medalwl22.png";
import medalwl13 from "../../assets/Image/medal/medalwl13.png";
import medalwl23 from "../../assets/Image/medal/medalwl23.png";

import medalwl111 from "../../assets/Image/medal/medalwl-11.png";
import medalwl211 from "../../assets/Image/medal/medalwl-21.png";
import medalwl121 from "../../assets/Image/medal/medalwl-12.png";
import medalwl221 from "../../assets/Image/medal/medalwl-22.png";
import medalwl131 from "../../assets/Image/medal/medalwl-13.png";
import medalwl231 from "../../assets/Image/medal/medalwl-23.png";

/** end */
import medalwl from "../../assets/Image/medal/wellbeing-in-crescita.png";
import medalril from "../../assets/Image/medal/rilassato.png";
import backArrow from "../../assets/Image/medal/bacik-arrow.png";
import MinfullAndCognitive from "./minfullAndCognitive";
import modelWellbeing from "../../assets/Image/medal-detail/wellbeing-icon.png";
import medalList from "./medalList";
import {
	Stepper,
	Step,
	useStepper,
	StepNumber,
	StepTitle,
	StepStatus,
	StepDescription,
} from "react-progress-stepper";
import { isMobile } from "react-device-detect";
import Filter from "./ui/filter";

const customStyles = {
	baseBgColor: "#9ECB61" // Replace with your desired background color for progress lin bar
};
const ProgressBarStyle = {
	bgColor: '#9ECB61'
}

ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);
function BrainWellness(props) {
	const [selectedImage, setSelectedImage] = useState('medalList');
	const [medalModalState, setMedalModalState] = useState(false);
	const [medalWLState, setMedalWLState] = useState(false);
	const [cMModalState, setCMModalState] = useState(false);
	const [medalWState, setMedalWState] = useState(false);
	const { step, incrementStep, decrementStep } = useStepper(4, 8);
	const [t, i18n] = useTranslation("common");
	let lang = getLocalStoarge("langauage");
	const [weekDay, setWeekDay] = useState(['MON', 'TUE', 'WED', 'THU', 'FRI']);
	const labels = lang == 'it' ? ['ECCELLENTE', 'BUONO', 'SUFFICIENTE', 'SCARSO', 'MOLTO SCARSO'] : ['EXCELLENT', 'GOOD', 'SUFFICIENT', 'POOR', 'VERY POOR'];
	const { healthRecord, wellbeingRecord, brainWelnessRecord, trainingModule, brainwellnessModule, cognitiveFilter, mindfulFilter } = props;
	const [radioBtnRecord, setRadioBtnRecord] = useState({ graphRadio: 's', traningRadio: "G", mindfullRadio: "G" });
	const totalScan = 5;
	const monthLowScore = 60;
	const monthHighScore = 80;
	const [wbrColor, setWbrColor] = useState();
	const [mindColor, setMindColor] = useState();
	const [cognitiveColor, setCogColor] = useState();
	const [medalDetail, getMedalDetail] = useState();
	useEffect(() => {
		console.log('medal list ', medalList[lang]);
		if (lang == 'it') {
			setWeekDay(['LUN', 'MAR', 'MER', 'GIO', 'VEN']);
			// setLabels(['ECCELLENTE', 'BUONO', 'SUFFICIENTE', 'SCARSO', 'MOLTO SCARSO'])

		}
		if (wellbeingRecord.coursePercentage <= 40) {
			setWbrColor("#B02418");
		} else if (wellbeingRecord.coursePercentage <= 60) {
			setWbrColor("#F6BF42");
		} else if (wellbeingRecord.coursePercentage <= 70) {
			setWbrColor("#F9EF96");
		} else if (wellbeingRecord.coursePercentage <= 80) {
			setWbrColor("#9ECB61");
		}
		else {
			setWbrColor("#367B21");
		}

	}, [])
	const callBack = (data) => {
		props.callBack(data);
	}
	const handleImageClick = (imageType) => {
		if (imageType == 'MEDITATORE') {

		}
		setSelectedImage(imageType);
	};
	const handleMedalClick = (id) => {
		if(id > 17 && id <21){
			let icon = '';
			if(id==18){
				icon = wellbeingRecord.monthly == 2 ? medalwl211 : medalwl111;
			}
			else if(id==19){
			icon = wellbeingRecord.halfYearly == 2 ? medalwl221 : medalwl121;
			}
			else if(id==20){
				icon = wellbeingRecord.yearly == 2 ? medalwl231 : medalwl131;
			}
			medalList[lang][id].icon =icon;
		}

		
		getMedalDetail(medalList[lang][id])
		
		setSelectedImage('MEDITATORE');
	}
	const handleMedalPopup = () => {
		setMedalModalState(true);
	};

	const handleHomePage = () => {
		setMedalModalState(true);

	}
	let [barChartData, setBarChartData] = useState({
		labels: lang == 'it' ? ['LUN', 'MAR', 'MER', 'GIO', 'VEN'] : ['MON', 'TUE', 'WED', 'THU', 'FRI'],
		datasets: [
			{
				label: labels[0],
				data: [50, 0, 0, 0, 0],
				backgroundColor: '#367B21',
				categoryPercentage: 1,
				barPercentage: 1,
				borderRadius: 1,
				borderWidth: 1,
				borderSkipped: false
			},
			{
				label: labels[1],
				data: [0, 36, 0, 0, 0],
				backgroundColor: '#9ECB61',
				categoryPercentage: 1,
				barPercentage: 1,
				borderRadius: 1,
				borderWidth: 1,
				borderSkipped: false
			},
			{
				label: labels[2],
				data: [50, 0, 74, 0, 0],
				backgroundColor: '#F9EF96',
				categoryPercentage: 1,
				barPercentage: 1,
				borderRadius: 1,
				borderWidth: 1,
				borderSkipped: false
			},
			{
				label: labels[3],
				data: [0, 0, 0, 17, 0],
				backgroundColor: '#F6BF42',
				categoryPercentage: 1,
				barPercentage: 1,
				borderRadius: 1,
				borderWidth: 1,
				borderSkipped: false
			},
			{
				label: labels[4],
				data: [0, 0, 0, 0, 66],
				backgroundColor: '#B02418',
				categoryPercentage: 1,
				barPercentage: 1,
				borderRadius: 1,
				borderWidth: 1,
				borderSkipped: false
			},
		],
	})
	let barChartOptions = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
				align: 'end',
				labels: {
					usePointStyle: true,
					pointStyle: 'circle',
					font: {
						size: 10
					}

				}
			},
			title: {
				display: false,
				//text: 'Chart.js Bar Chart',
			},
		},
		scales: {
			x: {
				grid: {
					display: true
				}
			},
			y: {
				max: 90,
				min: 0,
				ticks: {
					stepSize: 10
				}
			}
		}
	};
	const pieData = {
		labels: ['', '',],
		datasets: [
			{
				label: '# of Votes',
				data: [12, 19],
				backgroundColor: [
					'#F6BF42',
					'#ebf0ff'
				],
			},
		],
	};
	let PieChartOptions = {
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
				//text: 'Chart.js Bar Chart',
			},
		},

	};
	const closeModal = () => {
		setMedalModalState(false);
		setMedalWLState(false);
		setMedalWState(false);
		setCMModalState(false)
	}
	/**
	 * 
	 * Bar graph 
	 */
	const paddingOptions = {
		desktop: {
			left: 0,
			right: 0,
			top: 10,
			bottom: 0
		},
		mobile: {
			left: 0,
			right: 0,
			top: 50,
			bottom: 0
		}
	};
	const [graphData, setGraphData] = useState([]);
	const bgColor = ["#367B21", "#9ECB61", "#F9EF96", "#F6BF42", "#B02418"];
	const [mindfulMin, setMindfullMin] = useState(mindfulFilter);
	const [cognitiveProgress, setCognitiveProgress] = useState(cognitiveFilter);
	useEffect(() => {
		const data = [];
		if (radioBtnRecord.graphRadio == 's') {
			// setGraphData(healthRecord.weeklyWellBeingIndex);
			Object.keys(healthRecord.weeklyWellBeingIndex).map((element) => {
				data.push(healthRecord.weeklyWellBeingIndex[element]);
			});
		}
		else if (radioBtnRecord.graphRadio == 'm') {
			// setGraphData(healthRecord.monthlyWellBeingIndex);
			Object.keys(healthRecord.monthlyWellBeingIndex).map((element) => {
				data.push(element);
			});
		}
		// console.log('data setgraphdata - ', data)
		setGraphData(data);
		minfullnessData();
		cognitiveData();
		// console.log('cognitiveColor - ', cognitiveColor);


	}, ['', radioBtnRecord, cognitiveFilter]);
	const minfullnessData = () => {
		// console.log('mindfullRadio ', radioBtnRecord.mindfullRadio);
		let progress = mindfulFilter;
		// if (radioBtnRecord.mindfullRadio == 'G') {
		// 	progress = brainWelnessRecord.todayMindFullnessProgress;
		// }
		// else if (radioBtnRecord.mindfullRadio == 'S') {
		// 	progress = brainWelnessRecord.weeklyMindFullnessProgress;
		// }
		// else if (radioBtnRecord.mindfullRadio == 'M') {
		// 	progress = brainWelnessRecord.monthlyMindFullnessProgress;
		// }
		// else {
		// 	progress = brainWelnessRecord.todayMindFullnessProgress;
		// }
		setMindfullMin(progress)
		if (progress < 15) {
			setMindColor("#F6BF42");
		} else if (progress < 50) {
			setMindColor("#F6BF42");
		}
		else if (progress >= 50) {
			setMindColor("#9ECB61");
		}
		else {
			setMindColor("#F6BF42");
		}
	}
	const cognitiveData = () => {
		console.log('mindfullRadio ', cognitiveFilter);
		let progress = cognitiveFilter;
		// if (radioBtnRecord.traningRadio == 'G') {
		// 	progress = brainWelnessRecord.todayCognitiveProgress;
		// }
		// else if (radioBtnRecord.traningRadio == 'S') {
		// 	progress = brainWelnessRecord.weeklyCognitiveProgress;
		// }
		// else if (radioBtnRecord.traningRadio == 'M') {
		// 	progress = brainWelnessRecord.monthlyCognitiveProgress;
		// }
		// else {
		// 	progress = cognitiveFilter;
		// }
		setCognitiveProgress(progress)
		if (progress < 15) {
			setCogColor("#F6BF42");
		} else if (progress < 50) {
			setCogColor("#F6BF42");
		}
		else if (progress > 50) {
			setCogColor("#9ECB61");
		}
		else {
			setCogColor("#F6BF42");
		}

	}
	const [record, setRecord] = useState(1);
	const nextRecordFn = (rec) => {
		if (record == 3) {
			setRecord(1);
		}
		else {
			setRecord(rec + 1);
		}
		console.log('rec ', rec);

	}
	const prevtRecordFn = (rec) => {
		
			setRecord(rec - 1);
		

	}
	
	const wellnessMedalModal = () => {
		return (
			<div className="model-pop-new">
				<div className="model-pop ">
					<div className="modal-header">
						<h5 className="modal-title pop_heading" id="exampleModalLabel">
							<img src={mindfullness} alt="icon" />
							<strong className="ml-3">{t('user.common_dashboard.right_section.popup.h1')}</strong>
						</h5>
						<button
							type="button"
							className="close r0-side"
							onClick={() => closeModal()}
						>
							<img src={Group769} alt="icon" />
						</button>
					</div>

					<div className="modal-body">
						<div className="medal-modal-body">
							<div className="row">
								<div className='col-md-12'>
									<div className="medagliemindfulness">
										<div className="settimana-1">
											<img width="64" src={Group1749} alt="Step 1" />
											<p>{ t('user.common_dashboard.right_section.settimana') } 1</p>
										</div>
										<div className="settimana-1">
											<img width="64" src={Group1749} alt="Step 2" />
											<p>{ t('user.common_dashboard.right_section.settimana') } 2</p></div>
										<div className="settimana-1">
											<img width="64" src={Group1749} alt="Step 3" />
											<p>{ t('user.common_dashboard.right_section.settimana') } 3</p></div>
										<div className="settimana-1">
											<div className="double-icon-keep">
												<img width="64" src={medalm2} alt="Step 4" style={{ opacity: 0.5 }} />
											</div>
											<img width="64" src={Group1749} alt="Step 4" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 4</p>
										</div>
										<div className="settimana-1">
											<img width="64" src={Group1749} alt="Step 5" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 5</p></div>
										<div className="settimana-1">
											<img width="64" src={Group1749} alt="Step 6" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 6</p></div>
										<div className="settimana-1">
											<img width="64" src={Group1749} alt="Step 7" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 7</p></div>

										<div className="settimana-1">
											<div className="double-icon-keep">
												<img width="64" src={medalm3} alt="Step 4" style={{ opacity: 0.5 }} />
											</div>
											<img width="64" src={Group1749} alt="Step 8" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 8</p></div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className='col-md-12 pl-5 mb-3'>
									<div className='medal-heading'>
										<h6>{t('user.common_dashboard.right_section.popup.sh1')}</h6>
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medals1} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.mindful_txt1")}</h6>
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.cogDtxt1') }} ></p>
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medals2} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.mindful_txt2")}</h6>
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.cogDtxt2') }} ></p>
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medals3} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.mindful_txt3")}</h6>
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.cogDtxt3') }} ></p>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	const cognitiveMedalModal = () => {
		return (
			<div className="model-pop-new">
				<div className="model-pop ">
					<div className="modal-header">
						<h5 className="modal-title pop_heading" id="exampleModalLabel">
							<img src={cognitive} alt="icon" />
							<strong className="ml-3">{t('user.common_dashboard.right_section.popup.h2')}</strong>
						</h5>
						<button
							type="button"
							className="close r0-side"
							onClick={() => closeModal()}
						>
							<img src={Group769} alt="icon" />
						</button>
					</div>

					<div className="modal-body">
						<div className="medal-modal-body">
							<div className="row">
								<div className='col-md-12'>
									<div className="medagliemindfulness">
										<div className="settimana-1">
											<img width="64" src={medalt1} alt="Step 1" />
											<p>{ t('user.common_dashboard.right_section.settimana') } 1</p>
										</div>
										<div className="settimana-1">
											<img width="64" src={medalt1} alt="Step 2" />
											<p>{ t('user.common_dashboard.right_section.settimana') } 2</p></div>
										<div className="settimana-1">
											<img width="64" src={medalt1} alt="Step 3" />
											<p>{ t('user.common_dashboard.right_section.settimana') } 3</p></div>
										<div className="settimana-1">
											<div className="double-icon-keep">
												<img width="64" src={medalt2} alt="Step 4" style={{ opacity: 0.5 }} />
											</div>
											<img width="64" src={medalt1} alt="Step 4" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 4</p>
										</div>
										<div className="settimana-1">
											<img width="64" src={medalt1} alt="Step 5" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 5</p></div>
										<div className="settimana-1">
											<img width="64" src={medalt1} alt="Step 6" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 6</p></div>
										<div className="settimana-1">
											<img width="64" src={medalt1} alt="Step 7" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 7</p></div>

										<div className="settimana-1">
											<div className="double-icon-keep">
												<img width="64" src={medalt3} alt="Step 4" style={{ opacity: 0.5 }} />
											</div>
											<img width="64" src={medalt1} alt="Step 8" style={{ opacity: 0.5 }} />
											<p>{ t('user.common_dashboard.right_section.settimana') } 8</p></div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className='col-md-12 pl-5 mb-3'>
									<div className='medal-heading'>
										<h6>{t('user.common_dashboard.right_section.popup.sh2')}</h6>
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medalt1} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.cog_txt1")}</h6>
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.cogDtxt1') }} ></p>
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medalt2} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.cog_txt2")}</h6>
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.cogDtxt2') }} ></p>
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medalt3} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.cog_txt3")}</h6>
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.cogDtxt3') }} ></p>

									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	const wellbeingLiteracyModal = () => {
		return (
			<div className="model-pop-new">
				<div className="model-pop ">
					<div className="modal-header">
						<h5 className="modal-title pop_heading" id="exampleModalLabel">
							<img src={wlicon} alt="icon" />
							<strong className="ml-3">{t('user.common_dashboard.right_section.popup.h3')}</strong>
						</h5>
						<button
							type="button"
							className="close r0-side"
							onClick={() => closeModal()}
						>
							<img src={Group769} alt="icon" />
						</button>
					</div>

					<div className="modal-body">
						<div className="medal-modal-body">
							<div className="row">
								<div className='col-md-12'>
									<div className="medaglie-wellbeing-lit">
										<div className="settimana-1">
											<img width="64" src={medalwl11} alt="Step 7" style={{ opacity: 1 }} />
											<p>programma</p>
										</div>
										<div className="settimana-1">
											<img width="64" src={medalwl12} alt="Step 7" style={{ opacity: 1 }} />
											{/* <p>settimana 7</p> */}
										</div>
										<div className="settimana-1">

											<img width="64" src={medalwl13} alt="Step 4" style={{ opacity: 1 }} />

											{/* <p>settimana 8</p> */}
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className='col-md-12 pl-5 mb-3'>
									<div className='medal-heading'>
										<h6>{t('user.common_dashboard.right_section.popup.sh3')}</h6>
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medalwl11} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.wellbeing_txt1")}</h6>
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wl1') }} ></p>
										
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medalwl12} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.wellbeing_txt2")}</h6>
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wl2') }} ></p>
										
									</div>
								</div>
								<div className='col-md-4 text-center'>
									<div className='assiduoMedalText pl-5 pr-5 pt-2 pb-2'>
										<img src={medalwl13} alt='icon' />
										<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.wellbeing_txt3")}</h6>
										
										<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wl3') }} ></p>
									</div>
								</div>

							</div>
							<div className="row justify-content-end">
								<div className="col-sm-6">
									<div className="alert alert-cs-info text-uppercase">
										{t('user.common_dashboard.right_section.wltNote')}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	const wellbeingModal = () => {
		return (
			<div className="model-pop-new">
				<div className="model-pop ">
					<div className="modal-header">
						<h5 className="modal-title pop_heading" id="exampleModalLabel">
							<img src={modelWellbeing} alt="icon" />
							<strong className="ml-3">{t('user.common_dashboard.right_section.popup.h4')} </strong>
						</h5>
						<button
							type="button"
							className="close r0-side"
							onClick={() => closeModal()}
						>
							<img src={Group769} alt="icon" />
						</button>
					</div>

					<div className="modal-body">
						<div className="medal-modal-body">
							<div id="salute" style={{ display: record == 1 ? 'block' : 'none' }}>
								<div className="row">
									<div className='col-md-12'>
										<div className="medaglie-wellbeing">
											<div className="settimana-1">
												<img width="64" src={medalsalute1} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.mese")} </p>
											</div>
											<div className="settimana-1">
												<img width="64" src={medalsalute3} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.trimestre")} </p>
											</div>
											<div className="settimana-1">
												<img width="64" src={medalsalute6} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.semestre")} </p>
											</div>
											<div className="settimana-1">

												<img width="64" src={medalsalute12} alt="Step 4" style={{ opacity: 1 }} />

												<p class="text-uppercase">{t("user.common_dashboard.right_section.anno")} </p>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className='col-md-12 pl-5 mb-3'>
										<div className='medal-heading'>
											<h6>{t('user.common_dashboard.right_section.popup.sh4')}</h6>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalsalute1} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.graph2")} {t("user.common_dashboard.right_section.nel")} {t("user.common_dashboard.right_section.mese")}</h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt1') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalsalute3} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.graph2")} {t("user.common_dashboard.right_section.nel")} {t("user.common_dashboard.right_section.trimestre")}</h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt2') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalsalute6} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.graph2")} {t("user.common_dashboard.right_section.nel")} {t("user.common_dashboard.right_section.semestre")}</h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt3') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalsalute12} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.wtextnel2")}</h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt4') }} ></p>
										</div>
									</div>
								</div>
							</div>
							<div id="ottima" style={{ display: record == 2 ? 'block' : 'none' }}>
								<div className="row">
									<div className='col-md-12'>
										<div className="medaglie-wellbeing">
											<div className="settimana-1">
												<img width="64" src={medalottima1} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.mese")} </p>
											</div>
											<div className="settimana-1">
												<img width="64" src={medalottima3} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.trimestre")} </p>
											</div>
											<div className="settimana-1">
												<img width="64" src={medalottima6} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.semestre")} </p>
											</div>
											<div className="settimana-1">

												<img width="64" src={medalottima12} alt="Step 4" style={{ opacity: 1 }} />

												<p class="text-uppercase">{t("user.common_dashboard.right_section.anno")} </p>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className='col-md-12 pl-5 mb-3'>
										<div className='medal-heading'>
											<h6>{t('user.common_dashboard.right_section.popup.sh4')}</h6>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalottima1} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.graph3")} {t("user.common_dashboard.right_section.nel")} {t("user.common_dashboard.right_section.mese")}</h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt21') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalottima3} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.graph3")} {t("user.common_dashboard.right_section.nel")} {t("user.common_dashboard.right_section.trimestre")}</h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt22') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalottima6} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.graph3")} {t("user.common_dashboard.right_section.nel")} {t("user.common_dashboard.right_section.semestre")}</h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt23') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalottima12} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.wtextnel")}</h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt24') }} ></p>
										</div>
									</div>
								</div>
							</div>
							<div id="mis" style={{ display: record == 3 ? 'block' : 'none' }}>
								<div className="row">
									<div className='col-md-12'>
										<div className="medaglie-wellbeing1">
											<div className="settimana-1">
												<img width="64" src={Group1744} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.mis")} </p>
											</div>
											<div className="settimana-1">
												<img width="64" src={medalril} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.stress")} </p>
											</div>
											<div className="settimana-1">
												<img width="64" src={Group1754} alt="Step 7" style={{ opacity: 1 }} />
												<p class="text-uppercase">{t("user.common_dashboard.right_section.massa")} </p>
											</div>
											<div className="settimana-1">

												<img width="64" src={medalwl} alt="Step 4" style={{ opacity: 1 }} />

												<p class="text-uppercase">{t("user.common_dashboard.right_section.indice")} </p>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className='col-md-12 pl-5 mb-3'>
										<div className='medal-heading'>
											<h6>{t('user.common_dashboard.right_section.popup.sh4')}</h6>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={Group1744} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.graph1")} </h6>

											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt31') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalril} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.rel1")} </h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt32') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={Group1754} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.rel2")} </h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt33') }} ></p>
										</div>
									</div>
									<div className='col-md-3 text-center'>
										<div className='assiduoWellbeing pt-2 pb-2'>
											<img src={medalwl} alt='icon' />
											<h6 className="font-weight-light text-uppercase">{t("user.common_dashboard.right_section.rel3")} </h6>
											<p dangerouslySetInnerHTML={{ __html: t('user.common_dashboard.right_section.wtxt34') }} ></p>
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-center">
						
								<ul className="d-flex medal-pagination">
									{
										(record>1)?
											<li onClick={() => prevtRecordFn(record)}> <img src={BACK_ICONA} /></li>
										:null
									}
									<li className={record == 1 ? "active" : ""}>1</li>
									<li className={record == 2 ? "active" : ""}>2</li>
									<li className={record == 3 ? "active" : ""}>3</li>
									{
										(record<3)?
											<li onClick={() => nextRecordFn(record)}> <img src={NEXT_ICONA} /></li>
										:null 
									}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	const graphEnable = (event) => {
		let { name, value } = event.target;
		if (name == 'status') value = Number(value)
		setRadioBtnRecord({ ...radioBtnRecord, [name]: value })

	}

	const handleMindfull = () => {
		// alert('mindfulness');
		handleImageClick("MEDITATORE");
	}
	return (
		<>
			{/* Add content for your brain wellness page */}
			<Modal title="" size="lg" contentClasses="pop-minfullness" onClose={() => setMedalModalState(false)} show={medalModalState}>
				{wellnessMedalModal()}
			</Modal>
			<Modal title="" size="lg" contentClasses="pop-minfullness" onClose={() => setCMModalState(false)} show={cMModalState}>
				{cognitiveMedalModal()}
			</Modal>
			<Modal title="" size="lg" contentClasses="pop-minfullness" onClose={() => setMedalWLState(false)} show={medalWLState}>
				{wellbeingLiteracyModal()}
			</Modal>

			<Modal title="" size="lg" contentClasses="pop-minfullness" onClose={() => setMedalWState(false)} show={medalWState}>
				{wellbeingModal()}
			</Modal>

			<div className='wraaper-brain-wellness'>
				<div className="main-body-brain">
					<div className="row pr-2">
						<div className="col-md-8">
							<div className='wellbeing'> </div>
							<h3 className='brain-wellnes-heading'><img src={weblink} alt='icon' /><span>{t("user.common_dashboard.page_heading")}</span></h3>
						</div>

						<div className="col-md-4 d-none d-sm-block">
							<h3 className='brain-wellnes-heading mt-2'>{t("user.common_dashboard.right_section.section_heading")}</h3>
						</div>

						<div className="col-md-8">
							<div className="row">
								<div className="col-md-12 d-none">
									<div className='wellbeing'> </div>
									<h3 className='brain-wellnes-heading'><img src={weblink} alt='icon' /><span>{t("user.common_dashboard.page_heading")}</span></h3>
								</div>
								{/* <div className="col-md-2">
									<div className="right-s wellness">
										<p>{props.healthScore}<span className="green line"></span>
										</p>
									</div>
								</div> */}
								<div className="col-md-12">
									<div className="wellness p-1 p-sm-3">
										{/* <img src={DashboardImg} alt='icon'/> */}
										<div className="common-switch-field mt-4 mt-sm-0 ml-4">
											<input type="radio" id="radio-one" name="graphRadio" value="s" checked={radioBtnRecord?.graphRadio == 's'} onChange={(e) => graphEnable(e)} />
											<label htmlFor="radio-one">{t("user.common_dashboard.right_section.s")}</label>
											<input type="radio" id="radio-two" name="graphRadio" value="m" checked={radioBtnRecord?.graphRadio == 'm'} onChange={(e) => graphEnable(e)} />
											<label htmlFor="radio-two">{t("user.common_dashboard.right_section.m")}</label>
										</div>
										{/* <Bar options={barChartOptions} data={barChartData} />*/}
										{radioBtnRecord ?
											<GraphRecord healthData={healthRecord} btnChange={radioBtnRecord.graphRadio} />
											: null
										}
									</div>
								</div>
								{(brainwellnessModule == 1) ?
									<>
										<div className="col-md-12">
											<h3 className='brain-wellnes-heading mt-2 training_cognitivo'>
												<img src={brainWellness} alt="" />
												<span>{t("user.dashboard.brain_wellness")}</span>
											</h3>
										</div>
										<div className="col-md-12 col-lg-6 d-flex">
											{/* first element */}
											<div className="bg-boxes">
												<div className="Fisiologici">
													<div className="right-s GONOGO_ICONA"><h4><img src={TRAINING_COGNITIVO_ICONA} alt="icon" /><span>{t("user.sidebar.cognitive_training")}</span></h4></div>
													<Filter callBack={callBack} prefix={'cognitive'} />
													{/* <div className="right-part gonogo_right"> */}
													{/* <img src={Group696} alt="icon" /> */}
													{/* <div className="common-switch-field">
															<input type="radio" id="radio-three" name="traningRadio" value="G" checked={radioBtnRecord?.traningRadio == "G"} onChange={(e) => graphEnable(e)} />
															<label htmlFor="radio-three">{t("user.common_dashboard.right_section.g")}</label>
															<input type="radio" id="radio-four" name="traningRadio" value="S" checked={radioBtnRecord?.traningRadio == "S"} onChange={(e) => graphEnable(e)} />
															<label htmlFor="radio-four">{t("user.common_dashboard.right_section.s")}</label>
															<input type="radio" id="radio-five" name="traningRadio" value="M" checked={radioBtnRecord?.traningRadio == "M"} onChange={(e) => graphEnable(e)} />
															<label htmlFor="radio-five">{t("user.common_dashboard.right_section.m")}</label>
														</div>
													</div> */}
												</div>

												<div className="right-s variable brainwellness-circular">
													<div className="gonogo-chart">
														<CircularProgressbar value={(cognitiveProgress ? cognitiveProgress : cognitiveProgress)} maxValue={100} strokeWidth={15}
															styles={{
																path: {
																	stroke: cognitiveColor
																}
															}
															}
														/>
													</div>
													<div className="d-flex">
														<div>
															<p className='mb-0 pera-datas'>{cognitiveProgress ? cognitiveProgress : cognitiveProgress}<sub>%</sub></p>
															<span className='gonogo-text text-uppercase cognitive-training-text'>{t("user.common_dashboard.brain_wellness.heading_1")}</span>
														</div>
													</div>
												</div>
											</div>

											{/* first element close */}
										</div>
										<div className="col-md-12 col-lg-6 d-flex">
											<div className="bg-boxes">
												<div className="Fisiologici">
													<div className="right-s GONOGO_ICONA"><h4><img src={MEDITAZIONE_ICONA} alt="icon" /><span>{t("user.sidebar.mindfulness")}</span></h4></div>
													<Filter callBack={callBack} prefix={'mindfulness'} />
													{/* <div className="right-part gonogo_right"> */}
													{/* <img src={Group696} alt="icon" /> */}
													{/* <div className="common-switch-field">
															<input type="radio" id="radio-six" name="mindfullRadio" value="G" checked={radioBtnRecord?.mindfullRadio == "G"} onChange={(e) => graphEnable(e)} />
															<label htmlFor="radio-six">{t("user.common_dashboard.right_section.g")}</label>
															<input type="radio" id="radio-seven" name="mindfullRadio" value="S" checked={radioBtnRecord?.mindfullRadio == "S"} onChange={(e) => graphEnable(e)} />
															<label htmlFor="radio-seven">{t("user.common_dashboard.right_section.s")}</label>
															<input type="radio" id="radio-eight" name="mindfullRadio" value="M" checked={radioBtnRecord?.mindfullRadio == "M"} onChange={(e) => graphEnable(e)} />
															<label htmlFor="radio-eight">{t("user.common_dashboard.right_section.m")}</label>
														</div>
													</div> */}
												</div>
												<div className="right-s variable brainwellness-circular">
													<div className="gonogo-chart">
														<CircularProgressbar
															value={mindfulFilter ? mindfulFilter : brainWelnessRecord.todayMindFullnessProgress}
															maxValue={60}
															strokeWidth={50}
															styles={buildStyles({
																strokeLinecap: "butt",
																pathColor: mindColor,
															})}
														/>
													</div>
													<div>
														<p className='mb-0 pera-datas'>{mindfulFilter ? mindfulFilter : brainWelnessRecord.todayMindFullnessProgress}<sub>min</sub>  </p>
														<span className='gonogo-text text-uppercase cognitive-training-text'>{t("user.common_dashboard.brain_wellness.heading_2")}</span>
													</div>
												</div>
											</div>
										</div>
									</>
									: null}
								{/* Wellbeing Literacy Section Start */}
								{(trainingModule == 1) ?
									<>
										<div className="col-md-12">
											{/* <h3 className='brain-wellnes-heading mt-2'>
											<img src={journalingImg} alt="icon" />
											 Wellbeing Literacy</h3> */}

											<h3 className='brain-wellnes-heading mt-2 training_cognitivo'>
												<img src={FORMAZIONE_ICONA} alt="" />
												<span>{t("user.dashboard.wellbeing_literacy")}</span>
											</h3>
										</div>
										<div className="col-md-12 ">
											<div className="bg-boxes d-sm-flex">
												<div className="col-sm-6">
													<div className="Fisiologici">
														<div className="right-part gonogo_right">
														</div>
													</div>
													<div className="right-s pb-3">
														<div>
															<p className='mb-0 '>{wellbeingRecord?.completeCourses ? wellbeingRecord.completeCourses : 0}/{wellbeingRecord?.totalCourses ? wellbeingRecord.totalCourses : 0}<sub></sub>  </p>
														</div>
														<ProgressBar completed={wellbeingRecord?.completeCourses ? wellbeingRecord.completeCourses : 0} maxCompleted={wellbeingRecord?.totalCourses ? wellbeingRecord.totalCourses : 1} height={"7px"} bgColor={ProgressBarStyle.bgColor} labelClassName="label-" />
													</div>
												</div>
												<div className="col-sm-6">
													<div className="Fisiologici">
														<div className="right-part gonogo_right">
														</div>
													</div>
													<div className="right-s variable brainwellness-circular">
														<div className="gonogo-chart">

															<CircularProgressbar
																value={wellbeingRecord?.testPercentage ? wellbeingRecord.testPercentage : 0}
																maxValue={100}
																strokeWidth={15}
																styles={{
																	path: {
																		stroke: wbrColor
																	}
																}}
															/>

														</div>
														<div>
															<p className='mb-0 pera-datas'>{wellbeingRecord?.testPercentage ? wellbeingRecord?.testPercentage : 0}<sub>%</sub></p>
															<span className='gonogo-text text-uppercase cognitive-training-text'>{t("user.common_dashboard.training.heading_1")}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>

									: null}
								{/* Wellbeing Literacy Section End */}

								<div className="col-md-12 d-none">
									<h3 className='brain-wellnes-heading mt-2'><img src={journalingImg} alt="icon" /> Journaling</h3>

								</div>

								<div className="col-md-12 col-lg-6 d-none">
									<div className="bg-boxes">
										<div className="Fisiologici">
											<div className="right-s GONOGO_ICONA"><h4><img src={serieImg} alt="icon" /><span>{t("user.common_dashboard.journal.heading_1")}</span></h4></div>
											<div className="right-part gonogo_right">
												{/* <img src={Group696} alt="icon" /> */}
												<div className="common-switch-field">
													<input type="radio" id="radio-nine" name="languageType-7" value="S" checked={true} />
													<label htmlFor="radio-nine">G</label>
													<input type="radio" id="radio-ten" name="languageType-7" value="S" checked={false} />
													<label htmlFor="radio-ten">S</label>
													<input type="radio" id="radio-eleven" name="languageType-7" value="H" checked={false} />
													<label htmlFor="radio-eleven">M</label>
												</div>
											</div>
										</div>
										<div className="right-s pb-3">
											<div>
												<p className='mb-0'>{wellbeingRecord.completeCourses}/{wellbeingRecord.totalCourses}<sub></sub>  </p>
											</div>
											<ProgressBar completed={wellbeingRecord.completeCourses} maxCompleted={wellbeingRecord.totalCourses} height={"7px"} bgColor={ProgressBarStyle.bgColor} labelClassName="label-" />
										</div>


									</div>


								</div>
								<div className="col-md-12 col-lg-6 d-none">
									<div className="bg-boxes">
										<div className="Fisiologici">
											<div className="right-s GONOGO_ICONA"><h4><img src={paroleImg} alt="icon" /><span>{t("user.common_dashboard.journal.heading_2")}</span></h4></div>
											<div className="right-part gonogo_right">
												{/* <img src={Group696} alt="icon" /> */}
												<div className="common-switch-field">
													<input type="radio" id="radio-six" name="languageType-3" value="S" checked={true} />
													<label htmlFor="radio-six">G</label>
													<input type="radio" id="radio-seven" name="languageType-3" value="S" checked={false} />
													<label htmlFor="radio-seven">S</label>
													<input type="radio" id="radio-eight" name="languageType-3" value="H" checked={false} />
													<label htmlFor="radio-eight">M</label>
												</div>
											</div>
										</div>
										<div className="right-s variable brainwellness-circular">

											<div>
												<p className='mb-0'>7500<sub></sub>  </p>
												<span className='gonogo-text'>{t("user.common_dashboard.journal.heading_3")}</span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12 d-none">
									<h3 className='brain-wellnes-heading mt-2'><img src={FORMAZIONE_ICONA} alt="icon" /> {t("user.sidebar.training")}</h3>
								</div>
								<div className="col-md-12 col-lg-6 d-none">
									<div className="bg-boxes">
										<div className="Fisiologici">
											<div className="right-s GONOGO_ICONA"><h4><img src={processImg} alt="icon" /><span>{t("superadmin.training.programs")}</span></h4></div>
											<div className="right-part gonogo_right">
												{/* <img src={Group696} alt="icon" /> */}
												<div className="common-switch-field">
													<input type="radio" id="radio-nine" name="languageType-5" value="S" checked={true} />
													<label htmlFor="radio-nine">G</label>
													<input type="radio" id="radio-ten" name="languageType-5" value="S" checked={false} />
													<label htmlFor="radio-ten">S</label>
													<input type="radio" id="radio-eleven" name="languageType-5" value="H" checked={false} />
													<label htmlFor="radio-eleven">M</label>
												</div>
											</div>
										</div>
										<div className="right-s variable">
											<div className="gonogo-chart program-text">
												1/2
											</div>

											<div>
												{/* <span class="light-yellow line program-line"></span> */}
											</div>
											<span className='gonogo-text program-text2'>completati</span>


										</div>
										<ProgressBar completed={1} maxCompleted={2} height={"7px"} bgColor={customStyles.baseBgColor} labelClassName="label" />


									</div>


								</div>
								<div className="col-md-12 col-lg-6 d-none">
									<div className="bg-boxes">
										<div className="Fisiologici">
											<div className="right-s GONOGO_ICONA"><h4><img src={Group961} alt="icon" /><span>{t("superadmin.training.result")}</span></h4></div>
											<div className="right-part gonogo_right">
												{/* <img src={Group696} alt="icon" /> */}
												<div className="common-switch-field">

													<input type="radio" id="radio-nine" name="languageType-6" value="S" checked={true} />
													<label htmlFor="radio-nine">G</label>
													<input type="radio" id="radio-ten" name="languageType-6" value="S" checked={false} />
													<label htmlFor="radio-ten">S</label>
													<input type="radio" id="radio-eleven" name="languageType-6" value="H" checked={false} />
													<label htmlFor="radio-eleven">M</label>
												</div>
											</div>

										</div>
										<div className="right-s variable brainwellness-circular">
											<div className="gonogo-chart">
												<CircularProgressbar value={74 / 100} maxValue={1} strokeWidth={15} />
											</div>
											<div>
												<p className='mb-0'>74<sub>%</sub> </p>
												<span className='gonogo-text'>{t("user.common_dashboard.training.heading_1")}</span>
											</div>
										</div>
									</div>
								</div>




							</div>
						</div>
						<div className="col-md-4 d-block d-sm-none">
							<h3 className='brain-wellnes-heading mt-2'>{t("user.common_dashboard.right_section.section_heading")}</h3>
						</div>
						{selectedImage == "MEDITATORE" ? (
							<>
								<div className="col-md-4 misurazione">
									{/*  MindfulNess & Cognitive  */}
									<div className=''>
										<div className=''>
											<div className='m-2'>
												<div className='m-1 mb-4'>
													<div className='font-weight-light mt-5 pl-5 pr-5'>
														<img src={medalDetail['icon']} alt='icon' />
														<h6 className="mt-2 text-uppercase">{medalDetail['name']}</h6>
														{/* <p>14 marzo 2023</p> */}
														<div className="pb-5">
															<p style={{ 'font-size': '14px' }}>{medalDetail['description']}</p>
														</div>
														<div className="font-weight-bold mt-1 pt-2 pl-4 pr-4">
															<p>{medalDetail['description1']}</p>
															{
																medalDetail['popup'] == 'brainwellness' ?
																	<button onClick={() => handleMedalPopup()} className="buttons scopri text-capitalize">{t("user.common_dashboard.right_section.mindful_btn_txt1")}</button>
																	:
																	medalDetail['popup'] == 'wellbeing' ?
																		<button onClick={() => setMedalWLState(true)} className="buttons scopri text-capitalize">{t("user.common_dashboard.right_section.mindful_btn_txt1")}</button>
																		:
																		medalDetail['popup'] == 'well' ?
																			<button onClick={() => setMedalWState(true)} className="buttons scopri text-capitalize">{t("user.common_dashboard.right_section.mindful_btn_txt1")}</button>
																			:
																			medalDetail['popup'] == 'cognitive' ?
																				<button onClick={() => setCMModalState(true)} className="buttons scopri text-capitalize">{t("user.common_dashboard.right_section.mindful_btn_txt1")}</button>
																				: null
															}
														</div>
													</div>

												</div>
												<div className="bottom-span bottom-span-medal text-left">
													<div className="bottom-span-image">
														<img src={backArrow} alt='icon' />
														&nbsp;
														<span className="bottom-span-image text-uppercase text-decoration-underline"><a href="#" className="underline-a" onClick={() => handleImageClick("medalList")}>{t("user.common_dashboard.right_section.section_heading")}</a> </span>
													</div>
												</div>

											</div>

										</div>
									</div>
								</div>
							</>
						) : null}
						{selectedImage == "measurementAll" ? (

							<div className="col-md-4 misurazione">
								<div className=''>
									{/* <div className="col-md-12">
										<h3 className='brain-wellnes-heading mt-2'>{t("user.common_dashboard.right_section.section_heading")}</h3>
									</div> */}
									<div className=''>
										<div className='d-flex-misurazione'>
											<h3>{t("user.common_dashboard.right_section.misurazione")}<sup> TM </sup></h3>
											{/* <a href="#" onClick={() => handleImageClick("measurementAll")}>{t("user.common_dashboard.right_section.see_all")} 1</a> */}
										</div>
										<div className='row pointer'>

											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={Group1744} alt='icon' style={{ opacity: (healthRecord.currentMonthScan >= totalScan || healthRecord.previousMonthScan >= totalScan) ? 1 : 0.5 }} onClick={() => handleMedalClick(0)} />
													<h4>{t("user.common_dashboard.right_section.graph1")}</h4>
													<p> {(healthRecord.currentMonthScan >= totalScan) ? healthRecord.currentMonthMedalDate : healthRecord.previousMonthMedalDate} </p>
												</div>
											</div>
											{/**ASSIDUO MISURATORE */}

											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalsalute1} alt='icon' style={{ opacity: ((healthRecord.currentMonthWellBeing >= monthLowScore && healthRecord.currentMonthWellBeing < monthHighScore) || (healthRecord.previousMonthWellbeing >= monthLowScore && healthRecord.previousMonthWellbeing < monthHighScore)) ? 1 : 0.5 }} onClick={() => handleMedalClick(4)} />
													<h4>{t("user.common_dashboard.right_section.graph2")} </h4>
													<p> 1 {t("user.common_dashboard.right_section.mese")} / {(healthRecord.currentMonthWellBeing >= monthLowScore && healthRecord.currentMonthWellBeing < monthHighScore) ? healthRecord.currentMonthYear : (healthRecord.previousMonthWellbeing >= monthLowScore && healthRecord.previousMonthWellbeing < monthHighScore) ? healthRecord.previousMonthYear : null}</p>
												</div>


											</div>

											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalsalute3} style={{ opacity: (healthRecord.quarterlyWellBeing >= monthLowScore && healthRecord.quarterlyWellBeing < monthHighScore) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(5)} />
													<h4>{t("user.common_dashboard.right_section.graph2")}</h4>
													<p> 3 {t("user.common_dashboard.right_section.mese")}  </p>
												</div>
											</div>

											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalsalute6} style={{ opacity: (healthRecord.halfYearlyWellBeing >= monthLowScore && healthRecord.halfYearlyWellBeing < monthHighScore) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(6)} />
													<h4>{t("user.common_dashboard.right_section.graph3")}</h4>
													<p>6 {t("user.common_dashboard.right_section.mese")} </p>
												</div>
											</div>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalsalute12} style={{ opacity: (healthRecord.yearlyWellBeing >= monthLowScore && healthRecord.yearlyWellBeing < monthHighScore) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(7)} />
													<h4>{t("user.common_dashboard.right_section.graph1")}</h4>
													<p> 1 {t("user.common_dashboard.right_section.anno")} </p>
												</div>
											</div>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalottima1} alt='icon' style={{ opacity: (healthRecord.currentMonthWellBeing >= monthHighScore || healthRecord.previousMonthWellbeing >= monthHighScore) ? 1 : 0.5 }} onClick={() => handleMedalClick(8)} />
													<h4>{t("user.common_dashboard.right_section.graph3")}</h4>
													<p> 1 {t("user.common_dashboard.right_section.mese")} / {(healthRecord.currentMonthWellBeing >= monthHighScore) ? healthRecord.currentMonthYear : healthRecord.previousMonthYear} </p>
												</div>
											</div>

											{/* medal section two */}

											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalottima3} style={{ opacity: (healthRecord.quarterlyWellBeing >= monthHighScore) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(9)} />
													<h4>{t("user.common_dashboard.right_section.graph2")}</h4>
													<p> 3 {t("user.common_dashboard.right_section.mese")} </p>
												</div>
											</div>

											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalottima6} style={{ opacity: (healthRecord.halfYearlyWellBeing >= monthHighScore) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(10)} />
													<h4>{t("user.common_dashboard.right_section.graph3")}</h4>
													<p>6 {t("user.common_dashboard.right_section.mese")} </p>
												</div>
											</div>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalottima12} style={{ opacity: (healthRecord.yearlyWellBeing >= monthHighScore) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(11)} />
													<h4>{t("user.common_dashboard.right_section.graph1")}</h4>
													<p> 1 {t("user.common_dashboard.right_section.anno")} </p>
												</div>
											</div>

										</div>
										{/* medal section three */}
										<div className="row pointer">
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalril} alt='icon' style={{ opacity: (healthRecord.twoWeeksMSI < 3) ? 1 : 0.5 }} onClick={() => handleMedalClick(1)} />
													<h4>{t("user.common_dashboard.right_section.rel1")}</h4>
													{/* <p>10 maggio 2023</p> */}
												</div>
											</div>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={Group1754} alt='icon' style={{ opacity: (healthRecord.currentMonthBMI >= 18.5 && healthRecord.currentMonthBMI <= 24.9 || healthRecord.previousMonthBMI >= 18.5 && healthRecord.previousMonthBMI <= 24.9) ? 1 : 0.5 }} onClick={() => handleMedalClick(2)} />
													<h4>{t("user.common_dashboard.right_section.rel2")}</h4>
													<p>{healthRecord.currentMonthBMI >= 18.5 && healthRecord.currentMonthBMI <= 24.9 ? healthRecord.currentMonthBMIDate : healthRecord.lastMonthBMIDate}</p>
												</div>
											</div>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={medalwl} alt='icon' style={{ opacity: (healthRecord.wellbeingGrowth == true) ? 1 : 0.5 }} onClick={() => handleMedalClick(3)} />
													<h4>{t("user.common_dashboard.right_section.rel3")}</h4>
													{/* <p>10 maggio 2023</p> */}
												</div>
											</div>
										</div>
										<div className="bottom-span bottom-span-medal pull-left">
											<div className="bottom-span-image">
												<img src={backArrow} alt='icon' />
												&nbsp;
												<span className="bottom-span-image text-uppercase text-decoration-underline"><a href="#" className="underline-a" onClick={() => handleImageClick("medalList")}>{t("user.common_dashboard.right_section.section_heading")}</a> </span>
											</div>
										</div>

									</div>
								</div>
							</div>
						) : null}
						{selectedImage == "medalList" ? (
							<div className="col-md-4 misurazione">
								<div className=''>
									{/* <div className="col-md-12">
										<h3 className='brain-wellnes-heading mt-2'>{t("user.common_dashboard.right_section.section_heading")}</h3>
									</div> */}
									<div className=''>
										<div className='d-flex-misurazione'>
											<h3>{t("user.common_dashboard.right_section.misurazione")}<sup> TM </sup></h3>
											<a href="#" onClick={() => handleImageClick("measurementAll")}>{t("user.common_dashboard.right_section.see_all")}</a>
										</div>
										<div className='row pointer'>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={Group1744} alt='icon' style={{ opacity: (healthRecord.currentMonthScan >= totalScan || healthRecord.previousMonthScan >= totalScan) ? 1 : 0.5 }} onClick={() => handleMedalClick(0)} />
													<h4>{t("user.common_dashboard.right_section.graph1")} </h4>
													<p>{(healthRecord.currentMonthScan >= totalScan) ? healthRecord.currentMonthMedalDate : healthRecord.previousMonthMedalDate}</p>
												</div>
											</div>
											{((healthRecord.currentMonthWellBeing >= monthLowScore && healthRecord.currentMonthWellBeing < monthHighScore) || (healthRecord.previousMonthWellbeing >= monthLowScore && healthRecord.previousMonthWellbeing < monthHighScore)) ?
												<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
													<div className='assiduo'>
														<img src={medalsalute1} alt='icon' style={{ opacity: (healthRecord.currentMonthWellBeing >= monthLowScore && healthRecord.currentMonthWellBeing < monthHighScore || healthRecord.previousMonthWellbeing >= monthLowScore && healthRecord.previousMonthWellbeing < monthHighScore) ? 1 : 0.5 }} onClick={() => handleMedalClick(4)} />
														<h4>{t("user.common_dashboard.right_section.graph2")} </h4>
														<p> 1 {t("user.common_dashboard.right_section.mese")} / {(healthRecord.currentMonthWellBeing >= monthLowScore && healthRecord.currentMonthWellBeing < monthHighScore) ? healthRecord.currentMonthYear : healthRecord.previousMonthYear} </p>
													</div>
												</div> : null}
											{(healthRecord.currentMonthWellBeing >= monthHighScore || healthRecord.previousMonthWellbeing >= 80) ?
												<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
													<div className='assiduo'>
														<img src={medalottima1} alt='icon' style={{ opacity: (healthRecord.currentMonthWellBeing >= monthHighScore || healthRecord.previousMonthWellbeing >= 80) ? 1 : 0.5 }}  onClick={() => handleMedalClick(8)} />
														<h4>{t("user.common_dashboard.right_section.graph3")}</h4>
														<p>1 {t("user.common_dashboard.right_section.mese")} / {(healthRecord.currentMonthWellBeing >= monthHighScore) ? healthRecord.currentMonthYear : healthRecord.previousMonthYear}</p>
													</div>
												</div> : null}
											{((healthRecord.currentMonthBMI >= 18.5 && healthRecord.currentMonthBMI <= 24.9) || (healthRecord.previousMonthBMI >= 18.5 && healthRecord.previousMonthBMI <= 24.9)) ?
												<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
													<div className='assiduo'>
														<img src={Group1754} alt='icon' style={{ opacity: ((healthRecord.currentMonthBMI >= 18.5 && healthRecord.currentMonthBMI <= 24.9) || (healthRecord.previousMonthBMI >= 18.5 && healthRecord.previousMonthBMI <= 24.9)) ? 1 : 0.5 }} onClick={() => handleMedalClick(2)}/>
														<h4>{t("user.common_dashboard.right_section.graph4")}</h4>
														<p>{(healthRecord.currentMonthBMI >= 18.5 && healthRecord.currentMonthBMI <= 24.9) ? healthRecord.currentMonthBMIDate : healthRecord.lastMonthBMIDate}</p>
													</div>
												</div> : null}
										</div>
										{brainwellnessModule == 1 ?
											<>
												<div className='d-flex-misurazione'>
													<h3>{t("user.dashboard.mindfullness_txt")}</h3>
													<a href="#" onClick={() => handleImageClick("partialMindFullness")} >{t("user.common_dashboard.right_section.see_all")}</a>
												</div>
												<div className='row pointer'>
													{brainWelnessRecord.weeklyMindFullness == true || brainWelnessRecord.partialMindFullness == true || brainWelnessRecord.completeMindFullness == true ?
														<>
															<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.weeklyMindFullness == true ? '' : 'd-none'}`}>
																<div className='assiduo'>
																	<img src={medalm1} alt='icon' onClick={() => handleMedalClick(12)} />
																	<h4>{t("user.common_dashboard.right_section.mindful_txt1")} </h4>
																	{/* <p>14 marzo 2023 </p> */}
																</div>
															</div>
															<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.partialMindFullness == true ? '' : 'd-none'}`}>
																<div className='assiduo'>
																	<img src={medalm2} alt='icon' onClick={() => handleMedalClick(13)} />
																	<h4>{t("user.common_dashboard.right_section.mindful_txt2")} </h4>
																	{/* <p>14 marzo 2023 </p> */}
																</div>
															</div>
															<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.completeMindFullness == true ? '' : 'd-none'}`}>
																<div className='assiduo'>
																	<img src={medalm3} alt='icon' onClick={() => handleMedalClick(14)} />
																	<h4>{t("user.common_dashboard.right_section.mindful_txt3")} </h4>
																	{/* <p>14 marzo 2023 </p> */}
																</div>
															</div>
														</>
														: <div className='col-md-12'>
															<div className='assiduo medaglia'>
																<div className='assiduo no-image text-space2 text-uppercase' >
																	<span className="texttr">{t("user.common_dashboard.right_section.text_tr")}</span>
																</div>
															</div>
														</div>}
												</div>
												<div className='d-flex-misurazione'>
													<h3>{t("user.dashboard.training_cognitive")}</h3>
													<a href="#" onClick={() => handleImageClick("partialCognitive")}>{t("user.common_dashboard.right_section.see_all")}</a>
												</div>
												<div className='row pointer'>

													{brainWelnessRecord.weeklyCognitive == true || brainWelnessRecord.partialCognitive == true || brainWelnessRecord.completeCognitive == true ?
														<>
															<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.completeCognitive == true ? '' : 'd-none'}`}>
																<div className='assiduo'>
																	<img src={medalt1} alt='icon' onClick={() => handleMedalClick(15)} />
																	<h4>{t("user.common_dashboard.right_section.cog_txt1")} </h4>
																	{/* <p>14 marzo 2023 </p> */}
																</div>
															</div>
															<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.partialCognitive == true ? '' : 'd-none'}`}>
																<div className='assiduo'>
																	<img src={medalt2} alt='icon' onClick={() => handleMedalClick(16)} />
																	<h4>{t("user.common_dashboard.right_section.cog_txt2")} </h4>
																	{/* <p>14 marzo 2023 </p> */}
																</div>
															</div>
															<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.weeklyCognitive == true ? '' : 'd-none'}`}>
																<div className='assiduo'>
																	<img src={medalt3} alt='icon' onClick={() => handleMedalClick(17)} />
																	<h4>{t("user.common_dashboard.right_section.cog_txt3")} </h4>
																	{/* <p>14 marzo 2023 </p> */}
																</div>
															</div>
														</>
														:
														<div className='col-md-12'>
															<div className='assiduo medaglia'>
																<div className='assiduo no-image text-space2 text-uppercase' >
																	<span className="texttr">{t("user.common_dashboard.right_section.text_tr")}</span>
																</div>
															</div>
														</div>
													}
												</div>
											</>
											: null}
										{trainingModule == 1 ? <>
											<div className='d-flex-misurazione'>
												<h3>{t("user.dashboard.wellbeing_literacy")}</h3>
												<a href="#" onClick={() => handleImageClick("wellbeingList")} >{t("user.common_dashboard.right_section.see_all")}</a>
											</div>
											<div className='row pointer'>

												<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
													<div className='assiduo'>
														<img src={wellbeingRecord.monthly == 2 ? medalwl21 : medalwl11} style={{ opacity: (wellbeingRecord.monthly > 0) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(18)} />
														<h4>{t("user.common_dashboard.right_section.wellbeing_txt1")}</h4>
														<p>{wellbeingRecord.monthly > 0 ? wellbeingRecord.trainingDate : null}</p>
													</div>
												</div>
												<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
													<div className='assiduo'>
														<img src={wellbeingRecord.halfYearly == 2 ? medalwl22 : medalwl12} style={{ opacity: (wellbeingRecord.halfYearly > 0) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(19)} />
														<h4>{t("user.common_dashboard.right_section.wellbeing_txt2")}</h4>
														<p>{wellbeingRecord.halfYearly > 0 ? wellbeingRecord.trainingDate : null}</p>
													</div>
												</div>
												<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
													<div className='assiduo'>
														<img src={wellbeingRecord.yearly == 2 ? medalwl23 : medalwl13} style={{ opacity: (wellbeingRecord.yearly > 0) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(20)} />
														<h4>{t("user.common_dashboard.right_section.wellbeing_txt3")}</h4>
														<p>{wellbeingRecord.yearly > 0 ? wellbeingRecord.trainingDate : null}</p>
													</div>
												</div>
											</div>

										</> : null}
									</div>

								</div>
							</div>
						)
							: null
						}
						{selectedImage == "wellbeingList" ?

							<div className="col-md-4 misurazione">
								<div className=''>
									{/* <div className="col-md-12">
										<h3 className='brain-wellnes-heading mt-2'>{t("user.common_dashboard.right_section.section_heading")}</h3>
									</div> */}
									<div className=''>
										<div className='d-flex-misurazione'>
											<h3>Wellbeing Literacy</h3>
										</div>
										<div className='row pointer'>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={wellbeingRecord.monthly == 2 ? medalwl21 : medalwl11} style={{ opacity: (wellbeingRecord.monthly > 0) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(18)} />
													<h4>{t("user.common_dashboard.right_section.wellbeing_txt1")}</h4>
													<p>{wellbeingRecord.monthly > 0 ? wellbeingRecord.trainingDate : null}</p>
												</div>
											</div>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={wellbeingRecord.halfYearly == 2 ? medalwl22 : medalwl12} style={{ opacity: (wellbeingRecord.halfYearly > 0) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(19)} />
													<h4>{t("user.common_dashboard.right_section.wellbeing_txt2")}</h4>
													<p>{wellbeingRecord.halfYearly > 0 ? wellbeingRecord.trainingDate : null}</p>
												</div>
											</div>
											<div className='col-6 col-sm-6 col-md-6 col-lg-4'>
												<div className='assiduo'>
													<img src={wellbeingRecord.yearly == 2 ? medalwl23 : medalwl13} style={{ opacity: (wellbeingRecord.yearly > 0) ? 1 : 0.5 }} alt='icon' onClick={() => handleMedalClick(20)} />
													<h4>{t("user.common_dashboard.right_section.wellbeing_txt3")}</h4>
													<p>{wellbeingRecord.yearly > 0 ? wellbeingRecord.trainingDate : null}</p>
												</div>
											</div>
										</div>

										<div className="bottom-span bottom-span-medal pull-left">
											<div className="bottom-span-image">
												<img src={backArrow} alt='icon' />
												&nbsp;
												<span className="bottom-span-image text-uppercase text-decoration-underline"><a href="#" className="underline-a" onClick={() => handleImageClick("medalList")}>{t("user.common_dashboard.right_section.section_heading")}</a> </span>
											</div>
										</div></div>
								</div>
							</div>
							: null}

						{selectedImage == "partialCognitive" ?

							<div className="col-md-4 misurazione">
								<div className=''>

									<div className=''>
										<div className='d-flex-misurazione'>
											<h3>Cognitive</h3>
										</div>
										<div className='row pointer'>
											<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.completeCognitive == true ? '' : ''}`}>
												<div className='assiduo'>
													<img src={medalt1} alt='icon' style={{ opacity: (brainWelnessRecord.completeCognitive == true) ? 1 : 0.5 }} onClick={() => handleMedalClick(15)} />
													<h4>{t("user.common_dashboard.right_section.cog_txt1")} </h4>
													{/* <p>14 marzo 2023 </p> */}
												</div>
											</div>
											<div className={`col-6 col-sm-6 col-md-6 col-lg-4 `}>
												<div className='assiduo'>
													<img src={medalt2} alt='icon' style={{ opacity: (brainWelnessRecord.partialCognitive == true) ? 1 : 0.5 }} onClick={() => handleMedalClick(16)} />
													<h4>{t("user.common_dashboard.right_section.cog_txt2")} </h4>
													{/* <p>14 marzo 2023 </p> */}
												</div>
											</div>
											<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.weeklyCognitive == true ? '' : ''}`}>
												<div className='assiduo'>
													<img src={medalt3} alt='icon' style={{ opacity: (brainWelnessRecord.weeklyCognitive == true) ? 1 : 0.5 }} onClick={() => handleMedalClick(17)} />
													<h4>{t("user.common_dashboard.right_section.cog_txt3")} </h4>
													{/* <p>14 marzo 2023 </p> */}
												</div>
											</div>
										</div>

										<div className="bottom-span bottom-span-medal pull-left">
											<div className="bottom-span-image">
												<img src={backArrow} alt='icon' />
												&nbsp;
												<span className="bottom-span-image text-uppercase text-decoration-underline"><a href="#" className="underline-a" onClick={() => handleImageClick("medalList")}>{t("user.common_dashboard.right_section.section_heading")}</a> </span>
											</div>
										</div></div>
								</div>
							</div>
							: null}
						{selectedImage == "partialMindFullness" ?

							<div className="col-md-4 misurazione">
								<div className=''>

									<div className=''>
										<div className='d-flex-misurazione'>
											<h3>MindFullness</h3>
										</div>
										<div className='row pointer'>
											<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.weeklyMindFullness == true ? '' : ''}`}>
												<div className='assiduo'>
													<img src={medalm1} alt='icon' style={{ opacity: (brainWelnessRecord.weeklyMindFullness == true) ? 1 : 0.5 }} onClick={() => handleMedalClick(12)} />
													<h4>{t("user.common_dashboard.right_section.mindful_txt1")} </h4>

												</div>
											</div>
											<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.partialMindFullness == true ? '' : ''}`}>
												<div className='assiduo'>
													<img src={medalm2} alt='icon' style={{ opacity: (brainWelnessRecord.partialMindFullness == true) ? 1 : 0.5 }} onClick={() => handleMedalClick(13)} />
													<h4>{t("user.common_dashboard.right_section.mindful_txt2")} </h4>

												</div>
											</div>
											<div className={`col-6 col-sm-6 col-md-6 col-lg-4 ${brainWelnessRecord.completeMindFullness == true ? '' : ''}`}>
												<div className='assiduo'>
													<img src={medalm3} alt='icon' style={{ opacity: (brainWelnessRecord.completeMindFullness == true) ? 1 : 0.5 }} onClick={() => handleMedalClick(14)} />
													<h4>{t("user.common_dashboard.right_section.mindful_txt3")} </h4>

												</div>
											</div>
										</div>

										<div className="bottom-span bottom-span-medal pull-left">
											<div className="bottom-span-image">
												<img src={backArrow} alt='icon' />
												&nbsp;
												<span className="bottom-span-image text-uppercase text-decoration-underline"><a href="#" className="underline-a" onClick={() => handleImageClick("medalList")}>{t("user.common_dashboard.right_section.section_heading")}</a> </span>
											</div>
										</div></div>
								</div>
							</div>
							: null}
					</div>
				</div>
			</div>
		</>
	);
}

export default BrainWellness;