import React, { useEffect } from "react";
import { useState } from "react";
import ONBOARDINGICONA from "../../../assets/Image/ONBOARDINGICONA.svg";
import RIFERIMENTIICONA from "../../../assets/Image/RIFERIMENTIICONA.svg";
import logo from "../../../assets/Image/logo.svg";
import riepilogo from "../../../assets/Image/riepilogo.svg";
import dashboardIcon from "../../../assets/Image/dashboardIcon.svg";
import { Link } from "react-router-dom";
import Group756 from "../../../assets/Image/Group756.svg";
import Group824 from "../../../assets/Image/Group824.svg";
import Group758 from "../../../assets/Image/Group758.svg";
import ICONA_LIGHT1 from "../../../assets/Image/ICONA_LIGHT1.svg";
import LOGOUT_ICON from '../../../assets/Image/LOGOUT_ICONA.svg'
import DARK_ICONA2 from "../../../assets/Image/DARK_ICONA2.svg";
import Group842 from "../../../assets/Image/Group842.svg";
import REPORT_ICONA from "../../../assets/Image/REPORT_ICONA.svg";
import logoipad from "../../../assets/Image/logo-ipad.svg";
import logout from "../../../assets/Image/logout.svg";
import terms from "../../../assets/Image/T&C_ICONA.svg";
import PROTOCOL_ICON from '../../../assets/Image/PROTOCOLLI_ICONA.svg';
import FORMAZI from '../../../assets/Image/FORMAZIONE_ICONA.svg';
import DEMO from '../../../assets/Image/DEMO_ICONA.svg';
import userManualImg from '../../../assets/Image/user-manual-sidebar.svg';
import {
    setLocalStoarge,
    removeLocalStoarge1,
    getLocalStoarge,
    Logout,
} from "../../common/allfunction";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Super_Sidebar(props) {
    const [theme, setTheme] = useState("light");
    const [activebg, setActivebg] = useState(props.path);
    const [toggleStatus, setToggleStatus] = useState(true);
    const [adminName, setadminName] = useState("");
    const [adminProfile, setadminProfile] = useState("default.png");
    const [t, i18n] = useTranslation("common");
    const [userRole, setUserRole] = useState(getLocalStoarge("roleId"));


    const toggleTheme = () => {
        setTheme(theme === "light" ? "dark" : "light");
        setToggleStatus(theme === "light" ? true : false);
        setLocalStoarge(
            "theme",
            JSON.stringify(theme === "light" ? "dark" : "light")
        );
    };
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
    };

    const { page_name } = useParams();
    useEffect(() => {
        document.body.className = theme;
        handleTranslationLang();
    }, [theme]);


    useEffect(() => {
        const theme1 = JSON.parse(localStorage.getItem("theme"));
        setTheme(theme1);
        if (theme1) {
            setToggleStatus(theme1 === "light" ? false : true);
        } else {
            setTheme("light");
            setToggleStatus(false);
        }
        // setActivebg(page_name);
        let admin_data = JSON.parse(getLocalStoarge("admin_profile"));
        setadminName(admin_data?.user_name ? admin_data.user_name : "NO NAME");
        setadminProfile(
            admin_data?.profile_pic ? admin_data?.profile_pic : "default.png"
        );
    }, [page_name]);

    const path = window.location.pathname;
    const pageName = path.substring(path.lastIndexOf("/") + 1);

    const removeToken = () => {
        const logout = Logout();
        if (logout) {
            removeLocalStoarge1();
            window.location.href = "/sa/signin";          
        } else {
            console.log("something was wrong");
        }
    };
    const [defaultImage, setDefaultImage] = useState(process.env.REACT_APP_BACKEND_BASE_URL +
        process.env.REACT_APP_STATIC_PATH + "/default.png")
    const handleImageError = (event) => {
        event.target.onerror = null; 
        event.target.src = defaultImage; // Replace with fallback image URL
      };
    return (
        <>
            <div className="col-md-1 col-lg-2 topspace darkimenu ipad-widhset">
                <div className="left-dashboard text">
                    <div className="w-100">
                        <Link to="/protocol">
                            <img
                                src={logo}
                                className="img-fluid logo_common ipd-logo-n"
                                alt="logo"
                            />
                            <img
                                src={logoipad}
                                className="img-fluid logo_common vison-tab"
                                alt="logo"
                            />
                        </Link>
                        <div className="profile">
                            <div className="dropdown profile-drop">
                                <Link to="/profile">
                                    <button
                                        // className="dropdown-toggle"
                                        type="button"
                                    // id="dropdownMenuButton"
                                    // data-toggle="dropdown"
                                    // aria-haspopup="true"
                                    // aria-expanded="false"
                                    >
                                        <div className="prof-pic text">
                                            <img
                                                src={
                                                    process.env.REACT_APP_BACKEND_BASE_URL +
                                                    process.env.REACT_APP_STATIC_PATH +
                                                    adminProfile
                                                }
                                                className="img-fluid"
                                                alt="img"
                                                onError={handleImageError}

                                            />
                                        </div>
                                        <span className="name_left ">
                                            <span className="ipad-textn">{adminName}</span>
                                        </span>
                                    </button>
                                </Link>
                                {/* <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                >
                                    <Link to="/bi-steps" className="dropdown-item">
                                        {" "}
                                        <img src={ONBOARDINGICONA} alt="icon" />
                                        {t("user.sidebar.steps")}
                                    </Link>
                                    <Link to="/bi-privacy" className="dropdown-item">
                                        {" "}
                                        <img src={Group758} alt="icon" />
                                        {t("user.sidebar.privacy")}{" "}
                                    </Link>
                                    <Link to="/bi-terms" className="dropdown-item">
                                        <img src={terms} alt="icon" />
                                        {t("user.sidebar.t&c")}
                                    </Link>
                                    <Link to="/scientific-references" className="dropdown-item">
                                        <img src={RIFERIMENTIICONA} alt="icon" />
                                        {t("user.sidebar.referments")}
                                    </Link>

                                    <Link
                                        to="/frequently-asked-questions"
                                        className="dropdown-item"
                                    >
                                        {" "}
                                        <img src={Group824} alt="icon" />
                                        {t("user.sidebar.FAQ")}
                                    </Link>

                                    <Link to="/my-profile" className="dropdown-item">
                                        <img src={Group756} alt="icon" />
                                        {t("user.sidebar.profile")}
                                    </Link>



                                    <a className="dropdown-item " onClick={removeToken}>
                                        <img src={logout} alt="icon" />
                                        {t("user.sidebar.logout")}
                                    </a>
                                </div> */}
                            </div>
                        </div>
                        <div className="navigate manageside-bar">
                            <ul>
                                <li className={activebg == "protocol" ? "active" : ""}>
                                    <Link to="/protocol">
                                        <img src={PROTOCOL_ICON} alt="icon" />
                                        <span className={"ipad-textn"}>{t("superadmin.sidebar.protocols")}</span>
                                    </Link>
                                </li>
                                {/* <li className={activebg == "report" ? "active" : ""}>
                                    <Link to="/protocol">
                                        <img src={DEMO} alt="icon" />{" "}
                                        <span className={"ipad-textn"}> {t("superadmin.sidebar.demo")}</span>
                                    </Link>
                                </li> */}
                                <li className={activebg == "training" ? "active" : ""}>
                                    <Link to="/training">
                                        <img src={FORMAZI} alt="icon" />
                                        <span className={"ipad-textn"}>
                                            {t("superadmin.sidebar.training")}
                                        </span>
                                    </Link>
                                </li>
                                <li className={activebg == "brain-wellness" ? "active" : ""}>
                                    <Link to="/brain-wellness">
                                        <img src={riepilogo} alt="icon" />
                                        <span className={"ipad-textn"}>
                                            {t("superadmin.sidebar.brain_wellness")}
                                        </span>
                                    </Link>
                                </li>
                                {(userRole === "3") ?
                                    <li className={activebg == "white-label" ? "active" : ""}>
                                        <Link to="/white-label">
                                            <img src={dashboardIcon} alt="icon" />
                                            <span className={"ipad-textn"}>
                                                {t("superadmin.sidebar.white_label")}
                                            </span>
                                        </Link>
                                    </li>
                                    : null}
                                <li>
                                    <Link to="/sadmin-manual">
                                        {" "}
                                        <img src={userManualImg} alt="icon" />
                                        <span className={"ipad-textn"}>
                                        {t("user.sidebar.manual")}
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="ali-bottom">
                        <div className="bot-set n-mobi">
                            <div><button className="logout-button" onClick={removeToken}><img src={LOGOUT_ICON} alt="icon" /> Logout</button></div>
                            {/* <div className="ali-bottom"> */}
                            <div className="bot-set n-mobi">
                                {toggleStatus == true && (
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        id="checkbox"
                                        checked
                                        onChange={toggleTheme}
                                    />
                                )}
                                {toggleStatus == false && (
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        id="checkbox"
                                        onChange={toggleTheme}
                                    />
                                )}
                                <label htmlFor="checkbox" className="label">
                                    <div className="icco">
                                        <img src={ICONA_LIGHT1} className="img-fluid" alt="logo" />
                                    </div>
                                    <div className="moon">
                                        <img src={DARK_ICONA2} className="img-fluid" alt="logo" />
                                    </div>
                                    <div className="anim-dari">
                                        <div className="ball"></div>
                                        <div className="light"></div>
                                        <div className="dark"></div>
                                    </div>
                                </label>
                            </div>

                            {/* </div> */}


                            {/* {toggleStatus == true && (
            <input
              type="checkbox"
              className="checkbox"
              id="checkbox"
              defaultChecked={true}
              onClick={toggleTheme}
            />
          )}
          {toggleStatus == false && (
            <input
              type="checkbox"
              className="checkbox"
              id="checkbox"
              onClick={toggleTheme}
            />
          )} */}
                            {/* <label htmlFor="checkbox" className="label"> */}
                            {/* <div className="icco"> */}
                            {/* <img src={ICONA_LIGHT1} className="img-fluid" alt="logo" /> */}
                        </div>
                    </div>
                    {/* <div className="moon"> */}
                    {/* <img src={DARK_ICONA2} className="img-fluid" alt="logo" /> */}

                    {/* <div className="anim-dari">
                        <div className="ball"></div>
                        <div className="light"></div>
                        <div className="dark"></div>
                    </div> */}
                </div>
            </div>


        </>
    );
}
export default Super_Sidebar;
