import React, { useState, useEffect } from "react";
import Group1015 from "../../../assets/Image/Group1015.svg";
import emailIcon from "../../../assets/Image/emailIcon.svg";
import PREVIEWICONA from "../../../assets/Image/PREVIEWICONA.svg";
import { Link } from "react-router-dom";
import logo from "../../../assets/Image/logo.svg";
import LazyLoader from "../../common/lazyLoader";
import PostRequest from "../../apiConnection/postRequest";
import { getLocalStoarge } from "../../common/allfunction";
import { useTranslation } from "react-i18next";


function AdminForgotpassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [alerts, setAlerts] = useState({
    alert: '',
    alerttype: '',
    alertclass: 'danger',
    showalert: false,
  });
  const { alert, alerttype, alertclass, showalert } = alerts;
  const [inputValues, setInputValue] = useState({
    email: '',
  });
  const [validation, setValidation] = useState({
    email: '',
  });
  const [t, i18n] = useTranslation('common');
  useEffect(()=>{
    handleTranslationLang();
  },[])
  const handleTranslationLang = () => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  const checkValidation = async () => {
    let errors = validation;
    let Emailregex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
    const email = inputValues.email;
    if (!email) {
      errors.email = t('user.login.email_req');
    } else if (!email.match(Emailregex)) {
      errors.email = t('user.Login.valid_email');
    } else {
      errors.email = "";
    }
    

    setValidation(errors);
    let return_val = true;
    Object.entries(errors).forEach(([key, value]) => {
      if (value !== "")
        return_val = false;
    });
    return return_val;
  };


  const do_Forgot = async (e) => {
    e.preventDefault();
    setAlerts({
      alert: t('user.forgotpass.Forgot'),
      alerttype: 'Info',
      alertclass: 'info',
      showalert: true,
    })
    const {email} = inputValues;
      const payload = {
        "email":email,
        "role_id":"3"
      }

      setIsLoading(true);
      const validate = await checkValidation();
      if (validate!==true){
        setIsLoading(false);
        setAlerts({
          alert: validation.email,
          alerttype: 'Error',
          alertclass: 'danger',
          showalert: true,
        })
      } else {
        try {
          const forgotResult = await PostRequest(
            `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUPER_ADMIN_FORGET_PASSWORD}`,
            payload
          );
          if (forgotResult && forgotResult.status === 200) {
            if (forgotResult.data.code === 200) {
              setIsLoading(false);
              setAlerts({
                alert: t('user.forgotpass.alert'),
                alerttype: 'Success',
                alertclass: 'success',
                showalert: true,
              })
              setTimeout(() => { window.location.href = "./signin" }, 5000);

            }
          }
          else if(forgotResult && forgotResult.status === 203){
            setIsLoading(false);
            setAlerts({
              alert: t('user.forgotpass.err_msg'),
              alerttype: 'Error',
              alertclass: 'danger',
              showalert: true,
            })
          }
            else {
            setIsLoading(false);
            setAlerts({
              alert: t('user.forgotpass.err_msg'),
              alerttype: 'Error',
              alertclass: 'danger',
              showalert: true,
            })
          }
        } catch (error) {
          setIsLoading(false);
          setAlerts({
            alert: t('user.login.server'),
            alerttype: "Error",
            alertclass: "danger",
            showalert: true,
          });
        }  
      }
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
    setAlerts('', '', '', false);
    setValidation({
      email: '',
    })
  }
  return (
    <>
     <div className="bgadminmob">
      <section className="bg_otp m-top25m bgyellowadmin bgloginsuper-admin">
        <div className="container">
          <div className="logo-w">
            <Link to="/signin">
              <img src={logo} className="logo" alt="logo" />
            </Link>
          </div>
          <div className="row top63">
            <div className="forgot">
              <div className="icon-aut">
                <img src={Group1015} className="key" alt="icon" />
                <h2 className="otp-heading">{t("admin.login.forgotpass")}</h2>
                <p className="Riemposta" 
                  dangerouslySetInnerHTML={{
                    __html: t("user.forgotpass.common_instruction"),
                  }}
                >
                </p>
                <form onSubmit={(e) => do_Forgot(e)} method="POST" className="registrati-form">
                {(showalert) ? (
                  <div className={`alert alert-${alertclass}`}>
                    {alert}
                  </div>
                ) : (<></>)}
                  <div className="form-group relative super-admin">
                    <img src={emailIcon} alt="icon" className="icon_left" />
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      onChange={handleChange}
                      name='email'
                      defaultValue={inputValues.email}
                    />
                  </div>
                 
                <button type="submit" className="buttons m-bsp">
                {t("user.forgotpass.send")}
                </button>                
                <Link to="signin">
                  <div className="torna">
                    <img src={PREVIEWICONA} alt="icon" /> {t("user.forgotpass.back")}
                  </div>
                </Link>
                </form>
              </div>
            </div>
          </div>
          {(isLoading)?<LazyLoader />:null}
        </div>
       </section>
       <p className="Powered">© Powered By Neocogita</p>
    </div>
    </>
  );
}

export default AdminForgotpassword;
