import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
// import SidebarProfile from '../sidebarProfile';
import Sidebar from '../sidebar';
import SidebarMob from "../sidebarmob";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PostRequest from "../../apiConnection/postRequest";
import PutRequest from "../../apiConnection/putRequest";
import LazyLoader from "../../common/lazyLoader";
import { useParams } from "react-router-dom";
import Modal from "../../common/modal";
import Group698 from "../../../assets/Image/Group698.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg';
import DeleteBin from '../../../assets/Image/delete-bin.svg';
import crosseditprofile from '../../../assets/Image/cross-editprofile.svg';
import VectorDown from "../../../assets/Image/VectorDown.svg";
import '../admin_assets/scss/protocol.scss';
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg";
import NEXT_ICONA_P from "../../../assets/Image/NEXT_ICONA_P.svg";
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg';
import Statusicona from '../../../assets/Image/status_icona.svg';
import CALENDARIO_ICONA from '../../../assets/Image/CALENDARIO_ICONA.svg';
import PASSWORD_ICONA from '../../../assets/Image/PASSWORD_ICONA.svg';
import USER_ICONA from '../../../assets/Image/USER_ICONA.svg';
import rilascio_icona from '../../../assets/Image/rilascio_icona.svg';
import DOCUMENTO_ICONA from '../../../assets/Image/DOCUMENTO_ICONA.svg';
import EDIT_PENCIL from '../../../assets/Image/EDIT_PENCIL.svg';
import Group84123 from '../../../assets/Image/Group84123.svg';
import Group769 from "../../../assets/Image/Group769.svg";
import PREV_PAGE_ICON from '../../../assets/Image/PREVPAGE_ICON.svg';
import leftarrow from "../../../assets/Image/leftarrow.svg";
import filterFactory from "react-bootstrap-table2-filter";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge, getCompanyDetails } from "../../common/allfunction";
import VIDEO_ICONAvideo from "../../../assets/Image/VIDEO_ICONAvideo.svg"

//import ProgramStatics from "./programStatics";
import moment from 'moment';
const SA_Company_Programs = (props) => {
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation("common");
    const [companiesData, setCompaniesData] = useState([]);
    const [company_id, setCompanyId] = useState(props.match.params.company_id)
    const [program_id, setProgramId] = useState(props.match.params.program_id)
    const [companyDetails, setCompanyDetails] = useState({})
    const [programData, setProgramData] = useState({});
    const [courseData, setCourseData] = useState([]);
    const [courseContent, setCourseContent] = useState({})
    const [userCount, setUserCount] = useState();
    const [personalText, setPersonalText] = useState("detail")
    const [alertMessage, setAlertMessage] = useState({ show: false, spinner: false, message: "", class: "" })
    const [modifyUserModal, setModifyUserModal] = useState(false)
    const [testProgram, setTestProgram] = useState(false)
    const [modifyProgramOne, setModifyProgramOne] = useState(false)
    const [multipleChoice, setMultipleChoice] = useState(false)
    const [singleChoice, setSingleChoice] = useState(false)
    const [trueFalseChoice, setTrueFalseChoice] = useState(false)
    const [writtenChoice, setWrittenChoice] = useState(false)
    const [selectedUser, setSelectedUser] = useState([])
    const [allUser, setAllUser] = useState([])
    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [itemsPerPage, setItemPerPage] = useState(12)
    const [programAssignee, setProgramAssignee] = useState([])
    const [addTestButton, setAddTestButton] = useState(false)
    const [val, setVal] = useState([]);
    const [correct, setCorrect] = useState(null);
    const [optionCheck, setCheckOption] = useState(1);
    const [val2, setVal2] = useState([]);
    const [correct2, setCorrect2] = useState([]);
    const [allQuestionsArray, setAllQuestionsArray] = useState([]);
    const [allQuestionsObject, setAllQuestionsObject] = useState([]);
    const [queType, setQueType] = useState('');
    const [val4, setVal4] = useState([]);
    const [addProgramModal2Content, setAddProgramModal2Content] = useState(false);
    const [correct3, setCorrect3] = useState();
    const [questions, setQuestions] = useState([]);
    const [testData, setTestData] = useState([]);
    const [finalTest, setFinalTest] = useState({});
    const [noOfQue, setNoOfQue] = useState();
    const [programTestSummaryData, setProgramTestSummaryData] = useState([])
    const [programUser, setProgramUser] = useState([])
    const [showProgramUser, setShowProgramUsers] = useState([])
    const [currentPageList, setCurrentPageList] = useState([])
    const [itemPerPageList, setItemPerPageList] = useState(21)
    const [pageCountList, setPageCountList] = useState(0)
    const [isDisable, setIsDisable] = useState(true)
    const [disableUpAssigneeButton, setDisableUpAssigneeButton] = useState(false)
    const [fineBtn, setFineBtn] = useState(true)
    const [backGround, setBackGround] = useState(null)
    const [activeContent,setActiveContent] = useState('')  
    const [courseDescription, setCourseDescription] = useState([])
    const [activeCourse, setActiveCourse] = useState(null)
    const handleAddVal = () => {
        const text1 = [...val, []]
        setVal(text1)
    }


    const handleTextArea = (onChangeValue, i) => {
        const inputData = [...val];
        if (onChangeValue.target.value != '') {
            inputData[i] = onChangeValue.target.value;
            setVal(inputData);
            setIsDisable(false)
            setFineBtn(true)
        }
        else if (onChangeValue.target.value === '') {
            inputData[i] = onChangeValue.target.value;
            setVal(inputData);
            setIsDisable(true)
            setFineBtn(true)
        }

    };

    // const handleAddCorrectVal = () => {
    //     const option1 = [...correct]
    //     setCorrect(option1)
    // }

    const handleCheck = (e, i) => {
        setCorrect(i);
    };

    const handleCheckSequence = () => {
        setCheckOption(optionCheck + 1)
    }


    const handleAddVal2 = () => {
        const text2 = [...val2, []]
        setVal2(text2)
    }

    const handleTextArea2 = (onChangeValue2, i) => {
        const inputData2 = [...val2]
        if (onChangeValue2.target.value != '') {
            inputData2[i] = onChangeValue2.target.value;
            setVal2(inputData2)
            setIsDisable(false)
            setFineBtn(true)
        }
        else if (onChangeValue2.target.value === '') {
            inputData2[i] = onChangeValue2.target.value;
            setVal2(inputData2)
            setIsDisable(true)
            setFineBtn(true)
        }

    }

    const handleAddCorrectVal2 = () => {
        const option2 = [...correct2, []]
        // setCorrect2(option2)
    }



    const handleCheck2 = (e, i) => {
        if (correct2.includes(e)) {
            let arr = [];
            arr = correct2.filter((item) => item != e)
            setCorrect2(arr)
        }
        else {
            setCorrect2([...correct2, e])
        }

    }
    const handleChange1 = (q1) => {
        setFineBtn(true)
        allQuestionsObject.que = q1.target.value;
    }
    const handleAnswer = (a) => {
        setFineBtn(true)
        setVal4(a.target.value);
    }

    const handleCheck3 = (v, type) => {
        if (type == 'true') {
            setCorrect3(v);
            setBackGround(true)
        }
        else if (type == 'false') {
            setCorrect3(v);
            setBackGround(false)
        }

    }



    const checkValNewQuestionModal1 = () => {
        setFineBtn(false)
        setIsDisable(false)
        let obj = {};
        obj.type = queType;
        obj.que = allQuestionsObject.que;
        obj.ans = (queType == 'scq') ? val : (queType == 'mcq') ? val2 : (queType == 'written') ? val4 : '';
        obj.correct = (queType == 'scq') ? correct : (queType == 'mcq') ? correct2 : (queType == 'tfq') ? correct3 : '';
        if (queType != '') setAllQuestionsArray([...allQuestionsArray, obj]);
        setQueType('')
    }

    const setAllQuestionsDetails = () => {
        setFinalTest({ ...finalTest, questions: allQuestionsArray });
        setNoOfQue(allQuestionsArray?.length);
    }


    const { SearchBar } = Search;
    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div className="d-flex justify-content-center">
                <button className="table-action" onClick={() => window.location.href = `protocol/${row.company_id}`}><img src={ExpandIcon} alt="icon" /></button>
                <button className="table-action" onClick={() => { }}><img src={Group878} alt="icon" /></button>
                <button className="table-action" onClick={() => { }}><img src={DeleteBin} alt="icon" /></button>
            </div>
        )
    }
    // const addTextAnswer = ()=>{
    //     alert(textAreaAnswer+1)
    //     setTextAreaAnswer(textAreaAnswer+1)
    // }
    const [updateProgramData, setUpdateProgramData] = useState({ company_id: "", program_name: "", description: "", course: 0, duration: 0, release_date: "", show_release_date: "", obligatory: "", status: 0, referent: "" })
    const [updateProgramModal2, setUpdateProgramModal2] = useState(false)
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [programDatamodel2, setProgramDataModel2] = useState({ status: 0 });
    const [refrentData, setRefrentData] = useState([])
    const [previousReferent, setPreviousReferent] = useState("");

    const [generalColumn, setGeneralColumn] = useState([{
        // text: t("superadmin.protocol.name"),
        // text: "Name",
        dataField: "column1",
        sort: true,
    }]);
    const [token, setToken] = useState(() => {
        const saved = localStorage.getItem("R2");
        const initialValue = JSON.parse(saved);
        return initialValue.access_token || "";
      })
    const formatDate = (date) => {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [day, month, year].join(".");
    };

    const getCompaniesData = async (assignee) => {
        setIsLoading(true);
        try {
            const payload = {
                company: company_id,
                role_id: "1"
            }
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     token,
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getAllDataResult = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_GET_COMPANY_USER_LIST}`,
                payload,
                "",
                ""
            );
            if (getAllDataResult && getAllDataResult.status === 200) {
                setIsLoading(false);
                if (getAllDataResult.data.data?.length > 0) {
                    let arr = []
                    let users = []
                    await getAllDataResult.data.data?.forEach((data, i) => {
                        let obj = {
                            name: data?.name || "",
                            user_id: data?.user_id || "",
                        }
                        arr = [...arr, obj]
                        if (assignee.length > 0) {
                            if (assignee.includes(data.user_id))
                                users = [...users, obj]
                        }
                    })
                    setCurrentItems(arr?.slice(0, itemsPerPage))
                    setCurrentPage(1)
                    setPageCount(Math.ceil(arr?.length / itemsPerPage))
                    setCompaniesData(arr);
                    setAllUser(arr)
                    setProgramUser(users)
                    setShowProgramUsers(users.slice(0, itemPerPageList))
                    setPageCountList(Math.ceil(users?.length / itemPerPageList))
                    setCurrentPageList(1)
                }
                else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else if (getAllDataResult && getAllDataResult.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }
    const updateAssigneeAPI = async () => {
        setIsLoading(true);
        setDisableUpAssigneeButton(true)
        try {
            const payload = {
                company_id: company_id,
                program_id: program_id,
                assignee: selectedUser
            }
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await PutRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_MODIFY_ASSIGNEE}`,
                    payload,
                    "",
                    ""
                );
                if (getAllDataResult && getAllDataResult.status === 200) {
                    closeModal()
                    getProgramData()
                    setDisableUpAssigneeButton(false)
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setAlertMessage({ show: true, spinner: false, message: `${getAllDataResult.data.message}`, class: "alert alert-danger" })
                    setIsLoading(false);
                    setDisableUpAssigneeButton(false)
                } else {
                    setIsLoading(false);
                    setDisableUpAssigneeButton(false)
                }
            } else {
                setIsLoading(false);
                setDisableUpAssigneeButton(false)
            }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
            setDisableUpAssigneeButton(false)
        }
    }

    const getProgramData = async () => {
        setIsLoading(true);
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     token,
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getProgramDetails = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ADMIN_PROGRAM_DETAILS}?companyId=${company_id}&programId=${program_id}`
            );

            if (getProgramDetails && getProgramDetails.status === 200) {
                setProgramData(getProgramDetails.data.data);
                setCourseContent(getProgramDetails.data.data.course)
                setCourseData(Object.keys(getProgramDetails.data.data.course));
                setProgramAssignee(getProgramDetails.data.data?.assignee)
                if (localStorage.getItem('langauage') == 'it' && getProgramDetails.data.data.courseITA) {                    
                    setCourseDescription(getProgramDetails.data.data.courseDescriptionITA)                 
                }
                else {                 
                    if (getProgramDetails.data.data.courseDescription) setCourseDescription(getProgramDetails.data.data.courseDescription)                 
                }
                if (getProgramDetails.data.data?.assignee?.length > 0) {
                    setProgramAssignee(getProgramDetails.data.data.assignee)
                }
                getCompaniesData(getProgramDetails.data.data?.assignee);

            } else if (getProgramDetails && getProgramDetails.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }

    const updateProgramDetail = async (modal2) => {
        setIsLoading(true);
        try {
            let payload = {}
            if (modal2 == "modal2") {
                payload = {
                    program_id: programData.id,
                    program_name: programData.program_name,
                    referent: programDatamodel2.referent,
                    status: programDatamodel2.status,
                    duration: Number(programDatamodel2.duration),
                    obligatory: programDatamodel2.obligatory,
                    release_date: programDatamodel2.release_date
                }
            } else {
                payload = {
                    program_id: updateProgramData.id,
                    program_name: updateProgramData.program_name,
                    description: updateProgramData.description,
                    referent: updateProgramData.referent,
                    status: updateProgramData.status,
                    duration: updateProgramData.duration,
                    obligatory: updateProgramData.obligatory,
                    release_date: updateProgramData.release_date
                }
            }

            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            let payloadURL = (modal2 == "modal2") ? process.env.REACT_APP_UPDATE_PROGRAM : process.env.REACT_APP_UPDATE_PROGRAM_PARTIAL
            if (refreshToken) {
                const updateProgramResult = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${payloadURL}`,
                    payload,
                    "",
                    ""
                );
                if (updateProgramResult && updateProgramResult.status === 200) {
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else if (updateProgramResult && updateProgramResult.status === 203) {
                    setIsLoading(false);
                    setAlertMessage({ show: true, spinner: false, message: updateProgramResult.data?.message, class: "alert alert-danger" })
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    }

    const columnFormatter1 = (cell, row) => {
        return <div>{row.column1?.name ? row.column1?.name : ""}</div>
    }
    const columnFormatter2 = (cell, row) => {
        return <div>{row.column2?.name ? row.column2?.name : ""}</div>
    }
    const columnFormatter3 = (cell, row) => {
        return <div>{row.column3?.name ? row.column3?.name : ""}</div>
    }

    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                // text: t("superadmin.protocol.name"),
                // text: "Name",
                dataField: "column1",
                sort: true,
                formatter: columnFormatter1,
                headerStyle: {
                    display: "none"
                }
            },
            {
                // text: t("superadmin.protocol.name"),
                // text: "Email",
                dataField: "column2",
                sort: true,
                formatter: columnFormatter2,
                headerStyle: {
                    display: "none"
                }
            },
            {
                // text: t("superadmin.protocol.name"),
                // text: "Email",
                dataField: "column3",
                sort: true,
                formatter: columnFormatter3,
                headerStyle: {
                    display: "none"
                }
            },

        ])
    };
    const callApi = async () => {
        setIsLoading(true)
        let company = await getCompanyDetails(company_id)
        await getCompanyRefrent()
        if (company) setCompanyDetails(company)
        setIsLoading(false)
        await getProgramData()
        await getProgramSummery()
        handleTranslationLang()

    }
    useEffect(() => {
        callApi()
    }, []);
    const handleModifyUserModal = () => {
        if (programAssignee.length > 0) {
            setSelectedUser(programAssignee)
        }
        setModifyUserModal(true)
    }
    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#6d71f9';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'white';
        } else {
            // activeStyle.backgroundColor = 'gray';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = '';
            activeStyle.color = '#6d71f9';
        }
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-between pagination-list mt-3 mb-2">
                {/* <div className="d-flex justify-content-center align-items-center">
                    {pages.map((p, index) => {
                        if (p.page === "<") return <div key={index} className={`page ${p.page === 1 ? "disable-btn" : "enable-btn"}`} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon" /></div>
                        if (p.page === ">") return <div key={index} className="page" onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon" /></div>
                        if (p.active) return <div key={index} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                        return (
                            <div className="page" key={index} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                        )
                    })}
                </div>
                <div >
                    <button className="ricontrolla" onClick={() => handleModifyUserModal()}>{t("superadmin.training.popup.modifyButton")}</button>
                </div> */}
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }

    const closeModal = () => {
        setFinalTest({})
        setNoOfQue(null)
        setAllQuestionsArray([])
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        setModifyUserModal(false)
        setSelectedUser(programAssignee)
        setCurrentItems(allUser?.slice(0, itemsPerPage))
        setCurrentPage(1)
        setCompaniesData(allUser)
        setTestProgram(false)
        setModifyProgramOne(false)
        setUpdateProgramModal2(false)
        setAddTestButton(false)
        setMultipleChoice(false)
        setSingleChoice(false)
        setWrittenChoice(false)
        setAddProgramModal2Content(false)
        setNoOfQue()

    }
    const handleSelectUser = (id) => {
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
        if (selectedUser.includes(id)) {
            let arr = selectedUser
            arr = arr.filter((item) => item !== id)
            setSelectedUser(arr)
        } else {
            setSelectedUser([...selectedUser, id])
        }
    }
    const handlePageClick = (event) => {
        let page = event.selected + 1;
        let start = event.selected * itemsPerPage;
        setCurrentPage(page)
        setCurrentItems(companiesData.slice(start, start + itemsPerPage))
    };
    const handlePageClickList = (event) => {
        let page = event.selected + 1;
        let start = event.selected * itemPerPageList;
        setCurrentPageList(page)
        setShowProgramUsers(programUser.slice(start, start + itemPerPageList))
    };
    const handleSearch = (e) => {
        let value = e.target.value;
        let arr = allUser;
        if (value == "") {
            setCompaniesData(allUser)
            setCurrentItems(allUser.slice(0, itemsPerPage))
            setCurrentPage(1)
        } else {
            arr = arr.filter((item) => item.name?.toLowerCase().includes(value.toLowerCase()))
            setCompaniesData(arr)
            setCurrentItems(arr.slice(0, itemsPerPage))
            setCurrentPage(1)
        }
    }

    const handleChangeUpdate = (event) => {
        let { name, value } = event.target;
        setUpdateProgramData({ ...updateProgramData, [name]: value });
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    };
    const handleChangeUpdate2 = (event) => {
        let { name, value } = event.target;
        if (name == 'status') value = Number(value)
        setProgramDataModel2({ ...programDatamodel2, [name]: value });
        setAlertMessage({ show: false, spinner: false, message: "", class: "" })
    };
    const checkValNewProgramModal1 = () => {
        if (updateProgramData.program_name.length < 1 || updateProgramData.program_name.length > 150) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_program_name")}`, class: "alert alert-danger" })
        } else if (updateProgramData.description.length < 10 || updateProgramData.description.length > 400) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.invalid_description")}`, class: "alert alert-danger" })
        } else {
            // closeModal()
            setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" })
            updateProgramDetail()
        }
    }

    const checkValUpdateProgramModal2 = () => {
        let status = [1, 2, 3]
        if (!status.includes(programDatamodel2.status)) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_state")}`, class: "alert alert-danger" })
        } else if (!programDatamodel2.obligatory) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.popup.select_obligatory")}`, class: "alert alert-danger" })
        }
        else {
            setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" })
            updateProgramDetail("modal2")
        }
    }

    const handleQuestions = (evn) => {
        let { name, value } = evn.target;
        setFinalTest({ ...finalTest, [name]: value });

    }

    const questionsSubmitFun = () => {
        // setFinalTest({ ...finalTest, noOfQuestions: noOfQue });
        // if (isNaN(finalTest.noOfQuestions) || (finalTest.noOfQuestions > 50)) {
        //     setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.test_program.noOfQueErr")}`, class: "alert alert-danger" })
        // } else
        if ((!finalTest.description) || (finalTest.description.length > 400)) {
            setAlertMessage({ show: true, spinner: false, message: `${t("superadmin.training.test_program.descErr")}`, class: "alert alert-danger" })
        }
        else if (isNaN(finalTest.percentOfCorrectAns) || isNaN(finalTest.percentOfCorrectAns > 90) || (finalTest.percentOfCorrectAns < 50)) {
            setAlertMessage({ show: true, spinner: false, message: t("superadmin.training.test_program.percentageErr"), class: "alert alert-danger" })
        }
        else {
            setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" })
            addTestRecords()
        }
    }
    const modifyUserModalContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" onClick={() => setUpdateProgramData(updateProgramData)} />
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.modify_assignee")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                            <div className=" searchbar d-flex justify-content-end">
                                <div className="position-relative">
                                    <img src={Group698} className="search_icon-new" alt="icon" />
                                    <label htmlFor="search-bar-0" className="search-label">
                                        <span id="search-bar-0-label" className="sr-only"></span>
                                        <input id="search-bar-0" aria-labelledby="search-bar-0-label" className="search-bar-new form-control" placeholder={t("superadmin.protocol.search")} type="text" onChange={handleSearch} />
                                    </label>
                                </div>
                            </div>
                            <div className="user-list-box obbligatorieta-check-new">
                                {(currentItems.length > 0 && currentItems) ?
                                    currentItems.map((data, i) => {
                                        return (
                                            <div key={i} className="my-2">
                                                <label className={`mr-4 check-radiotype ${selectedUser.includes(data.user_id) ? "checked-icon" : ""} `}>
                                                    <input type="checkbox" onChange={() => handleSelectUser(data.user_id)} />
                                                </label>
                                                <span>{data.name}</span>
                                            </div>
                                        )
                                    })
                                    : null}
                            </div>
                        </div>
                        <div className="d-flex sa-program-pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<img src={NEXTICONA} alt="icon" />}
                                previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                renderOnZeroPageCount={null}
                                containerClassName="selectuser-pagination"
                                pageClassName="page-class"
                                previousClassName={currentPage == 1 ? "d-none" : ""}
                                nextClassName={currentPage == pageCount ? "d-none" : ""}
                                forcePage={currentPage - 1}
                            />
                        </div>
                        {alertMessage.show ? <div className="d-flex alert alert-info">
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{t("superadmin.training.popup.wait")} </div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-4">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" disabled={disableUpAssigneeButton} onClick={() => { updateAssigneeAPI(); setAlertMessage({ show: true, spinner: true, message: t("superadmin.training.popup.wait"), class: "alert alert-info" }) }}>{t("superadmin.training.popup.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const addProgramModal2ContentFun = () => {

        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <strong>{t("superadmin.training.popup.add_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="" >
                        <form className="registrati-form registrati-form-new nuovo-programma">
                            <div className="form-group">
                                <div>
                                    <label className="form-label">{t("superadmin.training.test_program.noOfQue")}</label>
                                    <div className="d-flex align-items-center">
                                        <input type="text" className="form-control no-of-que" name="noOfQuestions" value={noOfQue} readOnly />

                                    </div>
                                    <label className="form-label mt-4">{t("superadmin.training.test_program.testDescription1")}</label>
                                    <span className="ml-2">{t("superadmin.training.test_program.testDescription2")}</span>
                                    <div className="d-flex align-items-center test-desc">
                                        <div className="d-flex align-items-center w-100">
                                            <textarea rows={12} type="text" className="form-control-input2" name="description" value={finalTest.description} onChange={handleQuestions} />
                                        </div>
                                    </div>

                                    <label className="form-label mt-4">{t("superadmin.training.test_program.noOfQue")}</label>
                                    <div className="d-flex align-items-center">
                                        <input type="number" className="form-control no-of-percentage" placeholder="00" name="percentOfCorrectAns" value={finalTest.percentOfCorrectAns && finalTest.percentOfCorrectAns !== null ? `${finalTest.percentOfCorrectAns}` : null} onChange={handleQuestions} /> <span className="percentage-sign">%</span>

                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="modal-body scroll-pop scroll-pop-new">
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-5" >
                            <button className="buttons modal-button mr-3" onClick={() => { setAddProgramModal2Content(false); }}>{t("user.Questionnare.backwards")}</button>
                            <button className="buttons modal-button" onClick={() => questionsSubmitFun()}>{t("superadmin.training.popup.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    const addTestProgramContent = () => {
        return (
            <div className="model-pop-new">
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Group84123} alt="icon" />{" "}
                            </div>
                            <strong>{t("superadmin.training.test_program.title")}</strong>
                        </h5>

                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    {
                        //  (addTestButton == false) ?
                        //     <div className="card mt-1 ">
                        //         <button className="ricontrolla" onClick={() => setTestProgram(true)}>{t("superadmin.training.test_program.add_test")}</button>
                        //     </div>
                        //     : null
                    }
                    <div className="modal-body scroll-pop scroll-pop-new ">

                        {
                            (singleChoice) ?
                                singleChoiceQuestions() : null
                        }
                        {
                            (multipleChoice) ?
                                multipleChoiceQuestions() : null
                        }

                        {
                            (writtenChoice) ?
                                writtenChoiceQuestions() : null
                        }
                        {
                            (trueFalseChoice) ?
                                trueFalseChoiceQuestions() : null
                        }

                        <div className="buttons-superadmin">
                            {(singleChoice === true) || (multipleChoice === true) || (writtenChoice === true) || (trueFalseChoice === true) ? <button className={`buttons w-100 ${isDisable ? 'disable-btn' : 'enable-btn'}`} disabled={isDisable} onClick={() => checkValNewQuestionModal1()}>{t("superadmin.training.test_program.sub_title")}</button> : ''}
                        </div>
                        <div className="hight-space">
                            <p className="mt-3">
                                <span className="test-choice ml-1" onClick={() => { setSingleChoice(true); setAddTestButton(true); setQueType('scq') }}>{t("superadmin.training.test_program.choice1")}</span>
                                <span className="test-choice ml-1" onClick={() => { setMultipleChoice(true); setAddTestButton(true); setQueType('mcq') }}>{t("superadmin.training.test_program.choice2")}</span>
                                <span className="test-choice ml-1" onClick={() => { setWrittenChoice(true); setAddTestButton(true); setQueType('written') }}>{t("superadmin.training.test_program.choice3")}</span>
                                <span className="test-choice ml-1" onClick={() => { setTrueFalseChoice(true); setAddTestButton(true); setQueType('tfq') }}>{t("superadmin.training.test_program.choice4")}</span>
                            </p>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin mt-5">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            {/* <button className="buttons modal-button mr-3" onClick={() =>setAllQuestionsDetails()}>test</button> */}

                            <button className={`buttons modal-button ${fineBtn ? 'disable-btn' : 'enable-btn'}`} disabled={fineBtn} onClick={() => { setAllQuestionsDetails(); setAddProgramModal2Content(true); }}>{t("superadmin.training.test_program.fine")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const modifyProgramContentOne = () => {
        return (
            <div className="model-pop-new " >
                <div className="model-pop ">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" />
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.edit_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div></div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="" >
                            <form className="registrati-form registrati-form-new nuovo-programma">
                                <div className="form-group">
                                    <div>
                                        <label className="form-label">{t("superadmin.training.popup.name_program")}</label>
                                        <input type="text" className="form-control" name="program_name" value={updateProgramData.program_name} onChange={handleChangeUpdate} />
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">{t("superadmin.training.popup.description")} ( max 400 {t("superadmin.training.popup.characters")})</label>
                                        <textarea type="text" name="description" className="form-control" value={updateProgramData.description} onChange={handleChangeUpdate} />
                                    </div>
                                    {/* <div className="mt-3">
                                    <label className="form-label">{t("superadmin.training.popup.path")} 1</label>
                                    <input type="file" name="images" id="imgid" className="form-control" multiple />
                                </div> */}
                                </div>

                            </form>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-5">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => checkValNewProgramModal1()}>{t("superadmin.training.popup.end")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const upadateProgramModal2Content = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={EDIT_PENCIL} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.edit_program_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="">
                            <form className="registrati-form registrati-form-new nova-program">
                                <div className="form-group">
                                    <div>
                                        <label className="form-label">{t("superadmin.training.popup.state")}</label>
                                        <div className="d-flex">
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${programDatamodel2.status == 1 ? "active-green" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={1}
                                                        name="status"
                                                        checked={programDatamodel2.status == 1}
                                                        onChange={handleChangeUpdate2}
                                                    />
                                                    <span>{t("superadmin.training.active")}</span>
                                                </label>
                                            </div>
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${programDatamodel2.status == 2 ? "active-red" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={2}
                                                        name="status"
                                                        checked={programDatamodel2.status == 2}
                                                        onChange={handleChangeUpdate2}
                                                    />
                                                    <span>{t("superadmin.training.non_active")}</span>
                                                </label>
                                            </div>
                                            <div className="d-flex mr-0 program-status-radio">
                                                <label className={`radio-white ${programDatamodel2.status == 3 ? "active-yellow" : ""}`}>
                                                    <input
                                                        type="radio"
                                                        className="mr-2"
                                                        value={3}
                                                        name="status"
                                                        checked={programDatamodel2.status == 3}
                                                        onChange={handleChangeUpdate2}
                                                    />
                                                    <span>{t("superadmin.training.scheduled")}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="form-label">{t("superadmin.training.popup.referent")}</label>
                                        {/* <input type="text" name="referent" className="form-control" value={programDatamodel2.referent} onChange={handleChangeUpdate2} /> */}
                                        <select
                                            type="text"
                                            name="referent"
                                            className="form-control"
                                            onChange={(e) => setProgramDataModel2({ ...programDatamodel2, referent: e.target.value })}
                                            value={programDatamodel2.referent}
                                        >
                                            {refrentData.map((opts, i) => (
                                                <option key={i} value={opts.name} >
                                                    {opts.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="d-flex mt-3">
                                        <div className="mr-3">
                                            <label className="form-label">{t("superadmin.training.duration")}</label>
                                            <select type="text" className="form-control" value={programDatamodel2.duration} onChange={(e) => { setProgramDataModel2({ ...programDatamodel2, duration: e.target.value }); setAlertMessage({}) }}>
                                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((data, index) => {
                                                    return <option key={index} value={data}>{data}</option>

                                                })}
                                            </select>

                                        </div>
                                        <div className={!(programDatamodel2.status == 3) ? "disable-program-release" : ""}>
                                            <label className="form-label">{t("superadmin.training.popup.scheduled_release")}</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                data-date-format="DD.MM.YYYY"
                                                aria-describedby="emailHelp"
                                                //   placeholder={todayDate}
                                                value={programDatamodel2.show_release_date}
                                                onChange={(e) => { setProgramDataModel2({ ...programDatamodel2, release_date: new Date(e.target.value).getTime() / 1000, show_release_date: e.target.value }); setAlertMessage({}) }}
                                                min={moment().format('YYYY-MM-DD')}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-3 obbligatorieta-check">
                                        <label className="form-label">{t("superadmin.training.mandatory")}</label>
                                        <div className="d-flex">
                                            <label className={`mr-4 check-radiotype ${programDatamodel2.obligatory == "Yes" ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value="Yes"
                                                    name="obligatory"
                                                    checked={programDatamodel2.obligatory == "Yes"}
                                                    onChange={handleChangeUpdate2}
                                                />
                                                <span>{t("user.Questionnare.yes")}</span>
                                            </label>
                                            <label className={`check-radiotype ${programDatamodel2.obligatory == "No" ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    className="mr-2"
                                                    value="No"
                                                    name="obligatory"
                                                    checked={programDatamodel2.obligatory == "No"}
                                                    onChange={handleChangeUpdate2}
                                                />
                                                <span>{t("user.Questionnare.no")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {alertMessage.show ? <div className={`d-flex mt-3 ${alertMessage.class}`} >
                            {alertMessage.spinner ? <div className="spinner-container-new">
                                <div className="loading-spinner-new">
                                </div>
                            </div>
                                : null}
                            <div className="ml-1">{alertMessage.message}</div>
                        </div> : null}
                        <div className="d-flex justify-content-end buttons-superadmin mt-3" >
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button className="buttons modal-button" onClick={() => checkValUpdateProgramModal2()}>{t("superadmin.training.popup.save")}</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getCompanyRefrent = async () => {
        setIsLoading(true);
        const refrentPayload = {
            "company": company_id,
            "role_id": 2
        };
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     token,
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getRefrentData = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_USER_LIST}`,
                refrentPayload,
                "",
                ""
            );
            if (getRefrentData && getRefrentData.status === 200) {
                setIsLoading(false);
                if (getRefrentData.data?.data?.length > 0) {
                    let arr = [];
                    getRefrentData.data.data.forEach((item) => {
                        if (item.status > 0) {
                            arr = [...arr, {
                                name: item.name || "",
                                user_id: item.user_id || "",
                            }]
                        }

                    })
                    setRefrentData(arr);
                }
            } else {
                setIsLoading(false);
                // setLoading(false);
            }
            // } 
            // else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const addTestRecords = async () => {
        setIsLoading(true);
        const payload = {
            ...finalTest, company_id: company_id, program_id: program_id, noOfQuestions: noOfQue
        };
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                token,
                "refresh_token"
            );
            if (refreshToken) {
                const getTestData = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADD_TEST_RECORD}`,
                    payload,
                    "",
                    ""
                );
                if (getTestData && getTestData.status === 200) {
                    setIsLoading(false);
                    closeModal();
                    getProgramSummery();
                    resetData();
                } else {
                    setIsLoading(false);
                    //  setLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false);
        }
    };


    const getProgramSummery = async () => {
        setIsLoading(true);
        try {
            // const refreshToken = await PostRequest(
            //     `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
            //     {},
            //     token,
            //     "refresh_token"
            // );
            // if (refreshToken) {
            const getProgramTestDetails = await GetRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_PROGRAM_TEST_SUMMARY}?companyId=${company_id}&programId=${program_id}`
            );

            if (getProgramTestDetails && getProgramTestDetails.status === 200) {

                if (getProgramTestDetails?.data?.data?.length > 0) {
                    setProgramTestSummaryData(getProgramTestDetails?.data?.data)
                }
                setIsLoading(false);
                resetData()
            } else if (getProgramTestDetails && getProgramTestDetails.status === 203) {
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
            // } else {
            //     setIsLoading(false);
            // }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }

    const resetData = () => {
        setAllQuestionsArray([])
        setAllQuestionsObject([])
        setFinalTest({})
        setVal([])
    }

    const singleChoiceQuestions = () => {
        return (
            <>
                <div className="" >
                    <form className="registrati-form registrati-form-new nuovo-programma">
                        <div className="form-group">
                            <div>
                                <label className="form-label">{t("superadmin.training.test_program.question")}</label>
                                <div className="d-flex align-items-center domanda">
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" onChange={handleChange1} />
                                </div>
                                {val.map((data, i) => (
                                    <div className="d-flex align-items-center domanda" key={i}>
                                        <div className="d-flex align-items-center w-100">
                                            <span>{String.fromCharCode(65 + i)}</span>
                                            <textarea
                                                rows={2}
                                                type="text"
                                                className="form-control-input2"
                                                name="ans"
                                                value={val[i]}
                                                onChange={e => handleTextArea(e, i)}
                                            />
                                        </div>
                                        <div className="d-flex obbligatorieta-check domanda-right-check">
                                            <label className={`mr-4 check-radiotype ${correct === i ? "checked-icon" : ""}`}>
                                                <input
                                                    type="radio"
                                                    checked={correct === i}
                                                    className="mr-2"
                                                    name="correct"
                                                    value={i}
                                                    onChange={e => handleCheck(e, i)}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <span className="text-color-save" onClick={() => { handleAddVal(); handleCheckSequence(); }}>{t("superadmin.training.test_program.addAnswer")}</span>
                        </div>

                    </form>
                </div>
            </>
        )
    }

    const multipleChoiceQuestions = () => {
        return (
            <>
                <div className="" >
                    <form className="registrati-form registrati-form-new nuovo-programma">
                        <div className="form-group">
                            <div>
                                <label className="form-label">{t("superadmin.training.test_program.question")}</label>

                                <div className="d-flex align-items-center domanda">
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" onChange={handleChange1} />
                                </div>
                                {
                                    val2.map((data, i) => {
                                        return (
                                            <div className="d-flex align-items-center domanda" key={i}>
                                                <div className="d-flex align-items-center w-100">
                                                    <span>{(i == "0") ? String.fromCharCode(65) : String.fromCharCode(65 + i)}</span>
                                                    <textarea rows={2} type="text" className="form-control-input2" name="ans" onChange={e => handleTextArea2(e, i)} />

                                                </div>
                                                <div className="d-flex obbligatorieta-check domanda-right-check">
                                                    <label className={`mr-4 check-radiotype ${(correct2.includes(i + 1)) ? "checked-icon" : ""} `}>
                                                        <input type="checkbox" className="mr-2" name="correct2" value={i + 1} onChange={e => handleCheck2(i + 1)} />
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <span className="text-color-save" onClick={() => { handleAddVal2(); handleAddCorrectVal2(); }}>{t("superadmin.training.test_program.addAnswer")}</span>
                        </div>

                    </form>
                </div>
            </>
        )
    }

    const trueFalseChoiceQuestions = () => {
        return (
            <>
                <div className="" >
                    <form className="registrati-form registrati-form-new nuovo-programma">
                        <div className="form-group">
                            <div>
                                <label className="form-label">{t("superadmin.training.test_program.question")}</label>
                                <input type="hidden" className="" name="type" value="tfq" />
                                <div className="d-flex align-items-center domanda">
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" onChange={handleChange1} />
                                </div>
                                {
                                    <div className="d-flex align-items-center domanda">
                                        <div className="d-flex obbligatorieta-check domanda-right-check mt-2 mb-4">
                                            <span className={`test-choice ml-1 ${(backGround == true) ? 'true-btn' : 'false-btn'}`} value="1" onClick={() => { handleCheck3(1, 'true') }}>True</span>
                                            <span className={`test-choice ml-1 ${(backGround == false) ? 'true-btn' : 'false-btn'}`} value="0" onClick={() => { handleCheck3(0, 'false') }}>False</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                    </form>
                </div>
            </>
        )
    }

    const writtenChoiceQuestions = () => {
        return (
            <>
                <div className="" >
                    <form className="registrati-form registrati-form-new nuovo-programma">
                        <div className="form-group">
                            <div>
                                <label className="form-label">{t("superadmin.training.test_program.question")}</label>
                                <div className="d-flex align-items-center domanda">
                                    <textarea rows={1} type="text" className="form-control-input2 que-font" name="que1" onChange={handleChange1} />
                                </div>
                                <div className="d-flex align-items-center domanda">
                                    <div className="d-flex align-items-center w-100">
                                        <textarea rows={10} type="text" className="form-control-input2" name="ans1" onChange={handleAnswer} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        )
    }
    const activeContentConfig = (id) => {
        if (activeContent == id) setActiveContent('')
        else setActiveContent(id)
    }
    return (
        <>
            <Modal title="" size="sm" contentClasses="" onClose={() => setModifyUserModal(false)} show={modifyUserModal}>
                {modifyUserModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setTestProgram(false)} show={testProgram}>
                {addTestProgramContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setModifyProgramOne(false)} show={modifyProgramOne}>
                {modifyProgramContentOne()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setUpdateProgramModal2(false)} show={updateProgramModal2}>
                {upadateProgramModal2Content()}
            </Modal>
            <Modal title="" size="sm" contentClasses="" onClose={() => setAddProgramModal2Content(false)} show={addProgramModal2Content}>
                {addProgramModal2ContentFun()}
            </Modal>

            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar />
                        <div className="col-md-10 pr-0 p0 ipd-righ">
                            <div className="right-dashboard topspace">
                                <div className="d-flex justify-content-between company-name-programs">
                                    <h2 className="heading-dash">
                                        <button className="back-button" onClick={history.goBack}><img src={PREV_PAGE_ICON} alt="icon" /></button>
                                        {companyDetails.company_name} Programs</h2>

                                </div>
                                {/* <div className="graph-tabs">
                                    <ul
                                        className="nav nav-tabs nav-stacked"
                                        id="myTab"
                                        role="tablist"
                                    >
                                        <li className="nav-item" onClick={() => setPersonalText("detail")}>
                                            <a
                                                className={
                                                    "nav-link " +
                                                    (personalText == "detail"
                                                        ? "active show"
                                                        : "")
                                                }
                                                id="first-tab"
                                                data-toggle="tab"
                                                href="#first"
                                                role="tab"
                                                aria-controls="first"
                                                aria-selected="true"
                                            >
                                                <span className="das-icontabs">
                                                    <div className="dashboard-tabs-icon deskt-n"></div>
                                                    <span className="visi-none">
                                                        {t("superadmin.program.detail")}
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className="nav-item" onClick={() => setPersonalText("static")}>
                                            <a
                                                className={
                                                    "nav-link " +
                                                    (personalText == "static" ? "active show" : "")
                                                }
                                                id="second-tab"
                                                data-toggle="tab"
                                                href="#second"
                                                role="tab"
                                                aria-controls="second"
                                                aria-selected="false"
                                                onClick={() =>
                                                    window.dispatchEvent(new Event("resize"))
                                                }
                                            >
                                                <span className="das-icontabs">
                                                     <div className="dashboard-tabs-icon deskt-n stress-icons"></div>
                                                    <span className="visi-none">
                                                        {t("superadmin.program.static")}
                                                    </span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="card-body">
                                    <div className="tab-content">
                                        <>
                                            <div
                                                className={
                                                    "tab-pane fade " +
                                                    (personalText == "detail"
                                                        ? "active show"
                                                        : "")
                                                }
                                                id="first"
                                                role="tabpanel"
                                                aria-labelledby="first-tab"
                                            >
                                                <div className="row">
                                                    <div className="col-md-6 right_lines fade-lines pl-0 mb-5 ">
                                                        <div className="d-flex justify-content-between align-items-start">
                                                            <div className="text-left">
                                                                <h6 className="modal-title pop_heading " style={{ textAlign: "left" }}>
                                                                    <span className="program-title-heading">{programData.program_name}</span>
                                                                </h6>
                                                                <p className="margin-left20"  >{programData.description}</p>
                                                            </div>
                                                            {/* <img className="edit-margin" src={Group878} alt="icon" /> */}
                                                            {/* <span className="button-cursor" onClick={() => { setModifyProgramOne(true); setUpdateProgramData(programData) }}><img className="edit-margin" src={Group878} alt="icon" /></span> */}

                                                        </div>
                                                        <div id="accordion" className="accordion Percorso-text">
                                                            <div className="iapdwful p-r15">
                                                                <div className="card mb-0">
                                                                    {courseData.length > 0 && courseData.map((data, i) => {
                                                                        return (
                                                                            <div className="bot-lini" key={i}>
                                                                                <p className="course-name pt-2">{(programData.courseName && programData.courseName?.length )? programData.courseName[i] :data}</p>
                                                                                { courseDescription.length > 0 ? <div className={`${activeCourse == i ? "" : "text-ellipsis"} text-left cursor-pointer text-color-wh`} onClick={()=> setActiveCourse(i)}>
                                                                                    {courseDescription[i]}
                                                                                </div> : null}
                                                                                {courseContent[data].length > 0 ? (
                                                                                    <div>
                                                                                        {courseContent[data].map((d, j) => {
                                                                                            const uniqueId = `collapse_${i}_${j}`;
                                                                                            return (
                                                                                                <React.Fragment key={j}>
                                                                                                    <div
                                                                                                        className="card-header percorso-header cursor-pointer percorso-header-new collapsed"
                                                                                                        data-toggle="collapse"
                                                                                                        data-target={`#${uniqueId}`}
                                                                                                        aria-expanded="true"
                                                                                                    >
                                                                                                        <a className="card-title">
                                                                                                            <div className={`percorso-heading ${d.content_Id == activeContent ? "" : "text-ellipsis"}`} onClick={() => activeContentConfig(d.content_Id)}>
                                                                                                                {d.content_Name}
                                                                                                            </div>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        id={uniqueId}
                                                                                                        className="card-body collapse collapse-dark"
                                                                                                        data-parent="#accordion"
                                                                                                    >
                                                                                                        <div className="d-flex align-items-start">
                                                                                                            {/* <img className="mr-2" src={DOCUMENTO_ICONA} alt="icon" />
                                                                                                            <p className="course-title">{d.contentDescription}</p> */}
                                                                                                            <img className="mr-2 mt-2" src={(d.content_type == "document") ? DOCUMENTO_ICONA : VIDEO_ICONAvideo} alt="icon" />
                                                                                                            <p className="course-title">{(d.contentDescription) ? d.contentDescription : "No Description"}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        );
                                                                    })}

                                                                    {programTestSummaryData?.length > 0 && programTestSummaryData?.map((data, i) => {

                                                                        return (
                                                                            <div className="bot-lini" key={i}>
                                                                                <p className="course-name pt-2">Test</p>

                                                                                <div
                                                                                    className="card-header percorso-header collapsed"
                                                                                    data-toggle="collapse"
                                                                                    data-target={`#${i}`}
                                                                                    aria-expanded="true"
                                                                                >
                                                                                    <a className="card-title">
                                                                                        <div className="percorso-heading">
                                                                                            <div className="m-1">{data.description}</div>
                                                                                            <p className="m-1"><strong>{`${data.noOfQuestions}${t("superadmin.training.popup.request")}/${data.percentOfCorrectAns}%${t("superadmin.training.popup.answer")}`}</strong></p>
                                                                                        </div>

                                                                                    </a>
                                                                                </div>
                                                                                <div
                                                                                    id={i}
                                                                                    className="card-body collapse"
                                                                                    data-parent="#accordion"
                                                                                >
                                                                                    <div className="d-flex align-items-start">
                                                                                        <img className="mr-2" src={DOCUMENTO_ICONA} alt="icon" />
                                                                                        <p className="course-title">{data.description}</p>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        );
                                                                    })}



                                                                </div>
                                                                {/* <div className="card mt-5">
                                                                    <button className="ricontrolla" onClick={() => setTestProgram(true)}>
                                                                        {t("superadmin.training.test_program.add_test")}
                                                                    </button>
                                                                </div> */}
                                                            </div>
                                                        </div>



                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="d-flex">
                                                            <div className="Stato">
                                                                <h3><img src={Statusicona} alt="icon" /> {t("superadmin.program.status")}</h3>
                                                                <p>{
                                                                    (programData.status == 1) ? t("superadmin.program.active_status") : (programData.status == 2) ? t("superadmin.program.non_active_status") : t("superadmin.program.schedule_status")
                                                                }</p>
                                                            </div>
                                                            <div className="Stato">
                                                                <h3><img src={USER_ICONA} alt="icon" /> {t("superadmin.program.referente")}</h3>
                                                                <p>{programData.referent}

                                                                </p>
                                                            </div>
                                                            <div className="Stato">
                                                                <h3><img src={CALENDARIO_ICONA} alt="icon" /> {t("superadmin.program.duration")}</h3>
                                                                <p>{programData.duration + " " + t("superadmin.program.week")}


                                                                </p>
                                                            </div>
                                                            <div className="Stato border-r-o">
                                                                <h3><img src={PASSWORD_ICONA} alt="icon" /> {t("superadmin.program.mandatory")}</h3>
                                                                <p>{
                                                                    (programData.obligatory == "yes") ? t("superadmin.program.Obligatory_yes") : t("superadmin.program.Obligatory_no")
                                                                }</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <div className="Stato border-r-o">
                                                                <h3><img src={rilascio_icona} alt="icon" /> {t("superadmin.program.release")}</h3>
                                                                <p>
                                                                    {formatDate(
                                                                        programData.release_date * 1000
                                                                    )}
                                                                </p>

                                                            </div>
                                                            {/* <button className="ricontrolla" onClick={() => { setProgramDataModel2({ status: programData.status, obligatory: programData.obligatory, duration: programData.duration, referent: programData.referent, show_release_date: moment(new Date(Number(programData.release_date) * 1000)).format("YYYY-MM-DD"), release_date: `${programData.release_date ? programData.release_date : ""}` }); setUpdateProgramModal2(true) }} >{t("superadmin.training.popup.modifyButton")} </button> */}
                                                        </div>

                                                        {/* <div className="d-flex justify-content-between assegnatari">
                                                            <div>
                                                                <h3>{t("superadmin.program.assignees")}</h3>
                                                            </div>
                                                            <div>
                                                                <span>{programUser.length + " " + t("superadmin.program.persone")}</span>
                                                            </div>
                                                        </div> */}

                                                        {(showProgramUser.length > 0 && showProgramUser) ?
                                                            <div className="program-user-div">
                                                                <div className="program-user-list">
                                                                    {showProgramUser.map((d1, i1) => {
                                                                        return (
                                                                            <div key={i1} className="program-user">
                                                                                <div>{d1.name}</div>
                                                                                {(i1 + 1) % 3 == 1 && <hr className="hr-program-user" />}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                    <div className="d-flex sa-program-pagination">
                                                                        <ReactPaginate
                                                                            breakLabel="..."
                                                                            nextLabel={<img src={NEXTICONA} alt="icon" />}
                                                                            previousLabel={<img src={PRIVIEWICONA} alt="icon" />}
                                                                            onPageChange={handlePageClickList}
                                                                            pageRangeDisplayed={1}
                                                                            marginPagesDisplayed={1}
                                                                            pageCount={pageCountList}
                                                                            renderOnZeroPageCount={null}
                                                                            containerClassName="selectuser-pagination"
                                                                            pageClassName="page-class"
                                                                            previousClassName={currentPageList == 1 ? "d-none" : ""}
                                                                            nextClassName={currentPageList == pageCountList ? "d-none" : ""}
                                                                            forcePage={currentPageList - 1}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <button className="ricontrolla" onClick={() => { handleModifyUserModal() }} >{t("superadmin.training.popup.modifyButton")} </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null}

                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    "tab-pane fade " +
                                                    (personalText == "static" ? "active show" : "")
                                                }
                                                id="second"
                                                role="tabpanel"
                                                aria-labelledby="second-tab"
                                            >
                                                <div className="row">
                                                    {/* <ProgramStatics company_id={company_id} program_id={program_id} /> */}
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {isLoading ? <LazyLoader /> : null}

                </div>
            </section>
            <SidebarMob />
        </>
    );
}

export default SA_Company_Programs;


