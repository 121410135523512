import { useState, useEffect, useRef} from "react";
import VectorDown from "../../assets/Image/VectorDown.svg";
import Group698 from "../../assets/Image/Group698.svg";
import Group733 from "../../assets/Image/Group733.svg";
import Group735 from "../../assets/Image/Group735.svg";
import Adminsidebar from "../admin/sidebar";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import Sidebarmob from "../admin/sidebarmob";
import PostRequest from "../apiConnection/postRequest";
import LazyLoader from "../common/lazyLoader";
import Parser from 'html-react-parser';
import NEXTICONA from "../../assets/Image/NEXTICONA.svg"


function AdminSummary() {
  const ref = useRef(null);
  const [text,setText] = useState('Controlla i tuoi rischi');
  const [isLoading, setIsLoading] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [controlValue, setControlValue ] = useState(false);
  const [filterValue, setFilterValue] = useState([]);
  const [filterValueData, setFilterValueData] = useState([]);
  const [male,setMale] = useState([])
  const [general,setGeneral] = useState([])
  const [female,setFemale] = useState([])
  const [others,setOthers] = useState([])
  const [redmale,setredMale] = useState([])
  const [redgeneral,setredGeneral] = useState([])
  const [redfemale,setredFemale] = useState([])
  const [redothers,setredOthers] = useState([])
  const [source, setSource] = useState([])
  const [redsource, setRedSource] = useState([])
  const [filteredData, setFilteredData] = useState([]);

  const [generalColumn, setGeneralColumn] = useState([
    {
      text: "Parametro",
      dataField: "paramFullname",
      sort: true,
    },
    {
      text: "VALUTAZIONE MEDIA",
      dataField: "color_code",
      sort: true,
    },
    {
      text: "Media",
      dataField: "meadian",
      sort: true,
    },
    {
      text: "Mediana",
      dataField: "mean",
      sort: true,
    },
    {
      text: "Deviazione ST",
      dataField: "standard_deviation",
      sort: true,
    },
  ]);

  useEffect(() => {
    getAllData(false, []);
  }, []);

const swithSource = (updatesource, updateRedSource) =>{
  setFilterValue([]);
  setSource(updatesource);
  setRedSource(updateRedSource);
}
const updateFilterSlice = (selectedoption) =>{
  console.log(selectedoption);
  setSelection(selectedoption);
}

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (filterShow && ref.current && !ref.current.contains(e.target)) {
        setFilterShow(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [filterShow])
 
  const getAllData = async (control_value , filter_val) => {
   
    const payload = {
      filter : filter_val,
      control_value : control_value
    };
    setIsLoading(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        "",
        "refresh_token"
      );
      if(refreshToken){
        const getAllDataResult = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ADMIN_REPORT}`,payload,
          "",""
        );
        if(getAllDataResult  && getAllDataResult.status === 200){
         
          setIsLoading(false);
          let generaldata = getAllDataResult.data.data.general;
          let maledata = getAllDataResult.data.data.male;
          let femaledata = getAllDataResult.data.data.female;
          let othersdata = getAllDataResult.data.data.nonBinary;
          let temparray = [];
          let temparray1 = [];
          let temparray2 = [];
          let temparray3 = [];
          let x = 0;
          let allfiters = [];
          let filtergeneraldataRed = [];
          let filtermaledataRed = [];
          let filterfemaledataRed = [];
          let filterothersdataRed = [];
          femaledata.forEach((element) => {
            x++;
            const filterObj = {
              id : a,
              name : element.paramFullname,
              key : element.param,
              value : element.param,
            }
            
            allfiters.push(filterObj);
            let color_was = element.color_code;
            for (let i = 0; i < keyCount - 1; i++) {
              const tempDatePrev = "media" + (parseInt(i));
              for (let key in tempDatePrev
                
                ) {
                if (key === tempDate) {
                  copyElement[key] = element[key];
                  if (parseFloat(copyElement[tempDatePrev]) < parseFloat(element[key])) {
                    element[key] = Parser(`<span class="arrow_up">` + element[key].toFixed(0) + `</span>`)
                  } else if (parseFloat(copyElement[tempDatePrev]) > parseFloat(element[key])) {
                    element[key] = Parser(`<span class="arrow_down">` + element[key].toFixed(0) + `</span>`)
                  } else {
                    element[key] = Parser(`<span>` + element[key].toFixed(0) + `</span>`)
                  }
                }
              }
            };
            let s='<span class="'+element.color_code+' circle"></span>';    
            element.color_code = Parser(s)
            element.color = color_was;
            temparray.push(element)
            if ((color_was==="red") || (color_was==="yellow")){
              filterfemaledataRed.push(element);
            }
          });
          generaldata.forEach((element) => {
            let color_was = element.color_code;
            let j='<span class="'+element.color_code+' circle"></span>';    
            element.color_code = Parser(j)
            element.color = color_was;
            temparray1.push(element)
            if ((color_was==="red") || (color_was==="yellow")){
              filtergeneraldataRed.push(element);
            }
          });
          maledata.forEach((element) => {
            let color_was = element.color_code;
            let m='<span class="'+element.color_code+' circle"></span>';    
            element.color_code = Parser(m)
            element.color = color_was;
            temparray2.push(element)
            if ((color_was==="red") || (color_was==="yellow")){
              filtermaledataRed.push(element);
            }
          });
          othersdata.forEach((element) => {
            let color_was = element.color_code;
            let r='<span class="'+element.color_code+' circle"></span>';    
            element.color_code = Parser(r)
            element.color = color_was;
            temparray3.push(element)
            if ((color_was==="red") || (color_was==="yellow")){
              filterothersdataRed.push(element);
            }
          });
          allfiters.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
          filtermaledataRed.sort((a,b) => (a.color > b.color) ? 1 : ((b.color > a.color) ? -1 : 0))
          filterfemaledataRed.sort((a,b) => (a.color > b.color) ? 1 : ((b.color > a.color) ? -1 : 0))
          filtergeneraldataRed.sort((a,b) => (a.color > b.color) ? 1 : ((b.color > a.color) ? -1 : 0))
          filterothersdataRed.sort((a,b) => (a.color > b.color) ? 1 : ((b.color > a.color) ? -1 : 0))
          temparray1.sort((a,b) => (a.paramFullname > b.paramFullname) ? 1 : ((b.paramFullname > a.paramFullname) ? -1 : 0))
          temparray2.sort((a,b) => (a.paramFullname > b.paramFullname) ? 1 : ((b.paramFullname > a.paramFullname) ? -1 : 0))
          temparray3.sort((a,b) => (a.paramFullname > b.paramFullname) ? 1 : ((b.paramFullname > a.paramFullname) ? -1 : 0))
          temparray.sort((a,b) => (a.paramFullname > b.paramFullname) ? 1 : ((b.paramFullname > a.paramFullname) ? -1 : 0))
          setFilterValueData(allfiters);
          setredMale(filtermaledataRed);
          setredFemale(filterfemaledataRed);
          setRedSource(filtergeneraldataRed);
          setredGeneral(filtergeneraldataRed);
          setredOthers(filterothersdataRed);
          setGeneral(temparray1);
          setSource(temparray1);
          setFemale(temparray);
          setOthers(temparray3);
          setMale(temparray2);
        }else if(getAllDataResult && getAllDataResult.status === 203){
          setIsLoading(false);
        }else{
          setIsLoading(false);
        }
      }else{
        setIsLoading(false);
      }  
    } catch (error) {
      setIsLoading(false);
    }
  };

 
  const { SearchBar } = Search;

  // const filterValueData = [
  //   { id: 1, name: "HR BPM", key :"HR_BPM" },
  //   { id: 2, name: "BMI CALC", key: "BMI_CALC" },
  //   { id: 3, name: "SNR", key:"SNR" },
  // ];
  
  const changeFilter = () => {
    setFilterShow(!filterShow);
    setText('Controlla i tuoi rischi');
    setControlValue(false);
  }
  const onSearch = () =>{
    setControlValue(false);
    getAllData(false, filterValue);
  }

  const onChangeFilter = (e) =>{
    const value = e.target.value;
    const status = e.target.checked;
    if(status){
      let temp = filterValue;
      temp.push(value);
      setFilterValue(temp);
    }else{
      let temp1 = filterValue;
      temp1.pop(value);
      setFilterValue(temp1);
    }  
    console.log("filterValue", filterValue)
    refreshTable();   
  }

  const refreshTable = () =>{
    let tempData = [];
    source.forEach((element) => {
      if (filterValue.includes(element.param)){
        tempData.push(element);
      }
    })
    setFilteredData(tempData);
  }
  
  const changeText = ()=>{
    setText(!text);
    if(controlValue){
      setControlValue(false);
    }else{
      setControlValue(true);
    }
  }
 
  const pageListRenderer = ({
    pages,
    onPageChange
  }) => {
    const pageWithoutIndication = pages.filter(p => typeof p.page === 'string');
    return (
      <>
      <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 inline-text relative">

        {
          pageWithoutIndication.map((p,i) => (
            (p.page ==='>') ? (
              <div className="left-side" key={'key'+i}>
               <div className="text-right Prossimo prom-non visi-none textcolor" onClick={ () => onPageChange(p.page) }>
                Prossimo <img src={NEXTICONA} alt="icon" />
              </div>
              </div>
            ) : (
              <div className="right-side" key={'key'+i}>
               <div className="text-right Prossimo prom-non visi-none textcolor prcedenteSpaceRight " onClick={ () => onPageChange(p.page) }>
                 <img className="arrowLeft" src={NEXTICONA} alt="icon" />  Precedente
               </div>
              </div>
            )

          ))
        }
      </div>
        
      </>
    );
  };
  const resetFilters = () =>{
    setFilterValue([]);
    refreshTable();
    setFilterShow(false);
  }
  const options = {
    pageListRenderer
  };

  return (
    <>
       <section className="dashboard-mobile">
        <div className="container-fluid full_bg">
          <div className="row">
            <Adminsidebar />
            <div className="col-md-10 pr-0 ipd-righ">
              <div className="right-dashboard topspace">
                <h2 className="heading-dash">Tabella Riepilogativa</h2>
                <div className="graph-tabs report-mob adrepl">
                  <ul
                    className="nav nav-tabs nav-stacked"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" onClick={()=>swithSource(general, redgeneral)}>
                      <a
                        className="nav-link active"
                        id="first-tab"
                        data-toggle="tab"
                        href="#Generale"
                        role="tab"
                        aria-controls="first"
                        aria-selected="true"
                      >
                        <span>Generale</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={()=>swithSource(male, redmale)}>
                      <a
                        className="nav-link"
                        id="first-tab"
                        data-toggle="tab"
                        href="#donne"
                        role="tab"
                        aria-controls="first"
                        aria-selected="true"
                      >
                        <span>Donne</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={()=>swithSource(female, redfemale)}>
                      <a
                        className="nav-link"
                        id="first-tab"
                        data-toggle="tab"
                        href="#Uomini"
                        role="tab"
                        aria-controls="first"
                        aria-selected="true"
                      >
                        <span>Uomini</span>
                      </a>
                    </li>
                    <li className="nav-item" onClick={()=>swithSource(others, redothers)}>
                      <a
                        className="nav-link"
                        id="first-tab"
                        data-toggle="tab"
                        href="#binario"
                        role="tab"
                        aria-controls="first"
                        aria-selected="true"
                      >
                        <span>Non Binario</span>
                      </a>
                    </li>
                  </ul>
                  <div className="card-body">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="Generale"
                        role="tabpanel"
                        aria-labelledby="first-tab"
                      >
                {(generalColumn.length > 0 )?         
                <div className="chart-table" >
                  <ToolkitProvider
                    keyField="id"
                    key={'datatable'}
                    data={(!controlValue) ? ((filterValue.length === 0) ? ((selection===0) ? redsource : (selection===1) ? redsource.slice(0, 10) : (selection===2) ? redsource.slice(10, 20) : (selection === 3) ? redsource.slice(20,30) : redsource ) : filteredData) : reportDatatutti}
                    columns={generalColumn}
                    search
                    >
                    {(props) => (
                      <div className="table ricsb" >
                        <div className="rieplogo alignflexstart">
                          <div className="filter-box">
                              <div className={`filt-b ${filterShow ? "activeFilter" : ""}`} ref={ref} >
                               <button className="b-box" onClick={changeFilter}><img src={Group733} alt="icon" /> <span className="visi-none">Filtri
                               {/* -{filterValue.length} */}
                               </span> </button>
                                {(filterShow) ?
                                  <div className="list-checkboxes">
                                    <h4>Parametri</h4>
                                    {(filterValue.length===0) ? (<></>
                                    // <button className="search-filter" onClick={onSearch}><img src={Group698} alt="icon"/>Search</button>
                                    ) : (
                                    <button className="search-filter" onClick={resetFilters}>Reset</button>
                                    )}
                                    {filterValueData.map((data,i) => {
                                      let filterStatus= false;
                                      return(
                                        <div className="custom-control custom-checkbox" key={'div'+i}>
                                          <input type="checkbox" defaultChecked={filterValue.includes(data.key)} className="custom-control-input" id={"customCheck"+i} value={data.key} onChange={onChangeFilter} />
                                          <label className="custom-control-label" htmlFor={"customCheck"+i}>{data.name}</label>
                                        </div>
                                      )
                                    })}
                                    <h4>Misurazioni</h4>
                                    <div className="custom-control custom-checkbox">
                                    <input type="radio"  defaultChecked={selection===1} className="custom-control-input" name="fav_language" onClick={()=> updateFilterSlice(1)} id={"selectionfilter1"} />
                                        <label className="custom-control-label"
                                        htmlFor={"selectionfilter1"} onClick={()=> updateFilterSlice(1)}>1 - 10</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                      <input type="radio" defaultChecked={selection===2} className="custom-control-input"  name="fav_language" onClick={()=> updateFilterSlice(2)} id={"selectionfilter2"}/>
                                        <label className="custom-control-label"
                                       htmlFor={"selectionfilter2"} onClick={()=> updateFilterSlice(2)}>10 - 20</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                      <input type="radio" defaultChecked={selection===2} className="custom-control-input"  name="fav_language" onClick={()=> updateFilterSlice(3)} id={"selectionfilter2"}/>
                                        <label className="custom-control-label"
                                       htmlFor={"selectionfilter2"} onClick={()=> updateFilterSlice(2)}>20 - 30</label>
                                        </div>
                                        <div className="custom-control custom-checkbox">
                                      <input type="radio"  defaultChecked={selection===0} className="custom-control-input" name="fav_language" onClick={()=> updateFilterSlice(0)} id={"selectionfilter0"} />
                                        <label className="custom-control-label"
                                       htmlFor={"selectionfilter0"}  onClick={()=> updateFilterSlice(0)}>vedi tutte</label>
                                        </div>
                                  </div>: null}
                                </div>
                             {/* <div className="relative">
                              <div className="form-group icno-drop visi-none">
                                <select
                                  className="form-control repligo-select"
                                  id="exampleFormControlSelect1"
                                  placeholder="dfd"
                                >
                  
                                  <option>Ordina per</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                              </div>
                            </div> */}
                            <div className="searchbar">
                              <img
                                src={Group698}
                                className="search_icon"
                                alt="icon"
                              />
                              <SearchBar
                                placeholder="Cerca"
                                {...props.searchProps}
                              />
                            </div>
                          </div>
                          <button className="ricontrolla visi-none nonbutton1"  onClick={()=>changeText()}>
                            {text ? "Controlla i tuoi rischi" : "Tutti i parametri"}
                          </button>
                        </div>
                        <div className="row">
                        <div className="col-md-9">
                        <BootstrapTable
                            src={VectorDown} alt="icon" 
                            data={(!controlValue) ? ((filterValue.length === 0) ? ((selection===0) ? redsource : (selection===1) ? redsource.slice(0, 10) : (selection===2) ? redsource.slice(10, 20) : redsource ) : filteredData) : reportDatatutti}
                            {...props.baseProps}
                            filter={filterFactory()}
                            pagination={ paginationFactory(options) } 
                            wrapperClasses={'table-responsive'}
                        />
                        </div>
                                    <div className="col-md-3">
                                      <div className="box-rr-ght">
                                        <p className="intervento"> <img src={Group735} alt="icon" />
                                          Priorità di intervento</p>
                                        
                                        <p className="l-height0">
                                          <span className="redline"></span> /
                                        </p>
                                        <p className="l-height0"><span className="redline yellow_lines"></span>Indice di stress mentale<br/>Ansia</p>

                                        <p className="l-height0"><span className="redline light-line"></span>Variabilità battito cardiaco<br/>Rapporto altezza-vita<br/>Rischio di ipercolesterolemia<br/>Fatica mentale</p>
                                    </div>
                                    </div>
                                    </div>
                        <button className="ricontrolla tuoi  deskt-n"  onClick={()=>changeText()}>
                            {text ? "Controlla i tuoi rischi" : "Tutti i parametri"}
                          </button>
                      </div>
                      )}
                   </ToolkitProvider>
                </div>
                 :null} 
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(isLoading)?<LazyLoader />:null} 
        </div>
      </section>
      <Sidebarmob />
    </>
  );
}

export default AdminSummary;
