import React, { useEffect, useState, useParams } from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/Image/logo.svg";
import Group941 from "../../../../assets/Image/Group941.svg";
import Group14351 from "../../../../assets/Image/Group14351.svg";
import Ellipse174 from "../../../../assets/Image/Ellipse174.svg";
import { useTranslation } from "react-i18next";
import { getLocalStoarge } from '../../../common/allfunction';
import { t } from "i18next";
import { ReactionSpeedResult } from "../ReactionSpeed/ReactionSpeedResult";
import PostRequest from '../../../apiConnection/postRequest';
import GoNoGoResultComplex from "./GoNoGoResultComplex";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import GoNoGoResultImg from "../../../../assets/Image/GoNoGoResultImg.svg";


export const GoNoGoComplexResume = ({ props }) => {
  const { round, totalTime, resetRound, togglePause, startTimer, timeRemaining, avgAccuracy, params, totalAvgResponseTime, accuracyCurrentRound, averageResponseTime ,free } = props;
  const [t, i18n] = useTranslation("common");
  const [companyId, setCompanyId] = useState(getLocalStoarge('company_id'));
  const [status, setStatus] = useState("in-complete")
  const [isLoading, setIsLoading] = useState(true);
  const component_id = parseInt(params.component_id);
  const repeat_time = parseInt(params.repeat_time);
  const day_sequence = parseInt(params.day_sequence);
  const activity_id = parseInt(params.activity_id);

  useEffect(() => {
    togglePause();
  }, [])

  useEffect(() => {
    let lang = getLocalStoarge("langauage");
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [])

  const avgScore = () => {
    return (((avgAccuracy * round) + (accuracyCurrentRound)) / (round + 1))
  }

  const avgResponseTime = () => {
    return (((totalAvgResponseTime * round) + (parseInt(averageResponseTime))) / parseInt(round + 1));
  }

  const [payload, setPayload] = useState({
    company_id: companyId,
    component_id: component_id,
    day_sequence: day_sequence,
    repeat_time: repeat_time,
    activity_id: activity_id,
    status: status,
    activity_score: avgScore(),
    activity_play_time: ((totalTime * 60) - timeRemaining),
    activity_round_no: round + 1,
    total_avg_response_time: avgResponseTime()
  })

  useEffect(() => {
    console.log('props ',props);
    (timeRemaining > 0 && !free) ? submitUserActivityData(payload) : setIsLoading(false);
  }, [payload])


  const submitUserActivityData = async (payload_1) => {

    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        "",
        "refresh_token"
      );

      // console.log("refresh_token", refreshToken);
      if (refreshToken) {
        const result = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBMIT_USER_ACTIVITY_DATA}`,
          payload_1,
          "",
          ""
        );
        if (result) {
          if (result.status === 200) {
            // Handle status 200
            // console.log("api_submit_result_1", result);
          } else if (result.status === 203) {
            // Handle status 203
          }
        }
        setIsLoading(false);
      }
     
    } catch (error) {
      setIsLoading(false);
      console.log("error", error)
    }
  }

  const reset = (fun) => {
    resetRound();
  }


  return (
    <>
      {
        (timeRemaining > 0) ?
          <section className="reaction-speed-section">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6">
                  <div className="stepsin stpelog">
                    <Link to="dashboard">
                      <img src={logo} className="logo-steps" alt="logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="reaction-speed-result">
                    <div className="col-md-6">
                      <div className="reaction-speed-result-1">
                        <h2>{t("user.activity.go_no_go.result_heading")}</h2>

                        <p>
                          {t("user.activity.go_no_go.result_message").replace('percent', `${Math.round(parseInt((accuracyCurrentRound * 100)))}%`).replace('averageTime', `${Math.round(averageResponseTime)} ms`)}
                        </p>
                        <div className="row">
                          <div className="reaction-speed-result-1-1">
                            <div className="col-sm-10 col-md-10 col-lg-6 col-xl-6 col-xxl-6">
                              <p className="reaction-speed-result-reponse-bar">
                                <CircularProgressbar value={accuracyCurrentRound} maxValue={1} strokeWidth={12} styles={buildStyles({
                                  pathColor: (Math.round(parseInt(accuracyCurrentRound * 100))) >= 60 ? '#9ECB61' : '#B02418',
                                })}
                                /></p>
                            </div>
                            <div className="col-md-6 reaction-speed-result-accuracy">
                              <h6>{Math.round(parseInt(accuracyCurrentRound * 100))}%</h6>
                              <p>{t("user.activity.go_no_go.result_accuracy")}</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <p className="reaction-speed-result-response">{parseInt(Math.round(averageResponseTime))}ms</p>
                          </div>
                          <div className="col-md-6">
                            <p className="reaction-speed-result-response-text">{t("user.activity.go_no_go.result_response")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="reaction-speed-result-2 r-statistiche">
                        <img src={GoNoGoResultImg} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 bw-resume-buttons">
                    <button className="btn btn-primary btnNext" onClick={(event) => { reset() }}>{t("user.activity.executive_function.continue")}</button>
                    <Link to={`/congnitive-training/${parseInt(params.repeat_time)}`}>
                      <button className="btn btn-primary btnNext-act">
                        {t("user.activity.reaction_speed.exit")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          :
          <GoNoGoResultComplex props={{ "params": params, "avgAccuracy": avgAccuracy, "totalTime": totalTime, "totalAvgResponseTime": totalAvgResponseTime, "round": round, "accuracyCurrentRound": accuracyCurrentRound, "averageResponseTime": averageResponseTime ,"free":free }} />
      }
    </>
  )
}
