import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import Super_Sidebar from "./super_sidebar";
import Super_Sidebarmob from "./super_sidebarmob";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import PostRequest from "../../apiConnection/postRequest";
import LazyLoader from "../../common/lazyLoader";
import { useParams } from "react-router-dom";
import Modal from "../../common/modal";
import Group698 from "../../../assets/Image/Group698.svg";
import Group878 from "../../../assets/Image/Group878.svg";
import ExpandIcon from '../../../assets/Image/expand-icon.svg'
import REPORT_TRAINING from '../../../assets/Image/REPORT_TRAINING.svg'
import DeleteBin from '../../../assets/Image/delete-bin.svg'
import crosseditprofile from '../../../assets/Image/cross-editprofile.svg'
import VectorDown from "../../../assets/Image/VectorDown.svg";
import '../super_admin_assets/scss/protocol.scss';
import GetRequest from "../../apiConnection/getRequest";
import NEXTICONA from "../../../assets/Image/NEXTICONA.svg"
import PRIVIEWICONA from '../../../assets/Image/PREVIEWICONA.svg'
import filterFactory from "react-bootstrap-table2-filter";
import { useTranslation } from "react-i18next";
import { getLocalStoarge, setLocalStoarge } from "../../common/allfunction";
import Group1171 from "../../../assets/Image/Group1171.svg";
import CrossAdmin from "../../../assets/Image/cross-admin-icon.svg";
import Group769 from "../../../assets/Image/Group769.svg";
import Popup_Cross from '../../../assets/Image/Popup_Cross.svg'
import Popup_right from '../../../assets/Image/Popup_right.svg'
const SA_Training = (props) => {
    const { SearchBar } = Search;
    const [isLoading, setIsLoading] = useState(false);
    const [t, i18n] = useTranslation("common");
    const [companiesData, setCompaniesData] = useState([])
    const [generalColumn, setGeneralColumn] = useState([]);
    const [changeCompanyStatus, setChangeCompanyStatus] = useState({})
    const [enableCompanyModal, setEnableCompanyModal] = useState(false)
    const [disableCompanyModal, setDisableCompanyModal] = useState(false)
    const [popupLoader1, setPopupLoader1] = useState(false)
    const [popupLoader2, setPopupLoader2] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    const closeModal = () => {
        setChangeCompanyStatus({})
        setDisableButton(false)
        setDisableCompanyModal(false)
        setEnableCompanyModal(false)
        setPopupLoader1(false)
        setPopupLoader2(false)
    }

    const actionButtons = (cell, row, rowIndex, formateExtraData) => {
        return (
            <div className="d-flex justify-content-center">
                <button className="table-action" onClick={() => window.location.href = `training/${row.company_id}`} title="Click for view"><img src={ExpandIcon} alt="icon" /></button>
                <button className="table-action" onClick={() => {}}><img src={REPORT_TRAINING} alt="icon" /></button>
                {!(row.status == "1") ? <button className="table-action bg-green-delete" onClick={() => { setEnableCompanyModal(true); setChangeCompanyStatus({ company_id: row.company_id, status: "1", name: row.company_name }) }} title=" Click for enable">
                    <img src={CrossAdmin} alt="icon" />
                </button> :
                    <button className="table-action bg-green-delete" onClick={() => { setDisableCompanyModal(true); setChangeCompanyStatus({ company_id: row.company_id, status: "0", name: row.company_name }) }} title="Click for disable">
                        <img src={Group1171} alt="icon" />
                    </button>
                } 
            </div>
        )
    }

    const getCompaniesData = async () => {
        setIsLoading(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getAllDataResult = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPANY_TRAINING}`,
                    {},
                    "",
                    ""
                );
                if (getAllDataResult && getAllDataResult.status === 200) {
                    setIsLoading(false);
                    if (getAllDataResult.data.data?.length > 0) {
                        let arr = []
                        getAllDataResult.data.data.forEach((data) => {
                            arr = [...arr, {
                                company_id: data.company_id || "",
                                company_name: data.company_name || "",
                                program_count: data.program_count || "",
                                course_count: data.course_count || "",
                                document_count: data.document_count || "",
                                video_count: data.video_count || "",
                                
                                status: data.status || 0
                            }]
                            setCompaniesData(arr)
                        })
                    }
                } else if (getAllDataResult && getAllDataResult.status === 203) {
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.log("error", error)
            setIsLoading(false);
        }
    }
    const handleTranslationLang = () => {
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        setGeneralColumn([
            {
                text: t("superadmin.protocol.company_name"),
                dataField: "company_name",
                sort: true,

            },
            {
                text: t("superadmin.training.programs"),
                dataField: "program_count",
                align: "left",
                sort: true,
                style: {
                    textAlign: "left"
                }
            },
            {
                text: t("superadmin.training.paths"),
                dataField: "course_count",
                sort: true
            },
            {
                text: t("superadmin.training.documents"),
                dataField: "document_count",
                sort: true,
            },
            {
                text: t("superadmin.training.video"),
                dataField: "video_count",
                sort: true,
            },
            {
                text: t("superadmin.protocol.action"),
                dataField: "button",
                formatter: actionButtons,
                sort: true,
                headerAlign: "center"
            },
        ])
    };
    useEffect(() => {
        setIsLoading(true)
        getCompaniesData()
        handleTranslationLang()
    }, []);
    const pageListRenderer = ({
        page,
        pages,
        active,
        disable,
        title,
        onPageChange
    }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onPageChange(page);
        };
        const activeStyle = {};
        if (active) {
            activeStyle.backgroundColor = '#6d71f9';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'white';
        } else {
            // activeStyle.backgroundColor = 'gray';
            activeStyle.margin = "10px";
            activeStyle.padding = "5px 15px";
            activeStyle.color = 'black';
        }
        if (typeof page === 'string') {
            activeStyle.backgroundColor = '';
            activeStyle.color = '#6d71f9';
        }
        return (
            <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12 d-flex justify-content-center pagination-list">
                {pages.map(p => {
                    if (p.page === "<") return <div key={p.page} onClick={(e) => onPageChange(p.page)}><img src={PRIVIEWICONA} alt="icon"  style={{
                        marginTop: "20px", marginRight: "20px"
                    }} /></div>
                    if (p.page === ">") return <div key={p.page} onClick={(e) => onPageChange(p.page)}><img src={NEXTICONA} alt="icon"   style={{
                        marginTop: "20px", marginLeft: "20px"
                    }}/></div>
                  
                    if (p.active) return <div key={p.page} className="active" onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    return (
                        <div key={p.page} style={{ margin: "10px", cursor: "pointer" }} onClick={(e) => onPageChange(p.page)}>{p.page}</div>
                    )
                })}
                {/* <li className="">
                    <a href="#" onClick={handleClick} style={activeStyle}>{page}</a>
                </li> */}
            </div>
        );
    };
    let paginationOption = {
        paginationSize: 3,
        sizePerPage: 10,
        hideSizePerPage: true,
        withFirstAndLast: false,
        pageListRenderer
    }
    const changeCompanyStatusAPI = async () => {
        const payload = changeCompanyStatus
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                "",
                "refresh_token"
            );
            if (refreshToken) {
                const getDashboard_Data = await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ACTIVE_DEACTIVE_COMPANY_TRAINING}`,
                    payload,
                    "",
                    ""
                );
                if (getDashboard_Data && getDashboard_Data.status === 200) {
                    setIsLoading(false);
                    closeModal()
                    getCompaniesData()
                } else {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }
    const disableCompanyModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_Cross} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.disable_company_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                        {popupLoader1 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6 className="visi-project"><strong>{t("superadmin.training.popup.disable_company_content")} "{changeCompanyStatus?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button disabled={disableButton} className="buttons modal-button" onClick={() => {setPopupLoader1(true); changeCompanyStatusAPI();setDisableButton(true)} }>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const enableCompanyModalContent = () => {
        return (
            <div>
                <div className="model-pop">
                    <div className="modal-header">
                        <h5 className="modal-title pop_heading" id="exampleModalLabel">
                            <div className="in-linw-w">
                                <img src={Popup_right} alt="icon" />{" "}
                            </div>
                            {/* <strong>{t("user.dashboard.heading_1")}</strong> */}
                            <strong>{t("superadmin.training.popup.enable_company_heading")}</strong>
                        </h5>
                        <button
                            type="button"
                            className="close r0-side"
                            onClick={() => closeModal()}
                        >
                            <img src={Group769} alt="icon" />
                        </button>
                    </div>
                    <div className="modal-body scroll-pop scroll-pop-new">
                        <div className="container">
                        {popupLoader2 && <div className="d-flex alert alert-info">
                                <div className="spinner-container-new">
                                    <div className="loading-spinner-new">
                                    </div>
                                </div>
                                <div className="ml-1"> {t("superadmin.training.popup.wait")} </div>
                            </div>}
                            <h6 className="visi-project"><strong>{t("superadmin.training.popup.enable_company_content")} "{changeCompanyStatus?.name}"?</strong></h6>
                        </div>
                        <div className="d-flex justify-content-end buttons-superadmin">
                            <button className="buttons modal-button mr-3" onClick={() => closeModal()}>{t("superadmin.protocol.popup.cancel")}</button>
                            <button disabled={disableButton} className="buttons modal-button" onClick={() => {setPopupLoader2(true); changeCompanyStatusAPI();setDisableButton(true)}}>{t("superadmin.protocol.popup.confirm")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setDisableCompanyModal(false)} show={disableCompanyModal}>
                {disableCompanyModalContent()}
            </Modal>
            <Modal title="" size="sm" contentClasses="elimina-popup" onClose={() => setEnableCompanyModal(false)} show={enableCompanyModal}>
                {enableCompanyModalContent()}
            </Modal>
            <section className="full_bg dashboard-mobile">
                <div className="container-fluid">
                    <div className="row">
                        <Super_Sidebar path="training" />
                        <div className="col-md-10 pr-0 p0 ipd-righ">
                            <div className="right-dashboard topspace">
                                <div className="protocollo-space">
                                    <h2 className="heading-dash">{t("superadmin.sidebar.training")}</h2>
                                </div>
                                {(companiesData.length > 0 && companiesData) ? <>
                                    <ToolkitProvider
                                        keyField="company_id"
                                        key={'datatable'}
                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : (selection === 3) ? redsource.slice(20, 30) : redsource) : filteredData) : reportDatatutti}
                                        data={companiesData}
                                        columns={generalColumn}
                                        search
                                    >{(props) => (

                                        <div id="test" className="table  ricsb ricsb-new protocolli-data">
                                            <div>
                                                <div>
                                                    <div className="searchbar d-flex justify-content-start position-relative m-2">
                                                        <img
                                                            src={Group698}
                                                            className="search_icon"
                                                            alt="icon"
                                                        />
                                                        <SearchBar                                                       
                                                            placeholder={t("superadmin.protocol.search")}
                                                            {...props.searchProps}
                                                        />
                                                    </div>
                                                    <BootstrapTable                                                      
                                                        src={VectorDown} alt="icon"
                                                        // data={(!controlValue) ? ((filterValue.length === 0) ? ((selection === 0) ? redsource : (selection === 1) ? redsource.slice(0, 10) : (selection === 2) ? redsource.slice(10, 20) : redsource) : filteredData) : reportDatatutti}
                                                        data={companiesData}
                                                        {...props.baseProps}
                                                        filter={filterFactory()}
                                                        pagination={paginationFactory(paginationOption)}
                                                        wrapperClasses={'table-responsive'}

                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                    </ToolkitProvider>
                                </> : null}
                            </div>
                        </div>
                    </div>
                    {(isLoading) ? <LazyLoader /> : null}
                </div>
            </section>
            <Super_Sidebarmob />
        </>
    );
}

export default SA_Training;


