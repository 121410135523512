import { useEffect, useState } from 'react'
import { getCognitivePrePost, getLocalStoarge } from '../../../common/allfunction';
import { useTranslation } from 'react-i18next';
import LazyLoader from '../../../common/lazyLoader';


const CognitiveActivityWisePrePost2 = (props) => {
    const [t, i18n] = useTranslation("common");
    const [executiveFunctionIncongruent, setExecutiveFunctionIncongruent] = useState([]);
    const [executiveFunctionCongruent, setExecutiveFunctionCongruent] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        let lang = getLocalStoarge("langauage");
        if (lang) {
            i18n.changeLanguage(lang);
        }
        getPrePostData();
    }, [props.repeatTime]);

    const getPrePostData = async () => {
        const data = await getCognitivePrePost(props.repeatTime);
        setIsLoading(false);
        setExecutiveFunctionIncongruent(data?.ExecutiveFunctionsIncongruent);
        setExecutiveFunctionCongruent(data?.ExecutiveFunctionsCongruent);
    }


    return (
        <div>
            {isLoading ? <LazyLoader /> : null}
            <div className='second-top-heading mt-3'>
                <div className='col-12'>
                    <h2 className='second-page-heading'>{t('user.activityReport.details_pre_post')}</h2>
                    <p className='pre-post-heading-description' dangerouslySetInnerHTML={{ __html: t('user.activityReport.details_description') }}></p>
                </div>
            </div>
            <div className='pre-post-activity-name-desc'>
                <div className="row m-0">
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity-name4')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity4-description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(executiveFunctionIncongruent?.length > 0) ? executiveFunctionIncongruent[0] : 0}`}</h6>
                            <div className="progress report-progress-background-color" style={{ width: "100%" }} >
                                <div className="progress-bar color-light-sky" style={{ width: executiveFunctionIncongruent ? `${(100 * executiveFunctionIncongruent[0]) / 3500}%` : null }}></div>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(executiveFunctionIncongruent?.length > 0) ? executiveFunctionIncongruent[1] : 0}`} </h6>
                            <div className="progress report-progress-background-color" style={{ width: "100%" }}>
                                <div className="progress-bar color-light-sky" style={{ width: executiveFunctionIncongruent ? `${(100 * executiveFunctionIncongruent[1]) / 3500}%` : null }}></div>
                            </div>
                        </div>
                        <p className='calculate-percent'>{`${(executiveFunctionIncongruent?.length > 0 && Boolean(executiveFunctionIncongruent[2])) ? (executiveFunctionIncongruent[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>
                    </div>
                    <div className="col-lg-4">
                        <h5 className='second-page-activity-name'>{t('user.activityReport.Cognitive-activity-name5')}</h5>
                        <p className='second-page-activity-para'>{t('user.activityReport.Cognitive-activity5-description')}</p>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`PRE / ${(executiveFunctionCongruent?.length > 0) ? executiveFunctionCongruent[0] : 0}`}</h6>
                            <div className="progress report-progress-background-color" style={{ width: "100%" }}>

                                <div className="progress-bar color-light-sky" style={{ width: executiveFunctionCongruent ? `${(100 * executiveFunctionCongruent[0]) / 3500}%` : null }}></div>
                            </div>
                        </div>

                        <div className='ml-sm-0 ml-md-0 ml-lg-4 ml-xl-4 ml-xxl-4 mt-3'>
                            <h6 className='text-left'>{`POST / ${(executiveFunctionCongruent?.length > 0) ? executiveFunctionCongruent[1] : 0}`}</h6>
                            <div className="progress report-progress-background-color" style={{ width: "100%" }}>
                                <div className="progress-bar color-light-sky" style={{ width: executiveFunctionCongruent ? `${(100 * executiveFunctionCongruent[1]) / 3500}%` : null }}></div>
                            </div>
                        </div>
                        <p className='calculate-percent'>{`${(executiveFunctionCongruent?.length > 0 && Boolean(executiveFunctionCongruent[2])) ? (executiveFunctionCongruent[2])?.toString()?.replace('.', ',') : 0}`}<span>%</span></p>
                    </div>
                    <div className="col-lg-4">

                    </div>
                </div>
            </div>

        </div>
    )
}

export default CognitiveActivityWisePrePost2
